import { TableCell } from '@material-ui/core';
import { ColoredTableCellProps } from './types';
import { ColoredEllipsis } from './styles';

export const ColoredTableCell = ({
  children,
  ...colloredEllipsisProps
}: ColoredTableCellProps) => {
  return (
    <TableCell>
      <ColoredEllipsis {...colloredEllipsisProps}>{children}</ColoredEllipsis>
    </TableCell>
  );
};
