import { makeStyles } from '@material-ui/core';
import { infoMain, lightBoxShadow, mediumGray, white } from 'styles/theme';

export const useStyles = makeStyles(theme => ({
  container: {
    padding: '20px 62px 0px 62px',
    [theme.breakpoints.down('sm')]: {
      padding: 20,
    },
  },
  paper: {
    marginBottom: 20,
    borderRadius: 30,
    padding: '25px 20px',
    width: '100%',
    boxShadow: lightBoxShadow,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20,
    },
  },
  headerInfo: {
    marginBottom: 12,
  },
  totalTitle: {
    fontSize: 20,
  },
  totalInfoContainer: {
    textAlign: 'end',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  totalInfo: {
    fontSize: 14,
    color: mediumGray,
  },
  listContainer: {
    width: '100%',
    marginBottom: '90px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '190px',
    },
  },
  price: {
    display: 'inline-block',
    fontSize: 20,
    fontWeight: 700,
  },
  priceInfo: {
    display: 'inline-block',
    fontSize: 20,
  },
  mobilePrice: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '87px',
    padding: '30px',
    left: 0,
    marginBottom: '69px',
    background: white,
    boxShadow: `0px 6px 32px ${infoMain}`,
    borderRadius: '34px 34px 0px 0px',
  },
}));
