import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { CheckCircleOutlineOutlined, HelpOutline } from '@material-ui/icons';
import axios from 'axios';
import AddressForm from 'components/AddressForm';
import AlertCard from 'components/AlertCard';
import DependentForm from 'components/DependentForm';
import EditableFormContainer from 'components/EditableFormContainer';
import FileUploader from 'components/FileUploader';
import Dropdown from 'components/HeaderBar/Dropdown';
import Modal from 'components/Modal';
import PageFooter from 'components/PageFooter';
import PageHeader from 'components/PageHeader';
import SavedContract from 'components/SavedContract';
import SpinnerButton from 'components/SpinnerButton';
import { useAuth } from 'contexts/auth';
import { useAlert } from 'hooks/Alert';
import { MESSAGE_TYPE } from 'hooks/Alert/types';
import { useSearchCEP } from 'hooks/searchCEP';
import { StatusCodes } from 'http-status-codes';
import { omit, sortBy } from 'lodash';
import { dependentDataValidationSchema } from 'pages/Client/NewRegistration/utils';
import { uploadList } from 'pages/Client/ViewDependent/utils';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ContractService from 'services/contractService';
import DependentService from 'services/dependentService';
import PaymentService from 'services/paymentService';
import RegistrationService from 'services/registrationService';
import TuitionService from 'services/tuitionService';
import useGlobalStyles from 'styles';
import {
  EDIT_SUCCESSFUL,
  GENERAL_ERROR,
  OPTION_NOT_ALLOWED,
  REMOVED_SUCCESS,
  STATUS_CHANGE_BLOCKED,
  STATUS_CHANGE_SUCCESS,
} from 'texts';
import { Address } from 'types/address';
import { Bill } from 'types/bill';
import { ContractInfo } from 'types/contractInfo';
import { Dependent } from 'types/dependent';
import { DependentDocuments } from 'types/dependentDocuments';
import {
  INTERVIEW_STATUS,
  PAYMENT_STATUS,
  REGISTRATION_STATUS,
  USER_ROLES,
} from 'types/enums';
import { Registration } from 'types/registration';
import { RouteParams } from 'types/routeParams';
import { ISchoolClass } from 'types/schoolClass';
import { formatDate, trimSpecialCharacters } from 'utils';
import AddCoupon from './AddCoupon';
import GuardiansTable from './GuardiansTable';
import InfoBox from './InfoBox';
import SelectBox from './SelectBox';
import {
  BLOCKED_REGISTRATION_TITLE,
  CONFIRM_MODAL_BTN,
  REGISTRATION_STATUS_OPTIONS,
  TRANSFER_MODAL_SUBTITLE,
  TRANSFER_MODAL_TITLE,
  blockedStatusChangeFrom,
  blockedStatusChangeTo,
} from './utils';

const AdmRegistrationDetails = () => {
  const classes = useGlobalStyles();
  const history = useHistory();
  const {
    params: { id: registrationId },
  } = useRouteMatch<RouteParams>();
  const { user: loggedUser } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [editing, setEditing] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [dependent, setDependent] = useState<Dependent>({} as Dependent);
  const [dependentDocuments, setDependentDocuments] =
    useState<DependentDocuments>({} as DependentDocuments);
  const [guardianAddress, setGuardianAddress] = useState<Address>(
    {} as Address,
  );

  const [registration, setRegistration] = useState<Registration>(
    {} as Registration,
  );

  const [interviewStatus, setInterviewStatus] = useState(
    '' as INTERVIEW_STATUS,
  );
  const [registrationStatus, setRegistrationStatus] = useState(
    '' as REGISTRATION_STATUS,
  );

  const [schoolClasses, setSchoolClasses] = useState<ISchoolClass[]>([]);
  const [selectedSchoolClass, setSelectedSchoolClass] =
    useState<ISchoolClass>();
  const [observation, setObservation] = useState('');
  const [hasGuardianDocuments, setHasGuardianDocuments] = useState(false);
  const [hasGuardianAddress, setHasGuardianAddress] = useState(false);
  const [contractInfo, setContractInfo] = useState<ContractInfo | null>();
  const [transferModal, setTransferModal] = useState(false);
  const [refund, setRefund] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [blockedModal, setBlockedModal] = useState(false);

  const { openAlert, closeAlert, alertMessage, alertType, isShowAlert } =
    useAlert();

  const startDate = useMemo(() => {
    if (registration?.createdAt) {
      let sortedBills: Bill[] = [];
      if (registration?.payments[0]?.id) {
        sortedBills = sortBy(registration?.payments[0]?.bills, [
          'paymentDateForecast',
        ]);
      }

      return formatDate({
        date:
          registration?.payments[0]?.id && sortedBills[0]?.paidAt
            ? sortedBills[0]?.paidAt
            : registration?.createdAt,
      });
    }

    return '---';
  }, [registration]);

  const { handleSubmit, errors, register, control, setValue } = useForm({
    resolver: yupResolver(dependentDataValidationSchema),
    reValidateMode: 'onBlur',
  });

  const { address, debouncedSearchCEP, cepError, cepErrorCount } = useSearchCEP(
    registration?.dependent?.address,
  );

  useEffect(() => {
    register('hasGuardianDocuments');
    setValue('hasGuardianDocuments', hasGuardianDocuments);
    register('hasGuardianAddress');
    setValue('hasGuardianAddress', hasGuardianAddress);
  }, [hasGuardianDocuments, hasGuardianAddress, register, setValue]);

  const loadRegistration = useCallback(async () => {
    const { data: response, status } = await RegistrationService.registration(
      registrationId,
      {
        join: [
          ['dependent'],
          ['schoolClass'],
          ['interview'],
          ['coupon'],
          ['documents'],
          ['financialGuardian'],
          ['financialGuardian.user'],
          ['dependent.createdByUser'],
          ['dependent.legalGuardians'],
          ['payments'],
          ['payments.bills'],
        ],
      },
    );

    if (status === StatusCodes.OK) {
      setRegistration(response);
      setInterviewStatus(response.interview.status);
      setRegistrationStatus(response.status);
      setSelectedSchoolClass(response.schoolClass);
      setObservation(response.observation || '');
      setDependent(response.dependent);
      setHasGuardianDocuments(response.dependent.hasGuardianDocuments);
      setHasGuardianAddress(response.dependent.hasGuardianAddress);
      setGuardianAddress(
        response.dependent.hasGuardianAddress
          ? response.financialGuardian.address
          : {},
      );
      setIsLoading(false);
    } else {
      openAlert({
        message: GENERAL_ERROR,
        type: MESSAGE_TYPE.ERROR,
      });
    }
  }, [registrationId, openAlert]);

  useEffect(() => {
    loadRegistration();
  }, [loadRegistration]);

  useEffect(() => {
    register('editing');
    setValue('editing', true);
  }, [register, setValue]);

  const getSchoolClasses = useCallback(async () => {
    const { data: response, status } = await TuitionService.filterTuitions({
      sort: {
        field: 'id',
        order: 'ASC',
      },
    });
    if (status === StatusCodes.OK) {
      setSchoolClasses(response.data);
    } else {
      openAlert({
        message: GENERAL_ERROR,
        type: MESSAGE_TYPE.ERROR,
      });
    }
  }, [openAlert]);

  useEffect(() => {
    getSchoolClasses();
  }, [getSchoolClasses]);

  const getDocument = useCallback(async () => {
    // TODO: Take a look if the filter of the payment per status is necessary
    const { data: paymentResponse, status: paymentStatus } =
      await PaymentService.filterPayments({
        filter: [
          {
            field: 'registrationId',
            operator: '$eq',
            value: registrationId,
          },
          {
            field: 'canceledBy',
            operator: '$isnull',
          },
          {
            field: 'status',
            operator: '$ne',
            value: PAYMENT_STATUS.CANCELED,
          },
        ],
        sort: {
          field: 'id',
          order: 'DESC',
        },
      });

    if (paymentStatus === StatusCodes.OK && paymentResponse.data.length) {
      const paymentId = paymentResponse?.data?.[0].id;

      const { data } = await ContractService.contractByPaymentId(paymentId);
      const contractInfo = data[0];

      if (contractInfo) {
        setContractInfo(contractInfo);
      }
    }
  }, [registrationId]);

  useEffect(() => {
    getDocument();
  }, [getDocument]);

  const updateInterviewStatus = async () => {
    const response = await RegistrationService.changeInterviewStatus(
      registration.id,
      interviewStatus,
    );
    if (response.status === StatusCodes.OK) {
      setModal(true);
      setModalMessage(EDIT_SUCCESSFUL);
    } else {
      openAlert({
        message: GENERAL_ERROR,
        type: MESSAGE_TYPE.ERROR,
      });
    }
  };

  const updateRegistrationStatus = async () => {
    if (blockedStatusChangeTo.includes(registrationStatus)) {
      openAlert({
        message: OPTION_NOT_ALLOWED(registrationStatus),
        type: MESSAGE_TYPE.ERROR,
      });
      return;
    }

    if (blockedStatusChangeFrom.includes(registration.status)) {
      openAlert({
        message: STATUS_CHANGE_BLOCKED(registration.status),
        type: MESSAGE_TYPE.ERROR,
      });
      return;
    }

    const response = await RegistrationService.changeRegistrationStatus(
      registration.id,
      registrationStatus,
    );
    if (response.status === StatusCodes.OK) {
      setModal(true);
      setModalMessage(EDIT_SUCCESSFUL);
      loadRegistration();
    } else {
      openAlert({
        message: response.data.message || GENERAL_ERROR,
        type: MESSAGE_TYPE.ERROR,
      });
    }
  };

  const updateSchoolClass = async () => {
    if (!selectedSchoolClass) return;

    try {
      await RegistrationService.updateSchoolClass(
        registration.id,
        selectedSchoolClass.id,
      );

      setModal(true);
      setModalMessage(EDIT_SUCCESSFUL);
      loadRegistration();
    } catch (error: unknown) {
      let message = GENERAL_ERROR;
      if (axios.isAxiosError(error)) {
        message = error.response?.data?.message || GENERAL_ERROR;
      }
      openAlert({
        message,
        type: MESSAGE_TYPE.ERROR,
      });
    }
  };

  const updateObservation = async () => {
    const response = await RegistrationService.changeRegistrationData(
      registration.id,
      { observation },
    );
    if (response.status === StatusCodes.OK) {
      setModal(true);
      setModalMessage(EDIT_SUCCESSFUL);
    } else {
      openAlert({
        message: GENERAL_ERROR,
        type: MESSAGE_TYPE.ERROR,
      });
    }
  };

  const removeGuardian = async (id: number) => {
    const response = await DependentService.removeLegalGuardian(
      registration.dependentId,
      id,
    );
    if (response.status === StatusCodes.OK) {
      setModal(true);
      setModalMessage(REMOVED_SUCCESS);
    } else {
      openAlert({
        message: GENERAL_ERROR,
        type: MESSAGE_TYPE.ERROR,
      });
    }
  };

  const goToNewGuardian = () => {
    history.push({
      pathname: `/adm-registrations/${registrationId}/new-guardian`,
    });
  };

  const onSubmit = async (data: Partial<Dependent>) => {
    if (data.address && Object.keys(data.address)?.length) {
      data.address = { ...address, ...data.address };
    } else if (hasGuardianAddress) {
      data.address = guardianAddress;
    } else {
      delete data.address;
    }

    try {
      const response = await DependentService.updateDependent(
        omit(
          {
            ...data,
            hasGuardianDocuments: false,
            cpf: trimSpecialCharacters(data.cpf),
            hasGuardianAddress,
          },
          ['editing'],
        ),
        registration.dependentId,
      );

      setDependent(prev => ({ ...prev, ...response.data }));
      setEditing(!editing);
      setModal(true);
      setModalMessage(EDIT_SUCCESSFUL);
    } catch (error: any) {
      openAlert({
        message: error?.message || GENERAL_ERROR,
        type: MESSAGE_TYPE.ERROR,
      });
    }
  };

  const handleCheckHasGuardianDocuments = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = e.target;
    if (checked) {
      const { cpf, rg } = registration.financialGuardian;
      setDependentDocuments({ cpf, rg });
    } else {
      setDependentDocuments({} as DependentDocuments);
    }
    setHasGuardianDocuments(checked);
  };

  const handleCheckHasGuardianAddress = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setGuardianAddress(
      checked ? registration.financialGuardian.address : ({} as Address),
    );
    setHasGuardianAddress(checked);
  };

  const openTransferModal = (refund: boolean) => () => {
    setRefund(refund);
    setTransferModal(true);
  };

  const closeTransferModal = () => {
    setTransferModal(false);
  };

  const setTransferredStatus = async () => {
    const response = await RegistrationService.setTransferredStatus(
      Number(registrationId),
      refund,
    );
    if (response.status === StatusCodes.CREATED) {
      loadRegistration();
      setModal(true);
      setModalMessage(STATUS_CHANGE_SUCCESS(REGISTRATION_STATUS.TRANSFERRED));
    } else {
      openAlert({
        message: response.data.message,
        type: MESSAGE_TYPE.ERROR,
      });
    }
    closeTransferModal();
  };

  const openBlockAndUnblockRegistrationModal = (isBlocked: boolean) => () => {
    setBlocked(isBlocked);
    setBlockedModal(true);
  };

  const setBlockedAndUnblockedRegistration = async () => {
    const response = await RegistrationService.blockAndUnblockRegistration(
      Number(registrationId),
      blocked,
    );
    if (response.status === StatusCodes.CREATED) {
      loadRegistration();
      setModal(true);
      setModalMessage(response);
    } else if (response.status === StatusCodes.OK) {
      openAlert({
        message: response.data.message,
        type: MESSAGE_TYPE.SUCCESS,
      });
    } else {
      openAlert({
        message: response.data.message,
        type: MESSAGE_TYPE.ERROR,
      });
    }
    closeBlockedModal();
  };

  const closeBlockedModal = () => {
    setBlockedModal(false);
  };

  const selectedSchoolClassName = selectedSchoolClass?.name || '';

  const handleSelectSchoolClass = useCallback(
    (value: any) =>
      setSelectedSchoolClass(schoolClasses.find(item => item.name === value)),
    [schoolClasses],
  );

  const schoolClassOptions = useMemo(
    () =>
      schoolClasses.map(({ id, name }) => ({
        id,
        label: name,
      })),
    [schoolClasses],
  );

  if (isLoading) return null;

  return (
    <Grid
      style={{ padding: 25 }}
      container
      direction="column"
      alignItems="flex-start"
    >
      <Modal
        icon={
          <CheckCircleOutlineOutlined
            color="secondary"
            className={classes.dialogIcon}
          />
        }
        open={modal}
        text={modalMessage}
        btnText="Voltar"
        btnAction={() => setModal(false)}
      />
      <Modal
        icon={<HelpOutline color="primary" className={classes.dialogIcon} />}
        open={transferModal}
        text={TRANSFER_MODAL_TITLE}
        subtext={TRANSFER_MODAL_SUBTITLE(refund)}
        btnText={CONFIRM_MODAL_BTN}
        btnAction={setTransferredStatus}
        backBtnText="Voltar"
        backBtnAction={closeTransferModal}
      />
      <Modal
        icon={<HelpOutline color="primary" className={classes.dialogIcon} />}
        open={blockedModal}
        text={BLOCKED_REGISTRATION_TITLE(blocked)}
        btnText={CONFIRM_MODAL_BTN}
        btnAction={setBlockedAndUnblockedRegistration}
        backBtnText="Voltar"
        backBtnAction={closeBlockedModal}
      />
      <AlertCard
        message={alertMessage || cepError}
        open={isShowAlert || !!cepError}
        close={closeAlert}
        severity={isShowAlert ? alertType : MESSAGE_TYPE.ERROR}
      />
      <Grid container item direction="column">
        <PageHeader
          title="Matrículas"
          subtitle="Detalhes da matrícula"
          button={
            <Dropdown
              dark
              text="Ações"
              menuItems={[
                {
                  action: openTransferModal(false),
                  title: 'Aluno transferido/desistiu',
                  disabled: blockedStatusChangeFrom.includes(
                    registration.status,
                  ),
                },
                {
                  action: openBlockAndUnblockRegistrationModal(
                    !registration.isBlocked,
                  ),
                  title: registration.isBlocked
                    ? 'Desbloquear matrícula'
                    : 'Bloquear matrícula',
                },
                // removido para evitar confusão no uso, poderá ser utilizado ainda
                // {
                //   action: openTransferModal(true),
                //   title: 'Aluno transferido/desistiu - estornar cobranças',
                //   disabled: blockedStatusChangeFrom.includes(
                //     registration.status,
                //   ),
                // },
              ]}
            />
          }
        />
        <Paper elevation={0} className={classes.screenPaper}>
          <Grid item container spacing={2} justifyContent="space-around">
            <InfoBox
              title="Aluno"
              text={registration.dependent.name || '---'}
            />
            <InfoBox
              title={`Ano letivo ${registration.schoolYear}`}
              text={registration.schoolClass.name}
            />
            <InfoBox title="Matrícula" text={registration.number || '---'} />
            <InfoBox title="Data de início" text={startDate} />
            {registration?.cancelledAt &&
            registrationStatus === REGISTRATION_STATUS.CANCELLED ? (
              <InfoBox
                title="Data de cancelamento"
                text={registration.cancelledAt}
              />
            ) : null}
            <InfoBox title="Status" text={registration.status} />
          </Grid>
          <Divider className={classes.paymentScreenDivider1} />
          <Grid item container justifyContent="space-between">
            <SelectBox
              title="Solicitação de Entrevista"
              value={interviewStatus}
              setAction={setInterviewStatus}
              options={INTERVIEW_STATUS}
              saveAction={() => updateInterviewStatus()}
            />
            <SelectBox
              title="Status da Matrícula"
              value={registrationStatus}
              setAction={setRegistrationStatus}
              options={REGISTRATION_STATUS_OPTIONS}
              saveAction={() => updateRegistrationStatus()}
            />
            <SelectBox
              title="Período Escolar"
              value={selectedSchoolClassName}
              setAction={handleSelectSchoolClass}
              options={schoolClassOptions}
              saveAction={() => updateSchoolClass()}
              // https://app.clickup.com/t/86a0qv5z9
              disabled={registration.status === REGISTRATION_STATUS.ACTIVE}
            />
          </Grid>

          <EditableFormContainer
            title="Dados gerais"
            editing={editing}
            edit={() => setEditing(true)}
            save={handleSubmit(onSubmit)}
          >
            {dependent ? (
              <>
                <DependentForm
                  dependent={dependent}
                  documents={dependentDocuments}
                  hasGuardianDocuments={hasGuardianDocuments}
                  setHasGuardianDocuments={handleCheckHasGuardianDocuments}
                  register={register}
                  control={control}
                  errors={errors}
                  editing={editing}
                />
                <AddressForm
                  address={dependent?.address}
                  searchAddress={address}
                  guardianAddress={guardianAddress}
                  cepSearch={debouncedSearchCEP}
                  cepErrorCount={cepErrorCount}
                  hasGuardianAddress={hasGuardianAddress}
                  setHasGuardianAddress={handleCheckHasGuardianAddress}
                  register={register}
                  control={control}
                  errors={errors}
                  editing={editing}
                />
              </>
            ) : null}
          </EditableFormContainer>
        </Paper>

        <Grid
          container
          item
          alignItems="center"
          justifyContent="flex-end"
          className={classes.verticalMargin}
        >
          <Grid item>
            <SpinnerButton
              className={classes.newContractButton}
              action={handleSubmit(onSubmit)}
              text="Salvar alterações"
              disabled={!editing}
            />
          </Grid>
        </Grid>

        <Paper elevation={0} className={classes.screenPaper}>
          <Typography className={classes.mainScreenText3}>
            <b>Contato do responsável</b>
          </Typography>

          <Divider className={classes.divider1} />
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            spacing={3}
            className={classes.screenInputGrid}
          >
            <Grid item>
              <TextField
                label="Telefone"
                variant="outlined"
                value={dependent?.createdByUser?.phone || '---'}
                disabled
                className={classes.screenInputField}
              />
            </Grid>
            <Grid item>
              <TextField
                label="E-mail"
                variant="outlined"
                value={dependent?.createdByUser?.email || '---'}
                disabled
                className={classes.screenInputField}
              />
            </Grid>
          </Grid>
        </Paper>

        {loggedUser && loggedUser.roles.includes(USER_ROLES.ADMIN) ? (
          <AddCoupon registration={registration} />
        ) : null}

        {dependent?.legalGuardians ? (
          <GuardiansTable
            financialGuardian={registration.financialGuardian}
            legalGuardians={dependent?.legalGuardians}
            removeFn={removeGuardian}
            goToNew={goToNewGuardian}
          />
        ) : null}

        <Paper elevation={0} className={classes.screenPaper}>
          <Grid item container direction="column" alignItems="flex-start">
            <Typography className={classes.mainScreenText3}>
              <b>Documentos</b>
            </Typography>

            <Divider className={classes.divider1} />

            <Grid item style={{ marginBottom: 40, width: '100%' }}>
              {uploadList(registration.documents).map(item => (
                <FileUploader
                  key={item.type}
                  registrationId={registration.id}
                  title={item.title}
                  type={item.type}
                  savedDocument={item.savedDocument}
                />
              ))}
            </Grid>
            <Typography className={classes.mainScreenText3}>
              <b>Observações</b>
            </Typography>
            <TextField
              multiline
              rows={4}
              value={observation}
              onChange={e => setObservation(e.target.value)}
              variant="outlined"
              placeholder="Texto de observação inserido pelo admin"
              style={{ width: '100%', margin: '15px 0px' }}
            />
          </Grid>
          <Grid
            container
            item
            alignItems="center"
            justifyContent="flex-end"
            spacing={3}
          >
            <Grid item>
              <SpinnerButton
                text="Salvar"
                action={() => updateObservation()}
                className={classes.newDependentButton}
              />
            </Grid>
          </Grid>
        </Paper>

        {contractInfo ? (
          <SavedContract
            contract={contractInfo}
            year={registration.schoolYear}
          />
        ) : null}

        <PageFooter returnPath="/adm-registrations" />
      </Grid>
    </Grid>
  );
};

export default AdmRegistrationDetails;
