import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { SelectInput } from '../SelectInput/SelectInput';
import { ControllerSelectInputProps } from './types';

export const ControllerSelectInput = ({
  name,
  label,
  errors,
  register,
  control,
  options,
  defaultValue,
  selectProps,
  isMultiple = false,
  textFieldProps,
  helperText,
  disabled,
}: ControllerSelectInputProps) => {
  const parsedDefaultValue = useMemo(() => {
    if (defaultValue) return defaultValue;

    return isMultiple ? [] : '';
  }, [defaultValue, isMultiple]);

  const renderHelperText = useMemo(() => {
    if (errors[name]) {
      return errors[name].message;
    }
    return helperText;
  }, [errors, helperText, name]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={parsedDefaultValue}
      as={({ value, onChange }) => {
        return (
          <SelectInput
            disabled={disabled}
            isMultiple={isMultiple}
            defaultValue={parsedDefaultValue}
            selectProps={selectProps}
            name={name}
            label={label}
            value={value}
            onChange={onChange}
            options={options}
            error={!!errors[name]}
            helperText={renderHelperText}
            textFieldProps={{
              ...(textFieldProps || {}),
              inputRef: register,
            }}
          />
        );
      }}
    />
  );
};
