import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  linkBtn: {
    borderRadius: 8,
    boxShadow: 'none',
    marginBottom: 27,
    textTransform: 'none',
    padding: '15px 20px',
    marginTop: 50,
    width: 270,
  },
});
