import { Button, Divider, Grid, MenuItem, TextField } from '@material-ui/core';
import AlertCard from 'components/AlertCard';
import CreditCardModal from 'components/CreditCardModal';
import CurrencyField from 'components/CurrencyField';
import IncompleteProfileModal from 'components/IncompleteProfileModal';
import { selectMenuProps } from 'constants/selectMenu';
import { useAuth } from 'contexts/auth';
import { StatusCodes } from 'http-status-codes';
import { without } from 'lodash';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import CreditCardService from 'services/creditCardService';
import useGlobalStyles from 'styles';
import { GENERAL_ERROR } from 'texts';
import { Card } from 'types/card';
import { PAYMENT_TYPES } from 'types/enums';
import { CardViewProperties, PaymentCardOption } from '../types';
import { formatCard } from '../utils';
import { useStyles } from './styles';

const CardMethodView: React.FC<CardViewProperties> = ({
  paymentType,
  singlePaymentValue,
  calcRemaining,
  register,
  errors,
  hasFreeCoupon,
  subscriptions,
  setSubscriptions,
  installments,
}) => {
  const classes = useGlobalStyles();
  const styles = useStyles();
  const { user } = useAuth();
  const [alert, setAlert] = useState(false);
  const [creditCards, setCreditCards] = useState<Card[]>([]);
  const [newCardModal, setNewCardModal] = useState(false);
  const [incompleteProfileModal, setIncompleteProfileModal] = useState(false);

  const loadCards = useCallback(async () => {
    if (user) {
      const response = await CreditCardService.creditCards({
        filter: [
          {
            field: 'financialGuardianId',
            value: user.financialGuardian.id,
            operator: '$eq',
          },
        ],
      });
      if (response.status === StatusCodes.OK) {
        setCreditCards(response.data.data);
      } else {
        setAlert(true);
      }
    }
  }, [user]);

  useEffect(() => {
    loadCards();
  }, [loadCards]);

  const addCard = () => {
    setSubscriptions([
      ...subscriptions,
      {
        value: '',
        installments,
        card: undefined,
      },
    ]);
  };

  const removeCard = (index: number) => () => {
    setSubscriptions(without(subscriptions, subscriptions[index]));
  };

  const handleChangeValue = (
    value: string,
    index: number,
    subscriptions: PaymentCardOption[],
  ) => {
    saveSubscriptionValue(value, index);
    calcRemaining(subscriptions);
  };

  const saveSubscriptionValue = (value: string, index: number) => {
    setSubscriptions(() =>
      subscriptions.map((item, i) => (i === index ? { ...item, value } : item)),
    );
  };

  const saveSubscriptionCard = (index: number, card: Card) => () => {
    setSubscriptions(() =>
      subscriptions.map((item, i) => (i === index ? { ...item, card } : item)),
    );
  };

  const handleOpenCardModal = () => {
    setNewCardModal(!!user?.financialGuardian?.isCompleted);
    setIncompleteProfileModal(!user?.financialGuardian?.isCompleted);
  };

  const handlecloseCardModal = () => {
    setNewCardModal(false);
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };

  return (
    <>
      <AlertCard
        message={GENERAL_ERROR}
        open={alert}
        close={handleCloseAlert}
        severity="error"
      />
      {user ? (
        <CreditCardModal
          user={user}
          open={newCardModal}
          close={handlecloseCardModal}
          loadCards={loadCards}
        />
      ) : null}
      <IncompleteProfileModal open={incompleteProfileModal} />
      {subscriptions.map((item, index: number) => (
        <React.Fragment key={item.card?.id}>
          <Grid item container direction="row" alignItems="center" spacing={3}>
            <Grid item className={styles.cardRowField}>
              <TextField
                label="Selecione o cartão"
                variant="outlined"
                select
                value={subscriptions[index].card?.id}
                className={styles.smallField}
                disabled={hasFreeCoupon}
                SelectProps={selectMenuProps}
                helperText={
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item className={classes.listScreenText}>
                      Seu cartão não está na lista?
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={handleOpenCardModal}
                        color="primary"
                        className={classes.textButton}
                      >
                        Adicione um novo aqui.
                      </Button>
                    </Grid>
                  </Grid>
                }
              >
                {creditCards.map((item, i) => (
                  <MenuItem
                    key={i}
                    value={item.id}
                    onClick={saveSubscriptionCard(index, item)}
                  >
                    <Grid container justifyContent="space-between">
                      <Grid item>{item.alias}</Grid>
                      <Grid item>{formatCard(item.lastFour)}</Grid>
                    </Grid>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item className={styles.cardRowField}>
              {paymentType === PAYMENT_TYPES.SPLIT ? (
                <CurrencyField
                  variant="outlined"
                  label="Valor neste cartão"
                  name={`subscriptions[${index}].value`}
                  error={Boolean(errors.subscriptions?.[index]?.value)}
                  value={subscriptions[index].value}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChangeValue(e.target.value, index, subscriptions)
                  }
                  inputRef={register}
                  className={styles.smallField}
                  disabled={!subscriptions[index].card || hasFreeCoupon}
                />
              ) : (
                <TextField
                  variant="outlined"
                  label="Valor neste cartão"
                  className={styles.smallField}
                  value={subscriptions?.[index]?.value || 0}
                  disabled
                />
              )}
            </Grid>
            {index > 0 ? (
              <Grid item className={styles.removeBtn}>
                <Button
                  color="primary"
                  onClick={removeCard(index)}
                  className={classes.textButton}
                >
                  Remover
                </Button>
              </Grid>
            ) : null}
          </Grid>
          {paymentType === PAYMENT_TYPES.SPLIT && (
            <Divider className={classes.paymentScreenDivider2} />
          )}
        </React.Fragment>
      ))}
      <Grid item container spacing={3}>
        <Grid item>
          {paymentType === PAYMENT_TYPES.SPLIT && (
            <Button
              onClick={addCard}
              color="primary"
              className={classes.textButton}
            >
              Adicionar outro cartão
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CardMethodView;
