import { ConfigurationsSliceCreator } from './types';

export const createConfigurationsSlice: ConfigurationsSliceCreator = set => ({
  data: undefined,
  setConfigurations: config =>
    set(state => {
      state.configurations.data = config;
      return state;
    }),
  updateConfigurations: partialConfigObject =>
    set(state => {
      if (state.configurations.data) {
        state.configurations.data = {
          ...state.configurations.data,
          ...partialConfigObject,
        };
      }

      return state;
    }),
});
