import { REGISTRATION_STATUS } from 'types/enums';

export const USE_GUARDIAN_DATA = (field: string) =>
  `Caso o aluno não possua ${field}, insira o do responsável`;
export const INVALID_REG_NUMBER =
  'O número de matrícula deve ser composto de 8 números';
export const STATUS_CHANGE_BLOCKED = (status: REGISTRATION_STATUS) =>
  `O status ${status} de uma matrícula não pode ser alterado.`;
export const STATUS_CHANGE_SUCCESS = (status: REGISTRATION_STATUS) =>
  `Status alterado para ${status} com sucesso.`;

export const DEPENDENT_REGISTRATIONS_NOT_FOUND =
  'Não foi possível encontrar matrículas para esse dependente.';
