import { useCart } from 'hooks/useCart';
import { StatusCodes } from 'http-status-codes';
import { EServicesTypes } from 'pages/Admin/Marketplace/Services/components/ServiceForm/types';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import RegistrationService from 'services/registrationService';
import ServicesCodes from 'services/serviceCodes';
import ServicesService from 'services/servicesService';
import ServiceClassesService from 'services/serviceClassesService';
import { Registration } from 'types/registration';
import { CustomDialogRef } from 'components/CustomDialog/types';
import { ICartItem } from 'contexts/cart/types';
import { useAlert } from 'hooks/Alert';
import { MESSAGE_TYPE } from 'hooks/Alert/types';
import {
  EServiceVariationsNames,
  IServiceDetailsResponse,
  ServiceVariations,
  RouteParams,
  SelectServiceClass,
  PayloadValidateServiceSubmit,
  IServiceClassConflictResponse,
} from './types';

export const useMarketplaceServicesDetailController = () => {
  // Custom Hooks
  const {
    alertMessage,
    alertType,
    isShowAlert,
    openAlertForDuration,
    closeAlert,
  } = useAlert();

  // Navigation
  const history = useHistory();
  const { serviceId, registrationId } = useParams<RouteParams>();

  // States
  const [serviceDetails, setServiceDetails] = useState<IServiceDetailsResponse>(
    {} as IServiceDetailsResponse,
  );
  const [registrationDetails, setRegistrationDetails] = useState<Registration>(
    {} as Registration,
  );
  const [selectServiceClass, setSelectServiceClass] = useState<
    SelectServiceClass[]
  >([]);
  const [serviceClassConflictData, setServiceClassConflictData] =
    useState<IServiceClassConflictResponse>(
      {} as IServiceClassConflictResponse,
    );
  const [isLoadingConflictCheck, setIsLoadingConflictCheck] = useState(false);
  const [loadingServiceDetails, setLoadingServiceDetails] = useState(false);
  const [
    modalServiceAddedSuccessfullyOpen,
    setModalServiceAddedSuccessfullyOpen,
  ] = useState(false);
  const [modalShowInterestOpen, setModalShowInterestOpen] = useState(false);
  const [modalInsertInviteCodeOpen, setModalInsertInviteCodeOpen] =
    useState(false);
  const [isLoadingModalInsertInviteCode, setIsLoadingModalInsertInviteCode] =
    useState(false);
  const [serviceInviteCode, setServiceInviteCode] = useState('');
  // const [serviceAccessCode, setServiceInviteCode] = useState('');
  const [isOpenServiceCodeModal, setIsOpenServiceCodeModal] = useState(false);
  const [isOpenServiceCodeModalError, setIsOpenServiceCodeModalError] =
    useState(false);
  const [isLoadingValidateServiceCode, setIsLoadingValidateServiceCode] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const modalSuccessRef = useRef<CustomDialogRef>(null);
  const modalErrorRef = useRef<CustomDialogRef>(null);

  // Custom hooks
  // FIXME: PERFORM ERROR
  const {
    handleAddServiceToCart,
    handleRemoveServiceFromCart,
    getServiceFromCart,
    cartItems,
  } = useCart();

  // Callbacks
  const handleChangeSelectServiceClass = useCallback(
    (serviceClassId: number) => {
      setSelectServiceClass(state =>
        state.map(serviceClass => ({
          ...serviceClass,
          isSelected: serviceClass.id === serviceClassId,
        })),
      );
    },
    [],
  );

  const getServiceDetails = useCallback(async () => {
    try {
      setLoading(true);
      const [{ data: registrationResponse }, { data: serviceResponse }] =
        await Promise.all([
          RegistrationService.registration(registrationId, {
            join: [['dependent'], ['schoolClass']],
          }),
          ServicesService.getSpecificServiceBasedOnRegistration<IServiceDetailsResponse>(
            serviceId,
            registrationId,
          ),
        ]);

      const isValidServiceForRegistration = serviceResponse.schoolClasses.some(
        classItem => classItem.id === registrationResponse.schoolClassId,
      );

      if (!isValidServiceForRegistration) {
        // FIXME: improve alert
        alert('Serviço não disponível para a matricula selecionada');
        history.goBack();
        return;
      }

      const selectServiceClassesOfCurrentDependent: SelectServiceClass[] = [];
      serviceResponse.serviceClasses.forEach((serviceClass, idx) => {
        if (
          serviceClass.schoolClasses.some(
            ({ id }) => registrationResponse.schoolClassId === id,
          )
        ) {
          selectServiceClassesOfCurrentDependent.push({
            ...serviceClass,
            isSelected: idx === 0,
          });
        }
      });

      setSelectServiceClass(selectServiceClassesOfCurrentDependent);
      setServiceDetails(serviceResponse);
      setRegistrationDetails(registrationResponse);
    } catch (error) {
      // FIXME: error handling
      console.error(error);
    } finally {
      setLoadingServiceDetails(false);
      setLoading(false);
    }
  }, [serviceId]);

  const checkIfServiceClassHasConflict = useCallback(async () => {
    const currentServiceClass = selectServiceClass.find(
      serviceClass => serviceClass.isSelected,
    );

    if (currentServiceClass) {
      const serviceClassesIds = `${cartItems.map(cartItem => {
        if (cartItem.registrationId === Number(registrationId)) {
          return String(cartItem.selectedServiceClass.id);
        }
      })}`;

      const shouldCheckForConflictsWithCart =
        !!serviceClassesIds.length &&
        !serviceClassesIds.includes(String(currentServiceClass.id));

      try {
        setIsLoadingConflictCheck(true);

        const response = await ServiceClassesService.checkServiceClassConflicts(
          currentServiceClass.id,
          {
            registrationId: Number(registrationId),
            serviceClassesIds: shouldCheckForConflictsWithCart
              ? serviceClassesIds
              : undefined,
          },
        );

        if (response.status === StatusCodes.OK) {
          setServiceClassConflictData(response.data);
        } else {
          throw new Error();
        }
      } catch (error) {
        console.log(error);
        openAlertForDuration({
          message:
            'Não foi possível carregar as informações de conflito de horário',
          type: MESSAGE_TYPE.ERROR,
        });
      } finally {
        setIsLoadingConflictCheck(false);
      }
    }
  }, [selectServiceClass, cartItems, registrationId, openAlertForDuration]);

  const goToMarketplaceCart = () => {
    history.push('/client-marketplace/cart');
  };

  //
  const onChangeInsertCodeSubmit = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setServiceInviteCode(target.value);
  };

  const handleCloseModalInsertInviteCode = () => {
    setModalInsertInviteCodeOpen(false);
  };

  const handleValidateInviteCode = async () => {
    // FIXME: make "Aplicar código" button gray & disabled
    // instead of this check
    if (!serviceInviteCode) {
      handleCloseModalInsertInviteCode();
      modalErrorRef.current?.openDialog();
    } else {
      setIsLoadingModalInsertInviteCode(true);

      try {
        const { status, data } =
          await ServicesService.validateServiceInviteCode(
            serviceDetails.id,
            serviceInviteCode,
          );

        setIsLoadingModalInsertInviteCode(false);

        if (status === StatusCodes.OK) {
          const serviceToCart: Omit<ICartItem, 'payment'> = {
            serviceInviteCode,
            registrationId: registrationDetails.id,
            dependentId: registrationDetails.dependentId,
            selectedServiceClass: selectedServiceClass!,
            service: serviceDetails,
            dependent: registrationDetails.dependent,
            registration: registrationDetails,
            maxInstallments: 0,
          };
          handleAddServiceToCart(serviceToCart);
          setModalInsertInviteCodeOpen(false);
          modalSuccessRef.current?.openDialog();
        } else {
          throw new Error(
            data?.message || 'Erro ao encontrar código de convite',
          );
        }
      } catch (error) {
        handleCloseModalInsertInviteCode();
        modalErrorRef.current?.openDialog();
      }
    }
  };

  // exibe modal de erro se inserir *código de convite* errado
  const handleCloseModalAccessCodeError = () => {
    modalErrorRef.current?.closeDialog();
  };

  const removeServiceFromCart = () => {
    handleRemoveServiceFromCart(serviceDetails.id, registrationDetails.id);
  };

  const handleActionToEarlyAccess = () => {
    if (serviceDetails.earlyAccess) {
      setIsOpenServiceCodeModal(true);
    } else {
      addServiceToCart();
    }
  };

  const addServiceToCart = (serviceAccessCode?: string) => {
    // FIXME: validate this flow
    // if (cartItemAlreadyAdded) {
    //   handleRemoveItemsToCart(actualCartItem);
    //   return;
    // }

    if (!selectedServiceClass) {
      console.error('Select service class');
      return;
    }

    if (serviceDetails.serviceType.onlyByInvite) {
      setModalInsertInviteCodeOpen(true);
      return;
    }

    const serviceToCart: Omit<ICartItem, 'payment'> = {
      registrationId: registrationDetails.id,
      dependentId: registrationDetails.dependentId,
      selectedServiceClass,
      service: serviceDetails,
      dependent: registrationDetails.dependent,
      registration: registrationDetails,
      maxInstallments: 0,
      serviceAccessCode,
      serviceInviteCode,
    };
    handleAddServiceToCart(serviceToCart);
    modalSuccessRef.current?.openDialog();
    // handleAddItemsToCart();
  };

  const handleCloseModalServiceAddedSuccessfully = () => {
    modalSuccessRef.current?.closeDialog();
  };

  const handleOpenModalShowInterest = () => {
    setModalShowInterestOpen(true);
  };

  const handleCloseModalShowInterest = () => {
    setModalShowInterestOpen(false);
  };

  // Effects
  useEffect(() => {
    getServiceDetails();
  }, [getServiceDetails]);

  useEffect(() => {
    checkIfServiceClassHasConflict();
  }, [checkIfServiceClassHasConflict]);

  // Memo
  const serviceAlreadyInCart = useMemo(() => {
    return getServiceFromCart(serviceDetails.id, registrationDetails.id);
  }, [getServiceFromCart, registrationDetails.id, serviceDetails.id]);

  const selectedServiceClass = useMemo(() => {
    return selectServiceClass.find(serviceClass => serviceClass.isSelected);
  }, [selectServiceClass]);

  const isSelectedServiceClassFull = useMemo(() => {
    if (selectedServiceClass?.spotsFilled) {
      return (
        selectedServiceClass?.spotsFilled >= selectedServiceClass?.spotsNumber
      );
    }

    return false;
  }, [selectedServiceClass]);

  const isFooterButtonDisabled = useMemo(() => {
    return (
      isSelectedServiceClassFull ||
      isLoadingConflictCheck ||
      serviceClassConflictData.hasConflict
    );
  }, [
    isSelectedServiceClassFull,
    isLoadingConflictCheck,
    serviceClassConflictData.hasConflict,
  ]);

  const serviceVariation = useMemo((): ServiceVariations => {
    if (serviceDetails?.serviceType?.name === EServicesTypes.EVENT) {
      return EServiceVariationsNames.EVENT;
    }
    if (serviceDetails?.inviteCode) {
      return EServiceVariationsNames.BY_INVITE;
    }
    if (!serviceDetails?.amount) {
      return EServiceVariationsNames.WITHOUT_COST;
    }

    return EServiceVariationsNames.WITH_COST;
  }, [
    serviceDetails?.inviteCode,
    serviceDetails?.amount,
    serviceDetails?.serviceType?.name,
  ]);

  const onCloseServiceCodeModal = () => {
    setIsOpenServiceCodeModal(false);
  };

  const handleCloseModalServiceCodeError = () => {
    setIsOpenServiceCodeModalError(false);
  };

  // valida código de acesso antecipado
  const handleValidateServiceCode = async (
    data: PayloadValidateServiceSubmit,
  ) => {
    try {
      setIsLoadingValidateServiceCode(true);
      const payload = {
        ...data,
        serviceId: serviceDetails.id,
      };
      const response = await ServicesCodes.validateServiceCode(payload);

      if (response.status === StatusCodes.CREATED) {
        addServiceToCart(data.serviceCode);
        setIsOpenServiceCodeModal(false);
      } else {
        throw new Error();
      }
    } catch (error) {
      setIsOpenServiceCodeModalError(true);
    } finally {
      setIsLoadingValidateServiceCode(false);
    }
  };

  return {
    selectedServiceClass,
    selectServiceClass,
    handleChangeSelectServiceClass,
    addServiceToCart,
    removeServiceFromCart,
    serviceAlreadyInCart,
    serviceDetails,
    loadingServiceDetails,
    modalServiceAddedSuccessfullyOpen,
    goToMarketplaceCart,
    handleCloseModalServiceAddedSuccessfully,
    modalShowInterestOpen,
    handleCloseModalShowInterest,
    handleOpenModalShowInterest,
    serviceVariation,
    modalInsertInviteCodeOpen,
    handleCloseModalInsertInviteCode,
    serviceInviteCode,
    isLoadingConflictCheck,
    serviceClassConflictData,
    onChangeInsertCodeSubmit,
    handleValidateInviteCode,
    handleCloseModalAccessCodeError,
    onCloseServiceCodeModal,
    handleValidateServiceCode,
    handleActionToEarlyAccess,
    isOpenServiceCodeModal,
    isOpenServiceCodeModalError,
    handleCloseModalServiceCodeError,
    isLoadingValidateServiceCode,
    isLoadingModalInsertInviteCode,
    modalSuccessRef,
    modalErrorRef,
    isFooterButtonDisabled,
    isSelectedServiceClassFull,
    loading,

    // Alert
    alertMessage,
    alertType,
    isShowAlert,
    closeAlert,
  };
};
