import React from 'react';

import { Divider, Title } from './styles';

import { IFieldHeaderProps } from './types';

export const FieldHeader = ({ title }: IFieldHeaderProps) => {
  return (
    <>
      <Title className="field-header">{title}</Title>
      <Divider />
    </>
  );
};
