import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  arrow: {
    '&::before': {
      backgroundColor: '#212529',
    },
  },
  tooltip: {
    maxWidth: '265px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#212529',
    flexItem: 'center',
    fontSize: '12px',
  },
  icon: {
    padding: '1px 9px',
    paddingTop: '-6px',
  },
});
