import { Grid } from '@material-ui/core';
import { ControllerInputText } from 'components/Forms/ControllerInputText';
import { ControllerSelectInput } from 'components/Forms/ControllerSelectInput/ControllerSelectInput';
import { Controller } from 'react-hook-form';
import { InputText } from '../../styles';

import { IGeneraFormProps } from '../../types';
import { useGeneralDataFormController } from './controller';

export const ServiceClassGeneralDataForm = ({
  control,
  register,
  setValue,
  errors,
  serviceClassToUpdate,
}: IGeneraFormProps) => {
  const {
    MenuProps,
    schoolClasses,
    renderValue,
    inputRefMenuDimensions,
    pedagogicProfessors,
    pedagogicSchoolYears,
    serviceDetail,
  } = useGeneralDataFormController({ setValue, register });

  return (
    <Grid container spacing={2} md={11}>
      <Grid item md={4}>
        <Controller
          name="name"
          control={control}
          defaultValue={serviceClassToUpdate?.name}
          as={({ value, onChange }) => (
            <InputText
              ref={inputRefMenuDimensions}
              id="name"
              name="name"
              label="Nome"
              variant="outlined"
              value={value}
              defaultValue={value}
              onChange={onChange}
              error={!!errors.name}
              helperText={errors.name ? errors.name?.message : null}
              inputRef={register}
            />
          )}
        />
      </Grid>
      {/* <ControllerInputText
        name="pedagogicSubjectId"
        label="componente curricular"
        control={control}
        defaultValue={serviceDetail?.pedagogicSubjectId}
        errors={errors}
        register={register}
      /> */}
      <Grid item md={4}>
        <ControllerSelectInput
          name="pedagogicSchoolYearId"
          label="Ano letivo"
          control={control}
          defaultValue={serviceClassToUpdate?.pedagogicSchoolYearId}
          errors={errors}
          options={pedagogicSchoolYears}
          register={register}
        />
      </Grid>

      <Grid item md={4}>
        <ControllerSelectInput
          name="schoolClasses"
          defaultValue={
            serviceClassToUpdate?.schoolClasses?.map(
              schoolGrade => schoolGrade.id,
            ) || []
          }
          selectProps={{
            MenuProps,
            renderValue,
          }}
          label="Série/Ano"
          isMultiple
          options={(schoolClasses || []).map(schoolClass => ({
            id: schoolClass.id,
            label: schoolClass.name,
          }))}
          control={control}
          register={register}
          errors={errors}
        />
      </Grid>

      <Grid item md={4}>
        <Controller
          name="spotsNumber"
          control={control}
          defaultValue={serviceClassToUpdate?.spotsNumber}
          as={({ value, onChange }) => (
            <InputText
              id="spotsNumber"
              name="spotsNumber"
              label="Quantidade de vagas"
              variant="outlined"
              type="number"
              value={value}
              onChange={onChange}
              error={!!errors.spotsNumber}
              helperText={
                errors.spotsNumber ? errors.spotsNumber?.message : null
              }
              inputRef={register}
            />
          )}
        />
      </Grid>
      <Grid item md={4}>
        {serviceDetail?.pedagogicSubjectId ? (
          <ControllerSelectInput
            name="pedagogicUserId"
            label="Professor/Coordenador responsável"
            control={control}
            defaultValue={serviceClassToUpdate?.pedagogicUserId}
            errors={errors}
            options={pedagogicProfessors}
            register={register}
          />
        ) : (
          <ControllerInputText
            name="responsibleCoordinator"
            control={control}
            defaultValue={serviceClassToUpdate?.responsibleCoordinator}
            label="Coordenador responsável"
            errors={errors}
            register={register}
          />
        )}
      </Grid>
      {/* <Grid item md={4}>
        <Controller
          name="yearlyTotalHours"
          defaultValue={serviceToUpdate?.yearlyTotalHours}
          control={control}
          as={({ value, onChange }) => (
            <InputText
              id="yearlyTotalHours"
              name="yearlyTotalHours"
              label="Duração anual em horas"
              variant="outlined"
              value={value}
              onChange={onChange}
              type="number"
              error={!!errors.yearlyTotalHours}
              helperText={
                errors.yearlyTotalHours
                  ? errors.yearlyTotalHours?.message
                  : null
              }
              inputRef={register}
            />
          )}
        />
      </Grid> */}
    </Grid>
  );
};
