import { Box, Grid, useMediaQuery } from '@material-ui/core';
import React, { useState } from 'react';
import useGlobalStyles from 'styles';

import loginImg from 'assets/loginImg.png';
import loginImgMobile from 'assets/loginImgMobile.png';
import { useStyles, Container } from './styles';
import { IPublicPageContainer } from './types';

export const PublicPageContainer = ({ children }: IPublicPageContainer) => {
  const classes = useGlobalStyles();
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const styles = useStyles();

  const [width, setWidth] = useState(0);

  const onLoad = ({
    currentTarget,
  }: React.SyntheticEvent<HTMLImageElement, Event>) => {
    setWidth(currentTarget.offsetWidth);
  };

  return (
    <Container container className={classes.page}>
      <Box className={styles.imageGrid}>
        <img
          onLoad={onLoad}
          src={isMobile ? loginImgMobile : loginImg}
          className={isMobile ? styles.mobileImg : styles.img}
          alt="loginImg"
        />
      </Box>
      <Grid
        style={isMobile ? { marginTop: 47 } : { marginLeft: width }}
        className={styles.content}
        container
        direction="column"
        justifyContent={isMobile ? undefined : 'center'}
        alignItems="center"
      >
        {children}
      </Grid>
    </Container>
  );
};
