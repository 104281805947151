import { verifyPhone } from 'pages/Client/SignUp/utils';
import {
  DIFFERENT_PASSWORD,
  INVALID_EMAIL,
  INVALID_PHONE,
  NO_NUMBERS,
  REQUIRED_FIELD,
  WEAK_PASSWORD,
} from 'texts';
import { hasNoNumbers, testPasswordStrength } from 'utils';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string()
    .test('no numbers', NO_NUMBERS, hasNoNumbers)
    .required(REQUIRED_FIELD),
  phone: Yup.string()
    .required(REQUIRED_FIELD)
    .test('valid phone', INVALID_PHONE, value => verifyPhone(value))
    .transform(value => value.replace(/\D+/g, '')),
  email: Yup.string().email(INVALID_EMAIL).required(REQUIRED_FIELD),
  password: Yup.string()
    .required(REQUIRED_FIELD)
    .test('password-strength', WEAK_PASSWORD, value =>
      testPasswordStrength(value),
    ),
  repeatPassword: Yup.string()
    .required(REQUIRED_FIELD)
    .test('password-match', DIFFERENT_PASSWORD, function (value) {
      return this.parent.password === value;
    }),
});
