import { styled, Theme } from '@material-ui/core';
import ScrollContainer from 'react-indiana-drag-scroll';
import { white } from 'styles/theme';
import { CategorizedServicesProps } from '../CategorizedServices/types';

export const CardsContainer = styled(ScrollContainer)<
  Theme,
  Pick<CategorizedServicesProps, 'displayMode'>
>(({ theme, displayMode }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    width: 'calc(100% + 83px)',
    marginLeft: '-49px',
    padding: '4px 49px 19px 49px',
    gap: '33px',
    flexWrap: displayMode === 'grid' ? 'wrap' : undefined,
    [theme.breakpoints.down('sm')]: {
      justifyContent: displayMode === 'grid' ? 'center' : 'normal',
    },
    '& .MuiTextField-root': {
      backgroundColor: white,
      maxWidth: '300px',
    },
    '& .empty-states': {
      color: '#808080',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '18px',
      height: '250px',
      width: '80vw',
      textAlign: 'center',
      letterSpacing: '-0.3px',
      paddingTop: '100px',
      opacity: '0.5',
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
      },
    },
  };
});
