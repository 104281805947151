import Grid from '@material-ui/core/Grid';
import { ControllerInputText } from 'components/Forms/ControllerInputText';
import { ControllerSelectInput } from 'components/Forms/ControllerSelectInput/ControllerSelectInput';

import { IGeneraFormProps } from '../../types';
import { useGeneralDataFormController } from './controller';

export const GeneralDataForm = ({
  control,
  register,
  errors,
  serviceToUpdate,
}: IGeneraFormProps) => {
  const {
    servicesTypes,
    serviceCategories,
    servicePublicationStatus,
    currencyRecurrence,
    schoolClasses,
    pedagogicSubjects,
  } = useGeneralDataFormController();

  return (
    <Grid container spacing={2} md={11}>
      <Grid item md={4}>
        <ControllerInputText
          name="name"
          control={control}
          defaultValue={serviceToUpdate?.name}
          label="Nome"
          errors={errors}
          register={register}
        />
      </Grid>
      <Grid item md={4}>
        <ControllerSelectInput
          name="publicationStatus"
          label="Status"
          control={control}
          defaultValue={serviceToUpdate?.servicePublicationStatusId}
          errors={errors}
          options={servicePublicationStatus.map(type => ({
            id: type.id,
            label: type.name,
          }))}
          register={register}
        />
      </Grid>
      <Grid item md={4}>
        <ControllerSelectInput
          name="type"
          label="Tipo de serviço"
          control={control}
          defaultValue={serviceToUpdate?.serviceTypeId}
          errors={errors}
          options={servicesTypes.map(type => ({
            id: type.id,
            label: type.name,
          }))}
          register={register}
        />
      </Grid>
      <Grid item md={4}>
        <ControllerSelectInput
          name="category"
          label="Área do serviço"
          control={control}
          defaultValue={serviceToUpdate?.serviceCategories?.map(({ id }) => id)}
          errors={errors}
          options={serviceCategories.map(type => ({
            id: type.id,
            label: type.name,
          }))}
          register={register}
          isMultiple
        />
      </Grid>
      <Grid item md={4}>
        <ControllerSelectInput
          name="schoolClasses"
          label="Série/Ano"
          control={control}
          defaultValue={serviceToUpdate?.schoolClasses?.map(({ id }) => id)}
          errors={errors}
          options={schoolClasses.map(type => ({
            id: type.id,
            label: type.name,
          }))}
          register={register}
          isMultiple
        />
      </Grid>
      <Grid item md={4}>
        <ControllerSelectInput
          name="currencyRecurrence"
          label="Recorrência"
          control={control}
          defaultValue={serviceToUpdate?.serviceRecurrenceId}
          errors={errors}
          options={currencyRecurrence.map(type => ({
            id: type.id,
            label: type.name,
          }))}
          register={register}
        />
      </Grid>
      <Grid item md={4}>
        <ControllerSelectInput
          name="pedagogicSubjectId"
          label="Componente curricular complementar"
          control={control}
          defaultValue={serviceToUpdate?.pedagogicSubjectId}
          errors={errors}
          options={pedagogicSubjects}
          register={register}
        />
      </Grid>
      {/* <Grid item md={4}>
        <ControllerInputText
          name="coordinatorInCharge"
          control={control}
          defaultValue={serviceToUpdate?.responsibleCoordinator}
          label="Coordenador responsável"
          errors={errors}
          register={register}
        />
      </Grid> */}
    </Grid>
  );
};
