import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { FooterActions } from 'components/FooterActions';
import PageHeader from 'components/PageHeader';
import AlertCard from 'components/AlertCard';
import { formatBRL } from 'utils';
import { Hairline } from 'components/Hairline';
import { ItemCart } from './ItemCart';
import { useMarketplaceCartController } from './useMarketplaceCartController';

const MarketplaceCart = () => {
  const {
    styles,
    cartItems,
    totalCartValue,
    handleGoToPaymentCheckout,
    isMobile,
    isShowAlert,
    closeAlert,
    alertMessage,
    alertType,
  } = useMarketplaceCartController();

  return (
    <>
      <Grid container item direction="column" className={styles.container}>
        <PageHeader
          title="Carrinho"
          subtitle="Veja as atividades e eventos que estão no seu "
          subtitleBold="carrinho"
        />
        <Grid container spacing={4}>
          <Grid
            item
            sm={12}
            md={isMobile ? 12 : 7}
            lg={7}
            xl={8}
            className={styles.listContainer}
          >
            {cartItems?.map(item => (
              <>
                <Hairline thickness={2} />
                <ItemCart
                  key={`${item.service.id}:${item.registrationId}`}
                  item={item}
                />
              </>
            ))}
          </Grid>
          {isMobile ? (
            <Box className={styles.mobilePrice}>
              <Box>
                <Typography className={styles.totalTitle}>Total</Typography>
              </Box>
              <Box>
                <Typography className={styles.price}>
                  {formatBRL(totalCartValue)}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Grid item sm={12} md={5} lg={5} xl={4}>
              <Paper elevation={0} className={styles.paper}>
                <Grid container direction="column" spacing={1}>
                  <Grid item sm={12} md={5}>
                    <Typography className={styles.totalTitle}>Total</Typography>
                  </Grid>
                  <Grid item>
                    <hr />
                  </Grid>
                  <Grid item>
                    <Typography className={styles.price}>
                      {formatBRL(totalCartValue)}
                    </Typography>{' '}
                    {/* <Typography className={styles.priceInfo}>/anual</Typography> */}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Grid>
      <FooterActions
        onConfirm={handleGoToPaymentCheckout}
        confirmTextButton="Continuar a contratação"
      />
      <AlertCard
        message={alertMessage}
        open={isShowAlert}
        close={closeAlert}
        severity={alertType}
      />
    </>
  );
};

export { MarketplaceCart };
