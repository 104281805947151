import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import TuitionService from 'services/tuitionService';
import { useAppStore } from 'store';
import { editConfigInvoiceServiceCodeValidationSchema } from './schema';
import {
  EditConfigInvoiceServiceCode,
  SubmitEditConfigInvoiceServiceCode,
} from './types';

export const useEditConfigInvoiceServiceCodeController = ({
  editFormRef,
  schoolClass,
  onClose,
}: EditConfigInvoiceServiceCode) => {
  const showAlert = useAppStore(state => state.alert.showAlert);

  const { handleSubmit, errors, register, control } = useForm({
    resolver: yupResolver(editConfigInvoiceServiceCodeValidationSchema),
    reValidateMode: 'onSubmit',
    shouldFocusError: true,
  });

  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(data: SubmitEditConfigInvoiceServiceCode) {
    setIsLoading(true);

    if (schoolClass) {
      try {
        await TuitionService.updateTuition(schoolClass.id, {
          invoiceServiceCode: data.invoiceServiceCode,
        });
        showAlert({
          message: 'Código de serviço da nota fiscal atualizado!',
          severity: 'success',
        });
      } catch (error: any) {
        showAlert({
          message: error?.message || 'Falha ao atualizar código de serviço.',
          severity: 'error',
        });
      } finally {
        onClose();
        setIsLoading(false);
      }
    }
  }
  return {
    handleSubmit,
    errors,
    register,
    control,
    isLoading,
    onSubmit,
  };
};
