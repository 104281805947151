import { makeStyles, TextField, Theme } from '@material-ui/core';
import styled from 'styled-components';
import { borderGray, primaryMedium } from 'styles/theme';

export const useStyles = makeStyles({
  divider: {
    marginTop: 49,
    backgroundColor: primaryMedium,
    height: 0.5,
  },
});

export const InputText = styled(TextField)(() => {
  return {
    fontSize: '14px',
    width: '100%',
    background: 'white',
    // marginLeft: 20,
    marginBottom: 20,
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1.45px solid ${borderGray}`,
      fontSize: 16,
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock':
      {
        fontSize: '14px',
        height: '9px',
        marginTop: '-5px',
      },

    '& .material-icons': {
      marginRight: 15,
    },
    '& .MuiPopover-paper': {
      width: '20px !important',
    },
    '& .MuiSelect-select': {
      fontSize: '14px',
    },
  };
});
