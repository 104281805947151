export const downloadFile = (data: any, filename = 'relatorio') => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const a = document.createElement('a');

  a.href = url;
  a.setAttribute('download', `${filename}_${Date.now()}.csv`);
  document.body.appendChild(a);
  a.click();

  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};
