import { styled, Theme } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import { darkGray, primaryDark, white } from 'styles/theme';

export const Container = styled(Dialog)<Theme>(({ theme }) => {
  return {
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
    '& #top-modal-actions': {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        position: 'absolute',
        top: '-25px',
        right: '10px',
      },
    },
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: '631px',
      borderRadius: '20px',
      padding: '40px 70px 80px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        maxWidth: '100%',
        bottom: 0,
        margin: 0,
        borderRadius: '20px 20px 0 0',
        padding: '20px 30px 40px',
      },
    },
    '& .material-icons': {
      width: '100px',
    },
    '& span': {
      fontSize: '28px',
      lineHeight: '38px',
      color: darkGray,
      marginTop: '30px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '28px',
      },
    },
    '& p': {
      fontSize: '16px',
      lineHeight: '22px',
      color: darkGray,
      marginTop: '28px',
      marginBottom: '30px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
    '& #modal-actions': {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      marginTop: '20px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        justifyContent: 'center',
        width: 'fit-content',
      },
    },
    '& #cancel': {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '22px',
      textAlign: 'center',
      color: primaryDark,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      marginRight: '120px',
    },
    '& #confirm': {
      alignItems: 'center',
      padding: '15px 30px',
      // width: '180px',
      // height: '42px',
      background: primaryDark,
      color: white,
      borderRadius: '8px',
      fontWeight: 700,
    },
  };
});
