import { CondOperator, SFields } from '@nestjsx/crud-request';
import { checkIfDateIsValid } from 'components/CustomDatePicker/utils';
import { DEFAULT_SERVICE_NAME } from 'components/FinancialTable/consts';
import { endOfDay, startOfDay } from 'date-fns';
import { CONTRACT_STATUS } from 'types/contractInfo';
import { PAYMENT_SOURCE } from 'types/enums';
import { isString } from 'utils/typeCheckers';

export const handleContractStatusFilterOperator = (
  contractStatus?: string | null,
): CondOperator => {
  if (contractStatus === CONTRACT_STATUS.SIGNED) return CondOperator.NOT_NULL;

  return CondOperator.IS_NULL;
};

export const handleContractStatusFilter = (
  contractStatuses?: (string | null)[] | null,
): SFields | null => {
  // Filtering by both contract statuses is the same as not filtering,
  // hence this check
  if (contractStatuses?.length === 1) {
    return {
      'contractInfo.signDate': {
        [handleContractStatusFilterOperator(contractStatuses[0])]: true,
      },
    };
  }

  return null;
};

export const handleBuildSearchDateInterval = (
  start?: Date | null,
  end?: Date | null,
): SFields | null => {
  if (!start && !end) {
    return null;
  }

  const searchDateInterval: SFields = {
    createdAt: {},
  };

  const startDateISO = start ? startOfDay(start).toISOString() : '';
  const endDateISO = end ? endOfDay(end).toISOString() : '';

  if (startDateISO && endDateISO) {
    searchDateInterval.createdAt = {
      $between: [startDateISO, endDateISO],
    };
  } else if (startDateISO) {
    searchDateInterval.createdAt = {
      $gte: startDateISO,
    };
  } else if (endDateISO) {
    searchDateInterval.createdAt = {
      $lte: endDateISO,
    };
  }

  return searchDateInterval;
};

export const handleBuildSearchText = (
  search?: string | null,
): SFields | null => {
  if (!search) return null;

  const defaultServiceNameSearch: SFields = {
    source: {
      $eq: PAYMENT_SOURCE.REGISTRATION,
    },
  };

  const searchConditions: SFields[] = [
    {
      'payment.registration.number': {
        $contL: search,
      },
    },
    {
      'payment.service.name': {
        $contL: search,
      },
    },
    {
      'payment.registration.dependent.name': {
        $contL: search,
      },
    },
  ];

  if (DEFAULT_SERVICE_NAME.toLowerCase().includes(search.toLowerCase())) {
    searchConditions.push(defaultServiceNameSearch);
  }

  return {
    $or: searchConditions,
  };
};

export const filterStringValuesOnly = (values?: any[] | any) => {
  if (!values || !Array.isArray(values)) return [];
  return values.filter(isString);
};

export const handleDatesToQueryParams = (
  startDate: Date | null,
  endDate: Date | null,
) => {
  let start: Date | undefined;
  let end: Date | undefined;

  if (checkIfDateIsValid(startDate, endDate, 'start') && startDate) {
    start = startDate;
  }
  if (checkIfDateIsValid(startDate, endDate, 'end') && endDate) {
    end = endDate;
  }

  return { start, end };
};
