import { makeStyles } from '@material-ui/core';
import { backgroundGray, mediumGray, primaryDark } from 'styles/theme';
import { StyleProperties } from 'types/style';

export const drawerWidth = 204;

export const useStyles = ({ isAdmin }: StyleProperties) => {
  return makeStyles(theme => ({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      background: isAdmin ? primaryDark : 'white',
    },
    toolbar: theme.mixins.toolbar,
    content: {
      height: '100%',
      minHeight: '100vh',
      float: 'right',
      width: 'calc(100vw - 204px)',
      backgroundColor: backgroundGray,
      padding: theme.spacing(3),
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        width: '100vw',
        padding: 0,
      },
    },
    logo: {
      position: 'absolute',
      top: 0,
      right: 0,
      height: '98.03px',
    },
    list: {
      marginTop: 120,
    },
    accessRoles: {
      display: 'flex',
      color: 'white',
      fontSize: 10,
      paddingTop: 20,
      marginTop: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: 80,
      '& b': {
        marginLeft: 4,
      },
    },
    termsBtn: {
      color: mediumGray,
      display: 'flex',
      fontSize: 10,
      paddingTop: 20,
      marginTop: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: 80,
    },
    termsBtnIcon: {
      fontSize: 20,
      marginRight: 5,
    },
  }));
};
