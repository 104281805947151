import { CreateQueryParams } from '@nestjsx/crud-request';
import { PayloadValidateServiceSubmit } from 'pages/Client/MarketplaceServicesDetail/types';
import { PayloadServiceCodes } from 'types/serviceCodes';
import { api, getWithFilter } from './apiService';

const BASE_PATH = `service-codes`;
export default class ServicesCodes {
  static async getAllCodeServices(query?: CreateQueryParams) {
    try {
      return await getWithFilter(`${BASE_PATH}`, query);
    } catch (error: any) {
      return error.response;
    }
  }

  static async getServiceCode(id: number, query?: CreateQueryParams) {
    try {
      return await getWithFilter(`${BASE_PATH}/${id}`, query);
    } catch (error: any) {
      return error.response;
    }
  }

  static async createCodeServices(data: PayloadServiceCodes) {
    try {
      return await api.post(`${BASE_PATH}`, data);
    } catch (error: any) {
      return error.response;
    }
  }

  static async deleteCodeService(id: number) {
    try {
      return await api.delete(`${BASE_PATH}/${id}`);
    } catch (error: any) {
      return error.response;
    }
  }

  static async validateServiceCode(data: PayloadValidateServiceSubmit) {
    try {
      return await api.post(`${BASE_PATH}/validate`, data);
    } catch (error: any) {
      return error.response;
    }
  }
}
