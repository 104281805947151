import { Title, Subtitle, TitleLine } from './styles';
import { ServiceHeaderProps } from './types';

export const ServiceHeader = ({
  variant = 'major',
  title,
  subtitle,
}: ServiceHeaderProps) => {
  return (
    <div>
      <Title variant={variant}>{title}</Title>
      {variant === 'major' ? <TitleLine /> : null}
      {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
    </div>
  );
};
