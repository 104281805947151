import { makeStyles } from '@material-ui/core';

export const useLoadingPaymentStyles = makeStyles(() => ({
  loadingPaymentContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  },
  loadingPaymentTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
}));
