import { ImageRestrictions } from './types';

/**
 * @param file File object to verify
 */
export const verifyImageRestrictions = async ({ file }: ImageRestrictions) => {
  if (file?.size > 1000_000) {
    throw 'A imagem deve possuir no máximo 1MB.';
  }

  const imageSrc = window.URL.createObjectURL(file);

  return imageSrc;
};
