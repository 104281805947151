import { CartContext } from 'contexts/cart';
import { useContext } from 'react';

export const useCart = () => {
  const context = useContext(CartContext);

  if (!context) {
    throw new Error('Cart context not found');
  }

  return context;
};
