import { DependencyList, useEffect, useRef } from 'react';

type PollingEffectOptions = {
  interval?: number;
  onCleanUp?: () => void;
  attemptsLimit?: number;
};

type AsyncCallback = () => Promise<{
  mustStop?: boolean;
} | void>;

type PollingEffect = (
  asyncCallback: AsyncCallback,
  dependencies?: DependencyList,
  options?: PollingEffectOptions,
) => void;

const defaultOptions: PollingEffectOptions = {
  interval: 5_000,
  onCleanUp: () => {
    //
  },
  attemptsLimit: undefined,
};

export const usePollingEffect: PollingEffect = (
  asyncCallback,
  dependencies = [],
  {
    interval = defaultOptions.interval,
    onCleanUp = defaultOptions.onCleanUp,
    attemptsLimit = defaultOptions.attemptsLimit,
  } = defaultOptions,
) => {
  const timeoutIdRef = useRef<number | null>(null);
  const attemptsCountRef = useRef<number>(0);

  useEffect(() => {
    let stopped = false;
    // Side note: preceding semicolon needed for IIFEs.
    async function pollingCallback() {
      try {
        if (attemptsLimit && attemptsCountRef.current <= attemptsLimit) {
          const results = await asyncCallback();
          stopped = !!results?.mustStop;
        }
      } finally {
        // Set timeout after it finished, unless stopped
        timeoutIdRef.current = !stopped
          ? setTimeout(pollingCallback, interval)
          : null;
        if (attemptsLimit) {
          attemptsCountRef.current += 1;
        }
      }
    }

    pollingCallback();
    // Clean up if dependencies change
    return () => {
      stopped = true; // prevent racing conditions
      if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
      if (onCleanUp) onCleanUp();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, interval]);
};
