import { useMemo } from 'react';
import { useAppStore } from 'store';

export const useGlobalAlertController = () => {
  const { config, closeAlert } = useAppStore(state => state.alert);

  const alertTitle = useMemo(() => {
    // This is a workaround to avoid the alert title to be shown when it is null
    if (config?.title === null) return null;

    if (config?.title) return config.title;

    switch (config?.severity) {
      case 'error':
        return 'Opss! Ocorreu um erro.';
      case 'info':
        return 'Informação';
      case 'success':
        return 'Tudo certo!';
      case 'warning':
        return 'Atenção!';
      default:
        return null;
    }
  }, [config?.severity, config?.title]);

  return {
    config,
    alertTitle,
    closeAlert,
  };
};
