import React from 'react';

import { CustomButton } from 'components/CustomButton';
import Scaffold from 'components/Scaffold';

import { Grid } from '@material-ui/core';
import CustomDialog from 'components/CustomDialog';
import CustomLoadingDialog from 'components/CustomLoadindDialog';
import { FixedFooter } from 'components/FixedFooter';
import { Registration } from 'types/registration';
import ConfirmPayment from './steps/ConfirmPayment';
import { ContractClauses } from './steps/ContractClauses';
import SelectPaymentMethod from './steps/SelectPaymentMethod';
import { usePaymentMethodController } from './usePaymentMethodController';
import { PAYMENT_STEPS } from './utils';

const PaymentMethod: React.FC = () => {
  const {
    isLoadingRegistration,
    handleChangePaymentMethod,
    handleChangePaymentType,
    saveSubscriptionInstallments,
    canProceed,
    handleSubmit,
    paymentMethod,
    hasFreeCoupon,
    paymentType,
    remainingValue,
    installments,
    register,
    errors,
    subscriptions,
    setSubscriptions,
    appliedCoupon,
    setAppliedCoupon,
    currentRegistration,
    paymentStep,
    paymentSelectionData,
    renderSubmitTextButton,
    handleBackToSelection,
    isLoadingPayment,
    originalValue,
    originalValueWithDiscount,
    goToContract,
    successDialogRef,
    setIsContractClausesAccepted,
    isContractClausesAccepted,
  } = usePaymentMethodController();

  const renderPageSubtitle = () => {
    switch (paymentStep) {
      case PAYMENT_STEPS.CONTRACT_CLAUSES:
        return (
          <>
            Cláusulas <b>contratuais</b>
          </>
        );
      default:
        return (
          <>
            Dados para o <b>pagamento</b>
          </>
        );
    }
  };

  const renderPaymentStep = () => {
    switch (paymentStep) {
      case PAYMENT_STEPS.CONTRACT_CLAUSES:
        return (
          <ContractClauses
            isContractClausesAccepted={isContractClausesAccepted}
            setIsContractClausesAccepted={setIsContractClausesAccepted}
          />
        );
      case PAYMENT_STEPS.SELECTION:
        return (
          <SelectPaymentMethod
            registration={currentRegistration as Registration}
            register={register}
            appliedCoupon={appliedCoupon}
            // FIXME: remover isso aqui
            calcRemaining={() => {
              //
            }}
            errors={errors}
            hasFreeCoupon={hasFreeCoupon}
            installments={installments}
            onChangePaymentMethod={handleChangePaymentMethod}
            onChangePaymentType={handleChangePaymentType}
            paymentMethod={paymentMethod}
            paymentType={paymentType}
            remainingValue={remainingValue}
            onSaveSubscriptionInstallments={saveSubscriptionInstallments}
            setAppliedCoupon={setAppliedCoupon}
            setSubscriptions={setSubscriptions}
            subscriptions={subscriptions}
            originalValue={originalValue}
            originalValueWithDiscount={originalValueWithDiscount}
          />
        );
      case PAYMENT_STEPS.CONFIRMATION:
        return (
          <ConfirmPayment
            paymentSelection={paymentSelectionData}
            registration={currentRegistration as Registration}
          />
        );
      default:
        return null;
    }
  };

  const renderBackButton = () => {
    if (paymentStep !== PAYMENT_STEPS.CONFIRMATION) return null;
    return (
      <CustomButton
        disabled={isLoadingPayment}
        variant="secondary"
        onClick={handleBackToSelection}
      >
        Voltar
      </CustomButton>
    );
  };

  return (
    <Scaffold
      loading={isLoadingRegistration}
      rawTitle={<b>Solicitação de matrícula</b>}
      rawSubtitle={renderPageSubtitle()}
    >
      {renderPaymentStep()}
      <FixedFooter
        spacing={2}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item>{renderBackButton()}</Grid>
        <Grid item>
          <CustomButton
            onClick={handleSubmit}
            disabled={!canProceed || isLoadingPayment}
            loading={isLoadingPayment}
          >
            {renderSubmitTextButton()}
          </CustomButton>
        </Grid>
      </FixedFooter>

      <CustomLoadingDialog loading={isLoadingPayment} />
      <CustomDialog
        rawTitle={
          <>
            Sua ordem de compra foi <b>criada com sucesso</b>!
          </>
        }
        rawSubtitle="Siga para o contrato para garantir a vaga na turma desejada."
        primaryButton={{
          text: 'Ir para o contrato',
          onClick: goToContract,
        }}
        icon={{
          name: 'checkCircle',
        }}
        ref={successDialogRef}
      />
    </Scaffold>
  );
};

export default PaymentMethod;
