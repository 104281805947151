import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import AlertCard from 'components/AlertCard';
import MaskedTextField from 'components/MaskedTextField';
import SpinnerButton from 'components/SpinnerButton';
import CurrencyField from 'components/CurrencyField';
import { StatusCodes } from 'http-status-codes';
import ConfigurationsService from 'services/configurationsService';
import useGlobalStyles from 'styles';
import _ from 'lodash';
import { DATE_MASK, YEAR_MASK, GENERAL_ERROR } from 'texts';
import { IDiscount } from 'types/configurations';
import { unformatValueMask } from 'utils';
import { DatePicker } from '@material-ui/pickers';
import { format, addMinutes } from 'date-fns';
import { validationSchema } from '../utils';
import {
  IDiscountFormData,
  ModalProperties,
  SubmitConfiguration,
} from './types';

const EditModal: React.FC<ModalProperties> = ({
  type,
  title,
  configuration,
  discountSelected,
  open,
  close,
  openModal,
}) => {
  const classes = useGlobalStyles();
  const { handleSubmit, errors, register, control, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onBlur',
  });
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  async function onSubmit(data: SubmitConfiguration | IDiscountFormData) {
    const requestPayload = _.omit(data, ['type']);

    if (type === 'discount') {
      const discountData = data as IDiscountFormData;
      const newData: Partial<IDiscount> = {
        untilTheDay: new Date(discountData?.untilTheDay || new Date()),
        discountUntilTheDay: unformatValueMask(
          discountData?.discountUntilTheDay,
        ),
        discountDefault: unformatValueMask(discountData?.discountDefault),
      };
      const response = await ConfigurationsService.updateDiscount({
        id: discountSelected?.id || 0,
        ...newData,
      } as IDiscount);
      if (response.status !== StatusCodes.OK) {
        setAlert(true);
        setAlertMessage(GENERAL_ERROR);
      } else {
        openModal();
      }
    } else {
      const response = await ConfigurationsService.updateConfigurations(
        requestPayload as SubmitConfiguration,
      );
      if (response.status !== StatusCodes.OK) {
        setAlert(true);
        setAlertMessage(GENERAL_ERROR);
      } else {
        openModal();
      }
    }
  }

  /**
   * Executa apenas no início do ciclo do componente (componentDidMount)
   * Necessário para registrar o campo 'type'
   * Sem isso ele sempre retornará undefined na hora da validação
   */
  useEffect(() => {
    register('type');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue('type', type);
  }, [type, setValue]);

  return (
    <>
      <AlertCard
        message={alertMessage}
        open={alert}
        close={() => setAlert(false)}
        severity="error"
      />
      <Dialog open={open}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            style={{ width: 585, padding: 35 }}
            display="flex"
            flexDirection="column"
          >
            <Typography style={{ fontSize: 30, marginBottom: 30 }}>
              <b>{title}</b>
            </Typography>
            {type === 'discount' ? (
              <Box display="flex" flexDirection="column">
                <Box m={1}>
                  <Controller
                    name="discountUntilTheDay"
                    control={control}
                    as={({ value, onChange }) => (
                      <CurrencyField
                        isPercent
                        label="Desconto (Porcentagem)"
                        variant="outlined"
                        id="discountUntilTheDay"
                        name="discountUntilTheDay"
                        value={value}
                        onChange={onChange}
                        error={Boolean(errors.discountUntilTheDay)}
                        helperText={
                          errors.discountUntilTheDay
                            ? errors.discountUntilTheDay.message
                            : null
                        }
                        inputRef={register}
                        className={classes.newUserInput}
                      />
                    )}
                    defaultValue={discountSelected?.discountUntilTheDay}
                  />
                </Box>
                <Box m={1}>
                  <Controller
                    name="untilTheDay"
                    control={control}
                    as={({ value, onChange }) => (
                      <DatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        inputVariant="outlined"
                        label="Desconto válido até"
                        value={value}
                        onChange={e => {
                          const dateSelected = new Date(e || '');
                          const time = format(dateSelected, 'yyyy/MM/dd');
                          onChange(time);
                        }}
                        className={classes.newUserInput}
                      />
                    )}
                    defaultValue={discountSelected?.untilTheDay}
                  />
                </Box>
                <Box m={1}>
                  <Controller
                    name="discountDefault"
                    control={control}
                    as={({ value, onChange }) => (
                      <CurrencyField
                        isPercent
                        label="Desconto Padrão"
                        variant="outlined"
                        id="discountDefault"
                        name="discountDefault"
                        value={value}
                        onChange={onChange}
                        error={Boolean(errors.discountDefault)}
                        helperText={
                          errors.discountDefault
                            ? errors.discountDefault.message
                            : null
                        }
                        inputRef={register}
                        className={classes.newUserInput}
                      />
                    )}
                    defaultValue={discountSelected?.discountDefault}
                  />
                </Box>
              </Box>
            ) : null}
            {type === 'key' ? (
              <Controller
                name="storeKey"
                control={control}
                as={({ value, onChange }) => (
                  <TextField
                    label="Porcentagem"
                    variant="outlined"
                    id="storeKey"
                    name="storeKey"
                    value={value}
                    onChange={onChange}
                    error={Boolean(errors.storeKey)}
                    helperText={
                      errors.storeKey ? errors.storeKey.message : null
                    }
                    inputRef={register}
                    className={classes.newUserInput}
                  />
                )}
                defaultValue={configuration?.storeKey}
              />
            ) : null}
            {type === 'period' ? (
              <Box display="flex" flexDirection="row">
                <Box m={1}>
                  <Controller
                    name="periodStart"
                    control={control}
                    as={({ value, onChange }) => (
                      <MaskedTextField
                        mask={DATE_MASK}
                        label="Início"
                        name="periodStart"
                        value={value}
                        onChange={onChange}
                        error={Boolean(errors.periodStart)}
                        helperText={
                          errors.periodStart ? errors.periodStart.message : null
                        }
                        inputRef={register}
                        className={classes.newUserInput}
                      />
                    )}
                    defaultValue={configuration?.periodStart}
                  />
                </Box>
                <Box m={1}>
                  <Controller
                    name="periodEnd"
                    control={control}
                    as={({ value, onChange }) => (
                      <MaskedTextField
                        mask={DATE_MASK}
                        label="Término"
                        name="periodEnd"
                        value={value}
                        onChange={onChange}
                        error={Boolean(errors.periodEnd)}
                        helperText={
                          errors.periodEnd ? errors.periodEnd.message : null
                        }
                        inputRef={register}
                        className={classes.newUserInput}
                      />
                    )}
                    defaultValue={configuration?.periodEnd}
                  />
                </Box>
                <Box m={1}>
                  <Controller
                    name="enrollmentYear"
                    control={control}
                    as={({ value, onChange }) => (
                      <MaskedTextField
                        mask={YEAR_MASK}
                        label="Ano da matrícula"
                        name="enrollmentYear"
                        value={value}
                        onChange={onChange}
                        error={Boolean(errors.enrollmentYear)}
                        helperText={
                          errors.enrollmentYear
                            ? errors.enrollmentYear.message
                            : null
                        }
                        inputRef={register}
                        className={classes.newUserInput}
                      />
                    )}
                    defaultValue={configuration?.enrollmentYear}
                  />
                </Box>
              </Box>
            ) : null}

            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={3}
              className={classes.screenButtonGrid}
            >
              <Grid item>
                <Button
                  onClick={() => close()}
                  color="primary"
                  className={classes.textButton}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <SpinnerButton
                  text="Salvar"
                  className={classes.newDependentButton}
                />
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
    </>
  );
};

export default EditModal;
