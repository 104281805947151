import { styled, Theme } from '@material-ui/core';
import { primaryMedium } from 'styles/theme';
import { ServiceHeaderProps } from './types';
import { getTitleFontColorByVariant } from './utils';

export const Title = styled('h2')<
  Theme,
  Pick<Required<ServiceHeaderProps>, 'variant'>
>(({ theme, variant }) => {
  return {
    fontWeight: 700,
    fontSize: '24px',
    letterSpacing: '-0.3px',
    color: getTitleFontColorByVariant(variant),
    marginBottom: '3px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  };
});

export const TitleLine = styled('div')(() => {
  return {
    width: '159.5px',
    height: '0px',
    background: primaryMedium,
    border: `1px solid ${primaryMedium}`,
    marginBottom: '7px',
  };
});

export const Subtitle = styled('span')<Theme>(({ theme }) => {
  return {
    fontWeight: 400,
    fontSize: '18px',
    color: '#808080',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  };
});
