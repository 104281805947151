import { REQUIRED_FIELD } from 'texts';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  serviceId: Yup.number().required(REQUIRED_FIELD),
  codesQuantity: Yup.number()
    .required(REQUIRED_FIELD)
    .min(1, 'A quantitade minima é 1.')
    .typeError(REQUIRED_FIELD),
});
