import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { createAlertSlice } from './alert/createAlertSlice';
import { createConfigurationsSlice } from './configurations/createConfigurationsSlice';
import { createSnackbarLoadingSlice } from './loadingSnackbar/createLoadingSnackbarSlice';
import { AppStore } from './types';

export const useAppStore = create(
  immer<AppStore>((...actions) => ({
    alert: createAlertSlice(...actions),
    snackbarLoading: createSnackbarLoadingSlice(...actions),
    configurations: createConfigurationsSlice(...actions),
  })),
);
