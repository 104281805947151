import { useAlert } from 'hooks/Alert';
import { MESSAGE_TYPE } from 'hooks/Alert/types';
import { StatusCodes } from 'http-status-codes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ServiceClassesService from 'services/serviceClassesService';
import ServicesService from 'services/servicesService';
import { error, primaryMain, warning, white } from 'styles/theme';
import { LOAD_DATA_ERROR } from 'texts';

import ServiceClassEnrollmentsService from 'services/serviceClassEnrollments';

import { TableColumnConfig } from 'components/Table/types';

import { ColoredEllipsis } from 'components/ColoredTableCell/styles';
import { DarkTooltip } from 'components/DarkToolTip';
import {
  NestCrudTableFetchData,
  NestCrudTableFetchRequest,
} from 'components/NestCrudTable/types';
import { useAuth } from 'contexts/auth';
import {
  ServiceClassEnrollment,
  ServiceClassEnrollmentStatusEnum,
  ServiceClassEnrollmentStatusLabelsEnum,
} from 'types/serviceClassEnrollment';
import { isTreasurer } from 'utils/isTreasurer';
import { IServiceResponse } from '../../Services/components/ServiceForm/types';
import { IServiceClassResponse } from '../components/ServiceClassForm/types';
import { ServiceClassEnrollmentRowActionsComponent } from './components';
import { TABLE_HEADERS } from './consts';
import { RouteParamsServiceClass } from './types';

export const useServiceClassInfoController = () => {
  // ------------------------------ Page Data ------------------------------
  // ---------- Navigation ----------
  const { id, classId } = useParams<RouteParamsServiceClass>();
  const history = useHistory();

  // ---------- Custom Hooks ----------
  const { openAlert, closeAlert, isShowAlert, alertType, alertMessage } =
    useAlert();
  const { user } = useAuth();

  // ---------- Memos ----------
  const isTreasurerUser = useMemo(() => isTreasurer(user?.roles), [user]);

  // ---------- States ----------
  const [service, setService] = useState<Required<IServiceResponse>>(
    {} as Required<IServiceResponse>,
  );
  const [serviceClass, setServiceClass] = useState<
    Required<IServiceClassResponse>
  >({} as Required<IServiceClassResponse>);
  const [loadingPage, setLoadingPage] = useState(false);

  // ---------- Callbacks ----------
  const handleGoToEditClass = (classId: number) => {
    history.push(`adm-marketplace/services/${id}/service-class/${classId}`);
  };

  const areVacanciesSoldOut = useCallback(
    (spotsFilled: number, spotsNumber: number) => {
      if (spotsFilled < spotsNumber) return false;
      return true;
    },
    [],
  );

  const handleDispatchAlert = useCallback(() => {
    openAlert({
      message: LOAD_DATA_ERROR,
      type: MESSAGE_TYPE.ERROR,
    });
    setTimeout(() => {
      closeAlert();
    }, 2000);
  }, [closeAlert, openAlert]);

  const loadService = useCallback(async () => {
    try {
      setLoadingPage(true);
      const response = await ServicesService.getService(id);
      if (response.status === StatusCodes.OK) {
        setService(response.data);
      } else {
        throw new Error();
      }
    } catch (error) {
      handleDispatchAlert();
    } finally {
      setLoadingPage(false);
    }
  }, [id, handleDispatchAlert]);

  const loadServiceClass = useCallback(async () => {
    try {
      setLoadingPage(true);
      const response = await ServiceClassesService.getServiceClass(classId, {
        join: [['calendars'], ['schoolClasses']],
      });
      if (response.status === StatusCodes.OK) {
        setServiceClass(response.data);
      } else {
        throw new Error();
      }
    } catch (error) {
      handleDispatchAlert();
    } finally {
      setLoadingPage(false);
    }
  }, [classId, handleDispatchAlert]);

  // ---------- Effects ----------
  useEffect(() => {
    loadServiceClass();
  }, [loadServiceClass]);

  useEffect(() => {
    loadService();
  }, [loadService]);

  // FIXME: most of this "Table Data" is duplicated accross the application.
  // Create a CustomTable component that implements <MyTable /> and <TablePagination />
  // with useFilters() custom hook

  // ------------------------------ Table Data ------------------------------
  // ---------- States ----------
  const [loadingTableData, setLoadingTableData] = useState(false);

  // ---------- Memos ----------
  const labelColor = useMemo(
    () => (status: ServiceClassEnrollmentStatusEnum) => {
      switch (status) {
        case ServiceClassEnrollmentStatusEnum.ENROLLED:
          return { backgroundColor: primaryMain, color: white };
        case ServiceClassEnrollmentStatusEnum.PENDING_PAYMENT:
          return { backgroundColor: warning, color: primaryMain };
        case ServiceClassEnrollmentStatusEnum.CANCELLED:
        case ServiceClassEnrollmentStatusEnum.TRANSFERRED:
        default:
          return { backgroundColor: error, color: white };
      }
    },
    [],
  );

  const labelText = useMemo(
    () => (status: ServiceClassEnrollmentStatusEnum) => {
      switch (status) {
        case ServiceClassEnrollmentStatusEnum.CANCELLED:
          return ServiceClassEnrollmentStatusLabelsEnum.CANCELLED;
        case ServiceClassEnrollmentStatusEnum.ENROLLED:
          return ServiceClassEnrollmentStatusLabelsEnum.ENROLLED;
        case ServiceClassEnrollmentStatusEnum.PENDING_PAYMENT:
          return ServiceClassEnrollmentStatusLabelsEnum.PENDING_PAYMENT;
        case ServiceClassEnrollmentStatusEnum.TRANSFERRED:
          return ServiceClassEnrollmentStatusLabelsEnum.TRANSFERRED;
        case ServiceClassEnrollmentStatusEnum.DEFAULTING:
          return ServiceClassEnrollmentStatusLabelsEnum.DEFAULTING;
        default:
          return 'Erro';
      }
    },
    [],
  );

  const tableConfig: TableColumnConfig<ServiceClassEnrollment>[] =
    useMemo(() => {
      const config: TableColumnConfig<ServiceClassEnrollment>[] = [
        {
          headerName: TABLE_HEADERS.STUDENT_NAME,
          getter: 'dependent.name',
          defaultValue: '-',
          customRender: (name, enrollment) => {
            return (
              <>
                {name}
                <DarkTooltip text={`Matrícula ID: ${enrollment.id}`} />
              </>
            );
          },
        },
        {
          headerName: TABLE_HEADERS.RESPONSIBLE,
          getter: 'registration.financialGuardian.user.name',
          defaultValue: '-',
        },
        {
          headerName: TABLE_HEADERS.SCHOOL_CLASS,
          getter: 'registration.schoolClass.name',
          defaultValue: '-',
        },
        {
          headerName: TABLE_HEADERS.ENROLLMENT_STATUS,
          getter: 'status',
          defaultValue: '-',
          customRender: status => {
            return (
              <ColoredEllipsis
                backgroundColor={labelColor(status).backgroundColor}
                textColor={labelColor(status).color}
              >
                {labelText(status)}
              </ColoredEllipsis>
            );
          },
        },
      ];

      if (isTreasurerUser) {
        config.push({
          headerName: TABLE_HEADERS.ACTIONS,
          getter: 'id',
          defaultValue: '-',
          customRender: (_, enrollment) => {
            return (
              <ServiceClassEnrollmentRowActionsComponent
                serviceClassEnrollmentInfo={enrollment}
              />
            );
          },
        });
      }

      return config;
    }, [isTreasurerUser, labelColor, labelText]);

  const loadServiceClassEnrollmentTableData: NestCrudTableFetchData<ServiceClassEnrollment> =
    useCallback(
      async ({ page, perPage }: NestCrudTableFetchRequest) => {
        setLoadingTableData(true);

        const response =
          await ServiceClassEnrollmentsService.getServiceClassEnrollments({
            page,
            limit: perPage,
            fields: ['id', 'status'],
            sort: [{ field: 'dependent.name', order: 'ASC' }],
            join: [
              {
                field: 'payments',
                select: ['id', 'createdAt'],
              },
              {
                field: 'dependent',
                select: ['id', 'name'],
              },
              {
                field: 'registration',
                select: ['id'],
              },
              {
                field: 'registration.schoolClass',
                select: ['id', 'name'],
              },
              {
                field: 'registration.financialGuardian',
                select: ['id'],
              },
              {
                field: 'registration.financialGuardian.user',
                select: ['id', 'name'],
              },
            ],
            search: {
              $and: [
                {
                  serviceClassId: {
                    $eq: classId,
                  },
                },
              ],
            },
          });

        if (response.status === StatusCodes.OK) {
          return {
            data: response.data.data,
            total: response.data.total,
          };
        }
        return {
          data: [],
          total: 0,
        };
      },
      [classId],
    );

  return {
    // Page Data
    handleGoToEditClass,
    areVacanciesSoldOut,
    loadingPage,
    serviceClass,
    service,
    isShowAlert,
    alertType,
    alertMessage,
    closeAlert,

    // Table Data
    loadingTableData,
    tableConfig,
    loadServiceClassEnrollmentTableData,
  };
};
