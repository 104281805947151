import { ReactComponent as CircleErrorIcon } from 'assets/icons/check-circle-error.svg';
import { ReactComponent as CheckCicleIcon } from 'assets/icons/check-circle.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { PAYMENT_METHODS, PAYMENT_STATUS } from 'types/enums';
import { Payment } from 'types/payment';
import { formatDate } from 'utils';

// NOTE: src/pages/Client/MarketplaceOrderPayments/utils/getBillStatusDescription.ts has same logic and descriptions
export const getPaidStatusDescription = (
  payment: Pick<
    Payment,
    'paymentMethodCode' | 'paymentFailReason' | 'status' | 'paymentStartDate'
  >,
) => {
  switch (payment.status) {
    case PAYMENT_STATUS.CONCLUDED:
      return {
        title: 'Recebemos seu pagamento!',
        description: 'O pagamento foi realizado com sucesso.',
        icon: CheckCicleIcon,
      };
    case PAYMENT_STATUS.FUTURE:
      return {
        title: 'Seu pagamento esta agendado!',
        description: `Seu pagamento foi progamado para o dia ${formatDate({
          date: payment.paymentStartDate,
        })}. Avisaremos por email assim que emitirmos a sua fatura.`,
        icon: CheckCicleIcon,
      };
    case PAYMENT_STATUS.DEFAULTING:
      return {
        title: 'Seu pagamento está em atraso!',
        description: `Ainda não idenficamos o  seu pagamento. Caso tenha realizado o pagamento, aguarde até 48 horas para que o sistema atualize.`,
        icon: CircleErrorIcon,
      };
    case PAYMENT_STATUS.CANCELED:
      const cancelReason =
        payment?.paymentFailReason ||
        'Não foi possivel identificar o motivo do cancelamento.';
      return {
        title: 'Seu pagamento foi cancelado!',
        description: `Motivo: ${cancelReason}`,
        icon: CircleErrorIcon,
      };
    case PAYMENT_STATUS.ERROR:
      const reason =
        payment?.paymentFailReason ||
        'Não foi possivel identificar o motivo do erro, revise a forma de pagamento novamente';

      return {
        title: 'Ocorreu um erro com o pagamento!',
        description: `O pagamento não foi realizado devido a um erro interno.\nMotivo: ${reason}.\nNão se preocupe, caso alguma cobrança seja realizada, a mesma será automaticamente estornada em alguns instantes. Por favor, realize a contratação novamente ou entre em contato com a administração.`,
        icon: CircleErrorIcon,
      };
    case PAYMENT_STATUS.PROCESSING:
      const description =
        payment.paymentMethodCode !== PAYMENT_METHODS.CARD
          ? 'Escaneie o código ou copie para fazer o pagamento.'
          : 'Aguarde até o lançamento da fatura em seu cartão.';

      return {
        title: 'Aguardando pagamento.',
        description,
        icon: CheckCicleIcon,
      };

    default:
      return {
        title: 'Seu pagamento ainda não foi processado!',
        description: 'Aguarde alguns minutos ou tente atualizar a página.',
        icon: SearchIcon,
      };
  }
};
