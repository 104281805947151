import { styled, Theme } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { IGuardianTooltipProps } from './types';

export const HelpIcon = styled(HelpOutline)<Theme, IGuardianTooltipProps>(
  ({ theme, marginLeft, marginBottom, marginRight, marginTop }) => ({
    position: 'absolute',
    marginLeft,
    marginBottom,
    marginRight,
    marginTop,
    fontSize: 20,
    [theme.breakpoints.down(1280)]: {
      marginTop: 10,
    },
  }),
);
