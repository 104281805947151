import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import AlertCard from 'components/AlertCard';
import CreditCardModal from 'components/CreditCardModal';
import EmptyListPlaceholder from 'components/EmptyListPlaceholder';
import IncompleteProfileModal from 'components/IncompleteProfileModal';
import PageHeader from 'components/PageHeader';
import { useAuth } from 'contexts/auth';
import { StatusCodes } from 'http-status-codes';
import React, { useCallback, useEffect, useState } from 'react';
import Cards from 'react-credit-cards';
import CreditCardService from 'services/creditCardService';
import useGlobalStyles from 'styles';
import { GENERAL_ERROR, NO_CARDS } from 'texts';
import { Card } from 'types/card';
import { formatCard } from '../PaymentMethod/utils';
import { formatExpiry } from './utils';

const MyCards: React.FC = () => {
  const classes = useGlobalStyles();
  const { user } = useAuth();
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [cardModal, setCardModal] = useState(false);
  const [cards, setCards] = useState<Card[]>([]);
  const [modal, setModal] = useState(false);
  const isCompleted = user?.financialGuardian.isCompleted;

  const loadCards = useCallback(async () => {
    if (user) {
      const { data: response, status } = await CreditCardService.creditCards({
        filter: [
          {
            field: 'financialGuardianId',
            value: user.financialGuardian.id,
            operator: '$eq',
          },
          {
            field: 'isBlocked',
            operator: '$eq',
            value: false,
          },
        ],
      });
      if (status === StatusCodes.OK) {
        setCards(response.data);
      } else {
        setAlert(true);
        setAlertMessage(GENERAL_ERROR);
      }
    }
  }, [user]);

  useEffect(() => {
    loadCards();
  }, [loadCards]);

  const handleClickNewCard = () => {
    if (isCompleted) {
      setCardModal(true);
    } else {
      setModal(true);
    }
  };

  return (
    <Grid
      style={{ padding: 25 }}
      container
      direction="column"
      alignItems="flex-start"
    >
      {user ? (
        <CreditCardModal
          user={user}
          open={cardModal}
          close={() => setCardModal(false)}
          loadCards={loadCards}
        />
      ) : null}
      <AlertCard
        message={alertMessage}
        open={alert}
        close={() => setAlert(false)}
        severity="error"
      />
      <IncompleteProfileModal open={modal} />
      <Grid container item direction="column">
        <PageHeader
          title="Meus cartões"
          subtitle="Adicione ou remova seus cartões cadastrados"
          button={
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickNewCard}
              className={classes.listScreenButton}
            >
              Adicionar cartão
            </Button>
          }
        />
        {cards.length ? (
          <Paper elevation={0} className={classes.screenPaper}>
            <Grid item container direction="column">
              <Grid item container justifyContent="space-between">
                <Typography className={classes.mainScreenText3}>
                  <b>Formas de pagamento</b>
                </Typography>
                <Divider className={classes.divider1} />
                <Grid container justifyContent="flex-start" alignItems="center">
                  {cards.map((card, index) => (
                    <Box key={index} m={3}>
                      <Cards
                        cvc="***"
                        expiry={formatExpiry(card.dueDate)}
                        name={card.cardName}
                        number={formatCard(card.lastFour)}
                        locale={{ valid: 'válido até' }}
                        preview
                        issuer={card.brand}
                      />
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Divider className={classes.divider1} />
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography className={classes.mainScreenText3}>
                  <b>Remover cartão</b>
                </Typography>
              </Grid>
              <Grid item className={classes.publicScreenMargin2}>
                <Typography>
                  Para remover um cartão entre em contato com a escola.
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <EmptyListPlaceholder message={NO_CARDS} />
        )}
      </Grid>
    </Grid>
  );
};

export default MyCards;
