import { MarketplaceSearchResultsQueryParams } from 'pages/Client/NewMarketplace/SearchResults/types';
import { ADMIN_ROUTE } from './consts';

export const buildMarketplaceServiceDetailsRoute = (
  registrationId: number | string,
  serviceId: number | string,
) => `/client-marketplace/${registrationId}/service/${serviceId}`;

export const buildMarketplaceServiceSearchResultsRoute = (
  params: MarketplaceSearchResultsQueryParams,
) => {
  // registrationId is mandatory
  const hasRegistrationId = !!params.registrationId;
  // at least one of either serviceTypeId or searchText must be provided
  const hasValidTextParam = !!params.searchText && params.searchText.length > 0;
  const hasValidServiceTypeParam =
    !!params.serviceTypeId && Number.isInteger(params.serviceTypeId);
  const hasAtLeastOneOptionalParam =
    hasValidTextParam || hasValidServiceTypeParam;

  if (!hasRegistrationId || !hasAtLeastOneOptionalParam)
    return 'client-marketplace';

  return `/client-marketplace/search?registrationId=${params.registrationId}${
    params.serviceTypeId ? `&serviceTypeId=${params.serviceTypeId}` : ''
  }${params.searchText ? `&searchText=${params.searchText}` : ''}`;
};

export const buildMarketplaceContractTemplateUpdateRoute = (
  contractTemplateId: number,
) =>
  ADMIN_ROUTE.CONTRACT_TEMPLATE_UPDATE.replace(':id', `${contractTemplateId}`);
