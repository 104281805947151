import { Button, Divider, Grid, Typography } from '@material-ui/core';
import { EditOutlined } from '@material-ui/icons';
import React from 'react';
import useGlobalStyles from 'styles';
import { ContainerProperties } from './types';

const EditableFormContainer: React.FC<ContainerProperties> = ({
  hidden,
  title,
  editing,
  edit,
  save,
  children,
}) => {
  const classes = useGlobalStyles();

  return (
    <>
      {hidden ? null : (
        <>
          <Grid item container justifyContent="space-between">
            <Typography className={classes.mainScreenText3}>
              <b>{title}</b>
            </Typography>

            {editing ? null : (
              <Button
                className={classes.textButton}
                onClick={() => edit()}
                disabled={editing}
                color="primary"
              >
                <EditOutlined />
                Editar
              </Button>
            )}
          </Grid>

          <Divider className={classes.divider1} />
        </>
      )}

      {children}
    </>
  );
};

export default EditableFormContainer;
