import {
  Grid,
  styled,
  Typography,
  PaperProps as IPaperProps,
} from '@material-ui/core';
import { darkGray } from 'styles/theme';

export const ModalContainerGrid = styled(Grid)(() => {
  return {
    width: '611px',
    height: '416px',
    backgroundColor: 'white',
    padding: '42px 54px',
  };
});

export const ModalTitle = styled(Typography)(() => {
  return {
    marginTop: '30px',
    textAlign: 'center',
    fontSize: '28px',
    lineHeight: '38px',
    color: darkGray,
  };
});

export const ModalSubtitle = styled(ModalTitle)(() => {
  return {
    marginTop: '15px',
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: '28px',
    color: darkGray,
    marginBottom: '25px',
  };
});

export const ButtonGridItem = styled(Grid)(() => {
  return {
    '& .MuiButtonBase-root': {
      marginLeft: 0,
    },
  };
});

export const PaperProps: Partial<IPaperProps> = {
  style: { borderRadius: '20px' },
};
