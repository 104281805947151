import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { buildMarketplaceServiceDetailsRoute } from 'routes/utils';
import { formatBRL } from 'utils';
import { ServiceCardProps } from './types';

export const useServiceCardController = ({
  service,
  selectedRegistrationId,
  serviceType,
}: ServiceCardProps) => {
  const history = useHistory();

  const navigateToServiceDetails = useCallback(() => {
    if (selectedRegistrationId) {
      history.push(
        buildMarketplaceServiceDetailsRoute(selectedRegistrationId, service.id),
      );
    }
  }, [history, selectedRegistrationId, service.id]);

  const serviceCardCaption = useMemo(() => {
    if (serviceType.onlyByInvite) return 'Por convite';

    if (!serviceType.hasAnnuity) return 'Sem anuidade';

    if (service.amount)
      return `${formatBRL(
        service.amount,
      )} /${service.serviceRecurrence.name.toLowerCase()}`;

    return '';
  }, [
    service.amount,
    service.serviceRecurrence.name,
    serviceType.hasAnnuity,
    serviceType.onlyByInvite,
  ]);

  return {
    navigateToServiceDetails,
    serviceCardCaption,
  };
};
