import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export const formatShortDate = (date: string) => {
  date = date.substr(0, 10);
  return format(new Date(date), 'dd/MMM', {
    locale: ptBR,
  }).toLocaleUpperCase();
};

export const paperStyle = (
  itemHeight: number,
  maxItems: number,
  width: number,
) => {
  return {
    padding: '5px 12px',
    boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.12)',
    width,
    maxHeight: itemHeight * maxItems,
    borderRadius: '14px',
  };
};
