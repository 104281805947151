import { DIFFERENT_PASSWORD, REQUIRED_FIELD, WEAK_PASSWORD } from 'texts';
import { testPasswordStrength } from 'utils';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  password: Yup.string().required(REQUIRED_FIELD),
  newPassword: Yup.string()
    .required(REQUIRED_FIELD)
    .test('password-strength', WEAK_PASSWORD, value =>
      testPasswordStrength(value),
    ),
  repeatPassword: Yup.string()
    .required(REQUIRED_FIELD)
    // eslint-disable-next-line func-names
    .test('password-match', DIFFERENT_PASSWORD, function (value) {
      return this.parent.newPassword === value;
    }),
});
