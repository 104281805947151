import { Box, Paper, Typography } from '@material-ui/core';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import KidLeftImg from 'assets/kidLeft.png';
import KidRightImg from 'assets/kidRight.png';
import React from 'react';
import useGlobalStyles from 'styles';

const SuccessPaper: React.FC = () => {
  const classes = useGlobalStyles();

  return (
    <Paper className={classes.allAcceptedPaper}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box>
          <CheckCircleOutlineOutlined
            color="secondary"
            style={{ fontSize: 120, marginBottom: 25 }}
          />
        </Box>
        <Box>
          <Typography style={{ fontSize: 24 }}>
            <b>Tudo certo!</b>
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontSize: 20 }}>
            Todas as <b>cláusulas contratuais</b> foram <b>aceitas.</b>
          </Typography>
        </Box>
      </Box>
      <img
        className={classes.allAcceptedImage}
        style={{ left: -35 }}
        src={KidLeftImg}
        alt="criança1"
      />
      <img
        className={classes.allAcceptedImage}
        style={{ right: -18 }}
        src={KidRightImg}
        alt="criança2"
      />
    </Paper>
  );
};

export default SuccessPaper;
