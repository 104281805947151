import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import useGlobalStyles from 'styles';
import { InfoBoxProperties } from './types';

const InfoBox: React.FC<InfoBoxProperties> = ({ title, text }) => {
  const classes = useGlobalStyles();

  return (
    <Grid item xs={12} sm={6} md={3} xl={2} container direction="column">
      <Grid item>
        <Typography className={classes.confirmScreenText1}>
          <b>{title}</b>
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.confirmScreenText1}>{text}</Typography>
      </Grid>
    </Grid>
  );
};

export default InfoBox;
