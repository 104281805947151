import {
  NestCrudTableFetchData,
  NestCrudTableFetchRequest,
} from 'components/NestCrudTable/types';
import { StatusCodes } from 'http-status-codes';
import { ChangeEvent, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ADMIN_ROUTE } from 'routes/consts';
import ContractTemplatesService from 'services/contractTemplates';
import { ContractTemplates } from 'types/contractTemplates';
import { useDebouncedCallback } from 'use-debounce';

export const useContractTemplatesController = () => {
  const navigation = useHistory();
  // TODO: show loading when fetching data
  const [loadingTableData, setLoadingTableData] = useState(true);
  const [searchTemplateName, setSearchTemplateName] = useState('');

  const loadTemplatesTableData: NestCrudTableFetchData<ContractTemplates> =
    useCallback(
      async ({ page, perPage }: NestCrudTableFetchRequest) => {
        setLoadingTableData(true);
        const response = await ContractTemplatesService.getTemplates({
          page,
          limit: perPage,
          search: {
            name: { $contL: searchTemplateName },
          },
        });

        setLoadingTableData(false);

        if (response.status === StatusCodes.OK) {
          return {
            data: response.data.data,
            total: response.data.total,
          };
        }
        return {
          data: [],
          total: 0,
        };
      },
      [searchTemplateName],
    );

  const navigateToCreateConctractTemplate = () => {
    navigation.push(ADMIN_ROUTE.CONTRACT_TEMPLATE_CREATION);
  };

  const searchTemplateFieldDebounce = useDebouncedCallback(
    (e: ChangeEvent<HTMLInputElement>) => setSearchTemplateName(e.target.value),
    300,
  );

  return {
    loadTemplatesTableData,
    navigateToCreateConctractTemplate,
    searchTemplateFieldDebounce,
  };
};
