import { makeStyles } from '@material-ui/core';
import { primaryDark } from 'styles/theme';

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: 'white',
    padding: '10px 20px',
    borderRadius: '8px',
    boxShadow: '2px 2px 5px #0000001A',
    border: `1px solid ${primaryDark}2A`,
  },
  subtitle: {
    marginLeft: '10px',
    flex: 1,
  },
}));
