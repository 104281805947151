import { REQUIRED_FIELD } from 'texts';
import * as Yup from 'yup';

export const SCHEDULE_DAYS_ERROR_MESSAGE = 'Adicione um horário para cada dia.';
export const SCHEDULE_INTERVAL_ERROR_MESSAGE = 'Intervalo de horário inválido.';
export const ERROR_ON_SUBMIT_CREATION = 'Não foi possível criar um serviço.';
export const ERROR_ON_SUBMIT_UPDATE = 'Não foi possível editar o serviço.';
export const ERROR_NOT_FILLED_IMAGES = 'Todas as imagens são obrigatórias.';
export const ERROR_ACCESS_CODE_NOT_FILLED =
  'Código de convite obrigatório para atividade por convite.';
export const ERROR_NOT_ACCESS_CODE =
  'Código de convite obrigatório apenas para atividade por convite.';
export const ERROR_SERVICE_WITH_VALUE =
  'Valor total obrigatório para serviços com anuidade.';
export const ERROR_SERVICE_WITHOUT_VALUE =
  'Valor total apenas para serviços com anuidade.';
export const ERROR_SERVICE_RECURRENCE =
  'Recorrência obrigatória para serviços com custo.';
export const ERROR_SERVICE_RECURRENCE_AMOUNT =
  'Valor total obrigatório para serviços com recorrência.';
export const ERROR_SERVICE_INSTALLMENTS =
  'Serviço com custo deve possuir quantidade de parcelas.';
export const ERROR_SERVICE_INSTALLMENTS_WITHOUT_VALUE =
  'Adicione um valor total ao serviço.';
// FIXME: após refatoração completa dos formulários, checar mensagens
// de validação e remover as não utilizadas
export const ERROR_SERVICE_PRICE_MANDATORY =
  'Valor total obrigatório para serviços não isentos.';
export const ERROR_SERVICE_RECURRENCE_MANDATORY =
  'Recorrência obrigatória para serviços não isentos';
export const ERROR_SERVICE_INSTALLMENTS_MANDATORY =
  'Serviços não isentos devem deve possuir quantidade de parcelas.';
export const ERROR_SERVICE_PRICE_NOT_ZERO =
  'O valor total de serviços isentos deve ser 0.';

const generalFieldsSchema = {
  name: Yup.string().required(REQUIRED_FIELD),
  publicationStatus: Yup.string().required(REQUIRED_FIELD),
  type: Yup.string().required(REQUIRED_FIELD),
  category: Yup.array()
    .of(Yup.string())
    .required(REQUIRED_FIELD)
    .min(1, REQUIRED_FIELD),
  schoolClasses: Yup.array()
    .of(Yup.string())
    .required(REQUIRED_FIELD)
    .min(1, REQUIRED_FIELD),
  currencyRecurrence: Yup.string().required(REQUIRED_FIELD),
  // totalOfSpots: Yup.number().required(REQUIRED_FIELD).typeError(REQUIRED_FIELD),
  // annualDuratonInHours: Yup.number()
  //   .required(REQUIRED_FIELD)
  //   .typeError(REQUIRED_FIELD),
};

// const calendarFieldsSchema = {
//   startDate: Yup.string()
//     .required(REQUIRED_FIELD)
//     .test('is date valid', INVALID_FIELD, value => {
//       return isStringDateValid(value).isDateValid;
//     }),
//   endDate: Yup.string()
//     .required(REQUIRED_FIELD)
//     .test(
//       'is date valid',
//       INVALID_FIELD,
//       value => isStringDateValid(value).isDateValid,
//     )
//     .test('is interval valid', INVALID_INTERVAL, (value, context) => {
//       if (context.parent.startDate === value) {
//         return true;
//       }
//       return isDateIntervalValid(context.parent.startDate, value);
//     }),
//   days: Yup.mixed()
//     .when('isArray', {
//       is: Array.isArray,
//       then: Yup.array().of(Yup.string()),
//     })
//     .required(REQUIRED_FIELD),
//   scheduleCanVaryToEveryDay: Yup.boolean().required(REQUIRED_FIELD),
// };

const financialFieldsSchema = {
  pedagogicSubjectId: Yup.string(),
  amount: Yup.string(),
  installments: Yup.number()
    .min(1, 'Mínimo de 1 parcela')
    .max(12, 'Máximo de 12 parcelas'),
  registrationTaxValue: Yup.string(),
  partnerId: Yup.string().required(REQUIRED_FIELD),
  contractTemplateId: Yup.string().required(REQUIRED_FIELD),
  invoiceServiceCode: Yup.string().required(REQUIRED_FIELD),
};

const serviceAccessSchema = {
  inviteCode: Yup.string(),
  earlyAccess: Yup.string().oneOf(['true', 'false']),
};

const extraFieldsSchema = {
  description: Yup.string(),
  avatar: Yup.string(),
  cover: Yup.string(),
  images: Yup.mixed().when('isArray', {
    is: Array.isArray,
    then: Yup.array().of(Yup.string()),
  }),
  color: Yup.string().nullable(),
  youtubeVideo: Yup.string(),
};

export const schema = Yup.object({
  ...generalFieldsSchema,
  // ...calendarFieldsSchema,
  ...financialFieldsSchema,
  ...serviceAccessSchema,
  ...extraFieldsSchema,
});
