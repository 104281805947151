import { isAfter } from 'date-fns';
import DiscountsService from 'services/discountsService';
import { PRICES_TABLE } from 'texts';
import { Card } from 'types/card';
import { Coupon } from 'types/coupon';
import { FORM_OF_PAYMENT, PAYMENT_METHODS } from 'types/enums';
import { Guardian } from 'types/guardian';
import { formatBRL, unformatValueMask } from 'utils';
import * as Yup from 'yup';
import { currentYear } from '../NewRegistration/utils';
import { PaymentCardOption } from './types';

export enum PAYMENT_STEPS {
  SELECTION = 'SELECTION',
  CONFIRMATION = 'CONFIRMATION',
  CONTRACT_CLAUSES = 'CONTRACT_CLAUSES',
}

export const validationSchema = Yup.object({
  subscriptions: Yup.array().of(
    Yup.object().shape({
      value: Yup.string(),
    }),
  ),
});

export function calcInstallmentsValue(value: number, installments: number) {
  return `${installments}x de ${formatBRL(value / installments)}`;
}

const roundToTwo = (value: number) => Number(value.toFixed(2));

const formatResponseDiscount = (
  subscriptionsSum: number,
  totalValue: number,
  percent?: number | null,
  value?: number | null,
) => {
  if (percent) {
    const result = roundToTwo(totalValue - totalValue * (percent / 100));
    return { currentValue: result < 0 ? 0 : result, subscriptionsSum };
  } else if (value) {
    const result = totalValue - value;
    return { currentValue: result < 0 ? 0 : result, subscriptionsSum };
  }
  return false;
};

const calculateDiscountForEachMethod = (
  totalValue: number,
  coupon: Coupon,
  paymentMethod: PAYMENT_METHODS,
  subscriptionsSum: number,
) => {
  switch (paymentMethod) {
    case PAYMENT_METHODS.PIX:
      return formatResponseDiscount(
        subscriptionsSum,
        totalValue,
        coupon.pix_percent,
        coupon.pix_value,
      );
    case PAYMENT_METHODS.CARD:
      return formatResponseDiscount(
        subscriptionsSum,
        totalValue,
        coupon.credit_card_percent,
        coupon.credit_card_value,
      );
    case PAYMENT_METHODS.BANK_SLIP:
      return formatResponseDiscount(
        subscriptionsSum,
        totalValue,
        coupon.bank_slip_percent,
        coupon.bank_slip_value,
      );

    default:
      break;
  }
};

export async function calcRemainingValue(
  subscriptions: PaymentCardOption[],
  totalValue: number,
  coupon: Coupon | null,
  paymentMethod: PAYMENT_METHODS,
) {
  /**
   * calculates remaining value based on the total registration price, coupon discount and value inputs
   */
  let subscriptionsSum = subscriptions.reduce(
    (sum: number, subscription: PaymentCardOption) => {
      if (subscription.value) {
        return sum + unformatValueMask(subscription.value);
      } else {
        return 0;
      }
    },
    0,
  );
  subscriptionsSum = roundToTwo(subscriptionsSum);

  if (coupon) {
    const appliedCouponDiscount = calculateDiscountForEachMethod(
      totalValue,
      coupon,
      paymentMethod,
      subscriptionsSum,
    );

    if (appliedCouponDiscount) {
      return appliedCouponDiscount;
    }
  }

  const { data: discount } = await DiscountsService.applyDiscount({
    registrationValue: totalValue,
    paymentMethodCode: paymentMethod,
    formOfPayments:
      subscriptions[0].installments > 1
        ? FORM_OF_PAYMENT.INSTALLMENTS
        : FORM_OF_PAYMENT.CASH_PAYMENT,
  });

  return { currentValue: discount, subscriptionsSum };
}

const currentDay = new Date();
const currentMonth = new Date().getMonth();
const schoolYearStartDay = new Date(currentYear, 1, 15);

export function calcProportionalPrice(
  isVeteran: boolean,
  registrationValue: number | undefined,
) {
  /**
   * Essa função não está sendo utilizada,
   * foi repassado que a forma de calcular o valor proporcional será com dias e não meses,
   * e inicialmente será feita fora do sistema pela administração da escola.
   * Mas decidi deixar a função caso voltem atrás
   */
  if (!registrationValue) return 0;

  if (isAfter(currentDay, schoolYearStartDay) && !isVeteran) {
    // em caso de matrícula após o início das aulas, pagar preço proporcional (exceto veteranos)
    const valuePerMonth = registrationValue / 12;

    return valuePerMonth * (12 - currentMonth);
  } else {
    // para outros casos, pagar preço integral
    return registrationValue;
  }
}

export const installmentsArray = (
  registrationYear: number | undefined,
  paymentMethod: PAYMENT_METHODS,
) => {
  const array = [
    {
      label: 'Selecione as parcelas',
      value: 0,
    },
    {
      label: 'À vista',
      value: 1,
    },
    ...Array(11)
      .fill('')
      .map((_, i) => ({
        label: `${i + 2}x`,
        value: i + 2,
      })),
  ];

  if (!registrationYear) return [];

  if (paymentMethod === PAYMENT_METHODS.PIX) {
    return [array[0], array[1]];
  }

  if (isAfter(registrationYear, currentYear)) {
    // em caso de matrícula para o ano seguinte, só é possível pagar à vista ou parcelado em 12 vezes
    return [array[0], array[1], array[12]];
  }

  if (paymentMethod === PAYMENT_METHODS.BANK_SLIP) {
    /**
     * em caso de pagamento com boleto e não caindo na condição anterior,
     * só será possível pagar à vista ou parcelando o equivalente a quantidade de meses até dezembro
     */
    const maxInstallment =
      registrationYear === currentYear ? 12 - currentMonth : 12;

    if (maxInstallment === 1) return [array[0], array[1]];
    return [array[0], array[1], array[maxInstallment]];
  }

  // em outros casos, só é possível parcelar até dezembro
  // o array possui 13 indíces, por isso é necessário diminuir 1 para ficar igual a quantidade de meses até dezembro
  return array.filter((item, i) => i < array.length - currentMonth - 1);
};

export function formatCard(fourDigits: string | undefined) {
  if (fourDigits) {
    return `**** **** **** ${fourDigits}`;
  }
  return '';
}

export const findCard = (card: Card, guardians?: Guardian[]) => {
  if (guardians)
    return guardians.find(guardian => {
      if (guardian.id === card.guardian.id) return card;
    });
  return null;
};

export const handleOpenPriceTable = () => {
  window.open(PRICES_TABLE, 'name', 'width=800,height=600');
};
