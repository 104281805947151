import { FormDialog } from 'components/dialogs/FormDialog';
import { ControllerInputText } from 'components/Forms/ControllerInputText';
import { EditConfigInvoiceServiceCode } from './types';
import { useEditConfigInvoiceServiceCodeController } from './useEditConfigInvoiceServiceCodeController';

export const EditConfigInvoiceServiceCodeModal = ({
  editFormRef,
  schoolClass,
  onClose,
}: EditConfigInvoiceServiceCode) => {
  const { handleSubmit, errors, register, control, isLoading, onSubmit } =
    useEditConfigInvoiceServiceCodeController({
      editFormRef,
      schoolClass,
      onClose,
    });

  return (
    <FormDialog
      ref={editFormRef}
      headerTitle="Editar código de serviço da nota fiscal"
      primaryButton={{
        text: 'Salvar',
        loading: isLoading,
        onClick: handleSubmit(onSubmit),
      }}
      secondaryButton={{
        text: 'Voltar',
        disabled: isLoading,
        onClick: onClose,
      }}
    >
      <ControllerInputText
        name="invoiceServiceCode"
        label="Código de serviço"
        errors={errors}
        defaultValue={schoolClass?.invoiceServiceCode}
        register={register}
        control={control}
        inputRef={register}
      />
    </FormDialog>
  );
};
