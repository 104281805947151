import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { AddAPhotoOutlined } from '@material-ui/icons';
import { StatusCodes } from 'http-status-codes';
import BlankPic from 'assets/blankProfileImg.png';
import useGlobalStyles from 'styles';
import { findDocument } from 'pages/Client/ViewDependent/utils';
import { DOCUMENT_TYPE } from 'types/enums';
import { UploadedDocument } from 'types/uploadedDocument';
import RegistrationService from 'services/registrationService';
import { useAuth } from 'contexts/auth';
import { GENERAL_ERROR } from 'texts';
import { DependentBoxData } from './types';
import { useStyles } from './styles';

const DependentDataBox: React.FC<DependentBoxData> = ({
  dependentName,
  registration,
  editable,
  light,
  overlayed,
  showText,
}) => {
  const classes = useGlobalStyles();
  const styles = useStyles();
  const { loading } = useAuth();
  const { id, documents, schoolGrade } = registration;
  const savedPicture = findDocument(documents, DOCUMENT_TYPE.IMAGEM_PERFIL);
  const [uploadedPicture, setUploadedPicture] = useState<
    UploadedDocument | undefined
  >(savedPicture);

  const uploadImage = async (file: File | null) => {
    if (file) {
      const response = await RegistrationService.uploadPicture(id, file);

      if (response.status === StatusCodes.OK) {
        setUploadedPicture(response.data);
      } else {
        // TODO: Substituir por toast quando o design estiver pronto
        alert(GENERAL_ERROR);
      }
    }
  };

  return (
    <Box
      className={
        overlayed ? classes.dependentPaperBox : classes.dependentPaperBoxAlt
      }
    >
      <MenuItem
        className={
          overlayed ? classes.dependentPaperInfo : classes.dependentPaperInfoAlt
        }
        disableRipple
        style={{
          backgroundColor: 'transparent',
        }}
      >
        <img
          className={classes.dependentPaperImg}
          style={{ marginLeft: overlayed ? 0 : 15 }}
          src={uploadedPicture ? uploadedPicture.path : BlankPic}
          alt="Foto do aluno"
        />

        {showText ? (
          <Box>
            <Typography
              className={
                overlayed
                  ? classes.dependentPaperNameAlt
                  : classes.dependentPaperName
              }
            >
              <b>{dependentName}</b>
            </Typography>
            <Typography className={classes.listScreenText}>
              {schoolGrade}
            </Typography>
          </Box>
        ) : null}
      </MenuItem>
      {editable ? (
        loading ? (
          <CircularProgress
            className={classes.imgUploadProgress}
            size={20}
            color="secondary"
          />
        ) : (
          <Box width={107} display="flex" justifyContent="center">
            <input
              className={styles.hiddenInput}
              id="image-upload"
              type="file"
              accept="image/png, image/jpeg"
              onChange={e =>
                uploadImage(e.target.files ? e.target.files[0] : null)
              }
            />
            <label htmlFor="image-upload">
              <Button
                variant="contained"
                className={classes.imgUploadIcon}
                color={light ? 'secondary' : 'default'}
                size="small"
                component="span"
              >
                <AddAPhotoOutlined color="primary" fontSize="small" />
              </Button>
            </label>
          </Box>
        )
      ) : null}
    </Box>
  );
};

export default DependentDataBox;
