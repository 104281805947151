import CustomSearchFilters from 'components/CustomSearchFilters';
import React from 'react';
import { invoiceFiltersConfig } from './constants';
import { InvoiceFiltersProps } from './types';

const InvoiceFilters: React.FC<InvoiceFiltersProps> = ({ onFilter }) => {
  return (
    <CustomSearchFilters
      filtersConfig={invoiceFiltersConfig}
      onFiltersUpdated={onFilter}
    />
  );
};

export default InvoiceFilters;
