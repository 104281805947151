import { MESSAGE_TYPE } from 'hooks/Alert/types';
import { useAlert } from 'hooks/Alert';
import {
  SubscriptionsChange,
  SubscriptionsService,
} from 'pages/Client/MarketplacePaymentCheckout/types';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { REMOVED_ITEM } from 'texts/messages/cart';

import { Dependent } from 'types/dependent';
import { PAYMENT_METHODS } from 'types/enums';
import { calculateServiceClassMaxInstallments } from 'utils/calculateServiceClassMaxInstallments';
import {
  AddServiceToCartFn,
  CartLocalStorageItems,
  EKeysOfLocalStorage,
  ICartItem,
} from './types';

export const useCartController = () => {
  // Custom Hooks
  const { openAlert, closeAlert, alertMessage, alertType, isShowAlert } =
    useAlert();

  // States
  const [cartItems, setCartItems] = useState<ICartItem[]>([]);
  // <DEPRECATED>
  // USE [cartItems, setCartItems] INSTEAD
  const [actualCartItem, setActualCartItem] = useState({} as ICartItem);
  const [cartItemAlreadyAdded, setCartItemAlreadyAdded] = useState(false);
  const [actualDependent, setActualDependent] = useState<Dependent>(
    {} as Dependent,
  );
  const [subscriptions, setSubscriptions] = useState<SubscriptionsService[]>(
    [],
  );
  // </DEPRECATED>

  const totalCartValue = useMemo(() => {
    const totalValue = cartItems.reduce((accumulator, currentValue) => {
      const amount = currentValue.service?.amount ?? 0;
      const registrationTaxValue =
        currentValue.service?.registrationTaxValue ?? 0;

      return accumulator + amount + registrationTaxValue;
    }, 0);

    return totalValue;
  }, [cartItems]);

  const totalItemsCart = useMemo(() => {
    return cartItems.length;
  }, [cartItems]);

  // Callbacks
  const saveItemsOnLocalStorage = useCallback(() => {
    const cartLocalStorageItems: CartLocalStorageItems = {
      cartItems,
      actualDependent,
      cartTotal: totalCartValue,
      subscriptions,
    };
    localStorage.setItem(
      EKeysOfLocalStorage.CART_STORAGE,
      JSON.stringify(cartLocalStorageItems),
    );
  }, [actualDependent, cartItems, totalCartValue, subscriptions]);

  const getItemsOnLocalStorage = useCallback(() => {
    const cartLocalStorageItems = localStorage.getItem(
      EKeysOfLocalStorage.CART_STORAGE,
    );

    const defaultCart = {
      cartItems: [],
      actualDependent: {},
      cartTotal: 0,
      subscriptions: [],
    };

    const { cartItems, actualDependent, subscriptions }: CartLocalStorageItems =
      cartLocalStorageItems ? JSON.parse(cartLocalStorageItems) : defaultCart;

    setCartItems(cartItems);
    setActualDependent(actualDependent);
    setSubscriptions(subscriptions);
  }, []);

  const removeItemsOnLocalStorage = () => {
    localStorage.removeItem('actualDependent');
    localStorage.removeItem('cartItems');
    localStorage.removeItem('cartTotal');
    localStorage.removeItem('subscriptions');
    localStorage.removeItem(EKeysOfLocalStorage.CART_STORAGE);
  };

  const handleSetActualDependent = (dependent: Dependent) => {
    setActualDependent(actualDependent =>
      dependent !== actualDependent ? dependent : actualDependent,
    );
  };

  const handleAddServiceToCart: AddServiceToCartFn = useCallback(params => {
    setCartItems(prev => {
      const maxInstallments = calculateServiceClassMaxInstallments({
        servicePaymentLimitDate: new Date(params.service.paymentLimitDate),
        serviceMaxNumberInstallments:
          params?.service?.numberOfInstallments || 1,
        currentDate: new Date(),
      });

      return [
        ...prev,
        {
          ...params,
          serviceAccessCode: params.serviceAccessCode || null,
          serviceInviteCode: params.serviceInviteCode || null,
          payment: {
            cardId: null,
            installments: maxInstallments,
            paymentMethodCode: PAYMENT_METHODS.CARD,
            couponName: null,
            serviceAccessCode: params.serviceAccessCode || null,
            serviceInviteCode: params.serviceInviteCode || null,
          },
          maxInstallments,
        },
      ];
    });
  }, []);

  // FIXME: Por hora não faz sentido isso ser um request pro backend
  // // Essa função só é chamada quando a tela 'payment-checkout' é chamada
  // const updateAllCartItemsMaxInstallments = useCallback(async () => {
  //   if (cartItems.some(({ maxInstallments }) => maxInstallments === 0)) {
  //     const serviceClassIdsArray = cartItems.map(
  //       ({ selectedServiceClass }) => selectedServiceClass.id,
  //     );

  //     try {
  //       const { data: maxInstallmentsArray } =
  //         await ServiceClassesService.getServiceClassesMaximumInstallments(
  //           serviceClassIdsArray,
  //         );

  //       setCartItems(prev =>
  //         prev.map((prevItem, index) => {
  //           return {
  //             ...prevItem,
  //             maxInstallments: maxInstallmentsArray[index],
  //           };
  //         }),
  //       );
  //     } catch (error) {
  //       openAlert({
  //         message: LOAD_DATA_ERROR,
  //         type: MESSAGE_TYPE.ERROR,
  //       });
  //     }
  //   }
  // }, [cartItems, openAlert]);

  // const handleAddItemsToCart = useCallback(() => {
  //   setCartItems(prev => [
  //     ...prev,
  //     { ...actualCartItem, dependent: actualDependent },
  //   ]);
  //   setSubscriptions([
  //     ...subscriptions,
  //     {
  //       cardId: 0,
  //       installments: 0,
  //       paymentMethodCode: PAYMENT_METHODS.CARD,
  //       serviceId: actualCartItem.id,
  //     },
  //   ]);
  // }, [actualDependent, actualCartItem, subscriptions, setSubscriptions]);

  const handleResetCartItems = useCallback(() => {
    setCartItems([]);
  }, []);

  const handleResetCartContextData = () => {
    handleResetCartItems();
    setSubscriptions([]);
    setCartItemAlreadyAdded(false);
    removeItemsOnLocalStorage();
    setActualDependent({} as Dependent);
  };

  const handleRemoveServiceFromCart = useCallback(
    (serviceId: number, registrationId: number) => {
      setCartItems(state =>
        state.filter(
          cartItem =>
            !(
              cartItem.service.id === serviceId &&
              cartItem.registrationId === registrationId
            ),
        ),
      );

      openAlert({
        message: REMOVED_ITEM,
        type: MESSAGE_TYPE.SUCCESS,
      });

      setTimeout(() => {
        closeAlert();
      }, 3000);
    },
    [openAlert, closeAlert],
  );

  // "data" will only have values for things that have changed.
  // e.g. if data.installments is not undefined it means that
  // the selected amount of installments has changed
  const handleChangeSubscriptionData = (
    serviceId: number,
    registrationId: number,
    data: SubscriptionsChange,
  ) => {
    setCartItems(prev =>
      prev.map(prevItem => {
        if (
          prevItem.service.id !== serviceId ||
          prevItem.registrationId !== registrationId
        ) {
          return prevItem;
        }

        if (
          data.paymentMethod &&
          prevItem.payment.paymentMethodCode !== data.paymentMethod
        ) {
          // Payment method changed. Reset other payment data to default.
          let defaultInstallments = prevItem.maxInstallments;
          if (data.paymentMethod === PAYMENT_METHODS.PIX)
            defaultInstallments = 1;

          return {
            ...prevItem,
            payment: {
              ...prevItem.payment,
              cardId: data.cardId || null,
              installments: data.installments || defaultInstallments,
              paymentMethodCode: data.paymentMethod,
              couponName: data.couponName || null,
            },
          };
        }

        return {
          ...prevItem,
          payment: {
            ...prevItem.payment,
            cardId: data.cardId || prevItem.payment.cardId,
            installments: data.installments || prevItem.payment.installments,
            couponName: data.couponName || prevItem.payment.couponName,
          },
        };
      }),
    );
  };

  const isSubscriptionInputCorrect = useMemo(
    () =>
      !cartItems.some(({ payment }) => {
        const isInstallmentsZero = payment.installments === 0;
        const isCardIdInvalid = !payment.cardId || payment.cardId === 0;

        if (payment.paymentMethodCode === PAYMENT_METHODS.CARD) {
          return isCardIdInvalid || isInstallmentsZero;
        }

        return isInstallmentsZero;
      }),
    [cartItems],
  );

  const getServiceFromCart = useCallback(
    (serviceId: number, registrationId: number) => {
      const serviceCartItem = cartItems.find(
        cartItem =>
          cartItem.service.id === serviceId &&
          cartItem.registrationId === registrationId,
      );

      return serviceCartItem;
    },
    [cartItems],
  );

  // Effects
  useEffect(() => {
    getItemsOnLocalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    saveItemsOnLocalStorage();
  }, [cartItems, saveItemsOnLocalStorage]);

  // useEffect(() => {
  //   if (cartItems.length && actualCartItem && actualDependent) {
  //     const findItem = cartItems.find(
  //       cartItem =>
  //         cartItem.id === actualCartItem.id &&
  //         cartItem.dependent?.id === actualCartItem.dependent?.id,
  //     );

  //     if (findItem) {
  //       setCartItemAlreadyAdded(true);
  //     } else {
  //       setCartItemAlreadyAdded(false);
  //     }
  //   }
  // }, [actualCartItem, actualDependent, actualDependent.id, cartItems]);

  return {
    cartItems,
    closeAlert,
    alertMessage,
    alertType,
    isShowAlert,
    totalCartValue,
    totalItemsCart,
    // handleAddItemsToCart,
    handleAddServiceToCart,
    getServiceFromCart,
    handleRemoveServiceFromCart,
    handleRemoveAllItemsFromCart: handleResetCartItems,
    actualDependent,
    handleSetActualDependent,
    // handleSetActualCartItem,
    cartItemAlreadyAdded,
    actualCartItem,
    subscriptions,
    setSubscriptions,
    handleResetCartItems,
    handleResetCartContextData,
    handleChangeSubscriptionData,
    isSubscriptionInputCorrect,
  };
};
