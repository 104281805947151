import {
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  PersonOutline,
} from '@material-ui/icons';
import React, { MouseEvent, useState } from 'react';
import { paperStyle } from '../Notifications/utils';
import { useStyles } from './styles';
import { DropdownProps } from './types';

const ITEM_HEIGHT = 35.5;

const Dropdown: React.FC<DropdownProps> = ({
  menuItems,
  text,
  maxItems = 5,
  isAdmin,
  isMobile,
  dark,
  disabled,
}) => {
  const styles = useStyles({ isAdmin, dark })();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleMenuItemAction(action: void) {
    handleClose();
    return action;
  }

  return (
    <>
      <Button
        disabled={disabled}
        className={styles.dropdown}
        variant="contained"
        aria-controls="my-dropdown"
        aria-haspopup="true"
        onClick={handleOpen}
        disableElevation
      >
        {isMobile ? <PersonOutline className={styles.dropdownIcon} /> : text}
        {open ? (
          <KeyboardArrowUp color="secondary" className={styles.dropDownArrow} />
        ) : (
          <KeyboardArrowDown
            color="secondary"
            className={styles.dropDownArrow}
          />
        )}
      </Button>
      <Menu
        id="my-dropdown"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: paperStyle(ITEM_HEIGHT, maxItems, 220),
        }}
        disableScrollLock
      >
        {menuItems &&
          menuItems.map((item, index) => (
            <MenuItem
              key={index}
              disabled={item.disabled}
              onClick={() => handleMenuItemAction(item.action())}
            >
              {item.icon ? <ListItemIcon>{item.icon}</ListItemIcon> : null}
              <Typography className={styles.menuText}>{item.title}</Typography>
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default Dropdown;
