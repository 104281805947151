import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { CustomInputText } from '../CustomInputText';
import { ControllerInputTextProps } from './types';

export const ControllerInputText = ({
  name,
  label,
  errors,
  register,
  control,
  defaultValue,
  helperText,
  maxRows,
  ...textInputProps
}: ControllerInputTextProps) => {
  const renderHelperText = useMemo(() => {
    if (errors[name]) {
      return errors[name].message;
    }
    return helperText;
  }, [errors, helperText, name]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      as={({ value, onChange }) => {
        return (
          <CustomInputText
            {...textInputProps}
            name={name}
            label={label}
            value={value}
            defaultValue={value}
            onChange={onChange}
            error={!!errors[name]}
            helperText={renderHelperText}
            inputRef={register}
            maxRows={maxRows}
          />
        );
      }}
    />
  );
};
