import { Box, makeStyles, styled } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  errorMessage: {
    color: 'red',
    margin: '10px 0px',
  },
  link: {
    width: 'max-content',
  },
}));

export const Container = styled(Box)(({ theme }) => ({
  maxWidth: 800,
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '100vh',
  padding: '20px 30px',
  [theme.breakpoints.down(1024)]: {
    justifyContent: 'flex-start',
  },
}));
