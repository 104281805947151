import Dropdown from 'components/HeaderBar/Dropdown';
import { NestCrudTable } from 'components/NestCrudTable';
import Scaffold from 'components/Scaffold';
import { SchoolClassDetails } from 'types/schoolClassDetails';
import { EditConfigInvoiceServiceCodeModal } from './components/EditConfigInvoiceServiceCode';
import { EditSchoolClassDetailsModal } from './components/EditSchoolClassDetails';
import { SchoolClassCreateDetailsModal } from './components/SchoolClassCreateDetails';
import { useSchooClassDetailController } from './controller';

export const TuitionDetail = () => {
  const {
    schoolClassId,
    schoolClassDetails,
    loadSchoolClassDetail,
    tableConfig,
    handleCloseCreateModal,
    modalCreateTuitionOpen,
    tableRef,
    editSchoolClassDetailsModalRef,
    handleCloseSchoolClassDetailModal,
    selectedClassDetail,
    menuItems,
    handleCloseConfigInvoiceServiceCode,
    editConfigInvoiceServiceCodeModalRef,
  } = useSchooClassDetailController();

  return (
    <Scaffold
      rawTitle={<b>{schoolClassDetails?.name || ''}</b>}
      rawSubtitle="Veja os detalhes da anuidade"
      headerButton={
        <Dropdown
          dark
          text="Ações"
          menuItems={menuItems}
          disabled={!menuItems.length}
          maxItems={7}
        />
      }
    >
      <NestCrudTable<SchoolClassDetails>
        ref={tableRef}
        emptyTableMessage="Não há dados para serem exibidos."
        onFetchData={loadSchoolClassDetail}
        tableConfig={tableConfig}
      />
      <SchoolClassCreateDetailsModal
        close={handleCloseCreateModal}
        open={modalCreateTuitionOpen}
        schoolClassName={schoolClassDetails?.name || ''}
        schoolClassId={schoolClassId}
        afterCreate={() => {
          tableRef.current?.fetchData();
        }}
      />
      <EditSchoolClassDetailsModal
        afterSubmit={() => {
          tableRef.current?.fetchData();
        }}
        editFormRef={editSchoolClassDetailsModalRef}
        schoolClassDetails={selectedClassDetail}
        onClose={handleCloseSchoolClassDetailModal}
      />
      <EditConfigInvoiceServiceCodeModal
        editFormRef={editConfigInvoiceServiceCodeModalRef}
        schoolClass={schoolClassDetails}
        onClose={handleCloseConfigInvoiceServiceCode}
      />
    </Scaffold>
  );
};
