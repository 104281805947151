import { CircularProgress, IconButton } from '@material-ui/core';
import React, { useMemo } from 'react';
import { StyledConfirmButton } from './styles';
import { CustomButtonProps } from './types';

// FIXME: preserve button size when isLoading changes
export const CustomButton: React.FC<CustomButtonProps> = ({
  onClick,
  disabled,
  loading,
  children,
  variant = 'primary',
  textVariant = 'normal',
  text,
  className,
  isIconButton,
}) => {
  const isDisabled = useMemo(() => {
    if (disabled || loading) return true;
    return disabled || loading;
  }, [disabled, loading]);

  const renderButtonContent = () =>
    loading ? (
      <CircularProgress size={30} className="button-spinner" />
    ) : (
      text || children
    );

  if (isIconButton) {
    return (
      <IconButton disabled={isDisabled} onClick={onClick}>
        {renderButtonContent()}
      </IconButton>
    );
  }

  return (
    <StyledConfirmButton
      className={className || ''}
      variant={variant}
      textVariant={textVariant}
      onClick={onClick}
      disabled={isDisabled}
      isLoading={!!loading}
    >
      {renderButtonContent()}
    </StyledConfirmButton>
  );
};
