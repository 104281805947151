import { addYears, format } from 'date-fns';
import { isValidCPF } from 'pages/Admin/NewGuardian/utils';
import {
  INVALID_CPF,
  INVALID_FIELD,
  INVALID_REG_NUMBER,
  NO_NUMBERS,
  REQUIRED_FIELD,
} from 'texts';
import {
  hasNoNumbers,
  isBirthDateValid,
  isRegistrationNumberValid,
  trimWhiteSpaces,
} from 'utils';
import { dependentAddressSchema } from 'utils/schemas/addressSchema';
import * as Yup from 'yup';

// Used for update dependent
export const dependentDataValidationSchema = Yup.object({
  editing: Yup.boolean(),
  hasGuardianDocuments: Yup.boolean(),
  hasGuardianAddress: Yup.boolean(),
  name: Yup.string()
    .test('no numbers', NO_NUMBERS, value => hasNoNumbers(value))
    .required(REQUIRED_FIELD),
  birthplace: Yup.string()
    .test('no numbers', NO_NUMBERS, value => hasNoNumbers(value))
    .required(REQUIRED_FIELD),
  birthday: Yup.string()
    .test('is not empty', REQUIRED_FIELD, value => {
      // regex para remover "/" e "_"; isso é necessário pois estes sempre estarão presentes
      const rawDate = value?.replace(/\/|_/gm, '');
      return !!rawDate?.length;
    })
    .test('is valid', INVALID_FIELD, value => isBirthDateValid(value)),
  mother: Yup.string()
    .test('no numbers', NO_NUMBERS, value => hasNoNumbers(value))
    .required(REQUIRED_FIELD),
  cpf: Yup.mixed().when('hasGuardianDocuments', {
    is: false,
    then: Yup.string()
      .test('is valid', INVALID_CPF, value => isValidCPF(value || ''))
      .transform(value => value.replace(/\D+/g, ''))
      .required(REQUIRED_FIELD),
  }),
  address: dependentAddressSchema,
  previousSchool: Yup.string()
    .test('no numbers', NO_NUMBERS, value => hasNoNumbers(value))
    .required(REQUIRED_FIELD),
  number: Yup.string()
    .test('is valid', INVALID_REG_NUMBER, value =>
      isRegistrationNumberValid(value || ''),
    )
    .transform(value => trimWhiteSpaces(value)),
});

export const validationSchema = Yup.object({
  editing: Yup.boolean(),
  hasGuardianDocuments: Yup.boolean(),
  hasGuardianAddress: Yup.boolean(),
  name: Yup.string()
    .test('no numbers', NO_NUMBERS, value => hasNoNumbers(value))
    .required(REQUIRED_FIELD),
  birthplace: Yup.string()
    .test('no numbers', NO_NUMBERS, value => hasNoNumbers(value))
    .required(REQUIRED_FIELD),
  birthday: Yup.string()
    .test('is not empty', REQUIRED_FIELD, value => {
      // regex para remover "/" e "_"; isso é necessário pois estes sempre estarão presentes
      const rawDate = value?.replace(/\/|_/gm, '');
      return !!rawDate?.length;
    })
    .test('is valid', INVALID_FIELD, value => isBirthDateValid(value)),
  mother: Yup.string()
    .test('no numbers', NO_NUMBERS, value => hasNoNumbers(value))
    .required(REQUIRED_FIELD),
  cpf: Yup.mixed().when('hasGuardianDocuments', {
    is: false,
    then: Yup.string()
      .test('is valid', INVALID_CPF, value => isValidCPF(value || ''))
      .transform(value => value.replace(/\D+/g, ''))
      .required(REQUIRED_FIELD),
  }),
  // rg: Yup.mixed().when('hasGuardianDocuments', {
  //   is: false,
  //   then: Yup.object({
  //     rgNumber: Yup.string().required(REQUIRED_FIELD),
  //     emitter: Yup.string()
  //       .required(REQUIRED_FIELD)
  //       .test('no numbers', NO_NUMBERS, value => hasNoNumbers(value)),
  //   }),
  // }),
  address: dependentAddressSchema,
  previousSchool: Yup.string()
    .test('no numbers', NO_NUMBERS, value => hasNoNumbers(value))
    .required(REQUIRED_FIELD),
  number: Yup.string()
    .test('is valid', INVALID_REG_NUMBER, value =>
      isRegistrationNumberValid(value || ''),
    )
    .transform(value => trimWhiteSpaces(value)),
  schoolClassDetailsId: Yup.string().required(REQUIRED_FIELD),
});

export const allToUpperCase = (object: object) => {
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => {
      if (!noCaseChange.includes(key) && typeof value === 'string') {
        return [key, value.toUpperCase()];
      }
      return [key, value];
    }),
  );
};

const noCaseChange = [
  'email',
  'password',
  'schoolGrade',
  'civilStatus',
  'status',
];

export const currentYear = new Date().getFullYear();

export const nextYear = Number(format(addYears(new Date(), 1), 'yyyy'));
