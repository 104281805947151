import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';

import EmptyListPlaceholder from 'components/EmptyListPlaceholder';
import PageHeader from 'components/PageHeader';
import { useStyles } from 'pages/Admin/Contracts/ContractsTables/styles';
import React, { ReactElement, useCallback } from 'react';
import useGlobalStyles from 'styles';
import { ROWS_PER_PAGE } from 'utils';
import LoadingTable from './loadingTable';
import { MyTableRow } from './myTableRow';
import { TableProps } from './types';
/**
 * In order to pass a type T to this component, use it as follows:
 *
 * > <MyTable\<YourTypeHere\>
 * >  data={arrayOfYourType}
 * >  ...
 * > />
 *
 * Your type must contain an property named "id" and it must be unique
 */
export function MyTable<T extends { id: number | string }>({
  data,
  tableConfig,
  isLoading,
  emptyTableMessage,
  tableHeader,
  tablePaginationConfig,
  renderFilterComponent,
}: TableProps<T>): ReactElement {
  const classes = useGlobalStyles();
  const styles = useStyles();

  const handlePageChange = useCallback(
    (_e: unknown, newPage: number) => {
      tablePaginationConfig?.onChangePage?.(newPage);
    },
    [tablePaginationConfig],
  );

  const handleRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const rowsPerPage = Number(event.target.value);
      tablePaginationConfig?.onChangeRowsPerPage?.(rowsPerPage);
    },
    [tablePaginationConfig],
  );

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <Grid>
          <LoadingTable />
        </Grid>
      );
    }

    if (!data.length) {
      return (
        <Grid>
          <EmptyListPlaceholder message={emptyTableMessage} />
        </Grid>
      );
    }

    return (
      <Paper elevation={0} className={styles.paper}>
        <Table className={styles.table}>
          <TableHead>
            <TableRow>
              {tableConfig?.map(headerConfigItem => (
                <TableCell
                  key={headerConfigItem.headerName}
                  align={headerConfigItem?.align}
                >
                  <Typography className={classes.listScreenHeaderText1}>
                    {headerConfigItem.headerName}
                  </Typography>
                </TableCell>
              )) || null}
            </TableRow>
          </TableHead>
          <TableBody className={styles.tableBody}>
            <Box height={20} />
            {data?.map(item => (
              <MyTableRow
                key={item.id}
                data={item}
                tableConfig={tableConfig}
                emptyTableMessage={emptyTableMessage}
              />
            )) || null}
          </TableBody>
        </Table>
      </Paper>
    );
  };

  return (
    <Grid>
      {tableHeader ? (
        <PageHeader
          rawTitle={tableHeader.rawTitle}
          rawSubtitle={tableHeader.rawSubtitle}
          button={tableHeader.button}
        />
      ) : null}

      {renderFilterComponent ? renderFilterComponent() : null}

      {renderTableBody()}

      {tablePaginationConfig ? (
        <TablePagination
          component="div"
          count={tablePaginationConfig.total}
          page={tablePaginationConfig?.page}
          labelRowsPerPage="Itens por página"
          onPageChange={handlePageChange}
          rowsPerPage={tablePaginationConfig.perPage}
          rowsPerPageOptions={ROWS_PER_PAGE}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      ) : null}
    </Grid>
  );
}
