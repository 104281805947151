import { CircularProgress } from '@material-ui/core';
import Scaffold from 'components/Scaffold';
import { EmptyPlaceholderContainer } from '../components/EmptyPlaceholderContainer';
import { MarketplaceFilteredSearch } from '../components/MarketplaceFilteredSearch';
import { ServiceCardsContainer } from '../components/ServiceCardsContainer';
import { Container } from './styles';
import { useMarketplaceSearchResultsController } from './useMarketplaceSearchResultsController';

export const MarketplaceSearchResults = () => {
  const {
    // General
    hasLoadedRequiredData,
    loadingServices,
    pageHeader,
    selectedRegistrationId,

    // Filtered search
    registrations,
    selectedRegistrationSchoolClassId,
    doesGuardianHaveDependents,
    filterByServiceTypeId,
    handleOnSelectRegistrationId,

    // Services
    resultingServices,
  } = useMarketplaceSearchResultsController();

  return (
    <Scaffold
      loading={!hasLoadedRequiredData}
      rawTitle={<b>{pageHeader.title}</b>}
      rawSubtitle={pageHeader.subtitle}
    >
      {!doesGuardianHaveDependents ? (
        <EmptyPlaceholderContainer>
          <span>
            Não há matriculas ativas disponiveis para a contratação de serviços
          </span>
        </EmptyPlaceholderContainer>
      ) : (
        <MarketplaceFilteredSearch
          registrations={registrations}
          selectedRegistrationSchoolClassId={selectedRegistrationSchoolClassId}
          selectedRegistrationId={selectedRegistrationId}
          onSelectRegistrationId={handleOnSelectRegistrationId}
          filterByServiceTypeId={filterByServiceTypeId}
        />
      )}

      {loadingServices ? (
        <EmptyPlaceholderContainer>
          <CircularProgress size={100} />
        </EmptyPlaceholderContainer>
      ) : (
        <Container>
          <ServiceCardsContainer
            services={resultingServices}
            selectedRegistrationId={selectedRegistrationId}
            displayMode="grid"
          />
        </Container>
      )}
    </Scaffold>
  );
};
