import React from 'react';
import { Grid } from '@material-ui/core';

import * as Styled from './styles';
import { HeaderProperties } from './types';

export const PublicPageHeader = ({
  title,
  titleBoldComplement,
  subtitle,
  tooltip,
  testId,
}: HeaderProperties) => {
  return (
    <Styled.ContainerGrid item container data-test={testId}>
      <Grid item>
        <Styled.Title>
          {title} <b>{titleBoldComplement}</b>
          {tooltip || null}
        </Styled.Title>
      </Grid>
      <Grid item>
        <Styled.Subtitle>{subtitle}</Styled.Subtitle>
      </Grid>
    </Styled.ContainerGrid>
  );
};
