import { makeStyles } from '@material-ui/core';
import { TableStyle } from './types';

export const useStyles = ({ minWidth = 600 }: TableStyle) =>
  makeStyles(() => ({
    paper: {
      borderRadius: 8,
      padding: 20,
      overflowX: 'auto',
      marginBottom: 20,
    },
    table: {
      minWidth,
    },
  }));
