import {
  popUpDetailBodyComponentPros,
  popUpDetailDateComponentPros,
  popUpDetailTitleComponentPros,
  timeComponentProps,
} from './types';

export const timeComponent = ({
  classes,
  body,
  color,
  raw,
  start,
  end,
  title,
}: timeComponentProps) => {
  const startTime = new Date(start).toLocaleTimeString('pt-br').split(':');
  const endTime = new Date(end).toLocaleTimeString('pt-br').split(':');

  return `
        <div class=${classes.containerTime}>
          <p class=${classes.time}>${startTime[0]}:${startTime[1]} - ${
    endTime[0]
  }:${endTime[1]}</p>
          <div class=${
            classes.divider
          } style="border: 1px solid ${color}"></div>
          <div class=${classes.containerTimeTitle}>
            <p class=${classes.title}>
            ${title}
            </p>
            ${raw ? `<img src=${raw} class=${classes.raw}></img>` : ``}
          </div>  
          <p class=${classes.body}>
            ${body}
          </p>
        </div>
      `;
};

export const popUpDetailTitleComponent = ({
  classes,
  title,
  raw,
}: popUpDetailTitleComponentPros) => {
  return `
    <div class=${classes.containerTitle}>
      <p class=${classes.title} >
        ${title}
      </p>
       ${raw ? `<img src=${raw} class=${classes.raw}></img>` : ``}
    </div>
  `;
};

export const popUpDetailDateComponent = ({
  classes,
  color,
  start,
  end,
}: popUpDetailDateComponentPros) => {
  const startTime = new Date(start).toLocaleTimeString('pt-br');
  const endTime = new Date(end).toLocaleTimeString('pt-br');

  return `
    <div class=${classes.divider} style="border: 1px solid ${color}"></div>
    <p class=${classes.time}>${startTime.toString()} - ${endTime.toString()}</p>
  `;
};

export const popUpDetailBodyComponent = ({
  body,
  classes,
  detailIcon,
  editIcon,
}: popUpDetailBodyComponentPros) => {
  return `
    <p class=${classes.body}>
      ${body}
    </p>
    <div class=${classes.containerDetailBody}>
      <a href="#" class=${classes.lineHeight}>
      <img src=${editIcon} class=${classes.editIcon}></img>
      </a>
      <a href="#" class=${classes.containerLink}>
        <p class=${classes.seeDetailText}>Ver detalhes</p>
        <img src=${detailIcon} class=${classes.nextIcon}></img>
      </a>
    </div>
  `;
};
