import { Grid } from '@material-ui/core';
import { ResponsiveInputGridItemProps } from './types';

export const ResponsiveInputGridItem = ({
  children,
  ...gridProps
}: ResponsiveInputGridItemProps) => {
  return (
    <Grid item xs={12} sm={6} md={4} xl={3} {...gridProps}>
      {children}
    </Grid>
  );
};
