import { useEffect } from 'react';
import {
  DecodedValueMap,
  QueryParamConfigMap,
  useQueryParams,
} from 'use-query-params';
import { useLocalStorage } from '..';
import { LocalStorageKeys } from '../types';

export const useQueryParamsWithLocalStorage = <
  QPCMap extends QueryParamConfigMap,
>(
  key: LocalStorageKeys,
  paramConfigMap: QPCMap,
): [DecodedValueMap<QPCMap>, (value: DecodedValueMap<QPCMap>) => void] => {
  const [queryParams, setQueryParams] = useQueryParams<QPCMap>(paramConfigMap);

  const [storedQueryParams, setStoredQueryParams] = useLocalStorage<
    DecodedValueMap<QPCMap>
  >(key, queryParams);

  useEffect(() => {
    setQueryParams(storedQueryParams);
  }, [setQueryParams, storedQueryParams]);

  return [storedQueryParams, setStoredQueryParams];
};
