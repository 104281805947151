import { useCallback } from 'react';
import { CategorizedServiceControllerProps } from './types';

export const useCategorizedServicesController = ({
  onClickSeeAll,
  serviceTypeId,
}: CategorizedServiceControllerProps) => {
  const handleOnClick = useCallback(() => {
    onClickSeeAll(serviceTypeId);
  }, [onClickSeeAll, serviceTypeId]);

  return {
    handleOnClick,
  };
};
