import { CreateQueryParams } from '@nestjsx/crud-request';
import { INewRegistration } from 'pages/Client/NewRegistration/types';
import {
  api,
  getAxiosResponseMessageErrorOrDefault,
  getWithFilter,
  patchWithFilter,
} from 'services/apiService';
import {
  DOCUMENT_TYPE,
  INTERVIEW_STATUS,
  REGISTRATION_STATUS,
} from 'types/enums';
import { Registration } from 'types/registration';
import {
  CurrentActiveRegistrations,
  RegistrationWithCouponApplied,
} from './types/registrationService.types';

export default class RegistrationService {
  static async getCurrentActiveRegistrations() {
    try {
      return await api.get<CurrentActiveRegistrations>('registrations/current');
    } catch (error: any) {
      return error.response;
    }
  }

  static async registrations() {
    try {
      return await api.get('registrations');
    } catch (error: any) {
      return error.response;
    }
  }

  static async registration(id: string | number, query?: CreateQueryParams) {
    try {
      return await getWithFilter<Registration>(`registrations/${id}`, query);
    } catch (error: any) {
      return error.response;
    }
  }

  static async filterRegistrations(query?: CreateQueryParams) {
    try {
      return await getWithFilter<Registration>('/registrations', query);
    } catch (err: any) {
      return err.response;
    }
  }

  static async getFilteredCsv(query?: CreateQueryParams) {
    try {
      return await getWithFilter('/registrations/export-csv', query, {
        responseType: 'blob',
      });
    } catch (err: any) {
      return err.response;
    }
  }

  static async newRegistration(newRegistration: INewRegistration) {
    try {
      return await api.post('registrations', newRegistration);
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }
  /**
   * TODO: This is a temporary function to control registration status while
   * contract and signature aren't ready yet. It'll need to be changed later.
   */

  static async changeRegistrationStatus(
    id: number,
    status: REGISTRATION_STATUS,
  ) {
    try {
      return await api.patch(`registrations/${id}/update-status`, { status });
    } catch (error: any) {
      return error.response;
    }
  }

  static async changeRegistrationData(
    id: number,
    registration: Partial<Registration>,
  ) {
    try {
      return await api.patch(`registrations/${id}`, registration);
    } catch (error: any) {
      return error.response;
    }
  }

  static async changeInterviewStatus(id: number, status: INTERVIEW_STATUS) {
    try {
      return await patchWithFilter(
        `registrations/${id}`,
        {
          interview: {
            status,
          },
        },
        { join: [['interview']] },
      );
    } catch (error: any) {
      return error.response;
    }
  }

  static async uploadPicture(regId: number, picture: File) {
    const data = new FormData();
    data.append('name', 'documento');
    data.append('type', DOCUMENT_TYPE.IMAGEM_PERFIL);
    data.append('file', picture);

    try {
      return await api.put(`registrations/${regId}/file`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error: any) {
      return error.response;
    }
  }

  static async deleteDocument(regId: number, fileId: number) {
    try {
      return await api.delete(`registrations/${regId}/file/${fileId}`);
    } catch (error: any) {
      return error.response;
    }
  }

  static async applyCoupon(id: number, couponName: string) {
    try {
      return await api.patch<RegistrationWithCouponApplied>(
        `/registrations/${id}/apply-coupon`,
        {
          couponName,
        },
      );
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }

  static async removeCoupon(id: number) {
    try {
      return await api.patch(`/registrations/${id}/remove-coupon`);
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }

  static async reRegistrationEligibility(id: number) {
    try {
      return await api.get(
        `/registrations/${id}/re-register/verify-eligibility`,
      );
    } catch (error: any) {
      return error.response;
    }
  }

  static async startReRegistration(
    dependentId: number,
    params: Pick<Registration, 'schoolClassDetailsId'>,
  ) {
    try {
      return await api.post<Registration>(
        `/registrations/dependent/${dependentId}/re-register`,
        params,
      );
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }

  static async updateSchoolClass(
    registrationId: number,
    schoolClassId: number,
  ) {
    return api.patch(`registrations/${registrationId}/school-class`, {
      schoolClassId,
    });
  }

  static async deleteRegistration(id: number) {
    try {
      return await api.delete(`registrations/${id}`);
    } catch (error: any) {
      return error.response;
    }
  }

  static async count(year: number) {
    try {
      return await api.get(`/registrations/count/${year}`);
    } catch (error: any) {
      return error.response;
    }
  }

  static async setTransferredStatus(id: number, refund: boolean) {
    try {
      return await api.post(`registrations/${id}/transfer`, { refund });
    } catch (error: any) {
      return error.response;
    }
  }

  static async blockAndUnblockRegistration(
    registrationId: number,
    isBlocked: boolean,
  ) {
    try {
      return await api.patch(`registrations/block-and-unblock`, {
        registrationId,
        isBlocked,
      });
    } catch (error: any) {
      return error.response;
    }
  }

  static async startRegistrationEnrollmentFlow(
    registrationId: number | string,
  ) {
    try {
      return await api.patch<Registration>(
        `registrations/${registrationId}/start-enrollment`,
      );
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }

  static async CheckOlderBrotherEnrolled(
    registrationId: number,
    isReregistration: Boolean,
  ) {
    try {
      return await api.get(
        `registrations/${registrationId}/check-has-older-brother-enrolled?isReregistration=${isReregistration}`,
      );
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }
}
