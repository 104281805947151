/* eslint-disable @typescript-eslint/ban-ts-comment */
import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { CustomButton } from 'components/CustomButton';
import { NestCrudTable } from 'components/NestCrudTable';
import Scaffold from 'components/Scaffold';
import React from 'react';
import { ContractTemplates } from 'types/contractTemplates';
import { tableConfig } from './consts';
import { useContractTemplatesController } from './controller';
import { useStyles } from './styles';

// TODO: alert sucess, error etc
const ContractTemplatesPage: React.FC = () => {
  const {
    loadTemplatesTableData,
    navigateToCreateConctractTemplate,
    searchTemplateFieldDebounce,
  } = useContractTemplatesController();

  const styles = useStyles();

  return (
    <Scaffold
      rawTitle={
        <>
          Marketplace - <b>Contratos</b>
        </>
      }
      rawSubtitle="Gerencie os contratos do marketplace"
      headerButton={
        <CustomButton onClick={navigateToCreateConctractTemplate}>
          Criar contrato
        </CustomButton>
      }
    >
      <TextField
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder="Buscar"
        onChange={searchTemplateFieldDebounce}
        className={styles.search}
      />
      <NestCrudTable<ContractTemplates>
        onFetchData={loadTemplatesTableData}
        tableConfig={tableConfig}
        emptyTableMessage="Não existem templates de contratos criados."
      />
    </Scaffold>
  );
};

export default ContractTemplatesPage;
