import { styled } from '@material-ui/core';

export const ServicesContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}));

export const HomeContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '50px',
}));

export const ServicesMajorContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '70px',
}));
