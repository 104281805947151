import { styled, Theme } from '@material-ui/core';
import { mediumGray } from 'styles/theme';

export const Image = styled('img')<Theme>(({ theme }) => {
  return {
    width: '262.44px',
    height: '144.36px',
    borderRadius: 8,
    objectFit: 'cover',
    objectPosition: 'center',
    [theme.breakpoints.down('md')]: {
      objectFit: 'contain',
      width: '100%',
      height: '453px',
    },
  };
});

export const Iframe = styled('iframe')(() => {
  return {
    width: '100%',
    height: '453px',
    borderRadius: 8,
  };
});

export const ContainerActions = styled('div')(() => {
  return {
    fontSize: '14px',
    color: mediumGray,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 28,
    marginTop: 25,
    '& p': {
      margin: '0 30px',
    },
  };
});
