import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';
import SpinnerIconButton from 'components/SpinnerIconButton';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useGlobalStyles from 'styles';
import { TABLE_HEADERS } from 'utils/tableHeaders';
import { TableProperties } from './types';
import { useStyles } from './styles';

const GuardiansTable: React.FC<TableProperties> = ({
  financialGuardian,
  legalGuardians,
  removeFn,
  goToNew,
}) => {
  const classes = useGlobalStyles();
  const styles = useStyles();
  const history = useHistory();

  const headers = [
    TABLE_HEADERS.NAME,
    TABLE_HEADERS.TYPE,
    TABLE_HEADERS.RELATION_OR_KINSHIP,
  ];

  const goToDetails = () => {
    history.push({
      pathname: `/adm-users/${financialGuardian.user.id}`,
    });
  };

  const removeGuardian = (guardianId: number) => () => {
    if (removeFn) {
      removeFn(guardianId);
    }
  };

  return (
    <Grid item xs={12}>
      <Paper elevation={0} className={styles.paper}>
        <Table className={styles.table}>
          <TableHead>
            <TableRow>
              {headers.map(header => (
                <TableCell key={header}>
                  <Typography className={classes.listScreenHeaderText1}>
                    {header}
                  </Typography>
                </TableCell>
              ))}
              {removeFn ? (
                <TableCell align="right">
                  <Typography className={classes.listScreenHeaderText1}>
                    AÇÕES
                  </Typography>
                </TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{financialGuardian.user.name}</TableCell>
              <TableCell>Legal e Financeiro</TableCell>
              <TableCell>{financialGuardian.relationship}</TableCell>
              {removeFn ? (
                <TableCell align="right">
                  <SpinnerIconButton
                    action={goToDetails}
                    icon={<EditOutlined color="primary" />}
                  />
                </TableCell>
              ) : null}
            </TableRow>
            {legalGuardians.map(guardian => (
              <TableRow key={guardian.id}>
                <TableCell>{guardian.name}</TableCell>
                <TableCell> Legal</TableCell>
                <TableCell>{guardian.relationship}</TableCell>
                {removeFn ? (
                  <TableCell align="right">
                    <IconButton
                      onClick={removeGuardian(guardian.id)}
                      color="default"
                    >
                      <DeleteOutline color="primary" />
                    </IconButton>
                  </TableCell>
                ) : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {goToNew ? (
          <Grid container item alignItems="center" justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={() => goToNew()}
                color="primary"
                className={classes.textButton}
              >
                Adicionar responsável legal
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </Paper>
    </Grid>
  );
};

export default GuardiansTable;
