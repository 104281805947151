import Grid from '@material-ui/core/Grid';
import { ControllerInputText } from 'components/Forms/ControllerInputText';
import { ControllerSelectInput } from 'components/Forms/ControllerSelectInput/ControllerSelectInput';
import { formatBRL } from 'utils';

import { IGeneraFormProps } from '../../types';
import { useFinancialDataFormController } from './controller';

export const FinancialDataForm = ({
  control,
  register,
  errors,
  serviceToUpdate,
}: IGeneraFormProps) => {
  const { MenuProps, partners, contractTemplates } =
    useFinancialDataFormController();

  return (
    <Grid container spacing={2} md={11}>
      <Grid item md={4}>
        <ControllerInputText
          name="amount"
          control={control}
          defaultValue={formatBRL(serviceToUpdate?.amount || 0)}
          label="Valor total"
          errors={errors}
          register={register}
          type="currency"
        />
      </Grid>
      <Grid item md={4}>
        <ControllerInputText
          name="registrationTaxValue"
          control={control}
          defaultValue={formatBRL(serviceToUpdate?.registrationTaxValue || 0)}
          label="Taxa de matrícula"
          errors={errors}
          register={register}
          type="currency"
        />
      </Grid>
      <Grid item md={4}>
        <ControllerInputText
          name="installments"
          control={control}
          defaultValue={serviceToUpdate?.numberOfInstallments || 1}
          label="Quantidade de parcelas"
          errors={errors}
          register={register}
          type="number"
        />
      </Grid>
      <Grid item md={4}>
        <ControllerSelectInput
          name="partnerId"
          label="Quem oferece o serviço"
          errors={errors}
          register={register}
          control={control}
          options={(partners || []).map(partner => ({
            id: partner.id,
            label: partner.name,
          }))}
          defaultValue={serviceToUpdate?.partnerId}
          selectProps={{ MenuProps }}
        />
      </Grid>
      <Grid item md={4}>
        <ControllerSelectInput
          name="contractTemplateId"
          label="Template de contrato"
          control={control}
          defaultValue={serviceToUpdate?.contractTemplateId}
          errors={errors}
          options={contractTemplates.map(type => ({
            id: type.id,
            label: type.name,
          }))}
          register={register}
          selectProps={{ MenuProps }}
        />
      </Grid>
      <Grid item md={4}>
        <ControllerInputText
          name="invoiceServiceCode"
          control={control}
          defaultValue={serviceToUpdate?.invoiceServiceCode}
          label="Código de serviço"
          errors={errors}
          register={register}
        />
      </Grid>
    </Grid>
  );
};
