import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  CheckCircleOutlineOutlined,
  DeleteOutline,
  EditOutlined,
} from '@material-ui/icons';
import { CondOperator } from '@nestjsx/crud-request';
import AddressForm from 'components/AddressForm';
import AlertCard from 'components/AlertCard';
import ConfirmModal from 'components/ConfirmModal';
import EditableFormContainer from 'components/EditableFormContainer';
import EmptyListPlaceholder from 'components/EmptyListPlaceholder';
import GuardianForm from 'components/GuardianForm';
import Modal from 'components/Modal';
import PageHeader from 'components/PageHeader';
import SpinnerButton from 'components/SpinnerButton';
import UserForm from 'components/UserForm';
import { useAuth } from 'contexts/auth';
import { useSearchCEP } from 'hooks/searchCEP';
import { useAlert } from 'hooks/Alert';
import { StatusCodes } from 'http-status-codes';
import { omit, pick } from 'lodash';
import { ISubmitUser } from 'pages/Client/Profile/types';
import { userFields, validationSchema } from 'pages/Client/Profile/utils';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useRouteMatch } from 'react-router-dom';
import AuthService from 'services/authService';
import CreditCardService from 'services/creditCardService';
import RegistrationService from 'services/registrationService';
import UserService from 'services/userService';
import useGlobalStyles from 'styles';
import {
  ADM_NO_REGISTRATIONS,
  CARD_BLOCK_SUCCESS,
  CARD_DELETE_SUCCESS,
  CARD_UNBLOCK_SUCCESS,
  DEPENDENT_REGISTRATIONS_NOT_FOUND,
  EDIT_SUCCESSFUL,
  GENERAL_ERROR,
} from 'texts';
import { MESSAGE_TYPE } from 'hooks/Alert/types';
import { Card } from 'types/card';
import { CIVIL_STATUS, DEPENDENT_RELATIONSHIP, USER_ROLES } from 'types/enums';
import { FinancialGuardian } from 'types/financialGuardian';
import { Registration } from 'types/registration';
import { RouteParams } from 'types/routeParams';
import { User } from 'types/user';
import CardsList from './CardsList';
import { useStyles } from './styles';
import {
  BLOCK_MESSAGE,
  BLOCK_TITLE,
  DELETE_MESSAGE,
  DELETE_TITLE,
} from './texts';
import { ConfirmModalData, UpdateCard } from './types';
import { LINK_BUTTON, LINK_COPIED } from './utils';

const UserDetails: React.FC = () => {
  const classes = useGlobalStyles();
  const styles = useStyles();
  const history = useHistory();
  const { alertMessage, isShowAlert, openAlert, closeAlert } = useAlert();
  const { user: admin } = useAuth();
  const {
    params: { id: userId },
  } = useRouteMatch<RouteParams>();
  const [user, setUser] = useState<User | null>(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [registrations, setRegistrations] = useState<Registration[]>([]);
  const [editing, setEditing] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [confirmModal, setConfirmModal] = useState(false);
  const [modalData, setModalData] = useState<ConfirmModalData>({
    title: '',
    message: '',
  });
  const [updateCard, setUpdateCard] = useState<UpdateCard>({
    id: 0,
    isBlocked: false,
    isDeleting: false,
  });
  const [cards, setCards] = useState<Card[]>([]);
  const [civilStatus, setCivilStatus] = useState<CIVIL_STATUS | undefined>(
    user?.financialGuardian?.civilStatus,
  );
  const [relationship, setRelationship] = useState<
    DEPENDENT_RELATIONSHIP | undefined
  >(user?.financialGuardian?.relationship);
  const [selectedRole, setSelectedRole] = useState(
    user?.roles[0] || USER_ROLES.ADMIN,
  );

  const [btnText, setBtnText] = useState(LINK_BUTTON);

  const [selectedRegistration, setSelectedRegistration] =
    useState<Registration | null>();
  const [deleteModal, setDeleteModal] = useState(false);
  const [
    isTheLastRegistrationOfTheSelectedDependent,
    setIsTheLastRegistrationOfTheSelectedDependent,
  ] = useState(false);

  const { handleSubmit, errors, register, control, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onBlur',
  });

  const {
    address,
    debouncedSearchCEP,
    cepError,
    cepErrorCount,
    clearCepError,
  } = useSearchCEP(user?.financialGuardian?.address);

  useEffect(() => {
    if (user) {
      register('isAdmin');
      register('isCompleted');
      setValue('isAdmin', user.isAdmin);
      setValue('isCompleted', isCompleted);
      if (!user.isAdmin) register('civilStatus');
      if (!user.isAdmin) register('relationship');
      if (!user.isAdmin && isCompleted) {
        setRelationship(user.financialGuardian.relationship);
        setCivilStatus(user.financialGuardian.civilStatus);
      }
    }
  }, [user, register, setValue, isCompleted]);

  const loadUser = useCallback(async () => {
    const response = await UserService.user(Number(userId), {
      join: [['financialGuardian'], ['dependents']],
    });
    if (response.status === StatusCodes.OK) {
      setUser(response.data);
      setIsCompleted(
        response.data.isAdmin
          ? true
          : !!response.data.financialGuardian.isCompleted,
      );
    } else {
      openAlert({
        message: GENERAL_ERROR,
        type: MESSAGE_TYPE.ERROR,
      });
    }
  }, [openAlert, userId]);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  const loadRegistrations = useCallback(async () => {
    if (user) {
      const { data: response, status } =
        await RegistrationService.filterRegistrations({
          filter: {
            field: 'financialGuardianId',
            operator: '$eq',
            value: user?.financialGuardian.id,
          },
          join: [['dependent'], ['schoolClass']],
        });
      if (status === StatusCodes.OK) {
        setRegistrations(response.data);
      }
    }
  }, [user]);

  useEffect(() => {
    if (!user?.isAdmin) {
      loadRegistrations();
    }
  }, [loadRegistrations, user]);

  const onSubmit = async (data: ISubmitUser) => {
    data.address = { ...data.address, ...address };
    const userData = pick(data, userFields) as Partial<User>;
    const guardianData = omit(data, [
      ...userFields,
      'isAdmin',
      'isCompleted',
    ]) as Partial<FinancialGuardian>;

    updateUser(userData, !user?.isAdmin ? guardianData : undefined);
  };

  const updateUser = async (
    userData: Partial<ISubmitUser>,
    guardianData?: Partial<FinancialGuardian>,
  ) => {
    if (guardianData) guardianData.id = user?.financialGuardian?.id;
    if (user?.isAdmin) userData.roles = [selectedRole];

    const response = await UserService.updateUserAndGuardian(
      Number(userId),
      userData,
      guardianData,
    );
    if (response.status === StatusCodes.OK) {
      loadUser();
      setEditing(!editing);
      setModal(true);
      setModalMessage(EDIT_SUCCESSFUL);
    } else {
      openAlert({
        message: response.data.message,
        type: MESSAGE_TYPE.ERROR,
      });
    }
  };

  useEffect(() => {
    const getGuardianCards = async () => {
      if (!user?.financialGuardian) return;

      const response = await CreditCardService.creditCards({
        filter: [
          {
            field: 'financialGuardianId',
            value: user.financialGuardian.id,
            operator: '$eq',
          },
        ],
      });
      if (response.status === StatusCodes.OK) {
        setCards(response.data.data);
      } else {
        openAlert({
          message: response.data.message,
          type: MESSAGE_TYPE.ERROR,
        });
      }
    };
    getGuardianCards();
  }, [user, modal, openAlert]);

  const verifyIfIsTheLastRegistrationOfThisDependent = async (
    dependentId: string,
  ) => {
    const { data: response, status } =
      await RegistrationService.filterRegistrations({
        filter: [
          {
            field: 'dependentId',
            operator: CondOperator.EQUALS,
            value: dependentId,
          },
        ],
        limit: 2,
      });

    if (status === StatusCodes.OK) {
      return response.data.length === 1;
    } else {
      openAlert({
        message: DEPENDENT_REGISTRATIONS_NOT_FOUND,
        type: MESSAGE_TYPE.ERROR,
      });
    }
  };

  // TODO: This must be refactored, the get registration method must just take a registration
  // and not navigate the user
  const getRegistration = async (dependentId: string) => {
    const response = await RegistrationService.registration(dependentId);
    if (response.status === StatusCodes.OK) {
      goToRegistrationDetails(response.data);
    } else {
      openAlert({
        message: GENERAL_ERROR,
        type: MESSAGE_TYPE.ERROR,
      });
    }
  };

  const goToRegistrationDetails = (registration: Registration) => {
    history.push({
      pathname: `/adm-registrations/${registration.id}`,
    });
  };

  const goToNewDependent = () => {
    if (user) {
      const { cpf, rg, address } = user.financialGuardian;
      history.push({
        pathname: `/adm-users/${user.id}/new-registration`,
        state: {
          userId: user.id,
          guardianId: user.financialGuardian.id,
          isAdmin: true,
          userDocuments: {
            cpf,
            rg,
          },
          userAddress: address,
        },
      });
    }
  };

  const deleteCard = async (id: number) => {
    const response = await CreditCardService.deleteCard(id);
    if (response.status === StatusCodes.OK) {
      setConfirmModal(false);
      setModal(true);
      setModalMessage(CARD_DELETE_SUCCESS);
    } else {
      openAlert({
        message: GENERAL_ERROR,
        type: MESSAGE_TYPE.ERROR,
      });
    }
  };

  const blockCard = async (id: number, isBlocked: boolean) => {
    /**
     * isBlocked é passado com o valor oposto para alterar entre bloqueado e desbloqueado
     */
    const response = await CreditCardService.updateCard(id, !isBlocked);
    if (response.status === StatusCodes.OK) {
      setConfirmModal(false);
      setModal(true);
      setModalMessage(isBlocked ? CARD_UNBLOCK_SUCCESS : CARD_BLOCK_SUCCESS);
    } else {
      openAlert({
        message: GENERAL_ERROR,
        type: MESSAGE_TYPE.ERROR,
      });
    }
  };

  const handleOpenModal = (
    id: number,
    isBlocked: boolean,
    isDeleting: boolean,
  ) => {
    setModalData({
      title: isDeleting ? DELETE_TITLE : BLOCK_TITLE(isBlocked),
      message: isDeleting ? DELETE_MESSAGE : BLOCK_MESSAGE(isBlocked),
    });
    setUpdateCard({
      id,
      isBlocked,
      isDeleting,
    });
    setConfirmModal(true);
  };

  const handleUpdateCard = (update: UpdateCard) => {
    const { id, isBlocked, isDeleting } = update;
    if (isDeleting) {
      deleteCard(id);
    } else {
      blockCard(id, isBlocked);
    }
  };

  const handleChangeCivilStatus = (status: string) => {
    setCivilStatus(status as CIVIL_STATUS);
    setValue('civilStatus', status);
  };

  const handleChangeRelationship = (status: string) => {
    setRelationship(status as DEPENDENT_RELATIONSHIP);
    setValue('relationship', status);
  };

  const handleChangeRole = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedRole(e.target.value as USER_ROLES);
  };

  const handleCopyLink = () => {
    setBtnText(LINK_COPIED);
    setTimeout(() => {
      setBtnText(LINK_BUTTON);
    }, 3000);
  };

  const handleDeleteRegistration = async (registration: Registration) => {
    const isTheLastRegistrationOfThisDependent =
      await verifyIfIsTheLastRegistrationOfThisDependent(
        String(registration.dependentId),
      );

    setSelectedRegistration(registration);
    setDeleteModal(true);
    setIsTheLastRegistrationOfTheSelectedDependent(
      !!isTheLastRegistrationOfThisDependent,
    );
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
    setIsTheLastRegistrationOfTheSelectedDependent(false);
  };

  const deleteRegistration = async () => {
    if (selectedRegistration) {
      const response = await RegistrationService.deleteRegistration(
        selectedRegistration.id,
      );
      if (response.status !== StatusCodes.OK) {
        openAlert({
          message: response.data.message,
          type: MESSAGE_TYPE.ERROR,
        });
      } else {
        loadUser();
        setDeleteModal(false);
        setIsTheLastRegistrationOfTheSelectedDependent(false);
        setModal(true);
        setModalMessage(response.data.message);
      }
    }
  };

  const generateAndCopyToken = async () => {
    const response = await AuthService.getNewToken(Number(userId));
    if (response.status !== StatusCodes.CREATED) {
      openAlert({
        message: response.data.message,
        type: MESSAGE_TYPE.ERROR,
      });
    } else {
      navigator.clipboard.writeText(
        `${process.env.REACT_APP_FRONTEND_URL}/new-password/${response.data.recoveryToken}`,
      );
      handleCopyLink();
    }
  };

  const handleCloseAlert = () => {
    closeAlert();
    clearCepError();
  };

  useEffect(() => {
    if (user) {
      setSelectedRole(user?.roles[0] as USER_ROLES);
    }
  }, [user]);

  return (
    <Grid
      style={{ padding: 25 }}
      container
      direction="column"
      alignItems="flex-start"
    >
      <Modal
        icon={
          <CheckCircleOutlineOutlined
            color="secondary"
            className={classes.dialogIcon}
          />
        }
        open={modal}
        text={modalMessage}
        btnText="Voltar"
        btnAction={() => setModal(false)}
      />
      <Modal
        icon={<DeleteOutline color="primary" className={classes.dialogIcon} />}
        open={deleteModal}
        text="Deletar dependente?"
        subtext={
          isTheLastRegistrationOfTheSelectedDependent
            ? 'Esta é a única matrícula deste dependente. Ao confirmar, o dependente será excluído permanentemente.'
            : ''
        }
        btnText="Deletar"
        backBtnText="Voltar"
        btnAction={deleteRegistration}
        backBtnAction={handleCloseDeleteModal}
      />
      <ConfirmModal
        open={confirmModal}
        close={() => setConfirmModal(false)}
        title={modalData.title}
        message={modalData.message}
        buttonText={modalData.title}
        buttonFn={() => handleUpdateCard(updateCard)}
      />
      <AlertCard
        message={alertMessage || cepError}
        open={isShowAlert || !!cepError}
        close={handleCloseAlert}
        severity="error"
      />
      <Grid container item direction="column">
        <PageHeader
          title="Usuário"
          subtitle="Detalhes do usuário"
          button={
            <SpinnerButton
              className={styles.linkBtn}
              action={generateAndCopyToken}
              text={btnText}
            />
          }
        />
        <Paper elevation={0} className={classes.screenPaper}>
          <EditableFormContainer
            title={`Dados do ${
              user?.isAdmin ? 'administrador' : 'responsável'
            }`}
            editing={editing}
            edit={() => setEditing(true)}
            save={handleSubmit(onSubmit)}
          >
            {user ? (
              <UserForm
                user={user}
                register={register}
                control={control}
                errors={errors}
                editing={editing}
                role={selectedRole}
                changeRole={
                  user?.isAdmin && Number(userId) !== admin?.id
                    ? handleChangeRole
                    : undefined
                }
              />
            ) : null}
            {user && !user?.isAdmin ? (
              <>
                <GuardianForm
                  guardian={
                    user.financialGuardian.isCompleted
                      ? user.financialGuardian
                      : undefined
                  }
                  civilStatus={civilStatus}
                  relationship={relationship}
                  changeCivilStatus={handleChangeCivilStatus}
                  changeRelationship={handleChangeRelationship}
                  register={register}
                  control={control}
                  errors={errors}
                  editing={editing}
                />
                <AddressForm
                  address={
                    user.financialGuardian.isCompleted
                      ? user.financialGuardian.address
                      : undefined
                  }
                  searchAddress={address}
                  cepSearch={debouncedSearchCEP}
                  cepErrorCount={cepErrorCount}
                  register={register}
                  control={control}
                  errors={errors}
                  editing={editing}
                />
              </>
            ) : null}
          </EditableFormContainer>
        </Paper>

        <Grid
          container
          item
          alignItems="center"
          justifyContent="flex-end"
          className={classes.verticalMargin}
        >
          <Grid item>
            <SpinnerButton
              className={classes.newContractButton}
              action={handleSubmit(onSubmit)}
              text="Salvar alterações"
              disabled={!editing}
            />
          </Grid>
        </Grid>

        {!user?.isAdmin ? (
          <>
            <Paper elevation={0} className={classes.screenPaper}>
              <CardsList cards={cards} openModal={handleOpenModal} />
            </Paper>

            <PageHeader
              title="Dependentes"
              subtitle="Adicione ou edite um dependente para este usuário"
              button={
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => goToNewDependent()}
                  className={classes.listScreenButton}
                >
                  Novo dependente
                </Button>
              }
            />

            {registrations.length ? (
              <Paper
                style={{ marginTop: 30 }}
                elevation={0}
                className={classes.configurationsPaper}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography className={classes.listScreenHeaderText1}>
                          NOME
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.listScreenHeaderText1}>
                          TURMA
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography className={classes.listScreenHeaderText1}>
                          AÇÕES
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {registrations.map(registration => (
                      <TableRow key={registration.id}>
                        <TableCell>{registration.dependent.name}</TableCell>
                        <TableCell>{registration.schoolClass.name}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={() =>
                              getRegistration(String(registration.id))
                            }
                            color="default"
                          >
                            <EditOutlined color="primary" />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              handleDeleteRegistration(registration)
                            }
                            color="default"
                          >
                            <DeleteOutline color="primary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <EmptyListPlaceholder message={ADM_NO_REGISTRATIONS} />
            )}
          </>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default UserDetails;
