import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { MaskProperties } from './types';
import { currencyMaskOptions } from './utils';

const CurrencyInput = (props: MaskProperties) => {
  const { inputRef, ...other } = props;
  const currencyMask = createNumberMask({ ...currencyMaskOptions });
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) =>
    event.target.select();

  return (
    <MaskedInput
      {...other}
      ref={(ref: MaskedInput | null) => {
        inputRef(ref ? (ref.inputElement as HTMLInputElement) : null);
      }}
      onFocus={handleFocus}
      mask={currencyMask}
    />
  );
};

export default CurrencyInput;
