import { makeStyles, Theme } from '@material-ui/core';
import { mediumGray, primaryDark } from 'styles/theme';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    paper: {
      padding: '20px 17px',
      borderRadius: '10px',
      boxShadow: '0px 2px 8px -2px rgba(171, 167, 167, 0.2)',
      cursor: 'pointer',
      width: '100%',
      minWidth: 200,
      [theme.breakpoints.down('sm')]: {
        minWidth: 350,
      },
    },
    title: {
      fontSize: '1.125rem',
      color: primaryDark,
    },
    description: {
      color: mediumGray,
      fontSize: '16px',
      opacity: 0.5,
      minWidth: '100%',
    },
    icon: { fontSize: '30px' },
  };
});
