import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    padding: '22px 0',
  },
  header: {
    flex: 1,
    marginBottom: 14,
    gap: '10px',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down(580)]: {
      flexDirection: 'column',
    },
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: 18,
  },
  price: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: '20px',

    [theme.breakpoints.down(580)]: {
      fontWeight: 700,
    },
  },
  infoContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  priceContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [theme.breakpoints.down(580)]: {
      marginTop: '10px',
      alignItems: 'flex-start',
    },
  },

  infoText: {
    fontSize: 14,
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.palette.grey[800],
  },
  removeButton: {
    color: theme.palette.error.main,
    fontWeight: 400,
    width: 'fit-content',
    textTransform: 'none',
    fontSize: 14,
    padding: '10px 0',

    '& svg': {
      marginLeft: '5px',
    },
  },
}));
