import {
  SubscriptionsChange,
  SubscriptionsService,
} from 'pages/Client/MarketplacePaymentCheckout/types';
import {
  Service,
  ServiceClass,
} from 'pages/Client/MarketplaceServicesDetail/types';
import React, { Dispatch, SetStateAction } from 'react';
import { Dependent } from 'types/dependent';
import { PAYMENT_METHODS } from 'types/enums';
import { Registration } from 'types/registration';

export interface ICartContextProviderProps {
  children: React.ReactNode;
}

export interface ICartItem {
  registrationId: number;
  dependentId: number;
  selectedServiceClass: ServiceClass;
  registration: Registration;
  dependent: Dependent;
  service: Service;
  serviceAccessCode?: string | null;
  serviceInviteCode?: string | null;
  payment: {
    paymentMethodCode: PAYMENT_METHODS;
    installments: number;
    cardId: number | null;
    couponName: string | null;
    serviceAccessCode?: string | null;
    serviceInviteCode?: string | null;
  };
  maxInstallments: number;
}

export interface CartLocalStorageItems {
  actualDependent: Dependent;
  cartItems: ICartItem[];
  cartTotal: number;
  subscriptions: SubscriptionsService[];
}

export enum EKeysOfLocalStorage {
  CART_STORAGE = 'JPSMarketPlaceCart',
}

export type AddServiceToCartFn = (params: Omit<ICartItem, 'payment'>) => void;
export type RemoveServiceFromCartFn = (
  serviceId: number,
  registrationId: number,
) => void;
export type GetServiceFromCartFn = (
  serviceId: number,
  registrationId: number,
) => ICartItem | undefined;

export interface ICartContext {
  cartItems: ICartItem[];
  totalCartValue: number;
  totalItemsCart: number;
  actualDependent: Dependent;
  actualCartItem: ICartItem;
  cartItemAlreadyAdded: boolean;
  subscriptions: SubscriptionsService[];
  isSubscriptionInputCorrect: boolean;
  // handleSetActualDependent: (dependent: Dependent) => void;
  // handleSetActualCartItem: (cardItem: ICartItem) => void;
  handleAddServiceToCart: AddServiceToCartFn;
  handleRemoveServiceFromCart: RemoveServiceFromCartFn;
  getServiceFromCart: GetServiceFromCartFn;
  // handleAddItemsToCart: () => void;
  // handleRemoveItemsToCart: (item: ICartItem) => void;
  handleResetCartContextData: () => void;
  handleResetCartItems: () => void;
  setSubscriptions: Dispatch<SetStateAction<SubscriptionsService[]>>;
  handleChangeSubscriptionData: (
    serviceId: number,
    registrationId: number,
    data: SubscriptionsChange,
  ) => void;
}
