import { api, getAxiosResponseMessageErrorOrDefault } from './apiService';
import { PedagogicSubject } from './types/pedagogicSubjec.type';

export default class PedagogicSubjectService {
  static async getMarketplaceComplementaryCurriculumOptions() {
    try {
      return await api.get<PedagogicSubject[]>(
        'pedagogic-subjects/complementary-curriculum/options',
      );
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }
}
