import { useCallback, useEffect, useState } from 'react';

import { StatusCodes } from 'http-status-codes';

import { yupResolver } from '@hookform/resolvers/yup';
import lodash from 'lodash';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { ADMIN_ROUTE } from 'routes/consts';
import ContractTemplatesService from 'services/contractTemplates';
import useGlobalStyles from 'styles';
import { ContractTemplates } from 'types/contractTemplates';
import { RouteParams } from 'types/routeParams';
import { useStyles } from './styles';
import { SubmitTemplateFormData } from './types';
import { schema } from './utils';

export const ContractTemplateFormController = () => {
  // Styles
  const classes = useGlobalStyles();

  const styles = useStyles();

  // Navigation
  const history = useHistory();
  const { id: templateId } = useParams<RouteParams>();

  // States
  const [template, setTemplate] = useState<ContractTemplates>();
  const [submitError, setSubmitError] = useState('');
  const [loading, setLoading] = useState(false);

  // Form
  const {
    handleSubmit,
    errors,
    setError,
    register,
    control,
    clearErrors,
    setValue,
  } = useForm<SubmitTemplateFormData>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldUnregister: false,
  });

  const onSubmit = async (data: SubmitTemplateFormData) => {
    clearErrors();
    setLoading(true);
    try {
      const templateData = lodash.pick(data, ['name', 'externalId']);

      if (template) {
        const response = await ContractTemplatesService.update(
          template.id,
          templateData,
        );
        if (response.status !== StatusCodes.OK) {
          setSubmitError(response.data.message);
          throw new Error(response);
        }
      } else {
        const response = await ContractTemplatesService.create(templateData);

        if (response.status !== StatusCodes.CREATED) {
          setSubmitError(response.data.message);
        } else {
          throw new Error(response);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const goToContractTemplates = () => {
    history.push(ADMIN_ROUTE.CONTRACT_TEMPLATES);
  };

  const getTemplateData = useCallback(
    async (id: string) => {
      const { data } = await ContractTemplatesService.getOneById(id);
      setValue('name', data.name);
      setTemplate(data);
    },
    [setValue],
  );

  // Effects
  useEffect(() => {
    if (templateId) {
      getTemplateData(templateId);
    }
  }, [templateId, getTemplateData]);

  useEffect(() => {
    Object.keys(errors).map(errorKey => {
      setError(errorKey as keyof SubmitTemplateFormData, {
        message: errors[errorKey as keyof SubmitTemplateFormData]?.message,
      });
    });
  }, [errors, setError]);

  return {
    loading,
    classes,
    styles,
    submitError,
    handleSubmit,
    errors,
    register,
    control,
    onSubmit,

    setSubmitError,
    goToContractTemplates,
    templateId,
  };
};
