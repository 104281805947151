import { format, isValid, parse } from 'date-fns';
import { formatDate } from 'pages/Admin/Coupons/utils';
import {
  INVALID_FIELD,
  INVALID_INTERVAL,
  INVALID_PERCENT,
  REQUIRED_FIELD,
  VALUE_NAN,
} from 'texts';
import {
  isDateIntervalValid,
  isStringDateValid,
  unformatValueMask,
} from 'utils';
import * as Yup from 'yup';

const valueSchema = (currencyType: string) =>
  Yup.mixed().when(currencyType, {
    is: true,
    then: Yup.mixed()
      .transform(value => unformatValueMask(value))
      .required(REQUIRED_FIELD),
  });
const percentSchema = (currencyType: string) =>
  Yup.mixed().when(currencyType, {
    is: false,
    then: Yup.mixed()
      .transform(value => unformatValueMask(value))
      .required(REQUIRED_FIELD)
      .test('is <= 100', INVALID_PERCENT, value => {
        if (value) {
          return value <= 100 && value > 0;
        }
        return false;
      }),
  });

export const schema = Yup.object({
  hasDuration: Yup.boolean(),
  isLimited: Yup.boolean(),
  hasEvent: Yup.boolean(),
  name: Yup.string().required(REQUIRED_FIELD),
  source: Yup.mixed()
    .when('isArray', {
      is: Array.isArray,
      then: Yup.array().of(Yup.string()),
    })
    .required(REQUIRED_FIELD),
  pix_value: valueSchema('usesCurrencyPix'),
  pix_percent: percentSchema('usesCurrencyPix'),
  bank_slip_value: valueSchema('usesCurrencyBankSlip'),
  bank_slip_percent: percentSchema('usesCurrencyBankSlip'),
  credit_card_value: valueSchema('usesCurrencyCreditCard'),
  credit_card_percent: percentSchema('usesCurrencyCreditCard'),
  start_date: Yup.string().when('hasDuration', {
    is: true,
    then: Yup.string()
      .required(REQUIRED_FIELD)
      .test('is date valid', INVALID_FIELD, value => {
        return isStringDateValid(value).isDateValid;
      }),
  }),
  end_date: Yup.string().when('hasDuration', {
    is: true,
    then: Yup.string()
      .required(REQUIRED_FIELD)
      .test(
        'is date valid',
        INVALID_FIELD,
        value => isStringDateValid(value).isDateValid,
      )
      .test('is interval valid', INVALID_INTERVAL, (value, context) =>
        isDateIntervalValid(context.parent.start_date, value),
      ),
  }),
  limit: Yup.number()
    .typeError(REQUIRED_FIELD)
    .when('isLimited', {
      is: true,
      then: Yup.number().required(REQUIRED_FIELD).typeError(VALUE_NAN()),
    }),
  event: Yup.mixed().when('hasEvent', {
    is: true,
    then: Yup.string().required(REQUIRED_FIELD),
  }),
});

export const formatDateReverse = (date: string | null | undefined) => {
  if (date) {
    const parsed = parse(date, 'dd/MM/yyyy', new Date());
    if (isValid(parsed)) {
      return format(parsed, 'yyyy-MM-dd');
    }
    return null;
  }
};

export const formatDateToInput = (date: string | null | undefined) => {
  return formatDate(date?.replace(/-/g, '/')).replaceAll('-', '/');
};

export const parsePercent = (percent: string) => {
  const parsedPercentString = Number(
    percent.replace('%', '').replace(',', '.'),
  );

  return parsedPercentString;
};

export const parseValue = (value: string) => {
  const parsedValueString = Number(
    value.replace('R$', '').replaceAll('.', '').replaceAll(',', '.'),
  );

  return parsedValueString;
};
