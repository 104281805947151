/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';

import {
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  TablePagination,
} from '@material-ui/core';
import PageHeader from 'components/PageHeader';
import SearchIcon from '@material-ui/icons/Search';
import { SCHOOL_GRADES } from 'types/enums';
import { ROWS_PER_PAGE } from 'utils';
import { ServiceTable } from './components/ServicesTable';
import { ServiceHomeGridContainer, ServiceHomeTextField } from './styles';
import { useServicesController } from './controller';

export const Services = () => {
  const {
    classes,
    services,
    navigateToServiceCreation,
    handleChangeSearchServiceName,
    handleChangeGrade,
    handleChangePage,
    handleChangeRowsPerPage,
    statusOptions,
    handleChangePartner,
    partnerOptions,
    handleChangeStatus,
    page,
    rowsPerPage,
    total,
  } = useServicesController();
  return (
    <Box paddingX="45px">
      <ServiceHomeGridContainer container item direction="column">
        <PageHeader
          title="Gestão de "
          titleBoldSufix="serviços"
          subtitle="Tenha acesso e gestione as informações sobre serviços"
          button={
            <Button
              variant="contained"
              color="primary"
              className={classes.listScreenButton}
              onClick={navigateToServiceCreation}
            >
              Novo serviço
            </Button>
          }
        />
        <Grid container sm={12} spacing={2}>
          <Grid item sm={3}>
            <ServiceHomeTextField
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={handleChangeSearchServiceName}
              placeholder="Buscar por nome de serviço..."
            />
          </Grid>
          <Grid item sm={3}>
            <ServiceHomeTextField
              label="Ano / Série"
              variant="outlined"
              select
              onChange={handleChangeGrade}
              SelectProps={{
                MenuProps: {
                  disableScrollLock: true,
                  style: { maxHeight: 285 },
                },
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              {Object.values(SCHOOL_GRADES).map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </ServiceHomeTextField>
          </Grid>
          <Grid item sm={3}>
            <ServiceHomeTextField
              label="Status"
              variant="outlined"
              select
              onChange={handleChangeStatus}
              SelectProps={{
                MenuProps: {
                  disableScrollLock: true,
                  style: { maxHeight: 285 },
                },
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              {statusOptions.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </ServiceHomeTextField>
          </Grid>

          <Grid item sm={3}>
            <ServiceHomeTextField
              label="Parceiro"
              variant="outlined"
              select
              onChange={handleChangePartner}
              SelectProps={{
                MenuProps: {
                  disableScrollLock: true,
                  style: { maxHeight: 285 },
                },
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              {partnerOptions.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </ServiceHomeTextField>
          </Grid>
        </Grid>

        <ServiceTable services={services} />

        <Box maxWidth="100%">
          <TablePagination
            component="div"
            labelRowsPerPage="Itens por página"
            count={total}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPageOptions={ROWS_PER_PAGE}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {/* TODO: REMOVE ON INTEGRATE SCHEDULE */}
          {/* <ServicesScheduleButton
            variant="contained"
            color="primary"
            startIcon={<CustomIcon name="calendar" />}
            endIcon={<CustomIcon name="chevRight" />}
          >
            Ver a grade de horários de todos os serviços
          </ServicesScheduleButton> */}
        </Box>
      </ServiceHomeGridContainer>
    </Box>
  );
};
