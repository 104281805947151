import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { DatePicker } from '@material-ui/pickers';
import AlertCard from 'components/AlertCard';
import { NestCrudTable } from 'components/NestCrudTable';
import Scaffold from 'components/Scaffold';
import { selectMenuProps } from 'constants/selectMenu';
import React from 'react';
import { PAYMENT_STATUS, SCHOOL_GRADES } from 'types/enums';
import { YEARS_ARRAY } from '../AdmRegistrations/utils';
import { ContractTableData } from './ContractsTables/types';
import { tableConfig } from './consts';
import { useContractController } from './useContractController';

/** Essa é a data de criação do primeiro contrato no sistema. */
const minDate = new Date('2021-10-18 12:15:38.328361');
const maxDate = new Date();

const Contracts: React.FC = () => {
  const {
    classes,
    searchField,
    closeAlert,
    alertMessage,
    alertType,
    isShowAlert,
    handleChangeSearch,
    handleChangeStartDate,
    handleChangeEndDate,
    handleChangePaymentStatus,
    handleChangeGrade,
    handleChangeSchoolYear,
    resetPeriod,
    schoolYear,
    paymentStatus,
    grade,
    start,
    end,
    loadContracts,
  } = useContractController();

  return (
    <Scaffold
      rawTitle="Contratos"
      rawSubtitle="Acesse aqui os contratos das matrículas e serviços"
    >
      <AlertCard
        message={alertMessage}
        open={isShowAlert}
        close={closeAlert}
        severity={alertType}
      />
      <Grid container item direction="column">
        <Grid
          item
          container
          spacing={2}
          alignItems="center"
          className={classes.filtersGrid}
        >
          <Grid item>
            <TextField
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="Nome ou matrícula"
              value={searchField}
              onChange={handleChangeSearch}
              className={classes.bigFilterField}
            />
          </Grid>
          <Grid item>
            <DatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              label="Período - Início"
              minDate={minDate}
              maxDate={end || maxDate}
              value={start}
              onChange={handleChangeStartDate}
              className={classes.smallFilterField}
            />
          </Grid>
          <Grid item>
            <DatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              label="Período - Fim"
              minDate={start || minDate}
              maxDate={maxDate}
              value={end}
              onChange={handleChangeEndDate}
              className={classes.smallFilterField}
            />
          </Grid>
          {start || end ? (
            <Grid item>
              <Button
                color="primary"
                className={classes.textButton}
                onClick={resetPeriod}
              >
                Resetar período
              </Button>
            </Grid>
          ) : null}
        </Grid>
        <Grid container item spacing={2}>
          <Grid item>
            <TextField
              label="Turma/Série"
              variant="outlined"
              select
              SelectProps={selectMenuProps}
              value={grade}
              onChange={handleChangeGrade}
              className={classes.filterField}
            >
              <MenuItem>Todos</MenuItem>
              {Object.values(SCHOOL_GRADES).map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <TextField
              label="Status Pagamento"
              variant="outlined"
              select
              SelectProps={selectMenuProps}
              value={paymentStatus}
              onChange={handleChangePaymentStatus}
              className={classes.filterField}
            >
              <MenuItem>Todos</MenuItem>
              {Object.values(PAYMENT_STATUS).map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <TextField
              label="Ano da matrícula"
              variant="outlined"
              select
              SelectProps={selectMenuProps}
              value={schoolYear}
              onChange={handleChangeSchoolYear}
              className={classes.filterField}
            >
              <MenuItem>Todos</MenuItem>
              {YEARS_ARRAY.map(year => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>
      <NestCrudTable<ContractTableData>
        onFetchData={loadContracts}
        tableConfig={tableConfig}
        emptyTableMessage="Não há contratos para serem exibidos."
      />
    </Scaffold>
  );
};

export default Contracts;
