/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Button,
  Grid,
  InputAdornment,
  TablePagination,
  TextField,
  Typography,
} from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { CheckCircleOutline, DeleteOutline } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import AlertCard from 'components/AlertCard';
import EmptyListPlaceholder from 'components/EmptyListPlaceholder';
import Modal from 'components/Modal';
import NewUserModal from 'components/NewUserModal';
import { useAuth } from 'contexts/auth';
import { useFilters } from 'hooks/filter';
import { StatusCodes } from 'http-status-codes';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import UserService from 'services/userService';
import useGlobalStyles from 'styles';
import { NO_USERS } from 'texts';
import { USER_ROLES } from 'types/enums';
import { User } from 'types/user';
import { useDebouncedCallback } from 'use-debounce';
import { ROWS_PER_PAGE } from 'utils';
import UsersTable from './UsersTable';
import { useStyles } from './styles';

const Users: React.FC = () => {
  const classes = useGlobalStyles();
  const styles = useStyles();
  const { user: loggedUser } = useAuth();
  const theme = createMuiTheme({}, ptBR);
  const [guardians, setGuardians] = useState<User[]>([]);
  const [admins, setAdmins] = useState<User[]>([]);
  const [userModal, setUserModal] = useState(false);
  const [adminModal, setAdminModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);
  const { page, handleSetValuePage } = useFilters();
  const [adminName, setAdminName] = useState('');
  const [userName, setName] = useState('');
  const [selectedUser, setSelectedUser] = useState<User | null>();
  const [alertMessage, setAlertMessage] = useState('');
  const [alert, setAlert] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [successModal, setSuccessModal] = useState({
    open: false,
    icon: CheckCircleOutline,
    text: '',
  });

  const loadAdmins = useCallback(async () => {
    const { data: response, status } = await UserService.users({
      search: {
        name: { $contL: adminName },
        isAdmin: true,
      },
    });
    if (status === StatusCodes.OK) {
      setAdmins(response.data);
      setTotal(response.total);
    }
  }, [adminName]);

  useEffect(() => {
    loadAdmins();
  }, [loadAdmins]);

  const loadGuardians = useCallback(async () => {
    const { data: response, status } = await UserService.users({
      page: page + 1,
      limit: rowsPerPage,
      join: [['financialGuardian'], ['dependents']],
      search: {
        name: { $contL: userName },
        isAdmin: false,
      },
    });
    if (status === StatusCodes.OK) {
      setGuardians(response.data);
      setTotal(response.total);
    }
  }, [page, rowsPerPage, userName]);

  useEffect(() => {
    loadGuardians();
  }, [loadGuardians]);

  const deleteUser = async () => {
    if (selectedUser) {
      const { status, data } = await UserService.deleteUser(selectedUser.id);
      if (status !== StatusCodes.OK) {
        setAlert(true);
        setAlertMessage(data.message);
      } else {
        setDeleteModal(false);
        setSuccessModal({
          open: true,
          icon: DeleteOutline,
          text: data.message,
        });
      }
    }
  };

  const handleCloseModal = () => {
    setSuccessModal(prev => ({ ...prev, open: false }));
    loadAdmins();
    loadGuardians();
  };

  const handleOpenDeleteModal = (user: User) => {
    setSelectedUser(user);
    setDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const searchUserFieldDebounce = useDebouncedCallback(
    (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value),
    300,
  );

  const searchAdminFieldDebounce = useDebouncedCallback(
    (e: ChangeEvent<HTMLInputElement>) => setAdminName(e.target.value),
    300,
  );

  return (
    <Grid
      className={classes.listScreenGrid}
      container
      direction="column"
      alignItems="flex-start"
    >
      <NewUserModal
        open={adminModal}
        admin
        close={() => setAdminModal(false)}
        loadUsers={loadAdmins}
      />
      <NewUserModal
        open={userModal}
        close={() => setUserModal(false)}
        loadUsers={loadGuardians}
      />
      <Modal
        icon={
          <successModal.icon color="primary" className={classes.dialogIcon} />
        }
        open={successModal.open}
        text={successModal.text}
        btnText="Voltar"
        btnAction={handleCloseModal}
      />
      <Modal
        icon={<DeleteOutline color="primary" className={classes.dialogIcon} />}
        open={deleteModal}
        text="Deletar usuário?"
        btnText="Deletar"
        backBtnText="Voltar"
        btnAction={deleteUser}
        backBtnAction={handleCloseDeleteModal}
      />
      <AlertCard
        message={alertMessage}
        open={alert}
        close={() => setAlert(false)}
        severity="error"
      />
      {loggedUser &&
      !loggedUser.roles.includes(USER_ROLES.SECRETARY) &&
      !loggedUser.roles.includes(USER_ROLES.TREASURY) ? (
        <Grid container item direction="column" style={{ marginBottom: 50 }}>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography className={classes.screenHeaderText}>
                <b>Gerenciamento dos usuários administrativos</b>
              </Typography>
              <Typography className={classes.screenSubHeaderText}>
                Gerencie os perfis administrativos
              </Typography>
            </Grid>
            {loggedUser.roles.includes(USER_ROLES.ADMIN) ? (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setAdminModal(true)}
                  className={classes.listScreenButton}
                >
                  Novo perfil administrativo
                </Button>
              </Grid>
            ) : null}
          </Grid>
          <TextField
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder="Buscar"
            onChange={searchAdminFieldDebounce}
            className={styles.search}
          />

          <UsersTable users={admins} deleteFn={handleOpenDeleteModal} />
        </Grid>
      ) : null}
      <Grid container item direction="column">
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography className={classes.screenHeaderText}>
              <b>Gerenciamento dos usuários gerais</b>
            </Typography>
            <Typography className={classes.screenSubHeaderText}>
              Gerencie os perfis gerais
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setUserModal(true)}
              className={classes.listScreenButton}
            >
              Novo perfil geral
            </Button>
          </Grid>
        </Grid>
        <TextField
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder="Buscar"
          onChange={searchUserFieldDebounce}
          className={styles.search}
        />
        {guardians.length ? (
          <UsersTable users={guardians} deleteFn={handleOpenDeleteModal} />
        ) : (
          <EmptyListPlaceholder message={NO_USERS} />
        )}
        <ThemeProvider theme={theme}>
          {/** @ts-ignore */}
          <TablePagination
            component="div"
            count={total}
            page={page}
            labelRowsPerPage="Itens por página"
            onChangePage={(_event: unknown, newPage: number) =>
              handleSetValuePage(newPage)
            }
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
              handleSetValuePage(0);
              setRowsPerPage(parseInt(event.target.value, 10));
            }}
            rowsPerPageOptions={ROWS_PER_PAGE}
          />
        </ThemeProvider>
      </Grid>
    </Grid>
  );
};

export default Users;
