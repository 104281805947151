import { Checkbox, Grid, ListItemText, Radio } from '@material-ui/core';
import { format } from 'date-fns';
import { Controller } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { mask } from 'remask';
import { DATE_MASK } from 'texts';
import {
  InputText,
  SelectMenuItemContainer,
  SelectMenuItemDivider,
} from '../../styles';

import { IGeneraFormProps } from '../../types';
import { useSchedulerFormController } from './controller';

export const SchedulerForm = ({
  control,
  register,
  errors,
  serviceClassToUpdate,
}: IGeneraFormProps) => {
  const {
    inputRefMenuDimensions,
    MenuProps,
    renderValue,
    days,
    scheduleType,
    canVaryEveryDay,
    daysSchedule,
    handleCanVaryEveryDayChange,
    handleAddDaySchedule,
  } = useSchedulerFormController(
    (serviceClassToUpdate?.calendars?.map(
      calendar => calendar.day,
    ) as string[]) ?? [],
    serviceClassToUpdate?.timeType !== 'Todos os dias tem o mesmo horário',
  );

  return (
    <Grid container spacing={2} md={11}>
      <Grid item md={4}>
        <Controller
          name="startDate"
          control={control}
          defaultValue={() => {
            if (!serviceClassToUpdate?.startDate) return null;
            const parseDate = new Date(
              serviceClassToUpdate?.startDate || Date.now(),
            );
            return format(parseDate, 'dd/MM/yyyy');
          }}
          as={({ value, onChange }) => {
            return (
              <ReactInputMask
                mask={DATE_MASK}
                name="startDate"
                placeholder="Data de início"
                value={value}
                onChange={onChange}
                inputRef={register}
              >
                {(inputProps: any) => (
                  <InputText
                    ref={inputRefMenuDimensions}
                    id="startDate"
                    name="startDate"
                    label="Data de início"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    error={!!errors.startDate}
                    helperText={
                      errors.startDate ? errors.startDate?.message : null
                    }
                    inputRef={register}
                    {...inputProps}
                  />
                )}
              </ReactInputMask>
            );
          }}
        />
      </Grid>
      <Grid item md={4}>
        <Controller
          name="endDate"
          control={control}
          defaultValue={() => {
            // TODO: duplicated code in this file
            if (!serviceClassToUpdate?.startDate) return null;
            const parseDate = new Date(
              serviceClassToUpdate?.endDate || Date.now(),
            );
            return format(parseDate, 'dd/MM/yyyy');
          }}
          as={({ value, onChange }) => {
            return (
              <ReactInputMask
                mask={DATE_MASK}
                name="endDate"
                placeholder="Data de término"
                value={value}
                onChange={onChange}
                inputRef={register}
              >
                {(inputProps: any) => (
                  <InputText
                    id="endDate"
                    name="endDate"
                    label="Data de término"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    error={!!errors.endDate}
                    helperText={errors.endDate ? errors.endDate?.message : null}
                    inputRef={register}
                    {...inputProps}
                  />
                )}
              </ReactInputMask>
            );
          }}
        />
      </Grid>
      <Grid item md={4} />
      <Grid item md={4}>
        <Controller
          name="days"
          control={control}
          defaultValue={serviceClassToUpdate?.calendars?.map(
            calendar => calendar.day,
          )}
          as={({ value: malformedValue, onChange }) => {
            const value = (malformedValue || []) as unknown as Array<string>;
            return (
              <InputText
                select
                SelectProps={{
                  MenuProps,
                  multiple: true,
                  renderValue,
                }}
                id="days"
                name="days"
                label="Dias"
                variant="outlined"
                value={[...value]}
                onChange={({ target }) => {
                  onChange(target.value);
                  handleAddDaySchedule(
                    target.value as unknown as Array<string>,
                  );
                }}
                error={!!errors.days}
                helperText={errors.days ? errors.days.message : null}
                inputRef={register}
              >
                <SelectMenuItemContainer
                  id="header"
                  key="header"
                  value={undefined}
                >
                  <ListItemText>Dias</ListItemText>
                </SelectMenuItemContainer>
                <SelectMenuItemDivider />
                {days.map(day => {
                  return (
                    <SelectMenuItemContainer key={day} value={day}>
                      <Checkbox checked={value?.includes(day)} />
                      <ListItemText>{day}</ListItemText>
                    </SelectMenuItemContainer>
                  );
                })}
              </InputText>
            );
          }}
        />
      </Grid>
      <Grid item md={4}>
        <Controller
          name="scheduleCanVaryToEveryDay"
          control={control}
          defaultValue={canVaryEveryDay}
          as={({ value: selectedValue, onChange }) => (
            <InputText
              select
              SelectProps={{
                MenuProps,
              }}
              id="scheduleCanVaryToEveryDay"
              name="scheduleCanVaryToEveryDay"
              label="Tipo de horário"
              variant="outlined"
              value={selectedValue}
              onChange={e => {
                onChange(e.target.value);
                handleCanVaryEveryDayChange(e.target.value === 'true');
              }}
              error={!!errors.scheduleCanVaryToEveryDay}
              helperText={
                errors.scheduleCanVaryToEveryDay
                  ? errors.scheduleCanVaryToEveryDay.message
                  : null
              }
              inputRef={register}
            >
              <SelectMenuItemContainer
                id="header"
                key="header"
                value={undefined}
              >
                <ListItemText>Tipo de horário</ListItemText>
              </SelectMenuItemContainer>
              <SelectMenuItemDivider />
              {scheduleType.map(({ label, value }) => {
                return (
                  <SelectMenuItemContainer key={label} value={String(value)}>
                    <Radio checked={String(selectedValue) === String(value)} />
                    <ListItemText>{label}</ListItemText>
                  </SelectMenuItemContainer>
                );
              })}
            </InputText>
          )}
        />
      </Grid>
      <Grid item md={4} />
      {canVaryEveryDay === true &&
        daysSchedule.map((daySchedule, index) => {
          return (
            <>
              <Grid item md={4}>
                <Controller
                  name={index === 0 ? `day-schedule` : `day-schedule-${index}`}
                  control={control}
                  rules={{ required: true }}
                  defaultValue={
                    serviceClassToUpdate?.calendars?.[index]?.startTime
                      ? `${serviceClassToUpdate?.calendars?.[index].startTime}-${serviceClassToUpdate?.calendars?.[index].endTime}`
                      : ''
                  }
                  as={({ value: selectedValue, onChange }) => (
                    <InputText
                      id={
                        index === 0 ? `day-schedule` : `day-schedule-${index}`
                      }
                      name={
                        index === 0 ? `day-schedule` : `day-schedule-${index}`
                      }
                      label={`Horário de ${daySchedule}`}
                      variant="outlined"
                      value={selectedValue}
                      onChange={({ target }) => {
                        onChange(mask(target.value, '99:99-99:99'));
                      }}
                      error={!!errors.schedule}
                      helperText={
                        errors.schedule ? errors.schedule.message : null
                      }
                      inputRef={register}
                    />
                  )}
                />
              </Grid>
              {(index + 1) % 2 === 0 && <Grid item md={4} />}
            </>
          );
        })}

      {canVaryEveryDay === false && (
        <Grid item md={4}>
          <Controller
            name="day-schedule"
            control={control}
            defaultValue={
              serviceClassToUpdate?.calendars?.[0]?.startTime
                ? `${serviceClassToUpdate?.calendars?.[0].startTime}-${serviceClassToUpdate?.calendars?.[0].endTime}`
                : ''
            }
            rules={{ required: true }}
            as={({ value: selectedValue, onChange }) => (
              <InputText
                id="day-schedule"
                name="day-schedule"
                label="Horário"
                variant="outlined"
                value={selectedValue}
                onChange={({ target }) => {
                  onChange(mask(target.value, '99:99-99:99'));
                }}
                error={!!errors.schedule}
                helperText={errors.schedule ? errors.schedule.message : null}
                inputRef={register}
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};
