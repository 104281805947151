import { ISelectItem } from './types';

export const buildSelectOptionsFromArray = (
  values: (number | string)[],
  shouldHaveAllOption?: boolean,
): ISelectItem[] => {
  const options = values.map(value => ({
    id: value,
    label: String(value),
  }));

  if (shouldHaveAllOption) {
    options.unshift({ id: 'all', label: 'Todos' });
  }

  return options;
};
