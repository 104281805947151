import { TableColumnConfig } from 'components/Table/types';
import { ContractTemplates } from 'types/contractTemplates';
import { TABLE_HEADERS } from 'utils/tableHeaders';
import { ContractTemplateRowActions } from './components';

export const tableConfig: TableColumnConfig<ContractTemplates>[] = [
  {
    headerName: TABLE_HEADERS.CONTRACT_NAME,
    getter: 'name',
    defaultValue: '-',
  },
  {
    headerName: TABLE_HEADERS.CONTRACT_INTEGRATION,
    getter: 'externalId',
    defaultValue: '-',
  },
  {
    headerName: TABLE_HEADERS.ACTIONS,
    getter: 'externalId',
    defaultValue: '-',
    customRender: ContractTemplateRowActions,
  },
] as TableColumnConfig<ContractTemplates>[];
