import React from 'react';
import { Paper, Grid, Typography, Box } from '@material-ui/core';
import IncompleteProfileModal from 'components/IncompleteProfileModal';
import RunningImg from 'assets/running.png';
import { secondaryMain } from 'styles/theme';
import { IBannerProperties } from './types';
import { useHomeBannerController } from './controller';

export const HomeBanner = ({ date }: IBannerProperties) => {
  const { styles, modal, goToRegistration } = useHomeBannerController();

  return (
    <>
      <IncompleteProfileModal open={modal} />
      <Paper elevation={0} className={styles.paper} onClick={goToRegistration}>
        <img className={styles.img} src={RunningImg} alt="matrícula" />
        <Grid
          container
          direction="column"
          className={styles.container}
          spacing={3}
        >
          <Grid item>
            {date ? (
              <Box
                bgcolor={secondaryMain}
                width="fit-content"
                padding="8px"
                borderRadius={100}
              >
                <Typography className={styles.dateText}>
                  <b>ATÉ {date}</b>
                </Typography>
              </Box>
            ) : null}
          </Grid>
          <Grid item>
            <Typography className={styles.header}>
              Garanta a matrícula dos seus dependentes!
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={styles.text}>
              Clique aqui para realizar a matrícula de alunos.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
