import React from 'react';

import {
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import EmptyListPlaceholder from 'components/EmptyListPlaceholder';
import { TablePaper } from 'components/TablePaper';
import { NO_SERVICE_CODE } from 'texts/text/serviceCodes';
import { useServiceCodesTableController } from './controller';
import { TableProperties } from './types';

const ServiceCodesTable = ({
  serviceCodes,
  handleOpenDeleteServiceCodeModal,
}: TableProperties) => {
  const { classes, styles, headers } = useServiceCodesTableController();

  return serviceCodes.length ? (
    <TablePaper>
      <TableHead>
        <TableRow>
          {headers.map(header => (
            <TableCell key={header}>
              <Typography className={classes.listScreenHeaderText1}>
                {header}
              </Typography>
            </TableCell>
          ))}
          <TableCell align="center">
            <Typography className={classes.listScreenHeaderText1}>
              AÇÕES
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {serviceCodes.map(serviceCode => (
          <TableRow key={serviceCode.id}>
            <TableCell>{serviceCode.id}</TableCell>
            <TableCell>{serviceCode.service?.name || '-'}</TableCell>
            <TableCell>{serviceCode.serviceClass?.name || '-'}</TableCell>
            <TableCell>{serviceCode.code}</TableCell>
            <TableCell>{serviceCode.wasUsed ? 'SIM' : 'NÃO'}</TableCell>
            <TableCell>{serviceCode.whoUsed || '-'}</TableCell>
            <TableCell align="center">
              <IconButton
                onClick={() => handleOpenDeleteServiceCodeModal(serviceCode.id)}
              >
                <DeleteOutline color="error" />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </TablePaper>
  ) : (
    <Grid className={styles.grid}>
      <EmptyListPlaceholder message={NO_SERVICE_CODE} />
    </Grid>
  );
};

export default ServiceCodesTable;
