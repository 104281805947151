import { useCollapsed } from 'hooks/useCollapsed';
import { useCallback, useMemo } from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { sortBy } from 'lodash';
import { InstallmentsCollapsableTableCellProps } from './types';

export const useInstallmentsCollapsableTableCellController = ({
  installments,
  bills,
}: InstallmentsCollapsableTableCellProps) => {
  const { handleToggleCollapse, isExpanded } = useCollapsed();

  const sortedAndFilteredBills = useMemo(() => {
    return sortBy(
      bills.filter(bill => !!bill.billingDate),
      'billingDate',
    );
  }, [bills]);

  const getInstallmentNumber = useCallback(
    (index: number) => {
      return `${index + 1}/${installments}`;
    },
    [installments],
  );

  const getBillingMonth = useCallback((billingDateISO: string) => {
    return format(new Date(billingDateISO), 'LLLL', {
      locale: ptBR,
    });
  }, []);

  return {
    isExpanded,
    handleToggleCollapse,

    sortedBills: sortedAndFilteredBills,
    getInstallmentNumber,
    getBillingMonth,
  };
};
