import { Dependent } from './dependent';
import { FinancialGuardian } from './financialGuardian';
import { Service } from './service';

export interface InvoiceEntity {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  number?: string;
  internalStatus: INVOICE_STATUS;
  externalStatus?: any;
  externalId?: any;
  flowStatus?: any;
  printUrlPdf?: any;
  printUrlXml?: any;
  canceledAt?: any;
  invoiceFailReason?: any;
  serviceAmount: number;
  discountUnconditionedAmount: number;
  fractionsNumber: number;
  paymentId: number;
  financialGuardianId: number;
  registrationNumber: string;
  source: string;
  paymentDiscountedValue: number;
  paymentOriginalValue: number;
  paymentDiscountAmount: number;
  registrationId: number;
  dependentId: number;
  competenceDate: string;
  issuedDate?: string;
  startDateServiceProvision: string;
  endDateServiceProvision: string;
  serviceClassId: number;
  invoiceServiceCode: string;
  serviceId: number;
  errors?: any;
  service?: Service;
  financialGuardian?: FinancialGuardian;
  dependent?: Dependent;
}

export enum INVOICE_STATUS {
  NOT_ISSUED = 'Não emitida',
  REMOVED_BY_SYSTEM = 'Removido pelo sistema',
  CREATED = 'Criada',
  ISSUED = 'Emitida',
  CANCELED = 'Cancelada',
  FAILED = 'Falhou',
  NONE = 'Não definido',
  DO_NOT_ISSUE = 'Não emitir',
}

export type InvoiceGetMonthlyReport = {
  id: string;
  competence: string;
  totalDiscountUnconditionedAmount: string;
  totalAmount: string;
  nfsCount: number;
  issuedCount: number;
  notIssuedCount: number;
  doNotIssueCount: number;
  removedCount: number;
  canceledCount: number;
  createdCount: number;
};
