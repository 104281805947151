import { useMemo } from 'react';
import { ADMIN_ROUTE } from 'routes/consts';
import { PAYMENT_SOURCE } from 'types/enums';
import { PaymentSummaryProperties } from '../../types';

export const usePaymentSummaryController = ({
  payment,
}: Pick<PaymentSummaryProperties, 'payment'>) => {
  const paymentOriginLink = useMemo(() => {
    if (payment.source === PAYMENT_SOURCE.SERVICE) {
      return ADMIN_ROUTE.MARKETPLACE_SERVICE_CLASS_DETAILS.replace(
        ':id',
        String(payment.serviceId),
      ).replace(':classId', String(payment.serviceClassId));
    }
    return ADMIN_ROUTE.REGISTRATION_DETAILS.replace(
      ':id',
      String(payment.registrationId),
    );
  }, [
    payment.registrationId,
    payment.serviceClassId,
    payment.serviceId,
    payment.source,
  ]);

  const enrollmentReference = useMemo(() => {
    return payment.source === PAYMENT_SOURCE.SERVICE
      ? `Acessar matrícula de serviço #${payment.serviceClassEnrollmentId}`
      : `Acessar matrícula de ano letivo #${payment.registrationId}`;
  }, [
    payment.registrationId,
    payment.serviceClassEnrollmentId,
    payment.source,
  ]);

  return {
    paymentOriginLink,
    enrollmentReference,
  };
};
