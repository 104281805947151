import { ContainerButton, TextButton } from '../../styles';

import { FooterContainer, LoadingProgress } from './styles';
import { IFormFooterProps } from './types';

export const FormFooter = ({
  onSubmit,
  onCancel,
  type,
  loading,
}: IFormFooterProps) => {
  return (
    <FooterContainer position="absolute">
      <TextButton onClick={onCancel}>Cancelar</TextButton>
      <ContainerButton type="submit" onClick={onSubmit}>
        {type === 'create' ? 'Cadastrar parceiro' : 'Publicar alterações'}
        {loading ? <LoadingProgress size={10} /> : null}
      </ContainerButton>
    </FooterContainer>
  );
};
