import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  checkbox: {
    marginLeft: -11,
    marginTop: -9,
  },
  label: {
    width: '80%',
  },
});
