import React from 'react';

import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { PublicPageContainer } from 'components/PublicPageContainer';
import { PublicPageHeader } from 'components/PublicPageHeader';
import SpinnerButton from 'components/SpinnerButton';
import { Link } from 'react-router-dom';
import { NOT_VERIFIED } from 'texts';
import { Container } from './styles';
import { useLoginController } from './controller';

export const Login = () => {
  const {
    classes,
    styles,
    error,
    handleSubmit,
    errors,
    register,
    onSubmit,
    reSendEmail,
    email,
  } = useLoginController();

  return (
    <PublicPageContainer>
      <Container>
        <PublicPageHeader
          title="Bem-vindo(a) ao"
          titleBoldComplement="portal dos pais"
          subtitle="Faça login para acessar sua conta"
          testId="header"
        />
        <form action="submit" onSubmit={handleSubmit(onSubmit)}>
          <Grid item className={classes.publicScreenMargin2}>
            <TextField
              variant="outlined"
              label="Email"
              id="email"
              name="email"
              error={Boolean(errors.email)}
              helperText={errors.email ? errors.email.message : null}
              inputRef={register}
              className={classes.publicScreenInput}
              data-test="email"
            />
          </Grid>
          <Grid item className={classes.publicScreenMargin2}>
            <TextField
              variant="outlined"
              label="Senha"
              id="password"
              name="password"
              type="password"
              error={Boolean(errors.password)}
              helperText={errors.password ? errors.password.message : null}
              inputRef={register}
              className={classes.publicScreenInput}
              data-test="password"
            />
          </Grid>
          {error ? (
            <Typography
              className={styles.errorMessage}
              data-test="login-error-message"
            >
              <b>
                {error}{' '}
                {error && error === NOT_VERIFIED(email) ? (
                  <Button
                    className={classes.linkButton}
                    color="primary"
                    onClick={reSendEmail}
                  >
                    Não recebeu? Clique aqui para reenviar.
                  </Button>
                ) : null}
              </b>
            </Typography>
          ) : null}
          <Box display="flex" flexDirection="column">
            <Link
              to="/redefine-password"
              className={styles.link}
              data-test="redefine-password"
            >
              <Button color="primary" className={classes.linkButton}>
                Esqueci minha senha
              </Button>
            </Link>
            <SpinnerButton
              text="Entrar"
              className={classes.loginButton}
              testId="submit"
            />
          </Box>
        </form>
        <Grid item className={classes.publicScreenMargin3}>
          <Divider className={classes.publicScreenDivider} />
        </Grid>
        <Grid item container direction="row" alignItems="center" spacing={1}>
          <Grid item>Ainda não tem cadastro?</Grid>
          <Grid item>
            <Link to="/signup" data-test="signup">
              <Button color="primary" className={classes.textButton}>
                Criar conta
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </PublicPageContainer>
  );
};
