import { ColoredTableCell } from 'components/ColoredTableCell';
import { getColorPropsFromPaymentStatus } from 'pages/Admin/NewFinancialReport/FinancialReportTableEntry/utils';
import { ContractTableData } from '../../ContractsTables/types';

export const ContractRowLabelColor = (_: any, data: ContractTableData) => {
  return <LabelColorContract contract={data} />;
};

export const LabelColorContract = ({
  contract,
}: {
  contract: ContractTableData;
}) => {
  const {
    payment: { status },
  } = contract;
  return (
    <ColoredTableCell {...getColorPropsFromPaymentStatus(status)}>
      {status}
    </ColoredTableCell>
  );
};
