import React from 'react';

import ButtonBase from '@material-ui/core/ButtonBase';

import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Container } from './styles';
import { IConfirmationModalProps } from './types';

// FIXME: don't use this component, use components/CustomDialog instead
export const ConfirmationModal = ({
  open,
  title,
  description,
  confirmText,
  onCancel,
  onConfirm,
  cancelText,
  icon,
}: IConfirmationModalProps) => {
  return (
    <Container open={open}>
      <div id="top-modal-actions">
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      {icon}
      <span>{title}</span>
      <p>{description}</p>
      <div id="modal-actions">
        {cancelText && (
          <ButtonBase onClick={onCancel} id="cancel">
            {cancelText}
          </ButtonBase>
        )}
        {confirmText && (
          <ButtonBase onClick={onConfirm} id="confirm">
            {confirmText}
          </ButtonBase>
        )}
      </div>
    </Container>
  );
};
