import { Checkbox, FormControlLabel, Grid, Paper } from '@material-ui/core';
import { CustomButton } from 'components/CustomButton';
import CustomDialog from 'components/CustomDialog';
import { CustomDialogRef } from 'components/CustomDialog/types';
import { FixedFooter } from 'components/FixedFooter';
import PdfViewer from 'components/PdfViewer';
import Scaffold from 'components/Scaffold';
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { CLIENT_ROUTE } from 'routes/consts';
import ContractService from 'services/contractService';
import PaymentService from 'services/paymentService';
import { useAppStore } from 'store';
import useGlobalStyles from 'styles';
import { GENERAL_ERROR } from 'texts';
import { RouteParams } from 'types/routeParams';
import { PaymentWithContract } from './types';

const SignContract: React.FC = () => {
  const classes = useGlobalStyles();
  const showAlert = useAppStore(state => state.alert.showAlert);
  const { params } = useRouteMatch<RouteParams>();
  const [agree, setAgree] = useState(false);
  const [isSigningContract, setIsSigningContract] = useState(false);
  const [paymentWithContract, setPaymentWithContract] =
    useState<PaymentWithContract | null>(null);
  const history = useHistory();
  const { id: paymentId } = params;
  const [isContractLoading, setIsContractLoading] = useState(true);
  const modalSuccessRef = React.useRef<CustomDialogRef>(null);
  const modalErrorRef = React.useRef<CustomDialogRef>(null);

  useEffect(() => {
    const registrationPaymentContract = async () => {
      try {
        const response = await PaymentService.payment<PaymentWithContract>(
          paymentId,
          {
            fields: ['id', 'value', 'paymentMethodCode'],
            join: [
              { field: 'financialGuardian', select: ['id'] },
              {
                field: 'contractInfo',
                select: ['id', 'canceledAt', 'signDate'],
              },
              { field: 'contractInfo.document', select: ['id', 'path'] },
            ],
          },
        );

        if (response.data.contractInfo.signDate) {
          history.replace(CLIENT_ROUTE.HOME);
        } else {
          setPaymentWithContract(response.data);
        }
      } catch (error: any) {
        showAlert({
          severity: 'error',
          message: error?.message || GENERAL_ERROR,
        });
      } finally {
        setIsContractLoading(false);
      }
    };

    registrationPaymentContract();
  }, [history, paymentId, showAlert]);

  const sign = async () => {
    if (!paymentWithContract?.contractInfo?.id) {
      showAlert({
        severity: 'error',
        message: 'Referência do contrato não encontrada',
      });
      return;
    }
    setIsSigningContract(true);
    try {
      await ContractService.signContractAndChargeDraftPayment(
        paymentWithContract.contractInfo.id,
      );

      modalSuccessRef.current?.openDialog();
    } catch (error: any) {
      showAlert({
        severity: 'error',
        message: error?.message || GENERAL_ERROR,
      });
      modalErrorRef.current?.openDialog();
    } finally {
      setIsSigningContract(false);
    }
  };

  const handleGoToPayments = () => {
    if (paymentWithContract?.id) {
      history.replace(
        CLIENT_ROUTE.PAYMENT_STATUS.replace(
          ':id',
          String(paymentWithContract.id),
        ),
      );
    }
  };

  // FIXME: AINDA VAI PRECISAR?
  // const generateNewContract = async () => {
  //   if (contract) {
  //     try {
  //       setLoadingCreateNewContract(true);
  //       await ContractService.generateNewContractForced(contract?.id);
  //     } catch {
  //       setAlert(true);
  //     } finally {
  //       setLoadingCreateNewContract(false);
  //     }
  //   }
  // };
  return (
    <Scaffold
      loading={isContractLoading}
      rawTitle={<b>Solicitação de matrícula</b>}
      rawSubtitle={
        <>
          Assinatura do <b>contrato</b>
        </>
      }
    >
      <Paper elevation={0} className={classes.listScreenPaper}>
        {paymentWithContract?.contractInfo?.document?.path ? (
          <PdfViewer
            fileUrl={paymentWithContract?.contractInfo?.document?.path}
            viewHeight="800px"
          />
        ) : null}
      </Paper>

      <FixedFooter
        justifyContent="space-between"
        direction="row"
        alignItems="center"
      >
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                value={agree}
                onChange={() => setAgree(!agree)}
                color="secondary"
              />
            }
            label="Concordo com os termos do contrato"
          />
        </Grid>
        <Grid item>
          <CustomButton
            disabled={!agree || isSigningContract}
            onClick={sign}
            loading={isSigningContract}
          >
            Assinar e fazer pagamento
          </CustomButton>
        </Grid>
      </FixedFooter>

      <CustomDialog
        ref={modalSuccessRef}
        rawTitle={
          <>
            Contrato <b>assinado com sucesso!</b>
          </>
        }
        rawSubtitle="Siga para a tela de finalização da contratação e acompanhe os detalhes do pagamento."
        primaryButton={{
          text: 'Finalizar contratação',
          onClick: handleGoToPayments,
          textVariant: 'bold',
        }}
        icon={{
          name: 'checkCircle',
        }}
      />
      <CustomDialog
        ref={modalErrorRef}
        rawTitle="Tivemos um problema com a contratação."
        rawSubtitle={
          <>
            <b>Entre em contato</b> com a administração da escola para resolver
            a situação.
          </>
        }
        primaryButton={{
          text: 'Voltar ao painel principal',
          onClick: modalErrorRef.current?.closeDialog,
          textVariant: 'bold',
        }}
      />
    </Scaffold>
  );
};
export default SignContract;
