import { CustomButton } from 'components/CustomButton';
import React from 'react';
import { CSVLink } from 'react-csv';
import { useExportExcelButtonController } from './controller';
import { ExportProperties } from './types';

/** @deprecated refactor to use this functionality via CsvService/index.ts */
const ExportExcelButton: React.FC<ExportProperties> = ({
  data,
  fileName,
  onClick,
}) => {
  const { csvLinkRef, csvData, isLoading, handleFetchCsvData } =
    useExportExcelButtonController({
      data,
      onClick,
    });

  return (
    <>
      <CustomButton
        variant="primary"
        textVariant="bold"
        onClick={handleFetchCsvData}
        loading={isLoading}
      >
        Exportar
      </CustomButton>

      <CSVLink
        ref={csvLinkRef}
        style={{ color: 'inherit' }}
        data={csvData}
        filename={fileName || `relatorio_${new Date().getTime()}.csv`}
        separator=";"
      />
    </>
  );
};

export default ExportExcelButton;
