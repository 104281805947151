import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  margin: {
    marginBottom: 27,
  },
  processing: {
    marginTop: 43,
    fontSize: 24,
    marginBottom: 27,
  },
});
