import { Box, Button, Dialog, Grid, Typography } from '@material-ui/core';
import React from 'react';
import SpinnerButton from 'components/SpinnerButton';
import useGlobalStyles from 'styles';
import { ModalProperties } from './types';

// FIXME: don't use this component, use components/CustomDialog instead
const ConfirmModal: React.FC<ModalProperties> = ({
  open,
  close,
  title,
  message,
  buttonFn,
  buttonText,
}) => {
  const classes = useGlobalStyles();

  return (
    <Dialog open={open}>
      <Box
        style={{ width: 585, padding: 35 }}
        display="flex"
        flexDirection="column"
      >
        <Typography style={{ fontSize: 18 }}>
          <b>{title}</b>
        </Typography>
        <Typography style={{ fontSize: 16, marginBottom: 70 }}>
          {message}
        </Typography>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={3}
          className={classes.screenButtonGrid}
        >
          <Grid item>
            <Button
              onClick={() => close()}
              color="primary"
              className={classes.textButton}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <SpinnerButton
              action={() => buttonFn()}
              text={buttonText}
              className={classes.newContractButton}
            />
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default ConfirmModal;
