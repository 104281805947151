import React from 'react';

import { PaperContainer } from './styles';
import { IPartnersFormProps } from './types';
import { FieldHeader } from './components/FieldHeader';
import { GeneralDataForm } from './components/GeneralDataForm';
import { ExtraDataForm } from './components/ExtraDataForm';
import { MarketplaceAccessDataForm } from './components/MarketplaceAccessDataForm';

export const PartnersForm = ({
  control,
  errors,
  register,
  setValue,
  setAvatar,
  partnerToUpdate,
}: IPartnersFormProps) => {
  return (
    <PaperContainer elevation={0}>
      <FieldHeader title="Dados gerais" />
      <GeneralDataForm
        control={control}
        register={register}
        errors={errors}
        setValue={setValue}
        partnerToUpdate={partnerToUpdate}
      />

      <FieldHeader title="Informação extra" />
      <ExtraDataForm
        control={control}
        register={register}
        errors={errors}
        setValue={setValue}
        setAvatar={setAvatar}
        partnerToUpdate={partnerToUpdate}
      />

      <FieldHeader title="Acesso ao marketplace" />
      <MarketplaceAccessDataForm
        control={control}
        register={register}
        errors={errors}
        setValue={setValue}
        setAvatar={setAvatar}
        partnerToUpdate={partnerToUpdate}
      />
    </PaperContainer>
  );
};
