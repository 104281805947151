import { Dialog, Grid, Typography } from '@material-ui/core';
import { CustomButton } from 'components/CustomButton';
import { CustomIcon } from 'components/CustomIcon';
import React, { forwardRef, useImperativeHandle } from 'react';
import { useStyles } from './styles';
import { CustomDialogProps, CustomDialogRef } from './types';

// NOTE: Alert of duplicated code
// TODO: move to components/dialogs and use GenericDialog component as wrapper
const CustomDialog = forwardRef<CustomDialogRef, CustomDialogProps>(
  (props, ref) => {
    const {
      rawSubtitle,
      rawTitle,
      primaryButton,
      isVisibleByDefault,
      secondaryButton,
      icon,
      rawHeader,
    } = props;
    const classes = useStyles();
    const [isDialogVisible, setIsDialogVisible] = React.useState(
      !!isVisibleByDefault,
    );
    const [actionsIsDisabled, setActionsIsDisabled] = React.useState(false);

    useImperativeHandle(ref, () => ({
      openDialog() {
        setIsDialogVisible(true);
      },
      closeDialog() {
        setIsDialogVisible(false);
      },
      disableDialogActions(disable: boolean) {
        setActionsIsDisabled(disable);
      },
    }));

    return (
      <Dialog
        open={isDialogVisible}
        PaperProps={{
          className: classes.dialog,
        }}
      >
        {rawHeader ||
          (icon ? (
            <Grid item className={classes.dialogIcon}>
              <CustomIcon {...icon} />
            </Grid>
          ) : null)}
        <Grid item>
          <Typography className={classes.dialogText}>{rawTitle}</Typography>
          <Typography className={classes.dialogSubText}>
            {rawSubtitle}
          </Typography>
        </Grid>

        {primaryButton || secondaryButton ? (
          <Grid item className={classes.dialogActions}>
            {secondaryButton ? (
              <CustomButton
                {...secondaryButton}
                variant="secondary"
                disabled={actionsIsDisabled || secondaryButton.disabled}
              />
            ) : null}
            {primaryButton ? (
              <CustomButton
                {...primaryButton}
                variant="primary"
                disabled={actionsIsDisabled || primaryButton.disabled}
              />
            ) : null}
            {/* TODO: variant: danger (CustomButton) */}
          </Grid>
        ) : null}
      </Dialog>
    );
  },
);

export default CustomDialog;
