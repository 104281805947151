import Grid from '@material-ui/core/Grid';
import { ControllerInputText } from 'components/Forms/ControllerInputText';
import { ControllerSelectInput } from 'components/Forms/ControllerSelectInput/ControllerSelectInput';
import { IGeneraFormProps } from 'pages/Admin/Marketplace/Services/components/ServiceForm/types';
import {
  EARLY_ACCESS_SELECT_OPTIONS,
  SERVICE_EARLY_ACCESS_EXPLANATION,
  SERVICE_INVITE_CODE_EXPLANATION,
} from '../../utils';

export const EarlyAccessDataForm = ({
  control,
  register,
  errors,
  serviceToUpdate,
}: IGeneraFormProps) => {
  return (
    <Grid container spacing={2} md={12}>
      <Grid item md={6}>
        <ControllerInputText
          name="inviteCode"
          control={control}
          defaultValue={serviceToUpdate?.inviteCode}
          label="Código de convite"
          errors={errors}
          register={register}
          helperText={SERVICE_INVITE_CODE_EXPLANATION}
        />
      </Grid>
      <Grid item md={6}>
        <ControllerSelectInput
          name="earlyAccess"
          label="Acesso antecipado"
          control={control}
          options={EARLY_ACCESS_SELECT_OPTIONS}
          errors={errors}
          register={register}
          defaultValue={String(serviceToUpdate?.earlyAccess || false)}
          helperText={SERVICE_EARLY_ACCESS_EXPLANATION}
        />
      </Grid>
    </Grid>
  );
};
