import { postGenerateCsv } from '../apiService';
import { CsvRequestParams } from './types';
import { downloadFile } from './utils';

export default class CsvService {
  static async downloadFinancialReport(params: CsvRequestParams) {
    try {
      const response = await postGenerateCsv({
        url: 'payments/financial-report',
        queryParams: params.query,
        body: {
          config: params.config,
        },
      });

      downloadFile(response.data, 'relatorio-financeiro');
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
