import { CreateQueryParams } from '@nestjsx/crud-request';
import {
  api,
  getAxiosResponseMessageErrorOrDefault,
  getWithFilter,
} from 'services/apiService';
import { ISchoolClass } from 'types/schoolClass';
import {
  SchoolClassDetailCreate,
  SchoolClassDetailsUpdate,
} from 'types/schoolClassDetails';

export default class TuitionService {
  static async tuitions() {
    try {
      return await api.get('school-class');
    } catch (error: any) {
      return error.response;
    }
  }

  static async getSchoolClassById(schoolClassId: number | string) {
    try {
      return await api.get(`school-class/${schoolClassId}`);
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }

  static async filterTuitions(query?: CreateQueryParams) {
    try {
      return await getWithFilter('/school-class', query);
    } catch (err: any) {
      return err.response;
    }
  }

  static async newClass(schoolClass: Partial<ISchoolClass>) {
    try {
      return await api.post('school-class', schoolClass);
    } catch (error: any) {
      return error.response;
    }
  }

  static async updateTuition(
    id: number,
    tuition: Pick<ISchoolClass, 'invoiceServiceCode'>,
  ) {
    try {
      return await api.patch(`school-class/${id}`, {
        invoiceServiceCode: tuition.invoiceServiceCode,
      });
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }

  static async tuitionsDetails(query?: CreateQueryParams) {
    try {
      return await getWithFilter(`schoolClassDetails`, query);
    } catch (error: any) {
      return error.response;
    }
  }

  static async createDetail(schoolClassDetails: SchoolClassDetailCreate) {
    try {
      return await api.post(`schoolClassDetails`, schoolClassDetails);
    } catch (error: any) {
      return error.response;
    }
  }

  static async updateDetail(
    id: number,
    schoolClassDetails: Pick<SchoolClassDetailsUpdate, 'vacancies' | 'value'>,
  ) {
    try {
      return await api.put(`schoolClassDetails/${id}`, {
        vacancies: schoolClassDetails.vacancies,
        value: schoolClassDetails.value,
      });
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }

  static async deleteDetail(id: number) {
    try {
      return await api.delete(`schoolClassDetails/${id}`);
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }
}
