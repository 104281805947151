import { GenericDialogRef } from 'components/dialogs/GenericDigalog/types';
import { MenuItem } from 'components/HeaderBar/types';
import {
  NestCrudTableFetchData,
  NestCrudTableRef,
} from 'components/NestCrudTable/types';
import { ActionsColumn } from 'components/Table/components/ActionsColumn';
import { TableColumnConfig } from 'components/Table/types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import TuitionService from 'services/tuitionService';
import { useAppStore } from 'store';
import { RouteParams } from 'types/routeParams';
import { ISchoolClass } from 'types/schoolClass';
import { SchoolClassDetails } from 'types/schoolClassDetails';
import { formatBRL } from 'utils';

export const useSchooClassDetailController = () => {
  // Navigation
  const { id: schoolClassId } = useParams<RouteParams>();

  const tableRef = useRef<NestCrudTableRef>(null);
  const editSchoolClassDetailsModalRef = useRef<GenericDialogRef>(null);
  const editConfigInvoiceServiceCodeModalRef = useRef<GenericDialogRef>(null);

  // States
  const [schoolClassDetails, setSchoolClassDetails] = useState<ISchoolClass>();
  const showAlert = useAppStore(state => state.alert.showAlert);
  const showLoading = useAppStore(state => state.snackbarLoading.showLoading);
  const closeLoading = useAppStore(state => state.snackbarLoading.closeLoading);
  const [modalCreateTuitionOpen, setModalCreateTuitionOpen] = useState(false);
  const [selectedClassDetail, setSelectedClassDetail] =
    useState<SchoolClassDetails>();

  const handleLoadSchoolClassDetails = useCallback(async () => {
    try {
      const { data: response } = await TuitionService.getSchoolClassById(
        schoolClassId,
      );

      setSchoolClassDetails(response);
    } catch (error: any) {
      showAlert({
        message: error?.message || 'Erro ao carregar detalhes da série',
        severity: 'warning',
      });
    }
  }, [schoolClassId, showAlert]);

  const handleCloseCreateModal = () => {
    setModalCreateTuitionOpen(false);
  };

  const handleOpenCreateModal = () => {
    setModalCreateTuitionOpen(true);
  };

  const handleDeleteSchoolClassDetail = useCallback(
    async (schoolClassDetail: SchoolClassDetails) => {
      if (
        new Date().getFullYear() === Number(schoolClassDetail.referenceYear)
      ) {
        showAlert({
          message: 'Não é possível deletar o ano letivo vigente',
          severity: 'warning',
        });
        return;
      }

      showLoading({
        message: `Deletando ano letivo ${schoolClassDetail.referenceYear} da série ${schoolClassDetail.schoolClassName}...`,
      });
      try {
        await TuitionService.deleteDetail(schoolClassDetail.id);
        tableRef.current?.fetchData();
        showAlert({
          message: `Ano letivo ${schoolClassDetail.referenceYear} da série ${schoolClassDetail.schoolClassName} deletado com sucesso`,
          severity: 'success',
        });
      } catch (error: any) {
        showAlert({
          message: error?.message || 'Erro ao deletar ano letivo da série',
          severity: 'error',
        });
      } finally {
        closeLoading();
      }
    },
    [closeLoading, showAlert, showLoading],
  );

  const handleCloseSchoolClassDetailModal = () => {
    editSchoolClassDetailsModalRef.current?.closeDialog();
    setSelectedClassDetail(undefined);
  };

  const handleEditSchoolClassDetail = useCallback(
    (schoolClassDetail: SchoolClassDetails) => {
      setSelectedClassDetail(schoolClassDetail);
      editSchoolClassDetailsModalRef.current?.openDialog();
    },
    [],
  );

  const handleCloseConfigInvoiceServiceCode = () => {
    editConfigInvoiceServiceCodeModalRef.current?.closeDialog();
  };

  const handleEditConfigInvoiceServiceCode = () => {
    editConfigInvoiceServiceCodeModalRef.current?.openDialog();
  };

  const loadSchoolClassDetail: NestCrudTableFetchData<SchoolClassDetails> =
    useCallback(
      async ({ page, perPage }) => {
        const { data: response } = await TuitionService.tuitionsDetails({
          page,
          limit: perPage,
          sort: [{ field: 'referenceYear', order: 'DESC' }],
          filter: [
            {
              field: 'schoolClassId',
              value: Number(schoolClassId),
              operator: '$eq',
            },
          ],
        });

        return response;
      },
      [schoolClassId],
    );

  const tableConfig: TableColumnConfig<SchoolClassDetails>[] = useMemo(() => {
    return [
      {
        getter: 'referenceYear',
        headerName: 'Ano letivo',
        defaultValue: '-',
      },
      {
        headerName: 'Anuidade',
        getter: 'value',
        defaultValue: '0',
        onFormat: formatBRL,
      },
      {
        headerName: 'Vagas',
        getter: 'vacancies',
        defaultValue: '-',
        onFormat: (value: number, data: SchoolClassDetails) =>
          `${data.remainingVacancies}/${value} restantes`,
      },
      {
        getter: 'id',
        headerName: 'Ações',
        align: 'right',
        customRender(_value, data) {
          return (
            <ActionsColumn
              onDelete={{
                onClick: () => handleDeleteSchoolClassDetail(data),
              }}
              onEdit={{
                onClick: () => handleEditSchoolClassDetail(data),
              }}
            />
          );
        },
      },
    ] as TableColumnConfig<SchoolClassDetails>[];
  }, [handleDeleteSchoolClassDetail, handleEditSchoolClassDetail]);

  useEffect(() => {
    handleLoadSchoolClassDetails();
  }, [handleLoadSchoolClassDetails]);

  const getMenuItems = (): MenuItem[] => {
    const menuItems: MenuItem[] = [
      {
        title: 'Cadastrar novo ano',
        action: handleOpenCreateModal,
      },
      {
        title: 'Configurar código de serviço nota fiscal',
        action: handleEditConfigInvoiceServiceCode,
      },
    ];

    return menuItems;
  };

  return {
    tableRef,
    tableConfig,
    schoolClassDetails,
    loadSchoolClassDetail,
    schoolClassId,
    modalCreateTuitionOpen,
    handleCloseCreateModal,
    handleOpenCreateModal,
    editSchoolClassDetailsModalRef,
    selectedClassDetail,
    handleCloseSchoolClassDetailModal,
    menuItems: getMenuItems(),
    handleCloseConfigInvoiceServiceCode,
    editConfigInvoiceServiceCodeModalRef,
  };
};
