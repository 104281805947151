import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core';
import { ControllerInputText } from 'components/Forms/ControllerInputText';
import { FormDialog } from 'components/dialogs/FormDialog';
import { GenericDialogRef } from 'components/dialogs/GenericDigalog/types';
import { RefObject, useState } from 'react';
import { useForm } from 'react-hook-form';
import ConfigurationsService from 'services/configurationsService';
import { useAppStore } from 'store';
import { DATE_MASK } from 'texts';
import { shallow } from 'zustand/shallow';
import { registrationPeriodValidationSchema } from './schema';
import { SubmitRegistrationPeriodData } from './types';

// TODO: criar controller
export const RegistrationPeriodModal = ({
  registrationPeriodFormRef,
}: {
  registrationPeriodFormRef: RefObject<GenericDialogRef>;
}) => {
  const { configurations, showAlert, updateConfigurations } = useAppStore(
    state => ({
      configurations: state.configurations.data,
      updateConfigurations: state.configurations.updateConfigurations,
      showAlert: state.alert.showAlert,
    }),
    shallow,
  );

  const { handleSubmit, errors, register, control } = useForm({
    resolver: yupResolver(registrationPeriodValidationSchema),
    reValidateMode: 'onBlur',
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleCloseDialog = () => {
    registrationPeriodFormRef.current?.closeDialog();
  };

  async function onSubmit(data: SubmitRegistrationPeriodData) {
    if (!configurations?.id) {
      console.error('Configurações não encontradas.');
      return;
    }
    setIsLoading(true);

    try {
      const response = await ConfigurationsService.patchConfigurations(
        configurations.id,
        data,
      );
      updateConfigurations({
        enrollmentYear: response.data.enrollmentYear,
        periodEnd: response.data.periodEnd,
        periodStart: response.data.periodStart,
      });
      registrationPeriodFormRef.current?.closeDialog();
      showAlert({
        message: 'Periodo de matrícula atualizado com sucesso!',
        severity: 'success',
      });
    } catch (error: any) {
      showAlert({
        message:
          error?.message ||
          'Falha ao atualizar o periodo de matricula. Verifique os campos e tente novamente.',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }

  if (!configurations) {
    return <b>Configuração do sistema não encontrada</b>;
  }

  return (
    <FormDialog
      ref={registrationPeriodFormRef}
      headerTitle="Periodo de matrícula"
      primaryButton={{
        text: 'Salvar',
        loading: isLoading,
        onClick: handleSubmit(onSubmit),
      }}
      secondaryButton={{
        text: 'Voltar',
        disabled: isLoading,
        onClick: handleCloseDialog,
      }}
    >
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12} md={4}>
          <ControllerInputText
            name="periodStart"
            label="Início"
            errors={errors}
            register={register}
            control={control}
            inputRef={register}
            mask={DATE_MASK}
            type="mask"
            defaultValue={configurations?.periodStart}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={4}>
          <ControllerInputText
            name="periodEnd"
            label="Término"
            errors={errors}
            register={register}
            control={control}
            inputRef={register}
            mask={DATE_MASK}
            type="mask"
            defaultValue={configurations?.periodEnd}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={4}>
          <ControllerInputText
            name="enrollmentYear"
            label="Ano letivo"
            errors={errors}
            register={register}
            control={control}
            inputRef={register}
            mask={DATE_MASK}
            type="number"
            defaultValue={configurations?.enrollmentYear}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
};
