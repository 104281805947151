import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import PageHeader from 'components/PageHeader';
import React from 'react';
import { useStyles } from './styles';
import { ScaffoldProps } from './types';

// TODO: improve this component for long usage
const Scaffold: React.FC<ScaffoldProps> = ({
  children,
  rawSubtitle,
  rawTitle,
  loading = false,
  headerButton,
}) => {
  const styles = useStyles();

  return (
    <Box className={clsx(styles.scaffold)}>
      <PageHeader
        rawTitle={rawTitle}
        rawSubtitle={rawSubtitle}
        button={headerButton}
      />

      {loading ? (
        <Box className={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </Box>
  );
};

export default Scaffold;
