import { makeStyles } from '@material-ui/core';
import { primaryMedium } from 'styles/theme';

export const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: 20,
    borderRadius: 30,
    width: '100%',
    boxShadow: '0px 6px 32px rgba(9, 13, 27, 0.07)',
    height: 170,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 54,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20,
    },
  },
  img: {
    borderRadius: 30,
    width: 96,
    height: 96,
  },
  grow: {
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
    },
  },
  name: {
    fontWeight: 'bold',
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 19,
    },
  },
  text: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  navigateButton: {
    color: primaryMedium,
    minWidth: 'max-content',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      marginTop: 10,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  schoolGrade: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  container: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
  },
  columnOne: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20,
    },
  },
  columnTwo: {
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-end',
      paddingRight: 15,
    },
  },
}));
