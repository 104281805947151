import { User } from '../types/user';
import { api } from './apiService';

const TOKEN_KEY = 'jwtToken';
const CURRENT_USER = 'currentUser';

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const storeToken = (token: string) =>
  localStorage.setItem(TOKEN_KEY, token);

export const storeUser = (user: User) =>
  localStorage.setItem(CURRENT_USER, JSON.stringify(user));

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(CURRENT_USER);
};

export const getCurrentUser = () =>
  JSON.parse(localStorage.getItem(CURRENT_USER) || '{}');

export default class AuthService {
  static async recoverPassword(email: string) {
    try {
      return await api.post('/auth/forgot-password', {
        email,
      });
    } catch (error: any) {
      throw new Error(error.response);
    }
  }

  static async verifyEmail(email: string) {
    try {
      return api.post(`/auth/verify-email`, { email });
    } catch (error: any) {
      throw new Error(error.response);
    }
  }

  static async validateToken(token: string) {
    try {
      return api.get(`/auth/verify-token/${token}`);
    } catch (error: any) {
      throw new Error(error);
    }
  }

  static async resetPasswordWithToken(token: string, password: string) {
    try {
      return await api.post(`/auth/forgot-password/new/${token}`, {
        password,
      });
    } catch (error: any) {
      throw new Error(error.response);
    }
  }

  static async changePassword(
    email: string,
    password: string,
    newPassword: string,
  ) {
    try {
      return await api.put('auth/change-password', {
        email,
        password,
        newPassword,
      });
    } catch (error: any) {
      return error.response;
    }
  }

  static async validateUser(token: string) {
    try {
      return await api.get(`/auth/validate-user/${token}`);
    } catch (error: any) {
      throw new Error(error.response);
    }
  }

  static async getNewToken(id: number) {
    try {
      return await api.post(`/auth/generate-token/${id}`);
    } catch (error: any) {
      throw new Error(error.response);
    }
  }

  static async activateAccount(token: string) {
    try {
      return await api.post(`/auth/activate-account/${token}`);
    } catch (error: any) {
      throw new Error(error.response);
    }
  }

  static async createTemporaryToken() {
    try {
      return await api.post(`/auth/temporary-sessions`);
    } catch (error: any) {
      throw new Error(error.response);
    }
  }
}
