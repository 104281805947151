import React from 'react';
import { Box } from '@material-ui/core';

import { Text } from './styles';

export const EmptyTable = () => {
  return (
    <Box display="flex" width="100%" justifyContent="center">
      <Text>Ainda não existem dados para serem exibidos</Text>
    </Box>
  );
};
