import {
  EARLY_ACCESS,
  INVITE_CODE,
} from 'pages/Client/NewMarketplace/components/ServiceCard/texts';
import { useCallback } from 'react';
import { CoverImage } from '../styles';
import { ICoverProps } from '../types';
import { BannerInfo } from './BannerInfo';
import { StretchedContainer } from './styles';

// Voltar um codigo daqui: https://github.com/fleye-me/jps_frontend/pull/461/files#
export const Cover = ({
  coverPhoto,
  serviceIsEarlyAccess,
  serviceIsOnlyByInviteCode,
  serviceClassIsSoldOut,
}: ICoverProps) => {
  const renderBanner = useCallback(() => {
    if (serviceClassIsSoldOut) {
      return (
        <BannerInfo
          text="Vagas esgotadas - Selecione outra turma"
          variant="warning"
        />
      );
    }

    if (serviceIsEarlyAccess) {
      return (
        <BannerInfo
          text="Precisa do código de acesso antecipado"
          variant="secondary"
          toolTipText={EARLY_ACCESS}
        />
      );
    }

    if (serviceIsOnlyByInviteCode) {
      return (
        <BannerInfo
          text="Apenas convidados, precisa do código de convite"
          variant="primary"
          toolTipText={INVITE_CODE}
        />
      );
    }

    return null;
  }, [serviceClassIsSoldOut, serviceIsEarlyAccess, serviceIsOnlyByInviteCode]);

  return (
    <StretchedContainer>
      <CoverImage coverImage={coverPhoto} />
      {renderBanner()}
    </StretchedContainer>
  );
};
