import { useCallback, useState } from 'react';
import { DEFAULT_ALERT } from './consts';
import { IShowAlert, MESSAGE_TYPE } from './types';

export const useAlert = () => {
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState<MESSAGE_TYPE>(
    MESSAGE_TYPE.SUCCESS,
  );

  const openAlert = useCallback(
    ({ message, type }: IShowAlert = DEFAULT_ALERT) => {
      setAlertMessage(message);
      setAlertType(type || MESSAGE_TYPE.SUCCESS);
      setIsShowAlert(true);
    },
    [],
  );

  const closeAlert = useCallback(() => {
    setIsShowAlert(false);
    setAlertMessage(DEFAULT_ALERT.message);
    setAlertType(MESSAGE_TYPE.SUCCESS);
  }, []);

  const openAlertForDuration = useCallback(
    ({ message, type }: IShowAlert = DEFAULT_ALERT, ms = 5000) => {
      openAlert({ message, type });

      setTimeout(() => {
        closeAlert();
      }, ms);
    },
    [closeAlert, openAlert],
  );

  return {
    isShowAlert,
    alertMessage,
    alertType,
    openAlert,
    closeAlert,
    openAlertForDuration,
  };
};
