import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export const useFooterActionsController = () => {
  // Navigation
  const history = useHistory();

  // Callbacks
  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return { handleGoBack };
};
