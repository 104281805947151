import { CondOperator } from '@nestjsx/crud-request';
import { StatusCodes } from 'http-status-codes';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ConfigurationsService from 'services/configurationsService';
import DependentService from 'services/dependentService';
import useGlobalStyles from 'styles';
import { Dependent } from 'types/dependent';
import { latestRegistration } from '../Registrations/utils';
import { useStyles } from './styles';

export const useHomeController = () => {
  // Styles
  const classes = useGlobalStyles();
  const styles = useStyles();

  // Navigation
  const history = useHistory();

  // States
  const [alert, setAlert] = useState(false);
  const [dependents, setDependents] = useState<Dependent[]>([]);
  const [period, setPeriod] = useState({ end: '' });

  // Callbacks
  const loadDependents = useCallback(async () => {
    const { data: response, status } = await DependentService.queryDependents({
      join: [['registrations'], ['registrations.documents']],
      filter: {
        field: 'registrations.id',
        operator: CondOperator.NOT_NULL,
      },
      limit: 2,
    });

    if (status === StatusCodes.OK) {
      setDependents(response.data);
    } else {
      setAlert(true);
    }
  }, []);

  const loadConfiguration = useCallback(async () => {
    const { data: response } =
      await ConfigurationsService.getReregistrationInfo();

    setPeriod({
      end: response.periodEnd,
    });
  }, []);

  const goToDetails = (dependentData: Dependent) => () => {
    const registrationId = latestRegistration(dependentData).id;
    history.push({
      pathname: `/dependents/${registrationId}`,
    });
  };

  const closeAlert = () => {
    setAlert(false);
  };

  // Effects
  useEffect(() => {
    loadDependents();
    loadConfiguration();
  }, [loadDependents, loadConfiguration]);

  return {
    classes,
    styles,
    alert,
    dependents,
    goToDetails,
    closeAlert,
    period,
  };
};
