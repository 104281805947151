import { CreateQueryParams } from '@nestjsx/crud-request';
import { AxiosResponse } from 'axios';
import { MarketplacePurchase } from 'pages/Client/MarketplacePaymentCheckout/types';
import { api, getWithFilter } from 'services/apiService';

const MODULE_PATH = 'orders';

export default class OrderService {
  static async getOrderContracts(orderId: string | number) {
    try {
      return await api.get(`${MODULE_PATH}/${orderId}/contracts`);
    } catch (error: any) {
      return error.response;
    }
  }

  static async getOrderDetails(
    orderId: string | number,
    query?: CreateQueryParams,
  ) {
    try {
      return await getWithFilter(`${MODULE_PATH}/${orderId}`, query);
    } catch (error: any) {
      return error.response;
    }
  }

  static async createDraftOrder(itemsToBePurchased: MarketplacePurchase[]) {
    try {
      return await api.post(`${MODULE_PATH}/draft`, {
        itemsToBePurchased,
      });
    } catch (error: any) {
      return error.response;
    }
  }

  static async signContracts<T = unknown>(
    orderId: string,
  ): Promise<AxiosResponse<T>> {
    try {
      return await api.put(`${MODULE_PATH}/${orderId}/sign-contracts`);
    } catch (err: any) {
      return err.response;
    }
  }
}
