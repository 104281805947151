import { ServiceCategoryResponseType } from 'pages/Admin/Marketplace/Services/components/ServiceForm/types';
import { useServiceCreationController } from 'pages/Admin/Marketplace/Services/ServiceCreation/controller';
import { useEffect, useRef, useState } from 'react';
import ServicesService from 'services/servicesService';

export const useGeneralDataFormController = () => {
  // General Hooks
  const { renderValue } = useServiceCreationController();

  // Refs
  const inputRefMenuDimensions = useRef<HTMLInputElement>(null);

  // States
  const [serviceCategories, setServiceCategories] =
    useState<ServiceCategoryResponseType>([]);

  // Callbacks
  const getResources = async () => {
    const { data: response } = await ServicesService.getCategories();

    setServiceCategories(response.data);
  };

  useEffect(() => {
    getResources();
  }, []);

  return {
    inputRefMenuDimensions,
    serviceCategories,
    renderValue,
  };
};
