import {
  Grid,
  List,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import LogoImg from 'assets/logoImg.png';
import AcceptTermsModal from 'components/AcceptTermsModal';
import { HeaderBar } from 'components/HeaderBar';
import { useAuth } from 'contexts/auth';
import { handleOpenTerms } from 'pages/Client/SignUp/utils';
import { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { USER_ROLES } from 'types/enums';
import MenuItem from './MenuItem';
import { useStyles } from './styles';
import { ISideMenuProps, Route } from './types';
import { getRoutesByUserRoles } from './utils';

export const SideMenu = ({ children }: ISideMenuProps) => {
  const { user } = useAuth();
  const isAdmin = !!user?.isAdmin;
  const isMobile = useMediaQuery('(max-width:959px)');
  const styles = useStyles({ isAdmin })();
  const location = useLocation();
  const [routes, setRoutes] = useState<Route[]>([]);
  const [acceptTermsModal, setAcceptTermsModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (user) {
      setRoutes(getRoutesByUserRoles(user.roles));
      if (
        user.createdByAdmin &&
        !user.acceptedTerms &&
        user.roles.includes(USER_ROLES.GUARDIAN)
      ) {
        setAcceptTermsModal(true);
      }
    }
  }, [user]);

  const toggleDrawer = (open: boolean) => () => {
    setOpenDrawer(open);
  };

  const RoleLabelMessage = useCallback(() => {
    if (user?.roles.includes(USER_ROLES.GUARDIAN)) {
      return (
        <Typography className={styles.termsBtn} onClick={handleOpenTerms}>
          Termo e condições de uso
        </Typography>
      );
    }
    if (user?.roles.includes(USER_ROLES.ADMIN)) {
      return (
        <Typography className={styles.accessRoles}>
          Nível de acesso <b>Direção Geral</b>
        </Typography>
      );
    } else {
      return (
        <Typography className={styles.accessRoles}>
          Nível de acesso <b>{user?.roles[0]}</b>
        </Typography>
      );
    }
  }, [styles.accessRoles, styles.termsBtn, user?.roles]);

  return (
    <>
      <HeaderBar
        isAdmin={isAdmin}
        isMobile={isMobile}
        toggleMenu={toggleDrawer}
        menuOpen={openDrawer}
      />
      <Grid container justifyContent="flex-end">
        <Grid item>
          <SwipeableDrawer
            className={styles.drawer}
            open={isMobile ? openDrawer : true}
            onOpen={toggleDrawer(true)}
            onClose={toggleDrawer(false)}
            variant={isMobile ? 'temporary' : 'permanent'}
            classes={{
              paper: styles.drawerPaper,
            }}
            anchor="left"
          >
            <AcceptTermsModal
              open={acceptTermsModal}
              close={() => setAcceptTermsModal(false)}
            />
            <img src={LogoImg} className={styles.logo} alt="jpsul" />
            <List className={styles.list}>
              {routes.map(route => {
                const color = location.pathname.includes(route.route)
                  ? 'primary'
                  : 'secondary';

                return (
                  <Link
                    key={route.title}
                    to={{ pathname: route.route }}
                    onClick={() => route?.onClick?.() ?? undefined}
                  >
                    <MenuItem
                      active={location.pathname.includes(route.route)}
                      title={route.title}
                      icon={<route.icon color={isAdmin ? 'inherit' : color} />}
                      isAdmin={isAdmin}
                    />
                  </Link>
                );
              })}
            </List>
            <RoleLabelMessage />
          </SwipeableDrawer>
        </Grid>
        <Grid item>
          <main className={styles.content}>
            <div className={styles.toolbar} />
            {children}
          </main>
        </Grid>
      </Grid>
    </>
  );
};
