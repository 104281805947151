import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import { useCallback, useMemo } from 'react';
import { SelectInputProps, ISelectItem } from './types';

export const useSelectInputController = ({
  options,
  isMultiple,
  defaultValue,
}: Pick<SelectInputProps, 'options' | 'isMultiple' | 'defaultValue'>) => {
  const valuesDictionary = useMemo(() => {
    return options.reduce((acc, option) => {
      acc[String(option.id)] = option.label;
      return acc;
    }, {} as { [key: string]: string });
  }, [options]);

  const SelectComponent = useMemo(
    () => (isMultiple ? Checkbox : Radio),
    [isMultiple],
  );

  const isChecked = useCallback(
    (value, optionItem: ISelectItem) => {
      if (isMultiple) {
        const arrayValues = (Array.isArray(value) ? value : []) as any[];
        return arrayValues.some(
          selectedValue => selectedValue === optionItem.id,
        );
      }
      return optionItem.id === value;
    },
    [isMultiple],
  );

  const renderValue = useCallback(
    (values: unknown) => {
      if (Array.isArray(values)) {
        return (values as number[])
          .map(selectedValue => valuesDictionary[String(selectedValue)])
          .join(', ');
      }

      return valuesDictionary[String(values)];
    },
    [valuesDictionary],
  );

  const parsedDefaultValue = useMemo(() => {
    if (defaultValue) return defaultValue;

    return isMultiple ? [] : '';
  }, [defaultValue, isMultiple]);

  return {
    SelectComponent,
    isChecked,
    renderValue,
    parsedDefaultValue,
  };
};
