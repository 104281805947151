import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  dialog: {
    maxWidth: '625px',
    borderRadius: '20px',
    padding: '36px 48px',
  },
  justifyAtCenter: {
    justifyContent: 'center',
  },
  justifyAtEnd: {
    justifyContent: 'flex-end',
  },
  dialogActions: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '36px',
    flexDirection: 'row',
    gap: '10px',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
}));
