import { styled, Theme } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { InputTextStyleProps } from 'pages/Admin/Marketplace/Services/components/ServiceForm/types';
import { borderGray } from 'styles/theme';

export const SelectMenuItemDivider = styled(Divider)(() => {
  return {
    width: '85%',
    borderTop: '0.5px solid #979797',
    marginLeft: 25,
    marginBottom: 10,
  };
});

export const InputText = styled(TextField)<Theme, InputTextStyleProps>(
  ({ fixedWidth, innerSelectedColor }) => {
    return {
      fontSize: '14px',
      width: fixedWidth ? '116px' : '100%',
      '& .MuiInputBase-root': {
        backgroundColor: 'white',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1.45px solid ${borderGray}`,
        fontSize: 16,
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
      },
      '& .MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock':
        {
          fontSize: '14px',
          height: '9px',
          marginTop: '-5px',
        },
      '& .MuiSelect-root ': innerSelectedColor
        ? {
            margin: 8,
            height: '0px',
            backgroundColor: innerSelectedColor,
          }
        : undefined,
      '& .material-icons': {
        marginRight: 15,
      },
      '& .MuiPopover-paper': {
        width: '20px !important',
      },
      '& .MuiSelect-select': {
        fontSize: '14px',
      },
      '& .MuiSelect-select:focus': {
        backgroundColor: innerSelectedColor || 'white',
      },
      '& .MuiRadio-root': {
        display: 'none',
      },
    };
  },
);

export const SelectMenuItemContainer = styled(MenuItem)(() => {
  return {
    paddingBottom: 0,
    paddingTop: 0,
    '&.Mui-selected': {
      backgroundColor: 'white !important',
    },
    '&#header': {
      width: '95%',
      marginLeft: 9,
      marginTop: 10,
      marginBottom: 10,
    },
    '&#header:hover': {
      backgroundColor: 'white !important',
    },
    '&#header .MuiTypography-displayBlock': {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '14px',
    },
    '&:not(#header) .MuiTypography-displayBlock': {
      height: '9px',
      fontSize: '14px',
      marginTop: '-10px',
    },
  };
});
