import { MenuProps } from '@material-ui/core';
import { sortBy } from 'lodash';
import { useServiceCreationController } from 'pages/Admin/Marketplace/Services/ServiceCreation/controller';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import ServicesService from 'services/servicesService';

import { ISelectItem } from 'components/Forms/SelectInput/types';
import { IServiceResponse } from 'pages/Admin/Marketplace/Services/components/ServiceForm/types';
import PedagogicSchoolYearService from 'services/pedagogicSchoolYears';
import PedagogicUserService from 'services/pedagogicUser';
import { MenuProps as MenuPropsStructure } from '../../styles';
import {
  IGeneraFormProps,
  SelectState,
  ServiceClassRouteParams,
} from '../../types';

export const useGeneralDataFormController = ({
  setValue,
  register,
}: Pick<IGeneraFormProps, 'setValue' | 'register'>) => {
  // General Hooks
  const {
    params: { serviceId },
  } = useRouteMatch<ServiceClassRouteParams>();
  const { renderValue } = useServiceCreationController();

  // Refs
  const inputRefMenuDimensions = useRef<HTMLInputElement>(null);

  // States
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [menuProps, setMenuProps] = useState<Partial<MenuProps>>();
  const [pedagogicSchoolYears, setPedagogicSchoolYears] = useState<
    Array<ISelectItem>
  >([]);
  const [pedagogicProfessors, setPedagogicProfessors] = useState<
    Array<ISelectItem>
  >([]);
  const [serviceDetail, setServiceDetail] =
    useState<Pick<IServiceResponse, 'id' | 'pedagogicSubjectId'>>();
  const [serviceCategories, setServiceCategories] = useState<
    Array<SelectState>
  >([]);
  const [servicePublicationStatus, setServicePublicationStatus] = useState<
    Array<SelectState>
  >([]);
  const [servicesTypes, setServicesTypes] = useState<Array<SelectState>>([]);
  const [schoolClasses, setSchoolClasses] = useState<Array<SelectState>>([]);
  const [currencyRecurrence, setCurrencyRecurrence] = useState<
    Array<SelectState>
  >([]);

  // Callbacks
  const getResources = useCallback(async () => {
    const statusPromise = ServicesService.getStatus();
    const categoriesPromise = ServicesService.getCategories();
    const typesPromise = ServicesService.getTypes();
    // Return all classes or return only the classes of the selected service
    const schoolClassesPromise = ServicesService.getService(serviceId, {
      join: ['schoolClasses'],
      fields: ['id', 'pedagogicSubjectId'],
    });
    const pedagogicSchoolYearsPromise = PedagogicSchoolYearService.getOptions();
    const currencyRecurrencePromise = ServicesService.getCurrencyRecurrence();

    const [
      categories,
      types,
      currencyRecurrence,
      publicationStatus,
      classes,
      pedagogicSchoolYearsResults,
    ] = await Promise.all([
      categoriesPromise,
      typesPromise,
      currencyRecurrencePromise,
      statusPromise,
      schoolClassesPromise,
      pedagogicSchoolYearsPromise,
    ]);

    setServiceDetail({
      id: classes?.data?.id,
      pedagogicSubjectId: classes?.data?.pedagogicSubjectId,
    });
    register('pedagogicSubjectId');
    setValue?.('pedagogicSubjectId', classes?.data?.pedagogicSubjectId);

    const schoolClassesData = sortBy(classes?.data?.schoolClasses || [], [
      'id',
    ]);

    setSchoolClasses(schoolClassesData);
    setServiceCategories(categories.data.data || []);
    setServicesTypes(types.data.data || []);
    setCurrencyRecurrence(currencyRecurrence.data.data || []);
    setServicePublicationStatus(publicationStatus.data.data || []);
    setPedagogicSchoolYears(
      (pedagogicSchoolYearsResults.data || []).map(item => ({
        id: item.id,
        label: String(item.year),
      })),
    );

    if (classes?.data?.pedagogicSubjectId) {
      const professorsPromise = await PedagogicUserService.getProfessors([
        classes.data.pedagogicSubjectId,
      ]);

      setPedagogicProfessors(
        (professorsPromise.data || []).map(item => ({
          id: item.id,
          label: item.user.name,
        })),
      );
    }
  }, [serviceId, setValue]);

  useEffect(() => {
    if (inputRefMenuDimensions?.current) {
      setMenuProps(
        MenuPropsStructure(inputRefMenuDimensions.current.clientWidth + 1),
      );
    }
  }, [inputRefMenuDimensions]);

  useEffect(() => {
    getResources().finally(() => {
      setIsLoadingPage(false);
    });
  }, [getResources]);

  return {
    inputRefMenuDimensions,
    MenuProps: menuProps,
    servicesTypes,
    serviceCategories,
    pedagogicSchoolYears,
    pedagogicProfessors,
    serviceDetail,
    schoolClasses,
    renderValue,
    currencyRecurrence,
    servicePublicationStatus,
    isLoadingPage,
  };
};
