import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ServiceClassesService from 'services/serviceClassesService';
import { verifyValidHourInterval } from 'utils';
import { parseBrDateToISO } from 'utils/dateUtils';
import { ScheduleTypeLabel } from '../../Services/components/ServiceForm/components/SchedulerForm/types';
import {
  ERROR_ON_SUBMIT_CREATION,
  SCHEDULE_DAYS_ERROR_MESSAGE,
  SCHEDULE_INTERVAL_ERROR_MESSAGE,
  schema,
} from '../components/ServiceClassForm/schema';
import {
  ICalendarAssociatedFields,
  IServiceClassCreateRequest,
  IServiceFieldValues,
  ScheduleType,
  ServiceClassRouteParams,
} from '../components/ServiceClassForm/types';

export const useServiceClassCreationController = () => {
  // Navigation
  const history = useHistory();
  const {
    params: { serviceId },
  } = useRouteMatch<ServiceClassRouteParams>();

  // Form
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    setValue,
    getValues,
  } = useForm<IServiceFieldValues>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  // States
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [generalErrorMessageAlert, setGeneralErrorMessageAlert] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [loading, setLoading] = useState(false);

  // Callbacks
  const renderValue = useCallback((values: unknown) => {
    if ((values as Array<{ name: string }>)[0]?.name) {
      return (values as Array<{ name: string }>)
        .map(value => value.name)
        .join(', ');
    }
    return (values as Array<string>).join(', ');
  }, []);

  const submit = useCallback(
    async (values: IServiceFieldValues) => {
      setLoading(true);

      const parsedValues = { ...values };
      // const haveAmount = !!values.amount && values.amount !== 'R$ 0';
      // const [serviceTypeId, serviceTypeName] = values.serviceType.split(':');

      let generalErrorMessageAlert = '';
      let intervalIsValid = true;

      // generalErrorMessageAlert = validateGeneralErrorsOnSubmit(values);

      if (generalErrorMessageAlert) {
        setGeneralErrorMessageAlert(generalErrorMessageAlert);
      }

      if (parsedValues.scheduleCanVaryToEveryDay) {
        parsedValues.schedule = parsedValues.days.map((day, index) => {
          const dynamicKey = `day-schedule${index === 0 ? '' : `-${index}`}`;

          if (!values[dynamicKey as keyof ICalendarAssociatedFields]) {
            generalErrorMessageAlert = SCHEDULE_DAYS_ERROR_MESSAGE;
            setGeneralErrorMessageAlert(generalErrorMessageAlert);
          } else {
            intervalIsValid = verifyValidHourInterval(
              values[dynamicKey as keyof ICalendarAssociatedFields],
            );

            if (!intervalIsValid) {
              generalErrorMessageAlert = SCHEDULE_INTERVAL_ERROR_MESSAGE;
              setGeneralErrorMessageAlert(generalErrorMessageAlert);
            }

            return {
              day,
              schedule: values[dynamicKey as keyof ICalendarAssociatedFields],
            };
          }
        }) as ScheduleType[];
      } else {
        parsedValues.schedule = parsedValues.days.map(day => {
          if (!values[`day-schedule` as keyof ICalendarAssociatedFields]) {
            generalErrorMessageAlert = SCHEDULE_DAYS_ERROR_MESSAGE;
            setGeneralErrorMessageAlert(generalErrorMessageAlert);
          } else {
            intervalIsValid = verifyValidHourInterval(
              values[`day-schedule` as keyof ICalendarAssociatedFields],
            );

            if (!intervalIsValid) {
              generalErrorMessageAlert = SCHEDULE_INTERVAL_ERROR_MESSAGE;
              setGeneralErrorMessageAlert(generalErrorMessageAlert);
            }

            return {
              day,
              schedule:
                values[`day-schedule` as keyof ICalendarAssociatedFields],
            };
          }
        }) as ScheduleType[];
      }

      if (!generalErrorMessageAlert) {
        const data: IServiceClassCreateRequest = {
          name: values.name,
          schoolClasses: values.schoolClasses.map(id => Number(id)),
          // recurrenceId: Number(values.recurrence),
          spotsNumber: values.spotsNumber,
          yearlyTotalHours: values.yearlyTotalHours,
          startDate: parseBrDateToISO(values.startDate),
          endDate: parseBrDateToISO(values.endDate),
          calendars: parsedValues.schedule,
          responsibleCoordinator: values.responsibleCoordinator,
          pedagogicSchoolYearId: values.pedagogicSchoolYearId,
          pedagogicUserId: values.pedagogicUserId,
          timeType: values.scheduleCanVaryToEveryDay
            ? ScheduleTypeLabel.EachDayHaveDifferentSchedule
            : ScheduleTypeLabel.EachDayHaveEqualSchedule,
          // amount: formatCurrencyValueToNumber(values.amount),
          // maxInstallmentsNumber: values.maxInstallmentsNumber,
          // registrationTaxValue: formatCurrencyValueToNumber(
          //   values.registrationTaxValue,
          // ),
          serviceId: Number(serviceId),
        };
        try {
          const { status } = await ServiceClassesService.createServiceClass(
            data,
          );
          if (status === 201) {
            setSuccessModalOpen(true);
          } else {
            setSubmitError(ERROR_ON_SUBMIT_CREATION);
          }
        } catch (error) {
          setSubmitError(ERROR_ON_SUBMIT_CREATION);
        }
      }
      setLoading(false);
    },
    [serviceId],
  );

  const handleCloseScheduleError = () => {
    setGeneralErrorMessageAlert('');
  };

  const handleCloseSubmitError = () => {
    setSubmitError('');
  };

  const handleCancel = () => {
    setCancelModalOpen(true);
  };

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
    history.goBack();
  };

  const handleCloseCancelModal = () => {
    setCancelModalOpen(false);
  };

  const handleCloseAndNotGoBack = () => {
    setSuccessModalOpen(false);
    setCancelModalOpen(false);
  };
  const handleCloseModalAndExit = () => {
    setCancelModalOpen(false);
    history.goBack();
  };

  return {
    submit,
    handleSubmit,
    errors,
    register,
    control,
    setValue,
    getValues,
    renderValue,
    handleCancel,
    successModalOpen,
    cancelModalOpen,
    handleCloseSuccessModal,
    handleCloseCancelModal,
    handleCloseAndNotGoBack,
    generalErrorMessageAlert,
    handleCloseScheduleError,
    submitError,
    handleCloseSubmitError,
    loading,
    handleCloseModalAndExit,
  };
};
