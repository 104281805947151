export const weekDayNames:
  | []
  | [string, string, string, string, string, string, string]
  | undefined = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado',
];

export const timeZoneName = 'America/Sao_Paulo';
