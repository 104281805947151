import {
  Button,
  ButtonBase,
  Divider,
  Grid,
  styled,
  TableBody,
  TableCell,
  TextField,
  Theme,
} from '@material-ui/core';
import { infoMain, primaryDark, primaryMedium } from 'styles/theme';
import {
  LabelStyledProps,
  VacanciesCellStylesProps,
} from './components/ServicesTable/types';

export const ServiceHomeGridContainer = styled(Grid)(() => {
  return {
    marginBottom: '50px',
  };
});

export const ServiceHomeTextField = styled(TextField)(() => {
  return {
    width: '100%',
    background: 'white',
    borderRadius: '8px',
    height: '55px',
    marginBottom: '15px',
  };
});

export const ServicesScheduleButton = styled(Button)(() => {
  return {
    borderRadius: '8px',
    fontWeight: 700,
    fontSie: '18px',
    textTransform: 'none',
  };
});

export const CustomDivider = styled(Divider)(() => {
  return {
    height: '0.5px',
    backgroundColor: primaryMedium,
    marginTop: '60px',
    width: 'calc(100% + 50px)',
    position: 'relative',
    left: '-25px',
  };
});

export const DetailsButton = styled(ButtonBase)<Theme>(() => ({
  color: primaryDark,
  fontSize: '14px',
}));

export const Label = styled('span')<Theme, LabelStyledProps>(
  ({ backgroundColor, color }) => ({
    backgroundColor,
    padding: '4.5px 9px',
    borderRadius: '100px',
    fontSize: '10px',
    fontWeight: 700,
    color,
    textTransform: 'uppercase',
    letterSpacing: '0.13rem',
  }),
);

export const VacanciesCell = styled(TableCell)<Theme, VacanciesCellStylesProps>(
  ({ isOut }) => ({
    color: isOut ? '#FF0000' : 'inherit',
  }),
);

export const EditButton = styled(ButtonBase)(() => ({
  color: primaryDark,
  marginRight: '20px',
}));

export const TableBodyContainer = styled(TableBody)(() => ({
  borderTop: `1px solid ${infoMain}`,
}));
