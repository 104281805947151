import { CreateQueryParams } from '@nestjsx/crud-request';
import { SchoolClassDetails } from 'types/schoolClassDetails';
import {
  api,
  getAxiosResponseMessageErrorOrDefault,
  getWithFilter,
} from './apiService';
import { GetAvailableYearsResponse } from './types/schoolClassDetailsService.type';

export class SchoolClassDetailsService {
  static BASE_PATH = 'schoolClassDetails';

  static async getAvailableYears(fromYear: number) {
    try {
      return await api.get<GetAvailableYearsResponse[]>(
        `${this.BASE_PATH}/available-years`,
        {
          params: {
            fromYear,
          },
        },
      );
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }

  static async getMany(query: CreateQueryParams) {
    try {
      return await getWithFilter<SchoolClassDetails>(
        `${this.BASE_PATH}`,
        query,
      );
    } catch (error) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }

  static async getOneById(id: number) {
    try {
      return await api.get<SchoolClassDetails>(`${this.BASE_PATH}/${id}`);
    } catch (error) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }
}
