import { Grid } from '@material-ui/core';
import { SelectInput } from 'components/Forms/SelectInput/SelectInput';
import SearchAutocomplete from 'components/SearchAutocomplete';
import { MarketplaceFilteredSearchProps } from './types';
import { useMarketplaceFilteredSearchController } from './useMarketplaceFilteredSearchController';

export const MarketplaceFilteredSearch = ({
  selectedRegistrationId,
  ...otherProps
}: MarketplaceFilteredSearchProps) => {
  const {
    // Search (services)
    handleOnSelectService,
    handleOnSearchService,
    handleOnCreateQueryParamsCallback,
    handleGetOptionLabel,

    // Select input (registrations/dependents)
    registrationOptions,
    handleSelectRegistration,
  } = useMarketplaceFilteredSearchController({
    selectedRegistrationId,
    ...otherProps,
  });

  return (
    <Grid container spacing={2}>
      {selectedRegistrationId ? (
        <Grid item xs={12} md={6} lg={4}>
          <SearchAutocomplete
            createQueryParamsCallback={handleOnCreateQueryParamsCallback}
            filterEndpointPath="services"
            getAutocompleteOptionLabel={handleGetOptionLabel}
            searchLabel="Buscar por nome de eventos, passeios..."
            onSearchText={handleOnSearchService}
            onSelect={handleOnSelectService}
          />
        </Grid>
      ) : null}

      <Grid item xs={12} md={4} lg={3}>
        <SelectInput
          name="select-dependent"
          label="Nome do dependente"
          defaultValue={selectedRegistrationId}
          value={selectedRegistrationId}
          onChange={handleSelectRegistration}
          options={registrationOptions}
        />
      </Grid>
    </Grid>
  );
};
