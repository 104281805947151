import { Button, Grid, Typography } from '@material-ui/core';
import AlertCard from 'components/AlertCard';
import IncompleteProfileModal from 'components/IncompleteProfileModal';
import { RegistrationListItem } from 'components/RegistrationListItem';
import { useAuth } from 'contexts/auth';
import { useConfigurations } from 'contexts/configurations';
import { getYear } from 'date-fns';
import { StatusCodes } from 'http-status-codes';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import RegistrationService from 'services/registrationService';
import useGlobalStyles from 'styles';
import { REGISTRATION_STATUS } from 'types/enums';
import { Registration } from 'types/registration';
import { useStyles } from './styles';
import { OUT_OF_PERIOD } from './utils';

const Registrations: React.FC = () => {
  const classes = useGlobalStyles();
  const styles = useStyles();
  const { user } = useAuth();
  const { configurations } = useConfigurations();
  const history = useHistory();
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [modal, setModal] = useState(false);
  const [registrations, setRegistrations] = useState<any[]>([]);

  const canRegister = useMemo(() => {
    return !!configurations?.isRegistrationOpen;
  }, [configurations?.isRegistrationOpen]);

  const loadRegistrations = useCallback(async () => {
    if (user) {
      const { data: response, status } =
        await RegistrationService.filterRegistrations({
          join: [
            ['dependent'],
            ['financialGuardian'],
            // Required to check re registration flow
            { field: 'nextRegistration', select: ['id'] },
          ],
          sort: [
            {
              field: 'dependentId',
              order: 'DESC',
            },
            {
              field: 'schoolYear',
              order: 'DESC',
            },
          ],
        });
      if (status === StatusCodes.OK) {
        setRegistrations(response.data);
      }
    }
  }, [user]);

  useEffect(() => {
    loadRegistrations();
  }, [loadRegistrations]);

  function goToNewRegistration() {
    if (user && user.financialGuardian.isCompleted) {
      const { cpf, rg, address } = user.financialGuardian;
      history.push({
        pathname: '/registrations/new-registration',
        state: {
          userId: user?.id,
          guardianId: user?.financialGuardian.id,
          isAdmin: false,
          userDocuments: {
            cpf,
            rg,
          },
          userAddress: address,
        },
      });
    } else {
      setModal(true);
    }
  }

  const renderRegistrations = (
    registrations: Array<Registration>,
    isActive: Boolean,
  ) => {
    return registrations.map(registration => {
      const condition = isActive
        ? registration.status === REGISTRATION_STATUS.ACTIVE
        : registration.status !== REGISTRATION_STATUS.ACTIVE;
      const { dependent } = registration;
      return condition ? (
        <Link key={registration.id} to={`/registrations/${registration.id}`}>
          <RegistrationListItem
            dependentName={dependent.name}
            registration={registration}
          />
        </Link>
      ) : null;
    });
  };

  const handleClickNewRegistration = () => {
    if (canRegister) {
      goToNewRegistration();
    } else {
      const year = configurations?.enrollmentYear || getYear(new Date());
      setAlert(true);
      setAlertMessage(OUT_OF_PERIOD(year));
    }
  };

  return (
    <Grid
      className={classes.listScreenGrid}
      container
      direction="column"
      alignItems="flex-start"
    >
      <AlertCard
        message={alertMessage}
        open={alert}
        close={() => setAlert(false)}
        severity="error"
      />
      <IncompleteProfileModal open={modal} />
      <Grid container item direction="column">
        <Typography className={classes.screenHeaderText}>
          <b>Matrículas</b>
        </Typography>
        <Typography className={classes.screenSubHeaderText}>
          Selecione ou faça o cadastro de <b>um(a) aluno(a)</b> JPSul para
          iniciar a matrícula
        </Typography>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className={styles.margin}
        >
          <Grid item>
            <Typography className={styles.processing}>Em andamento</Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={handleClickNewRegistration}
              variant="contained"
              color="primary"
              className={classes.listScreenButton}
            >
              Novo aluno
            </Button>
          </Grid>
        </Grid>

        {renderRegistrations(registrations, false)}

        <Grid item className={styles.margin}>
          <Typography className={classes.screenHeaderText}>Ativas</Typography>
        </Grid>

        {renderRegistrations(registrations, true)}
      </Grid>
    </Grid>
  );
};

export default Registrations;
