import { PAYMENT_METHODS } from 'types/enums';
import { formatDatePtBr } from 'utils/formatDatePtBr';

export const BILLING_DAY = 25;

// Esse método é estritamente usado para a data da primeira parcela do cartão
// para matriculas que vao acontecer em anos futuros
export const getDecemberBillingDate = (registrationReferenceYear: number) => {
  const date = new Date(registrationReferenceYear, 11, BILLING_DAY);

  return formatDatePtBr(date);
};

export const translatePaymentMethod = (method: string) => {
  const codes: Record<string, string> = {
    [PAYMENT_METHODS.CARD]: 'Cartão de crédito',
    [PAYMENT_METHODS.BANK_SLIP]: 'Boleto bancário',
    [PAYMENT_METHODS.PIX]: 'Pix',
  };

  return method in codes ? codes?.[method] : 'Outro';
};
