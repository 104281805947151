import { TableRow, Theme, styled } from '@material-ui/core';
import { primaryMain, white } from 'styles/theme';
import { StyledTableRowProps } from './types';

export const StyledTableRow = styled(TableRow)<Theme, StyledTableRowProps>(
  ({ isExpanded }) => ({
    borderLeft: isExpanded ? `1px solid ${primaryMain}` : `1px solid ${white}`,
    cursor: 'pointer',
    padding: 0,
  }),
);
