import { Box, Divider, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import { RegCountProperties } from './types';

export const DashboardRegistrationCountCards = ({
  header,
  iconInterviewRequest,
  iconActiveEnrollment,
  titleInterviewRequest,
  countInterviewRequest,
  titleActiveEnrollment,
  countActiveEnrollment,
}: RegCountProperties) => {
  const classes = useStyles();
  return (
    <Grid item className={classes.container}>
      <Paper className={classes.paper}>
        <Box className={classes.contentContainer}>
          <Grid item container className={classes.header}>
            <Typography className={classes.headerText}>{header}</Typography>
          </Grid>
          <Grid item container>
            <Grid
              item
              container
              direction="column"
              justifyContent="space-between"
              className={classes.countContainer}
              md={6}
            >
              <Grid item className={classes.iconContainer}>
                {iconInterviewRequest}
              </Grid>
              <Typography className={classes.title}>
                {titleInterviewRequest}
              </Typography>
              <Divider className={classes.divider} />
              <Grid item>
                <Typography className={classes.count}>
                  {countInterviewRequest}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              container
              direction="column"
              justifyContent="space-between"
              className={classes.countContainer}
              md={6}
            >
              <Grid item className={classes.iconContainer}>
                {iconActiveEnrollment}
              </Grid>
              <Typography className={classes.title}>
                {titleActiveEnrollment}
              </Typography>
              <Divider className={classes.divider} />
              <Grid item>
                <Typography className={classes.count}>
                  {countActiveEnrollment}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};
