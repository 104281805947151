import { MyTable } from 'components/Table';
import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
} from 'react';
import { ROWS_PER_PAGE } from 'utils';
import {
  INestCrudTableProps,
  NestCrudTableRef,
  NestCrudTableWithForwardRefType,
} from './types';

/**
 * See example in src/pages/Admin/PaymentDetails/components/InvoicesTable/index.tsx
 */
const NestCrudTableComponent = forwardRef(
  <T extends { id: number | string }>(
    { onFetchData, ...myTableProps }: INestCrudTableProps<T>,
    ref: ForwardedRef<NestCrudTableRef>,
  ) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [data, setData] = React.useState<T[]>([]);
    const [tableDef, setTableDef] = React.useState({
      page: 0,
      perPage: ROWS_PER_PAGE[0],
    });
    const [total, setTotal] = React.useState(0);

    /**
     * FIXME: https://github.com/fleye-me/jps_frontend/pull/667#discussion_r1157134959
     * Apply debounce here: https://www.npmjs.com/package/use-debounce
     */
    const fetchData = useCallback(async () => {
      try {
        const response = await onFetchData({
          perPage: tableDef.perPage,
          page: tableDef.page + 1,
        });

        setData(response.data);
        setTotal(response.total);
      } catch (error) {
        // FIXME: remove console
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }, [onFetchData, tableDef.page, tableDef.perPage]);

    useEffect(() => {
      fetchData();
    }, [fetchData]);

    const handlePageChange = (newPage: number) => {
      setTableDef(prevState => ({
        ...prevState,
        page: newPage,
      }));
    };

    const handleRowsPerPageChange = (rowPerPage: number) => {
      setTableDef({
        page: 0,
        perPage: rowPerPage,
      });
    };

    useImperativeHandle(
      ref,
      () => ({
        fetchData,
      }),
      [fetchData],
    );

    return (
      <MyTable
        {...myTableProps}
        data={data}
        isLoading={isLoading}
        tablePaginationConfig={{
          page: tableDef.page,
          perPage: tableDef.perPage,
          total,
          onChangePage: handlePageChange,
          onChangeRowsPerPage: handleRowsPerPageChange,
        }}
      />
    );
  },
);

export const NestCrudTable =
  NestCrudTableComponent as NestCrudTableWithForwardRefType;
