import { AlertSliceCreator } from './types';

export const createAlertSlice: AlertSliceCreator = set => ({
  showAlert: config =>
    set(state => {
      state.alert.config = config;
      return state;
    }),
  closeAlert: () =>
    set(state => {
      state.alert.config = undefined;
      return state;
    }),
});
