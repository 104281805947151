import { ConfigurationsProvider } from 'contexts/configurations';
import React from 'react';
import { useAuth } from '../contexts/auth';
import AdminRoutes from './adminRoutes';
import ClientRoutes from './clientRoutes';
import NonAuthRoutes from './nonAuthRoutes';

const Routes: React.FC = () => {
  const { signed, user, loadingUser } = useAuth();

  if (!loadingUser) {
    if (signed && user) {
      return (
        <ConfigurationsProvider>
          {user.isAdmin ? <AdminRoutes /> : <ClientRoutes />}
        </ConfigurationsProvider>
      );
    }
    return <NonAuthRoutes />;
  }
  return null;
};

export default Routes;
