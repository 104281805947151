import AlertCard from 'components/AlertCard';
import { CustomButton } from 'components/CustomButton';
import { FC } from 'react';
import { useButtonDowloandIncomeTaxReturnController } from './controller';
import { ButtonProps } from './types';

export const ButtonDownloadIncomeTaxReturn: FC<ButtonProps> = props => {
  const {
    exportIncomeTaxReturn,
    isLoading,
    closeAlert,
    alertMessage,
    alertType,
    isShowAlert,
  } = useButtonDowloandIncomeTaxReturnController(props);

  return (
    <>
      <AlertCard
        message={alertMessage}
        open={isShowAlert}
        close={closeAlert}
        severity={alertType}
      />
      <CustomButton
        onClick={exportIncomeTaxReturn}
        loading={isLoading}
        variant="secondary"
        textVariant="bold"
      >
        Baixar declaração
      </CustomButton>
    </>
  );
};
