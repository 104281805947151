import Container from '@material-ui/core/Box';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import Typography from '@material-ui/core/Typography';
import { formatBRL } from 'utils';
import { CustomButton } from 'components/CustomButton';
import { useItemCartController } from './useItemCartController';
import { IItemCartProps } from './types';

const ItemCart = ({ item, disableRemove }: IItemCartProps) => {
  const { styles, handleRemoveServiceFromCart } = useItemCartController();
  // const { firstDayOfTheWeek, formattedHours } = useActivitiesSectionController({
  //   service: item.service,
  //   selectedRegistration: item.registration,
  // });

  return (
    <Container className={styles.container}>
      <Container className={styles.header}>
        <Container className={styles.infoContainer}>
          <Typography className={styles.title}>{item.service.name}</Typography>
          <Typography className={styles.infoText}>
            {item.selectedServiceClass.name} <br />
            {item.selectedServiceClass.calendars.map(calendar => (
              <>
                {/* FIXME: Temporario, validar design */}
                {calendar.day} {calendar.startTime} - {calendar.endTime}
                <br />
              </>
            ))}
            {item.dependent.name} - {item.registration.schoolClass.name} <br />
          </Typography>
        </Container>

        <Container className={styles.priceContainer}>
          <Typography className={styles.price}>
            {item?.service.amount
              ? `${formatBRL(item.service.amount)} /${
                  item.service.serviceRecurrence.name
                }`
              : 'Sem anuidade'}
          </Typography>

          <Typography>
            Taxa de matrícula: {formatBRL(item.service.registrationTaxValue)}
          </Typography>

          {!disableRemove ? (
            <CustomButton
              variant="secondary"
              className={styles.removeButton}
              onClick={() =>
                handleRemoveServiceFromCart(
                  item.service.id,
                  item.registrationId,
                )
              }
            >
              Remover <CloseOutlined fontSize="small" />
            </CustomButton>
          ) : null}
        </Container>
      </Container>
    </Container>
  );
};

export { ItemCart };
