import { DEFAULT_LOADING_MESSAGE } from './consts';
import { SnackbarLoadingSliceCreator } from './types';

export const createSnackbarLoadingSlice: SnackbarLoadingSliceCreator = set => ({
  isLoading: false,
  config: undefined,
  showLoading: config =>
    set(state => {
      state.snackbarLoading.config = {
        message: config?.message || DEFAULT_LOADING_MESSAGE,
      };
      state.snackbarLoading.isLoading = true;
      return state;
    }),
  closeLoading: () =>
    set(state => {
      state.snackbarLoading.isLoading = false;
      state.snackbarLoading.config = undefined;
      return state;
    }),
});
