import { CircularProgress, styled, Theme } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { borderGray } from 'styles/theme';
import { getDimensionBasedOnIcon } from './utils';

export const StyledAutocomplete = styled(Autocomplete)<
  Theme,
  { hasEndIcon?: boolean }
>(({ theme, hasEndIcon }) => ({
  '& .MuiFormLabel-root': {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: `calc(100% - ${getDimensionBasedOnIcon(hasEndIcon)})`,
    left: '5px',
  },
  '& .MuiFormLabel-root.MuiInputLabel-shrink': {
    maxWidth: '100%',
    transition: 'all .2s ease-in-out',
    marginRight: 0,
    padding: 0,
  },
  '& .MuiAutocomplete-inputRoot': {
    backgroundColor: 'white',
    fontWeight: 400,
    padding: '5px',
    fontSize: '14px',
    minHeight: '56px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: `1.45px solid ${borderGray}`,
    fontSize: 16,
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  '&	.MuiAutocomplete-input': {
    marginLeft: '8px',
  },
}));

export const StyledActivityIndicator = styled(CircularProgress)(() => ({
  marginRight: '25px',
}));
