import React from 'react';
import {
  createMuiTheme,
  ListItem,
  ListItemIcon,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { mediumGray, primaryDark } from 'styles/theme';
import { useStyles } from './styles';
import { RouteProperties } from './types';

const MenuItem: React.FC<RouteProperties> = ({
  active,
  title,
  icon,
  isAdmin,
}) => {
  const classes = useStyles({ isAdmin, active })();

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: isAdmin ? 'dark' : 'light',
          primary: {
            main: primaryDark,
          },
          secondary: {
            main: mediumGray,
          },
        },
      }),
    [isAdmin],
  );

  return (
    <ThemeProvider theme={theme}>
      <ListItem
        className={active ? classes.activeRoute : classes.route}
        button
        key={title}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <Typography className={classes.menuItemTitle}>{title}</Typography>
      </ListItem>
    </ThemeProvider>
  );
};

export default MenuItem;
