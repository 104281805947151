import React from 'react';
import { Box } from '@material-ui/core';
import { TabPanelProperties } from './types';

const TabPanel: React.FC<TabPanelProperties> = ({ children, value, index }) => {
  return (
    <Box
      flexGrow={1}
      key={index}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ padding: 25 }}
    >
      {value === index && children}
    </Box>
  );
};

export default TabPanel;
