import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import ConfigurationsService from 'services/configurationsService';
import { DetailedConfigurations } from 'types/configurations';
import { ConfigurationsContextProps } from './types';

const ConfigurationsContext = createContext<ConfigurationsContextProps>(
  {} as ConfigurationsContextProps,
);

export const ConfigurationsProvider: React.FC = ({ children }) => {
  const [configurations, setConfigurations] =
    useState<DetailedConfigurations | null>(null);

  const getConfigurations = useCallback(async () => {
    const { data: response } =
      await ConfigurationsService.getReregistrationInfo();
    setConfigurations(response);
  }, []);

  useEffect(() => {
    getConfigurations();
  }, [getConfigurations]);

  return (
    <ConfigurationsContext.Provider
      // FIXME: remove context
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        configurations,
        setConfigurations,
      }}
    >
      {children}
    </ConfigurationsContext.Provider>
  );
};

export function useConfigurations(): ConfigurationsContextProps {
  const context = useContext(ConfigurationsContext);
  return context;
}
