import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import EmptyListPlaceholder from 'components/EmptyListPlaceholder';
import LoadingTable from 'components/Table/loadingTable';
import useGlobalStyles from 'styles';
import { FinancialReportTableEntry } from '../FinancialReportTableEntry';
import { DEFAULT_EMPTY_FINANCIAL_TABLE_MESSAGE } from './consts';
import { StyledDiv, StyledPaper, StyledTable, StyledTableBody } from './styles';
import { FinancialReportTableProps } from './types';
import { useFinancialReportTableController } from './useFinancialReportTableController';

export const FinancialReportTable = ({
  payments,
  isLoading,
}: FinancialReportTableProps) => {
  const classes = useGlobalStyles();
  const { tableHeaders } = useFinancialReportTableController();

  if (isLoading) {
    return (
      <StyledDiv>
        <LoadingTable />
      </StyledDiv>
    );
  }

  if (!payments.length) {
    return (
      <StyledDiv>
        <EmptyListPlaceholder message={DEFAULT_EMPTY_FINANCIAL_TABLE_MESSAGE} />
      </StyledDiv>
    );
  }

  return (
    <StyledPaper elevation={0}>
      <StyledTable>
        <TableHead>
          <TableRow>
            {tableHeaders.map(tableHeader => (
              <TableCell key={tableHeader}>
                <Typography className={classes.listScreenHeaderText1}>
                  {tableHeader}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <StyledTableBody>
          <Box height={20} />
          {payments.map(payment => (
            <FinancialReportTableEntry key={payment.id} payment={payment} />
          ))}
        </StyledTableBody>
      </StyledTable>
    </StyledPaper>
  );
};
