import { Typography } from '@material-ui/core';
import TabPanel from 'components/TabPanel';
import React from 'react';
import useGlobalStyles from 'styles';
import { CLAUSE_LIST } from 'texts';
import { TabProperties } from './types';

const TabPage: React.FC<TabProperties> = ({ value, index }) => {
  const classes = useGlobalStyles();

  return (
    <TabPanel value={value} index={index}>
      <Typography className={classes.mainScreenHeader}>
        <b>Termos</b> de serviço
      </Typography>
      {CLAUSE_LIST[value].map((item, index) => (
        <Typography key={index} className={classes.contractScreenSmallText}>
          {item}
        </Typography>
      ))}
    </TabPanel>
  );
};

export default TabPage;
