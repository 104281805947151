import Blocked from 'assets/blocked.png';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { INCOMPLETE_PROFILE_SUBTITLE, INCOMPLETE_PROFILE_TITLE } from 'texts';
import Modal from './Modal';

const IncompleteProfileModal = ({ open }: { open: boolean }) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const close = () => {
    history.push('/home/profile');
    setIsOpen(false);
  };

  return (
    <Modal
      icon={<img src={Blocked} alt="perfil-incompleto" />}
      open={isOpen}
      text={INCOMPLETE_PROFILE_TITLE}
      subtext={INCOMPLETE_PROFILE_SUBTITLE}
      btnText="Completar cadastro"
      btnAction={close}
    />
  );
};

export default IncompleteProfileModal;
