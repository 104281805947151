import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { black, white, yellow } from 'styles/theme';

import { validationSchema } from './utils';

export const useServiceCodeModalController = () => {
  const { handleSubmit, errors, register, control } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onBlur',
  });

  return {
    handleSubmit,
    errors,
    register,
    control,
  };
};
