import { INVALID_FIELD, INVALID_INTERVAL, REQUIRED_FIELD } from 'texts';
import { isDateIntervalValid, isStringDateValid } from 'utils';
import * as Yup from 'yup';

export const registrationPeriodValidationSchema = Yup.object({
  periodStart: Yup.string()
    .required(REQUIRED_FIELD)
    .test(
      'is date valid',
      INVALID_FIELD,
      value => isStringDateValid(value).isDateValid,
    ),
  periodEnd: Yup.string()
    .required(REQUIRED_FIELD)
    .test(
      'is date valid',
      INVALID_FIELD,
      value => isStringDateValid(value).isDateValid,
    )
    .test('is interval valid', INVALID_INTERVAL, (value, context) =>
      isDateIntervalValid(context.parent.periodStart, value),
    ),
  enrollmentYear: Yup.string()
    .required(REQUIRED_FIELD)
    .transform(value => (value || '').trim()),
});
