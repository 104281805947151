import {
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Drafts, Mail, NotificationsOutlined } from '@material-ui/icons';
import { StatusCodes } from 'http-status-codes';
import { useHistory } from 'react-router-dom';
import React, { MouseEvent, useEffect, useState } from 'react';
import { useAuth } from 'contexts/auth';
import AlertCard from 'components/AlertCard';
import NotificationService from 'services/notificationService';
import useGlobalStyles from 'styles';
import { GENERAL_ERROR } from 'texts';
import { formatShortDate, paperStyle } from './utils';
import { useStyles } from './styles';
import { NotificationsProperties } from './types';

const NotificationButton: React.FC<NotificationsProperties> = ({
  notifications,
  isAdmin,
}) => {
  const classes = useGlobalStyles();
  const styles = useStyles({ isAdmin })();
  const history = useHistory();
  const { user, getUser } = useAuth();
  const [hasUnread, setHasUnread] = useState(false);
  const [alert, setAlert] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRedirect = (redirect?: string) => () => {
    if (redirect) {
      history.push(redirect);
    }
  };

  const handleMarkAllAsRead = async (event: MouseEvent<HTMLElement>) => {
    if (user) {
      const response = await NotificationService.markAllAsRead(user.id);
      if (response.status === StatusCodes.OK) {
        getUser();
      } else {
        setAlert(true);
      }
    }
  };

  useEffect(() => {
    if (notifications.length) {
      const allRead = notifications.every(item => item.isRead === true);
      setHasUnread(!allRead);
    }
  }, [notifications]);

  return (
    <>
      <AlertCard
        message={GENERAL_ERROR}
        open={alert}
        close={() => setAlert(false)}
        severity="error"
      />
      <Button className={styles.button} onClick={handleOpen}>
        <Badge color="secondary" variant="dot" invisible={!hasUnread}>
          <NotificationsOutlined color="inherit" className={styles.icon} />
        </Badge>
      </Button>
      <Menu
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: paperStyle(190, notifications.length, 400),
        }}
        disableScrollLock
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={styles.menuHeader}
        >
          <Grid item>
            <Typography variant="subtitle1">
              <b>Notificações</b>
            </Typography>
          </Grid>
          <Grid item>
            <Button
              className={classes.textButton}
              color="primary"
              onClick={handleMarkAllAsRead}
            >
              Marcar todas como lidas
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.notificationDivider} />

        {notifications.length
          ? notifications.map(item => (
              <MenuItem
                key={item.id}
                disabled={item.isRead}
                style={{ whiteSpace: 'normal' }}
                onClick={handleRedirect(item.redirectTo)}
              >
                <Box display="flex" flexDirection="row">
                  <Box>
                    <ListItemIcon>
                      {item.isRead ? <Drafts /> : <Mail color="primary" />}
                    </ListItemIcon>
                  </Box>
                  <Box className={styles.text}>
                    <Typography
                      style={{
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                      }}
                    >
                      {item.message}
                    </Typography>
                    <Typography variant="caption">
                      {formatShortDate(item.createdAt)}
                    </Typography>
                  </Box>
                </Box>
              </MenuItem>
            ))
          : null}
      </Menu>
    </>
  );
};

export default NotificationButton;
