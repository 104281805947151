import { Button } from '@material-ui/core';
import React from 'react';
import { basedGray, darkGray, white, whiteFont } from 'styles/theme';

interface ButtonProperties {
  action?: () => void;
  icon: React.ReactNode;
  text: string;
  active: boolean;
  className?: string;
  disabled?: boolean;
}

const SelectButton: React.FC<ButtonProperties> = ({
  action,
  icon,
  text,
  active,
  className,
  disabled,
}) => {
  return (
    <Button
      disableElevation
      style={{
        background: active ? whiteFont : white,
        color: active ? darkGray : basedGray,
        borderWidth: 1.5,
        borderColor: active ? '#004D83' : basedGray,
        borderStyle: 'solid',
        borderRadius: 8,
        textTransform: 'none',
        padding: '15px 45px',
        width: 310,
      }}
      startIcon={icon}
      onClick={action}
      className={className}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export default SelectButton;
