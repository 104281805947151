import { CustomSearchFiltersProps } from 'components/CustomSearchFilters/types';
import { NestCrudTableFetchData } from 'components/NestCrudTable/types';
import { getYear } from 'date-fns';
import { StatusCodes } from 'http-status-codes';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ADMIN_ROUTE } from 'routes/consts';
import TuitionService from 'services/tuitionService';
import { useAppStore } from 'store';
import { ISchoolClass } from 'types/schoolClass';

export const useTuitionController = () => {
  const history = useHistory();
  const showAlert = useAppStore(state => state.alert.showAlert);
  const [schoolClassFilters, setSchoolClassFilters] = useState({
    name: '',
    referenceYear: getYear(new Date()),
  });

  const loadTuitions: NestCrudTableFetchData<ISchoolClass> = useCallback(
    async ({ page, perPage }) => {
      const { data: response, status } = await TuitionService.filterTuitions({
        join: [['details']],
        page,
        limit: perPage,
        search: {
          $and: [
            {
              name: {
                $contL: schoolClassFilters.name,
              },
              'details.referenceYear': schoolClassFilters.referenceYear,
            },
          ],
        },
        sort: {
          field: 'id',
          order: 'ASC',
        },
      });
      if (status === StatusCodes.OK) {
        return {
          data: response.data,
          total: response.total,
        };
      } else {
        showAlert({
          message: 'Erro ao carregar anuidades',
          severity: 'error',
        });
      }

      return {
        data: [],
        total: 0,
      };
    },
    [schoolClassFilters.name, schoolClassFilters.referenceYear, showAlert],
  );

  const handleNavigateToDetails = (schoolClassId: string) => {
    history.push(ADMIN_ROUTE.TUITION_DETAILS.replace(':id', schoolClassId));
  };

  const handleFiltersUpdated: CustomSearchFiltersProps['onFiltersUpdated'] =
    filters => {
      setSchoolClassFilters(prevState => ({ ...prevState, ...filters }));
    };

  return {
    handleFiltersUpdated,
    handleNavigateToDetails,
    loadTuitions,
  };
};
