import React from 'react';
import { FormControl, Grid, TextField } from '@material-ui/core';

import { CustomButton } from 'components/CustomButton';
import { UseApplyCouponControllerProps } from './types';

const ApplyCouponComponent: React.FC<UseApplyCouponControllerProps> = ({
  coupon,
  loading,
  loadCoupon,
  removeCoupon,
  handleChangeCoupon,
  couponName,
}) => {
  return (
    <Grid item container direction="row" alignItems="center" spacing={2}>
      <Grid item>
        <FormControl>
          <TextField
            variant="outlined"
            label="Cupom"
            value={couponName}
            name="couponName"
            onChange={handleChangeCoupon}
            disabled={!!coupon}
          />
        </FormControl>
      </Grid>
      <Grid item>
        <CustomButton
          variant="secondary"
          textVariant="bold"
          loading={loading}
          onClick={!coupon ? loadCoupon : removeCoupon}
          text={!coupon ? 'Aplicar Cupom' : 'Remover cupom'}
        />
      </Grid>
    </Grid>
  );
};

export default ApplyCouponComponent;
