import { Box, Grid } from '@material-ui/core';
import PageHeader from 'components/PageHeader';
import { IndicatorButton } from './IndicatorButton';
import { useMarketplaceHomeController } from './controller';

export const HomeMarketplace = () => {
  const {
    goToServicesManagement,
    goToPartnersManagement,
    goToAccessReports,
    goToContractsManagement,
  } = useMarketplaceHomeController();

  return (
    <Box paddingX="45px">
      <PageHeader
        title="Gerenciamento do "
        titleBoldSufix="Marketplace"
        subtitle="Selecione serviços ou parceiros para fazer o gerenciamento"
      />
      <Grid container direction="row" spacing={5}>
        <IndicatorButton
          title="Gestão de "
          titleBoldSufix="serviços"
          description="Adicione, delete ou edite serviços"
          onClick={goToServicesManagement}
        />
        <IndicatorButton
          title="Gestão de "
          titleBoldSufix="parceiros"
          description="Gestione permissões de parceiros"
          onClick={goToPartnersManagement}
        />
        <IndicatorButton
          title="Acesso a "
          titleBoldSufix="relatórios"
          description="Exporte relatórios de serviços e parceiros"
          onClick={goToAccessReports}
        />
        <IndicatorButton
          title="Gestão de "
          titleBoldSufix="contratos"
          description="Gerencie contratos de serviços"
          onClick={goToContractsManagement}
        />
      </Grid>
    </Box>
  );
};
