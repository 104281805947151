import { Box, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import { TransitionProps } from '@material-ui/core/transitions';

import { useAppStore } from 'store';
import { useStyles } from './styles';

// many loading triggers override each other
const GlobalSnackbarLoading = () => {
  const { config } = useAppStore(state => state.snackbarLoading);
  const classes = useStyles();

  if (!config) return null;

  const TransitionUp = (props: TransitionProps) => (
    <Slide {...props} direction="up" />
  );

  return (
    <Snackbar open={!!config} TransitionComponent={TransitionUp}>
      <Box display="flex" flexDirection="row" className={classes.container}>
        <CircularProgress size={20} />
        <Typography variant="subtitle2" className={classes.subtitle}>
          {config.message}
        </Typography>
      </Box>
    </Snackbar>
  );
};

export default GlobalSnackbarLoading;
