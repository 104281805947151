import React from 'react';
import {
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { formatCard } from 'pages/Client/PaymentMethod/utils';
import useGlobalStyles from 'styles';
import { CardListProperties } from './types';

const CardsList: React.FC<CardListProperties> = ({ cards, openModal }) => {
  const classes = useGlobalStyles();

  return (
    <>
      <Typography className={classes.mainScreenText3}>
        <b>Dados de pagamento</b>
      </Typography>
      <Divider className={classes.divider1} />

      {cards.length ? (
        <Table style={{ marginTop: 20 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography className={classes.listScreenHeaderText1}>
                  CARTÃO
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography className={classes.listScreenHeaderText1}>
                  BLOQUEAR/DESBLOQUEAR
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cards.map(card => (
              <TableRow key={card.id}>
                <TableCell>
                  {`${card.alias} ${formatCard(card.lastFour)}`}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() => openModal(card.id, card.isBlocked, false)}
                    color="default"
                  >
                    {card.isBlocked ? (
                      <CheckCircleOutline color="primary" />
                    ) : (
                      <Block color="primary" />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography variant="body1" style={{ marginBottom: 20 }}>
          <b>Este usuário ainda não tem cartões cadastrados.</b>
        </Typography>
      )}
    </>
  );
};

export default CardsList;
