import { CustomDialogRef } from 'components/CustomDialog/types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { CustomLoadingDialogProps } from './types';

export const useCustomLoadindDialogController = ({
  loading,
  rawTitle,
  rawSubtitle,
}: CustomLoadingDialogProps) => {
  const modalLoadingRef = React.useRef<CustomDialogRef>(null);

  const shouldRenderDefaultText = useMemo(
    () => !rawTitle && !rawSubtitle,
    [rawSubtitle, rawTitle],
  );

  const handleCloseModal = useCallback(() => {
    modalLoadingRef.current?.closeDialog();
  }, []);

  const handleOpenModal = useCallback(() => {
    modalLoadingRef.current?.openDialog();
  }, []);

  useEffect(() => {
    if (loading) {
      handleOpenModal();
    } else {
      handleCloseModal();
    }
  }, [handleCloseModal, handleOpenModal, loading]);

  return { modalLoadingRef, shouldRenderDefaultText };
};
