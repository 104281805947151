import { styled, Theme } from '@material-ui/core';
import { mediumGray } from 'styles/theme';

export const StyledContainer = styled('div')<Theme>(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '12%',
    fontSize: '18px',
    lineHeight: '25px',
    letterSpacing: '-0.3px',
    color: mediumGray,
    opacity: '0.5',
    '& span': {
      textAlign: 'center',
      width: '40%',
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
        width: '100%',
        marginTop: '70px',
      },
    },
  };
});
