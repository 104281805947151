import { TextField } from '@material-ui/core';
import MaskedTextField from 'components/MaskedTextField';
import { DATE_MASK } from 'texts';
import { Dependent } from 'types/dependent';

export const dependentFields = (dependent?: Dependent) => [
  {
    label: 'Nome completo',
    value: dependent?.name,
    name: 'name',
    component: TextField,
  },
  {
    mask: DATE_MASK,
    label: 'Data de nascimento',
    value: dependent?.birthday,
    name: 'birthday',
    component: MaskedTextField,
  },
  {
    label: 'Nome da mãe',
    value: dependent?.mother,
    name: 'mother',
    component: TextField,
  },
  {
    label: 'Naturalidade',
    value: dependent?.birthplace,
    name: 'birthplace',
    component: TextField,
  },
  {
    label: 'Colégio anterior',
    value: dependent?.previousSchool,
    name: 'previousSchool',
    component: TextField,
  },
];
