import { Divider, Grid, Paper, Typography } from '@material-ui/core';
import {
  AutocompleteOption,
  OnAutocompleteSelectItemCallback,
  OnAutocompleteTextCallback,
} from 'components/BaseAutocomplete/types';
import { CustomButton } from 'components/CustomButton';
import Permission from 'components/Permission';
import SearchAutocomplete from 'components/SearchAutocomplete';
import { CreateQueryParamsCallback } from 'components/SearchAutocomplete/types';
import { useAuth } from 'contexts/auth';
import React, { useCallback, useMemo, useState } from 'react';
import RegistrationService from 'services/registrationService';
import { useAppStore } from 'store';
import useGlobalStyles from 'styles';
import { COUPON_APPLIED, COUPON_REMOVED, GENERAL_ERROR } from 'texts';
import { Coupon } from 'types/coupon';
import { COUPON_SOURCE, USER_ROLES } from 'types/enums';
import { shallow } from 'zustand/shallow';
import { useStyles } from './styles';
import { Properties } from './types';

const MIN_TEXT_LENGTH = 2;

const AddCoupon: React.FC<Properties> = ({ registration }) => {
  const { showAlert, showLoading, closeLoading } = useAppStore(
    state => ({
      showAlert: state.alert.showAlert,
      showLoading: state.snackbarLoading.showLoading,
      closeLoading: state.snackbarLoading.closeLoading,
    }),
    shallow,
  );
  const { user } = useAuth();
  const classes = useGlobalStyles();
  const styles = useStyles();

  const [couponField, setCouponField] = useState(
    registration.coupon?.name || '',
  );
  const [appliedCoupon, setAppliedCoupon] = useState<AutocompleteOption | null>(
    registration.coupon
      ? {
          id: registration.coupon.id,
          label: registration.coupon.name,
        }
      : null,
  );

  const isCouponButtonDisabled = useMemo(() => {
    if (appliedCoupon?.id) return false;

    return couponField?.length < MIN_TEXT_LENGTH;
  }, [appliedCoupon?.id, couponField?.length]);

  const applyCoupon = async () => {
    showLoading({
      message: 'Aplicando cupom...',
    });
    try {
      const { data: registrationWithCoupon } =
        await RegistrationService.applyCoupon(registration.id, couponField);

      showAlert({
        message: COUPON_APPLIED,
        severity: 'success',
      });

      setAppliedCoupon({
        id: registrationWithCoupon.coupon.id,
        label: registrationWithCoupon.coupon.name,
      });
    } catch (error: any) {
      showAlert({
        message: error?.message || GENERAL_ERROR,
        severity: 'error',
      });
    } finally {
      closeLoading();
    }
  };

  const removeCoupon = async () => {
    try {
      await RegistrationService.removeCoupon(registration.id);

      setAppliedCoupon(null);
      showAlert({
        message: COUPON_REMOVED,
        severity: 'success',
      });
    } catch (error) {
      showAlert({
        message: GENERAL_ERROR,
        severity: 'error',
      });
    }
  };

  const handleToggleCoupon = () => {
    if (appliedCoupon) {
      return removeCoupon();
    }

    return applyCoupon();
  };

  const handleOnChangeText: OnAutocompleteTextCallback = useCallback(text => {
    setCouponField(text);
  }, []);

  const handleOnSelectCoupon: OnAutocompleteSelectItemCallback = useCallback(
    couponOption => {
      setCouponField(couponOption.label);
    },
    [],
  );

  const handleOnCreateQueryParamsCallback: CreateQueryParamsCallback =
    useCallback(searchText => {
      return {
        fields: ['id', 'name'],
        limit: 10,
        search: {
          name: { $contL: searchText },
          source: COUPON_SOURCE.REGISTRATION,
        },
      };
    }, []);

  return (
    <Paper elevation={0} className={styles.paper}>
      <Grid container direction="column" alignItems="flex-start">
        <Typography className={classes.mainScreenText3}>
          <b>Cupom de desconto</b>
        </Typography>

        <Divider className={classes.divider1} />

        <Grid item container direction="row" alignItems="center" spacing={3}>
          <Grid item xs>
            <SearchAutocomplete
              searchLabel="Buscar cupom por nome..."
              filterEndpointPath="coupons"
              getAutocompleteOptionLabel={(option: Coupon) => option.name}
              createQueryParamsCallback={handleOnCreateQueryParamsCallback}
              onChange={handleOnChangeText}
              onSelect={handleOnSelectCoupon}
              minTextLength={MIN_TEXT_LENGTH}
              initialValue={couponField}
              disabled={!!appliedCoupon}
            />
          </Grid>
          <Permission roles={[USER_ROLES.ADMIN]} user={user}>
            <Grid item>
              <CustomButton
                variant="secondary"
                onClick={handleToggleCoupon}
                disabled={isCouponButtonDisabled}
              >
                {appliedCoupon ? 'Remover cupom' : 'Aplicar cupom'}
              </CustomButton>
            </Grid>
          </Permission>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AddCoupon;
