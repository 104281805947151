import { yupResolver } from '@hookform/resolvers/yup';
import { CustomDialogRef } from 'components/CustomDialog/types';
import { useStyles } from 'pages/Admin/ContractTemplates/ContractTemplateForm/styles';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import InvoicesService from 'services/invoicesService';
import PaymentService from 'services/paymentService';
import { useAppStore } from 'store';
import { formatCurrencyValueToNumber } from 'utils';
import { FeedbackMessages } from './consts';
import { schema } from './schema';
import {
  CreateInvoiceFormProps,
  GetPaymentForInvoiceByPaymentId,
  IInvoiceFieldValues,
} from './types';

export const CreateInvoiceFormController = ({
  paymentId,
}: CreateInvoiceFormProps) => {
  const styles = useStyles();

  const [payment, setPayment] =
    useState<GetPaymentForInvoiceByPaymentId | null>();
  const [loadingData, setLoadingData] = useState(false);
  const [loadingCreateInvoice, setLoadingCreateInvoice] = useState(false);

  const showAlert = useAppStore(state => state.alert.showAlert);

  const createInvoiceRef = useRef<CustomDialogRef>(null);
  const confirmCreateInvoiceRef = useRef<CustomDialogRef>(null);

  const history = useHistory();

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    setValue,
    getValues,
  } = useForm<IInvoiceFieldValues>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const loadPayment = useCallback(async () => {
    try {
      setLoadingData(true);
      const response = await PaymentService.getPaymentForInvoiceByPaymentId(
        paymentId,
      );

      setPayment(response.data);
    } catch (error: any) {
      showAlert({
        message: error?.message || FeedbackMessages.ERROR_LOADING_PAYMENT,
        severity: 'error',
      });
    } finally {
      setLoadingData(false);
    }
  }, [paymentId, showAlert]);

  const handleCloseConfirmModal = () => {
    confirmCreateInvoiceRef.current?.closeDialog();
    history.goBack();
  };

  const handleOpenCreateInvoiceModal = () => {
    createInvoiceRef.current?.openDialog();
  };

  const handleCreateInvoice = useCallback(async () => {
    try {
      const { serviceAmount, serviceUnconditionalDiscount } = getValues();

      setLoadingCreateInvoice(true);
      await InvoicesService.createManual({
        paymentId,
        serviceAmount: formatCurrencyValueToNumber(serviceAmount.toString()),
        serviceUnconditionalDiscount: formatCurrencyValueToNumber(
          serviceUnconditionalDiscount.toString(),
        ),
        competenceDate: new Date(),
      });

      createInvoiceRef.current?.closeDialog();
      confirmCreateInvoiceRef.current?.openDialog();
    } catch (error: any) {
      showAlert({
        message: error?.message || FeedbackMessages.ERROR_CREATING_INVOICE,
        severity: 'error',
      });
    } finally {
      setLoadingCreateInvoice(false);
    }
  }, [paymentId, showAlert, getValues]);

  useEffect(() => {
    loadPayment();
  }, [loadPayment]);

  return {
    styles,
    payment: payment?.paymentData,
    handleSubmit,
    errors,
    register,
    control,
    setValue,
    getValues,
    loadingData,
    handleCreateInvoice,
    loadingCreateInvoice,
    createInvoiceRef,
    confirmCreateInvoiceRef,
    handleCloseConfirmModal,
    handleOpenCreateInvoiceModal,
    descriptionInvoice: payment?.descriptionInvoice,
  };
};
