import { styled } from '@material-ui/core';
import SpinnerButton from 'components/SpinnerButton';

export const ButtonToLogin = styled(SpinnerButton)(({ theme }) => ({
  marginTop: 66,
  padding: '22px 45px',
  borderRadius: 8,
  [theme.breakpoints.down('md')]: {
    padding: '11px 22.5px',
  },
}));
