import React from 'react';

import { CustomIcon } from 'components/CustomIcon';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import {
  PaymentMethods,
  ScheduleConflictFeedback,
  ScheduleInfosCard,
  ServiceInfos as ServiceInfosContainer,
} from '../styles';
import { useStyles } from './styles';
import { ServiceInfosProps } from '../types';

export const ServiceInfos: React.FC<ServiceInfosProps> = ({
  isLoading,
  service,
  selectServiceClass,
  onChangeSelectServiceClass,
  selectedServiceClass,
  serviceConflict,
}) => {
  const styles = useStyles();

  return (
    <>
      <ServiceInfosContainer>
        {/* <ButtonAddToFavorites
          // TODO: INTEGRATE WHEN  ADD INTEREST FEATURE TO MARKETPLACE
          onClick={handleOpenModalShowInterest}
          startIcon={<CustomIcon name="unfilledWhiteHeart" />}
        >
          Mostrar interesse
        </ButtonAddToFavorites> */}
        <ScheduleInfosCard>
          <h4>Selecione uma turma</h4>
          <div className="container-classes">
            {selectServiceClass.length
              ? selectServiceClass.map(serviceClass => (
                  <Button
                    onClick={() => onChangeSelectServiceClass(serviceClass.id)}
                    variant="outlined"
                    key={serviceClass.id}
                    className={serviceClass.isSelected ? 'active' : ''}
                  >
                    {serviceClass.name}
                  </Button>
                ))
              : null}
          </div>
        </ScheduleInfosCard>
        <ScheduleInfosCard>
          <CustomIcon name="scheduleBlack" />
          {selectedServiceClass?.calendars.length
            ? selectedServiceClass.calendars.map(calendar => (
                <div className="schedule" key={calendar.id}>
                  <b>{calendar.day}</b> {calendar.startTime} -{' '}
                  {calendar.endTime}
                </div>
              ))
            : null}
          {!isLoading ? (
            <ScheduleConflictFeedback>
              <div id="schedule-conflict-feedback-content">
                <CustomIcon
                  name={
                    serviceConflict.hasConflict
                      ? 'closeXOutlineRed'
                      : 'checkCircle'
                  }
                />

                <p
                  className={
                    serviceConflict.hasConflict ? 'has-conflict' : undefined
                  }
                >
                  {serviceConflict.title}
                </p>
              </div>

              {serviceConflict.description ? (
                <p
                  id="schedule-conflict-feedback-description"
                  className={styles.conflictMessage}
                >
                  {serviceConflict.description}
                </p>
              ) : null}
            </ScheduleConflictFeedback>
          ) : (
            <Grid
              container
              item
              justifyContent="center"
              className={styles.circularProgressMargin}
            >
              <CircularProgress className={styles.circularProgressColor} />
            </Grid>
          )}
        </ScheduleInfosCard>
        {service.amount && !service.inviteCode ? (
          <PaymentMethods isMobile>
            <div id="payment-method-caption">Formas de pagamento</div>
            <div id="payment-method-items">
              <span>
                <CustomIcon name="creditCardBlack" /> Cartão de crédito
              </span>
              <span>
                <CustomIcon name="bankSlipAttachMoney" /> À vista / Boleto
              </span>
              <span>
                <CustomIcon name="pixBlack" /> Pix
              </span>
            </div>
          </PaymentMethods>
        ) : null}
      </ServiceInfosContainer>
      {/* TODO: show interest confirmation */}
      {/* <ModalInfoShowInterest
        open={modalShowInterestOpen}
        onClose={handleCloseModalShowInterest}
      /> */}
    </>
  );
};
