import { CreateQueryParams } from '@nestjsx/crud-request';
import { api, getWithFilter } from 'services/apiService';
import { FinancialGuardian } from 'types/financialGuardian';
import { NewUser, User } from 'types/user';

export default class UserService {
  static async login(email: string, password: string) {
    try {
      return await api.post('auth/login', { email, password });
    } catch (error: any) {
      return error.response;
    }
  }

  static async me() {
    try {
      return await getWithFilter('me', {
        join: [['financialGuardian'], ['notifications']],
      });
    } catch (error: any) {
      return error.response;
    }
  }

  static async users(query?: CreateQueryParams) {
    try {
      return await getWithFilter('users', query);
    } catch (error: any) {
      return error.response;
    }
  }

  static async user(id: number, query?: CreateQueryParams) {
    try {
      return await getWithFilter(`users/${id}`, query);
    } catch (error: any) {
      return error.response;
    }
  }

  static async createUserFinancialGuardian(user: NewUser) {
    try {
      return await api.post('public/financial-guardians/new', user);
    } catch (error: any) {
      return error.response;
    }
  }

  static async createUser(user: NewUser) {
    try {
      return await api.post('public/users/new', user);
    } catch (error: any) {
      return error.response;
    }
  }

  static async updateUser(id: number, user: Partial<User>) {
    try {
      return await api.patch(`users/${id}`, user);
    } catch (error: any) {
      return error.response;
    }
  }

  static async deleteUser(id: number) {
    try {
      return await api.delete(`users/${id}`);
    } catch (error: any) {
      return error.response;
    }
  }

  static async updateUserAndGuardian(
    id: number,
    user: Partial<User>,
    financialGuardian?: Partial<FinancialGuardian>,
  ) {
    try {
      return await api.patch(`users/${id}/update-user-and-guardian`, {
        user,
        financialGuardian,
      });
    } catch (error: any) {
      return error.response;
    }
  }
}
