import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { CustomCalendar } from 'components/CustomCalendar';
import { SelectInput } from 'components/Forms/SelectInput/SelectInput';
import PageHeader from 'components/PageHeader';
import { useReportController } from './controller';
import { ReportHomeGridContainer } from './styles';

export const Reports = () => {
  const {
    handleChangeSchoolClasses,
    handleChangeService,
    handleChangeCalendarView,
    mockEvents,
    schoolClasses,
    service,
    tabletWidthBreakPoint,
    calendarViewType,
    calendarViewTypesOptions,
    schoolClassesOptions,
    servicesOptions,
  } = useReportController();

  return (
    <Box paddingX="45px">
      <ReportHomeGridContainer container item direction="column">
        <PageHeader
          titleBoldPrefix="Grade de horários "
          title="dos serviços"
          subtitle="Veja a grade de horários e escolha filtros para ver com mais detalhes"
        />

        <Grid container item sm={12} spacing={2}>
          <Grid item xs={12} sm={4}>
            <SelectInput
              name="typeView"
              label="Tipo de visualização"
              value={calendarViewType}
              onChange={handleChangeCalendarView}
              options={calendarViewTypesOptions}
              selectProps={{ disabled: tabletWidthBreakPoint }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectInput
              label="Série / Ano"
              name="schoolClass"
              isMultiple
              value={schoolClasses}
              onChange={handleChangeSchoolClasses}
              options={schoolClassesOptions}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectInput
              label="Serviços"
              name="services"
              isMultiple
              value={service}
              onChange={handleChangeService}
              options={servicesOptions}
            />
          </Grid>
        </Grid>
      </ReportHomeGridContainer>

      <Box maxWidth="100%">
        <CustomCalendar viewType={calendarViewType} events={mockEvents} />
      </Box>
    </Box>
  );
};
