import { CustomIcon } from 'components/CustomIcon';
import { formatBRL } from 'utils';
import { toLower } from 'lodash';
import { EServicesTypes } from 'pages/Admin/Marketplace/Services/components/ServiceForm/types';
import {
  PaymentMethods,
  ServiceDescription as ServiceDescriptionContainer,
  ServiceDescriptionAvatar,
  ServiceDescriptionHeader,
  ServiceDescriptionHeaderContent,
} from '../styles';
import {
  DescriptionAvatar,
  HeaderContentProps,
  IServiceDetailsResponse,
} from '../types';

const Avatar = ({
  amount,
  partnerLogo,
  inviteCode,
  serviceType,
}: DescriptionAvatar) => {
  if (amount && !inviteCode && serviceType?.name !== EServicesTypes.EVENT) {
    return <ServiceDescriptionAvatar coverImage={partnerLogo} />;
  }
  return null;
};

const HeaderContent = ({
  amount,
  serviceRecurrence,
  numberOfInstallments,
  inviteCode,
  serviceType,
}: HeaderContentProps) => {
  if (inviteCode) {
    return (
      <span id="without-additional-cost" className="by-invite">
        Por convite
      </span>
    );
  }

  if (!amount) {
    return <span id="without-additional-cost">Sem anuidade</span>;
  }

  if (serviceType?.name === EServicesTypes.EVENT) {
    return (
      <div id="amount-description">
        <div id="amount">
          <b>{formatBRL(amount)}</b>
        </div>
      </div>
    );
  }

  if (amount && numberOfInstallments) {
    return (
      <div id="amount-description">
        <div id="amount">
          <b>{formatBRL(amount)}</b> / {toLower(serviceRecurrence?.name)}
        </div>{' '}
        {numberOfInstallments > 1 ? `(em até ${numberOfInstallments}x)` : null}
      </div>
    );
  }

  return null;
};

export const ServiceDescription = ({
  service,
}: {
  service: IServiceDetailsResponse;
}) => {
  return (
    <ServiceDescriptionContainer>
      <ServiceDescriptionHeader>
        <Avatar
          amount={service.amount}
          partnerLogo={service.partnerLogo}
          inviteCode={service.inviteCode}
          serviceType={service.serviceType}
        />
        <ServiceDescriptionHeaderContent>
          <div id="title">
            {service.name}{' '}
            {service?.partner?.name ? `- ${service.partner.name}` : ''}
          </div>
          <HeaderContent
            amount={service.amount}
            numberOfInstallments={service.numberOfInstallments}
            inviteCode={service.inviteCode}
            serviceRecurrence={service.serviceRecurrence}
            serviceType={service.serviceType}
          />
          {service.registrationTaxValue ? (
            <div id="amount">
              {`Taxa de matrícula: ${formatBRL(service.registrationTaxValue)}`}
            </div>
          ) : null}
        </ServiceDescriptionHeaderContent>
      </ServiceDescriptionHeader>

      <div id="service-description">
        <span>{service.description}</span>
      </div>
      {service.amount && !service.inviteCode ? (
        <PaymentMethods isMobile={false}>
          <div id="payment-method-caption">Formas de pagamento</div>
          <div id="payment-method-items">
            <span>
              <CustomIcon name="creditCardBlack" /> Cartão de crédito
            </span>
            <span>
              <CustomIcon name="bankSlipAttachMoney" /> À vista / Boleto
            </span>
            <span>
              <CustomIcon name="pixBlack" /> Pix
            </span>
          </div>
        </PaymentMethods>
      ) : null}
    </ServiceDescriptionContainer>
  );
};
