import { findDocument } from 'pages/Client/ViewDependent/utils';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { DOCUMENT_TYPE } from 'types/enums';
import { useStyles } from './styles';
import { IListItemPropertiesController } from './types';

export const useRegistrationListItemController = ({
  registration,
}: IListItemPropertiesController) => {
  // Styles
  const styles = useStyles();

  // Navigation
  const history = useHistory();

  // Memo
  const savedPicture = useMemo(
    () =>
      registration?.documents
        ? findDocument(registration?.documents, DOCUMENT_TYPE.IMAGEM_PERFIL)
        : null,
    [registration],
  );

  // Callbacks
  const goToDetails = (registrationId: number) => () => {
    history.push({
      pathname: `/dependents/${registrationId}`,
    });
  };

  return {
    styles,
    schoolGrade: registration?.schoolGrade,
    schoolYear: registration?.schoolYear,
    number: registration?.number,
    enrollmentNumber: registration?.enrollmentNumber,
    status: registration?.status,
    savedPicture,
    goToDetails,
  };
};
