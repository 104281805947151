import { isObject } from 'utils/typeCheckers';
import { AutocompleteOption } from './types';

export const isAutocompleteOption = (
  value: unknown,
): value is AutocompleteOption => {
  const keysOfAutocompleteOption: (keyof AutocompleteOption)[] = [
    'id',
    'label',
  ];
  return isObject(value) && keysOfAutocompleteOption.every(key => key in value);
};

export const getDimensionBasedOnIcon = (hasIcon?: boolean) => {
  if (hasIcon) return '80px';

  return '30px';
};
