import { useCallback, useEffect, useRef, useState } from 'react';

const useNewWindow = () => {
  const [loading, setLoading] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const newWindow = useCallback((documentPath: string) => {
    setLoading(true);
    const newWindow = window.open(documentPath, 'name', 'width=800,height=600');

    intervalRef.current = setInterval(() => {
      if (newWindow?.closed) {
        setLoading(false);
        if (intervalRef.current) clearInterval(intervalRef.current);
      }
    }, 500);
  }, []);

  useEffect(() => {
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  return {
    loading,
    newWindow,
  };
};

export default useNewWindow;
