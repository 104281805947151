import React from 'react';
import { ContainerButton, TextButton } from '../../styles';

import { FooterContainer, LoadingProgress } from './styles';
import { IFormFooterProps } from './types';

export const FormFooter = ({
  onSubmit,
  onCancel,
  type,
  loading,
}: IFormFooterProps) => {
  return (
    <FooterContainer position="absolute">
      <TextButton onClick={onCancel}>Cancelar</TextButton>
      <ContainerButton type="submit" onClick={onSubmit}>
        {type === 'create' ? 'Criar serviço' : 'Atualizar dados'}
        {loading ? <LoadingProgress size={24} color="secondary" /> : null}
      </ContainerButton>
    </FooterContainer>
  );
};
