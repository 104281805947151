import { INVALID_FIELD, REQUIRED_FIELD } from 'texts';
import { states } from 'utils';
import * as Yup from 'yup';
import { noNumbersStringSchema, stringSchema } from './stringSchema';

export const addressSchema = Yup.object({
  number: stringSchema,
  cep: Yup.string()
    .transform(value => value.replace(/\D+/g, ''))
    .test('is full cep', INVALID_FIELD, value => isFullCep(value))
    .required(REQUIRED_FIELD),
  complement: Yup.string(),
  street: stringSchema,
  district: noNumbersStringSchema,
  city: noNumbersStringSchema,
  state: noNumbersStringSchema.test('is valid state', INVALID_FIELD, value =>
    states.includes(value || ''),
  ),
});

export const dependentAddressSchema = Yup.object().when('hasGuardianAddress', {
  is: false,
  then: addressSchema,
});

const isFullCep = (cep = '') => {
  return cep.replace(/\D+/g, '').length === 8;
};
