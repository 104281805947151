import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  paper: {
    borderRadius: 15,
    border: '1px solid black',
    padding: 20,
    maxWidth: 290,
  },
  icon: {
    maxWidth: '30%',
  },
  text: {
    maxWidth: '70%',
  },
  message: {
    textAlign: 'justify',
    textJustify: 'inter-word',
    fontSize: 12,
  },
});
