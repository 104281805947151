import React from 'react';

import {
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import EmptyListPlaceholder from 'components/EmptyListPlaceholder';
import { TablePaper } from 'components/TablePaper';
import { NO_COUPON_DETAILS } from 'texts';
import { formatDate } from '../utils';
import { ITableProperties } from '../types';
import { useCouponRegistrationsController } from './controller';

export const CouponRegistrations = ({
  registrations = [],
}: ITableProperties) => {
  const { classes, headers, styles } = useCouponRegistrationsController();

  return registrations.length ? (
    <TablePaper>
      <TableHead>
        <TableRow>
          {headers.map(header => (
            <TableCell key={header}>
              <Typography className={classes.listScreenHeaderText1}>
                {header}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {registrations.map(register => {
          return (
            <TableRow key={register.id}>
              <TableCell>{register.dependent.name}</TableCell>
              <TableCell>{register.number ?? '---'}</TableCell>
              <TableCell>
                {register.dependent?.createdByUser?.name ?? '---'}
              </TableCell>
              <TableCell>
                {formatDate(
                  register?.couponAppliedByAdmin?.createdAt ??
                    register?.createdAt,
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </TablePaper>
  ) : (
    <Grid className={styles.grid}>
      <EmptyListPlaceholder message={NO_COUPON_DETAILS} />
    </Grid>
  );
};
