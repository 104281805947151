import { useState, useMemo } from 'react';
import {
  QuerySort,
  CondOperator,
  QueryFilter,
  QuerySortOperator,
} from '@nestjsx/crud-request';

enum ORDER {
  ASC = 'ASC',
  DESC = 'DESC',
}

// TODO: Fix all types to work with nestjsx-crud-request
export const useFilters = (defaultFilters: QueryFilter[] = []) => {
  const [sort, setSort] = useState<QuerySort>({
    field: 'id',
    order: ORDER.ASC,
  });
  const [filters, setFilters] = useState<QueryFilter[]>(defaultFilters);
  const [genericFilter, setGenericFilter] = useState<QueryFilter[]>([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);

  const filterFields = useMemo(
    () =>
      filters.map(f => ({
        [f.field]: { [f.operator]: f.value },
      })),
    [filters],
  );

  const genericFilterFields = useMemo(
    () =>
      genericFilter.map(f => ({
        [f.field]: { [f.operator]: f.value },
      })),
    [genericFilter],
  );

  const ignoredFilterValues = useMemo(
    () => [undefined, 'Todos', 'Todas', 'all'],
    [],
  );

  const handleUpdateFilters = (
    field: string,
    genericObject: { filterTitle?: string; value: any },
    op?: CondOperator,
    isGeneric?: boolean,
  ) => {
    const operator = op || CondOperator.EQUALS;
    const keptFilters =
      (filters && filters.filter(f => f.field !== field)) || [];
    const { value } = genericObject;

    if (!ignoredFilterValues.includes(value)) {
      keptFilters.push({ field, operator, value });
    }
    isGeneric ? setGenericFilter(keptFilters) : setFilters(keptFilters);
  };

  const handleSort = (field: string) => {
    let order: QuerySortOperator = ORDER.DESC;
    if (field === sort.field) {
      order = sort.order === ORDER.ASC ? ORDER.DESC : ORDER.ASC;
    }
    setSort({
      field,
      order,
    });
  };

  const handleGenericSearch = (
    event: any,
    filters: { field: string; operator: any }[],
  ) => {
    const value = event?.currentTarget?.value || event?.target?.value;
    setSearch(value);

    if (value) {
      filters.map(filter =>
        handleUpdateFilters(
          filter.field,
          { filterTitle: filter.field, value },
          filter.operator,
          true,
        ),
      );
      setPage(0);
      return;
    }

    handleClearFilters();
  };

  const handleClearFilters = () => {
    setSearch('');
    setGenericFilter([]);
    setFilters([]);
    setPage(0);
  };

  const handleSetValuePage = (value: number) => {
    setPage(value);
  };

  return {
    filterFields,
    genericFilterFields,
    sort,
    search,
    page,
    handleGenericSearch,
    handleSort,
    handleUpdateFilters,
    handleClearFilters,
    handleSetValuePage,
  };
};
