import { useAlert } from 'hooks/Alert';
import { StatusCodes } from 'http-status-codes';
import { useState } from 'react';
import PaymentService from 'services/paymentService';
import useGlobalStyles from 'styles';
import { GENERAL_ERROR, INCOME_TAX_EXPORT_FORBIDDEN } from 'texts';
import { MESSAGE_TYPE } from 'hooks/Alert/types';
import { PAYMENT_STATUS } from 'types/enums';
import { ButtonProps } from './types';

export const useButtonDowloandIncomeTaxReturnController = ({
  paymentId,
  paymentStatus,
  financialGuardianId,
}: ButtonProps) => {
  const classes = useGlobalStyles();
  const [isLoading, setIsLoading] = useState(false);

  const { openAlert, closeAlert, alertMessage, alertType, isShowAlert } =
    useAlert();

  const exportIncomeTaxReturn = async () => {
    try {
      setIsLoading(true);
      if (paymentStatus === PAYMENT_STATUS.CANCELED) {
        openAlert({
          message: INCOME_TAX_EXPORT_FORBIDDEN,
          type: MESSAGE_TYPE.ERROR,
        });
        return;
      }

      const verifyDefaultingPayments =
        await PaymentService.getDefaultingPayment(financialGuardianId);

      if (verifyDefaultingPayments.status === StatusCodes.BAD_REQUEST) {
        openAlert({
          message: INCOME_TAX_EXPORT_FORBIDDEN,
          type: MESSAGE_TYPE.ERROR,
        });
        return;
      }

      const response = await PaymentService.exportIncomeTaxReturnByPaymentId(
        paymentId,
      );

      if (response.status === StatusCodes.CREATED) {
        window.open(response.data.fileUrl);
      } else {
        openAlert({ message: response.data.message, type: MESSAGE_TYPE.ERROR });
      }
    } catch (error) {
      openAlert({ message: GENERAL_ERROR, type: MESSAGE_TYPE.ERROR });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    exportIncomeTaxReturn,
    classes,
    isLoading,
    closeAlert,
    alertMessage,
    alertType,
    isShowAlert,
  };
};
