import useGlobalStyles from 'styles';
import { TABLE_HEADERS } from 'utils/tableHeaders';

const headers = [
  TABLE_HEADERS.RECURRENCE,
  TABLE_HEADERS.SERVICE_TYPE,
  TABLE_HEADERS.COORDINATOR,
];

export const useServiceDataTableController = () => {
  // Styles
  const classes = useGlobalStyles();

  return {
    classes,
    headers,
  };
};
