import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export const formatDatePtBr = (date: Date | string) => {
  return format(new Date(date), 'dd/MM/yyyy', {
    locale: ptBR,
  });
};

export const formatDateTimePtBr = (date: Date | string) => {
  return format(new Date(date), 'dd/MM/yyyy HH:mm', {
    locale: ptBR,
  });
};

export const formatMonthYearDatePtBr = (date: Date | string) => {
  return format(new Date(date), 'MM/yyyy', {
    locale: ptBR,
  });
};
