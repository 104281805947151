import { SideMenu } from 'components/SideMenu';
import { CartContextProvider } from 'contexts/cart';
import Contracts from 'pages/Admin/Contracts';
import { FinancialReport } from 'pages/Admin/NewFinancialReport';
import PaymentDetails from 'pages/Admin/PaymentDetails';
import AwaitingPayment from 'pages/Client/AwaitingPayment';
import Dependents from 'pages/Client/Dependents';
import { Home } from 'pages/Client/Home';
import IncomeTaxReturn from 'pages/Client/IncomeTaxReturn';
import { MarketPlaceContracts } from 'pages/Client/MarketPlaceContracts';
import { MarketplaceCart } from 'pages/Client/MarketplaceCart';
import MarketplaceOrderPayments from 'pages/Client/MarketplaceOrderPayments';
import { MarketplacePaymentCheckout } from 'pages/Client/MarketplacePaymentCheckout';
import { MarketplaceServicesDetail } from 'pages/Client/MarketplaceServicesDetail';
import MyCards from 'pages/Client/MyCards';
import { ClientMarketplaceHome } from 'pages/Client/NewMarketplace/Home';
import { MarketplaceSearchResults } from 'pages/Client/NewMarketplace/SearchResults';
import NewRegistration from 'pages/Client/NewRegistration';
import PaymentMethod from 'pages/Client/PaymentMethod';
import Profile from 'pages/Client/Profile';
import Registrations from 'pages/Client/Registrations';
import SignContract from 'pages/Client/SignContract';
import ViewDependent from 'pages/Client/ViewDependent';
import ViewRegistration from 'pages/Client/ViewRegistration';
import ViewStudentReportCards from 'pages/Client/ViewStudentReportCards';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CLIENT_ROUTE } from './consts';

// WARNING: make sure to change routes in src/components/SideMenu/utils aswell

const ClientRoutes: React.FC = () => (
  <CartContextProvider>
    <SideMenu>
      <Switch>
        {/* Home */}
        <Route path={CLIENT_ROUTE.HOME} exact component={Home} />
        <Route path={CLIENT_ROUTE.HOME_PROFILE} exact component={Profile} />

        {/* Dependents */}
        <Route path={CLIENT_ROUTE.DEPENDENTS} exact component={Dependents} />
        <Route
          path={CLIENT_ROUTE.DEPENDENT_DETAILS}
          component={ViewDependent}
        />

        {/* Financial */}
        <Route
          path={CLIENT_ROUTE.FINANCIAL}
          exact
          component={FinancialReport}
        />
        <Route
          path={CLIENT_ROUTE.FINANCIAL_DETAILS}
          component={PaymentDetails}
          exact
        />
        <Route
          path={CLIENT_ROUTE.PAYMENT_STATUS}
          component={AwaitingPayment}
          exact
        />

        {/* Student report cards */}
        <Route
          path={CLIENT_ROUTE.STUDENT_REPORT_CARDS}
          component={ViewStudentReportCards}
          exact
        />

        {/* Credit Cards */}
        <Route path={CLIENT_ROUTE.CREDIT_CARDS} component={MyCards} exact />

        {/* Registrations */}
        <Route
          path={CLIENT_ROUTE.REGISTRATIONS}
          exact
          component={Registrations}
        />
        <Route
          path={CLIENT_ROUTE.REGISTRATIONS_NEW}
          component={NewRegistration}
          exact
        />
        <Route
          path={CLIENT_ROUTE.REGISTRATION_PAYMENT_METHOD}
          component={PaymentMethod}
          exact
        />
        <Route
          path={CLIENT_ROUTE.REGISTRATION_DETAILS}
          exact
          component={ViewRegistration}
        />
        <Route
          path={CLIENT_ROUTE.REGISTRATION_SIGN_CONTRACT}
          component={SignContract}
          exact
        />

        {/* Marketplace */}
        <Route
          exact
          path={CLIENT_ROUTE.MARKETPLACE_HOME}
          component={ClientMarketplaceHome}
        />
        <Route
          exact
          path={CLIENT_ROUTE.MARKETPLACE_SEARCH}
          component={MarketplaceSearchResults}
        />
        <Route
          path={CLIENT_ROUTE.MARKETPLACE_SERVICE_DETAILS}
          exact
          component={MarketplaceServicesDetail}
        />
        <Route
          exact
          path={CLIENT_ROUTE.MARKETPLACE_CART}
          component={MarketplaceCart}
        />
        <Route
          exact
          path={CLIENT_ROUTE.MARKETPLACE_PAYMENT_CHECKOUT}
          component={MarketplacePaymentCheckout}
        />
        <Route
          exact
          path={CLIENT_ROUTE.MARKETPLACE_ORDER_CONTRACTS}
          component={MarketPlaceContracts}
        />
        <Route
          exact
          path={CLIENT_ROUTE.MARKETPLACE_ORDER_PAYMENTS}
          component={MarketplaceOrderPayments}
        />

        {/* Income Tax Return */}
        <Route
          exact
          path={CLIENT_ROUTE.INCOME_TAX_RETURN}
          component={IncomeTaxReturn}
        />

        {/* Contracts  */}
        <Route exact path={CLIENT_ROUTE.CONTRACTS} component={Contracts} />
        <Route path="*">
          <Redirect to={CLIENT_ROUTE.HOME} />
        </Route>
      </Switch>
    </SideMenu>
  </CartContextProvider>
);

export default ClientRoutes;
