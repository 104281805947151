import { useAuth } from 'contexts/auth';
import { useCart } from 'hooks/useCart';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';

export const useHeaderBarController = (isAdmin: boolean | undefined) => {
  // General Hooks

  const { user, signOut } = useAuth();
  const { totalItemsCart, handleResetCartContextData } = useCart();

  // Styles

  const styles = useStyles({ isAdmin })();

  // Navigation

  const history = useHistory();

  // Callbacks

  const logOut = () => {
    if (!isAdmin) {
      handleResetCartContextData();
    }
    localStorage.clear();
    signOut();
    history.push('/login');
  };

  const goToCart = () => {
    history.push('/client-marketplace/cart');
  };

  const goToProfile = () => {
    history.push(isAdmin ? '/adm-home/profile' : '/home/profile');
  };

  const goBack = () => {
    history.goBack();
  };

  return {
    styles,
    user,
    logOut,
    goToProfile,
    isMarketplace: history.location.pathname.includes('marketplace'),
    canGoBack: history.location.pathname.includes('marketplace'),
    goBack,
    goToCart,
    totalItemsCart,
  };
};
