import { ColoredEllipsisProps } from 'components/ColoredTableCell/types';
import {
  darkGray,
  error,
  primaryMedium,
  purple,
  secondaryMain,
  white,
  yellow,
} from 'styles/theme';
import { PAYMENT_STATUS } from 'types/enums';

const LIGHT_FONT_COLOR = white;
const DARK_FONT_COLOR = darkGray;

// DRAFT => Currently not being shown in FinancialTable.
export const getColorPropsFromPaymentStatus = (
  paymentStatus: PAYMENT_STATUS,
): ColoredEllipsisProps => {
  switch (paymentStatus) {
    case PAYMENT_STATUS.OPEN:
      return {
        textColor: LIGHT_FONT_COLOR,
        backgroundColor: darkGray,
      };
    case PAYMENT_STATUS.PROCESSING:
      return {
        textColor: LIGHT_FONT_COLOR,
        backgroundColor: purple,
      };
    case PAYMENT_STATUS.CANCELED:
    case PAYMENT_STATUS.ERROR:
      return {
        textColor: LIGHT_FONT_COLOR,
        backgroundColor: error,
      };
    case PAYMENT_STATUS.DEFAULTING:
      return {
        textColor: DARK_FONT_COLOR,
        backgroundColor: yellow,
      };
    case PAYMENT_STATUS.CONCLUDED:
      return {
        textColor: DARK_FONT_COLOR,
        backgroundColor: secondaryMain,
      };
    case PAYMENT_STATUS.FUTURE:
    case PAYMENT_STATUS.DRAFT:
    default:
      return {
        textColor: LIGHT_FONT_COLOR,
        backgroundColor: primaryMedium,
      };
  }
};
