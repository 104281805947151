import { MenuProps } from '@material-ui/core';
import { useServiceCreationController } from 'pages/Admin/Marketplace/Services/ServiceCreation/controller';
import { useEffect, useRef, useState } from 'react';
import { MenuProps as MenuPropsStructure } from '../../styles';
import { ScheduleTypeLabel } from './types';

const days = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
const scheduleType = [
  { label: ScheduleTypeLabel.EachDayHaveDifferentSchedule, value: true },
  { label: ScheduleTypeLabel.EachDayHaveEqualSchedule, value: false },
];

export const useSchedulerFormController = (
  defaultDays: Array<string>,
  canVary: boolean,
) => {
  // General Hooks
  const { renderValue } = useServiceCreationController();

  // Refs
  const inputRefMenuDimensions = useRef<HTMLInputElement>(null);

  // States
  const [canVaryEveryDay, setCanVaryEveryDay] = useState<boolean | undefined>(
    canVary,
  );
  const [daysSchedule, setDaysSchedule] = useState<Array<string>>([
    ...defaultDays,
  ]);
  const [menuProps, setMenuProps] = useState<Partial<MenuProps>>();

  const handleCanVaryEveryDayChange = (value: boolean) => {
    setCanVaryEveryDay(value);
  };

  const handleAddDaySchedule = (value: Array<string>) => {
    setDaysSchedule(value);
  };

  // Effects
  useEffect(() => {
    if (inputRefMenuDimensions?.current) {
      setMenuProps(
        MenuPropsStructure(inputRefMenuDimensions.current.clientWidth + 1),
      );
    }
  }, [inputRefMenuDimensions]);

  return {
    inputRefMenuDimensions,
    MenuProps: menuProps,
    renderValue,
    days,
    scheduleType,
    canVaryEveryDay,
    daysSchedule,
    handleCanVaryEveryDayChange,
    handleAddDaySchedule,
  };
};
