import { Box, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

export const FooterContainer = styled(Box)(() => {
  return {
    backgroundColor: '#F2F6FA',
    width: '105%',
    bottom: '-120px',
    boxShadow: '0px 6px 32px #CED4DA',
    height: '77px',
    marginLeft: '-70px',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    paddingRight: '85px',
  };
});

export const LoadingProgress = styled(CircularProgress)(() => {
  return {
    marginLeft: '18px',
  };
});
