import { Coupon } from 'types/coupon';

export enum ECouponValueType {
  Currency = 'currency',
  Percent = 'percent',
}

export type CouponValueType =
  | ECouponValueType.Currency
  | ECouponValueType.Percent;

export interface SubmitCoupon extends Partial<Coupon> {
  hasDuration: boolean;
  isLimited: boolean;
  hasEvent: boolean;
  usesCurrencyPix: boolean;
  usesCurrencyBankSlip: boolean;
  usesCurrencyCreditCard: boolean;
}
