import { usePollingEffect } from 'hooks/usePollingEffect';
import { StatusCodes } from 'http-status-codes';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CLIENT_ROUTE } from 'routes/consts';
import OrderService from 'services/orderService';
import { PAYMENT_STATUS } from 'types/enums';
import { ORDER_STATUS, Order } from 'types/order';

export const useMarketplaceOrderPaymentsController = () => {
  // ---------- Navigation ----------
  const routeParams = useParams<{ orderId: string }>();
  const history = useHistory();

  // ---------- States ----------
  const [orderDetails, setOrderDetails] = useState<Order>({} as Order);
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingDialog, setLoadingDialog] = useState(true);

  // ---------- Callbacks ----------
  const handleLoadingDialog = useCallback(({ status }: Order) => {
    if (
      status === ORDER_STATUS.DRAFT ||
      status === ORDER_STATUS.PENDING_CONTRACT_SIGNATURE ||
      status === ORDER_STATUS.QUEUE
    ) {
      setLoadingDialog(true);
    } else {
      setLoadingDialog(false);
    }
  }, []);

  const handleGoToPayments = () => {
    history.push(CLIENT_ROUTE.FINANCIAL);
  };

  // ---------- Effects ----------
  usePollingEffect(
    async () => {
      if (!routeParams.orderId) return;

      try {
        const response = await OrderService.getOrderDetails(
          routeParams.orderId,
          {
            join: [
              ['payments'],
              ['payments.subscriptions'],
              ['payments.bills'],
              ['payments.service'],
              {
                field: 'payments.dependent',
                select: ['id', 'name'],
              },
            ],
          },
        );
        if (response.status !== StatusCodes.OK) {
          throw new Error(
            response.message ||
              response.data?.message ||
              'Erro ao buscar detalhes do pedido',
          );
        }

        const orderDetails = response.data as Order;
        setOrderDetails(orderDetails);

        const hasOnePaymentNotFinishedProcessing = orderDetails.payments?.some(
          ({ status }) => status === PAYMENT_STATUS.PROCESSING,
        );

        if (hasOnePaymentNotFinishedProcessing)
          return {
            mustStop: false,
          };

        const hasOrderFinishedProcessing =
          orderDetails.status === ORDER_STATUS.CONCLUDED ||
          orderDetails.status === ORDER_STATUS.CONCLUDED_WITH_ERROR;

        return {
          mustStop: hasOrderFinishedProcessing,
        };
      } catch (error: any) {
        alert(error?.message || 'Erro ao buscar detalhes do pedido');
      } finally {
        setLoadingPage(false);
      }
    },
    [routeParams.orderId],
    {
      attemptsLimit: 20,
    },
  );

  useEffect(() => {
    handleLoadingDialog(orderDetails);
  }, [handleLoadingDialog, orderDetails]);

  return {
    loadingPage,
    loadingDialog,
    orderDetails,
    handleGoToPayments,
  };
};
