import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Box,
  Button,
  Dialog,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import SpinnerButton from 'components/SpinnerButton';
import useGlobalStyles from 'styles';
import { error } from 'styles/theme';
import { useNewServiceCodesModalController } from './controller';
import { NewServiceCodesModalProps } from './types';
import { useStyles } from './styles';

export const NewServiceCodesModal: React.FC<NewServiceCodesModalProps> = ({
  close,
  open,
  loading,
  onSubmit,
  services,
}) => {
  const { errors, handleSubmit, register, control } =
    useNewServiceCodesModalController();

  const classes = useGlobalStyles();
  const styles = useStyles();

  return (
    <Dialog open={open}>
      <Box className={styles.container}>
        <Box>
          <Typography className={styles.title}>
            <b>Adicionar novos códigos de acesso</b>
          </Typography>
        </Box>

        <Grid item xs={12}>
          <Controller
            name="serviceId"
            control={control}
            as={({ value, onChange }) => {
              return (
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="service-label-id">
                    Selecione o serviço
                  </InputLabel>
                  <Select
                    labelId="service-label-id"
                    id="service-id"
                    name="serviceId"
                    label="Selecione o serviço"
                    value={value}
                    onChange={onChange}
                    error={!!errors.serviceId}
                  >
                    {!services.length ? (
                      <MenuItem value="">
                        Não existe nenhuma serviço cadastrado
                      </MenuItem>
                    ) : null}
                    {services.map(service => (
                      <MenuItem
                        key={service.id}
                        value={service.id}
                        title={service.name}
                      >
                        {service.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.serviceId ? (
                    <FormHelperText style={{ color: error }}>
                      {errors.serviceId ? errors.serviceId?.message : null}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              );
            }}
          />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Controller
            name="codesQuantity"
            control={control}
            as={({ value, onChange }) => (
              <TextField
                variant="outlined"
                label="Quantidade de códigos"
                id="codesQuantity"
                name="codesQuantity"
                value={value}
                onChange={onChange}
                error={!!errors.codesQuantity}
                helperText={
                  errors.codesQuantity ? errors.codesQuantity?.message : null
                }
                inputRef={register}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={3}
          className={classes.screenButtonGrid}
        >
          <Grid item>
            <Button
              onClick={() => close()}
              color="primary"
              className={classes.textButton}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <SpinnerButton
              text="Adicionar"
              className={classes.newDependentButton}
              action={handleSubmit(onSubmit)}
              localLoading={loading}
            />
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};
