export const isString = (value: unknown): value is string => {
  return typeof value === 'string';
};

export const isNumber = (value: unknown): value is number => {
  return typeof value === 'number';
};

export const isObject = (value: unknown): value is Object => {
  const isObject = typeof value === 'object';
  const isArray = Array.isArray(value as Object);

  return isObject && !isArray;
};
