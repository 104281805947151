import { yupResolver } from '@hookform/resolvers/yup';
import { StatusCodes } from 'http-status-codes';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import TuitionService from 'services/tuitionService';
import useGlobalStyles from 'styles';
import { useStyles } from './styles';
import { IModalCreateSubmit } from './types';
import { generateListOfYears, validationSchema } from './utils';

export const useSchoolClassCreateDetailsController = (
  schoolClassID: string,
  close: () => void,
  afterCreate: () => Promise<void> | void,
) => {
  const classes = useGlobalStyles();
  const styles = useStyles();
  const { handleSubmit, errors, setError, register, control } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onSubmit',
  });
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    Object.keys(errors).map(errorKey => {
      setError(errorKey, errors[errorKey]);
    });
  }, [errors, setError]);

  async function onSubmit(data: IModalCreateSubmit) {
    if (data.value <= 0) {
      setError('value', { message: 'Preencha o valor' });
      return;
    }

    const response = await TuitionService.createDetail({
      schoolClassId: Number(schoolClassID),
      referenceYear: data.year,
      vacancies: data.vacancies,
      value: data.value,
    });
    if (response.status === StatusCodes.CREATED) {
      setSuccessModalOpen(true);
      await afterCreate();
      close();
    } else {
      setAlertMessage(response.data.message);
    }
  }
  const handleCloseAlertMessage = () => {
    setAlertMessage('');
  };
  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  const YEARS = useMemo(() => generateListOfYears(4), []);

  return {
    classes,
    styles,
    successModalOpen,
    errors,
    control,
    onSubmit,
    handleSubmit,
    register,
    handleCloseSuccessModal,
    alertMessage,
    YEARS,
    handleCloseAlertMessage,
  };
};
