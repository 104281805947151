import { TableCell, TableRow } from '@material-ui/core';
import { useStyles } from 'pages/Admin/Contracts/ContractsTables/styles';
import { useCallback } from 'react';
import { getObjectProperty } from './getObjectProperty';
import { TableColumnConfig, TableRowProps } from './types';

export function MyTableRow<T>({ data, tableConfig }: TableRowProps<T>) {
  const styles = useStyles();

  const getRowValue = useCallback(
    (columnConfig: TableColumnConfig<T>) => {
      let dataValue = getObjectProperty(
        data,
        columnConfig.getter as string,
        columnConfig.defaultValue,
      );

      if (columnConfig.onFormat && dataValue !== columnConfig.defaultValue) {
        dataValue = columnConfig.onFormat(dataValue, data);
      }

      if (columnConfig.customRender) {
        return columnConfig.customRender(dataValue, data);
      }

      return dataValue;
    },
    [data],
  );

  return (
    <TableRow hover className={styles.row}>
      {tableConfig.map((columnConfig, idx) => (
        <TableCell
          className={styles.tableCell}
          key={`${columnConfig?.getter as string}-${idx}`}
          align={columnConfig?.align}
        >
          {getRowValue(columnConfig)}
        </TableCell>
      ))}
    </TableRow>
  );
}
