import { CreateQueryParams } from '@nestjsx/crud-request';
import { getWithFilter } from './apiService';

export default class PedagogicPerformanceReportService {
  static async queryPerformanceReport(query: CreateQueryParams) {
    try {
      return await getWithFilter(`/pedagogic-performance-reports`, query);
    } catch (error: any) {
      return error.response;
    }
  }
}
