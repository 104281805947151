import { Box, Grid } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import AlertCard from 'components/AlertCard';
import { CustomButton } from 'components/CustomButton';
import Dropdown from 'components/HeaderBar/Dropdown';
import Modal from 'components/Modal';
import PageFooter from 'components/PageFooter';
import PageHeader from 'components/PageHeader';
import Permission from 'components/Permission';
import React from 'react';
import useGlobalStyles from 'styles';
import { USER_ROLES } from 'types/enums';
import { REQUIRED_ROLES_ADMIN } from 'utils/isAdmin';
import FilteredInvoicesTable from '../Invoices/FilteredInvoicesTable';
import PaymentSummary from './components/PaymentSummary';
import SubscriptionTable from './components/SubscriptionTable';
import { useStyles } from './styles';
import { useAdmPaymentDetailsController } from './usePaymentDetailsController';
import {
  MODAL_BTN,
  MODAL_SUBTITLE,
  MODAL_TITLE,
  SYNC_MODAL_BTN,
  SYNC_MODAL_TITLE,
  paymentSummary,
} from './utils';

const AdmPaymentDetails: React.FC = () => {
  const classes = useGlobalStyles();
  const localClasses = useStyles();

  const {
    // Alert
    alertMessage,
    alert,
    closeAlert,
    alertColor,

    // Cancel payment modal
    modal,
    refund,
    cancelPayment,
    closeModal,

    // Sync with vindi modal
    syncModal,
    syncWithVindi,
    closeSyncModal,

    // Dropdown
    isAdmin,
    menuItems,

    // Page data
    loading,
    payment,
    subscriptions,
    loadPayment,
    getCancelationText,
    handleCreateManualInvoiceOnclick,
    user,
  } = useAdmPaymentDetailsController();

  if (!payment) return null;

  return (
    <Grid
      className={classes.listScreenGrid}
      container
      direction="column"
      alignItems="flex-start"
    >
      <AlertCard
        message={alertMessage}
        open={alert}
        close={closeAlert}
        severity={alertColor}
      />
      {/* FIXME: Change to use CustomDialog */}
      <Modal
        icon={<HelpOutline color="primary" className={classes.dialogIcon} />}
        open={modal}
        text={MODAL_TITLE(refund, 'pagamento')}
        subtext={MODAL_SUBTITLE(refund)}
        btnText={MODAL_BTN(refund)}
        btnAction={cancelPayment}
        backBtnText="Voltar"
        backBtnAction={closeModal}
      />
      {/* FIXME: Change to use CustomDialog */}
      <Modal
        icon={<HelpOutline color="primary" className={classes.dialogIcon} />}
        open={syncModal}
        text={SYNC_MODAL_TITLE}
        subtext="Esta ação irá buscar os dados mais atualizados com a integração de pagamento, Vindi ou Itau."
        btnText={SYNC_MODAL_BTN}
        btnAction={syncWithVindi}
        backBtnText="Voltar"
        backBtnAction={closeSyncModal}
      />
      <Grid container item direction="column">
        <PageHeader
          title="Detalhe financeiro"
          subtitle="Veja o detalhamento do pagamento selecionado"
          button={
            isAdmin && payment?.status ? (
              <Dropdown dark text="Ações" menuItems={menuItems} />
            ) : null
          }
        />

        {payment.canceledAt ? (
          <Alert
            variant="filled"
            severity="error"
            className={localClasses.mb10}
          >
            {getCancelationText(payment)}
          </Alert>
        ) : null}

        <PaymentSummary
          items={paymentSummary(payment)}
          payment={payment}
          isAdmin={isAdmin}
          canceledBy={payment.canceledBy?.admin}
        />

        {subscriptions.map(subscription => (
          <SubscriptionTable
            loading={loading}
            paymentMethodCode={payment.paymentMethodCode}
            key={subscription.id}
            isAdmin={isAdmin}
            subscription={subscription}
            loadPayment={loadPayment}
          />
        ))}

        <Box mt="30px" />

        <Permission roles={REQUIRED_ROLES_ADMIN} user={user}>
          <FilteredInvoicesTable
            tableHeader={{
              rawTitle: 'Notas fiscais',
              rawSubtitle:
                'Aqui estão as notas fiscais emitidas para este pagamento.',
              button: (
                <Permission
                  roles={[USER_ROLES.ADMIN, USER_ROLES.TREASURY]}
                  user={user}
                >
                  <CustomButton
                    variant="primary"
                    textVariant="bold"
                    onClick={handleCreateManualInvoiceOnclick}
                  >
                    Emitir nota fiscal avulsa
                  </CustomButton>
                </Permission>
              ),
            }}
            paymentId={payment.id}
            renderFilters={false}
          />
        </Permission>

        <PageFooter />
      </Grid>
    </Grid>
  );
};

export default AdmPaymentDetails;
