import { CreateQueryParams } from '@nestjsx/crud-request';
import {
  api,
  getAxiosResponseMessageErrorOrDefault,
  getWithFilter,
} from 'services/apiService';
import { ContractInfo } from 'types/contractInfo';
import { DOCUMENT_TYPE } from 'types/enums';
import { MarketplaceContractInfo } from 'types/marketplaceContractInfo';
import { Contract } from 'types/payment';

export default class ContractService {
  static async generateNewContractForced(paymentId: number) {
    try {
      return await api.post(
        `/payments/${paymentId}/generate-contract?force=true`,
      );
    } catch (error: any) {
      return error.response;
    }
  }

  static async contractByPaymentId(paymentId: number) {
    try {
      return await api.get(`contracts/payment/${paymentId}`);
    } catch (error: any) {
      return error.response;
    }
  }

  static async marketplaceContractByPaymentId(paymentId: number): Promise<{
    status: number;
    data: MarketplaceContractInfo;
  }> {
    try {
      return await api.get(
        `contracts/payment/${paymentId}/services-marketplace`,
      );
    } catch (error: any) {
      return error.response;
    }
  }

  static async signContract(id: number) {
    try {
      return await api.put<ContractInfo>(`contracts/${id}/sign`);
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }

  static async signContractAndChargeDraftPayment(id: number) {
    try {
      return await api.put<ContractInfo>(
        `contracts/${id}/sign/charge-draft-registration-payment`,
      );
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }

  static async sign(id: number, file: File, name: string) {
    const data = new FormData();
    data.append('name', name);
    data.append('file', file);

    try {
      return await api.put(`/payments/${id}/sign`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error: any) {
      return error.response;
    }
  }

  static async uploadPdf(regId: number, pdf: File) {
    const data = new FormData();
    data.append('name', 'documento');
    data.append('type', DOCUMENT_TYPE.HISTORICO_ESCOLAR);
    data.append('file', pdf);

    try {
      return await api.put(`registrations/${regId}/file`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error: any) {
      return error.response;
    }
  }

  static async filterContracts(query?: CreateQueryParams) {
    try {
      return await getWithFilter<Contract>('/contracts', query);
    } catch (err: any) {
      return err.response;
    }
  }
}
