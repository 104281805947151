import React from 'react';

import { Grid, Paper, Typography } from '@material-ui/core';
import BlankPic from 'assets/blankProfileImg.png';
import { useDependentCompactBoxController } from './controller';
import { IBoxProperties } from './types';

export const DependentCompactBox = ({
  dependentName,
  registration,
}: IBoxProperties) => {
  const { styles, classes, savedPicture, schoolGrade } =
    useDependentCompactBoxController({
      registration,
    });

  return (
    <Paper className={styles.paper}>
      <img
        className={styles.img}
        src={savedPicture ? savedPicture?.path : BlankPic}
        alt="Foto do aluno"
      />
      <Grid
        container
        className={styles.box}
        direction="column"
        justifyContent="flex-end"
      >
        <Grid item>
          <Typography className={classes.dependentPaperName}>
            <b>{dependentName}</b>
          </Typography>
          <Typography>{schoolGrade}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
