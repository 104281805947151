import { CreateQueryParams } from '@nestjsx/crud-request';
import { api, getWithFilter } from 'services/apiService';

export interface IGetDependentServicesParams {
  schoolClass: string;
  dependentId: number;
  serviceTypeId?: number;
  serviceName?: string;
}

export interface ICheckServiceClassConflicts {
  serviceClassesIds?: string;
  registrationId: number;
}

const BASE_PATH = 'service-classes';

// TODO: types
export default class ServiceClassesService {
  static async getServiceTypes() {
    try {
      return await api.get('service-types');
    } catch (error: any) {
      return error.response;
    }
  }

  static async getPublicationStatus() {
    try {
      return await api.get('service-publication-status');
    } catch (error: any) {
      return error.response;
    }
  }

  static async getServiceCategories() {
    try {
      return await api.get(`service-categories`);
    } catch (error: any) {
      return error.response;
    }
  }

  static async getServiceRecurrences() {
    try {
      return await api.get(`/service-recurrences`);
    } catch (error: any) {
      return error.response;
    }
  }

  static async createServiceClass(dataToCreate: any) {
    try {
      return await api.post(`/service-classes`, dataToCreate);
    } catch (error: any) {
      return error.response;
    }
  }

  static async updateServiceClass(classId: string, dataToUpdate: any) {
    try {
      return await api.patch(`/service-classes/${classId}`, dataToUpdate);
    } catch (error: any) {
      return error.response;
    }
  }

  static async getServiceClasses(query?: CreateQueryParams) {
    try {
      return await getWithFilter(`service-classes`, query);
    } catch (error: any) {
      return error.response;
    }
  }

  static async getServiceClass(classId: string, query?: CreateQueryParams) {
    try {
      return await getWithFilter(`service-classes/${classId}`, query);
    } catch (error: any) {
      return error.response;
    }
  }

  static async getServiceClassesMaximumInstallments(
    serviceClassIds: number[],
  ): Promise<{ status: number; data: number[] }> {
    try {
      return await api.post(`/service-classes/max-installments`, {
        serviceClassIds,
      });
    } catch (error: any) {
      return error.response;
    }
  }

  static async checkServiceClassConflicts(
    serviceClassId: number,
    data: ICheckServiceClassConflicts,
  ) {
    try {
      return await api.get(
        `${BASE_PATH}/${serviceClassId}/check-classes-conflict`,
        { params: { ...data } },
      );
    } catch (error: any) {
      return error;
    }
  }
}
