import {
  formatForecastMonth,
  formatMonth,
} from 'components/FinancialReportTable/utils';
import { Bill } from 'types/bill';
import { MonthlyReport, YearlyReport } from 'types/financialReport';
import { formatBRL, formatDate } from 'utils';
import { TABLE_HEADERS } from 'utils/tableHeaders';

export const billHeaders = [
  TABLE_HEADERS.MONTH_SLASH_YEAR,
  TABLE_HEADERS.REGISTRATION,
  TABLE_HEADERS.SCHOOL_GRADE,
  TABLE_HEADERS.STATUS,
  TABLE_HEADERS.VALUE,
];

export const billTableValues = (bill: Bill) => [
  formatDate({
    date: bill.paymentDateForecast,
    inputFormat: 'yyyy-MM-dd',
    outputFormat: 'MM/yyyy',
  }),
  bill.payment.registration?.number || '---',
  bill.payment.referenceGrade,
  bill.status,
  formatBRL(Number(bill.value)),
];

export const monthlyReportHeaders = [
  TABLE_HEADERS.YEAR,
  TABLE_HEADERS.MONTH,
  TABLE_HEADERS.RECEIPT,
  TABLE_HEADERS.VALUE,
];

export const monthlyReportValues = (item: MonthlyReport) => [
  item.ano,
  formatMonth(Number(item.mes)),
  formatForecastMonth(Number(item.mes) + 1, item.ano),
  formatBRL(Number(item.total)),
];

export const yearlyReportHeaders = [TABLE_HEADERS.YEAR, TABLE_HEADERS.VALUE];

export const yearlyReportValues = (item: YearlyReport) => [
  item.ano,
  formatBRL(Number(item.total)),
];
