import { makeStyles } from '@material-ui/core';
import { darkGray, lightBoxShadow, mediumGray } from 'styles/theme';

export const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: 34,
    height: 271,
    position: 'relative',
    padding: 35,
    boxShadow: lightBoxShadow,
    overflow: 'hidden',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      height: 351,
    },
  },
  container: {
    height: 271,
    [theme.breakpoints.down('md')]: {
      height: 351,
    },
  },
  header: {
    fontSize: 26,
    color: darkGray,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
  },
  text: {
    fontSize: 16,
    width: '60%',
    color: mediumGray,
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },
  },
  dateText: {
    fontSize: 12,
  },
  img: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
}));
