import { makeStyles } from '@material-ui/core';
import { lightBoxShadow } from 'styles/theme';

export const useStyles = makeStyles(theme => ({
  paper: {
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 22,
    boxShadow: lightBoxShadow,
    overflowX: 'auto',
  },
  table: {
    minWidth: 600,
  },
}));
