import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import logoAdmin from 'assets/logoSMLight.png';
import logo from 'assets/logoNoBG.png';
import ExitToApp from '@material-ui/icons/ExitToApp';
import PersonOutline from '@material-ui/icons/PersonOutline';
import Menu from '@material-ui/icons/Menu';
import ShoppingCartOutlined from '@material-ui/icons/ShoppingCartOutlined';
import Dropdown from 'components/HeaderBar/Dropdown';
import { CustomIcon } from 'components/CustomIcon';
import Notifications from '../Notifications';
import { HeaderProperties } from './types';
import { useHeaderBarController } from './controller';

export const HeaderBar = ({
  isAdmin,
  isMobile,
  toggleMenu,
  menuOpen,
}: HeaderProperties) => {
  const {
    styles,
    user,
    logOut,
    goToProfile,
    totalItemsCart,
    isMarketplace,
    canGoBack,
    goBack,
    goToCart,
  } = useHeaderBarController(isAdmin);

  return (
    <AppBar color="transparent" position={isMobile ? 'fixed' : 'absolute'}>
      <Toolbar disableGutters className={styles.toolbar}>
        {isMobile ? (
          <img
            src={isAdmin ? logoAdmin : logo}
            alt="logo"
            className={styles.logo}
          />
        ) : null}
        <Grid
          container
          alignItems="center"
          justifyContent={
            !isMobile && isMarketplace ? 'space-between' : 'flex-end'
          }
        >
          {!canGoBack || isMobile ? null : (
            <Box
              className={styles.pageGoBack}
              marginTop={2}
              marginLeft="220px"
              display="flex"
              alignItems="center"
              onClick={goBack}
            >
              <CustomIcon name="textAndChevArrowBack" />
            </Box>
          )}

          <Grid
            container
            item
            justifyContent="flex-end"
            spacing={1}
            className={styles.itemsContainer}
          >
            <Grid item>
              {!isAdmin ? (
                <Button className={styles.button} onClick={goToCart}>
                  <Badge color="secondary" badgeContent={totalItemsCart}>
                    <ShoppingCartOutlined color="inherit" />
                  </Badge>
                </Button>
              ) : null}
            </Grid>

            {!isMobile ? (
              <Grid item>
                {user && user?.notifications.length ? (
                  <Notifications
                    isAdmin={isAdmin}
                    notifications={user.notifications}
                  />
                ) : null}
              </Grid>
            ) : null}

            {!isMobile ? (
              <Grid item>
                <Dropdown
                  isAdmin={isAdmin}
                  isMobile={isMobile}
                  text={`Olá, ${user?.name.replace(/ .*/, '')}`}
                  menuItems={[
                    {
                      icon: <PersonOutline />,
                      action: goToProfile,
                      title: 'Perfil',
                    },
                    {
                      icon: <ExitToApp />,
                      action: logOut,
                      title: 'Sair',
                    },
                  ]}
                />
              </Grid>
            ) : null}
            {isMobile ? (
              <Grid item>
                <IconButton
                  onClick={toggleMenu(!menuOpen)}
                  color={isAdmin ? 'inherit' : 'primary'}
                  size="small"
                >
                  <Menu className={styles.adminText} />
                </IconButton>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
