import { api } from 'services/apiService';

export default class NotificationService {
  static async markAsRead(id: number) {
    try {
      return await api.patch(`notifications/${id}`, { isRead: true });
    } catch (error: any) {
      return error.response;
    }
  }

  static async markAllAsRead(id: number) {
    try {
      return await api.patch(`notifications/mark-all-as-read/${id}`);
    } catch (error: any) {
      return error.response;
    }
  }
}
