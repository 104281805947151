import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import React from 'react';
import { Typography } from '@material-ui/core';
import { CheckBoxInputProps } from './types';
import { useStyles } from './styles';

const CheckBoxInput: React.FC<CheckBoxInputProps> = ({
  isChecked,
  name,
  onChange,
  children,
  label,
}) => {
  const styles = useStyles();

  return (
    <FormControlLabel
      name={name}
      control={
        <Checkbox
          name={name}
          checked={isChecked}
          value={isChecked}
          onChange={onChange}
          color="secondary"
        />
      }
      label={
        <Typography className={styles.checkboxLabel}>
          {label || children}
        </Typography>
      }
    />
  );
};

export default CheckBoxInput;
