import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Paper, TextField, Typography } from '@material-ui/core';
import AddressForm from 'components/AddressForm';
import CustomDialog from 'components/CustomDialog';
import { CustomDialogRef } from 'components/CustomDialog/types';
import DependentForm from 'components/DependentForm';
import { ControllerSelectInput } from 'components/Forms/ControllerSelectInput/ControllerSelectInput';
import { SelectInput } from 'components/Forms/SelectInput/SelectInput';
import PageFooter from 'components/PageFooter';
import { ResponsiveInputGridItem } from 'components/ResponsiveInputGridItem';
import Scaffold from 'components/Scaffold';
import SpinnerButton from 'components/SpinnerButton';
import { useSearchCEP } from 'hooks/searchCEP';
import { omit } from 'lodash';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { CLIENT_ROUTE } from 'routes/consts';
import RegistrationService from 'services/registrationService';
import { SchoolClassDetailsService } from 'services/schoolClassDetailsService';
import { useAppStore } from 'store';
import useGlobalStyles from 'styles';
import { NEW_REGISTRATION_SUBTEXT, NEW_REGISTRATION_TEXT } from 'texts';
import { Address } from 'types/address';
import { DependentDocuments } from 'types/dependentDocuments';
import { SchoolClassDetails } from 'types/schoolClassDetails';
import { trimSpecialCharacters } from 'utils';
import { StateData } from './types';
import { allToUpperCase, validationSchema } from './utils';

// FIXME: Componente fora do padrão
const NewRegistration: React.FC = () => {
  const classes = useGlobalStyles();
  const history = useHistory();
  const showAlert = useAppStore(state => state.alert.showAlert);
  const {
    state: { isAdmin, userDocuments, userAddress, userId, guardianId },
  } = useLocation<StateData>();

  const successDialogRef = useRef<CustomDialogRef>(null);

  const [dependentDocuments, setDependentDocuments] =
    useState<DependentDocuments>({} as DependentDocuments);
  const [guardianAddress, setGuardianAddress] = useState<Address>(
    {} as Address,
  );
  const [hasGuardianDocuments, setHasGuardianDocuments] = useState(false);
  const [hasGuardianAddress, setHasGuardianAddress] = useState(false);

  // registration data
  const [availableYears, setAvailableYears] = useState<
    Pick<SchoolClassDetails, 'referenceYear'>[]
  >([]);
  const [selectedYear, setSelectedYear] = useState<number>();
  const [availableSchoolClasses, setAvailableSchoolClasses] = useState<
    SchoolClassDetails[]
  >([]);

  const { handleSubmit, errors, control, register, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onBlur',
  });

  const { address, debouncedSearchCEP, cepErrorCount } = useSearchCEP();

  useEffect(() => {
    register('hasGuardianDocuments');
    setValue('hasGuardianDocuments', hasGuardianDocuments);
    register('hasGuardianAddress');
    setValue('hasGuardianAddress', hasGuardianAddress);
  }, [hasGuardianDocuments, hasGuardianAddress, register, setValue]);

  useEffect(() => {
    const getAvailableSchoolClassDetails = async () => {
      try {
        if (!selectedYear) {
          setAvailableSchoolClasses([]);
          setValue('schoolClassDetailsId', undefined);
          return;
        }

        const response = await SchoolClassDetailsService.getMany({
          limit: 20,
          sort: {
            field: 'schoolClassId',
            order: 'ASC',
          },
          search: {
            $and: [
              {
                referenceYear: selectedYear,
                vindiProductId: {
                  $notnull: true,
                },
                vindiPlanId: {
                  $notnull: true,
                },
              },
            ],
          },
        });
        setAvailableSchoolClasses(response.data.data);
      } catch (error: any) {
        showAlert({
          message: error?.message || 'Erro ao buscar turmas disponíveis',
          severity: 'error',
        });
      }
    };

    getAvailableSchoolClassDetails();
  }, [selectedYear, setValue, showAlert]);

  useEffect(() => {
    const getAvailableRegistrationYears = async () => {
      try {
        const currentYear = new Date().getFullYear();
        const response = await SchoolClassDetailsService.getAvailableYears(
          currentYear,
        );
        setAvailableYears(response.data);
      } catch (error: any) {
        showAlert({
          message: error?.message || 'Erro ao buscar anos disponíveis',
          severity: 'error',
        });
      }
    };
    getAvailableRegistrationYears();
  }, [showAlert]);

  useEffect(() => {
    register('editing');
    setValue('editing', false);
  }, [register, setValue]);

  async function onSubmit(data: any) {
    if (data.address) {
      data.address = { ...data.address, ...address };
    }
    if (hasGuardianAddress) {
      data.address = guardianAddress;
    }
    // if (hasGuardianDocuments) {
    //   dependentDocuments.cpf = trimSpecialCharacters(
    //     dependentDocuments.cpf,
    //   ) as string;
    //   data = { ...data, ...dependentDocuments };
    // }
    data = allToUpperCase(data);
    const dependent = omit(data, ['number', 'schoolClassDetailsId']);

    try {
      await RegistrationService.newRegistration({
        dependent: {
          ...dependent,
          cpf: trimSpecialCharacters(dependent.cpf) as string,
          hasGuardianDocuments: false,
          hasGuardianAddress,
          createdByUserId: userId,
        },
        financialGuardianId: guardianId,
        number: data.number,
        schoolClassDetailsId: data.schoolClassDetailsId,
      });
      successDialogRef.current?.openDialog();
    } catch (error: any) {
      showAlert({
        message: error?.message || 'Erro ao realizar matrícula',
        severity: 'error',
      });
    }
  }

  const handleCheckHasGuardianDocuments = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = e.target;
    if (checked) {
      setDependentDocuments(userDocuments);
    } else {
      setDependentDocuments({
        cpf: '',
        rg: {
          rgNumber: '',
          emitter: '',
        },
      });
    }
    setHasGuardianDocuments(checked);
  };

  const handleCheckHasGuardianAddress = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setGuardianAddress(
      checked
        ? userAddress
        : {
            cep: '',
            number: '',
            complement: '',
            street: '',
            district: '',
            city: '',
            state: '',
          },
    );
    setHasGuardianAddress(checked);
  };

  const handleSelectYear = (e: ChangeEvent<{ value: number }>) => {
    setSelectedYear(e.target.value);
    setValue('schoolClassDetailsId', undefined);
  };

  const handleGoToDashboard = () => {
    history.push(CLIENT_ROUTE.HOME);
  };

  return (
    <Scaffold
      rawTitle={
        isAdmin ? (
          <>
            Adicionar um <b>novo aluno</b>
          </>
        ) : (
          <>
            Solicitação <b>de entrevista</b>
          </>
        )
      }
      rawSubtitle="Informações básicas do aluno"
    >
      {/* FIXME: se isso voltar a ser necessário, criar usando o componente CustomDialog, isso foi removido pelo Manogel em 29junho2023 por estar mal feito (olhar histórico no git) */}
      {/* <Modal
        icon={<PriorityHigh color="primary" className={classes.dialogIcon} />}
        open={duplicationModal}
        text="Aluno existente."
        subtext="Já existe um aluno cadastrado com o nome e CPF informados. Deseja duplicar o cadastro?"
        btnText="Sim, duplicar"
        btnAction={handleConfirmDuplication}
        backBtnText="Não, voltar"
        backBtnAction={handleCloseDuplicationModal}
      /> */}

      <CustomDialog
        ref={successDialogRef}
        rawTitle={NEW_REGISTRATION_TEXT(isAdmin)}
        rawSubtitle={NEW_REGISTRATION_SUBTEXT(isAdmin)}
        primaryButton={{
          text: 'Voltar para o painel principal',
          onClick: handleGoToDashboard,
        }}
        icon={{
          name: 'checkCircle',
        }}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper elevation={0} className={classes.screenPaper}>
          <Grid item container direction="column" alignItems="flex-start">
            <Typography className={classes.screenText3}>
              <b>Dados pessoais</b>
            </Typography>
            <DependentForm
              documents={dependentDocuments}
              hasGuardianDocuments={hasGuardianDocuments}
              setHasGuardianDocuments={handleCheckHasGuardianDocuments}
              register={register}
              control={control}
              errors={errors}
              editing
            />

            <Typography className={classes.screenText3}>
              <b>Endereço</b>
            </Typography>
            <AddressForm
              searchAddress={address}
              guardianAddress={guardianAddress}
              cepSearch={debouncedSearchCEP}
              cepErrorCount={cepErrorCount}
              hasGuardianAddress={hasGuardianAddress}
              setHasGuardianAddress={handleCheckHasGuardianAddress}
              register={register}
              control={control}
              errors={errors}
              editing
            />

            <Typography className={classes.screenText3}>
              <b>Informações para matricula</b>
            </Typography>

            <Grid item container direction="row" spacing={3}>
              <ResponsiveInputGridItem>
                <SelectInput
                  name="referenceYear"
                  label="Ano letivo"
                  helperText="Ano letivo para o qual o dependente será matriculado"
                  value={selectedYear}
                  // TODO: mover pro escopo da função
                  options={availableYears.map(({ referenceYear }) => ({
                    id: referenceYear,
                    label: String(referenceYear),
                  }))}
                  onChange={handleSelectYear}
                />
              </ResponsiveInputGridItem>
              <ResponsiveInputGridItem>
                <ControllerSelectInput
                  name="schoolClassDetailsId"
                  label="Série"
                  helperText="Série na qual o dependente será matriculado"
                  disabled={!selectedYear}
                  // TODO: mover pro escopo da função
                  options={availableSchoolClasses.map(
                    ({ id, schoolClassName }) => ({
                      id,
                      label: schoolClassName,
                    }),
                  )}
                  register={register}
                  control={control}
                  errors={errors}
                />
              </ResponsiveInputGridItem>
              {isAdmin ? (
                <ResponsiveInputGridItem>
                  <TextField
                    label="Número de matrícula (caso existente)"
                    variant="outlined"
                    id="number"
                    name="number"
                    error={Boolean(errors.number)}
                    helperText={errors.number ? errors.number.message : null}
                    inputRef={register}
                    className={classes.screenInputField}
                  />
                </ResponsiveInputGridItem>
              ) : null}
            </Grid>
          </Grid>
        </Paper>
        <PageFooter
          returnPath="/registrations"
          nextButton={
            <SpinnerButton
              text="Próximo"
              className={classes.newDependentButton}
            />
          }
        />
      </form>
    </Scaffold>
  );
};

export default NewRegistration;
