import { Box, Divider, Grid, Paper, Typography } from '@material-ui/core';
import DependentDataBox from 'components/DependentDataBox';
import { useMemo } from 'react';
import useGlobalStyles from 'styles';
import { PAYMENT_METHODS } from 'types/enums';
import { Registration } from 'types/registration';
import { formatBRL } from 'utils';
import { PaymentCardOption, PaymentSelectionData } from '../../types';
import { formatCard } from '../../utils';
import PaymentType from './PaymentType';
import PaymentSummary from './paymentSummary';
import { useStyles } from './styles';
import { translatePaymentMethod } from './utils';

type ConfirmPaymentProps = {
  paymentSelection: PaymentSelectionData;
  registration: Registration;
};

const ConfirmPayment = ({
  paymentSelection,
  registration,
}: ConfirmPaymentProps) => {
  const classes = useGlobalStyles();
  const styles = useStyles();

  const isCardPayment = useMemo(
    () => paymentSelection?.method === PAYMENT_METHODS.CARD,
    [paymentSelection?.method],
  );

  const paymentRowItem = (item: PaymentCardOption) => {
    if (paymentSelection?.hasFreeCoupon) return 'Cupom de desconto integral';
    return isCardPayment
      ? item.card?.alias
      : translatePaymentMethod(paymentSelection?.method || '');
  };

  return (
    <>
      <Paper elevation={0} className={classes.overlayConfirmPaper}>
        <Grid container alignItems="center">
          <Grid item className={styles.item}>
            <DependentDataBox
              dependentName={registration?.dependent.name}
              registration={registration}
              light
              overlayed
            />
          </Grid>
          <Grid item className={styles.item} />
          <Grid item>
            <Typography className={classes.paymentScreenTitle1}>
              FALTA POUCO.
            </Typography>
            <Typography className={classes.paymentScreenTitle2}>
              Vamos ficar felizes em ter o(a) {registration?.dependent.name}{' '}
              aqui conosco!
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={0} className={classes.listScreenPaper}>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={3}
          className={classes.screenInputGrid}
        >
          <PaymentSummary
            registration={registration}
            installments={paymentSelection.cardOptions[0].installments}
            paymentMethod={paymentSelection.method}
            couponName={paymentSelection.coupon?.name}
          />

          <Divider className={classes.paymentScreenDivider1} />
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Box>
                <PaymentType
                  paymentMethod={paymentSelection.method}
                  rowsLength={paymentSelection.cardOptions.length}
                />
                {paymentSelection.cardOptions.map(item => (
                  <Grid
                    key={item.card?.id}
                    container
                    justifyContent="space-between"
                    className={styles.cardGrid}
                  >
                    <Grid item>
                      <Typography className={classes.confirmScreenText1}>
                        {paymentRowItem(item)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.confirmScreenText1}>
                        {formatCard(item.card?.lastFour)}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <Typography className={classes.confirmScreenText1}>
                  <b>Valor total</b>
                </Typography>
                <Typography className={classes.confirmScreenText2}>
                  <b>{formatBRL(paymentSelection.originalValueWithDiscount)}</b>
                </Typography>
                <Typography className={classes.confirmScreenText3}>
                  <b>
                    {paymentSelection?.cardOptions?.[0].installments < 2
                      ? 'à vista'
                      : `parcelado em (${paymentSelection?.cardOptions[0].installments}x)`}
                  </b>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {isCardPayment ? (
          <>
            <Divider className={classes.divider1} />

            <Typography>
              <b>
                Será feita uma cobrança no valor de R$ 1,25 para validar cada
                cartão. Após confirmação, o valor será estornado.
              </b>
            </Typography>
          </>
        ) : null}
        {!isCardPayment &&
        paymentSelection.method === PAYMENT_METHODS.BANK_SLIP ? (
          <>
            <Divider className={classes.divider1} />

            <Typography>
              <b>
                Após vencimento do boleto será cobrado multa de 2% e juros de
                mora de 1% ao mês.
              </b>
            </Typography>
          </>
        ) : null}
        {paymentSelection.method === PAYMENT_METHODS.PIX ? (
          <>
            <Divider className={classes.divider1} />

            <Typography>
              <b>
                O pagamento via PIX pode demorar uma média de 30 minutos para
                ser aprovado e atualizado em nosso sistema.
              </b>
            </Typography>
          </>
        ) : null}
      </Paper>
    </>
  );
};

export default ConfirmPayment;
