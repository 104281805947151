import { ServiceCard } from '../ServiceCard';
import { CardsContainer } from './styles';
import { ServiceCardsContainerProps } from './types';

export const ServiceCardsContainer = ({
  displayMode = 'row',
  emptyDescription,
  services,
  ...serviceCardProps
}: ServiceCardsContainerProps) => {
  return (
    <CardsContainer
      horizontal={displayMode === 'row'}
      vertical={false}
      displayMode={displayMode}
    >
      {!services.length ? (
        <span className="empty-states">
          {emptyDescription || 'Nenhum serviço a ser exibido.'}
        </span>
      ) : (
        services.map(service => {
          return (
            <ServiceCard
              key={service.id}
              service={service}
              serviceType={service.serviceType}
              {...serviceCardProps}
            />
          );
        })
      )}
    </CardsContainer>
  );
};
