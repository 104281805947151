import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, TextField, Typography } from '@material-ui/core';
import { ThumbUpOutlined } from '@material-ui/icons';
import Modal from 'components/Modal';
import { PublicPageContainer } from 'components/PublicPageContainer';
import { PublicPageHeader } from 'components/PublicPageHeader';
import SpinnerButton from 'components/SpinnerButton';
import { StatusCodes } from 'http-status-codes';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import AuthService from 'services/authService';
import useGlobalStyles from 'styles';
import { WEAK_PASSWORD } from 'texts';
import { InputRef } from 'types/FormTypes';
import { Container, useStyles } from '../Login/styles';
import { RouteToken } from '../ValidateUser/types';
import { validationSchema } from './utils';

const NewPassword: React.FC = () => {
  const classes = useGlobalStyles();
  const styles = useStyles();
  const history = useHistory();
  const { params } = useRouteMatch<RouteToken>();
  const [modal, setModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const password = useRef<InputRef>();
  const repeatPassword = useRef<InputRef>();

  const { handleSubmit, errors, setError, register } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    async function verifyToken() {
      const { token } = params;
      try {
        await AuthService.validateToken(token);
        setErrorMessage(false);
      } catch (error: any) {
        setErrorMessage(true);
      }
    }
    verifyToken();
  }, [history, params]);

  function onSubmit(data: any) {
    newPassword(data);
  }

  async function newPassword(formData: any) {
    const { token } = params;

    const response = await AuthService.resetPasswordWithToken(
      token,
      formData.password,
    );
    if (response.status !== StatusCodes.CREATED) {
      setError('password', { message: 'Houve algum erro.' });
      setError('repeatPassword', { message: 'Houve algum erro.' });
    } else {
      setModal(true);
    }
  }

  function modalClose() {
    setModal(false);
    history.push('/login');
  }

  return (
    <>
      <Modal
        icon={
          <ThumbUpOutlined color="primary" className={classes.dialogIcon} />
        }
        open={modal}
        text="Sua senha foi alterada com sucesso!"
        btnText="Acessar conta"
        btnAction={() => modalClose()}
      />
      <PublicPageContainer>
        {errorMessage ? (
          <PublicPageHeader
            title={<b>Link inválido ou expirado</b>}
            subtitle={
              <Typography>
                O link utilizado é inválido ou está expirado.{' '}
                <Link to="/redefine-password">
                  Clique aqui para enviar outro e-mail.
                </Link>
              </Typography>
            }
          />
        ) : (
          <Container>
            <PublicPageHeader
              title={
                <>
                  <b>Definir</b> nova senha
                </>
              }
              subtitle="Defina sua nova senha de acesso ao Portal dos Pais JPSul."
            />
            <Grid item className={classes.publicScreenMargin1}>
              <TextField
                variant="outlined"
                label="Nova senha"
                id="password"
                name="password"
                type="password"
                inputProps={{ ref: password }}
                error={Boolean(errors.password)}
                helperText={
                  errors.password ? errors.password.message : WEAK_PASSWORD
                }
                inputRef={register}
                className={classes.publicScreenInput}
              />
            </Grid>
            <Grid item className={classes.publicScreenMargin1}>
              <TextField
                variant="outlined"
                label="Confirmar nova senha"
                id="repeatPassword"
                name="repeatPassword"
                type="password"
                inputProps={{ ref: repeatPassword }}
                error={Boolean(errors.repeatPassword)}
                helperText={
                  errors.repeatPassword ? errors.repeatPassword.message : null
                }
                inputRef={register}
                className={classes.publicScreenInput}
              />
            </Grid>
            <SpinnerButton
              text="Enviar"
              className={classes.loginButton}
              action={handleSubmit(onSubmit)}
            />
          </Container>
        )}
      </PublicPageContainer>
    </>
  );
};

export default NewPassword;
