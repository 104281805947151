import React from 'react';

import { Box, Grid } from '@material-ui/core';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { IServiceDescriptionProps } from './types';
import {
  Container,
  DescriptionResumeName,
  DescriptionResumeAvatar,
  DescriptionResumeText,
  DescriptionSpotsButton,
} from './styles';
import { useDescriptionController } from './controller';

export const Description = ({
  id,
  name,
  avatarUrl,
  description,
}: IServiceDescriptionProps) => {
  const { goToUpdateService, goToNewServiceClass } = useDescriptionController();
  return (
    <Container
      container
      spacing={5}
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Grid item lg={5} md={12}>
        <Box mb="15px" display="flex" alignItems="center">
          <DescriptionResumeAvatar alt={`avatar-${name}`} src={avatarUrl} />
          <DescriptionResumeName>{name}</DescriptionResumeName>
        </Box>
        <DescriptionResumeText
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Grid>

      <Grid item lg={3} md={6} sm={12} xs={12}>
        <Box display="flex" justifyContent="space-between">
          <DescriptionSpotsButton
            disableElevation
            variant="text"
            color="primary"
            disableRipple
            endIcon={<CreateOutlinedIcon />}
            onClick={() => goToUpdateService(id)}
          >
            Editar serviço
          </DescriptionSpotsButton>
          <DescriptionSpotsButton
            disableElevation
            variant="contained"
            disableRipple
            onClick={goToNewServiceClass(id)}
          >
            Abrir nova turma
          </DescriptionSpotsButton>
        </Box>
      </Grid>
    </Container>
  );
};
