import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  bigIcon: {
    fontSize: 120,
    marginBottom: 20,
  },
  textButton: {
    textTransform: 'none',
    fontWeight: 'bold',
    padding: '0px 0px 3px 0px',
    fontSize: 15.5,
    fontFamily: 'Open Sans',
  },
  link: {
    color: 'white',
    fontFamily: 'Open Sans',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});
