import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import EmptyListPlaceholder from 'components/EmptyListPlaceholder';
import { formatGrade } from 'pages/Admin/AdmRegistrations/utils';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import useGlobalStyles from 'styles';
import {
  error,
  primaryDark,
  primaryMedium,
  warning,
  white,
} from 'styles/theme';
import { ADM_NO_REGISTRATIONS } from 'texts';
import { INTERVIEW_STATUS, REGISTRATION_STATUS } from 'types/enums';
import { Registration } from 'types/registration';
import { TABLE_HEADERS } from 'utils/tableHeaders';
import { Label, useStyles } from './styles';
import { TableProperties } from './types';

const RegistrationsTable: React.FC<TableProperties> = ({
  registrations,
  isShortened,
}) => {
  const classes = useGlobalStyles();
  const styles = useStyles();
  const history = useHistory();

  const headers = [
    TABLE_HEADERS.YEAR,
    TABLE_HEADERS.REGISTRATION,
    TABLE_HEADERS.DEPENDENT,
    TABLE_HEADERS.YEAR_OR_GRADE,
    TABLE_HEADERS.INTERVIEW,
    TABLE_HEADERS.STATUS,
  ];

  const goToAll = () => () => {
    history.push('/adm-registrations');
  };

  const goToDetails = (registration: Registration) => () => {
    history.push({
      pathname: `/adm-registrations/${registration.id}`,
    });
  };

  const labelColor = useMemo(
    () => (text: INTERVIEW_STATUS | REGISTRATION_STATUS) => {
      switch (text) {
        case INTERVIEW_STATUS.DONE:
          return { backgroundColor: primaryMedium, color: white };
        case INTERVIEW_STATUS.REFUSED:
          return { backgroundColor: error, color: white };
        case REGISTRATION_STATUS.ACTIVE:
          return { backgroundColor: primaryMedium, color: white };
        case REGISTRATION_STATUS.RE_REGISTRATION:
          return { backgroundColor: primaryMedium, color: white };
        case REGISTRATION_STATUS.BLOCKED:
          return { backgroundColor: error, color: white };
        case REGISTRATION_STATUS.CANCELLED:
          return { backgroundColor: error, color: white };
        default:
          return { backgroundColor: warning, color: primaryDark };
      }
    },
    [],
  );

  return registrations.length ? (
    <Grid item xs={12} style={{ marginTop: -20 }}>
      <Paper elevation={0} className={styles.paper}>
        <Table className={styles.table}>
          <TableHead>
            <TableRow>
              {headers.map(header => (
                <TableCell key={header}>
                  <Typography className={classes.listScreenHeaderText1}>
                    {header}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={styles.tableBody}>
            <Box height={20} />
            {registrations.map(registration => (
              <TableRow
                key={registration.id}
                hover
                onClick={goToDetails(registration)}
                className={styles.row}
              >
                <TableCell className={styles.tableCell}>
                  {registration.schoolYear}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {registration.number || '---'}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {registration.dependent.name}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {formatGrade(registration.schoolGrade)}
                </TableCell>
                <TableCell className={styles.tableCellLabel}>
                  <Label
                    backgroundColor={
                      labelColor(registration.interview.status).backgroundColor
                    }
                    color={labelColor(registration.interview.status).color}
                  >
                    {registration.interview.status}
                  </Label>
                </TableCell>
                <TableCell className={styles.tableCellLabel}>
                  <Label
                    backgroundColor={
                      labelColor(registration.status).backgroundColor
                    }
                    color={labelColor(registration.status).color}
                  >
                    {registration.status}
                  </Label>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {isShortened ? (
          <Grid container item alignItems="center" justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={goToAll()}
                color="primary"
                className={classes.textButton}
              >
                Ver todas
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </Paper>
    </Grid>
  ) : (
    <Grid className={styles.grid}>
      <EmptyListPlaceholder message={ADM_NO_REGISTRATIONS} />
    </Grid>
  );
};

export default RegistrationsTable;
