// Should be the same as src/csv/csv.enums.ts in the backend
export enum FormatOption {
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  CURRENCY_BRL = 'CURRENCY_BRL',
  SCHOOL_GRADE = 'SCHOOL_GRADE',
  CONTRACT_STATUS = 'CONTRACT_STATUS',
  DATE_DD_MM_YYYY = 'DATE_DD_MM_YYYY',

  // Merging operations
  REDUCE_SUM_VALUES = 'REDUCE_SUM_VALUES',
}
