import {
  Collapse,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { CustomButton } from 'components/CustomButton';
import useGlobalStyles from 'styles';
import { StyledTableRow } from '../FinancialReportTableEntry/styles';
import { InstallmentsCollapsableTableCell } from '../InstallmentsCollapsableTableCell';
import { Hairline, StyledSubTable, StyledTableCell } from './styles';
import { FinancialReportCollapsableTableRowProps } from './types';
import { useFinancialReportCollapsableTableRowController } from './useFinancialReportCollapsableTableRowController';

/**
 * In this component we have all the collapsable content from Financial Table
 * (rows #2 and #3).
 */
export const FinancialReportCollapsableTableRow = ({
  isExpanded = true,
  payment,
}: FinancialReportCollapsableTableRowProps) => {
  const classes = useGlobalStyles();
  const {
    subTableHeaders1,
    subTableHeaders2,
    navigateToFinancialDetails,
    renderContractSignDate,
    renderSchoolClassOrServiceClassName,
    renderContractStatus,
    renderTotalNumberOfInstallments,
  } = useFinancialReportCollapsableTableRowController({ payment });

  return (
    <StyledTableRow isExpanded={isExpanded}>
      <StyledTableCell colSpan={7}>
        <Collapse in={isExpanded}>
          <StyledSubTable>
            <TableHead>
              <TableRow>
                {subTableHeaders1.map(tableHeader => (
                  <TableCell key={tableHeader}>
                    <Typography className={classes.listScreenHeaderText1}>
                      {tableHeader}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{payment.registration.number || '-'}</TableCell>
                <TableCell>{renderSchoolClassOrServiceClassName()}</TableCell>
                <TableCell>{renderContractStatus()}</TableCell>
                <TableCell>
                  {renderContractSignDate(payment.contractInfo?.signDate)}
                </TableCell>
              </TableRow>
            </TableBody>
          </StyledSubTable>

          <Hairline />

          <StyledSubTable>
            <TableHead>
              <TableRow>
                {subTableHeaders2.map(tableHeader => (
                  <TableCell key={tableHeader}>
                    <Typography className={classes.listScreenHeaderText1}>
                      {tableHeader}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <InstallmentsCollapsableTableCell
                  installments={renderTotalNumberOfInstallments()}
                  bills={payment.bills}
                />
                <TableCell>{payment.financialGuardian.user.name}</TableCell>
                <TableCell>
                  <CustomButton
                    variant="secondary"
                    textVariant="bold"
                    className={classes.textButton}
                    onClick={navigateToFinancialDetails}
                  >
                    Visualizar detalhes
                  </CustomButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </StyledSubTable>
        </Collapse>
      </StyledTableCell>
    </StyledTableRow>
  );
};
