import { makeStyles, styled, Theme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import {
  darkGray,
  error,
  mediumGray,
  primaryDark,
  purple,
  secondaryMain,
  white,
  whiteFont,
} from 'styles/theme';
import { InputText } from 'pages/Admin/Marketplace/Services/components/ServiceForm/styles';
import {
  CoverImageCaptionContainerStyledProps,
  CoverImageStyledProps,
} from './types';

export const Container = styled('div')<Theme>(() => {
  return {
    width: 'calc(100% - 60px)',
    height: '100%',
    margin: '20px 20px',
    paddingBottom: 200,
  };
});

export const CoverImage = styled('div')<Theme, CoverImageStyledProps>(
  ({ coverImage, theme }) => {
    return {
      height: '320px',
      backgroundSize: 'cover',
      backgroundImage: `url("${coverImage}")`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        height: 210,
      },
    };
  },
);

export const CoverImageCaptionContainer = styled('div')<
  Theme,
  CoverImageCaptionContainerStyledProps
>(({ theme, coverType }) => {
  const background = {
    'by-invite': purple,
    'without-cost': primaryDark,
    'with-cost': secondaryMain,
    event: 'transparent',
  };

  const color = {
    'by-invite': white,
    'without-cost': white,
    'with-cost': primaryDark,
    event: 'transparent',
  };

  if (coverType === 'event') {
    return { display: 'none' };
  }

  return {
    background: background[coverType],
    height: 50,
    width: '100%',
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '45px',
    paddingRight: '65px',
    color: color[coverType],
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '-0.3px',
    fontWeight: 700,
    '& div': {
      display: 'flex',
    },
    '& span': {
      marginLeft: '12px',
    },
    '& .MuiIcon-root': {
      paddingRight: '8px',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      '& #interested-people-container': {
        display: 'none',
      },
    },
  };
});

export const ContainerServiceDetails = styled('div')<Theme>(({ theme }) => {
  return {
    // height: '500px',
    display: 'flex',
    // marginBottom: 80,
    [theme.breakpoints.down('md')]: {
      // height: 'auto',
      flexDirection: 'column',
    },
  };
});

export const ServiceDescription = styled('div')<Theme>(({ theme }) => {
  return {
    width: '100%',
    marginTop: '54px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '30px',
    },
    '& #service-description': {
      fontSize: '16px',
      lineHeight: '22px',
      letterSpacing: '-0.3px',
      color: darkGray,
      width: '80%',
      overflowWrap: 'break-word',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    '& #service-description span ': {
      [theme.breakpoints.down('sm')]: {
        minWidth: '200px',
      },
    },
  };
});

export const PaymentMethods = styled('div')<Theme, { isMobile: boolean }>(
  ({ theme, isMobile }) => {
    return {
      display: isMobile ? 'none' : 'flex',
      alignItems: 'center',
      marginTop: '43px',
      [theme.breakpoints.down('md')]: {
        paddingTop: '30px',
      },
      [theme.breakpoints.down('md')]: {
        display: isMobile ? 'flex' : 'none',
        width: '440px',
        background: white,
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
        borderRadius: '34px',
        padding: '40px 30px',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
      '& #payment-method-caption': {
        display: 'flex',
        alignItems: 'center',
        width: '120px',
        height: '85px',
        borderRight: '1px solid rgba(0, 0, 0, 0.15)',
        fontSize: '16px',
        lineHeight: '22px',
        color: mediumGray,
        marginRight: '40px',
      },
      '& #payment-method-items span': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '8px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
        },
      },
      '& #payment-method-items .MuiIcon-root': {
        marginRight: '15px',
      },
      '& #payment-method-items img': {
        [theme.breakpoints.down('sm')]: {
          width: '25px',
        },
      },
    };
  },
);

export const ServiceInfos = styled('div')<Theme>(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: '60px',
    [theme.breakpoints.down('md')]: {
      marginTop: '40px',
      alignItems: 'flex-start',
    },
  };
});

export const ServiceDescriptionHeader = styled('div')<Theme>(({ theme }) => {
  return {
    display: 'flex',
    marginBottom: '54px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '28px',
    },
  };
});

export const ServiceDescriptionAvatar = styled('div')<
  Theme,
  CoverImageStyledProps
>(({ theme, coverImage }) => {
  return {
    height: '84px',
    width: '84px',
    borderRadius: '50%',
    backgroundImage: `url("${coverImage}")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    marginRight: '24px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  };
});

export const ServiceDescriptionHeaderContent = styled('div')<Theme>(
  ({ theme }) => {
    return {
      '& #title': {
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '30px',
        lineHeight: '120%',
        color: darkGray,
        [theme.breakpoints.down('sm')]: {
          fontSize: '24px',
        },
        marginBottom: '17px',
      },
      '& #without-additional-cost': {
        fontSize: '26px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '18px',
        },
        lineHeight: '120%',
        color: primaryDark,
      },
      '& #without-additional-cost.by-invite': {
        color: purple,
      },
      '& #amount-description': {
        display: 'flex',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '120%',
        color: mediumGray,
      },
      '& #amount': {
        color: primaryDark,
        marginRight: '8px',
      },
    };
  },
);

export const ButtonAddToFavorites = styled(Button)<Theme>(({ theme }) => {
  return {
    width: '181px',
    height: '40px',
    background: primaryDark,
    border: `1px solid ${whiteFont}`,
    borderRadius: '8px',
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '19px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '-0.3px',
    color: white,
    marginBottom: '64px',
    '&:hover': {
      opacity: 0.8,
      transition: 'opacity 0.2s linear',
      backgroundColor: primaryDark,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '35',
    },
  };
});

export const ScheduleInfosCard = styled('div')<Theme>(({ theme }) => {
  return {
    width: '440px',
    background: white,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
    borderRadius: '34px',
    padding: '30px',
    marginBottom: '25px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& .schedule': {
      fontWeight: '400',
      fontSize: '20px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
      lineHeight: '120%',
      color: darkGray,
      marginTop: '16px',
    },

    '& h4': {
      color: mediumGray,
      fontWeight: 400,
      fontSize: '18px',
    },

    '& .container-classes': {
      marginTop: '24px',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '10px',
    },

    '& .container-classes button': {
      borderWidth: '1.5px',
      padding: '11px 18px',
      borderColor: '#808080',
      color: '#3C3C3B',
    },

    '& .container-classes button.active': {
      color: '#003576',
      borderColor: '#003576',
      background: '#EFF7FF',
    },
  };
});

export const ScheduleConflictFeedback = styled('div')<Theme>(({ theme }) => {
  return {
    '& #schedule-conflict-feedback-content': {
      marginTop: '30px',
      display: 'flex',
      alignItems: 'center',
      fontWeight: '600',
      fontSize: '18px',
      lineHeight: '25px',
      letterSpacing: '-0.3px',
      color: primaryDark,
    },
    '& #schedule-conflict-feedback-content .MuiIcon-root': {
      height: '28px',
      width: '38px',
    },
    '& p': {
      marginLeft: '10px',
    },
    '& .has-conflict': {
      color: error,
    },
    '& #schedule-conflict-feedback-description': {
      marginLeft: '50px',
      marginTop: '10px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
  };
});

export const ContainerModalShowInterestInfo = styled(Dialog)<Theme>(
  ({ theme }) => {
    return {
      [theme.breakpoints.down('sm')]: {
        position: 'relative',
      },
      '& #top-modal-actions': {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
          display: 'flex',
          position: 'absolute',
          top: '10px',
          right: '10px',
        },
      },
      '& .MuiDialog-paper': {
        width: '100%',
        maxWidth: '611px',
        borderRadius: '20px',
        padding: '35px',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
          position: 'absolute',
          maxWidth: '100%',
          bottom: 0,
          margin: 0,
          borderRadius: '20px 20px 0 0',
          paddingBottom: '100px',
        },
        '& b': {
          marginBottom: '8px',
        },
        '& #close': {
          padding: '10px 20px',
          width: '150px',
          height: '42px',
          background: primaryDark,
          color: white,
          borderRadius: '8px',
          fontWeight: 700,
          [theme.breakpoints.down('sm')]: {
            display: 'none',
          },
        },
        '& #modal-actions': {
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          marginTop: '50px',
          [theme.breakpoints.down('sm')]: {
            display: 'none',
          },
        },
      },
    };
  },
);

export const ModalShowInterestInfoTitle = styled('div')<Theme>(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    marginBottom: '16px',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    '&  svg': {
      marginRight: '8px',
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        marginBottom: '20px',
      },
    },
  };
});

export const ContainerModalInsertInviteCode = styled(Dialog)<Theme>(
  ({ theme }) => {
    return {
      [theme.breakpoints.down('sm')]: {
        position: 'relative',
      },
      '& #top-modal-actions': {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
          display: 'flex',
          position: 'absolute',
          top: '10px',
          right: '10px',
        },
      },
      '& .MuiDialog-paper': {
        width: '100%',
        maxWidth: '611px',
        borderRadius: '20px',
        padding: '35px',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
          position: 'absolute',
          maxWidth: '100%',
          bottom: 0,
          margin: 0,
          borderRadius: '20px 20px 0 0',
          paddingBottom: '100px',
        },
        '& #title': {
          fontStyle: 'normal',
          textAlign: 'center',
          fontSize: '28px',
          lineHeight: '38px',
          color: darkGray,
          marginTop: '24px',
          [theme.breakpoints.down('sm')]: {
            fontWeight: 700,
            fontSize: '18px !important',
            textAlign: 'initial !important',
          },
        },
        '& #description': {
          fontStyle: 'normal',
          fontSize: '16px',
          lineHeight: '22px',
          textAlign: 'center',
          color: mediumGray,
          marginTop: '12px',
          marginBottom: '40px',
          [theme.breakpoints.down('sm')]: {
            textAlign: 'initial !important',
            fontSize: '14px !important',
          },
        },
        '& #modal-actions': {
          display: 'flex',
          width: '55%',
          margin: '0 auto',
          justifyContent: 'space-around',
          marginBottom: '20px',
          [theme.breakpoints.down('sm')]: {
            marginTop: '33px !important',
            width: 'fit-content !important',
            margin: '0 !important',
          },
        },
        '& #cancel': {
          fontWeight: 700,
          fontSize: '16px',
          lineHeight: '22px',
          textAlign: 'center',
          color: primaryDark,
          [theme.breakpoints.down('sm')]: {
            display: 'none',
          },
        },
        '& #confirm': {
          alignItems: 'center',
          padding: '10px 20px',
          width: '153px',
          height: '42px',
          background: primaryDark,
          color: white,
          borderRadius: '8px',
          fontWeight: 700,
        },
      },
    };
  },
);

export const ModalInsertInviteInputText = styled(InputText)<Theme>(
  ({ theme }) => {
    return {
      '&.desktop-input': {
        [theme.breakpoints.down(960)]: {
          display: 'none',
        },
        width: '207px',
        margin: '30px auto',
      },
      '&.mobile-input': {
        [theme.breakpoints.down(960)]: {
          display: 'flex',
          width: '100%',
          margin: '20px 0',
          alignItems: 'flex-start',
        },
        [theme.breakpoints.up(960)]: {
          display: 'none',
        },
      },
    };
  },
);

export const useStyles = makeStyles(() => ({
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
