import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import EditOutlined from '@material-ui/icons/EditOutlined';
import AlertCard from 'components/AlertCard';
import Modal from 'components/Modal';
import { TablePaper } from 'components/TablePaper';
import { format } from 'date-fns';
import { StatusCodes } from 'http-status-codes';
import { translatePaymentMethod } from 'pages/Client/PaymentMethod/steps/ConfirmPayment/utils';
import React, { useEffect, useState } from 'react';
import ConfigurationsService from 'services/configurationsService';
import useGlobalStyles from 'styles';
import { EDIT_SUCCESSFUL, GENERAL_ERROR } from 'texts';
import { Configurations, IDiscount } from 'types/configurations';
import EditModal from './EditModal';
import { tablesList } from './utils';

const GeneralConfigurations: React.FC = () => {
  const classes = useGlobalStyles();
  const [configurations, setConfigurations] = useState<Configurations | null>();
  const [discounts, setDiscounts] = useState<IDiscount[] | undefined>(
    undefined,
  );
  const [editModal, setEditModal] = useState(false);
  const [discountSelected, setDiscountSelected] = useState<
    IDiscount | undefined
  >(undefined);
  const [editModalType, setEditModalType] = useState('');
  const [editModalTitle, setEditModalTitle] = useState('');
  const [modal, setModal] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    async function getConfigurations() {
      const { data: response, status } =
        await ConfigurationsService.configurations();
      if (status === StatusCodes.OK && response.data.length) {
        setConfigurations(response.data.shift());
      } else {
        setAlert(true);
        setAlertMessage(GENERAL_ERROR);
      }
    }
    getConfigurations();
  }, [modal]);

  useEffect(() => {
    async function getConfigurations() {
      const { data: response, status } =
        await ConfigurationsService.getDiscountInfo();
      if (status === StatusCodes.OK && response.data.length) {
        setDiscounts(response.data);
      } else {
        setAlert(true);
        setAlertMessage(GENERAL_ERROR);
      }
    }
    getConfigurations();
  }, [modal]);

  function openEditModal(
    type: string,
    title: string,
    discountSelected?: IDiscount,
  ) {
    setDiscountSelected(discountSelected);
    setEditModalTitle(title);
    setEditModalType(type);
    setEditModal(true);
  }

  const handleOpenModal = () => {
    setModal(true);
    setEditModal(false);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  return (
    <Grid
      className={classes.listScreenGrid}
      container
      direction="column"
      alignItems="flex-start"
    >
      {configurations ? (
        <EditModal
          title={editModalTitle}
          type={editModalType}
          open={editModal}
          close={() => setEditModal(false)}
          configuration={configurations}
          discountSelected={discountSelected}
          openModal={handleOpenModal}
        />
      ) : null}
      <Modal
        icon={
          <CheckCircleOutline
            color="secondary"
            className={classes.dialogIcon}
          />
        }
        open={modal}
        text={EDIT_SUCCESSFUL}
        btnText="Voltar"
        btnAction={handleCloseModal}
      />
      <AlertCard
        message={alertMessage}
        open={alert}
        close={() => setAlert(false)}
        severity="error"
      />
      <Grid container item direction="column" style={{ marginBottom: 50 }}>
        <Typography className={classes.screenHeaderText}>
          <b>Configurações</b>
        </Typography>
        <Typography className={classes.screenSubHeaderText}>
          Acesse aqui as configurações de desconto, período de matrícula e chave
          de materiais
        </Typography>

        {configurations
          ? tablesList(configurations, discounts).map(item => (
              <>
                <Typography className={classes.screenHeaderText}>
                  <b>{item.title}</b>
                </Typography>
                <TablePaper key={item.type} minWidth={250}>
                  <TableHead>
                    <TableRow>
                      {item.headers.map(header => (
                        <TableCell key={header}>
                          <Typography className={classes.listScreenHeaderText1}>
                            {header}
                          </Typography>
                        </TableCell>
                      ))}
                      <TableCell align="right">
                        <Typography className={classes.listScreenHeaderText1}>
                          EDITAR
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item?.rows?.length ? (
                      item.rows.map((payment, index) => (
                        <TableRow>
                          <TableCell key={index}>
                            {`${translatePaymentMethod(
                              payment.paymentMethodCode,
                            )} (${payment.formOfPayments})`}
                          </TableCell>
                          <TableCell key={index}>
                            {payment?.discountUntilTheDay}%
                          </TableCell>
                          <TableCell key={index}>
                            {payment?.untilTheDay
                              ? format(
                                  new Date(payment.untilTheDay),
                                  'dd/MM/yyyy',
                                )
                              : '-'}
                          </TableCell>
                          <TableCell key={index}>
                            {payment?.discountDefault}%
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              onClick={() =>
                                openEditModal(
                                  item.type,
                                  `${translatePaymentMethod(
                                    payment.paymentMethodCode,
                                  )} (${payment.formOfPayments})`,
                                  payment,
                                )
                              }
                              color="default"
                            >
                              <EditOutlined color="primary" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        {item.values.map(value => (
                          <TableCell key={value}>{value}</TableCell>
                        ))}
                        <TableCell align="right">
                          <IconButton
                            onClick={() => openEditModal(item.type, item.title)}
                            color="default"
                          >
                            <EditOutlined color="primary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </TablePaper>
              </>
            ))
          : null}
      </Grid>
    </Grid>
  );
};

export default GeneralConfigurations;
