import { DEFAULT_SERVICE_NAME } from 'components/FinancialTable/consts';
import { TableColumnConfig } from 'components/Table/types';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { TABLE_HEADERS } from 'utils/tableHeaders';
import { formatGrade } from '../AdmRegistrations/utils';
import { ContractTableData } from './ContractsTables/types';
import { ContractRowActions } from './components';
import { ContractRowLabelColor } from './components/LabelColor';

const contractStatus = (_: any, data: ContractTableData) => {
  const { signDate, canceledAt } = data;

  if (canceledAt) {
    return 'Cancelado';
  } else if (signDate) {
    return 'Assinado';
  }

  return 'Emitido';
};

export const tableConfig: TableColumnConfig<ContractTableData>[] = [
  {
    headerName: TABLE_HEADERS.YEAR,
    getter: 'payment.referenceYear',
    defaultValue: '-',
  },
  {
    headerName: TABLE_HEADERS.REGISTRATION,
    getter: 'payment.registration.number',
    defaultValue: '-',
  },
  {
    headerName: TABLE_HEADERS.DEPENDENT,
    getter: 'payment.registration.dependent.name',
    defaultValue: '-',
  },
  {
    headerName: TABLE_HEADERS.YEAR_OR_GRADE,
    getter: 'payment.referenceGrade',
    defaultValue: '-',
    onFormat: referenceGrade => formatGrade(referenceGrade),
  },
  {
    headerName: TABLE_HEADERS.SERVICE_NAME,
    getter: 'service.name',
    defaultValue: DEFAULT_SERVICE_NAME,
  },
  {
    headerName: TABLE_HEADERS.PAYMENT_STATUS,
    getter: 'payment.status',
    defaultValue: '-',
    customRender: ContractRowLabelColor,
  },
  {
    headerName: TABLE_HEADERS.STATUS,
    getter: 'signDate',
    defaultValue: '-',
    customRender: contractStatus,
  },
  {
    headerName: TABLE_HEADERS.SIGNATURE_DATE,
    getter: 'signDate',
    defaultValue: '-',
    onFormat: signDate =>
      format(new Date(signDate), 'dd/MM/yyyy', {
        locale: ptBR,
      }),
  },
  {
    headerName: TABLE_HEADERS.ACTIONS,
    getter: 'signDate',
    defaultValue: '-',
    customRender: ContractRowActions,
  },
] as TableColumnConfig<ContractTableData>[];
