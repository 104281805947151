import axios from 'axios';
import { VindiCreditCard } from 'types/vindiCreditCard';
import * as Sentry from '@sentry/react';

const vindiApi = axios.create({
  baseURL: process.env.REACT_APP_VINDI_URL,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

export default class VindiService {
  static async generateVindiCardToken(card: VindiCreditCard) {
    try {
      return await vindiApi.post('/payment_profiles', card, {
        auth: {
          username: `${process.env.REACT_APP_VINDI_API_KEY}`,
          password: '',
        },
      });
    } catch (error: any) {
      const { errors } = error.response.data;
      Sentry.setContext('Vindi', {
        ...errors,
        apiKey: process.env.REACT_APP_VINDI_API_KEY,
        name: card.holder_name,
        expiration: card.card_expiration,
        customer: card.customer_id,
        method: card.payment_method_code,
      });
      Sentry.captureMessage('Vindi card tokenization error');
      return error;
    }
  }
}
