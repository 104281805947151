import { useHistory } from 'react-router-dom';
import useGlobalStyles from 'styles';
import { TABLE_HEADERS } from 'utils/tableHeaders';

const headers = [
  TABLE_HEADERS.PARTNER_NAME,
  // TABLE_HEADERS.STUDENTS,
  TABLE_HEADERS.SERVICES_OFFER,
  // TABLE_HEADERS.TAX_TO_CHARGE,
];

export const usePartnersTableController = () => {
  // Styles
  const classes = useGlobalStyles();

  // Navigation
  const history = useHistory();

  // Callbacks
  const goToPartnerServices = (partnerId: number) => {
    history.push(`/adm-marketplace/partners/${partnerId}/services`);
  };

  const goToPartnerUpdate = (partnerId: number) => {
    history.push(`/adm-marketplace/partners/${partnerId}/update-partner`);
  };

  return {
    headers,
    classes,
    goToPartnerServices,
    goToPartnerUpdate,
  };
};
