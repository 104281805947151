import React from 'react';
import LoginAdmin from 'pages/Admin/LoginAdmin';
import RedefinePassword from 'pages/Client/RedefinePassword';
import NewPassword from 'pages/Client/NewPassword';
import { Login } from 'pages/Client/Login';
import { SignUp } from 'pages/Client/SignUp';
import { ValidateUser } from 'pages/Client/ValidateUser';
import { Redirect, Route, Switch } from 'react-router-dom';

const NonAuthRoutes: React.FC = () => (
  <Switch>
    <Route path="/login" exact component={Login} />
    <Route path="/adm-login" component={LoginAdmin} />
    <Route path="/signup" component={SignUp} />
    <Route path="/redefine-password" component={RedefinePassword} />
    <Route path="/new-password/:token" component={NewPassword} />
    <Route path="/validate-user/:token" component={ValidateUser} />
    <Route path="*">
      <Redirect to="/login" />
    </Route>
  </Switch>
);

export default NonAuthRoutes;
