import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ITooltipProps } from './types';
import { useDarkToolTipController } from './controller';

export const DarkTooltip = ({ text }: ITooltipProps) => {
  const { styles, showTooltip, openToolTip, closeToolTip, onClickToolTip } =
    useDarkToolTipController();
  // FIXME: create button component with icon
  return (
    <Tooltip
      title={text}
      placement="top"
      arrow
      leaveTouchDelay={10000}
      classes={{ tooltip: styles.tooltip, arrow: styles.arrow }}
      open={showTooltip}
      onOpen={openToolTip}
      onClose={closeToolTip}
    >
      <IconButton onClick={onClickToolTip} className={styles.icon}>
        <InfoOutlinedIcon className="info-icon-tooltip" />
      </IconButton>
    </Tooltip>
  );
};
