import React from 'react';

import { Box } from '@material-ui/core';

export const PartnersCreationCode = () => {
  return (
    <Box paddingX="45px">
      <h1>PARTNER CREATION CODE - WIP</h1>
    </Box>
  );
};
