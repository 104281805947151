import React from 'react';
import { useHistory } from 'react-router-dom';
import useGlobalStyles from 'styles';
import { FooterProperties } from './types';
import { BackButton, Container, ContainerProgress } from './styles';

const PageFooter: React.FC<FooterProperties> = ({
  nextButton,
  returnPath,
  progress,
}) => {
  const classes = useGlobalStyles();
  const history = useHistory();

  const handleGoBack = () => {
    returnPath ? history.push(returnPath) : history.goBack();
  };

  return (
    <Container>
      <ContainerProgress>{progress}</ContainerProgress>
      <div>
        <BackButton
          onClick={handleGoBack}
          color="primary"
          className={classes.textButton}
        >
          Voltar
        </BackButton>
        {nextButton}
      </div>
    </Container>
  );
};

export default PageFooter;
