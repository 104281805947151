import { useRegistrationsController } from 'hooks/StoredData/useRegistrationsController';
import { useServiceTypesController } from 'hooks/StoredData/useServiceTypesController';
import { useMemo } from 'react';

export const useNewMarketplaceController = (
  selectedRegistrationId?: number | null,
) => {
  // ---------- Registrations ----------
  const { registrations, hasLoadedRegistrations } =
    useRegistrationsController();

  // ---------- ServiceTypes ----------
  const { serviceTypes, hasLoadedServiceTypes } = useServiceTypesController();

  // ---------- Memos ----------
  const hasLoadedRequiredData = useMemo(
    () => hasLoadedRegistrations && hasLoadedServiceTypes,
    [hasLoadedRegistrations, hasLoadedServiceTypes],
  );

  const doesGuardianHaveDependents = useMemo(
    () => !!registrations?.length,
    [registrations],
  );

  const selectedRegistrationSchoolClassId = useMemo(() => {
    if (!registrations || !registrations.length || !selectedRegistrationId)
      return null;

    const selectedRegistration = registrations.find(
      ({ id }) => id === Number(selectedRegistrationId),
    );

    if (!selectedRegistration) return null;

    return selectedRegistration.schoolClass.id;
  }, [registrations, selectedRegistrationId]);

  return {
    // States
    registrations,
    serviceTypes,
    hasLoadedRequiredData,
    // Memos
    doesGuardianHaveDependents,
    selectedRegistrationSchoolClassId,
  };
};
