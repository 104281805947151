import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Box from '@material-ui/core/Box';
import CheckBoxInput from 'components/Forms/CheckBoxInput';

import PdfViewer from 'components/PdfViewer';
import { Card, CircularProgress } from '@material-ui/core';
import { ContractBoxProps } from './types';
import {
  ConditionalDivider,
  ContractSubtitle,
  ContractTitle,
  DownloadButton,
  StyledBox,
  useStyles,
} from './styles';
import { useContractBoxController } from './controller';

export const ContractBox = ({
  payment,
  isChecked = false,
  handleCheckboxToggle,
}: ContractBoxProps) => {
  const { downloadPDF, fileUrl } = useContractBoxController(payment);
  const classes = useStyles();

  return (
    <Box>
      <ContractTitle>
        {`Contrato - ${payment.service?.name || 'Sem nome'}`}
      </ContractTitle>
      <ContractSubtitle>
        {payment.dependent?.name || 'Sem nome'} -{' '}
        {payment.referenceGrade || 'Sem ano/série'}
      </ContractSubtitle>

      <Card className={classes.cardContainer}>
        <ConditionalDivider />

        <CardActions className={classes.centeredCardActions}>
          <DownloadButton variant="secondary" onClick={downloadPDF}>
            <b>Fazer download</b>
          </DownloadButton>
        </CardActions>

        <CardContent>
          {!fileUrl ? (
            <CircularProgress />
          ) : (
            <StyledBox>
              <PdfViewer fileUrl={fileUrl} viewHeight="100%" />
            </StyledBox>
          )}
        </CardContent>

        <ConditionalDivider shouldHideWhenMobile />

        <CardActions>
          <CheckBoxInput
            onChange={() => handleCheckboxToggle(payment.id)}
            name={`contact-${payment.id}`}
            isChecked={isChecked}
          >
            Concordo com os termos e assino o contrato
          </CheckBoxInput>
        </CardActions>
      </Card>
    </Box>
  );
};
