import { useMediaQuery } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import AuthService from 'services/authService';
import { RouteToken } from './types';

export const useValidateUserController = () => {
  // Navigation
  const history = useHistory();
  const { params } = useRouteMatch<RouteToken>();

  // Styles
  const isMobile = useMediaQuery('(max-width: 1024px)');

  // States
  const [verificationError, setVerificationError] = useState(false);

  // Memo
  const memoPadding = useMemo(() => {
    if (verificationError && !isMobile) {
      return {
        pl: 20,
        pr: 0,
      };
    } else if (verificationError && isMobile) {
      return {
        pl: 5,
        pr: 0,
      };
    }
    if (isMobile) {
      return {
        pl: 5,
        pr: 0,
      };
    } else {
      return {
        pl: 30,
        pr: 10,
      };
    }
  }, [isMobile, verificationError]);

  // Callbacks
  const verifyToken = useCallback(async () => {
    try {
      await AuthService.activateAccount(params.token);
      setVerificationError(false);
    } catch (error: any) {
      setVerificationError(true);
    }
  }, [params.token]);

  const pushToLogin = () => {
    history.push('/login');
  };

  // Effects
  useEffect(() => {
    verifyToken();
  }, [history, params, verifyToken]);

  return {
    verificationError,
    isMobile,
    pushToLogin,
    memoPadding,
  };
};
