import { Grid, styled, Typography } from '@material-ui/core';
import { mediumGray } from 'styles/theme';

export const ContainerGrid = styled(Grid)(({ theme }) => ({
  margin: '15px 0px 20px 0px',
  width: 500,
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.down('lg')]: {
    width: '90%',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: 40,
  fontWeight: 300,
  [theme.breakpoints.down('md')]: {
    fontSize: 25,
  },
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  marginTop: 20,
  marginBottom: 15,
  fontSize: 20,
  fontWeight: 300,
  color: mediumGray,
  [theme.breakpoints.down(1024)]: {
    display: 'none',
  },
}));
