import { makeStyles } from '@material-ui/core';
import { mediumGray } from 'styles/theme';

export const usePaymentCardStyles = makeStyles(theme => ({
  title: {
    maxWidth: '450px',
    fontWeight: 'bold',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  marginLeft10: {
    marginLeft: '10px',
  },
  descriptionText: {
    marginTop: '10px',
    color: mediumGray,
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      color: mediumGray,
    },
  },
  button: {
    textTransform: 'initial',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  gridButtons: {
    marginTop: '20px',
    gap: '24px',
    [theme.breakpoints.down('sm')]: {
      gap: '8px',
    },
  },
  cardContainer: {
    padding: '32px 24px 32px 32px',
    borderRadius: '20px',
    width: '100%',
  },
  cardContent: {
    padding: '24px 0px',
    '&:last-child': {
      paddingBottom: '0px',
    },
  },
  gridInformations: {},
  cardTitle: {
    marginBottom: '5px',
    fontSize: '18px',
  },
  cardSubtitle: {
    marginBottom: '10px',
    fontSize: '18px',
  },
  divider: {
    width: '100%',
  },
  icon: {
    height: '25px',
    width: '25px',
  },
}));
