import {
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';
import SpinnerIconButton from 'components/SpinnerIconButton';
import { TablePaper } from 'components/TablePaper';
import { useAuth } from 'contexts/auth';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useGlobalStyles from 'styles';
import { USER_ROLES } from 'types/enums';
import { User } from 'types/user';
import { TABLE_HEADERS } from 'utils/tableHeaders';
import { TableProperties } from './types';

const UsersTable: React.FC<TableProperties> = ({ users, deleteFn }) => {
  const { user: loggedUser } = useAuth();
  const classes = useGlobalStyles();
  const history = useHistory();

  const headers = [
    TABLE_HEADERS.NAME,
    TABLE_HEADERS.EMAIL,
    TABLE_HEADERS.PHONE,
    TABLE_HEADERS.ACCESS_LEVEL,
  ];

  const goToDetails = (user: User) => () => {
    history.push({
      pathname: `/adm-users/${user.id}`,
    });
  };

  const deleteUser = (user: User) => () => {
    deleteFn(user);
  };

  return (
    <TablePaper>
      <TableHead>
        <TableRow>
          {headers.map(header => (
            <TableCell key={header}>
              <Typography className={classes.listScreenHeaderText1}>
                {header}
              </Typography>
            </TableCell>
          ))}
          {loggedUser?.roles.includes(USER_ROLES.ADMIN) ? (
            <TableCell align="right">
              <Typography className={classes.listScreenHeaderText1}>
                AÇÕES
              </Typography>
            </TableCell>
          ) : null}
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map(user => (
          <TableRow key={user.id}>
            <TableCell>{user.name}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>{user.phone || '---'}</TableCell>
            <TableCell>{user.roles[0]}</TableCell>
            <TableCell align="right">
              {!loggedUser?.roles.includes(USER_ROLES.COORDINATOR) ? (
                <IconButton color="primary" onClick={goToDetails(user)}>
                  <EditOutlined color="primary" />
                </IconButton>
              ) : null}
              {loggedUser?.roles.includes(USER_ROLES.ADMIN) ? (
                <SpinnerIconButton
                  action={deleteUser(user)}
                  icon={<DeleteOutline color="primary" />}
                />
              ) : null}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </TablePaper>
  );
};

export default UsersTable;
