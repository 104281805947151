import { CondOperator, SCondition } from '@nestjsx/crud-request';
import { useFilters } from 'hooks/filter';
import { StatusCodes } from 'http-status-codes';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import RegistrationService from 'services/registrationService';
import useGlobalStyles from 'styles';
import { Registration } from 'types/registration';
import { useDebouncedCallback } from 'use-debounce';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { useStyles } from './styles';

export const useDashboardController = () => {
  const classes = useGlobalStyles();
  const styles = useStyles();
  const [registrations, setRegistrations] = useState<Registration[]>([]);
  const [alert, setAlert] = useState(false);
  const [countRegistrations, setCountRegistrations] = useState({
    activeregistrationscurrentyear: 0,
    pendinginterviewcurrentyear: 0,
    activeregistrationsnextyear: 0,
    pendinginterviewnextyear: 0,
  });
  const [searchField, setSearchField] = useState('');
  const year = new Date().getFullYear();
  useEffect(() => {
    const getRegistrationCount = async () => {
      const response = await RegistrationService.count(year);
      if (response.status === StatusCodes.OK) {
        const {
          activeregistrationscurrentyear,
          pendinginterviewcurrentyear,
          activeregistrationsnextyear,
          pendinginterviewnextyear,
        } = response.data;
        setCountRegistrations({
          activeregistrationscurrentyear,
          pendinginterviewcurrentyear,
          activeregistrationsnextyear,
          pendinginterviewnextyear,
        });
      } else {
        setAlert(true);
      }
    };
    getRegistrationCount();
  }, []);

  const [query, setQuery] = useQueryParams({
    queryYear: NumberParam,
    search: StringParam,
  });

  const { queryYear, search } = query;

  const [defaultFilters] = useState([
    {
      field: 'schoolClass.name',
      value: search,
      operator: CondOperator.EQUALS,
    },
    {
      field: 'schoolYear',
      value: queryYear,
      operator: CondOperator.EQUALS,
    },
  ]);

  const { filterFields, handleUpdateFilters } = useFilters(defaultFilters);

  const searchFieldDebounce = useDebouncedCallback((value: string) => {
    setQuery({ search: value });
  }, 1000);

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchField(e.target.value);
    searchFieldDebounce(e.target.value);
  };
  const handleChangeYear = (e: ChangeEvent<HTMLInputElement>) => {
    handleUpdateFilters(
      'schoolYear',
      { value: e.target.value },
      CondOperator.EQUALS,
    );
    setQuery({ queryYear: Number(e.target.value) });
  };

  const loadRegistrations = useCallback(async () => {
    setSearchField(search || '');

    const { data: response, status } =
      await RegistrationService.filterRegistrations({
        page: 1,
        limit: 5,
        search: {
          $and: [
            ...filterFields,
            {
              $or: [
                { 'dependent.name': { $contL: searchField } },
                { number: { $contL: searchField } },
              ],
              schoolYear: { $eq: Number.isNaN(queryYear) ? year : queryYear },
            },
          ],
        } as SCondition,
        join: [['dependent'], ['schoolClass'], ['interview']],
      });
    if (status === StatusCodes.OK) {
      setRegistrations(response.data);
    } else {
      setAlert(true);
    }
  }, [search, queryYear, filterFields]);

  useEffect(() => {
    loadRegistrations();
  }, [loadRegistrations]);
  return {
    classes,
    handleChangeSearch,
    handleChangeYear,
    styles,
    registrations,
    alert,
    countRegistrations,
    setAlert,
    year,
    searchField,
  };
};
