import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  paper: {
    borderRadius: 34,
    marginTop: 25,
    width: '100%',
    padding: 30,
    boxShadow: '0px 6px 32px rgba(9, 13, 27, 0.07)',
  },
  smallField: {
    width: 270,
  },
});
