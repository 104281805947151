import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  capitalized: {
    textTransform: 'capitalize',
  },
  container: {
    width: '100%',
    padding: 15,
  },
  item: {
    flexGrow: 1,
  },
  mb10: {
    marginBottom: 10,
  },
});
