import { FieldHeader } from './components/FieldHeader';
import { ServiceClassGeneralDataForm } from './components/GeneralDataForm';
import { SchedulerForm } from './components/SchedulerForm';
import { PaperContainer } from './styles';
import { IServiceClassFormProps } from './types';

export const ServiceClassForm = ({
  control,
  errors,
  register,
  setValue,
  serviceClassToUpdate,
}: IServiceClassFormProps) => {
  return (
    <PaperContainer elevation={0}>
      <FieldHeader title="Dados gerais" />
      <ServiceClassGeneralDataForm
        control={control}
        register={register}
        errors={errors}
        setValue={setValue}
        serviceClassToUpdate={serviceClassToUpdate}
      />

      <FieldHeader title="Datas, dias e horários" />
      <SchedulerForm
        control={control}
        register={register}
        errors={errors}
        setValue={setValue}
        serviceClassToUpdate={serviceClassToUpdate}
      />
    </PaperContainer>
  );
};
