import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core';
import { ControllerInputText } from 'components/Forms/ControllerInputText';
import { FormDialog } from 'components/dialogs/FormDialog';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import TuitionService from 'services/tuitionService';
import { useAppStore } from 'store';
import { formatBRL } from 'utils';
import { editSchoolClassDetailsValidationSchema } from './schema';
import {
  EditSchoolClassDetailsProps,
  SubmitEditSchoolClassDetails,
} from './types';

export const EditSchoolClassDetailsModal = ({
  editFormRef,
  schoolClassDetails,
  onClose,
  afterSubmit,
}: EditSchoolClassDetailsProps) => {
  const showAlert = useAppStore(state => state.alert.showAlert);

  const { handleSubmit, errors, register, control } = useForm({
    resolver: yupResolver(editSchoolClassDetailsValidationSchema),
    reValidateMode: 'onSubmit',
    shouldFocusError: true,
  });

  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(data: SubmitEditSchoolClassDetails) {
    if (!schoolClassDetails) {
      console.error('Nenhuma turma foi selecionada.');
      return;
    }
    setIsLoading(true);

    try {
      await TuitionService.updateDetail(schoolClassDetails.id, {
        value: data.value,
        vacancies: data.vacancies,
      });
      onClose();
      afterSubmit?.();
      showAlert({
        message: `Detalhes da turma ${schoolClassDetails.schoolClassName} - ${schoolClassDetails.referenceYear} atualizada!`,
        severity: 'success',
      });
    } catch (error: any) {
      showAlert({
        message:
          error?.message || 'Falha ao atualizar as informações da turma.',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <FormDialog
      ref={editFormRef}
      headerTitle={`${schoolClassDetails?.referenceYear} - ${schoolClassDetails?.schoolClassName}`}
      primaryButton={{
        text: 'Salvar',
        loading: isLoading,
        onClick: handleSubmit(onSubmit),
      }}
      secondaryButton={{
        text: 'Voltar',
        disabled: isLoading,
        onClick: onClose,
      }}
    >
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12} md={6}>
          <ControllerInputText
            name="value"
            label="Valor"
            errors={errors}
            defaultValue={formatBRL(schoolClassDetails?.value || 0)}
            register={register}
            control={control}
            inputRef={register}
            type="currency"
          />
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <ControllerInputText
            name="vacancies"
            defaultValue={schoolClassDetails?.vacancies}
            label="Número de vagas"
            errors={errors}
            register={register}
            control={control}
            inputRef={register}
            type="number"
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
};
