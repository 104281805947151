import { Grid, GridProps, styled } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { infoMain } from 'styles/theme';

export const FixedFooterContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: '#F2F6FA',
  position: 'fixed',
  zIndex: 1,
  bottom: 0,
  left: 0,
  right: 0,
  boxShadow: `0px 6px 32px ${infoMain}`,
  paddingTop: '16px',
  paddingLeft: `${204 + 16}px`,
  paddingBottom: '16px',
  paddingRight: '16px',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down(960)]: {
    paddingRight: '10px',
    paddingLeft: '10px',
  },
}));

type FixedFooterProps = Pick<
  GridProps,
  'className' | 'justifyContent' | 'alignItems' | 'direction' | 'spacing'
>;

export const FixedFooter: React.FC<FixedFooterProps> = ({
  children,
  justifyContent = 'flex-end',
  ...gridProps
}) => {
  return (
    <FixedFooterContainer
      justifyContent={justifyContent}
      {...gridProps}
      className={clsx(gridProps.className, 'fixed-footer')}
      container
    >
      {children}
    </FixedFooterContainer>
  );
};
