import React from 'react';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'types/routeParams';
import CreateInvoiceForm from './CreateInvoiceForm';

const CreateInvoice: React.FC = () => {
  const { paymentId } = useParams<RouteParams>();

  return <CreateInvoiceForm paymentId={Number(paymentId)} />;
};

export default CreateInvoice;
