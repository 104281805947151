import calendar from '../../assets/icons/calendar.svg';
import chevRight from '../../assets/icons/chevRight.svg';
import textAndChevArrowBack from '../../assets/icons/textAndChevArrowBack.svg';
import dropdownArrow from '../../assets/icons/dropdownArrow.svg';
import addPhoto from '../../assets/icons/addPhoto.svg';
import warningCheckCircle from '../../assets/icons/warning-check-circle.svg';
import checkCircle from '../../assets/icons/check-circle.svg';
import search from '../../assets/icons/search.svg';
import nounFireIcon from '../../assets/icons/noun-fire-icon.svg';
import scheduleBlack from '../../assets/icons/schedule-black.svg';
import unfilledWhiteHeart from '../../assets/icons/unfilled-white-heart.svg';
import pixBlack from '../../assets/icons/pix_black.svg';
import bankSlipAttachMoney from '../../assets/icons/bank_slip_attach_money.svg';
import creditCardBlack from '../../assets/icons/credit_card-black.svg';
import galleryPaginationChevLeft from '../../assets/icons/gallery-pagination-chev-left.svg';
import galleryPaginationChevRight from '../../assets/icons/gallery-pagination-chev-right.svg';
import whiteLock from '../../assets/icons/white-lock.svg';
import filledBlueHeart from '../../assets/icons/filled-blue-heart.svg';
import filledWhiteHeart from '../../assets/icons/filled-white-heart.svg';
import checkCircleError from '../../assets/icons/check-circle-error.svg';
import closeXOutlineRed from '../../assets/icons/close-x-outline-red.svg';
import blackLock from '../../assets/icons/black-lock.svg';
import searchBlue from '../../assets/icons/search-blue.svg';
import { TIcons } from './types';

export const icons: TIcons = {
  calendar,
  chevRight,
  textAndChevArrowBack,
  dropdownArrow,
  addPhoto,
  warningCheckCircle,
  checkCircle,
  search,
  nounFireIcon,
  scheduleBlack,
  unfilledWhiteHeart,
  pixBlack,
  bankSlipAttachMoney,
  creditCardBlack,
  galleryPaginationChevLeft,
  galleryPaginationChevRight,
  whiteLock,
  filledWhiteHeart,
  filledBlueHeart,
  checkCircleError,
  closeXOutlineRed,
  blackLock,
  searchBlue,
};
