import React from 'react';

import { Box, Button, Divider, Grid, TextField } from '@material-ui/core';
import { ThumbUpOutlined } from '@material-ui/icons';
import AlertCard from 'components/AlertCard';
import CheckboxWithLabel from 'components/CheckboxWithLabel';
import { GuardianTooltip } from 'components/GuardianTooltip';
import MaskedTextField from 'components/MaskedTextField';
import Modal from 'components/Modal';
import { PublicPageContainer } from 'components/PublicPageContainer';
import { PublicPageHeader } from 'components/PublicPageHeader';
import SpinnerButton from 'components/SpinnerButton';
import { Link } from 'react-router-dom';
import { Container } from '../Login/styles';
import { useSignUpController } from './controller';
import { handleOpenTerms } from './utils';

export const SignUp = () => {
  const {
    classes,
    modalSuccessIsOpen,
    checkTerms,
    toggleCheckTerms,
    modalSuccessClose,
    onSubmit,
    nameRef,
    emailRef,
    repeatEmailRef,
    passwordRef,
    repeatPasswordRef,
    handleSubmit,
    errors,
    register,
    alertMessage,
    resetAlertMessage,
  } = useSignUpController();

  return (
    <>
      <Modal
        icon={
          <ThumbUpOutlined color="primary" className={classes.dialogIcon} />
        }
        open={modalSuccessIsOpen}
        text="Registro cadastrado com sucesso!"
        subtext="Um e-mail foi enviado para você confirmar sua conta"
        btnText="Acessar conta"
        btnAction={modalSuccessClose}
      />
      <AlertCard
        message={alertMessage}
        open={!!alertMessage}
        close={resetAlertMessage}
        severity="error"
      />
      <PublicPageContainer>
        <Container>
          <PublicPageHeader
            title="Cadastro de"
            titleBoldComplement="responsável"
            subtitle="Cadastre-se para ter acesso a plataforma"
            tooltip={<GuardianTooltip marginLeft={8} />}
          />
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                label="Nome completo"
                id="name"
                name="name"
                inputProps={{ ref: nameRef }}
                error={Boolean(errors.name)}
                helperText={errors.name ? errors.name.message : null}
                inputRef={register}
                className={classes.publicScreenInput}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MaskedTextField
                mask="(99) 99999-9999"
                label="Telefone"
                name="phone"
                error={!!errors.phone}
                helperText={errors.phone ? errors.phone?.message : null}
                inputRef={register}
                className={classes.publicScreenInput}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                label="Email"
                id="email"
                name="email"
                inputProps={{ ref: emailRef }}
                error={Boolean(errors.email)}
                helperText={errors.email ? errors.email.message : null}
                inputRef={register}
                className={classes.publicScreenInput}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                label="Confirme seu email"
                id="repeatEmail"
                name="repeatEmail"
                inputProps={{ ref: repeatEmailRef }}
                error={Boolean(errors.repeatEmail)}
                helperText={
                  errors.repeatEmail ? errors.repeatEmail.message : null
                }
                inputRef={register}
                className={classes.publicScreenInput}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                label="Senha"
                id="password"
                name="password"
                type="password"
                inputProps={{ ref: passwordRef }}
                error={Boolean(errors.password)}
                helperText={errors.password && errors.password.message}
                inputRef={register}
                className={classes.publicScreenInput}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                label="Confirme sua senha"
                id="repeatPassword"
                name="repeatPassword"
                type="password"
                inputProps={{ ref: repeatPasswordRef }}
                error={Boolean(errors.repeatPassword)}
                helperText={
                  errors.repeatPassword ? errors.repeatPassword.message : null
                }
                inputRef={register}
                className={classes.publicScreenInput}
              />
            </Grid>
          </Grid>
          <Box mt={4}>
            <CheckboxWithLabel
              value={checkTerms}
              onChange={toggleCheckTerms}
              label={
                <>
                  Li e aceito o{' '}
                  <Button
                    className={classes.linkButton}
                    color="primary"
                    onClick={handleOpenTerms}
                  >
                    termo e condições de uso.
                  </Button>
                </>
              }
            />
          </Box>
          {/* TODO: This will be disable until we have the service to manage the emails */}
          {/* <CheckboxWithLabel
            disabled
            value={false}
            onChange={() => setCheckIsGuardian(!checkIsGuardian)}
            label={CHECK_GET_EMAILS}
          /> */}
          <SpinnerButton
            disabled={!checkTerms}
            action={handleSubmit(onSubmit)}
            text="Continuar"
            className={classes.signUpButton}
          />
          <Grid className={classes.publicScreenMargin3}>
            <Divider className={classes.publicScreenDivider} />
          </Grid>
          <Box pb={3}>
            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid>Já tem cadastro?</Grid>
              <Grid>
                <Link to="/login">
                  <Button color="primary" className={classes.textButton}>
                    Entrar
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </PublicPageContainer>
    </>
  );
};
