import { makeStyles } from '@material-ui/core';
import { darkGray } from 'styles/theme';

export const useStyles = makeStyles(theme => ({
  dialog: {
    maxWidth: '625px',
    borderRadius: '20px',
    padding: '48px 100px',

    [theme.breakpoints.down('sm')]: {
      padding: '48px 60px',
    },
  },
  dialogIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: '35px',
  },
  dialogText: {
    fontSize: '28px',
    textAlign: 'center',
    color: darkGray,
  },
  dialogSubText: {
    fontSize: '16px',
    textAlign: 'center',
    color: darkGray,
    marginTop: '12px',
  },
  dialogActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '48px',
    flexDirection: 'row',
    gap: '10px',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
}));
