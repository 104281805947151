/* eslint-disable @typescript-eslint/ban-ts-comment */

import { Box, Divider, Grid, Paper, Typography } from '@material-ui/core';

import AlertCard from 'components/AlertCard';
import { CustomButton } from 'components/CustomButton';
import { ControllerInputText } from 'components/Forms/ControllerInputText';
import Scaffold from 'components/Scaffold';
import { ContractTemplateFormController } from './controller';

// TODO: alert sucess, error etc
export const ContractTemplateForm = () => {
  const {
    loading,
    styles,
    submitError,
    handleSubmit,
    errors,
    register,
    control,
    onSubmit,
    setSubmitError,
    goToContractTemplates,
    templateId,
  } = ContractTemplateFormController();

  return (
    <Scaffold
      rawTitle={
        <>
          {templateId ? 'Editar' : 'Novo'} <b>contrato</b>
        </>
      }
      rawSubtitle={
        !templateId
          ? 'Adicione um novo contrato'
          : 'Edite as informações do contrato'
      }
    >
      <Paper className={styles.paper}>
        <Typography className={styles.paperTitle}>Dados</Typography>
        <Divider className={styles.divider} />
        <Grid
          container
          item
          lg={12}
          className={styles.fieldsContainer}
          spacing={3}
        >
          <Grid item md={4}>
            <ControllerInputText
              key="name"
              name="name"
              label="Nome do contrato"
              control={control}
              register={register}
              errors={errors}
            />
          </Grid>
          <Grid item lg={4} md={6}>
            <ControllerInputText
              key="externalId"
              name="externalId"
              label="Referência da integração"
              control={control}
              register={register}
              errors={errors}
              helperText="Código de referência da integração, você pode obter esse código na plataforma que gera os modelos de contratos"
            />
          </Grid>
        </Grid>
      </Paper>
      <Box className={styles.footerContainer}>
        <CustomButton
          disabled={loading}
          onClick={goToContractTemplates}
          variant="secondary"
          className={styles.goBackButtom}
        >
          Voltar
        </CustomButton>

        <CustomButton
          loading={loading}
          onClick={handleSubmit(onSubmit)}
          text="Salvar template"
          className={styles.submitButtom}
        />
      </Box>
      <AlertCard
        message={submitError}
        open={!!submitError}
        close={() => setSubmitError('')}
        severity="error"
      />
    </Scaffold>
  );
};
