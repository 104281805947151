import { StatusCodes } from 'http-status-codes';
import { allToUpperCase } from 'pages/Client/NewRegistration/utils';
import { useCallback, useState } from 'react';
import CEPService from 'services/cepService';
import { CEP_ERROR, CEP_NOT_FOUND } from 'texts';
import { Address } from 'types/address';
import { useDebouncedCallback } from 'use-debounce';

export const useSearchCEP = (defaultAddress?: Partial<Address>) => {
  const [address, setAddress] = useState<Partial<Address>>(
    defaultAddress || {},
  );
  const [cepError, setCepError] = useState('');
  const [cepErrorCount, setCepErrorCount] = useState(0);

  const clearCepError = (clearCount?: boolean) => {
    setCepError('');
    if (clearCount) setCepErrorCount(0);
  };

  const handleCepError = (message: string, addCount?: boolean) => {
    setAddress({});
    setCepError(message);
    if (addCount) setCepErrorCount(prev => prev + 1);
  };

  const searchCEP = useCallback(
    async (cep?: string) => {
      if (!cep) return;
      clearCepError();
      cep = cep.replace(/\D+/g, '');
      if (cep.length === 8) {
        const response = await CEPService.verifyCEP(cep);
        if (response.status !== StatusCodes.OK) {
          handleCepError(CEP_ERROR);
        } else if (response.data.erro) {
          handleCepError(CEP_NOT_FOUND, true); // adds to error count if cep isn't found
        } else {
          const { logradouro, bairro, localidade, uf, ibge } = response.data;
          clearCepError(true);
          setAddress(
            allToUpperCase({
              street: logradouro,
              city: localidade,
              district: bairro,
              state: uf,
              ibge,
              isComercial: false,
            }),
          );
        }
      }
    },
    [cepErrorCount],
  );

  const debouncedSearchCEP = useDebouncedCallback(searchCEP, 300);

  return {
    debouncedSearchCEP,
    address,
    cepError,
    cepErrorCount,
    clearCepError,
  };
};
