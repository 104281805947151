import { Box, Typography } from '@material-ui/core';
import { forwardRef } from 'react';
import { GenericDialog } from '../GenericDigalog';
import { GenericDialogRef } from '../GenericDigalog/types';
import { useStyles } from './styles';
import { FormDialogProps } from './types';

export const FormDialog = forwardRef<GenericDialogRef, FormDialogProps>(
  (props, ref) => {
    const { headerTitle, headerSubtitle, children, ...genericDialogProps } =
      props;
    const classes = useStyles();

    return (
      <GenericDialog {...genericDialogProps} alignActions="rigth" ref={ref}>
        <Box className={classes.headerContainer}>
          <Typography className={classes.headerTitle}>{headerTitle}</Typography>
          {headerSubtitle ? (
            <Typography className={classes.headerSubtitle}>
              {headerSubtitle}
            </Typography>
          ) : null}
        </Box>

        {children}
      </GenericDialog>
    );
  },
);
