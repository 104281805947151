import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import PartnersService from 'services/partnersService';
import { RouteParams } from 'types/routeParams';

import { FileState } from '../components/PartnersForm/components/ExtraDataForm/types';
import { schema } from '../components/PartnersForm/schema';
import {
  IPartnersFieldValues,
  IPartnersResponse,
} from '../components/PartnersForm/types';

export const usePartnerUpdateController = () => {
  const { id } = useParams<RouteParams>();

  // Navigation
  const history = useHistory();

  // Form
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    setValue,
    getValues,
  } = useForm<IPartnersFieldValues>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  // States
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [avatarFile, setAvatarFile] = useState<FileState>();
  const [partnerToUpdate, setPartnerToUpdate] =
    useState<Partial<IPartnersResponse>>();
  const [loading, setLoading] = useState(false);

  // Callbacks
  const getPartnerToUpdate = useCallback(async () => {
    const { data: response } = await PartnersService.getPartner(id, {
      join: [
        {
          field: 'serviceCategories',
          select: ['id', 'name'],
        },
      ],
    });
    setPartnerToUpdate(response);
  }, [id]);

  const submit = useCallback(
    async (values: IPartnersFieldValues) => {
      setLoading(true);
      const data = new FormData();
      data.append('name', values.name);
      data.append('description', values.description);
      data.append('email', values.email);
      data.append('phone', values.phone);
      data.append('color', values.color);
      data.append('cnpj', values.cnpj);
      if (avatarFile?.file) {
        data.append('photo', avatarFile.file as Blob);
      }
      values.category.forEach(categoryName => {
        data.append('serviceCategories[]', categoryName);
      });
      data.append('feeToBeCharged', values.tax.replace('%', ''));

      const res = await PartnersService.updatePartner(data, id);

      if (res.status === 200) {
        setSuccessModalOpen(true);
      } else {
        setSubmitError(res.data.message);
      }
      setLoading(false);
    },
    [avatarFile?.file, id],
  );

  const handleCloseSubmitError = () => {
    setSubmitError('');
  };

  const handleCancel = () => {
    setCancelModalOpen(true);
  };

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
    history.goBack();
  };

  const handleCloseCancelModal = () => {
    setCancelModalOpen(false);
    history.goBack();
  };

  const handleCloseAndNotGoBack = () => {
    setSuccessModalOpen(false);
    setCancelModalOpen(false);
  };

  // Effects
  useEffect(() => {
    getPartnerToUpdate();
  }, [getPartnerToUpdate]);

  return {
    submit,
    handleSubmit,
    errors,
    register,
    control,
    setValue,
    getValues,
    handleCancel,
    successModalOpen,
    cancelModalOpen,
    handleCloseSuccessModal,
    handleCloseCancelModal,
    handleCloseAndNotGoBack,
    setAvatarFile,
    submitError,
    handleCloseSubmitError,
    partnerToUpdate,
    loading,
  };
};
