import React from 'react';
import { Paper, Grid, Typography, Button } from '@material-ui/core';
import useGlobalStyles from 'styles';
import { Properties } from './types';
import { useStyles } from './styles';

const SavedContract: React.FC<Properties> = ({ contract, year }) => {
  const styles = useStyles();
  const classes = useGlobalStyles();

  const viewContract = () => {
    if (contract?.document) {
      window.open(contract.document.path, 'name', 'width=800,height=600');
    }
  };

  return (
    <Paper className={styles.paper}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={styles.container}
      >
        <Grid item>
          <Typography variant="body1">
            <b>Contrato - {year}</b>
          </Typography>
          <Typography variant="caption">
            Vigência - Durante a prestação de serviço do ano letivo.
          </Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={viewContract}
            color="primary"
            className={classes.textButton}
          >
            Ver contrato {contract.signDate ? '' : '(Não assinado)'}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SavedContract;
