import React from 'react';
import { Box } from '@material-ui/core';
import { PublicPageContainer } from 'components/PublicPageContainer';
import { PublicPageHeader } from 'components/PublicPageHeader';
import { useValidateUserController } from './controller';
import { ButtonToLogin } from './styles';
import * as messages from './messages';

export const ValidateUser = () => {
  const { verificationError, pushToLogin, memoPadding } =
    useValidateUserController();

  const titleSuccess = !verificationError && (
    <>
      E-mail <b>verificado!</b>{' '}
    </>
  );

  const titleErrorBoldComplement =
    verificationError && messages.VALIDATION_ERROR_TITLE;

  const subtitle = !verificationError
    ? messages.VALIDATION_SUCCESS
    : messages.VALIDATION_ERROR;

  return (
    <PublicPageContainer>
      <Box pl={memoPadding.pl} pr={memoPadding.pr}>
        <PublicPageHeader
          title={titleSuccess}
          titleBoldComplement={titleErrorBoldComplement}
          subtitle={subtitle}
        />
        <ButtonToLogin
          text="Continuar para o Portal dos pais"
          action={pushToLogin}
        />
      </Box>
    </PublicPageContainer>
  );
};
