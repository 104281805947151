import Dropdown from 'components/HeaderBar/Dropdown';
import { FC } from 'react';
import { ButtonInvoiceActionProps } from './types';
import { useButtonInvoiceActionsController } from './useButtonInvoiceActionsController';

export const ButtonInvoiceActions: FC<ButtonInvoiceActionProps> = ({
  invoiceData,
}) => {
  const { menuItems, isActionsDisabled } = useButtonInvoiceActionsController({
    invoiceData,
  });

  return (
    <Dropdown
      text="Ações"
      menuItems={menuItems}
      disabled={!menuItems.length || isActionsDisabled}
      // FIXME: Actions item can broken line in small screens
      maxItems={7}
    />
  );
};
