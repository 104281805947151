import React from 'react';
import { ChevronRight } from '@material-ui/icons';
import { Box, Grid, Paper, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import { IIndicatorButtonProps } from './types';

export const IndicatorButton = ({
  title,
  titleBoldSufix,
  description,
  onClick,
}: IIndicatorButtonProps) => {
  const styles = useStyles();

  return (
    <Grid item md={4} sm={8}>
      <Paper className={styles.paper} onClick={onClick}>
        <Box display="flex" justifyContent="space-between" paddingBottom="20px">
          <Typography className={styles.title}>
            {title} <b>{titleBoldSufix}</b>
          </Typography>
          <ChevronRight className={styles.icon} />
        </Box>
        <Typography className={styles.description}>{description}</Typography>
      </Paper>
    </Grid>
  );
};
