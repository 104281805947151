import { CreateQueryParams } from '@nestjsx/crud-request';
import {
  api,
  getAxiosResponseMessageErrorOrDefault,
  getWithFilter,
} from 'services/apiService';
import {
  CountOlderBrothersAndCountRegistrationsResponse,
  Dependent,
} from 'types/dependent';

export default class DependentService {
  static async dependents() {
    try {
      return await api.get('dependents');
    } catch (error: any) {
      return error.response;
    }
  }

  static async dependent(id: number) {
    try {
      return await api.get(`dependents/${id}`);
    } catch (error: any) {
      return error.response;
    }
  }

  static async createDependent(
    dependent: Partial<Dependent>,
    createdByUserId: number,
  ) {
    try {
      return await api.post('dependents', { ...dependent, createdByUserId });
    } catch (error: any) {
      return error.response;
    }
  }

  static async updateDependent(dependent: Partial<Dependent>, id: number) {
    try {
      return await api.patch(`dependents/${id}`, dependent);
    } catch (error) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }

  static async queryDependent(id: number, query?: CreateQueryParams) {
    try {
      return await getWithFilter(`/dependents/${id}`, query);
    } catch (error: any) {
      return error.response;
    }
  }

  static async queryDependents(query?: CreateQueryParams) {
    try {
      return await getWithFilter('/dependents', query);
    } catch (err: any) {
      return err.response;
    }
  }

  static async attachLegalGuardian(dependentId: number, guardianId: number) {
    try {
      return await api.put(
        `dependents/${dependentId}/legal-guardians/${guardianId}/attach`,
      );
    } catch (error: any) {
      return error.response;
    }
  }

  static async removeLegalGuardian(dependentId: number, guardianId: number) {
    try {
      return await api.put(
        `dependents/${dependentId}/legal-guardians/${guardianId}/remove`,
      );
    } catch (error: any) {
      return error.response;
    }
  }

  static checkOlderBrothersWithActiveEnrollment(
    dependentId: number | string,
    referenceYear: number,
  ) {
    try {
      return api.get<CountOlderBrothersAndCountRegistrationsResponse>(
        `dependents/${dependentId}/check-older-brothers-with-active-enrollment`,
        {
          params: {
            registrationReferenceYear: referenceYear,
          },
        },
      );
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }
}
