import { useHistory } from 'react-router-dom';

export const usePartnersCreationHomeController = () => {
  // Navigation
  const history = useHistory();

  // Callbacks
  const goToCreatePartner = () => {
    history.push('/adm-marketplace/partners/new-partners/create-partner');
  };
  const goToGenerateCode = () => {
    history.push('/adm-marketplace/partners/new-partners/generate-code');
  };
  return {
    goToCreatePartner,
    goToGenerateCode,
  };
};
