import React from 'react';
import loginImg from 'assets/loginNoLogo.png';
import logo from 'assets/logoNoBG.png';
import { Box } from '@material-ui/core';

const SideImageAdmin: React.FC = () => {
  return (
    <Box>
      <img
        src={loginImg}
        style={{
          height: '100vh',
          position: 'fixed',
          right: 0,
          top: 0,
        }}
        alt="loginImg"
      />
      <img
        src={logo}
        style={{
          height: 85,
          position: 'absolute',
          right: 15,
          top: 15,
          zIndex: 2,
        }}
        alt="loginImg"
      />
    </Box>
  );
};

export default SideImageAdmin;
