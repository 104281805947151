import { yupResolver } from '@hookform/resolvers/yup';
import { ControllerSelectInput } from 'components/Forms/ControllerSelectInput/ControllerSelectInput';
import { FormDialog } from 'components/dialogs/FormDialog';
import { GenericDialogRef } from 'components/dialogs/GenericDigalog/types';
import { RefObject, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import RegistrationService from 'services/registrationService';
import { SchoolClassDetailsService } from 'services/schoolClassDetailsService';
import { useAppStore } from 'store';
import { REQUIRED_FIELD } from 'texts';
import { Dependent } from 'types/dependent';
import { SchoolClassDetails } from 'types/schoolClassDetails';
import * as Yup from 'yup';
import { shallow } from 'zustand/shallow';
import { getNextClassToReRegister } from './utils';

// FIXME: separe responsibilities
const selectSchoolClassDetailsSchemaValidator = Yup.object({
  schoolClassDetailsId: Yup.number()
    .typeError(REQUIRED_FIELD)
    .required(REQUIRED_FIELD),
});

type ReRegistrationFormValues = {
  schoolClassDetailsId: number;
};

type ReRegistrationFormDialogProps = {
  dialogRef: RefObject<GenericDialogRef>;
  dependent: Pick<Dependent, 'name' | 'id'>;
  currentSchoolClassId: number;
  onStartRegistrationEnrollment: (_registrationId: string) => Promise<void>;
};

export const ReRegistrationFormDialog = ({
  dialogRef,
  dependent,
  currentSchoolClassId,
  onStartRegistrationEnrollment,
}: ReRegistrationFormDialogProps) => {
  const { configurations, showAlert } = useAppStore(
    state => ({
      configurations: state.configurations.data,
      showAlert: state.alert.showAlert,
    }),
    shallow,
  );
  const [availableSchoolClasses, setAvailableSchoolClasses] = useState<
    SchoolClassDetails[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, errors, register, control, setValue } = useForm({
    resolver: yupResolver(selectSchoolClassDetailsSchemaValidator),
    reValidateMode: 'onSubmit',
    shouldFocusError: true,
  });

  useEffect(() => {
    const getAvailableSchoolClassDetails = async () => {
      try {
        if (!configurations?.enrollmentYear || !currentSchoolClassId) {
          setAvailableSchoolClasses([]);
          setValue('schoolClassDetailsId', undefined);
          return;
        }

        const response = await SchoolClassDetailsService.getMany({
          limit: 20,
          sort: {
            field: 'schoolClassId',
            order: 'ASC',
          },
          search: {
            $and: [
              {
                schoolClassId: {
                  $gt: currentSchoolClassId,
                },
                referenceYear: Number(configurations?.enrollmentYear),
                vindiProductId: {
                  $notnull: true,
                },
                vindiPlanId: {
                  $notnull: true,
                },
              },
            ],
          },
        });
        // Ajuste solicitado pela Lisiane (cliente) para apresentar apenas a próxima turma do dependente
        const firstResult = response.data.data.length
          ? response.data.data[0]
          : null;
        if (firstResult) {
          setAvailableSchoolClasses([firstResult]);
        }
      } catch (error: any) {
        showAlert({
          message: error?.message || 'Erro ao buscar turmas disponíveis',
          severity: 'error',
        });
      }
    };

    getAvailableSchoolClassDetails();
  }, [
    configurations?.enrollmentYear,
    currentSchoolClassId,
    setValue,
    showAlert,
  ]);

  const defaultNextClassId = useMemo(() => {
    const nextClass = getNextClassToReRegister(
      availableSchoolClasses,
      currentSchoolClassId,
    );
    return nextClass?.id;
  }, [availableSchoolClasses, currentSchoolClassId]);

  const handleReRegister = async (values: ReRegistrationFormValues) => {
    setIsLoading(true);
    try {
      // Essas duas chamadas podem ser uma só pelo backend
      const { data: newRegistration } =
        await RegistrationService.startReRegistration(Number(dependent.id), {
          schoolClassDetailsId: values.schoolClassDetailsId,
        });

      await onStartRegistrationEnrollment(String(newRegistration.id));
    } catch (error: any) {
      showAlert({
        severity: 'error',
        message:
          error.message ||
          'Não é possível realizar a matrícula de veterano deste dependente. Contate a administração.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormDialog
      ref={dialogRef}
      headerTitle={`Matrícula de veterano ${configurations?.enrollmentYear}.`}
      headerSubtitle={`Selecione a série/ano letivo que o ${dependent.name} irá ingressar.`}
      primaryButton={{
        text: 'Avançar',
        onClick: handleSubmit(handleReRegister),
        loading: isLoading,
      }}
      secondaryButton={{
        text: 'Voltar',
        onClick: dialogRef.current?.closeDialog,
        disabled: isLoading,
      }}
    >
      <ControllerSelectInput
        name="schoolClassDetailsId"
        label="Série"
        helperText="Série na qual o dependente será matriculado"
        disabled={!configurations?.enrollmentYear}
        // TODO: mover pro escopo da função
        options={availableSchoolClasses.map(({ id, schoolClassName }) => ({
          id,
          label: schoolClassName,
        }))}
        defaultValue={defaultNextClassId}
        register={register}
        control={control}
        errors={errors}
      />
    </FormDialog>
  );
};
