import { StatusCodes } from 'http-status-codes';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CouponService from 'services/couponService';
import useGlobalStyles from 'styles';
import { RouteParams } from 'types/routeParams';
import { ICouponDetail } from './types';

export const useCouponDetailController = () => {
  // Styles
  const classes = useGlobalStyles();

  // Navigation
  const history = useHistory();
  const { id: couponId } = useParams<RouteParams>();

  // States
  const [couponDetail, setCouponDetail] = useState<ICouponDetail>();

  // Callbacks
  const loadCouponDetail = useCallback(async (id: string) => {
    const { data: response, status } = await CouponService.couponDetail(
      Number(id),
    );

    if (status === StatusCodes.OK) {
      setCouponDetail(response);
    } else {
      history.push('/adm-coupons');
    }
  }, []);

  // Effects
  useEffect(() => {
    loadCouponDetail(couponId);
  }, [loadCouponDetail, couponId]);

  return {
    classes,
    couponDetail,
  };
};
