import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PartnersService from 'services/partnersService';
import ServicesService from 'services/servicesService';
import useGlobalStyles from 'styles';
import { EServicesSolicitationStatus } from '../Services/components/ServicesSolicitationsTable/types';
import { TPartner } from './components/PartnersTable/types';

export const usePartnersController = () => {
  // Styles
  const classes = useGlobalStyles();

  // Navigation
  const history = useHistory();

  // States
  const [serviceSolicitations, setServiceSolicitations] = useState([]);
  const [partners, setPartners] = useState<Array<TPartner>>([]);

  // Callbacks
  const navigateToPartnersCreation = () => {
    history.push('/adm-marketplace/partners/new-partners');
  };

  const getPublishServicesSolicitations = useCallback(async () => {
    const { data: response } = await ServicesService.getServices({
      search: {
        $or: [
          {
            'servicePublicationStatus.name': {
              $eq: EServicesSolicitationStatus.PUBLISHED,
            },
          },
          {
            'servicePublicationStatus.name': {
              $eq: EServicesSolicitationStatus.PENDING,
            },
          },
          {
            'servicePublicationStatus.name': {
              $eq: EServicesSolicitationStatus.REFUSED,
            },
          },
        ],
      },
      join: [['servicePublicationStatus']],
    });

    setServiceSolicitations(response.data);
  }, []);

  const getPartners = useCallback(async () => {
    const { data: response } = await PartnersService.getPartners({
      join: [['serviceCategory']],
    });

    if (response?.data) {
      setPartners(response.data);
    }
  }, []);

  useEffect(() => {
    getPublishServicesSolicitations();
  }, [getPublishServicesSolicitations]);

  useEffect(() => {
    getPartners();
  }, [getPartners]);

  return {
    classes,
    partners,
    serviceSolicitations,
    navigateToPartnersCreation,
  };
};
