import {
  Divider,
  MenuItem,
  MenuProps as IMenuProps,
  styled,
} from '@material-ui/core';

import { Theme } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CurrencyField from 'components/CurrencyField';

import {
  borderGray,
  secondaryMain,
  error as errorColor,
  primaryDark,
} from 'styles/theme';
import {
  GridServiceImageStyleProps,
  InputImageStyleProps,
  InputTextStyleProps,
} from './types';

export const PaperContainer = styled(Paper)(() => {
  return {
    borderRadius: 10,
    padding: '41px 31px',
    marginBottom: '95px',
    '& .field-header:not(:first-child)': {
      marginTop: 70,
    },
  };
});

export const InputText = styled(TextField)<Theme, InputTextStyleProps>(
  ({ fixedWidth, innerSelectedColor }) => {
    return {
      fontSize: '14px',
      width: fixedWidth ? '116px' : '100%',
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1.45px solid ${borderGray}`,
        fontSize: 16,
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
      },
      '& .MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock':
        {
          fontSize: '14px',
          height: '9px',
          marginTop: '-5px',
        },
      '& .MuiSelect-root ': innerSelectedColor
        ? {
            margin: 8,
            height: '0px',
            backgroundColor: innerSelectedColor,
          }
        : undefined,
      '& .material-icons': {
        marginRight: 15,
      },
      '& .MuiPopover-paper': {
        width: '20px !important',
      },
      '& .MuiSelect-select': {
        fontSize: '14px',
      },
      '& .MuiSelect-select:focus': {
        backgroundColor: innerSelectedColor || 'white',
      },
      '& .MuiRadio-root': {
        display: 'none',
      },
    };
  },
);

export const CustomCurrencyInput = styled(CurrencyField)(() => {
  return {
    fontSize: '14px',
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1.45px solid ${borderGray}`,
      fontSize: 16,
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock':
      {
        fontSize: '14px',
        height: '9px',
        marginTop: '-5px',
      },
  };
});

export const AvatarPlaceholderContainer = styled(Box)<
  Theme,
  InputImageStyleProps
>(({ imageUrl }) => {
  return {
    border: '1.45px solid #ADB5BD80',
    borderRadius: '50%',
    width: '116px',
    height: '116px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundImage: `url("${imageUrl}")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
});

export const ImagePlaceholderContainer = styled(Box)<
  Theme,
  InputImageStyleProps
>(({ imageUrl, error }) => {
  return {
    border: `1.45px solid ${error ? errorColor : borderGray}`,
    borderRadius: 8,
    width: '100%',
    minHeight: '182.65px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    marginTop: 23,
    backgroundImage: `url("${imageUrl}")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
});

export const AvatarPlaceholderText = styled(Typography)(() => {
  return {
    fontSize: '0.625rem',
    opacity: 0.5,
    '&:first-child': {
      marginTop: 5,
      marginBottom: 15,
    },
  };
});

export const CameraButton = styled(ButtonBase)(() => {
  return {
    backgroundColor: secondaryMain,
    borderRadius: '50%',
    padding: 5,
    position: 'absolute',
    bottom: 0,
    transform: 'translateY(50%)',
  };
});

export const ContainerSiderGrid = styled(Grid)(() => {
  return {
    maxHeight: 300,
  };
});

export const YoutubeLinkGridItem = styled(Grid)(() => {
  return {
    marginTop: 50,
  };
});

export const ContainerSpacedGrid = styled(Grid)<
  Theme,
  GridServiceImageStyleProps
>(({ isMiddleGridItem }) => {
  if (isMiddleGridItem) {
    return {
      paddingLeft: 18,
      paddingRight: 18,
    };
  } else {
    return {};
  }
});

export const HiddenFileInput = styled('input')(() => {
  return {
    display: 'none',
  };
});

export const SelectMenuHeaderTitle = styled(Typography)(() => {
  return {
    fontSize: 14,
  };
});

export const SelectMenuItemContainer = styled(MenuItem)(() => {
  return {
    paddingBottom: '2px',
    paddingTop: '2px',
    alignItems: 'center',
    '&.Mui-selected': {
      backgroundColor: 'white !important',
    },
    '&#header': {
      width: '95%',
      marginLeft: 9,
      marginTop: 10,
      marginBottom: 10,
    },
    '&#header:hover': {
      backgroundColor: 'white !important',
    },
    '&#header .MuiTypography-displayBlock': {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '14px',
    },
    '&:not(#header) .MuiTypography-displayBlock': {
      fontSize: '14px',
      whiteSpace: 'normal',
    },
  };
});

export const SelectMenuItemDivider = styled(Divider)(() => {
  return {
    width: '85%',
    borderTop: '0.5px solid #979797',
    marginLeft: 25,
    marginBottom: 10,
  };
});

export const SelectMenuInputContainerColorDots = styled(Grid)(() => {
  return {
    '& .MuiSelect-outlined': {
      borderRadius: '8px',
    },
  };
});

export const SelectMenuContainerColorDots = styled('div')(() => {
  return {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gap: 3,
    width: 'fit-content',
    marginRight: 'auto',
    marginLeft: 'auto',
  };
});

export const SelectMenuColorDotBorder = styled('div')<
  Theme,
  { color: string; selectedColor: string }
>(({ color, selectedColor }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: '50%',
    padding: 2,
    border:
      color === selectedColor ? `2px solid ${color}` : `2px solid transparent`,
    '& .color-dot': {
      height: 30,
      width: 30,
      backgroundColor: color,
      borderRadius: '50%',
      cursor: 'pointer',
    },
  };
});

export const MenuProps = (width = 350): Partial<IMenuProps> => {
  return {
    getContentAnchorEl: null,
    PaperProps: {
      elevation: 0,
      style: {
        marginTop: '-5px',
        width,
        border: `1.45px solid ${borderGray}`,
        borderRadius: '8px',
        paddingBottom: '10px',
      },
    },
  };
};

export const ContainerButton = styled(ButtonBase)(() => {
  return {
    padding: '10px 20px',
    width: 'fit-content',
    height: '48px',
    background: primaryDark,
    borderRadius: '8px',
    fontWeight: 700,
    fontSize: 16,
    color: 'white',
    marginLeft: 10,
    minWidth: '130px',
  };
});

export const TextButton = styled(ButtonBase)(() => {
  return {
    padding: '10px 20px',
    minWidth: '167px',
    height: '48px',
    borderRadius: '8px',
    fontWeight: 700,
    fontSize: 16,
    color: primaryDark,
  };
});
