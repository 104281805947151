import {
  format,
  isAfter,
  isBefore,
  isValid,
  parse,
  startOfDay,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { error, success, warning } from 'styles/theme';
import { DateFormat } from 'types/dateFormat';
import { PAYMENT_METHODS, REGISTRATION_STATUS } from 'types/enums';
import { IRestVersusTotal } from 'types/restVersusTotal';

export const noNextStepStatus = [
  REGISTRATION_STATUS.BLOCKED,
  REGISTRATION_STATUS.CANCELLED,
  REGISTRATION_STATUS.PENDING_INTERVIEW,
  REGISTRATION_STATUS.PENDING_DOCUMENTS,
  REGISTRATION_STATUS.ACTIVE,
];

export const registrationStatusColors = (
  status: REGISTRATION_STATUS | string,
) => {
  const colors: Record<string, string> = {
    [REGISTRATION_STATUS.BLOCKED]: error,
    [REGISTRATION_STATUS.CANCELLED]: error,
    [REGISTRATION_STATUS.PENDING_INTERVIEW]: warning,
    [REGISTRATION_STATUS.PENDING_DOCUMENTS]: warning,
    [REGISTRATION_STATUS.PENDING_PAYMENT]: warning,
    [REGISTRATION_STATUS.CONTRACT_CREATED]: warning,
    [REGISTRATION_STATUS.OPEN]: success,
    [REGISTRATION_STATUS.ACTIVE]: success,
    [REGISTRATION_STATUS.RE_REGISTRATION]: success,
  };

  return status in colors ? colors[status] : 'lightgray';
};

export const formatTotalVersusRestCount = ({
  rest,
  total,
}: IRestVersusTotal) => {
  const swapTotal = total ?? 0;
  const swapRest = rest ?? swapTotal;

  return `${swapRest.toString().padStart(2, '0')}/ ${swapTotal
    .toString()
    .padStart(2, '0')}`;
};

export const verifyValidHourInterval = (hourInterval: string) => {
  if (!hourInterval) return false;

  const [start, end] = hourInterval.split('-');

  if (!start || !end) {
    return false;
  }

  const validStart =
    start.search(/^\d{2}:\d{2}$/) !== -1 &&
    Number(start.slice(0, 2)) >= 0 &&
    Number(start.slice(0, 2)) <= 24 &&
    Number(start.slice(3, 2)) >= 0 &&
    Number(start.slice(3, 2)) <= 59;

  const validEnd =
    end.search(/^\d{2}:\d{2}$/) !== -1 &&
    Number(end.slice(0, 2)) >= 0 &&
    Number(end.slice(0, 2)) <= 24 &&
    Number(end.slice(3, 2)) >= 0 &&
    Number(end.slice(3, 2)) <= 59;

  const startDate = new Date(
    2022,
    1,
    1,
    Number(start.split(':')[0]),
    Number(start.split(':')[1]),
  );

  const endDate = new Date(
    2022,
    1,
    1,
    Number(end.split(':')[0]),
    Number(end.split(':')[1]),
  );

  if (!validStart || !validEnd) {
    return false;
  }
  if (!isValid(startDate) || !isValid(endDate)) {
    return false;
  }

  if (isBefore(startDate, endDate)) {
    return true;
  } else {
    return false;
  }
};

export const formatDate = (data: DateFormat) => {
  const { date, inputFormat, outputFormat } = data;

  if (!date) return '-';

  let parsed = startOfDay(new Date(date));

  if (inputFormat) {
    parsed = parse(date, inputFormat, new Date());
  }

  return format(parsed, outputFormat || 'dd/MM/yyyy', {
    locale: ptBR,
  });
};

export const parseDate = (date: string, format?: string) => {
  return parse(date, format || 'dd/MM/yyyy', new Date());
};

export const formatBRL = (value: number) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return formatter.format(value);
};

export const unformatValueMask = (value: string) => {
  // regex para remover formatação de moeda e porcentagem
  const str = value.replace(/[R$.%\s]+/g, '');
  const number = Number(str.replace(',', '.'));
  return Number(number);
};

export const hasNoNumbers = (value?: string) => {
  if (value) {
    return !/^[0-9]+$/.test(value);
  }
  return false;
};

export const formatCPF = (cpf: string) => {
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

export const isStringDateValid = (date?: string) => {
  if (!date) return { isDateValid: false };
  const parsedDate = parse(date, 'P', new Date(), { locale: ptBR });

  return { isDateValid: isValid(parsedDate), parsedDate };
};

export const isBirthDateValid = (date?: string) => {
  if (!date) return false;
  const { isDateValid, parsedDate } = isStringDateValid(date);

  return isDateValid && isBefore(parsedDate || new Date(), new Date());
};

export const isDateIntervalValid = (startDate?: string, end_date?: string) => {
  const { parsedDate: parsedStartDate, isDateValid: isStartDateValid } =
    isStringDateValid(startDate);
  const { parsedDate: parsedEndDate, isDateValid: isEndDateValid } =
    isStringDateValid(end_date);

  if (!isStartDateValid || !isEndDateValid) return false;

  return isAfter(parsedEndDate!, parsedStartDate!);
};

export const isRegistrationNumberValid = (number?: string) => {
  number = trimWhiteSpaces(number);
  if (number) {
    if (number.length !== 8) return false;
    return /^\d+$/.test(number);
  }
  return true;
};

export const trimWhiteSpaces = (value?: string) => {
  if (value) return value.replace(/\s+/g, '');
  return undefined;
};

export const trimSpecialCharacters = (value?: string) => {
  if (value) return value.replace(/\D+/g, '');
  return undefined;
};

export const testPasswordStrength = (password?: string) => {
  if (password) {
    if (password.length < 8) return false;
    if (!/[^A-Za-z0-9]/g.test(password)) return false; // has no special characters
    if (!/[A-Z]/g.test(password)) return false; // has no capital letters
    if (!/[0-9]/g.test(password)) return false; // has no numbers
    return true;
  }
  return false;
};

export const monthsArray = [
  'Janeiro',
  'Feveiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const states = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
];

export const ROWS_PER_PAGE = [10, 25, 50, 100, 400];

export const formatDateToISO = (date: string) => {
  return date.split('/').reverse().join('-');
};

export const formatCurrencyValueToNumber = (value: string) => {
  return Number(value.split('R$ ')[1].replaceAll('.', '').replace(',', '.'));
};

export const COLOR_LIST = [
  '#1F3F6D',
  '#953132',
  '#2D4017',
  '#7D7743',
  '#46435B',
  '#7D3343',
  '#000000',

  '#376EBD',
  '#CB4F1C',
  '#57782E',
  '#C6C17F',
  '#514986',
  '#C8576F',
  '#535353',

  '#71D0F6',
  '#EC9E7E',
  '#8FC74A',
  '#F4E580',
  '#5648CE',
  '#F45A7C',
  '#AAAAAA',

  '#DFF6FF',
  '#EEE0DA',
  '#7FD38F',
  '#F5EBA7',
  '#9D98CA',
  '#EF95A9',
  '#E6E7E8',
];

export const getIdFromYoutubeVideo = (url = '') => {
  if (!url) return '';

  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : '';
};

export const capitalizeFirstLetter = (text: string) => {
  if (!text) return '';

  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const formatPaymentMethodCode = (paymentMethodCode: PAYMENT_METHODS) => {
  if (!paymentMethodCode) return PAYMENT_METHODS.NOT_DEFINED;

  switch (paymentMethodCode) {
    case PAYMENT_METHODS.BANK_SLIP:
      return 'Boleto bancário';
    case PAYMENT_METHODS.CARD:
      return 'Cartão de crédito';
    case PAYMENT_METHODS.PIX:
      return 'Pix';
    case PAYMENT_METHODS.CASH:
      return PAYMENT_METHODS.CASH;
    default:
      return paymentMethodCode;
  }
};
