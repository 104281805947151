import ListItemText from '@material-ui/core/ListItemText';
import { useSelectInputController } from './controller';
import {
  InputText,
  SelectMenuItemContainer,
  SelectMenuItemDivider,
} from './styles';
import { SelectInputProps } from './types';

export const SelectInput = ({
  name,
  label,
  options,
  defaultValue,
  selectProps,
  onChange,
  value,
  textFieldProps = {},
  isMultiple = false,
  error = false,
  helperText,
  disabled,
}: SelectInputProps) => {
  const { isChecked, SelectComponent, renderValue, parsedDefaultValue } =
    useSelectInputController({
      isMultiple,
      options,
      defaultValue,
    });

  return (
    <InputText
      select
      SelectProps={{
        multiple: isMultiple,
        ...selectProps,
        renderValue,
      }}
      id={name}
      name={name}
      label={label}
      value={value}
      variant="outlined"
      defaultValue={parsedDefaultValue}
      onChange={onChange}
      helperText={helperText}
      error={error}
      disabled={disabled}
      {...textFieldProps}
    >
      <SelectMenuItemContainer
        id="header"
        key="header"
        value={undefined}
        disabled
      >
        <ListItemText>{label}</ListItemText>
      </SelectMenuItemContainer>
      <SelectMenuItemDivider />
      {options.map(optionItem => {
        return (
          <SelectMenuItemContainer key={optionItem.id} value={optionItem.id}>
            <SelectComponent checked={isChecked(value, optionItem)} />
            <ListItemText>{optionItem.label}</ListItemText>
          </SelectMenuItemContainer>
        );
      })}
    </InputText>
  );
};
