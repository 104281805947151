import { Address } from 'types/address';
import { Dependent } from 'types/dependent';
import { REGISTRATION_STATUS } from 'types/enums';

export const dependentFields = (dependent: Dependent) => [
  {
    label: 'Nome completo',
    value: dependent.name,
    name: 'name',
  },
  {
    label: 'Data de nascimento',
    value: dependent.birthday,
    name: 'birthday',
  },
  {
    label: 'CPF',
    value: dependent.cpf,
    name: 'cpf',
  },
  {
    label: 'RG',
    value: dependent.rg.rgNumber,
    name: 'rg.rgNumber',
  },
  {
    label: 'Órgão Emissor',
    value: dependent.rg.emitter,
    name: 'rg.emitter',
  },
  {
    label: 'Nome da mãe',
    value: dependent.mother,
    name: 'mother',
  },
  {
    label: 'Naturalidade',
    value: dependent.birthplace,
    name: 'birthplace',
  },
  {
    label: 'Colégio de origem',
    value: dependent.previousSchool,
    name: 'previousSchool',
  },
  {
    label: 'Número',
    value: dependent.address.number,
    name: 'address.number',
  },
  {
    label: 'Complemento',
    value: dependent.address.complement || '---',
    name: 'address.complement',
  },
];

export const nonEditableAddressFields = (
  dependent: Dependent,
  address: Partial<Address>,
) => [
  {
    label: 'Rua',
    defaultValue: dependent.address.street,
    value: address.street,
  },
  {
    label: 'Cidade',
    defaultValue: dependent.address.city,
    value: address.city,
  },
  {
    label: 'Estado',
    defaultValue: dependent.address.state,
    value: address.state,
  },
];

// registration status that can't be manually set
export const blockedStatusChangeTo = [
  REGISTRATION_STATUS.AWAITING_PAYMENT,
  REGISTRATION_STATUS.CONTRACT_CREATED,
  REGISTRATION_STATUS.TRANSFERRED,
  REGISTRATION_STATUS.EXPIRED_PIX,
  REGISTRATION_STATUS.GENERATED_PIX,
  REGISTRATION_STATUS.ACTIVE,
];

// Os status desabilitados não poderão mais ser atribuidos a uma matrícula.
// Motivo: Ficar de acordo com o fluxo de status de matrícula.
// Se isso mudar: Favor revisar no backend registration.service.ts
// https://app.clickup.com/t/8678976k2 - Atualizado em 24/Jul/2023
export const REGISTRATION_STATUS_OPTIONS = [
  {
    label: REGISTRATION_STATUS.CANCELLED,
    isDisabled: false,
  },
  {
    label: REGISTRATION_STATUS.PENDING_INTERVIEW,
    isDisabled: false,
  },
  {
    label: REGISTRATION_STATUS.OPEN,
    isDisabled: false,
  },
  {
    label: REGISTRATION_STATUS.PENDING_DOCUMENTS,
    isDisabled: false,
  },
  {
    label: REGISTRATION_STATUS.ACTIVE,
    isDisabled: true,
  },
  {
    label: REGISTRATION_STATUS.BLOCKED,
    isDisabled: true,
  },
  {
    label: REGISTRATION_STATUS.RE_REGISTRATION,
    isDisabled: true,
  },
  {
    label: REGISTRATION_STATUS.PENDING_PAYMENT,
    isDisabled: true,
  },
  {
    label: REGISTRATION_STATUS.AWAITING_PAYMENT,
    isDisabled: true,
  },
  {
    label: REGISTRATION_STATUS.CONTRACT_CREATED,
    isDisabled: true,
  },
  {
    label: REGISTRATION_STATUS.GENERATED_PIX,
    isDisabled: true,
  },
  {
    label: REGISTRATION_STATUS.EXPIRED_PIX,
    isDisabled: true,
  },
  {
    label: REGISTRATION_STATUS.TRANSFERRED,
    isDisabled: true,
  },
];

// if a registration has a status from this array, it can't be changed anymore
export const blockedStatusChangeFrom = [REGISTRATION_STATUS.TRANSFERRED];

export const TRANSFER_MODAL_TITLE = `Alterar status da matrícula para ${REGISTRATION_STATUS.TRANSFERRED}?`;

export const BLOCKED_REGISTRATION_TITLE = (isBlocked: boolean) =>
  `${isBlocked ? 'Bloquear matrícula?' : 'Desbloquear matrícula?'}`;

export const TRANSFER_MODAL_SUBTITLE = (refund: boolean) =>
  `Esta ação não poderá ser desfeita. Todas as cobranças futuras serão canceladas ${
    refund ? ' e as que foram pagas serão estornadas' : ''
  }. Além disso, esse status não poderá mais ser alterado. Deseja continuar?`;

export const CONFIRM_MODAL_BTN = 'Sim, continuar';
