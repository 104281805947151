import { createMuiTheme } from '@material-ui/core';
import '@fontsource/open-sans';

export const white = '#fff';
export const success = '#83CDBD';
export const warning = '#EAC962';
export const error = '#CC4737';
export const transparent = 'rgba(0, 0, 0, 0)';
export const backgroundGray = '#f2f6fa';
export const whiteFont = '#E7F2F9';
export const gray = '#A7A7A7';
export const borderGray = '#ADB5BD';
export const basedGray = '#9B9B9B';
export const mediumGray = '#808080';
export const darkFont = '#363636';
export const darkGray = '#3C3C3B';
export const lightGray = '#F0F0F0';
export const lightBoxShadow = '0px 6px 32px rgba(9, 13, 27, 0.07)';
export const primaryLight = '#1378BF';
export const primaryMedium = '#2B60A1';
export const primaryMain = '#003C66';
export const primaryDark = '#003576';
export const primaryCardTitle = '#1A90FF';
export const secondaryMain = '#58EDC9';
export const infoMain = '#CED4DA';
export const purple = '#8858ED';
export const divideBorderColor = '#979797';
export const black = '#000';
export const red = '#D73D2F';
export const yellow = '#EDD558';

const mainTheme = createMuiTheme({
  palette: {
    primary: {
      light: primaryLight,
      main: primaryMain,
      contrastText: white,
    },
    secondary: {
      main: secondaryMain,
      contrastText: white,
    },
    info: {
      main: infoMain,
      contrastText: primaryMain,
    },
    success: {
      main: success,
      contrastText: primaryMain,
    },
    error: {
      main: error,
      contrastText: white,
    },
    warning: {
      main: warning,
      contrastText: primaryMain,
    },
  },

  typography: {
    fontFamily: 'Open Sans',
  },

  overrides: {
    MuiFormLabel: { root: { color: darkGray, fontSize: 14 } },
    MuiTableCell: {
      root: {
        borderBottom: 'none',
      },
    },
    MuiAccordion: {
      root: {
        disabled: {
          background: 'rgba(0, 0, 0, 0)',
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: 0,
        paddingTop: 38,
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: 0,
        borderBottom: '0.5px solid gray',
      },
    },
    MuiTypography: {
      h1: {
        fontSize: 50,
        fontWeight: 'bold',
      },
      h2: {
        fontSize: 50,
      },
      h3: {
        fontSize: 23,
        marginBottom: 30,
      },
      paragraph: {
        marginBottom: 15,
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: gray,
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: 670,
      },
    },
  },
});

export default mainTheme;
