export enum GUARDIAN_TYPES {
  LEGAL = 'Legal',
  FINANCIAL = 'Financeiro',
  BOTH = 'Legal e Financeiro',
}

export enum CIVIL_STATUS {
  SINGLE = 'Solteiro',
  DIVORCED = 'Divorciado',
  LEGALLY_SEPARATED = 'Separado judicialmente',
  MARRIED = 'Casado',
  STABLE_UNION = 'União Estável',
  WIDOW = 'Viúvo',
}

export enum DEPENDENT_RELATIONSHIP {
  FATHER = 'Pai',
  MOTHER = 'Mãe',
  GUARDIAN = 'Responsável legal',
}

export enum PAYMENT_TYPES {
  COMPLETE = 'Pagamento integral',
  SPLIT = 'Pagamento compartilhado',
}

export enum PAYMENT_METHODS {
  CARD = 'credit_card',
  BANK_SLIP = 'bank_slip',
  PIX = 'pix',
  CASH = 'Dinheiro',
  NOT_DEFINED = 'Não definido',
}

export enum FORM_OF_PAYMENT {
  CASH_PAYMENT = 'À vista',
  INSTALLMENTS = 'Parcelado',
}

export enum PAYMENT_INSTALLMENTS {
  CASH = 'Pagamento à vista',
  INSTALLMENTS = 'Pagamento parcelado',
}

// https://app.clickup.com/t/8678976k2
export enum REGISTRATION_STATUS {
  ACTIVE = 'Matrícula ativa',
  CANCELLED = 'Matrícula cancelada',
  PENDING_INTERVIEW = 'Entrevista pendente',
  OPEN = 'Matrícula em aberto',
  PENDING_DOCUMENTS = 'Documentos pendentes',
  /**
   * @deprecated use registration block action instead
   */
  BLOCKED = 'Necessário contato com a escola',
  /**
   * @deprecated use registration block action instead
   */
  DEFAULTING = 'Inadimplente',
  /**
   * @deprecated delete after migrate all deprecated status from production
   */
  RE_REGISTRATION = 'Matrícula veterano aberta',
  /**
   * @deprecated delete after migrate all deprecated status from production
   */
  PENDING_PAYMENT = 'Pagamento pendente',
  /**
   * @deprecated delete after migrate all deprecated status from production
   */
  AWAITING_PAYMENT = 'Aguardando pagamento',
  /**
   * @deprecated delete after migrate all deprecated status from production
   */
  CONTRACT_CREATED = 'Contrato emitido',
  /**
   * @deprecated delete after migrate all deprecated status from production
   */
  GENERATED_PIX = 'Pix gerado',
  /**
   * @deprecated use registration block action instead
   */
  EXPIRED_PIX = 'Pix vencido',
  /**
   * @deprecated use registration block action instead
   */
  TRANSFERRED = 'Desistência/Transferência',
}

export enum INTERVIEW_STATUS {
  TO_SCHEDULE = 'Entrevista solicitada',
  SCHEDULED = 'Entrevista agendada',
  REFUSED = 'Entrevista não aprovada',
  DONE = 'Entrevista aprovada',
}

export enum PAYMENT_STATUS {
  OPEN = 'Aberto',
  ERROR = 'Ocorreu um erro',
  PROCESSING = 'Em processamento',
  CANCELED = 'Cancelado',
  DEFAULTING = 'Inadimplente',
  CONCLUDED = 'Concluido',
  DRAFT = 'Rascunho',
  FUTURE = 'Agendado',
}

export enum BILL_STATUS {
  OPEN = 'Aberta',
  CLOSED = 'Fechada',
  CANCELED = 'Cancelada',
  BILLED = 'Faturada',
  REJECTED = 'Rejeitada',
  REFUNDED = 'Estornado',
  AWAITING_PAYMENT = 'Aguardando pagamento',
  EXPIRED_PIX = 'Pix vencido',
  DEFAULTING = 'Inadimplente',
}

export enum SCHOOL_GRADES {
  INFANT_II = 'Infantil II',
  INFANT_III = 'Infantil III',
  INFANT_IV = 'Infantil IV',
  INFANT_V = 'Infantil V',
  FIRST_FUNDAMENTAL = '1º Ano - Ensino Fundamental',
  SECOND_FUNDAMENTAL = '2º Ano - Ensino Fundamental',
  THIRD_FUNDAMENTAL = '3º Ano - Ensino Fundamental',
  FOURTH_FUNDAMENTAL = '4º Ano - Ensino Fundamental',
  FIFTH_FUNDAMENTAL = '5º Ano - Ensino Fundamental',
  SIXTH_FUNDAMENTAL = '6º Ano - Ensino Fundamental',
  SEVENTH_FUNDAMENTAL = '7º Ano - Ensino Fundamental',
  EIGHTH_FUNDAMENTAL = '8º Ano - Ensino Fundamental',
  NINTH_FUNDAMENTAL = '9º Ano - Ensino Fundamental',
  FIRST_MEDIUM = '1º Série - Ensino Médio',
  SECOND_MEDIUM = '2º Série - Ensino Médio',
  THIRD_MEDIUM = '3º Série - Ensino Médio',
}

export enum DOCUMENT_TYPE {
  CONTRATO_ASSINADO = 'Contrato',
  CERTIDAO_NASCIMENTO = 'Certidao',
  HISTORICO_ESCOLAR = 'Historico',
  DOCUMENTO_RESPONSAVEL = 'Responsavel',
  CARTEIRA_VACINACAO = 'Vacinacao',
  IMAGEM_PERFIL = 'Profile',
  DECLARACAO_CONVENIO = 'Convenio',
}

export enum REGISTRATION_CSV_FIELDS {
  YEAR = 'Ano',
  REGISTRATION = 'Matrícula',
  NAME = 'Nome do aluno',
  CLASS = 'Turma',
  INTERVIEW = 'Entrevista',
  STATUS = 'Status',
}

export enum FINANCIAL_CSV_FIELDS {
  YEAR = 'Ano',
  REG_DATE = 'Data matrícula',
  REGISTRATION = 'Matrícula',
  DEPENDENT = 'Aluno',
  CLASS = 'Turma',
  INSTALLMENTS = 'Nº Parcelas',
  VALUE = 'Valor total',
  PAYMENT_METHOD = 'Forma Pagto.',
  STATUS = 'Status',
}

export enum ALERT_COLORS {
  RED = 'error',
  GREEN = 'success',
  YELLOW = 'warning',
}

export enum USER_ROLES {
  ADMIN = 'Admin',
  COORDINATOR = 'Coordenação',
  TREASURY = 'Tesouraria',
  SECRETARY = 'Secretaria',
  GUARDIAN = 'Responsável',
  DEVELOPER = 'Desenvolvedor',
}

export enum COUPON_EVENT {
  DIRECTION = 'Direção',
  AABB = 'AABB',
  ASDEP = 'ASDEP',
  FBM = 'FBM',
  OLDER_BROTHER = 'Irmão mais velho',
  UNION = 'Sindical',
  AFISVEC = 'AFISVEC',
  ASOFBM = 'ASOFBM',
  CRA_RS = 'CRA/RS',
  DIPLANCO = 'DIPLANCO',
  MARKETING = 'MARKETING',
  PROPORCIONAL_INGRESSO = 'PROPORCIONAL INGRESSO',
  PROTOCOLO_ESPECIAL = 'PROTOCOLO ESPECIAL',
}

export enum BILL_OBSERVATION {
  EXPIRED_PIX = 'Pix expirou e foi cancelado automaticamente pelo sistema.',
  PIX_CANCELED_BY_SCHOOL = 'Pix cancelado pela escola',
}

export const GroupRole = {
  basicAdmin: [
    USER_ROLES.DEVELOPER,
    USER_ROLES.ADMIN,
    USER_ROLES.COORDINATOR,
    USER_ROLES.TREASURY,
  ],
  financial: [USER_ROLES.DEVELOPER, USER_ROLES.ADMIN, USER_ROLES.TREASURY],
};

export enum COUPON_SOURCE {
  REGISTRATION = 'Matrícula',
  SERVICE = 'Serviço',
}

export enum PAYMENT_SOURCE {
  REGISTRATION = 'MATRICULA',
  SERVICE = 'SERVICO',
}

// WARNING: update it in backend aswell
export enum SERVICE_MAJOR_TYPE {
  GENERAL_ACTIVITIES = 'Atividades',
  NON_RECURRING_ACTIVITIES = 'Atividades não recorrentes',
}
