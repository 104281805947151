import TextField from '@material-ui/core/TextField';
import { CustomButton } from 'components/CustomButton';
import { CustomIcon } from 'components/CustomIcon';
import { ReactNode } from 'react';
import { StyledActivityIndicator, StyledAutocomplete } from './styles';
import { BaseAutocompleteProps } from './types';
import { useBaseAutocompleteController } from './useBaseAutocompleteController';

const BaseAutocomplete = ({
  options,
  loading,
  onAction,
  onSelect,
  onChange,
  onEndTyping,
  triggerActionOnEnter,
  endIcon,
  freeSolo,
  searchLabel,
  delayAfterLastKeyStroke,
  initialValue,
  disabled,
}: BaseAutocompleteProps) => {
  const {
    text,
    handleOnAutocompleteChange,
    handleOnTextInputChange,
    handleOnKeyDown,
    handleOnClick,
    handleGetOptionLabel,
  } = useBaseAutocompleteController({
    onSelect,
    onChange,
    onAction,
    onEndTyping,
    triggerActionOnEnter,
    freeSolo,
    delayAfterLastKeyStroke,
    initialValue,
  });

  const renderInputPropsEndAdornment = (
    inputPropsEndAdornment: ReactNode,
    { endIcon, loading }: Pick<BaseAutocompleteProps, 'loading' | 'endIcon'>,
  ) => {
    if (!endIcon && loading) return <StyledActivityIndicator size={20} />;
    if (!endIcon) return inputPropsEndAdornment;

    return (
      <CustomButton
        variant="secondary"
        onClick={handleOnClick}
        disabled={loading}
      >
        <CustomIcon name={endIcon} />
      </CustomButton>
    );
  };

  return (
    <StyledAutocomplete
      hasEndIcon={!!endIcon}
      freeSolo={freeSolo}
      disableClearable
      options={options}
      getOptionLabel={handleGetOptionLabel}
      defaultValue=""
      value={text}
      noOptionsText="Nenhum resultado encontrado"
      onChange={handleOnAutocompleteChange}
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          label={searchLabel || 'Digite aqui...'}
          variant="outlined"
          onChange={handleOnTextInputChange}
          onKeyDown={handleOnKeyDown}
          InputProps={{
            ...params.InputProps,
            endAdornment: renderInputPropsEndAdornment(
              params.InputProps.endAdornment,
              { endIcon, loading },
            ),
          }}
        />
      )}
    />
  );
};

export default BaseAutocomplete;
