import { Grid } from '@material-ui/core';
import BlueCardInformations from 'components/BlueCardInformations';
import { CustomButton } from 'components/CustomButton';
import CustomLoadingDialog from 'components/CustomLoadindDialog';
import { FixedFooter } from 'components/FixedFooter';
import Scaffold from 'components/Scaffold';
import React from 'react';
import { PAYMENT_METHODS } from 'types/enums';
import { Payment } from 'types/payment';
import CardPaymentCreditCard from './components/CardPaymentCreditCard';
import PaymentBankslip from './components/PaymentBankslip';
import PaymentPix from './components/PaymentPix';
import { useStyles } from './styles';
import { useMarketplaceOrderPaymentsController } from './useMarketplaceOrderPaymentsController';

const MarketplaceOrderPayments: React.FC = () => {
  const styles = useStyles();
  const { loadingPage, loadingDialog, orderDetails, handleGoToPayments } =
    useMarketplaceOrderPaymentsController();

  const renderPayment = (payment: Payment) => {
    switch (payment.paymentMethodCode) {
      case PAYMENT_METHODS.PIX:
        return (
          <PaymentPix
            key={payment.id}
            payment={payment}
            bill={payment.bills?.[0]}
          />
        );
      case PAYMENT_METHODS.BANK_SLIP:
        return (
          <PaymentBankslip
            key={payment.id}
            payment={payment}
            bill={payment.bills?.[0]}
          />
        );
      case PAYMENT_METHODS.CARD:
        return (
          <CardPaymentCreditCard
            key={payment.id}
            payment={payment}
            bill={payment.bills?.[0]}
          />
        );
      default:
        return <p>{payment.paymentMethodCode}</p>;
    }
  };

  return (
    <Scaffold
      rawTitle="Contratação de serviços"
      rawSubtitle="Aguardando confirmação de pagamento."
      loading={loadingPage}
    >
      <BlueCardInformations
        title="FALTA POUCO"
        descriptionText={
          <>
            Estamos processando a sua solicitação.{' '}
            <strong>Assim que o pagamento for confirmado,</strong> o processo de
            contratação será concluído.
          </>
        }
      />
      <Grid container spacing={4} className={styles.container}>
        {orderDetails.payments?.map(renderPayment)}
      </Grid>

      <CustomLoadingDialog loading={loadingDialog} />

      <FixedFooter>
        <CustomButton variant="secondary" onClick={handleGoToPayments}>
          Ver pagamentos
        </CustomButton>
      </FixedFooter>
    </Scaffold>
  );
};

export default MarketplaceOrderPayments;
