import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { MaskProperties } from './types';
import { percentMaskOptions } from './utils';

const PercentInput = (props: MaskProperties) => {
  const { inputRef, ...other } = props;
  const percentMask = createNumberMask({
    ...percentMaskOptions,
  });

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={percentMask}
    />
  );
};

export default PercentInput;
