import { useHistory } from 'react-router-dom';
import useGlobalStyles from 'styles';
import { TABLE_HEADERS } from 'utils/tableHeaders';
import { useStyles } from './styles';

const headers = [
  TABLE_HEADERS.ID,
  TABLE_HEADERS.SERVICE_NAME,
  TABLE_HEADERS.SERVICE_CLASS,
  TABLE_HEADERS.CODE,
  TABLE_HEADERS.CODE_WAS_USED,
  TABLE_HEADERS.WHO_USED,
];

export const useServiceCodesTableController = () => {
  // Styles
  const classes = useGlobalStyles();
  const styles = useStyles();

  // Navigation
  const history = useHistory();

  // Callbacks
  const navigateToDetail = (id: number) => {
    history.push(`/adm-service-codes/${id}`);
  };

  return {
    classes,
    styles,
    headers,
    navigateToDetail,
  };
};
