import React from 'react';

import {
  Box,
  Grid,
  MenuItem,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { TablePaper } from 'components/TablePaper';

import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { ChevronRight } from '@material-ui/icons';
import { TABLE_HEADERS } from 'utils/tableHeaders';
import { DetailsButton, EditButton, TableBodyContainer } from '../../styles';
import { useServiceClassesDataTableController } from './controller';
import { ServiceClassesTextField } from './styles';

export const ServiceClassesDataTable = ({ serviceId = 0 }) => {
  const {
    headers,
    classes,
    fontColorBasedOnAvailableSpots,
    goToServiceClassDetail,
    goToServiceClassUpdate,
    total,
    page,
    rowsPerPageOptions,
    rowsPerPage,
    serviceClasses,
    handleChangePage,
    handleChangeRowsPerPage,
    selectProps,
    schoolGradeOptions,
    handleChangeYearOrGrade,
  } = useServiceClassesDataTableController(serviceId);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <ServiceClassesTextField
            label="Ano / Série"
            variant="outlined"
            select
            onChange={handleChangeYearOrGrade}
            SelectProps={selectProps}
          >
            <MenuItem value={undefined}>Todas</MenuItem>
            {schoolGradeOptions.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </ServiceClassesTextField>
        </Grid>
      </Grid>

      <TablePaper isEmpty={serviceClasses.length === 0}>
        <TableHead>
          <TableRow>
            {headers.map(header => (
              <TableCell key={header}>
                <Typography className={classes.listScreenHeaderText1}>
                  {header}
                </Typography>
              </TableCell>
            ))}
            <TableCell align="right">
              <Typography className={classes.listScreenHeaderText1}>
                {TABLE_HEADERS.ACTIONS}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBodyContainer>
          <Box height={20} />
          {serviceClasses.map(
            ({ id, name, spotsNumber, spotsFilled, schoolClasses }) => {
              return (
                <TableRow key={id}>
                  <TableCell>
                    {(schoolClasses || []).map(({ name }) => name).join(', ')}
                  </TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell
                    style={{
                      color: fontColorBasedOnAvailableSpots(spotsNumber),
                    }}
                  >
                    {`${spotsFilled}/${spotsNumber}`}
                  </TableCell>
                  <TableCell align="right">
                    <EditButton
                      disableTouchRipple
                      onClick={() => goToServiceClassUpdate(id)}
                    >
                      <CreateOutlinedIcon />
                    </EditButton>
                    <DetailsButton
                      disableTouchRipple
                      onClick={() => goToServiceClassDetail(id)}
                    >
                      Ver detalhes
                      <ChevronRight color="primary" />
                    </DetailsButton>
                  </TableCell>
                </TableRow>
              );
            },
          )}
        </TableBodyContainer>
      </TablePaper>
      {serviceClasses.length ? (
        <TablePagination
          component="div"
          labelRowsPerPage="Itens por página"
          count={total}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPageOptions={rowsPerPageOptions}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </>
  );
};
