import React, { useState, useRef, memo } from 'react';
import {
  Publish,
  Check,
  Close,
  DeleteOutline,
  Visibility,
} from '@material-ui/icons';
import { Box, IconButton, LinearProgress, Typography } from '@material-ui/core';
import { StatusCodes } from 'http-status-codes';
import axios, { Canceler } from 'axios';
import { api } from 'services/apiService';
import RegistrationService from 'services/registrationService';
import useGlobalStyles from 'styles';
import { UploadedDocument } from 'types/uploadedDocument';
import { FileUploadProperties } from './types';

const FileUploader: React.FC<FileUploadProperties> = memo(
  ({ registrationId, title, type, savedDocument }) => {
    const classes = useGlobalStyles();
    const [percentage, setPercentage] = useState(savedDocument ? 100 : 0);
    const [uploadedDocument, setUploadedDocument] = useState<
      UploadedDocument | undefined
    >(savedDocument);
    const { CancelToken } = axios;
    const cancelFileUpload = useRef<Canceler>();

    const uploadFile = async (file: File | null) => {
      if (file) {
        const data = new FormData();
        data.append('name', 'documento');
        data.append('type', type);
        data.append('file', file);

        const options = {
          onUploadProgress: (progressEvent: ProgressEvent) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            setPercentage(percent);
          },
          cancelToken: new CancelToken(
            cancel => (cancelFileUpload.current = cancel),
          ),
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };

        try {
          const response = await api.put(
            `registrations/${registrationId}/file`,
            data,
            options,
          );
          setUploadedDocument(response.data);
        } catch (error: any) {
          setPercentage(0);
        }
      }
    };

    const cancelUpload = () => {
      if (cancelFileUpload.current) cancelFileUpload.current('');
    };

    const deleteFile = async () => {
      if (uploadedDocument) {
        const response = await RegistrationService.deleteDocument(
          registrationId,
          uploadedDocument.id,
        );
        if (response.status === StatusCodes.OK) {
          setPercentage(0);
          setUploadedDocument(undefined);
        }
      }
    };

    const handleViewDocument = (path: string) => () => {
      window.open(path);
    };

    return (
      <Box display="flex" alignItems="center" p={1}>
        <Box p={1} style={{ width: 245 }}>
          <Typography>{title}</Typography>
        </Box>
        <Box p={1} flexGrow={1}>
          <LinearProgress
            classes={{ root: classes.registrationProgress }}
            color="secondary"
            variant="determinate"
            value={percentage}
            style={{ width: '100%' }}
          />
        </Box>
        <Box p={1}>
          {uploadedDocument ? (
            <IconButton
              color="default"
              size="small"
              component="span"
              onClick={handleViewDocument(uploadedDocument.path)}
            >
              <Visibility />
            </IconButton>
          ) : (
            <>
              <input
                className={classes.hidden}
                id={`${type}-upload`}
                type="file"
                onChange={e =>
                  uploadFile(e.target.files ? e.target.files[0] : null)
                }
              />
              <label htmlFor={`${type}-upload`}>
                <IconButton
                  disabled={percentage > 0}
                  color="default"
                  size="small"
                  component="span"
                >
                  <Publish />
                </IconButton>
              </label>
            </>
          )}
        </Box>
        <Box p={1}>
          <IconButton
            disabled={percentage === 100 || percentage === 0}
            onClick={() => cancelUpload()}
            color="default"
            size="small"
          >
            {percentage === 100 ? <Check /> : <Close />}
          </IconButton>
        </Box>
        <Box p={1}>
          <IconButton
            disabled={percentage !== 100}
            onClick={() => deleteFile()}
            color="default"
            size="small"
          >
            <DeleteOutline />
          </IconButton>
        </Box>
      </Box>
    );
  },
);

export default FileUploader;
