import { ServiceCategory } from 'pages/Client/MarketplaceServicesDetail/types';

export enum EServiceStatus {
  SOLD_OUT = 'SEM VAGAS',
  NOT_PUBLISHED = 'SEM PUBLICAR',
  PUBLISHED = 'PUBLICADO',
}

export type VacanciesCellStylesProps = {
  isOut?: boolean;
};

export type LabelStyledProps = {
  backgroundColor: string;
  color: string;
};

export type SchoolGrade = {
  name: string;
};

export type TService = {
  id: number;
  name: string;
  schoolClasses: Array<SchoolGrade>;
  remainingSpots: number;
  numberOfSpots: number;
  servicePublicationStatus: {
    name: string;
  };
  serviceType: {
    name: string;
  };
  serviceCategories: ServiceCategory[];
  partner: {
    name: string;
  };
};

export interface IServiceTableProps {
  services: Array<TService>;
}
