import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import { TransitionProps } from '@material-ui/core/transitions';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useGlobalAlertController } from './useGlobalAlertCardController';

// FIXME: many alert card triggers override each other
const GlobalAlertCard = () => {
  const { config, closeAlert, alertTitle } = useGlobalAlertController();

  if (!config) return null;

  const TransitionUp = (props: TransitionProps) => (
    <Slide {...props} direction="up" />
  );

  return (
    <Snackbar
      open={!!config}
      autoHideDuration={config.durationInMs || 10000}
      onClose={closeAlert}
      TransitionComponent={TransitionUp}
    >
      <Alert onClose={closeAlert} severity={config.severity}>
        {alertTitle ? (
          <AlertTitle>
            <b>{alertTitle}</b>
          </AlertTitle>
        ) : null}
        {config.message}
      </Alert>
    </Snackbar>
  );
};

export default GlobalAlertCard;
