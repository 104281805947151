import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { COLOR_LIST } from 'utils';
import { FileState, IExtraDataFormControllerProps } from './types';
import { verifyImageRestrictions } from './utils';

import { MenuProps as MenuPropsStructure } from '../../styles';
import { ImagesNames } from '../../types';

export const useExtraDataFormController = ({
  setValue,
  setImagesValues,
  defaultColor,
}: IExtraDataFormControllerProps) => {
  // Refs
  const avatarInputRef = useRef<HTMLInputElement>(null);
  const coverInputRef = useRef<HTMLInputElement>(null);
  const serviceImagesRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  // States
  const [avatarFile, setAvatarFile] = useState<FileState>();
  const [coverFile, setCoverFile] = useState<FileState>();
  const [serviceImagesFiles, setServiceImagesFile] = useState<
    Array<Partial<FileState>>
  >([
    { file: undefined, url: undefined },
    { file: undefined, url: undefined },
    { file: undefined, url: undefined },
  ]);
  const [imageSizeError, setImageSizeError] = useState('');
  const [selectedColor, setSelectedColor] = useState(defaultColor);

  // Callbacks
  const onClickAvatarUpload = () => {
    avatarInputRef?.current?.click();
  };

  const onChangeAvatar = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files?.length) {
      const file = event.currentTarget.files![0];

      try {
        const url = await verifyImageRestrictions({ file });

        setAvatarFile({ file, url });
        if (setImagesValues) {
          setImagesValues(ImagesNames.Avatar, { file, url });
        }
      } catch (error) {
        setImageSizeError(error as string);
      }
    }
  };

  const onClickCoverUpload = () => {
    coverInputRef?.current?.click();
  };

  const onChangeCover = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files?.length) {
      const file = event.currentTarget.files![0];

      try {
        const url = await verifyImageRestrictions({ file });

        setCoverFile({ file, url });

        if (setImagesValues) {
          setImagesValues(ImagesNames.Cover, { file, url });
        }
      } catch (error) {
        setImageSizeError(error as string);
      }
    }
  };

  const onClickServiceImagesUpload = (imageIndex: number) => {
    serviceImagesRefs[imageIndex]?.current?.click();
  };

  const onChangeServiceImages = async (
    event: ChangeEvent<HTMLInputElement>,
    imageIndex: number,
  ) => {
    if (event.currentTarget.files?.length) {
      const file = event.currentTarget.files![0];

      try {
        const url = await verifyImageRestrictions({ file });

        const swapServiceImages = [...serviceImagesFiles];
        swapServiceImages[imageIndex].file = file;
        swapServiceImages[imageIndex].url = url;

        if (setImagesValues) {
          setImagesValues(ImagesNames.Images, swapServiceImages);
        }

        setServiceImagesFile(swapServiceImages);
      } catch (error) {
        setImageSizeError(error as string);
      }
    }
  };

  const clearImageSizeError = () => {
    setImageSizeError('');
  };

  const RedimensionedMenuProps = useCallback((width: number) => {
    return MenuPropsStructure(width + 1);
  }, []);

  const handleSelectColor = (color: string) => {
    if (setValue) {
      setValue('color', color);
    }
    setSelectedColor(color);
  };

  // Memo
  const MenuProps = RedimensionedMenuProps(350);

  return {
    avatarInputRef,
    coverInputRef,
    serviceImagesRefs,
    avatarFile,
    onChangeAvatar,
    onClickAvatarUpload,
    coverFile,
    onChangeCover,
    onClickCoverUpload,
    serviceImagesFiles,
    onClickServiceImagesUpload,
    onChangeServiceImages,
    imageSizeError,
    clearImageSizeError,
    MenuProps,
    selectedColor,
    handleSelectColor,
    colors: COLOR_LIST,
  };
};
