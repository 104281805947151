import { CondOperator, SCondition } from '@nestjsx/crud-request';
import {
  NestCrudTableFetchData,
  NestCrudTableFetchRequest,
} from 'components/NestCrudTable/types';
import { useAuth } from 'contexts/auth';
import { useAlert } from 'hooks/Alert';
import { useFilters } from 'hooks/filter';
import { StatusCodes } from 'http-status-codes';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import ContractService from 'services/contractService';
import useGlobalStyles from 'styles';
import { PAYMENT_STATUS } from 'types/enums';
import { useDebouncedCallback } from 'use-debounce';
import {
  DateParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { ROWS_PER_PAGE } from 'utils';
import { ContractTableData } from './ContractsTables/types';
import { handleDateFilter } from './contracts.utils';

export const useContractController = () => {
  const classes = useGlobalStyles();
  const [searchField, setSearchField] = useState('');

  const [query, setQuery] = useQueryParams({
    schoolYear: NumberParam,
    rows: withDefault(NumberParam, ROWS_PER_PAGE[0]),
    search: StringParam,
    grade: StringParam,
    paymentStatus: StringParam,
    start: withDefault(DateParam, null),
    end: withDefault(DateParam, null),
  });
  const { schoolYear, search, grade, paymentStatus, start, end } = query;

  const { closeAlert, alertMessage, alertType, isShowAlert } = useAlert();

  const { user } = useAuth();

  const [defaultFilters] = useState([
    {
      field: 'payment.status',
      value: paymentStatus,
      operator: CondOperator.EQUALS,
    },
    {
      field: 'payment.referenceGrade',
      value: grade,
      operator: CondOperator.EQUALS,
    },
    {
      field: 'payment.referenceYear',
      value: schoolYear,
      operator: CondOperator.EQUALS,
    },
  ]);

  const { filterFields, handleUpdateFilters, handleSetValuePage } =
    useFilters(defaultFilters);

  const paymentStatusFilter = useMemo(() => {
    const isAdmin = !!user?.isAdmin;

    const paymentStatuses = [PAYMENT_STATUS.DRAFT, PAYMENT_STATUS.ERROR];

    if (!isAdmin) {
      paymentStatuses.push(PAYMENT_STATUS.CANCELED);
    }

    return {
      'payment.status': {
        $notin: paymentStatuses,
      },
    };
  }, [user?.isAdmin]);

  const loadContracts: NestCrudTableFetchData<ContractTableData> = useCallback(
    async ({ page, perPage }: NestCrudTableFetchRequest) => {
      setSearchField(search || '');
      const { data: response, status } = await ContractService.filterContracts({
        page,
        limit: perPage,
        search: {
          $and: [
            ...filterFields,
            handleDateFilter(start, end),
            {
              signDate: {
                $notnull: true,
              },
            },
            paymentStatusFilter,
            {
              $or: [
                // as tabelas estão com esse nome por causa do alias no backend
                {
                  'paymentRegistration.number': {
                    $contL: search ?? undefined,
                  },
                },
                {
                  'paymentRegistrationDependent.name': {
                    $contL: search ?? undefined,
                  },
                },
              ],
            },
          ],
        } as SCondition,
        join: [
          {
            field: 'payment',
            select: [
              'id',
              'status',
              'registration',
              'referenceYear',
              'referenceGrade',
            ],
          },
          {
            field: 'payment.registration',
            select: ['id', 'dependent', 'number'],
          },
          {
            field: 'payment.registration.dependent',
            select: ['id', 'name'],
          },
          {
            field: 'document',
            select: ['id', 'path'],
          },
          {
            field: 'service',
            select: ['id', 'name'],
          },
        ],
      });

      if (status === StatusCodes.OK) {
        return {
          data: response.data,
          total: response.total,
        };
      } else {
        return {
          data: [],
          total: 0,
        };
      }
    },
    [search, filterFields, start, end, paymentStatusFilter],
  );

  const searchFieldDebounce = useDebouncedCallback((value: string) => {
    setQuery({ search: value });
  }, 2000);

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchField(e.target.value);
    searchFieldDebounce(e.target.value);
  };

  const handleChangeStartDate = (date: Date | null) => {
    if (date) setQuery({ start: date });
  };

  const handleChangeEndDate = (date: Date | null) => {
    if (date) setQuery({ end: date });
  };

  const handleChangePaymentStatus = (e: ChangeEvent<HTMLInputElement>) => {
    handleUpdateFilters(
      'payment.status',
      { value: e.target.value },
      CondOperator.EQUALS,
    );
    setQuery({ paymentStatus: e.target.value });
  };

  const handleChangeGrade = (e: ChangeEvent<HTMLInputElement>) => {
    handleUpdateFilters(
      'payment.referenceGrade',
      { value: e.target.value },
      CondOperator.EQUALS,
    );
    setQuery({ grade: e.target.value });
  };

  const handleChangeSchoolYear = (e: ChangeEvent<HTMLInputElement>) => {
    handleUpdateFilters(
      'payment.referenceYear',
      { value: e.target.value },
      CondOperator.EQUALS,
    );
    setQuery({ schoolYear: Number(e.target.value) });
  };

  const resetPeriod = () => {
    setQuery({ start: null, end: null });
  };

  return {
    classes,
    searchField,
    closeAlert,
    alertMessage,
    alertType,
    isShowAlert,
    handleSetValuePage,
    loadContracts,
    handleChangeSearch,
    handleChangeStartDate,
    handleChangeEndDate,
    handleChangePaymentStatus,
    handleChangeGrade,
    handleChangeSchoolYear,
    resetPeriod,
    schoolYear,
    paymentStatus,
    grade,
    start,
    end,
    query,
  };
};
