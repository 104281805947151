import { Box } from '@material-ui/core';
import AlertCard from 'components/AlertCard';
import { CustomIcon } from 'components/CustomIcon';
import PageHeader from 'components/PageHeader';
import { ServiceClassForm } from '../components/ServiceClassForm';
import { FormFooter } from '../components/ServiceClassForm/components/FormFooter';
import { Modal } from '../components/ServiceClassForm/components/Modal';
import {
  DISCART_OR_SAVE_WITHOUT_PUBLISHING,
  NOT_RECOVERABLE_DATA,
} from '../components/ServiceClassForm/components/Modal/messages';
import {
  ContainerButton,
  TextButton,
} from '../components/ServiceClassForm/styles';
import { useServiceClassUpdateController } from './controller';

export const ServiceClassUpdate = () => {
  const {
    handleSubmit,
    handleCancel,
    submit,
    control,
    errors,
    register,
    setValue,
    getValues,
    successModalOpen,
    cancelModalOpen,
    handleCloseModal,
    generalErrorMessageAlert,
    handleCloseScheduleError,
    serviceClassToUpdate,
    submitError,
    handleCloseSubmitError,
    loading,
    handleCloseModalAndExit,
  } = useServiceClassUpdateController();

  return (
    <>
      <Box paddingX="45px" position="relative">
        <PageHeader
          title="Editar"
          titleBoldSufix="turma"
          subtitle="Edite os dados de uma turma já cadastrada no marketplace, preenchendo "
          subtitleBold="todas as informações"
        />

        {serviceClassToUpdate && (
          <>
            <ServiceClassForm
              type="edit"
              control={control}
              errors={errors}
              register={register}
              setValue={setValue}
              getValues={getValues}
              serviceClassToUpdate={serviceClassToUpdate}
            />
            <FormFooter
              type="edit"
              onSubmit={handleSubmit(submit)}
              onCancel={handleCancel}
              loading={loading}
            />
          </>
        )}
      </Box>
      <Modal
        isOpen={successModalOpen}
        icon={<CustomIcon name="checkCircle" />}
        title={
          <>
            Alterações <b>publicadas</b> com sucesso!
          </>
        }
        subtitle="A turma foi publicada no marketplace e os responsáveis podem fazer a contratação."
        confirmButton={
          <ContainerButton onClick={handleCloseModal}>Fechar</ContainerButton>
        }
      />
      <Modal
        isOpen={cancelModalOpen}
        icon={<CustomIcon name="warningCheckCircle" />}
        subtitle={NOT_RECOVERABLE_DATA}
        title={DISCART_OR_SAVE_WITHOUT_PUBLISHING}
        cancelButton={
          <TextButton onClick={handleCloseModal}>Continuar </TextButton>
        }
        confirmButton={
          <ContainerButton onClick={handleCloseModalAndExit}>
            Sair
          </ContainerButton>
        }
      />
      <AlertCard
        message={generalErrorMessageAlert}
        open={!!generalErrorMessageAlert}
        close={handleCloseScheduleError}
        severity="error"
      />
      <AlertCard
        message={submitError}
        open={!!submitError}
        close={handleCloseSubmitError}
        severity="error"
      />
    </>
  );
};
