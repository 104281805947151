export abstract class BaseEntity {
  protected mapJsonToEntity(json: Record<string, any>) {
    Object.keys(json).forEach(key => {
      if (key in this) {
        // @ts-ignore
        this[key] = json[key];
      } else {
        console.warn(`${this.getClassName()}: key ${key} not mapped`);
      }
    });
  }

  protected getClassName() {
    return (<any>this).constructor.name;
  }
}
