import { makeStyles, styled } from '@material-ui/core';
import {
  lightBoxShadow,
  mediumGray,
  primaryDark,
  white,
  whiteFont,
} from 'styles/theme';

export const Container = styled('div')(() => {
  return {
    padding: '100px',
    // padding: '29px 20px',
  };
});

export const useStyles = makeStyles(theme => ({
  paper: {
    background: primaryDark,
    padding: '30px 50px',
    marginBottom: 20,
    borderRadius: 34,
    width: '100%',
    boxShadow: lightBoxShadow,
    [theme.breakpoints.down('sm')]: {
      padding: '20px 40px',
    },
  },
  headerField: {
    width: 350,
  },
  paperCard: {
    padding: '30px 50px',
    marginBottom: 20,
    borderRadius: 34,
    width: '100%',
    boxShadow: lightBoxShadow,
    [theme.breakpoints.down('sm')]: {
      padding: '30px 40px',
    },
  },
  selectionButton: {
    paddingRight: 25,
    paddingLeft: 25,
    paddingTop: 8,
    paddingBottom: 8,
    textTransform: 'initial',
    minWidth: 171,
    height: 50,
    border: '1.5px solid #004d83',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '10px',
    },
  },
  gridButtons: {
    display: 'flex',
    padding: 0,
    gap: '16px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '8px',
    },
  },
  selectedButton: {
    backgroundColor: whiteFont,
  },
  activeDetailPaper: {
    transform: 'translateY(34px)',
  },
  servicePaymentInfo: {
    color: mediumGray,
    marginTop: 5,
  },
  buttonText: {
    textTransform: 'none',
  },
  buttonWhite: {
    color: white,
  },
  arrowDetailButton: {
    fontSize: '40px',
    color: white,
  },
  mediumFont: {
    fontWeight: 600,
  },
  gridPaperContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '50px',
    [theme.breakpoints.down('sm')]: {
      padding: '30px',
    },
  },
  gridPaperDetail: {
    justifyContent: 'space-between',
    padding: '50px',
    [theme.breakpoints.down('sm')]: {
      padding: '30px',
    },
  },
  marginText: {
    marginTop: 5,
  },
  dividerMargin: {
    marginTop: '30px',
    marginBottom: '30px',
  },
  cartItemsDetail: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 10,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  boldText: {
    fontWeight: 'bold',
  },
  cartTotalDetailText: {
    fontSize: '32px',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '26px',
    },
  },
  formPaymentGrid: {
    marginTop: 60,
    marginBottom: 30,
  },
  formPaymentTextInfo: {
    color: mediumGray,
    marginTop: 5,
  },
  creditCardIcon: {
    marginRight: 5,
  },
  paymentMethodButton: {
    marginLeft: 15,
  },
  serviceContainer: {
    [theme.breakpoints.up('md')]: {
      gap: '40px',
    },
  },
  blueCardTotalValue: {
    color: white,
    fontSize: '26px',
    fontWeight: 'bold',
  },
  serviceComponentGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  columnEnd: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
}));
