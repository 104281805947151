import {
  SFields,
  CreateQueryParams,
  SConditionAND,
} from '@nestjsx/crud-request';
import { OnAutocompleteSelectItemCallback } from 'components/BaseAutocomplete/types';
import { ISelectItem } from 'components/Forms/SelectInput/types';
import { GetAutocompleteOptionLabelCallback } from 'components/SearchAutocomplete/types';
import { formatGrade } from 'pages/Admin/AdmRegistrations/utils';
import { useCallback, useMemo, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import {
  buildMarketplaceServiceDetailsRoute,
  buildMarketplaceServiceSearchResultsRoute,
} from 'routes/utils';
import { Service } from 'types/service';
import { MarketplaceFilteredSearchProps } from './types';
import {
  buildFilterServicesByServiceNameNameSearchCondition,
  buildFilterServicesByServiceClassSearchCondition,
  buildFilterServicesByServiceTypeSearchCondition,
  filterServicesByServiceClassJoinArray,
} from './utils';

export const useMarketplaceFilteredSearchController = ({
  selectedRegistrationSchoolClassId,
  registrations,
  filterByServiceTypeId,
  selectedRegistrationId,
  onSelectRegistrationId,
}: MarketplaceFilteredSearchProps) => {
  // ---------- Navigation ----------
  const history = useHistory();

  const handleOnSelectService: OnAutocompleteSelectItemCallback = useCallback(
    serviceOption => {
      if (selectedRegistrationId) {
        history.push(
          buildMarketplaceServiceDetailsRoute(
            selectedRegistrationId,
            serviceOption.id,
          ),
        );
      }
    },
    [history, selectedRegistrationId],
  );

  const handleOnSearchService = useCallback(
    (text: string) => {
      if (selectedRegistrationId) {
        history.push(
          buildMarketplaceServiceSearchResultsRoute({
            registrationId: selectedRegistrationId,
            searchText: text,
            serviceTypeId: filterByServiceTypeId,
          }),
        );
      }
    },
    [filterByServiceTypeId, history, selectedRegistrationId],
  );

  // ---------- Memos ----------
  const registrationOptions: ISelectItem[] = useMemo(() => {
    if (!registrations) return [];

    return registrations.map(registration => ({
      id: registration.id,
      label: `${registration.dependent.name} - ${formatGrade(
        registration.schoolClass.name,
      )}`,
    }));
  }, [registrations]);

  // ---------- Callbacks ----------
  const handleSelectRegistration = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onSelectRegistrationId(Number(target.value));
    },
    [onSelectRegistrationId],
  );

  const handleOnCreateQueryParamsCallback: (text: string) => CreateQueryParams =
    useCallback(
      text => {
        const searchAndArray: (SFields | SConditionAND)[] = [
          buildFilterServicesByServiceNameNameSearchCondition(text),
        ];

        if (selectedRegistrationSchoolClassId) {
          searchAndArray.push(
            buildFilterServicesByServiceClassSearchCondition(
              selectedRegistrationSchoolClassId,
            ),
          );
        }

        if (filterByServiceTypeId) {
          searchAndArray.push(
            buildFilterServicesByServiceTypeSearchCondition(
              filterByServiceTypeId,
            ),
          );
        }

        return {
          fields: ['id', 'name'],
          limit: 10,
          search: {
            $and: searchAndArray,
          },
          join: filterServicesByServiceClassJoinArray,
        };
      },
      [filterByServiceTypeId, selectedRegistrationSchoolClassId],
    );

  const handleGetOptionLabel: GetAutocompleteOptionLabelCallback<Service> =
    useCallback(option => option.name, []);

  return {
    // Search (services)
    handleOnSelectService,
    handleOnSearchService,
    handleOnCreateQueryParamsCallback,
    handleGetOptionLabel,

    // Select input (registrations/dependents)
    registrationOptions,
    handleSelectRegistration,
  };
};
