import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useGlobalStyles from 'styles';
import { HeaderProperties } from './types';

const PageHeader = ({
  title,
  titleBold,
  titleBoldPrefix,
  titleBoldSufix,
  subtitleSufix,
  subtitleBold,
  subtitle,
  button,
  testId,
  rawTitle,
  rawSubtitle,
}: HeaderProperties) => {
  const classes = useGlobalStyles();

  return (
    <Grid
      item
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      data-test={testId}
      className={classes.headerContainer}
    >
      <Grid item>
        <Typography className={classes.screenHeaderText}>
          {rawTitle || (
            <>
              <b>{titleBoldPrefix} </b>
              {title || <b>{titleBold}</b>}
              <b> {titleBoldSufix}</b>
            </>
          )}
        </Typography>

        <Typography className={classes.screenSubHeaderText}>
          {rawSubtitle || (
            <>
              {subtitle}
              <b>{subtitleBold}</b>
              {subtitleSufix}
            </>
          )}
        </Typography>
      </Grid>

      <Grid item>{button}</Grid>
    </Grid>
  );
};

export default PageHeader;
