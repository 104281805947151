import { differenceInCalendarDays } from 'date-fns';

type CalculateMaxInstallments = {
  servicePaymentLimitDate: Date;
  serviceMaxNumberInstallments: number;
  currentDate: Date;
};

// NOTE: If you change this function, you must also change in the backend too (src/marketplace/serviceClasses/utils/calculateMaxInstallments.ts)
export const calculateServiceClassMaxInstallments = (
  data: CalculateMaxInstallments,
) => {
  const { currentDate, serviceMaxNumberInstallments, servicePaymentLimitDate } =
    data;
  const daysDiff = differenceInCalendarDays(
    servicePaymentLimitDate,
    currentDate,
  );
  const monthMedia = 365.25 / 12;
  let monthsDiff = Math.round(daysDiff / monthMedia);

  if (monthsDiff <= 0) {
    monthsDiff = 1;
  }

  return Math.min(monthsDiff, serviceMaxNumberInstallments);
};
