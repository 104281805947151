import {
  ButtonBase,
  Divider,
  Grid,
  styled,
  TableBody,
  Theme,
} from '@material-ui/core';
import { infoMain, primaryDark, primaryMedium } from 'styles/theme';

export const PartnersHomeGridContainer = styled(Grid)(() => {
  return {
    marginBottom: '50px',
  };
});

export const CustomDivider = styled(Divider)(() => {
  return {
    height: '0.5px',
    backgroundColor: primaryMedium,
    marginTop: '60px',
    width: 'calc(100% + 50px)',
    position: 'relative',
    left: '-25px',
  };
});

export const EditButton = styled(ButtonBase)(() => ({
  color: primaryDark,
  marginRight: '20px',
}));

export const TableBodyContainer = styled(TableBody)(() => ({
  borderTop: `1px solid ${infoMain}`,
}));

export const DetailsButton = styled(ButtonBase)<Theme>(() => ({
  color: primaryDark,
  fontSize: '14px',
}));
