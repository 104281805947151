import React, { useCallback, useEffect, useState } from 'react';

import { CheckCircleOutline } from '@material-ui/icons';
import { StatusCodes } from 'http-status-codes';

import CouponService from 'services/couponService';
import useGlobalStyles from 'styles';
import { COUPON_CREATED, COUPON_EDITED } from 'texts';
import { Coupon } from 'types/coupon';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { COUPON_EVENT, COUPON_SOURCE } from 'types/enums';
import lodash from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { RouteParams } from 'types/routeParams';
import { useStyles } from './styles';
import {
  formatDateReverse,
  formatDateToInput,
  parsePercent,
  parseValue,
  schema,
} from './utils';
import { CouponValueType, ECouponValueType, SubmitCoupon } from './types';

export const useFormCouponsController = () => {
  // Styles
  const classes = useGlobalStyles();

  const styles = useStyles();

  // Navigation
  const history = useHistory();
  const { id: couponId } = useParams<RouteParams>();

  // States
  const [coupon, setCoupon] = useState<Coupon>();
  const [submitError, setSubmitError] = useState('');

  const [couponValueTypePix, setCouponValueTypePix] = useState<CouponValueType>(
    ECouponValueType.Currency,
  );
  const [couponValueTypeCreditCard, setCouponValueTypeCreditCard] =
    useState<CouponValueType>(ECouponValueType.Currency);
  const [couponValueTypeBankSlip, setCouponValueTypeBankSlip] =
    useState<CouponValueType>(ECouponValueType.Currency);

  const [successModalOpen, setSuccessModalOpen] = useState({
    open: false,
    icon: CheckCircleOutline,
    text: '',
  });
  const [hasDuration, setHasDuration] = useState(false);
  const [couponHaveLimit, setCouponHaveLimit] = useState(false);
  const [hasEvent, setHasEvent] = useState(false);
  const [event, setEvent] = useState<COUPON_EVENT | null>(null);

  // Form
  const {
    handleSubmit,
    errors,
    setError,
    register,
    control,
    setValue,
    clearErrors,
  } = useForm<SubmitCoupon>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldUnregister: false,
  });

  // Callbacks
  const onSubmit = async (data: SubmitCoupon) => {
    const couponData = lodash.pick(data, [
      'name',
      'value',
      'percent',
      'pix_value',
      'pix_percent',
      'credit_card_value',
      'credit_card_percent',
      'bank_slip_value',
      'bank_slip_percent',
      'start_date',
      'end_date',
      'limit',
      'event',
      'source',
    ]);
    if (couponHaveLimit && !couponData?.limit) {
      setError('limit', { message: 'Preencha o valor' });
      return;
    }

    if (hasDuration) {
      couponData.start_date = formatDateReverse(couponData.start_date);
      couponData.end_date = formatDateReverse(couponData.end_date);
    } else {
      couponData.start_date = null;
      couponData.end_date = null;
    }
    if (!hasEvent) {
      couponData.event = null;
    }

    if (couponValueTypePix === ECouponValueType.Percent) {
      couponData.pix_percent = parsePercent(String(couponData.pix_percent));
      couponData.pix_value = null;
    } else {
      couponData.pix_value = parseValue(String(couponData.pix_value));
      couponData.pix_percent = null;
    }

    if (couponValueTypeCreditCard === ECouponValueType.Percent) {
      couponData.credit_card_percent = parsePercent(
        String(couponData.credit_card_percent),
      );
      couponData.credit_card_value = null;
    } else {
      couponData.credit_card_value = parseValue(
        String(couponData.credit_card_value),
      );
      couponData.credit_card_percent = null;
    }

    if (couponValueTypeBankSlip === ECouponValueType.Percent) {
      couponData.bank_slip_percent = parsePercent(
        String(couponData.bank_slip_percent),
      );
      couponData.bank_slip_value = null;
    } else {
      couponData.bank_slip_value = parseValue(
        String(couponData.bank_slip_value),
      );
      couponData.bank_slip_percent = null;
    }

    if (coupon) {
      const response = await CouponService.updateCoupon(
        { ...couponData, limit: couponHaveLimit ? couponData.limit : null },
        coupon.id,
      );
      if (response.status !== StatusCodes.OK) {
        setSubmitError(response.data.message);
      } else {
        handleOpenEditedModal();
      }
    } else {
      const response = await CouponService.createCoupon({
        ...couponData,
      });

      if (response.status !== StatusCodes.CREATED) {
        setSubmitError(response.data.message);
      } else {
        handleOpenCreatedModal();
      }
    }
  };

  const toggleCheckbox =
    (toggleValue: (newValue: boolean) => void) =>
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      toggleValue(target.checked);
    };

  const toggleCouponValuePix = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === ECouponValueType.Currency) {
      setValue('usesCurrencyPix', false);
      setCouponValueTypePix(ECouponValueType.Percent);
      setValue('pix_value', undefined);
    } else {
      setCouponValueTypePix(ECouponValueType.Currency);
      setValue('usesCurrencyPix', true);
      setValue('pix_percent', undefined);
    }

    setCouponValueTypePix(event.target.value as ECouponValueType);
  };

  const toggleCouponValueCreditCard = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value === ECouponValueType.Currency) {
      setValue('usesCurrencyCreditCard', false);
      setCouponValueTypeCreditCard(ECouponValueType.Percent);
      setValue('credit_card_value', undefined);
    } else {
      setCouponValueTypeCreditCard(ECouponValueType.Currency);
      setValue('usesCurrencyCreditCard', true);
      setValue('credit_card_percent', undefined);
    }

    setCouponValueTypeCreditCard(event.target.value as ECouponValueType);
  };

  const toggleCouponValueBankSlip = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value === ECouponValueType.Currency) {
      setValue('usesCurrencyBankSlip', false);
      setCouponValueTypeBankSlip(ECouponValueType.Percent);
      setValue('bank_slip_value', undefined);
    } else {
      setCouponValueTypeBankSlip(ECouponValueType.Currency);
      setValue('usesCurrencyBankSlip', true);
      setValue('bank_slip_percent', undefined);
    }

    setCouponValueTypeBankSlip(event.target.value as ECouponValueType);
  };

  const toggleCouponLimit = () => {
    setCouponHaveLimit(previousCouponHaveLimit => {
      if (previousCouponHaveLimit) {
        setValue('limit', undefined);
        setValue('isLimited', false);
      }

      return !previousCouponHaveLimit;
    });
  };

  const toggleHasDuration = () => {
    clearErrors('hasDuration');
    clearErrors('start_date');
    clearErrors('end_date');
    setHasDuration(previousHasDuration => {
      if (previousHasDuration) {
        setHasDuration(false);
        setValue('hasDuration', false);
      } else {
        setHasDuration(true);
        setValue('hasDuration', true);
      }

      return !previousHasDuration;
    });
  };

  const handleOpenCreatedModal = () => {
    setSuccessModalOpen({
      open: true,
      icon: CheckCircleOutline,
      text: COUPON_CREATED,
    });
  };

  const handleOpenEditedModal = () => {
    setSuccessModalOpen({
      open: true,
      icon: CheckCircleOutline,
      text: COUPON_EDITED,
    });
  };

  const handleCloseCreatedModal = () => {
    setSuccessModalOpen({
      open: false,
      icon: CheckCircleOutline,
      text: COUPON_CREATED,
    });
    goToCoupons();
  };

  const onChangeCouponEvent = ({
    target,
  }: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setEvent(target.value as COUPON_EVENT);
    setValue('event', target.value);
  };

  const goToCoupons = () => {
    history.push('/adm-coupons');
  };

  const setCouponToEdit = useCallback(
    (coupon: Coupon) => {
      setCoupon(coupon);
      setValue('name', coupon.name);
      setValue('source', coupon.source);

      if (coupon.pix_percent) {
        setValue('usesCurrencyPix', false);
        setCouponValueTypePix(ECouponValueType.Percent);
        setValue('pix_percent', coupon.pix_percent);
      } else {
        setValue('usesCurrencyPix', true);
        setValue('pix_value', coupon.pix_value);
        setCouponValueTypePix(ECouponValueType.Currency);
      }

      if (coupon.credit_card_percent) {
        setValue('usesCurrencyCreditCard', false);
        setCouponValueTypeCreditCard(ECouponValueType.Percent);
        setValue('credit_card_percent', coupon.credit_card_percent);
      } else {
        setValue('usesCurrencyCreditCard', true);
        setValue('credit_card_value', coupon.credit_card_value);
        setCouponValueTypeCreditCard(ECouponValueType.Currency);
      }

      if (coupon.bank_slip_percent) {
        setValue('usesCurrencyBankSlip', false);
        setCouponValueTypeBankSlip(ECouponValueType.Percent);
        setValue('bank_slip_percent', coupon.bank_slip_percent);
      } else {
        setValue('usesCurrencyBankSlip', true);
        setValue('bank_slip_value', coupon.bank_slip_value);
        setCouponValueTypeBankSlip(ECouponValueType.Currency);
      }

      if (coupon.start_date) {
        setHasDuration(true);
        setValue('hasDuration', true);
        setValue('start_date', formatDateToInput(coupon.start_date));
        setValue('end_date', formatDateToInput(coupon.end_date));
      }
      if (coupon.limit) {
        setValue('limit', coupon.limit);
        setCouponHaveLimit(true);
        setValue('isLimited', true);
      }
      if (coupon.event) {
        setHasEvent(true);
        setEvent(coupon.event);
        setValue('event', coupon.event);
        setValue('hasEvent', true);
      }
    },
    [setValue],
  );

  const getCoupon = useCallback(
    async (id: string) => {
      const { data } = await CouponService.coupon(Number(id));

      setCouponToEdit(data);
    },
    [setCouponToEdit],
  );

  // Effects
  useEffect(() => {
    if (couponId) {
      getCoupon(couponId);
    }
  }, [couponId, getCoupon]);

  useEffect(() => {
    Object.keys(errors).map(errorKey => {
      setError(errorKey as keyof SubmitCoupon, {
        message: errors[errorKey as keyof SubmitCoupon]?.message,
      });
    });
  }, [errors, setError]);

  return {
    classes,
    styles,
    submitError,
    successModalOpen,
    handleSubmit,
    errors,
    register,
    control,
    onSubmit,
    event,
    couponHaveLimit,
    setHasEvent,
    toggleCheckbox,
    toggleCouponValuePix,
    toggleCouponValueCreditCard,
    toggleCouponValueBankSlip,
    toggleCouponLimit,
    handleCloseCreatedModal,
    handleOpenEditedModal,
    onChangeCouponEvent,
    toggleHasDuration,
    hasEvent,
    hasDuration,
    couponValueTypePix,
    couponValueTypeCreditCard,
    couponValueTypeBankSlip,
    setSubmitError,
    goToCoupons,
    couponId,
    coupon,
  };
};
