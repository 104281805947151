import { Box, CircularProgress } from '@material-ui/core';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { CustomIcon } from 'components/CustomIcon';
import { FooterActions } from 'components/FooterActions';
import PageHeader from 'components/PageHeader';
import { ServiceGallery } from 'pages/Admin/Marketplace/Services/ServiceDetail/ServiceGallery';

import AlertCard from 'components/AlertCard';
import CustomDialog from 'components/CustomDialog';
import { Cover } from './components/Cover';
import { ModalInsertInviteCode } from './components/ModalInsertInviteCode';
import { ModalInsertServiceCode } from './components/ModalServiceCodes';
import { ServiceDescription } from './components/ServiceDescription';
import { ServiceInfos } from './components/ServiceInfos';
import { useMarketplaceServicesDetailController } from './controller';
import { Container, ContainerServiceDetails, useStyles } from './styles';

export const MarketplaceServicesDetail = () => {
  const {
    removeServiceFromCart,
    serviceAlreadyInCart,
    serviceDetails,
    loadingServiceDetails,
    goToMarketplaceCart,
    handleCloseModalServiceAddedSuccessfully,
    serviceVariation,
    modalInsertInviteCodeOpen,
    handleCloseModalInsertInviteCode,
    serviceInviteCode,
    isLoadingConflictCheck,
    serviceClassConflictData,
    onChangeInsertCodeSubmit,
    handleValidateInviteCode,
    selectServiceClass,
    handleChangeSelectServiceClass,
    selectedServiceClass,
    onCloseServiceCodeModal,
    handleValidateServiceCode,
    handleActionToEarlyAccess,
    isOpenServiceCodeModal,
    handleCloseModalServiceCodeError,
    isOpenServiceCodeModalError,
    isLoadingValidateServiceCode,
    isLoadingModalInsertInviteCode,
    modalErrorRef,
    modalSuccessRef,
    isFooterButtonDisabled,
    isSelectedServiceClassFull,
    loading,

    // Alert
    alertMessage,
    alertType,
    isShowAlert,
    closeAlert,
  } = useMarketplaceServicesDetailController();
  const styles = useStyles();

  if (loadingServiceDetails) {
    return null;
  }

  return (
    <>
      <AlertCard
        message={alertMessage}
        open={isShowAlert}
        severity={alertType}
        close={closeAlert}
      />
      <Cover
        coverPhoto={serviceDetails.coverPhoto}
        serviceIsOnlyByInviteCode={serviceDetails?.serviceType?.onlyByInvite}
        serviceIsEarlyAccess={serviceDetails?.earlyAccess}
        serviceClassIsSoldOut={isSelectedServiceClassFull}
      />
      {loading ? (
        <Box className={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      ) : (
        <Container>
          <ContainerServiceDetails>
            <ServiceDescription service={serviceDetails} />
            <ServiceInfos
              isLoading={isLoadingConflictCheck}
              service={serviceDetails}
              selectServiceClass={selectServiceClass}
              onChangeSelectServiceClass={handleChangeSelectServiceClass}
              selectedServiceClass={selectedServiceClass}
              serviceConflict={serviceClassConflictData}
            />
          </ContainerServiceDetails>
          <Box paddingTop={0}>
            <PageHeader title="Galeria" />
            <ServiceGallery service={serviceDetails} />
          </Box>
          <FooterActions
            loading={false}
            confirmTextButton={
              serviceAlreadyInCart
                ? 'Remover do carrinho'
                : 'Adicionar ao carrinho'
            }
            onConfirm={
              serviceAlreadyInCart
                ? removeServiceFromCart
                : handleActionToEarlyAccess
            }
            disabled={isFooterButtonDisabled}
          />
        </Container>
      )}

      <CustomDialog
        icon={{
          name: 'checkCircle',
        }}
        ref={modalSuccessRef}
        rawTitle={`${
          serviceVariation === 'event'
            ? 'Evento adicionado'
            : 'Atividade adicionada'
        }  ao carrinho!`}
        rawSubtitle="Você pode continuar escolhendo outras atividades ou ir para o resumo do carrinho"
        secondaryButton={{
          text: 'Continuar escolhendo',
          onClick: handleCloseModalServiceAddedSuccessfully,
          textVariant: 'bold',
        }}
        primaryButton={{
          text: 'Ir para o carrinho',
          onClick: goToMarketplaceCart,
          textVariant: 'bold',
        }}
      />
      <CustomDialog
        icon={{
          name: 'checkCircleError',
        }}
        ref={modalErrorRef}
        rawTitle={'Código de convite\nincorreto'}
        rawSubtitle="Tente novamente ou entre em contato com a escola para receber o código de convite correto"
        primaryButton={{
          text: 'Fechar',
          onClick: modalErrorRef.current?.closeDialog,
          textVariant: 'bold',
        }}
      />
      <ModalInsertInviteCode
        open={modalInsertInviteCodeOpen}
        onClose={handleCloseModalInsertInviteCode}
        onChange={onChangeInsertCodeSubmit}
        value={serviceInviteCode}
        onConfirm={handleValidateInviteCode}
        isLoading={isLoadingModalInsertInviteCode}
      />
      <ModalInsertServiceCode
        open={isOpenServiceCodeModal}
        onClose={onCloseServiceCodeModal}
        onConfirm={handleValidateServiceCode}
        isLoading={isLoadingValidateServiceCode}
      />
      <ConfirmationModal
        open={isOpenServiceCodeModalError}
        title="Código de acesso antecipado incorreto"
        description="Tente novamente ou entre em contato com a escola para receber o código de acesso antecipado correto."
        confirmText="Fechar"
        onConfirm={handleCloseModalServiceCodeError}
        icon={<CustomIcon name="checkCircleError" />}
      />
    </>
  );
};
