import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    height: '100%',
    width: '100%',
  },
  paper: {
    borderRadius: 34,
    marginTop: 20,
    padding: 30,
    height: 130,
    width: '100%',
    boxShadow: '0px 6px 32px rgba(9, 13, 27, 0.07)',
  },
});
