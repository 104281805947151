import { ServiceClass } from 'pages/Client/MarketplaceServicesDetail/types';
import { Dependent } from './dependent';
import { Payment } from './payment';
import { Registration } from './registration';
import { Service } from './service';

export enum ServiceClassEnrollmentStatusEnum {
  ENROLLED = 'MATRICULADO',
  PENDING_PAYMENT = 'PAGAMENTO_PENDENTE',
  CANCELLED = 'CANCELADO',
  TRANSFERRED = 'TRANSFERIDO',
  DEFAULTING = 'INADIMPLENTE',
}

export enum ServiceClassEnrollmentStatusLabelsEnum {
  ENROLLED = 'Matrícula ativa',
  PENDING_PAYMENT = 'Pagamento pendente',
  DEFAULTING = 'Pagamento em atraso',
  CANCELLED = 'Matrícula cancelada',
  TRANSFERRED = 'Matrícula transferida',
}

export interface ServiceClassEnrollment {
  id: number;
  enrollmentNumber: number;
  serviceId: number;
  service: Service;
  serviceClassId: number;
  serviceClass: ServiceClass;
  dependentId: number;
  dependent: Dependent;
  registrationId: number;
  registration: Registration;
  status: ServiceClassEnrollmentStatusEnum;
  payments: Payment[];
}
