import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { TablePaper } from 'components/TablePaper';

import { TableBodyContainer } from '../../styles';
import { useServiceDataTableController } from './controller';
import { IServiceDataTableProps } from './types';

export const ServiceDataTable = ({
  coordinator = '-',
  recurrence = '',
  serviceType = '',
  isEmpty,
}: IServiceDataTableProps) => {
  const { headers, classes } = useServiceDataTableController();
  return (
    <TablePaper isEmpty={isEmpty}>
      <TableHead>
        <TableRow>
          {headers.map(header => (
            <TableCell key={header}>
              <Typography className={classes.listScreenHeaderText1}>
                {header}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBodyContainer>
        <Box height={20} />
        <TableRow>
          <TableCell>{recurrence}</TableCell>
          <TableCell>{serviceType}</TableCell>
          <TableCell>{coordinator}</TableCell>
        </TableRow>
      </TableBodyContainer>
    </TablePaper>
  );
};
