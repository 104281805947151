export const LOAD_SERVICES_ERROR = 'Ocorreu um erro ao carregar os serviços.';
export const LOAD_SERVICES_CLASSES_ERROR =
  'Ocorreu um erro ao carregar as turmas.';
export const LOAD_SERVICES_CODES_ERROR =
  'Ocorreu um erro ao carregar os códigos.';
export const CREATE_SERVICE_CODES_SUCCESS = 'Código(s) gerado(s) com sucesso!';
export const CREATE_SERVICE_CODES_ERROR =
  'Ocorreu um erro ao tentar criar os códigos do serviço';
export const REMOVE_SERVICE_CODE_SUCCESS = 'Código removido com sucesso.';
export const REMOVE_SERVICE_CODE_ERROR =
  'Houve um erro ao tentar remover o código.';
