import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ServicesService from 'services/servicesService';
import { formatCurrencyValueToNumber } from 'utils';
import {
  ERROR_ON_SUBMIT_CREATION,
  schema,
} from '../components/ServiceForm/schema';
import {
  FileState,
  IServiceFieldValues,
  ImagesNames,
} from '../components/ServiceForm/types';
import {
  generateServiceFormData,
  validateGeneralErrorsOnSubmit,
} from '../components/ServiceForm/utils';

export const useServiceCreationController = () => {
  // Navigation
  const history = useHistory();

  // Form
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    setValue,
    getValues,
  } = useForm<IServiceFieldValues>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  // States
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [generalErrorMessageAlert, setGeneralErrorMessageAlert] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [avatarFile, setAvatarFile] = useState<FileState>();
  const [coverFile, setCoverFile] = useState<FileState>();
  const [serviceImagesFiles, setServiceImagesFile] = useState<
    Array<Partial<FileState>>
  >([
    { file: undefined, url: undefined },
    { file: undefined, url: undefined },
    { file: undefined, url: undefined },
  ]);
  const [loading, setLoading] = useState(false);

  // Callbacks
  const renderValue = useCallback((values: unknown) => {
    if ((values as Array<{ name: string }>)[0]?.name) {
      return (values as Array<{ name: string }>)
        .map(value => value.name)
        .join(', ');
    }
    return (values as Array<string>).join(', ');
  }, []);

  const submit = useCallback(
    async (values: IServiceFieldValues) => {
      setLoading(true);

      const parsedValues = { ...values };
      const haveAmount = !!values.amount && values.amount !== 'R$ 0';
      const serviceTypeId = values.type;
      const servicePublicationStatusId = values.publicationStatus;

      let generalErrorMessageAlert = '';
      const intervalIsValid = true;

      generalErrorMessageAlert = validateGeneralErrorsOnSubmit(
        values,
        haveAmount ? formatCurrencyValueToNumber(values.amount) : 0,
      );

      if (generalErrorMessageAlert) {
        setGeneralErrorMessageAlert(generalErrorMessageAlert);
      }

      if (!generalErrorMessageAlert) {
        const data = generateServiceFormData({
          values,
          serviceTypeId,
          servicePublicationStatusId,
          coverPhoto: coverFile as FileState,
          galleryImages: serviceImagesFiles as Array<FileState>,
          partnerLogo: avatarFile as FileState,
        });
        try {
          const { status } = await ServicesService.createService(data);
          if (status === 201) {
            setSuccessModalOpen(true);
          } else {
            setSubmitError(ERROR_ON_SUBMIT_CREATION);
          }
        } catch (error) {
          setSubmitError(ERROR_ON_SUBMIT_CREATION);
        }
      }
      setLoading(false);
    },
    [avatarFile, coverFile, serviceImagesFiles],
  );

  const handleCloseScheduleError = () => {
    setGeneralErrorMessageAlert('');
  };

  const handleCloseSubmitError = () => {
    setSubmitError('');
  };

  const handleCancel = () => {
    setCancelModalOpen(true);
  };

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
    history.goBack();
  };

  const handleCloseCancelModal = () => {
    setCancelModalOpen(false);
  };

  const handleCloseAndNotGoBack = () => {
    setSuccessModalOpen(false);
    setCancelModalOpen(false);
  };
  const handleCloseModalAndExit = () => {
    setCancelModalOpen(false);
    history.goBack();
  };

  const setImagesValues = (
    name: ImagesNames,
    value?: FileState | FileState[],
  ) => {
    if (name === ImagesNames.Avatar) {
      setAvatarFile(value as FileState);
    }
    if (name === ImagesNames.Cover) {
      setCoverFile(value as FileState);
    }

    if (name === ImagesNames.Images) {
      setServiceImagesFile(value as FileState[]);
    }
  };

  return {
    submit,
    handleSubmit,
    errors,
    register,
    control,
    setValue,
    getValues,
    renderValue,
    handleCancel,
    successModalOpen,
    cancelModalOpen,
    handleCloseSuccessModal,
    handleCloseCancelModal,
    handleCloseAndNotGoBack,
    generalErrorMessageAlert,
    handleCloseScheduleError,
    setImagesValues,
    submitError,
    handleCloseSubmitError,
    loading,
    handleCloseModalAndExit,
  };
};
