import { Button, Divider, Grid, Paper, TextField } from '@material-ui/core';
import AttachMoney from '@material-ui/icons/AttachMoney';
import CreditCard from '@material-ui/icons/CreditCard';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import SelectButton from 'components/SelectButton';
import useGlobalStyles from 'styles';
import { PAYMENT_METHODS } from 'types/enums';
import { formatBRL } from 'utils';
import { handleOpenPriceTable } from '../../utils';
import CardMethodView from '../CardMethodView';
import SelectionFields from '../SelectionFields';
import { useStyles } from '../styles';
import PaymentHeader from './PaymentHeader';
import { SelectPaymentMethodProps } from './types';

// FIXME: Isso aqui ta horrivel ok? Tem que jogar fora
const SelectPaymentMethod = ({
  registration,
  onChangePaymentMethod,
  paymentMethod,
  onChangePaymentType,
  hasFreeCoupon,
  paymentType,
  remainingValue,
  installments,
  onSaveSubscriptionInstallments,
  appliedCoupon,
  setAppliedCoupon,
  calcRemaining,
  register,
  errors,
  subscriptions,
  setSubscriptions,
  originalValue,
  originalValueWithDiscount,
}: SelectPaymentMethodProps) => {
  const classes = useGlobalStyles();
  const styles = useStyles();

  return (
    <Grid container item direction="column">
      <PaymentHeader totalValue={originalValue} registration={registration} />
      <Paper elevation={0} className={classes.paymentScreenPaper}>
        <Grid item container direction="row" alignItems="center" spacing={3}>
          <Grid item>
            <SelectButton
              action={onChangePaymentMethod(PAYMENT_METHODS.CARD)}
              text="Cartão de crédito"
              icon={<CreditCard />}
              active={paymentMethod === PAYMENT_METHODS.CARD}
            />
          </Grid>
          <Grid item>
            <SelectButton
              action={onChangePaymentMethod(PAYMENT_METHODS.BANK_SLIP)}
              text="Boleto"
              icon={<InsertDriveFile />}
              active={paymentMethod === PAYMENT_METHODS.BANK_SLIP}
            />
          </Grid>
          <Grid item>
            <SelectButton
              action={onChangePaymentMethod(PAYMENT_METHODS.PIX)}
              text="Pix"
              icon={<AttachMoney />}
              active={paymentMethod === PAYMENT_METHODS.PIX}
            />
          </Grid>
        </Grid>
        <Divider className={classes.paymentScreenDivider1} />

        <SelectionFields
          hasFreeCoupon={hasFreeCoupon}
          paymentType={paymentType}
          paymentMethod={paymentMethod}
          handleChangePaymentType={onChangePaymentType}
          remainingValue={remainingValue}
          installments={installments}
          saveInstallment={onSaveSubscriptionInstallments}
          appliedCoupon={appliedCoupon}
          setAppliedCoupon={setAppliedCoupon}
          registration={registration}
        />

        {paymentMethod === PAYMENT_METHODS.CARD ? (
          <CardMethodView
            paymentType={paymentType}
            singlePaymentValue={originalValueWithDiscount}
            calcRemaining={calcRemaining}
            register={register}
            errors={errors}
            hasFreeCoupon={hasFreeCoupon}
            subscriptions={subscriptions}
            setSubscriptions={setSubscriptions}
            installments={installments}
          />
        ) : null}

        {paymentMethod === PAYMENT_METHODS.BANK_SLIP ||
        paymentMethod === PAYMENT_METHODS.PIX ? (
          <Grid item className={styles.cardRowField}>
            <TextField
              variant="outlined"
              label="Valor a pagar"
              value={formatBRL(originalValueWithDiscount)}
              disabled
            />
          </Grid>
        ) : null}

        <Button className={styles.redTextButton} onClick={handleOpenPriceTable}>
          <i>Visualizar tabela de valores</i>
        </Button>
      </Paper>
    </Grid>
  );
};

export default SelectPaymentMethod;
