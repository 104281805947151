import { endOfMonth, parseISO, startOfMonth } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

const BRAZILIAN_TIMEZONE = 'America/Sao_Paulo';

/**
 * Parse date from dd/mm/yyyy to ISO format
 *
 * @param   {string}  date  [date with format dd/mm/yyyy]
 *
 * @return  {[string]}        [return ISO date format in UTC-3 timezone]
 */
export const parseBrDateToISO = (date: string) => {
  const [day, month, year] = date.split('/');
  // Return ISO date format in UTC-3 timezone
  const dateObj = new Date(`${year}-${month}-${day}T00:00:00-03:00`);
  return dateObj.toISOString();
};

/**
 * get startOfMonth and endOfMonth from date
 */
export const getMonthPeriod = (date: string | Date) => {
  const dateObj = new Date(date);
  const startMonthDate = startOfMonth(dateObj);
  const endMonthDate = endOfMonth(dateObj);
  return {
    startMonthDate,
    endMonthDate,
  };
};

/**
 * This function will be convert zoned date to brazilian zoned data
 *
 * @param dateStr string in ISO format (YYYY-MM-DD)
 */
export const newDateInBrazilianTime = (dateStr: string) => {
  const dateObject = parseISO(dateStr);
  const utcDate = zonedTimeToUtc(dateObject, BRAZILIAN_TIMEZONE);
  return utcDate;
};
