import MaskedTextField from 'components/MaskedTextField';
import { ResponsiveInputGridItem } from 'components/ResponsiveInputGridItem';
import React from 'react';
import { Controller } from 'react-hook-form';
import useGlobalStyles from 'styles';
import { CPF_MASK } from 'texts';
import { useStyles } from './styles';
import { FormProperties } from './types';

const DependentDocumentsForm: React.FC<FormProperties> = ({
  dependent,
  documents,
  hasGuardianDocuments,
  setHasGuardianDocuments,
  editing,
  register,
  errors,
  control,
}) => {
  const classes = useGlobalStyles();
  const styles = useStyles();

  return (
    <>
      <ResponsiveInputGridItem>
        <Controller
          name="cpf"
          control={control}
          as={({ value, onChange }) => (
            <MaskedTextField
              mask={CPF_MASK}
              label="CPF"
              name="cpf"
              value={value}
              onChange={onChange}
              error={!!errors.cpf}
              helperText={errors.cpf ? errors.cpf?.message : null}
              inputRef={register}
              disabled={!editing}
              className={classes.maxWidthInput}
            />
          )}
          defaultValue={dependent?.cpf}
        />
      </ResponsiveInputGridItem>
      {/* <RGForm
        rg={dependent?.rg}
        documents={documents}
        editing={editing}
        register={register}
        errors={errors}
        control={control}
        hasGuardianDocuments={hasGuardianDocuments}
      /> */}
      {/* NOTE: Removido em 19/Out/2023 para adicionar a validação de duplicidade de dependentes */}
      {/* <ResponsiveInputGridItem>
        <FormControlLabel
          className={styles.checkbox}
          control={
            <Checkbox
              checked={hasGuardianDocuments}
              value={hasGuardianDocuments}
              onChange={setHasGuardianDocuments}
              color="secondary"
              disabled={!editing}
            />
          }
          label="CPF e RG inseridos são do responsável legal e financeiro"
        />
      </ResponsiveInputGridItem> */}
    </>
  );
};

export default DependentDocumentsForm;
