import { Grid } from '@material-ui/core';
import { CondOperator } from '@nestjsx/crud-request';
import AlertCard from 'components/AlertCard';
import EmptyListPlaceholder from 'components/EmptyListPlaceholder';
import PageHeader from 'components/PageHeader';
import { RegistrationListItem } from 'components/RegistrationListItem';
import { StatusCodes } from 'http-status-codes';
import React, { useCallback, useEffect, useState } from 'react';
import DependentService from 'services/dependentService';
import useGlobalStyles from 'styles';
import { GENERAL_ERROR, NO_DEPENDENTS } from 'texts';
import { Dependent } from 'types/dependent';
import { latestRegistration } from '../Registrations/utils';

const Dependents: React.FC = () => {
  const classes = useGlobalStyles();
  const [alert, setAlert] = useState(false);
  const [dependents, setDependents] = useState<Dependent[]>([]);

  const loadDependents = useCallback(async () => {
    const { data: response, status } = await DependentService.queryDependents({
      join: [['registrations'], ['registrations.documents']],
      filter: {
        field: 'registrations.id',
        operator: CondOperator.NOT_NULL,
      },
    });

    if (status === StatusCodes.OK) {
      setDependents(response.data);
    } else {
      setAlert(true);
    }
  }, []);

  useEffect(() => {
    loadDependents();
  }, [loadDependents]);

  return (
    <Grid
      className={classes.listScreenGrid}
      container
      direction="column"
      alignItems="flex-start"
    >
      <AlertCard
        message={GENERAL_ERROR}
        open={alert}
        close={() => setAlert(false)}
        severity="error"
      />
      <Grid container item direction="column">
        <PageHeader
          title="Lista dos dependentes"
          subtitle="Tenha acesso a todos os dependentes "
          subtitleBold="cadastrados"
        />

        {dependents?.length ? (
          dependents.map(dependent => {
            return (
              <Grid key={dependent.id}>
                <RegistrationListItem
                  dependentName={dependent.name}
                  registration={latestRegistration(dependent)}
                  showNumber
                  showDetails
                />
              </Grid>
            );
          })
        ) : (
          <EmptyListPlaceholder message={NO_DEPENDENTS} />
        )}
      </Grid>
    </Grid>
  );
};

export default Dependents;
