import MaskedTextField from 'components/MaskedTextField';
import { useMemo } from 'react';
import { CustomCurrencyInput, InputText } from './styles';
import { CustomInputTextProps } from './types';

export const CustomInputText = ({
  name,
  label,
  defaultValue,
  type = 'text',
  helperText,
  value,
  multiline,
  onChange,
  error,
  disabled,
  maxRows,
  ...textInputProps
}: CustomInputTextProps) => {
  const { InputComponent, inputType } = useMemo(() => {
    if (type === 'currency') {
      return {
        inputType: 'text',
        InputComponent: CustomCurrencyInput,
      };
    }

    if (type === 'mask') {
      return {
        inputType: 'text',
        InputComponent: MaskedTextField,
      };
    }

    return {
      inputType: type,
      InputComponent: InputText,
    };
  }, [type]);

  return (
    <InputComponent
      {...textInputProps}
      id={name}
      type={inputType}
      name={name}
      label={label}
      variant="outlined"
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      error={error}
      helperText={helperText}
      multiline={multiline}
      disabled={disabled}
      maxRows={maxRows}
      mask={type === 'mask' ? textInputProps.mask : undefined}
    />
  );
};
