import { format } from 'date-fns';
import { formatBRL } from 'utils';

export const formatDate = (date: string | undefined | null) => {
  if (date) {
    return format(new Date(`${date} 00:00:00`), 'dd/MM/yyyy');
  }
  return '';
};

export const formatValue = (value?: number | null, percent?: number | null) => {
  if (!value && !percent) {
    return 'Não registrado';
  }
  if (value) {
    return formatBRL(value);
  } else {
    return `${percent}%`;
  }
};
