import { Grid } from '@material-ui/core';
import { CustomButton } from 'components/CustomButton';
import CustomDialog from 'components/CustomDialog';
import DataPaper from 'components/DataPaper';
import { FooterContainer } from 'components/FooterActions/styles';
import { ControllerInputText } from 'components/Forms/ControllerInputText';
import Scaffold from 'components/Scaffold';
import React from 'react';
import { formatBRL } from 'utils';
import { formatDatePtBr } from 'utils/formatDatePtBr';
import { CreateInvoiceFormProps } from './types';
import { CreateInvoiceFormController } from './useCreateInvoiceFormController';

const CreateInvoiceForm: React.FC<CreateInvoiceFormProps> = ({ paymentId }) => {
  const {
    styles,
    control,
    register,
    errors,
    payment,
    loadingData,
    createInvoiceRef,
    loadingCreateInvoice,
    handleCreateInvoice,
    confirmCreateInvoiceRef,
    handleCloseConfirmModal,
    handleSubmit,
    handleOpenCreateInvoiceModal,
    descriptionInvoice,
  } = CreateInvoiceFormController({
    paymentId,
  });

  return (
    <Scaffold
      rawTitle={
        <>
          Emitir nota fiscal <b>avulsa</b>
        </>
      }
      rawSubtitle=""
      loading={loadingData}
    >
      <DataPaper title="Dados do Tomador">
        <Grid item xs={12} lg={4}>
          <ControllerInputText
            key="financialGuardianName"
            name="financialGuardianName"
            label="Nome"
            control={control}
            register={register}
            errors={errors}
            defaultValue={payment?.financialGuardian.user.name}
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={4} md={6}>
          <ControllerInputText
            key="financialGuardianCpf"
            name="financialGuardianCpf"
            label="CPF"
            control={control}
            register={register}
            errors={errors}
            defaultValue={payment?.financialGuardian.cpf}
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={4} md={6}>
          <ControllerInputText
            key="financialGuardianEmail"
            name="financialGuardianEmail"
            label="Email"
            control={control}
            register={register}
            errors={errors}
            defaultValue={payment?.financialGuardian.user.email}
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={4} md={6}>
          <ControllerInputText
            key="financialGuardianAddressStreet"
            name="financialGuardianAddressStreet"
            label="Rua"
            control={control}
            register={register}
            errors={errors}
            defaultValue={payment?.financialGuardian.address.street}
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={4} md={6}>
          <ControllerInputText
            key="financialGuardianAddressDistrict"
            name="financialGuardianAddressDistrict"
            label="Bairro"
            control={control}
            register={register}
            errors={errors}
            defaultValue={payment?.financialGuardian.address.district}
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={4} md={6}>
          <ControllerInputText
            key="financialGuardianCity"
            name="financialGuardianCity"
            label="Cidade"
            control={control}
            register={register}
            errors={errors}
            defaultValue={payment?.financialGuardian.address.city}
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={4} md={6}>
          <ControllerInputText
            key="financialGuardianCep"
            name="financialGuardianCep"
            label="CEP"
            control={control}
            register={register}
            errors={errors}
            defaultValue={payment?.financialGuardian.address.cep}
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={4} md={6}>
          <ControllerInputText
            key="financialGuardianComplement"
            name="financialGuardianComplement"
            label="Complemento"
            control={control}
            register={register}
            errors={errors}
            defaultValue={payment?.financialGuardian.address.complement}
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={4} md={6}>
          <ControllerInputText
            key="financialGuardianNumber"
            name="financialGuardianNumber"
            label="Número"
            control={control}
            register={register}
            errors={errors}
            defaultValue={payment?.financialGuardian.address.number}
            disabled
          />
        </Grid>
      </DataPaper>
      <DataPaper title="Dados do Serviço">
        <Grid item xs={12} lg={4} md={6}>
          <ControllerInputText
            key="competenceDate"
            name="competenceDate"
            label="Data de competência"
            control={control}
            register={register}
            errors={errors}
            defaultValue={formatDatePtBr(new Date())}
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={4} md={6}>
          <ControllerInputText
            key="paymentInvoiceServiceCode"
            name="paymentInvoiceServiceCode"
            label="Código de serviço"
            control={control}
            register={register}
            errors={errors}
            defaultValue={payment?.paymentInvoiceServiceCode}
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={4} md={6}>
          <ControllerInputText
            key="serviceAmount"
            name="serviceAmount"
            label="Valor"
            control={control}
            register={register}
            errors={errors}
            type="currency"
          />
        </Grid>
        <Grid item xs={12} lg={4} md={6}>
          <ControllerInputText
            key="serviceUnconditionalDiscount"
            name="serviceUnconditionalDiscount"
            label="Valor desconto incondicionado"
            control={control}
            register={register}
            errors={errors}
            type="currency"
            defaultValue={formatBRL(0)}
          />
        </Grid>
        <Grid item xs={12} lg={4} md={8}>
          <ControllerInputText
            key="description"
            name="description"
            label="Descrição da nota fiscal"
            control={control}
            register={register}
            errors={errors}
            multiline
            defaultValue={descriptionInvoice}
            maxRows={10}
            disabled
          />
        </Grid>
      </DataPaper>
      <CustomDialog
        ref={confirmCreateInvoiceRef}
        rawTitle="Nota Fiscal"
        rawSubtitle="A nota fiscal foi enviada para emissão. Isso pode levar alguns instantes. Acompanhe o andamento nos detalhes do pagamento ou no relatório de notas fiscais."
        primaryButton={{
          text: 'Fechar',
          onClick: handleCloseConfirmModal,
        }}
        icon={{
          name: 'checkCircle',
        }}
      />
      <CustomDialog
        ref={createInvoiceRef}
        rawTitle="Criar nota fiscal avulsa?"
        rawSubtitle=""
        primaryButton={{
          text: 'Sim, criar nota fiscal',
          onClick: handleCreateInvoice,
          loading: loadingCreateInvoice,
        }}
        secondaryButton={{
          text: 'Voltar',
          onClick: createInvoiceRef.current?.closeDialog,
          disabled: loadingCreateInvoice,
        }}
        icon={{
          name: 'warningCheckCircle',
        }}
      />
      <FooterContainer position="absolute">
        <CustomButton
          text="Criar nota fiscal"
          onClick={handleSubmit(handleOpenCreateInvoiceModal)}
        />
      </FooterContainer>
    </Scaffold>
  );
};

export default CreateInvoiceForm;
