import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import ServicesService from 'services/servicesService';
import { RouteParams } from 'types/routeParams';
import { formatCurrencyValueToNumber } from 'utils';
import {
  ERROR_ON_SUBMIT_UPDATE,
  schema,
} from '../components/ServiceForm/schema';
import {
  FileState,
  IServiceFieldValues,
  IServiceResponse,
  ImagesNames,
} from '../components/ServiceForm/types';
import {
  generateServiceFormData,
  validateGeneralErrorsOnSubmit,
} from '../components/ServiceForm/utils';

export const useServiceUpdateController = () => {
  // Navigation
  const { id } = useParams<RouteParams>();
  const history = useHistory();

  // Form
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    setValue,
    getValues,
  } = useForm<IServiceFieldValues>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  // States
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [generalErrorMessageAlert, setGeneralErrorMessageAlert] = useState('');
  const [avatarFile, setAvatarFile] = useState<FileState>();
  const [coverFile, setCoverFile] = useState<FileState>();
  const [serviceImagesFiles, setServiceImagesFile] = useState<
    Array<Partial<FileState>>
  >([
    { file: undefined, url: undefined },
    { file: undefined, url: undefined },
    { file: undefined, url: undefined },
  ]);
  const [serviceToUpdate, setServiceToUpdate] = useState<IServiceResponse>();
  const [submitError, setSubmitError] = useState('');
  const [loading, setLoading] = useState(false);

  // Callbacks
  const renderValue = useCallback((values: unknown) => {
    return (values as Array<string>).join(', ');
  }, []);

  const getServiceToUpdate = useCallback(async () => {
    const { data: response } = await ServicesService.getService(id, {
      join: [
        ['serviceCategories'],
        ['schoolClasses'],
        ['serviceType'],
        ['servicePublicationStatus'],
        ['serviceRecurrence'],
        ['galleryImages'],
        ['partner'],
      ],
    });
    setServiceToUpdate(response);
  }, [id]);

  const submit = useCallback(
    async (values: IServiceFieldValues) => {
      setLoading(true);
      const parsedValues = { ...values };
      let generalErrorMessageAlert = '';
      const intervalIsValid = true;
      const serviceTypeId = values.type;
      const servicePublicationStatusId = values.publicationStatus;

      const haveAmount = !!values.amount && values.amount !== 'R$ 0';
      generalErrorMessageAlert = validateGeneralErrorsOnSubmit(
        values,
        haveAmount ? formatCurrencyValueToNumber(values.amount) : 0,
      );

      if (generalErrorMessageAlert) {
        setGeneralErrorMessageAlert(generalErrorMessageAlert);
      }

      if (!generalErrorMessageAlert) {
        const data = generateServiceFormData({
          values,
          serviceTypeId,
          servicePublicationStatusId,
          coverPhoto: coverFile,
          galleryImages: serviceImagesFiles,
          partnerLogo: avatarFile,
          isUpdate: true,
        });

        const { status } = await ServicesService.updateService(data, id);
        if (status === 200) {
          setSuccessModalOpen(true);
        } else {
          setSubmitError(ERROR_ON_SUBMIT_UPDATE);
        }
      }
      setLoading(false);
    },
    [avatarFile, coverFile, id, serviceImagesFiles],
  );

  const handleCloseScheduleError = () => {
    setGeneralErrorMessageAlert('');
  };

  const handleCloseSubmitError = () => {
    setSubmitError('');
  };

  const handleCancel = () => {
    setCancelModalOpen(true);
  };

  const handleCloseModal = () => {
    setSuccessModalOpen(false);
    setCancelModalOpen(false);
  };
  const handleCloseModalAndExit = () => {
    handleCloseModal();
    history.goBack();
  };

  const setImagesValues = (
    name: ImagesNames,
    value?: FileState | FileState[],
  ) => {
    if (name === ImagesNames.Avatar) {
      setAvatarFile(value as FileState);
    }
    if (name === ImagesNames.Cover) {
      setCoverFile(value as FileState);
    }

    if (name === ImagesNames.Images) {
      setServiceImagesFile(value as FileState[]);
    }
  };

  // Effects
  useEffect(() => {
    getServiceToUpdate();
  }, [getServiceToUpdate]);

  return {
    submit,
    handleSubmit,
    errors,
    register,
    control,
    setValue,
    getValues,
    renderValue,
    handleCancel,
    successModalOpen,
    cancelModalOpen,
    handleCloseModal,
    generalErrorMessageAlert,
    handleCloseScheduleError,
    setImagesValues,
    serviceToUpdate,
    submitError,
    handleCloseSubmitError,
    loading,
    handleCloseModalAndExit,
  };
};
