import React from 'react';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import AlertCard from 'components/AlertCard';
import { NestCrudTable } from 'components/NestCrudTable';
import { ServiceClassEnrollment } from 'types/serviceClassEnrollment';
import {
  CardTitle,
  DescriptionResumeAvatar,
  DescriptionResumeName,
  PaperContainer,
  DescriptionSpotsButton,
  useStyles,
} from './styles';
import { useServiceClassInfoController } from './controller';

export const ServiceClassInfo: React.FC = () => {
  const styles = useStyles();

  const {
    // Page Data
    handleGoToEditClass,
    areVacanciesSoldOut,
    loadingPage,
    serviceClass,
    service,
    isShowAlert,
    alertType,
    alertMessage,
    closeAlert,

    // Table Data
    tableConfig,
    loadServiceClassEnrollmentTableData,
  } = useServiceClassInfoController();

  return (
    <>
      {loadingPage ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: '80vh' }}
        >
          <CircularProgress size={50} color="inherit" />
        </Grid>
      ) : (
        <Box paddingX="45px">
          {service.id && serviceClass.id ? (
            <>
              <Grid item xs={12}>
                <Box mb="15px" display="flex" alignItems="center">
                  <DescriptionResumeAvatar
                    alt="Imagem da turma"
                    src={service.partnerLogo}
                  />
                  <DescriptionResumeName>
                    {serviceClass.name}
                  </DescriptionResumeName>
                </Box>
              </Grid>
              <Grid container item xs={12} spacing={5}>
                <Grid item md={4} xs={12}>
                  <PaperContainer>
                    <Typography className={styles.mediumGrayColor}>
                      Vagas
                    </Typography>
                    <Typography
                      className={clsx(
                        styles.quantitySpotsText,
                        areVacanciesSoldOut(
                          serviceClass.spotsFilled,
                          serviceClass.spotsNumber,
                        )
                          ? styles.errorColor
                          : '',
                      )}
                    >
                      {serviceClass.spotsFilled}/{serviceClass.spotsNumber}
                    </Typography>
                    {areVacanciesSoldOut(
                      serviceClass.spotsFilled,
                      serviceClass.spotsNumber,
                    ) ? (
                      <Typography
                        className={styles.vecanciesSoldOutText}
                        variant="body2"
                      >
                        Acabaram as vagas nessa turma!
                      </Typography>
                    ) : null}
                    <DescriptionSpotsButton
                      className={styles.marginButton}
                      variant="contained"
                      disableRipple
                      onClick={() => handleGoToEditClass(1)}
                    >
                      Aumentar vagas
                    </DescriptionSpotsButton>
                  </PaperContainer>
                </Grid>
                <Grid item md={3} xs={12}>
                  <PaperContainer>
                    <CardTitle>Dias e horário</CardTitle>
                    {serviceClass.calendars.map(serviceClassCalendar => (
                      <Typography variant="subtitle2">
                        {serviceClassCalendar.day}
                        {serviceClassCalendar.startTime} -
                        {serviceClassCalendar.endTime}
                      </Typography>
                    ))}
                  </PaperContainer>
                </Grid>
                <Grid item md={5} xs={12}>
                  <PaperContainer>
                    <CardTitle>Série / ano</CardTitle>
                    {serviceClass.schoolClasses.map(schoolClass => (
                      <Typography variant="subtitle2">
                        {schoolClass.name}
                      </Typography>
                    ))}
                  </PaperContainer>
                </Grid>
              </Grid>
            </>
          ) : (
            <Typography>Houve um erro ao carregar os dados da turma</Typography>
          )}

          <Box mt="30px" />

          <NestCrudTable<ServiceClassEnrollment>
            onFetchData={loadServiceClassEnrollmentTableData}
            tableConfig={tableConfig}
            emptyTableMessage="Não existem alunos matriculados nessa turma."
            tableHeader={{
              rawTitle: 'Dados da turma',
            }}
          />
        </Box>
      )}

      <AlertCard
        message={alertMessage}
        open={isShowAlert}
        severity={alertType}
        close={closeAlert}
      />
    </>
  );
};
