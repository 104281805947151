import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  search: {
    background: 'white',
    borderRadius: 8,
    width: '50%',
    height: 55,
    margin: '25px 10px 25px 0px',
    textTransform: 'none',
  },
});
