import axios from 'axios';

export default class CEPService {
  static async verifyCEP(cep: string) {
    try {
      return await axios.get(`${process.env.REACT_APP_VIACEP_URL}/${cep}/json`);
    } catch (error: any) {
      return error.response;
    }
  }
}
