export const GUARDIAN_ERROR = 'Houve algum erro, verifique os dados inseridos.';
export const CARD_ERROR = 'Houve algum erro ao salvar o cartão.';
export const INVALID_PHONE = 'Insira um número de celular completo com DDD.';
export const CARD_EXPIRED = 'O cartão está vencido.';
export const INVALID_CARD =
  'Este número não é de uma das bandeiras aceitas ou não é válido.';
export const CEP_ERROR = 'Houve algum erro ao buscar o CEP inserido.';
export const CEP_NOT_FOUND =
  'CEP não encontrado, insira o endereço manualmente.';
export const INVALID_CPF = 'CPF inválido.';
export const PARSE_VINDI_ERROR_MESSAGE = (errors: any[]) => {
  const parameters: any = {
    card_cvv: 'CVV inválido',
    card_number: 'Número do cartão inválido',
    holder_name: 'Nome do cartão inválido',
    card_expiration: 'Data de expiração inválida',
  };
  if (errors.length > 0) {
    return parameters[errors[0]?.parameter] || CARD_ERROR;
  }
  return CARD_ERROR;
};
export const REMOVED_SUCCESS = 'Responsável removido.';
export const INCOMPLETE_PROFILE =
  'Perfil incompleto. É preciso que este usuário esteja com dados completos para cadastrar dependentes.';
export const LOAD_DATA_ERROR = 'Houve um erro ao tentar carregar os dados.';
