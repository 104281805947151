import React from 'react';

import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { TablePaper } from 'components/TablePaper';
import ChevronRight from '@material-ui/icons/ChevronRight';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { useServicesTableController } from './controller';
import { EServiceStatus, IServiceTableProps } from './types';
import {
  DetailsButton,
  EditButton,
  Label,
  TableBodyContainer,
} from '../../styles';

export const ServiceTable = ({ services }: IServiceTableProps) => {
  const { headers, classes, label, goToServiceDetail, goToServiceUpdate } =
    useServicesTableController();

  return (
    <TablePaper isEmpty={!services.length}>
      <TableHead>
        <TableRow>
          {headers.map(header => (
            <TableCell key={header}>
              <Typography className={classes.listScreenHeaderText1}>
                {header}
              </Typography>
            </TableCell>
          ))}
          <TableCell align="right">
            <Typography className={classes.listScreenHeaderText1}>
              AÇÕES
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBodyContainer>
        <Box height={20} />
        {services.map(service => (
          <TableRow key={String(service.id)}>
            <TableCell>{service.name}</TableCell>
            <TableCell>{service.serviceType?.name || ''}</TableCell>
            <TableCell>
              {(service.serviceCategories || [])
                .map(({ name }) => name)
                .join(', ')}
            </TableCell>
            <TableCell>{service.partner?.name || ''}</TableCell>
            <TableCell>
              <Label
                backgroundColor={
                  label(service.servicePublicationStatus.name as EServiceStatus)
                    .backgroundColor
                }
                color={
                  label(service.servicePublicationStatus.name as EServiceStatus)
                    .color
                }
              >
                {service.servicePublicationStatus.name}
              </Label>
            </TableCell>

            <TableCell align="right">
              <EditButton
                disableTouchRipple
                onClick={() => goToServiceUpdate(service.id)}
              >
                <CreateOutlinedIcon />
              </EditButton>
              <DetailsButton
                disableTouchRipple
                onClick={() => goToServiceDetail(service.id)}
              >
                Ver detalhes
                <ChevronRight color="primary" />
              </DetailsButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBodyContainer>
    </TablePaper>
  );
};
