import { TableColumnConfig } from 'components/Table/types';
import { InvoiceGetMonthlyReport } from 'types/invoice';
import { formatBRL } from 'utils';

export const MONTHLY_REPORTS_TABLE_HEADERS = {
  TOTAL_COUNT: 'Total de notas',
  COMPETENCE_MONTH: 'Mês de competencia',
  AMOUNT: 'Valor total líquido',
  CREATED: 'Criadas',
  ISSUED: 'Emitidas',
  FAILED: 'Falhas',
  CANCELED: 'Canceladas',
  NOT_ISSUED: 'Não emitidas',
};

export const invoiceMonthlyReportsTableConfig: TableColumnConfig<InvoiceGetMonthlyReport>[] =
  [
    {
      headerName: MONTHLY_REPORTS_TABLE_HEADERS.COMPETENCE_MONTH,
      getter: 'competence',
      defaultValue: '-',
    },
    {
      headerName: MONTHLY_REPORTS_TABLE_HEADERS.AMOUNT,
      getter: 'totalAmount',
      onFormat: (_, data) => {
        return formatBRL(
          Number(data.totalAmount) -
            Number(data.totalDiscountUnconditionedAmount),
        );
      },
      defaultValue: '-',
    },
    {
      headerName: MONTHLY_REPORTS_TABLE_HEADERS.ISSUED,
      getter: 'issuedCount',
      defaultValue: '-',
    },
    {
      headerName: MONTHLY_REPORTS_TABLE_HEADERS.FAILED,
      getter: 'failedCount',
      defaultValue: '-',
    },
    {
      headerName: MONTHLY_REPORTS_TABLE_HEADERS.CANCELED,
      getter: 'canceledCount',
      defaultValue: '-',
    },
  ] as TableColumnConfig<InvoiceGetMonthlyReport>[];
