import { makeStyles } from '@material-ui/core';
import { primaryDark } from 'styles/theme';

export const useStyles = makeStyles({
  paper: {
    position: 'relative',
    cursor: 'pointer',
    background: primaryDark,
    marginTop: 60,
    padding: 17,
    marginBottom: 20,
    borderRadius: 34,
    minHeight: 224,
    minWidth: 248,
    boxShadow: '0px 6px 32px rgba(9, 13, 27, 0.07)',
    paddingTop: 60,
  },
  img: {
    position: 'absolute',
    top: -60,
    width: 120,
    height: 120,
    borderRadius: 28,
    marginRight: 40,
  },
  box: {
    color: 'white',
    cursor: 'inherit',
    paddingLeft: 12,
    marginTop: 10,
  },
  textBox: {
    color: 'white',
  },
});
