import { darkGray, primaryMedium, purple } from 'styles/theme';
import { ServiceHeaderVariant } from './types';

export const getTitleFontColorByVariant = (variant: ServiceHeaderVariant) => {
  switch (variant) {
    case 'by-invite':
      return purple;
    case 'major':
      return primaryMedium;
    case 'minor':
      return darkGray;
    default:
      return darkGray;
  }
};
