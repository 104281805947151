import { CondOperator } from '@nestjsx/crud-request';
import { useFilters } from 'hooks/filter';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import DependentService from 'services/dependentService';
import PedagogicPerformanceReportService from 'services/pedagogicPerformanceReportService';
import PedagogicSchoolYearService from 'services/pedagogicSchoolYears';
import { PedagogicSchoolYear } from 'services/types/pedagogicSchoolYear.type';
import useGlobalStyles from 'styles';
import { Dependent } from 'types/dependent';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { TableViewStudentReportCard } from './types';

export const useViewStudentReportCardsController = () => {
  const [dependents, setDependents] = useState<Dependent[]>([]);
  const [pedagogicSchoolYears, setPedagogicSchoolYears] = useState<
    PedagogicSchoolYear[]
  >([]);
  const [studentReportCardData, setStudentReportCardData] = useState<
    TableViewStudentReportCard[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);

  const classes = useGlobalStyles();

  const [query, setQuery] = useQueryParams({
    referenceYear: withDefault(NumberParam, new Date().getFullYear()),
  });

  const { referenceYear } = query;

  const [defaultFilters] = useState([
    {
      field: 'year',
      value: referenceYear,
      operator: CondOperator.EQUALS,
    },
  ]);

  const { handleUpdateFilters } = useFilters(defaultFilters);

  const loadPedagogicSchoolYears = useCallback(async () => {
    const { data } = await PedagogicSchoolYearService.getOptions();
    setPedagogicSchoolYears(data || []);
  }, []);

  useEffect(() => {
    loadPedagogicSchoolYears();
  }, [loadPedagogicSchoolYears]);

  const loadDependents = useCallback(async () => {
    const { data: response } = await DependentService.queryDependents({
      join: [['registrations']],
      filter: {
        field: 'registrations.id',
        operator: CondOperator.NOT_NULL,
      },
    });
    setDependents(response.data || []);
  }, []);

  useEffect(() => {
    loadDependents();
  }, [loadDependents]);

  const loadStudentReportCardData = useCallback(async () => {
    setIsLoading(true);
    const registrationNumbers = dependents.flatMap(dependent =>
      dependent.registrations.flatMap(r => r.number),
    );
    if (!registrationNumbers.length) return;
    const { data: response } =
      await PedagogicPerformanceReportService.queryPerformanceReport({
        fields: ['portalDependentName', 'pdfUrl'],
        filter: [
          {
            field: 'portalRegistrationNumber',
            operator: CondOperator.IN,
            value: registrationNumbers,
          },
          {
            field: 'schoolYear',
            operator: CondOperator.EQUALS,
            value: referenceYear,
          },
        ],
      });
    setStudentReportCardData(response.data || []);
    setIsLoading(false);
  }, [dependents, referenceYear]);

  const handleChangeReferenceYear = (e: ChangeEvent<HTMLInputElement>) => {
    handleUpdateFilters('year', { value: e.target.value }, CondOperator.EQUALS);
    setQuery({ referenceYear: Number(e.target.value) });
  };

  const openPdfUrl = (url: string) => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    loadStudentReportCardData();
  }, [loadStudentReportCardData]);

  return {
    isLoading,
    studentReportCardData,
    openPdfUrl,
    classes,
    referenceYear,
    handleChangeReferenceYear,
    pedagogicSchoolYears,
  };
};
