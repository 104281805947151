/* eslint-disable @typescript-eslint/ban-ts-comment */

import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import PageHeader from 'components/PageHeader';
import { TablePaper } from 'components/TablePaper';
import useGlobalStyles from 'styles';
import { DetailsButton, TableBodyContainer } from '../styles';
import { Description } from './Description';
import { ServiceClassesDataTable } from './ServiceClassesDataTable';
import { ServiceDataTable } from './ServiceDataTable';
import { ServiceGallery } from './ServiceGallery';
import { useServiceDetailController } from './controller';

export const ServiceDetail = () => {
  const classes = useGlobalStyles();
  const {
    service,
    totalServiceCodes,
    totalServiceCodesUsed,
    handleGoToServiceCodes,
  } = useServiceDetailController();

  return (
    <Box paddingX="45px" marginTop="45px">
      <Description
        id={service.id}
        name={service.name}
        avatarUrl={service.partnerLogo}
        description={service.description}
      />

      <PageHeader title="Dados do serviço" />
      <ServiceDataTable
        isEmpty={!service.id}
        coordinator={service.responsibleCoordinator}
        recurrence={service.serviceRecurrence?.name}
        serviceType={service.serviceType?.name}
      />

      <PageHeader title="Acesso antecipado" />
      <TablePaper>
        <TableHead>
          <TableRow>
            {['códigos utilizados', 'ações'].map(header => (
              <TableCell key={header}>
                <Typography className={classes.listScreenHeaderText1}>
                  {header}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBodyContainer>
          <Box height={20} />
          <TableRow>
            <TableCell>
              {totalServiceCodesUsed}/{totalServiceCodes}
            </TableCell>
            <TableCell>
              <DetailsButton onClick={handleGoToServiceCodes}>
                Gerenciar códigos <ChevronRight color="primary" />
              </DetailsButton>
            </TableCell>
          </TableRow>
        </TableBodyContainer>
      </TablePaper>

      <PageHeader title="Turmas" />
      <ServiceClassesDataTable serviceId={service.id} />

      <PageHeader title="Galeria" />
      <ServiceGallery service={service} />
    </Box>
  );
};
