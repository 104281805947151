import React, { ReactNode, useEffect, useState } from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';
import { useAuth } from 'contexts/auth';

interface ButtonProperties {
  icon: ReactNode;
  className?: string;
  disabled?: boolean;
  action?: () => void;
  localLoading?: boolean;
}

const SpinnerIconButton: React.FC<ButtonProperties> = ({
  action,
  icon,
  className,
  disabled,
  localLoading,
}) => {
  const { loading: globalLoading } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(localLoading === undefined ? globalLoading : localLoading);
  }, [localLoading, globalLoading]);

  return (
    <IconButton
      color="primary"
      className={className}
      disabled={disabled || isLoading}
      onClick={action}
    >
      {isLoading ? <CircularProgress size={30} color="inherit" /> : icon}
    </IconButton>
  );
};

export default SpinnerIconButton;
