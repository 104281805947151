// TODO: Replace project's admin routes gradually as you come accross code that doesn't use this constant
export const ADMIN_ROUTE = {
  // Home
  HOME: '/adm-home',
  HOME_PROFILE: '/adm-home/profile',

  // Registrations
  REGISTRATIONS: '/adm-registrations',
  REGISTRATION_DETAILS: '/adm-registrations/:id',
  REGISTRATION_GUARDIAN_CREATION: '/adm-registrations/:id/new-guardian',

  // Marketplace
  MARKETPLACE_HOME: '/adm-marketplace',
  MARKETPLACE_SERVICES: '/adm-marketplace/services',
  MARKETPLACE_SERVICE_DETAILS: '/adm-marketplace/services/:id/detail',
  MARKETPLACE_SERVICE_CLASS_DETAILS:
    '/adm-marketplace/services/:id/service-class/:classId/info',
  MARKETPLACE_SERVICE_CREATION: '/adm-marketplace/services/new-service',
  MARKETPLACE_SERVICE_UPDATE: '/adm-marketplace/services/:id/update-service',
  MARKETPLACE_SERVICE_CLASS_CREATION:
    '/adm-marketplace/services/:serviceId/new-service-class',
  MARKETPLACE_SERVICE_CLASS_UPDATE:
    '/adm-marketplace/services/:serviceId/service-class/:serviceClassId',
  MARKETPLACE_SERVICE_CODES: '/adm-marketplace/service-codes',
  MARKETPLACE_PARTNERS: '/adm-marketplace/partners',
  MARKETPLACE_PARTNERS_MANAGEMENT: '/adm-marketplace/partners/new-partners',
  MARKETPLACE_PARTNER_CREATION:
    '/adm-marketplace/partners/new-partners/create-partner',
  MARKETPLACE_PARTNERS_CODE_CREATION:
    '/adm-marketplace/partners/new-partners/generate-code',
  MARKETPLACE_PARTNER_UPDATE: '/adm-marketplace/partners/:id/update-partner',
  MARKETPLACE_PARTNERS_SERVICES: '/adm-marketplace/partners/:id/services',
  MARKETPLACE_REPORTS: '/adm-marketplace/reports',

  // Contract templates (DG)
  CONTRACT_TEMPLATES: '/adm-marketplace/contract-templates',
  CONTRACT_TEMPLATE_CREATION: '/adm-marketplace/contract-templates/new',
  CONTRACT_TEMPLATE_UPDATE: '/adm-marketplace/contract-templates/:id/edit',

  // Tuition
  TUITION: '/adm-tuition',
  TUITION_DETAILS: '/adm-tuition/:id',

  // Coupons
  COUPONS: '/adm-coupons',
  COUPON_CREATION: '/adm-coupons/create',
  COUPON_UPDATE: '/adm-coupons/edit/:id',
  COUPON_DETAILS: '/adm-coupons/:id',

  // Contracts
  CONTRACTS: '/adm-contracts',

  // Users
  USERS: '/adm-users',
  USER_DETAILS: '/adm-users/:id',
  USER_CREATION: '/adm-users/:id/new-registration',

  // Financial
  PAYMENTS: '/adm-financial',
  PAYMENT_DETAILS: '/adm-financial/:id',
  PAYMENT_FORECAST: '/adm-forecast',

  // Configurations
  CONFIGURATIONS: '/adm-configurations',

  // Invoices
  INVOICES: '/adm-invoices',
  CREATE_MANUAL_INVOICE: '/adm-financial/:paymentId/create-manual-invoice',
};

export const CLIENT_ROUTE = {
  // Home
  HOME: '/home',
  HOME_PROFILE: '/home/profile',

  // Dependents
  DEPENDENTS: '/dependents',
  DEPENDENT_DETAILS: '/dependents/:id',

  // Financial
  FINANCIAL: '/financial',
  FINANCIAL_DETAILS: '/financial/:id',

  // Credit Cards
  CREDIT_CARDS: '/cards',

  // Registrations
  REGISTRATIONS: '/registrations',
  REGISTRATIONS_NEW: '/registrations/new-registration',
  REGISTRATION_DETAILS: '/registrations/:id',
  REGISTRATION_PAYMENT_METHOD: '/registrations/:id/payment-method',
  REGISTRATION_SIGN_CONTRACT: '/registrations/payment/:id/sign-contract',

  // Marketplace
  MARKETPLACE_HOME: '/client-marketplace',
  MARKETPLACE_SEARCH: '/client-marketplace/search',
  MARKETPLACE_SERVICE_DETAILS:
    '/client-marketplace/:registrationId/service/:serviceId',
  MARKETPLACE_CART: '/client-marketplace/cart',
  MARKETPLACE_PAYMENT_CHECKOUT: '/client-marketplace/payment-checkout',
  MARKETPLACE_ORDER_CONTRACTS: '/client-marketplace/orders/:orderId/contracts',
  MARKETPLACE_ORDER_PAYMENTS: '/client-marketplace/orders/:orderId/payments',

  // Income Tax Return
  INCOME_TAX_RETURN: '/income-tax-return',

  // Contracts
  CONTRACTS: '/contracts',

  // Payments
  PAYMENT_STATUS: '/payment/:id/status',

  // Student report cards
  STUDENT_REPORT_CARDS: '/student-report-cards',
};
