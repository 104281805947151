import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  grid: {
    padding: 25,
  },
  grow: {
    flexGrow: 1,
  },
  margin: {
    marginBottom: 40,
  },
});
