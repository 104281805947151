import { TableColumnConfig } from 'components/Table/types';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { formatCard } from 'pages/Client/PaymentMethod/utils';
import { useCallback, useMemo } from 'react';
import { BILL_STATUS, PAYMENT_METHODS } from 'types/enums';
import {
  capitalizeFirstLetter,
  formatBRL,
  formatPaymentMethodCode,
} from 'utils';
import { formatDatePtBr, formatDateTimePtBr } from 'utils/formatDatePtBr';
import { TABLE_HEADERS } from 'utils/tableHeaders';
import { ButtonBillActions } from './components/ButtonBillActions';
import { DEFAULT_TABLE_VALUE } from './consts';
import { SubscriptionTableData, SubscriptionTableProperties } from './types';

export const useSubscriptionTableController = ({
  loadPayment,
  subscription,
  paymentMethodCode,
}: Omit<SubscriptionTableProperties, 'loading'>) => {
  const pageHeaderTitle = useMemo(
    () =>
      paymentMethodCode === PAYMENT_METHODS.CARD
        ? 'Cartão de crédito'
        : 'Parcelas',
    [paymentMethodCode],
  );

  const pageHeaderSubtitle = useMemo(
    () =>
      `${subscription.card?.alias || ''} ${
        formatCard(subscription.card?.lastFour) || ''
      }`,
    [subscription.card?.alias, subscription.card?.lastFour],
  );

  const goToVindiSubscription = useCallback(() => {
    window.open(
      `${process.env.REACT_APP_VINDI_DASHBOARD_URL}/subscriptions/${subscription.externalId}`,
    );
  }, [subscription.externalId]);

  // ---------- Callbacks ----------
  const handleRenderAdminActionButton = useCallback(
    (_formattedValue: any, billData: SubscriptionTableData) => {
      return (
        <ButtonBillActions billData={billData} onReloadPayment={loadPayment} />
      );
    },
    [loadPayment],
  );

  // ---------- Memos ----------
  const subscriptionTableConfig: TableColumnConfig<SubscriptionTableData>[] =
    useMemo(
      () =>
        [
          {
            headerName: TABLE_HEADERS.INSTALLMENT,
            getter: 'billInstallmentNumber',
            defaultValue: DEFAULT_TABLE_VALUE,
            onFormat: billInstallmentNumber =>
              `${billInstallmentNumber}/${subscription.installments}`,
          },
          {
            headerName: TABLE_HEADERS.BILLING_MONTH,
            getter: 'billingDate',
            defaultValue: DEFAULT_TABLE_VALUE,
            onFormat: billingDate =>
              capitalizeFirstLetter(
                format(new Date(billingDate), 'LLLL', {
                  locale: ptBR,
                }),
              ),
          },
          {
            headerName: TABLE_HEADERS.PAYMENT_DATE,
            getter: 'paidAt',
            defaultValue: DEFAULT_TABLE_VALUE,
            onFormat: formatDatePtBr,
          },
          {
            headerName: TABLE_HEADERS.STATUS,
            getter: 'status',
            defaultValue: DEFAULT_TABLE_VALUE,
            onFormat(value, data) {
              if (
                [BILL_STATUS.REJECTED, BILL_STATUS.CANCELED].includes(
                  data.status,
                ) &&
                data.lastTransactionReason
              ) {
                return `${value} (${data.lastTransactionReason})`;
              }

              return value;
            },
          },
          {
            headerName: TABLE_HEADERS.PAYMENT_METHOD,
            getter: 'paymentMethodCode',
            defaultValue: DEFAULT_TABLE_VALUE,
            onFormat: formatPaymentMethodCode,
          },
          {
            headerName: TABLE_HEADERS.VALUE,
            getter: 'value',
            defaultValue: DEFAULT_TABLE_VALUE,
            onFormat: billValue => formatBRL(billValue),
          },
          {
            headerName: TABLE_HEADERS.LAST_UPDATE,
            getter: 'updatedAt',
            onFormat: formatDateTimePtBr,
          },
          {
            headerName: TABLE_HEADERS.ACTIONS,
            getter: '',
            customRender: handleRenderAdminActionButton,
          },
        ] as TableColumnConfig<SubscriptionTableData>[],
      [handleRenderAdminActionButton, subscription.installments],
    );

  const subscriptionTableData: SubscriptionTableData[] = useMemo(
    () =>
      subscription.bills.map((billData, index) => ({
        ...billData,
        billInstallmentNumber: index + 1,
      })) as SubscriptionTableData[],
    [subscription.bills],
  );

  return {
    // Page header
    pageHeaderTitle,
    pageHeaderSubtitle,

    // Admin actions
    goToVindiSubscription,

    // Table data
    subscriptionTableData,
    subscriptionTableConfig,
  };
};
