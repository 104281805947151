import { MenuProps } from '@material-ui/core';
import { useServiceCreationController } from 'pages/Admin/Marketplace/Services/ServiceCreation/controller';
import { useEffect, useRef, useState } from 'react';
import SchoolClassesService from 'services/schoolClassesService';
import ServicesService from 'services/servicesService';

import { ISelectItem } from 'components/Forms/SelectInput/types';
import PedagogicSubjectService from 'services/pedagogicSubjects';
import { MenuProps as MenuPropsStructure } from '../../styles';
import { SelectState } from '../../types';

export const useGeneralDataFormController = () => {
  // General Hooks
  const { renderValue } = useServiceCreationController();

  // Refs
  const inputRefMenuDimensions = useRef<HTMLInputElement>(null);

  // States
  const [menuProps, setMenuProps] = useState<Partial<MenuProps>>();
  const [pedagogicSubjects, setPedagogicSubjects] = useState<
    Array<ISelectItem>
  >([
    {
      id: 1,
      label: 'Matemática',
    },
  ]);
  const [serviceCategories, setServiceCategories] = useState<
    Array<SelectState>
  >([]);
  const [servicePublicationStatus, setServicePublicationStatus] = useState<
    Array<SelectState>
  >([]);
  const [servicesTypes, setServicesTypes] = useState<Array<SelectState>>([]);
  const [currencyRecurrence, setCurrencyRecurrence] = useState<
    Array<SelectState>
  >([]);
  const [schoolClasses, setSchoolClasses] = useState<Array<SelectState>>([]);

  // Callbacks
  const getResources = async () => {
    const statusPromise = ServicesService.getStatus();
    const categoriesPromise = ServicesService.getCategories();
    const typesPromise = ServicesService.getTypes();
    const pedagogicSubjectsPromise =
      PedagogicSubjectService.getMarketplaceComplementaryCurriculumOptions();
    const schoolClassesPromise = SchoolClassesService.getSchoolClasses({
      limit: 50,
    });
    const currencyRecurrencePromise = ServicesService.getCurrencyRecurrence();
    const [
      categories,
      types,
      currencyRecurrence,
      publicationStatus,
      schoolClasses,
      pedagogicSubjectsResults,
    ] = await Promise.all([
      categoriesPromise,
      typesPromise,
      currencyRecurrencePromise,
      statusPromise,
      schoolClassesPromise,
      pedagogicSubjectsPromise,
    ]);

    setServiceCategories(categories.data.data);
    setServicesTypes(types.data.data || []);
    setCurrencyRecurrence(currencyRecurrence.data.data || []);
    setServicePublicationStatus(publicationStatus.data.data || []);
    setSchoolClasses(schoolClasses.data.data || []);
    setPedagogicSubjects(
      (pedagogicSubjectsResults.data || []).map(item => ({
        id: item.id,
        label: item.name,
      })),
    );
  };

  useEffect(() => {
    if (inputRefMenuDimensions?.current) {
      setMenuProps(
        MenuPropsStructure(inputRefMenuDimensions.current.clientWidth + 1),
      );
    }
  }, [inputRefMenuDimensions]);

  useEffect(() => {
    getResources();
  }, []);

  return {
    pedagogicSubjects,
    inputRefMenuDimensions,
    schoolClasses,
    MenuProps: menuProps,
    servicesTypes,
    serviceCategories,
    renderValue,
    currencyRecurrence,
    servicePublicationStatus,
  };
};
