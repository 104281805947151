import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  item: {
    height: 50,
  },
  cardGrid: {
    width: 500,
    margin: '15px 0px',
  },
});
