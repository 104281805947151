import { TextField } from '@material-ui/core';
import { ResponsiveInputGridItem } from 'components/ResponsiveInputGridItem';
import React from 'react';
import { Controller } from 'react-hook-form';
import useGlobalStyles from 'styles';
import { FormProperties } from './types';

const RGForm: React.FC<FormProperties> = ({
  rg,
  documents,
  editing,
  register,
  errors,
  control,
  hasGuardianDocuments,
}) => {
  const classes = useGlobalStyles();

  return (
    <>
      <ResponsiveInputGridItem>
        <Controller
          name="rg.rgNumber"
          control={control}
          as={({ value, onChange }) => (
            <TextField
              variant="outlined"
              label="RG"
              id="rg.rgNumber"
              name="rg.rgNumber"
              value={documents?.rg?.rgNumber || value}
              onChange={onChange}
              error={!!errors.rg?.rgNumber}
              helperText={
                errors.rg?.rgNumber ? errors.rg?.rgNumber.message : null
              }
              inputProps={{ maxLength: 15 }}
              disabled={hasGuardianDocuments || !editing}
              inputRef={register}
              className={classes.maxWidthInput}
            />
          )}
          defaultValue={rg?.rgNumber}
        />
      </ResponsiveInputGridItem>
      <ResponsiveInputGridItem>
        <Controller
          name="rg.emitter"
          control={control}
          as={({ value, onChange }) => (
            <TextField
              variant="outlined"
              label="Orgão Emissor"
              id="rg.emitter"
              name="rg.emitter"
              value={documents?.rg?.emitter || value}
              onChange={onChange}
              error={!!errors.rg?.emitter}
              helperText={
                errors.rg?.emitter ? errors.rg?.emitter.message : null
              }
              disabled={hasGuardianDocuments || !editing}
              inputRef={register}
              className={classes.maxWidthInput}
            />
          )}
          defaultValue={rg?.emitter}
        />
      </ResponsiveInputGridItem>
    </>
  );
};

export default RGForm;
