import { TIconNames } from 'components/CustomIcon/types';
import { ChangeEvent } from 'react';

export type RouteParams = { serviceId: string; registrationId: string };

export enum EServiceVariationsNames {
  BY_INVITE = 'by-invite',
  WITHOUT_COST = 'without-cost',
  WITH_COST = 'with-cost',
  EVENT = 'event',
}

export type ServiceVariations =
  | EServiceVariationsNames.BY_INVITE
  | EServiceVariationsNames.WITHOUT_COST
  | EServiceVariationsNames.WITH_COST
  | EServiceVariationsNames.EVENT;

export type CoverImageCaptionContainerStyledProps = {
  coverType: ServiceVariations;
};

export interface IModalInfoShowInterestProps {
  open: boolean;
  onClose: () => void;
}

export interface IModalInsertInviteCodeProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  value: string;
  onChange: (
    changeEvent: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  isLoading?: boolean;
}

export type BannerVariant = 'primary' | 'secondary' | 'warning';

export interface IBannerProps {
  variant: BannerVariant;
  text: string;
  toolTipText?: string;
  iconName?: TIconNames;
  mini?: boolean;
}
export interface BannerData {
  color?: string;
  iconName?: TIconNames;
  text?: string;
}
export interface ICoverProps {
  coverPhoto: string;
  serviceIsEarlyAccess?: boolean;
  serviceIsOnlyByInviteCode?: boolean;
  serviceClassIsSoldOut?: boolean;
}

export interface ICoverControllerProps {
  earlyAccess?: boolean;
  isSelectedServiceClassFull?: boolean;
}

export interface StyledGridProps {
  variant?: string;
  mini?: boolean;
}

export interface IModalInsertServiceCodeProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (data: PayloadValidateServiceSubmit) => void;
  isLoading: boolean;
}

export type HeaderContentProps = Pick<
  Service,
  | 'amount'
  | 'serviceRecurrence'
  | 'numberOfInstallments'
  | 'inviteCode'
  | 'serviceType'
>;

export type DescriptionAvatar = Pick<
  Service,
  'amount' | 'partnerLogo' | 'inviteCode' | 'serviceType'
>;

export type CoverImageStyledProps = { coverImage: string };

export type Service = {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: any;
  name: string;
  serviceTypeId: number;
  serviceRecurrenceId: number;
  servicePublicationStatusId: number;
  responsibleCoordinator: string;
  totalHours: any;
  numberOfSpots?: any;
  startDate: string;
  endDate: string;
  timeType?: any;
  amount: number;
  registrationTaxValue: number;
  numberOfInstallments: number;
  paymentLimitDate: Date;
  partnerId: number;
  partnerLogo: string;
  description: string;
  coverPhoto: string;
  color: string;
  earlyAccess: boolean;
  videoLink: string;
  inviteCode?: string;
  vindiProductId: number;
  vindiPlanId: number;
  serviceType: ServiceType;
  serviceRecurrence: ServiceCategory;
  contractTemplateId?: number;
  serviceCalendars: ServiceCalendar[];
  invoiceServiceCode?: string;
};

export interface IServiceDetailsResponse extends Service {
  pedagogicSubjectId?: number;
  partner: {
    id: number;
    name: string;
  };
  galleryImages: GalleryImage[];
  serviceCategories: ServiceCategory[];
  servicePublicationStatus: ServiceCategory;
  schoolClasses: SchoolClass[];
  serviceClasses: ServiceClass[];
}

export interface ServiceClass {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: any;
  name: string;
  spotsNumber: number;
  spotsFilled: number;
  timeType: string;
  startDate: string;
  endDate: string;
  earlyAccess: boolean;
  serviceId: number;
  schoolClasses: SchoolClass[];
  calendars: ServiceCalendar[];
}

interface SchoolClass {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: any;
  name: string;
  currentYearValue: number;
  nextYearValue: number;
}

interface ServiceType {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: any;
  updatedByUserId?: any;
  createdByUserId?: any;
  name: string;
  onlyByInvite: boolean;
  hasAnnuity: boolean;
}

export interface ServiceCategory {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: any;
  name: string;
}

interface ServiceCalendar {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: any;
  day: string;
  startTime: string;
  endTime: string;
  serviceId: number;
  serviceClassId: number;
  schedule: string;
}

export interface GalleryImage {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: any;
  source: string;
  serviceId: number;
}

export interface SelectServiceClass extends ServiceClass {
  isSelected: boolean;
}

export interface PayloadValidateServiceSubmit {
  serviceCode: string;
  serviceId?: number;
}

export interface IServiceClassConflictResponse {
  hasConflict: boolean;
  title: string;
  description?: string;
}

export interface ServiceInfosProps {
  isLoading?: boolean;
  service: IServiceDetailsResponse;
  selectServiceClass: SelectServiceClass[];
  onChangeSelectServiceClass: (serviceClassId: number) => void;
  serviceConflict: IServiceClassConflictResponse;
  selectedServiceClass?: SelectServiceClass;
}
