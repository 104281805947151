import Grid from '@material-ui/core/Grid';

import { Controller } from 'react-hook-form';
import { mask } from 'remask';
import { ControllerSelectInput } from 'components/Forms/ControllerSelectInput/ControllerSelectInput';
import { InputText } from '../../styles';

import { useGeneralDataFormController } from './controller';
import { IGeneraFormProps } from '../../types';

export const GeneralDataForm = ({
  control,
  register,
  errors,
  partnerToUpdate,
}: IGeneraFormProps) => {
  const { serviceCategories, inputRefMenuDimensions } =
    useGeneralDataFormController();

  return (
    <Grid container spacing={2} md={11}>
      <Grid item md={4}>
        <Controller
          name="name"
          control={control}
          defaultValue={partnerToUpdate?.name}
          as={({ value, onChange }) => (
            <InputText
              ref={inputRefMenuDimensions}
              id="name"
              name="name"
              label="Nome"
              variant="outlined"
              value={value}
              defaultValue={value}
              onChange={onChange}
              error={!!errors.name}
              helperText={errors.name ? errors.name?.message : null}
              inputRef={register}
            />
          )}
        />
      </Grid>
      <Grid item md={4}>
        <Controller
          name="cnpj"
          control={control}
          defaultValue={
            partnerToUpdate?.cnpj
              ? mask(partnerToUpdate?.cnpj, '99.999.999/9999-99')
              : ''
          }
          as={({ value, onChange }) => (
            <InputText
              ref={inputRefMenuDimensions}
              id="cnpj"
              name="cnpj"
              label="CNPJ"
              variant="outlined"
              value={value}
              defaultValue={value}
              onChange={({ target }) => {
                onChange(mask(target.value, '99.999.999/9999-99'));
              }}
              error={!!errors.cnpj}
              helperText={errors.cnpj ? errors.cnpj?.message : null}
              inputRef={register}
            />
          )}
        />
      </Grid>
      <Grid item md={4}>
        <ControllerSelectInput
          name="category"
          label="Área do serviço"
          control={control}
          defaultValue={partnerToUpdate?.serviceCategories?.map(({ id }) => id)}
          errors={errors}
          options={serviceCategories.map(type => ({
            id: type.id,
            label: type.name,
          }))}
          register={register}
          isMultiple
        />
      </Grid>

      <Grid item md={4}>
        <Controller
          name="email"
          control={control}
          defaultValue={partnerToUpdate?.email}
          as={({ value, onChange }) => (
            <InputText
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              value={value}
              onChange={onChange}
              error={!!errors.email}
              helperText={errors.email ? errors.email?.message : null}
              inputRef={register}
            />
          )}
        />
      </Grid>
      <Grid item md={4}>
        <Controller
          name="phone"
          control={control}
          defaultValue={partnerToUpdate?.phone}
          as={({ value, onChange }) => (
            <InputText
              id="phone"
              name="phone"
              label="Telefone"
              variant="outlined"
              value={value}
              onChange={({ target }) => {
                onChange(mask(target.value, '(99) 99999-9999'));
              }}
              error={!!errors.phone}
              helperText={errors.phone ? errors.phone?.message : null}
              inputRef={register}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
