import { CondOperator } from '@nestjsx/crud-request';
import { useAlert } from 'hooks/Alert';
import { MESSAGE_TYPE } from 'hooks/Alert/types';
import { useFilters } from 'hooks/filter';
import { StatusCodes } from 'http-status-codes';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ServiceClassesService from 'services/serviceClassesService';
import ServicesCodes from 'services/serviceCodes';
import ServicesService from 'services/servicesService';
import useGlobalStyles from 'styles';
import {
  CREATE_SERVICE_CODES_ERROR,
  CREATE_SERVICE_CODES_SUCCESS,
  LOAD_SERVICES_CLASSES_ERROR,
  LOAD_SERVICES_CODES_ERROR,
  LOAD_SERVICES_ERROR,
  REMOVE_SERVICE_CODE_ERROR,
  REMOVE_SERVICE_CODE_SUCCESS,
} from 'texts/messages/services';
import { ServiceCodes } from 'types/serviceCodes';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { ROWS_PER_PAGE } from 'utils';
import { IServiceResponse } from '../Services/components/ServiceForm/types';
import { useStyles } from './styles';

export const useServiceCodesController = () => {
  // Styles
  const classes = useGlobalStyles();
  const styles = useStyles();
  const { state }: any = useLocation();

  // Query Hooks
  const [query, setQuery] = useQueryParams({
    rows: withDefault(NumberParam, ROWS_PER_PAGE[0]),
    search: StringParam,
  });

  // States
  const [serviceCodes, setServiceCodes] = useState<ServiceCodes[]>([]);
  const [services, setServices] = useState<IServiceResponse[]>([]);
  const [servicesClasses, setServicesClasses] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);
  const [total, setTotal] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteServiceCodeModal, setOpeDeleteServiceCodeModal] =
    useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idServiceCode, setIdServiceCode] = useState(0);
  const [selectedServiceId, setSelectedServiceId] = useState('');
  const [selectedServiceClassId, setSelectedServiceClassId] = useState('');

  // Custom hooks
  const { openAlert, closeAlert, isShowAlert, alertType, alertMessage } =
    useAlert();
  const { page, handleSetValuePage, filterFields, handleUpdateFilters } =
    useFilters();

  const handleDispatchAlertError = useCallback(
    (message: string) => {
      openAlert({
        message,
        type: MESSAGE_TYPE.ERROR,
      });
      setTimeout(() => {
        closeAlert();
      }, 2000);
    },
    [closeAlert, openAlert],
  );

  const handleDispatchAlertSuccess = useCallback(
    (message: string) => {
      openAlert({
        message,
        type: MESSAGE_TYPE.SUCCESS,
      });
      setTimeout(() => {
        closeAlert();
      }, 2000);
    },
    [closeAlert, openAlert],
  );

  const handleChangeServiceId = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleUpdateFilters(
      'serviceId',
      { value: e.target.value },
      CondOperator.EQUALS,
    );
    setSelectedServiceId(e.target.value);
  };

  const handleChangeServiceClassId = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    handleUpdateFilters(
      'serviceClassId',
      { value: e.target.value },
      CondOperator.EQUALS,
    );
    setSelectedServiceClassId(e.target.value);
  };

  const loadServices = useCallback(async () => {
    try {
      const { data: response, status } = await ServicesService.getServices({
        join: [{ field: 'service', select: ['id', 'name'] }],
      });

      if (status === StatusCodes.OK) {
        setServices(response.data);
      } else {
        throw new Error(LOAD_SERVICES_ERROR);
      }
    } catch (error: any) {
      handleDispatchAlertError(error.message);
    }
  }, [handleDispatchAlertError]);

  const loadServicesClasses = useCallback(
    async (serviceId?: number) => {
      try {
        const filter = [];
        if (serviceId) {
          filter.push({
            field: 'serviceId',
            operator: CondOperator.EQUALS,
            value: serviceId,
          });
        }
        const { data: response, status } =
          await ServiceClassesService.getServiceClasses({
            fields: ['id', 'name'],
            filter: [...filter],
          });

        if (status === StatusCodes.OK) {
          setServicesClasses(response.data);
        } else {
          throw new Error(LOAD_SERVICES_CLASSES_ERROR);
        }
      } catch (error: any) {
        handleDispatchAlertError(error.message);
      }
    },
    [handleDispatchAlertError],
  );

  const loadServiceCodes = useCallback(async () => {
    try {
      setLoading(true);
      const { data: response, status } = await ServicesCodes.getAllCodeServices(
        {
          page: page + 1,
          limit: query.rows,
          sort: [{ field: 'id', order: 'DESC' }],
          search: {
            $and: [...filterFields],
          },
          join: [
            { field: 'service', select: ['name'] },
            { field: 'serviceClass', select: ['name'] },
          ],
        },
      );

      if (status === StatusCodes.OK) {
        setServiceCodes(response.data);
        setTotal(response.total);
      } else {
        throw new Error(LOAD_SERVICES_CODES_ERROR);
      }
    } catch (error: any) {
      handleDispatchAlertError(error.message);
    } finally {
      setLoading(false);
    }
  }, [filterFields, query.rows, page, handleDispatchAlertError]);

  const onSubmit = async (data: any) => {
    try {
      setLoadingButton(true);
      const response = await ServicesCodes.createCodeServices({
        codesQuantity: data.codesQuantity,
        serviceId: data.serviceId,
      });

      if (response.status === StatusCodes.CREATED) {
        handleDispatchAlertSuccess(CREATE_SERVICE_CODES_SUCCESS);
        handleCloseModal();
        await loadServiceCodes();
      } else {
        throw new Error(CREATE_SERVICE_CODES_ERROR);
      }
    } catch (error: any) {
      handleDispatchAlertError(error.message);
    } finally {
      setLoadingButton(false);
    }
  };

  const handleDeleteServiceCode = async (id: number) => {
    try {
      setLoadingButton(true);
      const { status } = await ServicesCodes.deleteCodeService(id);
      if (status === StatusCodes.OK) {
        handleDispatchAlertSuccess(REMOVE_SERVICE_CODE_SUCCESS);
        handleCloseDeleteServiceCodeModal();
        await loadServiceCodes();
      } else {
        throw new Error(REMOVE_SERVICE_CODE_ERROR);
      }
    } catch (error: any) {
      handleDispatchAlertError(error.message);
    } finally {
      setLoadingButton(false);
    }
  };

  const onChangeRowsPerPage = ({ target }: ChangeEvent<HTMLInputElement>) => {
    handleSetValuePage(0);
    setQuery({ rows: parseInt(target.value, 10) });
  };

  const onChangePage = (_event: unknown, newPage: number) => {
    handleSetValuePage(newPage);
  };

  const goToCreateServiceCodes = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setServicesClasses([]);
    setOpenModal(false);
  };

  const handleOpenDeleteServiceCodeModal = (id: number) => {
    setIdServiceCode(id);
    setOpeDeleteServiceCodeModal(true);
  };

  const handleCloseDeleteServiceCodeModal = () => {
    setOpeDeleteServiceCodeModal(false);
  };

  // Effects
  useEffect(() => {
    loadServiceCodes();
  }, [loadServiceCodes]);

  useEffect(() => {
    loadServices();
  }, [loadServices]);

  useEffect(() => {
    if (state?.serviceId) {
      handleUpdateFilters(
        'serviceId',
        { value: state.serviceId },
        CondOperator.EQUALS,
      );
      setSelectedServiceId(String(state.serviceId));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedServiceId) {
      loadServicesClasses(Number(selectedServiceId));
    } else {
      loadServicesClasses();
    }
  }, [loadServicesClasses, selectedServiceId]);

  return {
    classes,
    styles,
    serviceCodes,
    query,
    alertMessage,
    page,
    total,
    onChangeRowsPerPage,
    onChangePage,
    goToCreateServiceCodes,
    openModal,
    handleCloseModal,
    loadServiceCodes,

    services,
    servicesClasses,
    loadServices,
    handleChangeServiceId,

    closeAlert,
    isShowAlert,
    alertType,
    loadingButton,
    onSubmit,

    openDeleteServiceCodeModal,
    handleCloseDeleteServiceCodeModal,
    handleOpenDeleteServiceCodeModal,
    idServiceCode,
    handleDeleteServiceCode,
    selectedServiceId,
    handleChangeServiceClassId,
    selectedServiceClassId,

    loading,
  };
};
