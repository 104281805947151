export const NO_DEPENDENTS = 'Você não possui nenhum dependente cadastrado.';
export const NO_PROCESSING_REGISTRATIONS =
  'Você não possui nenhuma matrícula em andamento.';
export const NO_ACTIVE_REGISTRATIONS =
  'Você não possui nenhuma matrícula ativa.';
export const NO_PLANS = 'Não há pagamentos para serem exibidos.';
export const ADM_NO_REGISTRATIONS = 'Não há matrículas para serem exibidas.';
export const NO_CARDS = 'Você não possui nenhum cartão cadastrado.';
export const NO_COUPONS = 'Não há cupons para serem exibidos.';
export const NO_COUPON_DETAILS = 'Não há detalhes para serem exibidos.';
export const NO_USERS = 'Não há usuários para serem exibidos.';
export const NO_SCHOOL_CLASSES = 'Não há turmas para serem exibidas.';
export const NO_MARKETPLACE_SERVICES = 'Não há serviços no momento.';
