import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  ButtonProps,
  Grid,
  makeStyles,
  Paper,
  styled,
  TableBody,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  darkGray,
  error,
  infoMain,
  mediumGray,
  primaryDark,
  white,
} from 'styles/theme';
import { LabelStyledProps } from './types';

export const DescriptionResumeName = styled(Typography)(({ theme }) => {
  return {
    fontSize: 24,
    fontWeight: 'bold',
    color: darkGray,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
  };
});

export const DescriptionResumeAvatar = styled(Avatar)(() => {
  return {
    height: 36,
    width: 36,
    marginRight: 13,
  };
});

export const Container = styled(Grid)(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  };
});

const DescriptionPaperContainer = styled(Box)(({ theme }) => {
  return {
    backgroundColor: '#fff',
    padding: '30px 12px 30px 30px',
    borderRadius: 10,
    height: '209px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  };
});

export const DescriptionPaperContainerClasses = styled(
  DescriptionPaperContainer,
)(() => {
  return {
    minWidth: '246px',
    minHeight: '230px',
  };
});

export const DetailsButton = styled(ButtonBase)<Theme>(() => ({
  color: primaryDark,
  fontSize: '14px',
}));

export const Label = styled('span')<Theme, LabelStyledProps>(
  ({ backgroundColor, color }) => ({
    backgroundColor,
    padding: '4.5px 9px',
    borderRadius: 100,
    fontSize: 10,
    fontWeight: 700,
    color,
    textTransform: 'uppercase',
    letterSpacing: '0.13rem',
  }),
);

export const TableBodyContainer = styled(TableBody)(() => ({
  borderTop: `1px solid ${infoMain}`,
}));

export const PaperContainer = styled(Paper)(() => ({
  padding: 30,
  borderRadius: 10,
}));

export const CardTitle = styled(Typography)(() => ({
  color: mediumGray,
  marginBottom: 10,
}));

export const DescriptionSpotsButton = styled(Button)<Theme, ButtonProps>(
  ({ variant }) => {
    return {
      fontWeight: 'bold',
      fontSize: 14,
      borderRadius: 8,
      textTransform: 'none',
      backgroundColor: variant === 'text' ? 'transparent' : primaryDark,
      color: variant === 'text' ? primaryDark : white,
      padding: variant === 'text' ? 0 : '14px 20px',
      '&:hover': {
        opacity: 0.8,
        transition: 'opacity 0.2s linear',
        backgroundColor: variant === 'text' ? 'transparent' : primaryDark,
      },
    };
  },
);

export const useStyles = makeStyles({
  detailButton: {
    marginLeft: 25,
  },
  marginButton: {
    marginTop: 30,
  },
  vecanciesSoldOutText: {
    width: 165,
    marginTop: 5,
    color: error,
  },
  errorColor: {
    color: error,
  },
  mediumGrayColor: {
    color: mediumGray,
  },
  circularProgress: {
    minHeight: '80vh',
  },
  quantitySpotsText: {
    fontWeight: 700,
    marginTop: 5,
  },
});
