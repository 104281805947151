import { CreateQueryParams } from '@nestjsx/crud-request';
import { api, getWithFilter } from 'services/apiService';
import { Coupon } from 'types/coupon';

export default class CouponService {
  static async coupons() {
    try {
      return await api.get('coupons');
    } catch (error: any) {
      return error.response;
    }
  }

  static async coupon(id: number) {
    try {
      return await api.get(`coupons/${id}`);
    } catch (error: any) {
      return error.response;
    }
  }

  static async couponDetail(id: number) {
    try {
      return await api.get(`coupons/${id}/details`);
    } catch (error: any) {
      return error.response;
    }
  }

  static async filterCoupons(query?: CreateQueryParams) {
    try {
      return await getWithFilter('/coupons', query);
    } catch (error: any) {
      return error.response;
    }
  }

  static async createCoupon(coupon: Partial<Coupon>) {
    try {
      return await api.post('coupons', coupon);
    } catch (error: any) {
      return error.response;
    }
  }

  static async updateCoupon(coupon: Partial<Coupon>, id: number) {
    try {
      return await api.patch(`coupons/${id}`, coupon);
    } catch (error: any) {
      return error.response;
    }
  }

  static async deleteCoupon(id: number) {
    try {
      return await api.delete(`coupons/${id}`);
    } catch (error: any) {
      return error.response;
    }
  }

  // TODO: colocar DTO
  static async couponValidation(couponName: string, serviceId: number) {
    try {
      return await api.post(`coupons/validate`, {
        couponName,
        serviceId,
      });
    } catch (error: any) {
      return error.response;
    }
  }
}
