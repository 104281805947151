import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Divider,
  Grid,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import { MailOutline } from '@material-ui/icons';
import Modal from 'components/Modal';
import { PublicPageContainer } from 'components/PublicPageContainer';
import { PublicPageHeader } from 'components/PublicPageHeader';
import SpinnerButton from 'components/SpinnerButton';
import { StatusCodes } from 'http-status-codes';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import AuthService from 'services/authService';
import useGlobalStyles from 'styles';
import { InputRef } from 'types/FormTypes';
import * as Yup from 'yup';
import { Container } from '../Login/styles';

const RedefinePassword: React.FC = () => {
  const classes = useGlobalStyles();
  const history = useHistory();
  const email = useRef<InputRef>();
  const [modal, setModal] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string().email('Email inválido.').required('Informe seu email.'),
  });

  const { handleSubmit, errors, setError, register } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onBlur',
  });

  function modalClose() {
    setModal(false);
    history.push('/login');
  }

  function onSubmit(data: any) {
    sendEmail(data);
  }

  async function sendEmail(formData: any) {
    const response = await AuthService.recoverPassword(formData.email);
    if (response.data.status !== StatusCodes.OK) {
      setError('email', {
        message: 'Email não cadastrado.',
      });
    } else {
      setModal(true);
    }
  }

  return (
    <>
      <Modal
        icon={<MailOutline color="primary" className={classes.dialogIcon} />}
        open={modal}
        text="Sua solicitação foi enviada com sucesso!"
        subtext="Um e-mail foi enviado para você, siga as instruções para recuperar sua senha."
        btnText="Fechar"
        btnAction={() => modalClose()}
      />
      <PublicPageContainer>
        <Container>
          <PublicPageHeader
            title={
              <>
                <b>Redefinir</b> a senha
              </>
            }
            subtitle=" Para redefinir sua senha, insira seu e-mail. Você receberá um e-mail
          com as instruções para prosseguir."
          />
          <Grid item className={classes.publicScreenMargin1}>
            <TextField
              variant="outlined"
              label="Email"
              id="email"
              name="email"
              inputProps={{ ref: email }}
              error={Boolean(errors.email)}
              helperText={errors.email ? errors.email.message : null}
              inputRef={register}
              className={classes.publicScreenInput}
            />
          </Grid>
          <SpinnerButton
            text="Enviar"
            className={classes.loginButton}
            action={handleSubmit(onSubmit)}
          />
          <Grid item className={classes.publicScreenMargin3}>
            <Divider className={classes.publicScreenDivider} />
          </Grid>
          <Link to="/login">
            <Button color="primary" className={classes.textButton}>
              Voltar
            </Button>
          </Link>
        </Container>
      </PublicPageContainer>
    </>
  );
};

export default RedefinePassword;
