import React from 'react';

import { Box, Button } from '@material-ui/core';
import PageHeader from 'components/PageHeader';
import { CustomDivider, PartnersHomeGridContainer } from './styles';

import { usePartnersController } from './controller';
import { ServiceSolicitationsTable } from '../Services/components/ServicesSolicitationsTable';
import { PartnersTable } from './components/PartnersTable';

export const Partners = () => {
  const {
    classes,
    partners,
    serviceSolicitations,
    navigateToPartnersCreation,
  } = usePartnersController();
  return (
    <Box paddingX="45px">
      <PartnersHomeGridContainer container item direction="column">
        <PageHeader
          title="Gestão de "
          titleBoldSufix="parceiros"
          subtitle="Tenha acesso e gestione as informações sobre parceiros"
          button={
            <Button
              variant="contained"
              color="primary"
              className={classes.listScreenButton}
              onClick={navigateToPartnersCreation}
            >
              Novo parceiro
            </Button>
          }
        />

        <PartnersTable partners={partners} />
        {/* //FIXME
        Comentando pra ficar coerente com figma. Faz parte da funcionalidade
        de Fluxo de solicatacao de turmas que vai entrar mais pra frente */}
        {/* <CustomDivider />
        
        <PageHeader
          titleBoldPrefix="Solicitações"
          title=" de publicação de serviços"
          subtitle="Aprove ou recuse solicitações dos parceiros"
        />
        <ServiceSolicitationsTable
          serviceSolicitations={serviceSolicitations}
        /> */}
      </PartnersHomeGridContainer>
    </Box>
  );
};
