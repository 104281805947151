export const currencyMaskOptions = {
  prefix: 'R$ ',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2,
  integerLimit: 20,
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const percentMaskOptions = {
  prefix: '',
  suffix: '%',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2,
  integerLimit: 20,
  allowNegative: false,
  allowLeadingZeroes: false,
};
