import { makeStyles } from '@material-ui/core';
import { error } from 'styles/theme';
import { REGISTRATION_STATUS } from 'types/enums';
import { registrationStatusColors } from 'utils';

interface StyleProperties {
  status: REGISTRATION_STATUS | string;
}

export const useStyles = ({ status }: StyleProperties) =>
  makeStyles(theme => ({
    button: {
      backgroundColor: registrationStatusColors(status),
      borderRadius: 100,
      padding: '4.5px 15px',
      textTransform: 'uppercase',
      letterSpacing: '0.13rem',
    },
    label: {
      fontWeight: 'bold',
      color:
        registrationStatusColors(status) === error
          ? 'white'
          : theme.palette.primary.main,
      [theme.breakpoints.down('sm')]: {
        fontSize: 11,
      },
    },
  }));
