import axios, { AxiosError } from 'axios';
import { CustomDialogRef } from 'components/CustomDialog/types';
import { useAlert } from 'hooks/Alert';
import { StatusCodes } from 'http-status-codes';
import { ChangeEvent, useCallback, useRef, useState } from 'react';
import CouponService from 'services/couponService';
import { INVALID_COUPON, COUPON_APPLIED } from 'texts';
import { MESSAGE_TYPE } from 'hooks/Alert/types';
import { Coupon } from 'types/coupon';
import { PAYMENT_METHODS } from 'types/enums';
import { calculateCouponDiscountForEachPaymentMethod } from '../utils';
import { ServiceComponentControllerProps } from './types';

// FIXME: organize stuff
export const useServiceComponentController = ({
  subscription,
  serviceId,
  serviceValue,
  handleChangeSubscription,
}: ServiceComponentControllerProps) => {
  // ---------- Custom Hooks ----------
  const { openAlert, closeAlert, alertMessage, alertType, isShowAlert } =
    useAlert();

  // ---------- Refs ----------
  const warningChangePaymentMethodModal = useRef<CustomDialogRef>(null);

  // ---------- States ----------
  const [calculatedValue, setCalculatedValue] = useState(serviceValue);
  const [coupon, setCoupon] = useState<Coupon | null>(null);
  const [loading, setLoading] = useState(false);
  const [couponName, setCouponName] = useState<string>('');

  const [paymentMethodCodeToBeChanged, setPaymentMethodCodeToBeChanged] =
    useState<PAYMENT_METHODS>();

  // ---------- Callbacks ----------
  const loadCoupon = useCallback(async () => {
    try {
      setLoading(true);
      if (couponName) {
        const response = await CouponService.couponValidation(
          couponName,
          serviceId,
        );

        if (response.status !== StatusCodes.CREATED) {
          openAlert({
            message: response?.data?.message || INVALID_COUPON,
            type: MESSAGE_TYPE.ERROR,
          });

          setCouponName('');
        } else {
          openAlert({
            message: COUPON_APPLIED,
            type: MESSAGE_TYPE.SUCCESS,
          });
          const discountedValue = calculateCouponDiscountForEachPaymentMethod(
            serviceValue,
            response.data,
            PAYMENT_METHODS.CARD,
          );

          if (discountedValue) {
            setCalculatedValue(discountedValue.currentValue);
          }

          setCoupon(response.data);
          handleChangeSubscription(serviceId, {
            couponName: response.data.name,
          });
        }
      }
    } catch (error: any | AxiosError) {
      let mesageError;
      if (axios.isAxiosError(error)) {
        mesageError = error?.response?.data.message;
      } else {
        mesageError = error?.message;
      }
      openAlert({
        message: mesageError,
        type: MESSAGE_TYPE.ERROR,
      });
    } finally {
      setLoading(false);
    }
  }, [
    couponName,
    serviceId,
    serviceValue,
    openAlert,
    handleChangeSubscription,
  ]);

  const handleChangeCoupon = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCouponName(e.target.value);
  }, []);

  const removeCoupon = useCallback(() => {
    setCouponName('');
    setCalculatedValue(serviceValue);
    setCoupon(null);
    handleChangeSubscription(serviceId, {
      couponName: null,
    });
  }, [handleChangeSubscription, serviceId, serviceValue]);

  const checkIsButtonSelected = useCallback(
    (buttonPaymentMethod: PAYMENT_METHODS) =>
      subscription.serviceId === serviceId &&
      subscription.paymentMethodCode === buttonPaymentMethod,
    [serviceId, subscription.paymentMethodCode, subscription.serviceId],
  );

  const handleChangePaymentMethod = useCallback(
    (paymentMethodCode: PAYMENT_METHODS) => {
      if (subscription.paymentMethodCode === paymentMethodCode) return;

      if (coupon) {
        setPaymentMethodCodeToBeChanged(paymentMethodCode);
        warningChangePaymentMethodModal.current?.openDialog();
      } else {
        removeCoupon();

        handleChangeSubscription(serviceId, {
          paymentMethod: paymentMethodCode,
        });
      }
    },
    [
      coupon,
      handleChangeSubscription,
      removeCoupon,
      serviceId,
      subscription.paymentMethodCode,
    ],
  );

  const handleCloseModal = useCallback(() => {
    warningChangePaymentMethodModal.current?.closeDialog();
  }, []);

  const handleChangeSubscriptionData = useCallback(() => {
    handleCloseModal();

    if (paymentMethodCodeToBeChanged) {
      removeCoupon();

      handleChangeSubscription(serviceId, {
        paymentMethod: paymentMethodCodeToBeChanged,
      });
    }
  }, [
    handleChangeSubscription,
    handleCloseModal,
    paymentMethodCodeToBeChanged,
    removeCoupon,
    serviceId,
  ]);

  return {
    // Refs
    warningChangePaymentMethodModal,

    // States
    alertMessage,
    alertType,
    isShowAlert,
    calculatedValue,

    // Callbacks
    checkIsButtonSelected,
    handleChangePaymentMethod,
    handleCloseModal,
    handleChangeSubscriptionData,
    closeAlert,

    // ApplyCouponComponent props
    applyCouponProps: {
      coupon,
      couponName,
      loading,
      loadCoupon,
      removeCoupon,
      handleChangeCoupon,
    },
  };
};
