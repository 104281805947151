import { CreateQueryParams } from '@nestjsx/crud-request';
import { getWithFilter } from 'services/apiService';

// TODO: types
export default class SchoolClassesService {
  static async getSchoolClasses(query?: CreateQueryParams) {
    try {
      return await getWithFilter(`school-class`, query);
    } catch (error: any) {
      return error.response;
    }
  }
}
