import { styled, Theme } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import { primaryDark, white } from 'styles/theme';
import { StyledButtonProps } from './types';

export const StyledConfirmButton = styled(ButtonBase)<Theme, StyledButtonProps>(
  ({ disabled, variant, textVariant, isLoading }) => {
    const buttonVariants = {
      background: primaryDark,
      color: white,
      fontWeight: textVariant === 'bold' ? 700 : 400,
      opacity: 1,
    };

    if (variant === 'secondary') {
      buttonVariants.background = 'transparent';
      buttonVariants.color = primaryDark;
    }

    if (!isLoading && disabled) {
      buttonVariants.opacity = 0.5;
    } else if (isLoading && disabled) {
      buttonVariants.opacity = 0.8;
    }

    return {
      alignItems: 'center',
      padding: '10px 20px',
      lineHeight: '22px',
      fontSize: '16px',
      borderRadius: '8px',
      width: 'fit-content',
      ...buttonVariants,

      '& .button-spinner': {
        color: buttonVariants.color,
      },
    };
  },
);

export const StyledCancelButton = styled(ButtonBase)(() => ({
  textAlign: 'center',
  padding: '10px 20px',
  lineHeight: '22px',
  fontWeight: 700,
  fontSize: '16px',
  borderRadius: '8px',
  color: primaryDark,
}));
