import { CondOperator, SCondition } from '@nestjsx/crud-request';
import { useFilters } from 'hooks/filter';
import { StatusCodes } from 'http-status-codes';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CouponService from 'services/couponService';
import useGlobalStyles from 'styles';
import { GENERAL_ERROR } from 'texts';
import { Coupon } from 'types/coupon';
import { useDebouncedCallback } from 'use-debounce';
import {
  DateParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { ROWS_PER_PAGE } from 'utils';
import { useStyles } from './styles';

const minDate = new Date(2020, 9);
const actualDate = new Date();
const maxDate = new Date(
  actualDate.getFullYear() + 1,
  actualDate.getMonth(),
  actualDate.getDate(),
);

export const useCouponsController = () => {
  // Styles
  const classes = useGlobalStyles();
  const styles = useStyles();

  // General Hooks
  const [query, setQuery] = useQueryParams({
    rows: withDefault(NumberParam, ROWS_PER_PAGE[0]),
    search: StringParam,
    couponEvent: StringParam,
    start: withDefault(DateParam, minDate),
    end: withDefault(DateParam, maxDate),
  });

  // Navigation
  const history = useHistory();

  // States
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [searchField, setSearchField] = useState('');
  const [total, setTotal] = useState(0);

  // General Hooks
  const { page, handleSetValuePage, filterFields, handleUpdateFilters } =
    useFilters();

  // Callbacks
  const loadCoupons = useCallback(async () => {
    const { data: response, status } = await CouponService.filterCoupons({
      page: page + 1,
      limit: query.rows,
      search: {
        $and: [
          ...filterFields,
          {
            $or: [
              {
                start_date: null,
              },
              {
                start_date: {
                  $between: [query.start, query.end],
                },
              },
            ],
          },
          {
            event: {
              $eq: query.couponEvent,
            },
          },
          {
            name: { $contL: query.search },
          },
        ],
      } as SCondition,
    });

    if (status === StatusCodes.OK) {
      setCoupons(response.data);
      setTotal(response.total);
    } else {
      setAlertModalOpen(true);
      setAlertMessage(GENERAL_ERROR);
    }
  }, [filterFields, page, query]);

  const handleChangeStartDate = (date: Date | null) => {
    if (date) setQuery({ start: date });
  };

  const handleChangeEndDate = (date: Date | null) => {
    if (date) setQuery({ end: date });
  };

  const searchFieldDebounce = useDebouncedCallback((search: string) => {
    setQuery({ search });
  }, 1000);

  const handleChangeEvent = ({ target }: ChangeEvent<HTMLInputElement>) => {
    handleUpdateFilters('event', { value: target.value }, CondOperator.EQUALS);
    setQuery({ couponEvent: target.value });
  };

  const handleChangeSearch = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSearchField(target.value);
    searchFieldDebounce(target.value);
  };

  const handleCloseModalAlert = () => {
    setAlertModalOpen(false);
  };

  const goToCreateCoupon = () => {
    history.push('/adm-coupons/create');
  };

  const onChangeRowsPerPage = ({ target }: ChangeEvent<HTMLInputElement>) => {
    handleSetValuePage(0);
    setQuery({ rows: parseInt(target.value, 10) });
  };

  const onChangePage = (_event: unknown, newPage: number) => {
    handleSetValuePage(newPage);
  };

  // Effects
  useEffect(() => {
    loadCoupons();
  }, [loadCoupons]);

  return {
    classes,
    styles,
    coupons,
    query,
    alertMessage,
    alertModalOpen,
    page,
    total,
    minDate,
    maxDate,
    searchField,
    handleCloseModalAlert,
    handleChangeSearch,
    goToCreateCoupon,
    handleChangeStartDate,
    handleChangeEndDate,
    handleChangeEvent,
    handleSetValuePage,
    onChangeRowsPerPage,
    onChangePage,
  };
};
