import { TableCell } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { ColoredTableCell } from 'components/ColoredTableCell';
import { useCollapsed } from 'hooks/useCollapsed';
import { formatBRL, formatPaymentMethodCode } from 'utils';
import { buildRegistrationServiceName } from 'utils/buildRegistrationServiceName';
import { FinancialReportCollapsableTableRow } from '../FinancialReportCollapsableTableRow';
import { StyledTableRow } from './styles';
import { FinancialReportTableEntryProps } from './types';
import { getColorPropsFromPaymentStatus } from './utils';

/**
 * An entry from this table has essentially 3 rows:
 * #1. Shown by default; and
 * #2~3. Shown when expanded
 */
export const FinancialReportTableEntry = ({
  payment,
}: FinancialReportTableEntryProps) => {
  const { isExpanded, handleToggleCollapse } = useCollapsed();

  return (
    <>
      {/* First row */}
      <StyledTableRow
        hover
        isExpanded={isExpanded}
        onClick={handleToggleCollapse}
      >
        <TableCell>{payment.referenceYear}</TableCell>
        <TableCell>{payment.registration.dependent.name}</TableCell>
        <TableCell>
          {payment.service?.name || buildRegistrationServiceName()}
        </TableCell>
        <TableCell>
          {formatPaymentMethodCode(payment.paymentMethodCode)}
        </TableCell>
        <ColoredTableCell {...getColorPropsFromPaymentStatus(payment.status)}>
          {payment.status}
        </ColoredTableCell>
        <TableCell>{formatBRL(payment.value)}</TableCell>
        <TableCell>
          {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </TableCell>
      </StyledTableRow>

      {/* Second and third rows */}
      <FinancialReportCollapsableTableRow
        payment={payment}
        isExpanded={isExpanded}
      />
    </>
  );
};
