import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import CreditCardModal from 'components/CreditCardModal';
import React from 'react';
import { FORM_OF_PAYMENT } from 'types/enums';
import { PaymentMethodCardProps } from '../../types';
import ApplyCouponComponent from '../ApplyCoupon';
import { usePaymentMethodCardStyles } from './styles';
import { usePaymentMethodController } from './usePaymentMethodCardController';

const PaymentMethodCard: React.FC<PaymentMethodCardProps> = ({
  creditCards,
  loadCards,
  open,
  user,
  handleClose,
  handleOpen,
  serviceId,
  calculatedServiceValue,
  handleChangeSubscription,
  subscription,
  maxInstallments,
  registrationTaxValue = 0,
  ...applyCouponProps
}) => {
  const styles = usePaymentMethodCardStyles();
  const { installmentsAndPriceInfo, registrationTaxDisclaimer, totalPrice } =
    usePaymentMethodController({
      calculatedServiceValue,
      subscriptionInstallments: subscription.installments,
      registrationTaxValue,
    });

  return (
    <Grid
      container
      direction="row"
      spacing={2}
      className={styles.titleMarginTop}
    >
      <CreditCardModal
        user={user}
        open={open}
        close={handleClose}
        loadCards={loadCards}
      />

      <Grid
        container
        item
        xs={12}
        className={clsx(styles.titleMarginTop, styles.titleMarginBottom)}
      >
        <Typography variant="body2">Cartão de crédito</Typography>
      </Grid>

      <Grid container item xs={12} className={styles.formContainer}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="credit_card_label_id">
              Selecione o cartão
            </InputLabel>
            <Select
              labelId="credit_card_label_id"
              id="credit_card_id"
              value={subscription.cardId || ''}
              label="Selecione o cartão"
              name={`cardId${serviceId}`}
            >
              {creditCards.map(creditCard => (
                <MenuItem
                  key={creditCard.id}
                  value={creditCard.id}
                  onClick={() =>
                    handleChangeSubscription(serviceId, {
                      cardId: creditCard.id,
                    })
                  }
                >
                  <Grid container justifyContent="space-between">
                    <Grid item>{creditCard.alias || creditCard.cardName}</Grid>
                    <Grid item>**** **** **** {creditCard.lastFour}</Grid>
                  </Grid>
                </MenuItem>
              ))}
            </Select>
            <Typography variant="body2" className={styles.helpText}>
              Seu cartão não está nessa lista?
              <Button
                className={styles.buttonAddNewCard}
                variant="text"
                color="primary"
                onClick={handleOpen}
              >
                <Typography className={styles.addNewCardText} variant="body2">
                  Adicionar cartão
                </Typography>
              </Button>
            </Typography>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={5}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              label="Valor neste cartão"
              value={totalPrice}
              name="priceTotal"
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="body2">
                      {installmentsAndPriceInfo}
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
            {registrationTaxValue ? (
              <Typography
                variant="body2"
                className={styles.registrationTaxDisclaimer}
              >
                {registrationTaxDisclaimer}
              </Typography>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="form-payment">Número de parcelas</InputLabel>
            <Select
              labelId="form-payment"
              id="form-payment-credit-card-id"
              value={subscription.installments || ''}
              label="Número de parcelas"
              name={`installments${serviceId}`}
            >
              {[...Array(maxInstallments)].map((_, index) => (
                <MenuItem
                  key={`${index}-${serviceId}`}
                  value={index + 1}
                  onClick={() =>
                    handleChangeSubscription(serviceId, {
                      installments: index + 1,
                    })
                  }
                >
                  {index + 1 === 1
                    ? FORM_OF_PAYMENT.CASH_PAYMENT
                    : `${index + 1}x`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <ApplyCouponComponent {...applyCouponProps} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentMethodCard;
