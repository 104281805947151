import React from 'react';

import {
  ButtonBase,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import EmptyListPlaceholder from 'components/EmptyListPlaceholder';
import { TablePaper } from 'components/TablePaper';
import { COUPON_IS_UNLIMITED, COUPON_LASTS_FOREVER, NO_COUPONS } from 'texts';
import { formatDate, formatValue } from '../utils';
import { useCouponsTableController } from './controller';
import { TableProperties } from './types';

const CouponsTable = ({ coupons }: TableProperties) => {
  const { classes, styles, headers, navigateToDetail } =
    useCouponsTableController();

  return coupons.length ? (
    <TablePaper>
      <TableHead>
        <TableRow>
          {headers.map(header => (
            <TableCell key={header}>
              <Typography className={classes.listScreenHeaderText1}>
                {header}
              </Typography>
            </TableCell>
          ))}
          <TableCell align="center">
            <Typography className={classes.listScreenHeaderText1}>
              AÇÕES
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {coupons.map(coupon => (
          <TableRow key={coupon.id}>
            <TableCell>{coupon.name}</TableCell>
            <TableCell>
              {formatValue(coupon.pix_value, coupon.pix_percent)}
            </TableCell>
            <TableCell>
              {formatValue(
                coupon.credit_card_value,
                coupon.credit_card_percent,
              )}
            </TableCell>
            <TableCell>
              {formatValue(coupon.bank_slip_value, coupon.bank_slip_percent)}
            </TableCell>
            <TableCell>{coupon.limit || COUPON_IS_UNLIMITED}</TableCell>

            <TableCell>
              {coupon.start_date && coupon.end_date
                ? `${formatDate(coupon.start_date)} até ${formatDate(
                    coupon.end_date,
                  )}`
                : COUPON_LASTS_FOREVER}
            </TableCell>
            <TableCell align="center">
              <ButtonBase
                onClick={() => navigateToDetail(coupon.id)}
                className={styles.navigateButton}
                disableTouchRipple
              >
                Ver detalhes
                <ChevronRight />
              </ButtonBase>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </TablePaper>
  ) : (
    <Grid className={styles.grid}>
      <EmptyListPlaceholder message={NO_COUPONS} />
    </Grid>
  );
};

export default CouponsTable;
