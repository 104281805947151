import {
  AutocompleteOption,
  BaseAutocompleteProps,
  OnAutocompleteTextCallback,
} from 'components/BaseAutocomplete/types';
import { getWithFilter } from 'services/apiService';

import { CreateQueryParams } from '@nestjsx/crud-request';
import { StatusCodes } from 'http-status-codes';
import { useCallback, useState } from 'react';
import { NestCrudPaginatedResponse } from 'services/types';
import { isNumber, isString } from 'utils/typeCheckers';
import { LooseObject, SearchAutocompleteProps } from './types';
import { MIN_CHAR_LENGHT, formatSearchText, isSearchTextValid } from './utils';

const DEFAULT_AUTOCOMPLETE_ITEMS_LIMIT = 10;

export const useSearchAutocompleteController = ({
  filterEndpointPath,
  onSearchText,
  createQueryParamsCallback,
  getAutocompleteOptionLabel,
  identifierProperty = 'id',
  minTextLength = MIN_CHAR_LENGHT,
}: Omit<SearchAutocompleteProps, keyof BaseAutocompleteProps>) => {
  // TODO: if possible, turn this LooseObject into a dynamic type
  // ---------- States ----------
  const [autocompleteOptions, setAutocompleteOptions] = useState<
    AutocompleteOption[]
  >([]);
  const [loading, setLoading] = useState(false);

  // ---------- Callbacks ----------
  // Generate an array of unique properties containing "identifierProperty"s
  const uniqueProperties = useCallback(
    ({ fields }: CreateQueryParams) => {
      return fields
        ? Array.from(new Set([identifierProperty].concat(fields)))
        : undefined;
    },
    [identifierProperty],
  );

  const handleTransformLabel = useCallback((label: unknown) => {
    if (isString(label)) {
      return label;
    }

    console.warn(
      `Received unexpected value to render as text: ${label}`,
      label,
    );
    return String(label) || 'erro';
  }, []);

  const handleUpdateOptions = useCallback(
    (data: LooseObject[]) => {
      const newAutocompleteOptions = data.map(option => {
        const id = option[identifierProperty];
        const label = handleTransformLabel(getAutocompleteOptionLabel(option));

        if (isString(id) || isNumber(id)) {
          return {
            id,
            label,
          };
        }

        console.warn(
          `Unexpected type of identifier ${identifierProperty}: ${typeof id}`,
        );
        return {
          id: String(id),
          label,
        };
      });

      setAutocompleteOptions(newAutocompleteOptions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleTransformLabel, identifierProperty],
  );

  const fetchOptions = useCallback(
    async (text: string) => {
      const crudQueryParams = createQueryParamsCallback(text);
      setLoading(true);
      try {
        const response = (await getWithFilter(filterEndpointPath, {
          ...crudQueryParams,
          fields: uniqueProperties(crudQueryParams),
          limit: crudQueryParams.limit || DEFAULT_AUTOCOMPLETE_ITEMS_LIMIT,
        })) as NestCrudPaginatedResponse<LooseObject>;

        if (response.status === StatusCodes.OK) {
          handleUpdateOptions(response.data.data);
        }
      } catch (error) {
        console.error('Failed to fetch autocomplete options:', error);
      } finally {
        setLoading(false);
      }
    },
    [
      createQueryParamsCallback,
      filterEndpointPath,
      uniqueProperties,
      handleUpdateOptions,
    ],
  );

  const handleOnAutocompleteAction: OnAutocompleteTextCallback = useCallback(
    text => {
      if (!isSearchTextValid(text, minTextLength)) return;

      onSearchText?.(text);
    },
    [minTextLength, onSearchText],
  );

  const handleOnEndTyping: OnAutocompleteTextCallback = useCallback(
    text => {
      const formattedText = formatSearchText(text);

      if (!isSearchTextValid(formattedText, minTextLength)) {
        setAutocompleteOptions([]);
        return;
      }

      fetchOptions(formattedText);
    },
    [fetchOptions, minTextLength],
  );

  return {
    loading,
    autocompleteOptions,
    handleOnAutocompleteAction,
    handleOnEndTyping,
  };
};
