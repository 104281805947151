/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';

import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  TablePagination,
  TextField,
  ThemeProvider,
} from '@material-ui/core';

import { createTheme } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

import SearchIcon from '@material-ui/icons/Search';

import AlertCard from 'components/AlertCard';
import PageHeader from 'components/PageHeader';

import { ROWS_PER_PAGE } from 'utils';
import { DatePicker } from '@material-ui/pickers';
import { selectMenuProps } from 'constants/selectMenu';
import { COUPON_EVENT } from 'types/enums';
import CouponsTable from './CouponsTable';
import { useCouponsController } from './controller';

const theme = createTheme({}, ptBR);

export const Coupons = () => {
  const {
    classes,
    styles,
    coupons,
    query,
    page,
    total,
    alertMessage,
    alertModalOpen,
    searchField,
    minDate,
    maxDate,
    handleCloseModalAlert,
    handleChangeSearch,
    goToCreateCoupon,
    handleChangeStartDate,
    handleChangeEndDate,
    handleChangeEvent,
    onChangeRowsPerPage,
    onChangePage,
  } = useCouponsController();

  return (
    <>
      <Grid
        className={classes.listScreenGrid}
        container
        direction="column"
        alignItems="flex-start"
      >
        <Grid container item direction="column" style={{ marginBottom: 50 }}>
          <PageHeader
            title="Gerenciamento dos "
            titleBoldSufix="cupons"
            subtitle="Adicione, edite ou delete os cupons disponíveis"
            button={
              <Button
                onClick={goToCreateCoupon}
                variant="contained"
                color="primary"
                className={classes.listScreenButton}
              >
                Novo cupom
              </Button>
            }
          />
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={3} lg={6}>
              <TextField
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder="Buscar"
                value={searchField}
                onChange={handleChangeSearch}
                className={styles.gridItems}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <DatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                inputVariant="outlined"
                label="Início"
                minDate={minDate}
                maxDate={query.end || maxDate}
                value={query.start}
                onChange={handleChangeStartDate}
                className={styles.gridItems}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <DatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                inputVariant="outlined"
                label="Término"
                minDate={query.start || minDate}
                maxDate={maxDate}
                value={query.end}
                onChange={handleChangeEndDate}
                className={styles.gridItems}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <TextField
                label="Evento"
                variant="outlined"
                select
                SelectProps={selectMenuProps}
                value={query.couponEvent}
                onChange={handleChangeEvent}
                className={styles.gridItems}
              >
                <MenuItem>Todos</MenuItem>
                {Object.values(COUPON_EVENT).map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <CouponsTable coupons={coupons} />

          <ThemeProvider theme={theme}>
            {/** @ts-ignore */}
            <TablePagination
              component="div"
              count={total}
              page={page}
              className={classes.tablePagination}
              labelRowsPerPage="Itens por página"
              onChangePage={onChangePage}
              rowsPerPage={query.rows}
              onChangeRowsPerPage={onChangeRowsPerPage}
              rowsPerPageOptions={ROWS_PER_PAGE}
            />
          </ThemeProvider>
        </Grid>
      </Grid>
      <AlertCard
        message={alertMessage}
        open={alertModalOpen}
        close={handleCloseModalAlert}
        severity="error"
      />
    </>
  );
};
