import { Button } from '@material-ui/core';
import PageHeader from 'components/PageHeader';
import { MyTable } from 'components/Table';
import React from 'react';
import useGlobalStyles from 'styles';
import { SubscriptionTableData, SubscriptionTableProperties } from './types';
import { useSubscriptionTableController } from './useSubscriptionTableController';

// FIXME: this page should NOT have any business logic in it.
const SubscriptionTable: React.FC<SubscriptionTableProperties> = ({
  loadPayment,
  subscription,
  isAdmin,
  paymentMethodCode,
  loading,
}) => {
  const classes = useGlobalStyles();
  const {
    // Page header
    pageHeaderTitle,
    pageHeaderSubtitle,

    // Admin actions
    goToVindiSubscription,

    // Table data
    subscriptionTableData,
    subscriptionTableConfig,
  } = useSubscriptionTableController({
    loadPayment,
    subscription,
    paymentMethodCode,
    isAdmin,
  });

  return (
    <>
      {/* FIXME: Use custom button component instead */}
      <PageHeader
        title={pageHeaderTitle}
        subtitle={pageHeaderSubtitle}
        button={
          isAdmin && subscription.externalId ? (
            <Button
              variant="contained"
              color="primary"
              className={classes.listScreenButton}
              onClick={goToVindiSubscription}
            >
              Acessar na Vindi
            </Button>
          ) : null
        }
      />

      <MyTable<SubscriptionTableData>
        data={subscriptionTableData}
        emptyTableMessage="Não foram encontradas faturas para este pagamento."
        tableConfig={subscriptionTableConfig}
        isLoading={loading}
      />
    </>
  );
};

export default SubscriptionTable;
