import { formatCurrencyValueToNumber } from 'utils';
import { IGenerateServiceFormDataProps, IServiceFieldValues } from './types';

export const SERVICE_INVITE_CODE_EXPLANATION =
  'Código de convite é obrigatório para as atividade por convite. O código é único e permite que os responsáveis acessem o serviço.';

export const SERVICE_EARLY_ACCESS_EXPLANATION =
  'Acesso antecipado permite que os responsáveis acessem o serviço antes do início da atividade. Os códigos podem ser gerados no menu de códigos de acesso no "ver detalhes" do serviço, depois de criado.';

export const EARLY_ACCESS_SELECT_OPTIONS = [
  {
    id: 'true',
    value: 'true',
    label: 'Sim, este serviço possui acesso antecipado',
  },
  {
    id: 'false',
    value: 'false',
    label: 'Não, este serviço não possui acesso antecipado',
  },
];

export const validateGeneralErrorsOnSubmit = (
  values: IServiceFieldValues,
  amount: number,
) => {
  // TODO: Validações específicas de acordo com o tipo de serviço
  // TODO: Tem que receber a entidade do tipo de serviço aqui
  // switch (serviceTypeName) {
  //   case EServicesTypes.WITHOUT_ADDITIONAL_COST:
  //     if (amount) return ERROR_SERVICE_PRICE_NOT_ZERO;
  //     break;
  //   case EServicesTypes.BY_INVITE:
  //     if (!values.accessCode) return ERROR_ACCESS_CODE_NOT_FILLED;
  //     break;
  //   default:
  //     break;
  // }

  // if (serviceTypeName !== EServicesTypes.BY_INVITE && values.accessCode) {
  //   return ERROR_NOT_ACCESS_CODE;
  // }

  // // Validações genéricas aplicáveis à serviços não isentos
  // if (serviceTypeName !== EServicesTypes.WITHOUT_ADDITIONAL_COST) {
  //   if (!amount) {
  //     return ERROR_SERVICE_PRICE_MANDATORY;
  //   }

  //   if (!values.currencyRecurrence) {
  //     return ERROR_SERVICE_RECURRENCE_MANDATORY;
  //   }

  //   if (Number(values.installments || 0) <= 0) {
  //     return ERROR_SERVICE_INSTALLMENTS_MANDATORY;
  //   }
  // }

  return '';
};

export const generateServiceFormData = ({
  values,
  serviceTypeId,
  coverPhoto,
  galleryImages,
  servicePublicationStatusId,
  partnerLogo,
  isUpdate,
}: IGenerateServiceFormDataProps) => {
  const data = new FormData();

  data.append('name', values.name);
  data.append('serviceTypeId', serviceTypeId);

  values.category.forEach(categoryName => {
    data.append('serviceCategories[]', categoryName);
  });

  values.schoolClasses.forEach(schoolClass => {
    data.append('schoolClasses[]', schoolClass);
  });

  data.append('serviceRecurrenceId', values.currencyRecurrence);

  if (values.pedagogicSubjectId) {
    data.append('pedagogicSubjectId', values.pedagogicSubjectId);
  }

  data.append('servicePublicationStatusId', servicePublicationStatusId);
  data.append('contractTemplateId', String(values.contractTemplateId));
  data.append('earlyAccess', String(values.earlyAccess === 'true'));
  data.append('amount', String(formatCurrencyValueToNumber(values.amount)));
  data.append('numberOfInstallments', String(values.installments));
  data.append(
    'registrationTaxValue',
    String(formatCurrencyValueToNumber(values.registrationTaxValue)),
  );

  data.append('partnerId', values.partnerId);
  data.append('description', values.description);
  data.append('invoiceServiceCode', values.invoiceServiceCode?.trim());
  if (values.color) {
    data.append('color', values.color);
  }

  if (partnerLogo?.file) {
    data.append('partnerLogo', partnerLogo.file as Blob);
  }

  galleryImages.forEach((galleryImage, index) => {
    if (galleryImage.file) {
      data.append('galleryImages', galleryImage.file as Blob);
    }
    if (galleryImage.file && isUpdate) {
      data.append('indexUpdatedGalleryImages[]', String(index));
    }
  });

  if (coverPhoto?.file) {
    data.append('coverPhoto', coverPhoto?.file as Blob);
  }

  if (values.youtubeVideo) {
    data.append('videoLink', values.youtubeVideo);
  }

  if (values.inviteCode) {
    data.append('inviteCode', values.inviteCode);
  }

  return data;
};
