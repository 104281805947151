import { TableCell, IconButton, Collapse, Grid } from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import { capitalizeFirstLetter } from 'utils';
import { InstallmentsCollapsableTableCellProps } from './types';
import { useInstallmentsCollapsableTableCellController } from './useInstallmentsCollapsableTableCellController';
import { FinancialTableBill } from '../FinancialReportTable/types';

export const InstallmentsCollapsableTableCell = ({
  installments,
  bills,
}: InstallmentsCollapsableTableCellProps) => {
  const {
    handleToggleCollapse,
    isExpanded,
    sortedBills,
    getInstallmentNumber,
    getBillingMonth,
  } = useInstallmentsCollapsableTableCellController({ installments, bills });

  const renderBillInformation = (bill: FinancialTableBill, index: number) => {
    return (
      <>
        {getInstallmentNumber(index)}
        {' - '}
        <b>{bill.status}</b>
        {' - '}
        {capitalizeFirstLetter(getBillingMonth(bill.billingDate))}
      </>
    );
  };

  return (
    <TableCell>
      {installments}
      <IconButton size="small" onClick={handleToggleCollapse}>
        {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </IconButton>
      <Collapse in={isExpanded}>
        {sortedBills.map((bill, index) => (
          <Grid key={bill.id}>{renderBillInformation(bill, index)}</Grid>
        ))}
      </Collapse>
    </TableCell>
  );
};
