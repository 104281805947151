import {
  default as Calendar,
  default as ToastUIReactCalendar,
} from '@toast-ui/react-calendar';
import editIcon from 'assets/icons/edit-icon.svg';
import detailIcon from 'assets/icons/gallery-pagination-chev-right.svg';
import { timeZoneName, weekDayNames } from 'constants/calendar';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  StyledBeforeIcon,
  StyledCalendarDate,
  StyledGrid,
  StyledNextIcon,
  useStyles,
} from './styles';
import {
  CustomCalendarProps,
  TemplateDetailBodyProps,
  TemplateDetailDateProps,
  TemplateDetailTitleProps,
  TemplateTitleProps,
} from './types';

import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import {
  popUpDetailBodyComponent,
  popUpDetailDateComponent,
  popUpDetailTitleComponent,
  timeComponent,
} from './template';

export const CustomCalendar: React.FC<CustomCalendarProps> = ({
  viewType,
  events,
}) => {
  const calendar = useRef<ToastUIReactCalendar>(null);
  const classes = useStyles();
  const [renderDate, setRenderDate] = useState<string>('');

  const moveToNextOrPrevRange = (offset: number) => {
    if (offset === 1) {
      calendar.current?.calendarInstance?.next();
    } else if (offset === -1) {
      calendar.current?.calendarInstance?.prev();
    }
    getRenderedDate();
  };

  const getRenderedDate = useCallback(() => {
    const date = calendar.current?.calendarInstance?.getDate();
    const year = date?.getFullYear();
    const month = date?.getMonth();

    setRenderDate(`${month !== undefined ? month + 1 : month}-${year}`);
  }, []);

  useEffect(() => {
    getRenderedDate();
  }, [getRenderedDate]);

  const calendarTemplate = useMemo(
    () => ({
      time({ color, start, end, body, raw, title }: TemplateTitleProps) {
        return timeComponent({ classes, body, color, raw, start, end, title });
      },
      popupDetailTitle({ title, raw }: TemplateDetailTitleProps) {
        return popUpDetailTitleComponent({ classes, title, raw });
      },
      popupDetailDate({ start, end, color }: TemplateDetailDateProps) {
        return popUpDetailDateComponent({ classes, color, start, end });
      },
      popupDetailBody({ body }: TemplateDetailBodyProps) {
        return popUpDetailBodyComponent({
          body,
          classes,
          detailIcon,
          editIcon,
        });
      },
    }),
    [],
  );

  return (
    <div>
      <StyledGrid item container alignItems="center">
        <StyledBeforeIcon onClick={() => moveToNextOrPrevRange(-1)} />
        <StyledNextIcon onClick={() => moveToNextOrPrevRange(1)} />
        <StyledCalendarDate>{renderDate}</StyledCalendarDate>
      </StyledGrid>

      <Calendar
        template={calendarTemplate}
        usageStatistics={false}
        events={events}
        view={viewType}
        ref={calendar}
        isReadOnly
        week={{
          dayNames: weekDayNames,
          hourStart: 6,
          hourEnd: 20,
          taskView: false,
          eventView: ['time'],
        }}
        useDetailPopup
        timezone={{
          zones: [
            {
              timezoneName: timeZoneName,
            },
          ],
        }}
      />
    </div>
  );
};
