import { useCallback, useEffect, useRef, useState } from 'react';
import { ExportProperties } from './types';

/**
 * Working with react-csv asynchronously simply don't work,
 * despite following exactly what the documentation says.
 * https://www.npmjs.com/package/react-csv
 * https://github.com/react-csv/react-csv/issues/189
 */
export const useExportExcelButtonController = ({
  data,
  onClick,
}: ExportProperties) => {
  const [csvData, setCsvData] = useState(data || []);
  const [isLoading, setIsLoading] = useState(false);

  const csvLinkRef = useRef<any | null>(null);

  const handleFetchCsvData = useCallback(async () => {
    if (onClick) {
      setIsLoading(true);
      try {
        const newData = await onClick();

        setCsvData(newData);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [onClick]);

  useEffect(() => {
    if (
      csvData &&
      csvData.length &&
      csvLinkRef &&
      csvLinkRef.current &&
      csvLinkRef.current.link
    ) {
      csvLinkRef.current.link.click();
      setCsvData([]);
    }
  }, [csvData]);

  return { csvLinkRef, csvData, isLoading, handleFetchCsvData };
};
