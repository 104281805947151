import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { format, isAfter, isBefore, isSameDay, isValid } from 'date-fns';

// isValid only returns true if date is also either a number or a Date
// https://date-fns.org/v2.29.3/docs/isValid
const isDateFormattable = (date: ParsableDate): date is Date | number =>
  isValid(date);

export const getMinDateMessage = (date: ParsableDate) => {
  if (!isDateFormattable(date))
    return 'A data deve ser maior ou igual a mínima.';

  return `A data deve ser maior ou igual a ${format(date, 'dd/MM/yyyy')}.`;
};

export const getMaxDateMessage = (date: ParsableDate) => {
  if (!isDateFormattable(date))
    return 'A data deve ser menor ou igual a máxima.';

  return `A data deve ser menor ou igual a ${format(date, 'dd/MM/yyyy')}.`;
};

export const checkIfDateIsValid = (
  startDate: Date | null,
  endDate: Date | null,
  check: 'start' | 'end',
) => {
  // eslint-disable-next-line default-case
  switch (check) {
    case 'start':
      if (!isValid(startDate)) return false;
      if (!endDate) return true;
      return isBefore(startDate!, endDate) || isSameDay(startDate!, endDate);
    case 'end':
      if (!isValid(endDate)) return false;
      if (!startDate) return true;
      return isAfter(endDate!, startDate) || isSameDay(endDate!, startDate);
    default:
      throw Error(`Expected "start" or "end" but received "${check}" instead.`);
  }
};
