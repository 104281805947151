import { Divider, styled, Theme } from '@material-ui/core';
import { divideBorderColor } from 'styles/theme';
import { HairlineProps } from './types';

export const StyledDivider = styled(Divider)<Theme, HairlineProps>(
  ({ thickness }) => {
    return {
      width: '100%',
      borderBottom: `${thickness || '0.8'}px solid ${divideBorderColor}`,
    };
  },
);
