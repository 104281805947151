import { CustomButton } from 'components/CustomButton';
import { FC } from 'react';
import { ButtonProps } from './types';
import { useButtonViewContractController } from './useButtonViewContractController';

export const ButtonViewContract: FC<ButtonProps> = ({
  signDate,
  documentPath,
}) => {
  const { loading, viewContract, classes } = useButtonViewContractController({
    documentPath,
  });

  return (
    <CustomButton
      loading={loading}
      disabled={loading}
      variant="secondary"
      className={classes.textButton}
      onClick={viewContract}
      text={`Ver contrato ${signDate ? '' : '(Não assinado)'}`}
    />
  );
};
