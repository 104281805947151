import {
  api,
  getAxiosResponseMessageErrorOrDefault,
} from 'services/apiService';
import { Configurations, IDiscount } from 'types/configurations';

export default class ConfigurationsService {
  static async configurations() {
    try {
      return await api.get('configurations');
    } catch (error: any) {
      return error.response;
    }
  }

  static async getDiscountInfo() {
    try {
      return await api.get('discounts');
    } catch (error: any) {
      return error.response;
    }
  }

  static async getReregistrationInfo() {
    try {
      return await api.get('configurations/detailed');
    } catch (error: any) {
      return error.response;
    }
  }

  static async updateConfigurations(configurations: Partial<Configurations>) {
    try {
      return await api.patch('configurations/1', configurations);
    } catch (error: any) {
      return error.response;
    }
  }

  static async updateDiscount(discount: Partial<IDiscount>) {
    try {
      const { id, ...restDiscount } = discount;
      return await api.patch(`discounts/${id}`, restDiscount);
    } catch (error: any) {
      return error.response;
    }
  }

  static async patchConfigurations(
    configId: number,
    configurations: Partial<Omit<Configurations, 'id'>>,
  ) {
    try {
      return await api.patch<Configurations>(
        `configurations/${configId}`,
        configurations,
      );
    } catch (error: any) {
      throw getAxiosResponseMessageErrorOrDefault(error);
    }
  }
}
