import { styled } from '@material-ui/core';
import Button from '@material-ui/core/Button';

export const Container = styled('div')(() => {
  return {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 20px',
    marginTop: '50px',
  };
});
export const ContainerProgress = styled('div')(() => {
  return {
    width: '30%',
  };
});

export const BackButton = styled(Button)(() => {
  return {
    marginRight: '10px',
  };
});
