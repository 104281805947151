import { useMediaQuery } from '@material-ui/core';
import { DesktopContractClauses } from './DesktopContractClauses';
import { MobileContractClauses } from './MobileContractClauses';
import { ContractClausesProps } from './types';

export const ContractClauses = (props: ContractClausesProps) => {
  const isMobile = useMediaQuery('(max-width: 959px)');

  if (isMobile) return <MobileContractClauses {...props} />;
  return <DesktopContractClauses {...props} />;
};
