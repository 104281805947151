import { useHistory } from 'react-router-dom';

export const useDescriptionController = () => {
  const history = useHistory();

  const goToUpdateService = (serviceId: number) => {
    history.push(`/adm-marketplace/services/${serviceId}/update-service`);
  };

  const goToNewServiceClass = (serviceId: number) => () => {
    history.push(`/adm-marketplace/services/${serviceId}/new-service-class`);
  };

  return {
    goToUpdateService,
    goToNewServiceClass,
  };
};
