import { makeStyles } from '@material-ui/core';
import { primaryDark, primaryMain, transparent } from 'styles/theme';
import { StyleProperties } from 'types/style';

export const useStyles = ({ isAdmin, dark }: StyleProperties) => {
  const darkBlue = dark ? primaryMain : primaryDark;
  return makeStyles(theme => ({
    logo: {
      position: 'absolute',
      top: '7px',
      left: '10px',
      width: '46px',
    },
    button: {
      height: '100%',
      backgroundColor: 'white',
      borderRadius: '14px',
      boxShadow: '0px 6px 32px rgba(9, 13, 27, 0.07)',
      [theme.breakpoints.down('md')]: {
        color: isAdmin ? 'white' : 'black',
        backgroundColor: isAdmin ? theme.palette.primary.main : 'white',
        boxShadow: 'none',
      },
    },
    toolbar: {
      height: '64px',
      width: '100vw',
      padding: '0 40px',
      background: transparent,
      [theme.breakpoints.down('sm')]: {
        background: isAdmin ? darkBlue : 'white',
        padding: '0 10px',
      },
    },
    labelsContainer: {
      position: 'absolute',
      width: 'auto',
      left: '337px',
      top: isAdmin ? '45px' : '95px',
      [theme.breakpoints.down('sm')]: {
        left: '24px',
        top: '73px',
      },
    },
    itemsContainer: {
      display: 'flex',
      width: '350px',
      marginTop: '20px',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
      marginRight: '20px',
      [theme.breakpoints.down('md')]: {
        marginRight: 0,
        justifyContent: 'flex-end',
      },
      [theme.breakpoints.down('sm')]: {
        width: '50%',
        marginTop: 0,
      },
      '& .MuiGrid-item': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    menuText: {
      whiteSpace: 'pre-line',
    },

    dropdown: {
      borderRadius: '14px',
      width: 'auto',
      maxWidth: '250px',
      textTransform: 'capitalize',
      fontSize: '14px',
      backgroundColor: dark ? darkBlue : 'white',
      boxShadow: '0px 6px 32px rgba(9, 13, 27, 0.07)',
      color: dark ? 'white' : 'black',
      [theme.breakpoints.down('sm')]: {
        width: 70,
        backgroundColor: isAdmin || dark ? darkBlue : 'white',
        boxShadow: 'none',
        color: isAdmin || dark ? 'white' : 'black',
      },
    },
    dropdownIcon: {
      color: 'black',
      [theme.breakpoints.down('sm')]: {
        color: isAdmin ? 'white' : 'black',
      },
    },
    dropDownArrow: {
      fontSize: '30px',
      [theme.breakpoints.up('sm')]: {
        marginLeft: '15px',
      },
    },
    adminText: {
      fontSize: '35px',
      [theme.breakpoints.down('sm')]: {
        color: isAdmin ? 'white' : darkBlue,
      },
    },

    pageGoBack: {
      cursor: 'pointer',
    },
  }));
};
