import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { CheckCircleOutlineOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import { StatusCodes } from 'http-status-codes';
import { useForm } from 'react-hook-form';
import SpinnerButton from 'components/SpinnerButton';
import Modal from 'components/Modal';
import UserService from 'services/userService';
import AuthService from 'services/authService';
import useGlobalStyles from 'styles';
import { INCORRECT_PASSWORD, GENERAL_ERROR, WEAK_PASSWORD } from 'texts';
import { ModalProperties, SubmitPasswordChange } from './types';
import { validationSchema } from './utils';

const NewUserModal: React.FC<ModalProperties> = ({ open, email, close }) => {
  const classes = useGlobalStyles();
  const { handleSubmit, errors, setError, register } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onBlur',
  });
  const [modal, setModal] = useState(false);

  function onSubmit(data: SubmitPasswordChange) {
    verifyPassword(data);
  }

  async function verifyPassword(data: SubmitPasswordChange) {
    const response = await UserService.login(email, data.password);
    if (response.status !== StatusCodes.CREATED) {
      setError('password', { message: INCORRECT_PASSWORD });
    } else {
      saveNewPassword(data);
    }
  }

  async function saveNewPassword(data: SubmitPasswordChange) {
    const { password, newPassword } = data;
    const response = await AuthService.changePassword(
      email,
      password,
      newPassword,
    );
    if (response.status === StatusCodes.OK) {
      setModal(true);
      close();
    } else {
      setError('password', { message: GENERAL_ERROR });
    }
  }

  return (
    <>
      <Modal
        icon={
          <CheckCircleOutlineOutlined
            color="secondary"
            className={classes.dialogIcon}
          />
        }
        open={modal}
        text="Sua senha foi alterada com sucesso!"
        btnText="Voltar"
        btnAction={() => setModal(false)}
      />
      <Dialog open={open}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box style={{ width: 585, padding: 35 }}>
            <Typography style={{ fontSize: 30, marginBottom: 10 }}>
              <b>Definir</b> nova senha
            </Typography>
            <Typography style={{ fontSize: 18, marginBottom: 50 }}>
              Defina sua nova senha de acesso ao Portal dos Pais JPSul
            </Typography>
            <TextField
              variant="outlined"
              label="Senha antiga"
              id="password"
              name="password"
              type="password"
              error={Boolean(errors.password)}
              helperText={errors.password ? errors.password.message : null}
              inputRef={register}
              className={classes.newUserInput}
            />
            <TextField
              variant="outlined"
              label="Nova senha"
              id="newPassword"
              name="newPassword"
              type="password"
              error={Boolean(errors.newPassword)}
              helperText={
                errors.newPassword ? errors.newPassword.message : WEAK_PASSWORD
              }
              inputRef={register}
              className={classes.newUserInput}
            />
            <TextField
              variant="outlined"
              label="Confirme a senha"
              id="repeatPassword"
              name="repeatPassword"
              type="password"
              error={Boolean(errors.repeatPassword)}
              helperText={
                errors.repeatPassword ? errors.repeatPassword.message : null
              }
              inputRef={register}
              className={classes.newUserInput}
            />

            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={3}
              className={classes.screenButtonGrid}
            >
              <Grid item>
                <Button
                  onClick={() => close()}
                  color="primary"
                  className={classes.textButton}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <SpinnerButton
                  text="Confirmar"
                  className={classes.newDependentButton}
                />
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
    </>
  );
};

export default NewUserModal;
