import {
  DateFilterProps,
  SearchFilterProps,
} from 'components/CustomSearchFilters/types';
import { INVOICE_STATUS } from 'types/invoice';
import {
  DateParam,
  DelimitedArrayParam,
  StringParam,
  withDefault,
} from 'use-query-params';

export const invoiceFiltersConfig: SearchFilterProps[] = [
  {
    type: 'search',
    label: 'Buscar por aluno...',
    name: 'search',
    placeholder: 'Buscar por aluno ou responsável financeiro',
    defaultValue: '',
    queryParamConfig: withDefault(StringParam, ''),
    style: {
      sm: 6,
      xs: 12,
    },
  },
  {
    type: 'select',
    label: 'Status da nota',
    name: 'internalStatus',
    defaultValue: [],
    queryParamConfig: withDefault(DelimitedArrayParam, [INVOICE_STATUS.ISSUED]),
    style: {
      sm: 6,
      xs: 12,
    },
    options: Object.values(INVOICE_STATUS).map(item => ({
      id: item,
      label: item,
    })),
    isMultiple: true,
  },
  {
    type: 'date',
    label: 'Mês de competência',
    name: 'competenceDate',
    format: 'MM/yyyy',
    defaultValue: new Date(),
    queryParamConfig: withDefault(DateParam, new Date()),
    style: {
      sm: 6,
      xs: 12,
    },
  } as DateFilterProps,
];
