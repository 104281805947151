import { ButtonBase, Grid, Paper, Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import BlankPic from 'assets/blankProfileImg.png';
import StatusLabel from 'components/StatusLabel';
import { useAppStore } from 'store';
import { useRegistrationListItemController } from './controller';
import { IListItemPropertiesProps } from './types';

export const RegistrationListItem = ({
  dependentName,
  registration,
  showNumber,
  showDetails,
}: IListItemPropertiesProps) => {
  const {
    styles,
    schoolGrade,
    schoolYear,
    number,
    enrollmentNumber,
    status,
    savedPicture,
    goToDetails,
  } = useRegistrationListItemController({ registration });
  const configurations = useAppStore(state => state.configurations.data);

  return (
    <Paper elevation={0} className={styles.paper}>
      <img
        className={styles.img}
        src={savedPicture ? savedPicture?.path : BlankPic}
        alt="Foto do aluno"
      />
      <Grid item container alignItems="center" className={styles.container}>
        <Grid
          item
          container
          xs={7}
          md={9}
          className={styles.columnOne}
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography className={styles.name}>{dependentName}</Typography>
            <Typography className={styles.schoolGrade}>
              {schoolGrade} - Ano letivo {schoolYear}
            </Typography>
          </Grid>
          {showNumber ? (
            <Grid item>
              <Grid item className={styles.text}>
                <b>Nº de {`${number ? 'matrícula' : 'inscrição'}`}</b>
              </Grid>
              {/* TODO: Take a look at this line, find a better approach to this */}
              <Grid item className={styles.text}>
                <i>{number || (enrollmentNumber ?? '------')}</i>
              </Grid>
            </Grid>
          ) : (
            <Grid item container spacing={1}>
              <Grid item>
                <StatusLabel status={status} year={schoolYear} />
              </Grid>
              {registration.canReRegister && configurations?.enrollmentYear ? (
                <Grid item>
                  <StatusLabel
                    status={`
                Matrícula de veterano ${configurations?.enrollmentYear} disponível`}
                    year={schoolYear}
                  />
                </Grid>
              ) : null}
            </Grid>
          )}
        </Grid>
        {showDetails ? (
          <Grid item container md={3} className={styles.columnTwo} spacing={2}>
            <Grid item>
              <ButtonBase
                className={styles.navigateButton}
                disableTouchRipple
                onClick={goToDetails(registration?.id)}
              >
                Ver detalhes
                <ChevronRight />
              </ButtonBase>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
};
