import React, { useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { REGISTRATION_STATUS } from 'types/enums';
import { LabelProperties } from './types';
import { useStyles } from './styles';

const StatusLabel: React.FC<LabelProperties> = ({ status, year }) => {
  const styles = useStyles({ status })();
  const formatStatus = useMemo(() => {
    if (status === REGISTRATION_STATUS.OPEN) {
      return `Matrícula ${year} aberta`;
    } else {
      return status;
    }
  }, [status, year]);

  return (
    <Box className={styles.button}>
      <Typography className={styles.label}>{formatStatus}</Typography>
    </Box>
  );
};

export default StatusLabel;
