import { Grid, MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import RGForm from 'components/RGForm';
import { Controller } from 'react-hook-form';
import useGlobalStyles from 'styles';
import { CIVIL_STATUS, DEPENDENT_RELATIONSHIP } from 'types/enums';
import { LegalGuardian } from 'types/legalGuardian';
import { FormProperties } from './types';
import { guardianFields, legalGuardianFields } from './utils';

const GuardianForm: React.FC<FormProperties> = ({
  isLegal,
  guardian,
  editing,
  relationship,
  civilStatus,
  changeCivilStatus,
  changeRelationship,
  register,
  errors,
  control,
}) => {
  const classes = useGlobalStyles();

  return (
    <Grid item container spacing={4} className={classes.screenInputGrid}>
      {isLegal
        ? legalGuardianFields(guardian as LegalGuardian).map(field => (
            <Grid item key={field.label}>
              <Controller
                name={field.name}
                control={control}
                as={({ value, onChange }) => (
                  <field.component
                    variant="outlined"
                    mask={field.mask || ''}
                    label={field.label}
                    name={field.name}
                    value={value}
                    onChange={onChange}
                    error={!!errors[field.name]}
                    helperText={
                      errors[field.name] ? errors[field.name]?.message : null
                    }
                    inputRef={register}
                    disabled={!editing}
                    className={classes.screenInputField}
                  />
                )}
                defaultValue={field.value}
              />
            </Grid>
          ))
        : null}
      <Grid item>
        <TextField
          label="Grau de parentesco com o aluno"
          variant="outlined"
          id="relationship"
          name="relationship"
          select
          SelectProps={{ MenuProps: { disableScrollLock: true } }}
          value={relationship || ''}
          onChange={e => changeRelationship(e.target.value)}
          disabled={!editing}
          error={!!errors.relationship}
          helperText={errors.relationship ? errors.relationship?.message : null}
          className={classes.screenInputField}
        >
          {Object.values(DEPENDENT_RELATIONSHIP).map(item => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {guardianFields(guardian).map(field => (
        <Grid item key={field.label}>
          <Grid item key={field.label}>
            <Controller
              name={field.name}
              control={control}
              as={({ value, onChange }) => (
                <field.component
                  variant="outlined"
                  mask={field.mask || ''}
                  label={field.label}
                  name={field.name}
                  value={value}
                  onChange={onChange}
                  error={!!errors[field.name]}
                  helperText={
                    errors[field.name] ? errors[field.name]?.message : null
                  }
                  inputRef={register}
                  disabled={!editing}
                  className={classes.screenInputField}
                />
              )}
              defaultValue={field.value}
            />
          </Grid>
        </Grid>
      ))}
      <RGForm
        rg={guardian?.rg}
        editing={editing}
        register={register}
        errors={errors}
        control={control}
      />
      <Grid item>
        <TextField
          label="Estado Civil"
          variant="outlined"
          id="civilStatus"
          name="civilStatus"
          select
          SelectProps={{ MenuProps: { disableScrollLock: true } }}
          value={civilStatus || ''}
          onChange={e => changeCivilStatus(e.target.value)}
          disabled={!editing}
          error={!!errors.civilStatus}
          helperText={errors.civilStatus ? errors.civilStatus?.message : null}
          className={classes.screenInputField}
        >
          <MenuItem value={CIVIL_STATUS.SINGLE}>Solteiro(a)</MenuItem>
          <MenuItem value={CIVIL_STATUS.MARRIED}>Casado(a)</MenuItem>
          <MenuItem value={CIVIL_STATUS.STABLE_UNION}>União Estável</MenuItem>
          <MenuItem value={CIVIL_STATUS.DIVORCED}>Divorciado(a)</MenuItem>
          <MenuItem value={CIVIL_STATUS.LEGALLY_SEPARATED}>
            Separado(a) judicialmente
          </MenuItem>
          <MenuItem value={CIVIL_STATUS.WIDOW}>Viúvo(a)</MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );
};

export default GuardianForm;
