import { Box, Grid, ListItemText } from '@material-ui/core';
import AlertCard from 'components/AlertCard';
import { CustomIcon } from 'components/CustomIcon';
import { ControllerInputText } from 'components/Forms/ControllerInputText';
import { Controller } from 'react-hook-form';
import {
  AvatarPlaceholderContainer,
  AvatarPlaceholderText,
  CameraButton,
  ContainerSiderGrid,
  ContainerSpacedGrid,
  HiddenFileInput,
  ImagePlaceholderContainer,
  InputText,
  SelectMenuColorDotBorder,
  SelectMenuContainerColorDots,
  SelectMenuInputContainerColorDots,
  SelectMenuItemContainer,
  SelectMenuItemDivider,
  YoutubeLinkGridItem,
} from '../../styles';

import { IGeneraFormProps } from '../../types';
import { useExtraDataFormController } from './controller';

export const ExtraDataForm = ({
  control,
  register,
  errors,
  setValue,
  setImagesValues,
  serviceToUpdate,
}: IGeneraFormProps) => {
  const {
    avatarInputRef,
    coverInputRef,
    serviceImagesRefs,
    avatarFile,
    onChangeAvatar,
    onClickAvatarUpload,
    coverFile,
    onChangeCover,
    onClickCoverUpload,
    serviceImagesFiles,
    onClickServiceImagesUpload,
    onChangeServiceImages,
    imageSizeError,
    clearImageSizeError,
    MenuProps,
    selectedColor,
    handleSelectColor,
    colors,
  } = useExtraDataFormController({
    setValue,
    setImagesValues,
    defaultColor: serviceToUpdate?.color ?? '',
  });

  return (
    <>
      <Grid container spacing={2}>
        <ContainerSiderGrid container item md={2} spacing={0}>
          <Grid item md={9}>
            <AvatarPlaceholderContainer
              imageUrl={avatarFile?.url ?? serviceToUpdate?.partnerLogo}
            >
              {!avatarFile?.url && !serviceToUpdate?.partnerLogo ? (
                <>
                  <AvatarPlaceholderText>
                    Logo do parceiro
                  </AvatarPlaceholderText>
                  <AvatarPlaceholderText>(500 x 500 px)</AvatarPlaceholderText>
                  <AvatarPlaceholderText>Máx. 1MB</AvatarPlaceholderText>
                </>
              ) : null}

              <Box display="flex" justifyContent="center">
                <HiddenFileInput
                  type="file"
                  accept="image/png, image/jpeg"
                  ref={avatarInputRef}
                  onChange={onChangeAvatar}
                />

                <CameraButton onClick={onClickAvatarUpload}>
                  <CustomIcon name="addPhoto" />
                </CameraButton>
              </Box>
            </AvatarPlaceholderContainer>
          </Grid>
          <SelectMenuInputContainerColorDots item md={9}>
            <Controller
              name="color"
              control={control}
              defaultValue={serviceToUpdate?.color}
              as={({ value }) => (
                <InputText
                  id="color"
                  select
                  innerSelectedColor={selectedColor}
                  SelectProps={{
                    MenuProps,
                  }}
                  name="color"
                  label="Cor"
                  variant="outlined"
                  value={value}
                  onBlur={undefined}
                  error={!!errors.color}
                  helperText={errors.color ? errors.color?.message : null}
                  inputRef={register}
                  fixedWidth
                >
                  <SelectMenuItemContainer
                    id="header"
                    key="header"
                    value={undefined}
                    onClick={undefined}
                  >
                    <ListItemText>Cor</ListItemText>
                  </SelectMenuItemContainer>
                  <SelectMenuItemDivider />
                  <SelectMenuContainerColorDots>
                    {colors.map(color => {
                      return (
                        <SelectMenuColorDotBorder
                          color={color}
                          selectedColor={selectedColor}
                          onClick={() => {
                            handleSelectColor(color);
                          }}
                        >
                          <div className="color-dot" />
                        </SelectMenuColorDotBorder>
                      );
                    })}
                  </SelectMenuContainerColorDots>
                </InputText>
              )}
            />
          </SelectMenuInputContainerColorDots>
        </ContainerSiderGrid>
        <Grid container item md={10}>
          <Grid item md={12}>
            <ControllerInputText
              name="description"
              control={control}
              defaultValue={serviceToUpdate?.description}
              label="Sobre o serviço"
              errors={errors}
              register={register}
              multiline
              rows={10}
            />
          </Grid>
          <Grid item md={12}>
            <ImagePlaceholderContainer
              imageUrl={coverFile?.url ?? serviceToUpdate?.coverPhoto}
            >
              {!coverFile?.url && !serviceToUpdate?.coverPhoto ? (
                <>
                  <AvatarPlaceholderText>
                    Imagem de capa do serviço
                  </AvatarPlaceholderText>
                  <AvatarPlaceholderText>(1200 x 270 px)</AvatarPlaceholderText>
                  <AvatarPlaceholderText>Máx. 1MB</AvatarPlaceholderText>
                </>
              ) : null}
              <HiddenFileInput
                type="file"
                accept="image/png, image/jpeg"
                ref={coverInputRef}
                onChange={onChangeCover}
              />
              <CameraButton onClick={onClickCoverUpload}>
                <CustomIcon name="addPhoto" />
              </CameraButton>
            </ImagePlaceholderContainer>
          </Grid>
          <Grid container item md={12}>
            {serviceImagesRefs.map((ref, index) => {
              return (
                <ContainerSpacedGrid
                  key={`service-images-${index}`}
                  item
                  md={4}
                  isMiddleGridItem={index === 1}
                >
                  <ImagePlaceholderContainer
                    imageUrl={
                      serviceImagesFiles[index]?.url ??
                      serviceToUpdate?.galleryImages?.[index]?.source
                    }
                  >
                    {!serviceImagesFiles[index]?.url &&
                    !serviceToUpdate?.galleryImages?.[index]?.source ? (
                      <>
                        <AvatarPlaceholderText>
                          Imagem do serviço
                        </AvatarPlaceholderText>
                        <AvatarPlaceholderText>
                          (500 x 300 px)
                        </AvatarPlaceholderText>
                        <AvatarPlaceholderText>Máx. 1MB</AvatarPlaceholderText>
                      </>
                    ) : null}
                    <HiddenFileInput
                      type="file"
                      accept="image/png, image/jpeg"
                      ref={ref}
                      onChange={event => {
                        onChangeServiceImages(event, index);
                      }}
                    />
                    <CameraButton
                      onClick={() => onClickServiceImagesUpload(index)}
                    >
                      <CustomIcon name="addPhoto" />
                    </CameraButton>
                  </ImagePlaceholderContainer>
                </ContainerSpacedGrid>
              );
            })}

            <YoutubeLinkGridItem container item md={12}>
              <Grid item md={12}>
                <ControllerInputText
                  name="youtubeVideo"
                  control={control}
                  defaultValue={serviceToUpdate?.videoLink}
                  label="Link do vídeo"
                  errors={errors}
                  register={register}
                />
              </Grid>
            </YoutubeLinkGridItem>
          </Grid>
        </Grid>
      </Grid>
      <AlertCard
        message={imageSizeError}
        open={!!imageSizeError}
        close={clearImageSizeError}
        severity="error"
      />
    </>
  );
};
