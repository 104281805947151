import { DarkTooltip } from 'components/DarkToolTip';
import { useCallback } from 'react';
import { BannerInfo } from 'pages/Client/MarketplaceServicesDetail/components/BannerInfo';
import { useServiceCardController } from './useServiceCardController';

import {
  CardContainer,
  CardCover,
  LabelAccessCodeContainer,
  Avatar,
  CardHeader,
} from './styles';
import { ServiceCardProps } from './types';
import { EARLY_ACCESS, INVITE_CODE } from './texts';

export const ServiceCard = ({
  service,
  selectedRegistrationId,
  serviceType,
}: ServiceCardProps) => {
  const { navigateToServiceDetails, serviceCardCaption } =
    useServiceCardController({
      service,
      selectedRegistrationId,
      serviceType,
    });

  const renderLabel = useCallback(() => {
    if (service.earlyAccess) {
      return (
        <BannerInfo
          text="Acesso antecipado"
          variant="secondary"
          toolTipText={EARLY_ACCESS}
          mini
        />
      );
    }

    if (serviceType.onlyByInvite) {
      return (
        <BannerInfo
          text="Precisa de código de convite"
          variant="primary"
          toolTipText={INVITE_CODE}
          mini
        />
      );
    }

    return null;
  }, [service.earlyAccess, serviceType.onlyByInvite]);

  return (
    <CardContainer onClick={navigateToServiceDetails}>
      <CardCover cover={service?.coverPhoto || ''}>{renderLabel()}</CardCover>
      <div className="card-body">
        <CardHeader>
          <p>{service.name}</p>
          {serviceType.hasAnnuity && service.partnerLogo ? (
            <Avatar
              alt={`${service.name}-partner-avatar`}
              src={service.partnerLogo}
            />
          ) : null}
        </CardHeader>
        <div className="card-caption">
          <span>{serviceCardCaption}</span>
        </div>
      </div>
    </CardContainer>
  );
};
