import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import { useStyles } from 'components/DependentDocumentsForm/styles';
import MaskedTextField from 'components/MaskedTextField';
import { ResponsiveInputGridItem } from 'components/ResponsiveInputGridItem';
import { useAuth } from 'contexts/auth';
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import useGlobalStyles from 'styles';
import { CEP_MASK } from 'texts';
import { Address } from 'types/address';
import { FormProperties } from './types';
import {
  EDITABLE_FIELD,
  READ_ONLY_FIELD,
  TRIES_TO_SEARCH_CEP,
  mainAddressFields,
} from './utils';

const AddressForm: React.FC<FormProperties> = ({
  address,
  searchAddress,
  cepSearch,
  cepErrorCount,
  guardianAddress,
  hasGuardianAddress,
  setHasGuardianAddress,
  editing,
  register,
  errors,
  control,
}) => {
  const classes = useGlobalStyles();
  const styles = useStyles();
  const { loading } = useAuth();

  const displayAddress = useMemo(() => {
    return hasGuardianAddress ? guardianAddress : searchAddress;
  }, [guardianAddress, hasGuardianAddress, searchAddress]);

  // main fields (street, district, etc..) can only be enabled after cep search fails a few times
  const mainFieldsEnabled = useMemo(() => {
    const errorCountExceeded = cepErrorCount >= TRIES_TO_SEARCH_CEP;

    // if the box "Same as guardian address" is checked, the values can't be edited
    if (hasGuardianAddress) return false;
    if (editing && errorCountExceeded) return true;

    return false;
  }, [cepErrorCount, editing, hasGuardianAddress]);

  const helperText = (key: string) => {
    if (errors.address?.[key]) {
      return errors.address?.[key]?.message;
    }
    if (mainFieldsEnabled) return EDITABLE_FIELD;

    return READ_ONLY_FIELD;
  };

  const handleSearchCEP = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (cepSearch) cepSearch(e.target.value);
  };

  return (
    <Grid item container spacing={4} className={classes.screenInputGrid}>
      <ResponsiveInputGridItem className={classes.adornedItem}>
        <Controller
          name="address.cep"
          control={control}
          as={({ value, onChange }) => (
            <MaskedTextField
              mask={CEP_MASK}
              label="CEP"
              name="address.cep"
              value={guardianAddress?.cep || value}
              onChange={onChange}
              onBlur={handleSearchCEP}
              error={!!errors.address?.cep}
              helperText={
                errors.address?.cep ? errors.address?.cep.message : null
              }
              inputRef={register}
              disabled={hasGuardianAddress || !editing}
              className={classes.maxWidthInput}
            />
          )}
          defaultValue={address?.cep || ''}
        />
        {loading ? (
          <CircularProgress
            className={classes.adornment}
            size={20}
            color="primary"
          />
        ) : null}
      </ResponsiveInputGridItem>
      <ResponsiveInputGridItem>
        <Controller
          name="address.number"
          control={control}
          as={({ value, onChange }) => (
            <TextField
              variant="outlined"
              label="Número"
              id="address.number"
              name="address.number"
              value={guardianAddress?.number || value}
              onChange={onChange}
              error={!!errors.address?.number}
              helperText={
                errors.address?.number ? errors.address?.number.message : null
              }
              disabled={hasGuardianAddress || !editing}
              inputRef={register}
              className={classes.maxWidthInput}
            />
          )}
          defaultValue={address?.number || ''}
        />
      </ResponsiveInputGridItem>
      <ResponsiveInputGridItem>
        <Controller
          name="address.complement"
          control={control}
          as={({ value, onChange }) => (
            <TextField
              variant="outlined"
              label="Complemento"
              id="address.complement"
              name="address.complement"
              value={guardianAddress?.complement || value || '---'}
              onChange={onChange}
              error={!!errors.address?.complement}
              helperText={
                errors.address?.complement
                  ? errors.address?.complement.message
                  : null
              }
              disabled={hasGuardianAddress || !editing}
              inputRef={register}
              className={classes.maxWidthInput}
            />
          )}
          defaultValue={address?.complement || ''}
        />
      </ResponsiveInputGridItem>
      {mainAddressFields.map(field => (
        <ResponsiveInputGridItem item key={field.label}>
          <Controller
            name={field.name}
            control={control}
            as={({ value, onChange }) => (
              <TextField
                variant="outlined"
                label={field.label}
                id={field.name}
                name={field.name}
                value={displayAddress?.[field.key as keyof Address] || value}
                onChange={mainFieldsEnabled ? onChange : undefined}
                error={!!errors.address?.[field.key]}
                helperText={helperText(field.key)}
                disabled={hasGuardianAddress || !editing}
                inputRef={register}
                inputProps={field.inputProps}
                className={classes.maxWidthInput}
              />
            )}
            defaultValue={address?.[field.key as keyof Address] || ''}
          />
        </ResponsiveInputGridItem>
      ))}
      {hasGuardianAddress !== undefined ? (
        <ResponsiveInputGridItem>
          <FormControlLabel
            className={styles.checkbox}
            control={
              <Checkbox
                checked={hasGuardianAddress}
                value={hasGuardianAddress}
                onChange={setHasGuardianAddress}
                color="secondary"
                disabled={!editing}
              />
            }
            label="Mesmo endereço do responsável"
          />
        </ResponsiveInputGridItem>
      ) : null}
    </Grid>
  );
};

export default AddressForm;
