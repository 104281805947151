import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import PartnersService from 'services/partnersService';

import { FileState } from '../components/PartnersForm/components/ExtraDataForm/types';
import { schema } from '../components/PartnersForm/schema';
import { IPartnersFieldValues } from '../components/PartnersForm/types';

export const usePartnerCreationController = () => {
  // Navigation
  const history = useHistory();

  // Form
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    setValue,
    getValues,
  } = useForm<IPartnersFieldValues>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  // States
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [avatarFile, setAvatarFile] = useState<FileState>();
  const [loading, setLoading] = useState(false);

  const submit = useCallback(
    async (values: IPartnersFieldValues) => {
      setLoading(true);
      const data = new FormData();
      data.append('name', values.name);
      data.append('description', values.description);
      data.append('email', values.email);
      data.append('phone', values.phone);
      data.append('color', values.color);
      data.append('cnpj', values.cnpj);
      data.append('photo', avatarFile?.file as Blob);
      values.category.forEach(categoryName => {
        data.append('serviceCategories[]', categoryName);
      });
      data.append('feeToBeCharged', values.tax.replace('%', ''));

      const res = await PartnersService.createPartner(data);

      if (res.status === 201) {
        setSuccessModalOpen(true);
      } else {
        setSubmitError(res.data.message);
      }
      setLoading(true);
    },
    [avatarFile],
  );

  const handleCloseSubmitError = () => {
    setSubmitError('');
  };

  const handleCancel = () => {
    setCancelModalOpen(true);
  };

  const handleCloseSuccessModal = () => {
    history.push('/adm-marketplace/partners');
    setSuccessModalOpen(false);
  };

  const handleCloseCancelModal = () => {
    setCancelModalOpen(false);
    history.goBack();
  };

  const handleCloseAndNotGoBack = () => {
    setSuccessModalOpen(false);
    setCancelModalOpen(false);
  };

  return {
    submit,
    handleSubmit,
    errors,
    register,
    control,
    setValue,
    getValues,
    handleCancel,
    successModalOpen,
    cancelModalOpen,
    handleCloseSuccessModal,
    handleCloseCancelModal,
    handleCloseAndNotGoBack,
    setAvatarFile,
    submitError,
    handleCloseSubmitError,
    loading,
  };
};
