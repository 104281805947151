import { Dependent } from 'types/dependent';
import { DOCUMENT_TYPE } from 'types/enums';
import { UploadedDocument } from 'types/uploadedDocument';
import { dependentAddressSchema } from 'utils/schemas/addressSchema';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  hasGuardianAddress: Yup.boolean(),
  address: dependentAddressSchema,
});

export const uploadList = (documents: UploadedDocument[]) => {
  return [
    {
      title: 'Certidão de nascimento',
      type: DOCUMENT_TYPE.CERTIDAO_NASCIMENTO,
      savedDocument: findDocument(documents, DOCUMENT_TYPE.CERTIDAO_NASCIMENTO),
    },
    {
      title: 'Histórico escolar',
      type: DOCUMENT_TYPE.HISTORICO_ESCOLAR,
      savedDocument: findDocument(documents, DOCUMENT_TYPE.HISTORICO_ESCOLAR),
    },
    {
      title: 'Documento do responsável',
      type: DOCUMENT_TYPE.DOCUMENTO_RESPONSAVEL,
      savedDocument: findDocument(
        documents,
        DOCUMENT_TYPE.DOCUMENTO_RESPONSAVEL,
      ),
    },
    {
      title: 'Carteira de vacinação',
      type: DOCUMENT_TYPE.CARTEIRA_VACINACAO,
      savedDocument: findDocument(documents, DOCUMENT_TYPE.CARTEIRA_VACINACAO),
    },
    {
      title: 'Declaração de convênio (AABB/ASDEP/FBM)',
      type: DOCUMENT_TYPE.DECLARACAO_CONVENIO,
      savedDocument: findDocument(documents, DOCUMENT_TYPE.DECLARACAO_CONVENIO),
    },
  ];
};

export const findDocument = (
  documents: UploadedDocument[],
  type: DOCUMENT_TYPE,
) => {
  return documents?.find(doc => {
    if (doc.type === type) return doc;
  });
};

export const nonEditableFields = (dependent: Dependent) => [
  {
    label: 'Nome completo',
    value: dependent.name,
    name: 'name',
  },
  {
    label: 'Data de nascimento',
    value: dependent.birthday,
    name: 'birthday',
  },
  {
    label: 'CPF',
    value: dependent.cpf,
    name: 'cpf',
  },
  {
    label: 'RG',
    value: dependent.rg?.rgNumber,
    name: 'rg.rgNumber',
  },
  {
    label: 'Órgão Emissor',
    value: dependent.rg?.emitter,
    name: 'rg.emitter',
  },
  {
    label: 'Nome da mãe',
    value: dependent.mother,
    name: 'mother',
  },
  {
    label: 'Naturalidade',
    value: dependent.birthplace,
    name: 'birthplace',
  },
  {
    label: 'Colégio de origem',
    value: dependent.previousSchool,
    name: 'previousSchool',
  },
];

export const editableFields = (dependent: Dependent) => [
  {
    label: 'Número',
    value: dependent.address.number,
    name: 'address.number',
  },
  {
    label: 'Complemento',
    value: dependent.address.complement || '---',
    name: 'address.complement',
  },
];
