import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useCart } from 'hooks/useCart';
import { useStyles } from './styles';

export const useItemCartController = () => {
  // Custom Hooks
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const { handleRemoveServiceFromCart } = useCart();

  const styles = useStyles();

  return { styles, handleRemoveServiceFromCart, isMobile };
};
