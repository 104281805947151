import React from 'react';
import { Badge, Grid, Typography } from '@material-ui/core';
import useGlobalStyles from 'styles';
import { TabLabelProperties } from './types';

const TabLabel: React.FC<TabLabelProperties> = ({ label, index }) => {
  const classes = useGlobalStyles();

  return (
    <Grid container direction="row" justifyContent="flex-start">
      <Grid item style={{ marginRight: 25 }}>
        <Badge badgeContent={index} color="primary" />
      </Grid>
      <Grid item>
        <Typography className={classes.textButton}>{label}</Typography>
      </Grid>
    </Grid>
  );
};

export default TabLabel;
