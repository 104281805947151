import { CircularProgress } from '@material-ui/core';
import Scaffold from 'components/Scaffold';
import { SERVICE_MAJOR_TYPE } from 'types/enums';
import { CategorizedServices } from '../components/CategorizedServices';
import { EmptyPlaceholderContainer } from '../components/EmptyPlaceholderContainer';
import { MarketplaceFilteredSearch } from '../components/MarketplaceFilteredSearch';
import { ServiceHeader } from '../components/ServiceHeader';
import {
  HomeContainer,
  ServicesContainer,
  ServicesMajorContainer,
} from './styles';
import { useNewMarkatplaceHomeController } from './useNewMarketplaceHomeController';

export const ClientMarketplaceHome = () => {
  const {
    // Search component
    registrations,
    selectedRegistrationSchoolClassId,

    // Select input (registrations/dependents)
    hasLoadedRequiredData,
    doesGuardianHaveDependents,
    selectedRegistrationId,
    handleOnSelectRegistrationId,

    // Landing page services
    loadingServices,
    regularServiceTypes,
    nonRecurrentServiceTypes,
    handleOnClickSeeAll,
  } = useNewMarkatplaceHomeController();

  const renderContent = () => {
    if (loadingServices) {
      return (
        <EmptyPlaceholderContainer>
          <CircularProgress size={100} />
        </EmptyPlaceholderContainer>
      );
    }

    if (!selectedRegistrationId) {
      return (
        <EmptyPlaceholderContainer>
          <span>
            Aqui vão aparecer as atividades e eventos disponíveis depois de
            selecionar o dependente.
          </span>
        </EmptyPlaceholderContainer>
      );
    }

    if (!regularServiceTypes?.length && !nonRecurrentServiceTypes?.length) {
      return (
        <EmptyPlaceholderContainer>
          <span>
            Ainda não existe nenhum serviço disponível para o dependente
            selecionado.
          </span>
        </EmptyPlaceholderContainer>
      );
    }

    return (
      <ServicesMajorContainer>
        {regularServiceTypes?.length ? (
          <div>
            <ServiceHeader
              variant="major"
              title={SERVICE_MAJOR_TYPE.GENERAL_ACTIVITIES}
            />
            <ServicesContainer>
              {regularServiceTypes.map(
                ({ shouldRenderSeeAllButton, ...servicesByType }) => {
                  return (
                    <CategorizedServices
                      key={servicesByType.id}
                      selectedRegistrationId={selectedRegistrationId}
                      servicesByType={servicesByType}
                      shouldRenderSeeAllButton={shouldRenderSeeAllButton}
                      onClickSeeAll={handleOnClickSeeAll}
                    />
                  );
                },
              )}
            </ServicesContainer>
          </div>
        ) : null}

        {nonRecurrentServiceTypes?.length ? (
          <div>
            <ServiceHeader
              variant="major"
              title={SERVICE_MAJOR_TYPE.NON_RECURRING_ACTIVITIES}
            />
            <ServicesContainer>
              {nonRecurrentServiceTypes.map(
                ({ shouldRenderSeeAllButton, ...servicesByType }) => {
                  return (
                    <CategorizedServices
                      key={servicesByType.id}
                      selectedRegistrationId={selectedRegistrationId}
                      servicesByType={servicesByType}
                      shouldRenderSeeAllButton={shouldRenderSeeAllButton}
                      onClickSeeAll={handleOnClickSeeAll}
                    />
                  );
                },
              )}
            </ServicesContainer>
          </div>
        ) : null}
      </ServicesMajorContainer>
    );
  };

  return (
    <Scaffold
      loading={!hasLoadedRequiredData}
      rawTitle={
        <>
          <b>Encontre</b> atividades, eventos e passeios
        </>
      }
      rawSubtitle={
        <>
          Você pode fazer contratações de um dependente <b>por vez</b>
        </>
      }
    >
      {!doesGuardianHaveDependents ? (
        <EmptyPlaceholderContainer>
          <span>
            Não há matriculas ativas disponiveis para a contratação de serviços.
            <br />
            Caso já tenha realizado a contratação do ano letivo, verifique se há
            pendências em pagamentos ou entre em contato com a secretaria.
          </span>
        </EmptyPlaceholderContainer>
      ) : (
        <HomeContainer>
          <MarketplaceFilteredSearch
            registrations={registrations}
            selectedRegistrationSchoolClassId={
              selectedRegistrationSchoolClassId
            }
            selectedRegistrationId={selectedRegistrationId}
            onSelectRegistrationId={handleOnSelectRegistrationId}
          />

          {renderContent()}
        </HomeContainer>
      )}
    </Scaffold>
  );
};
