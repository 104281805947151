import { CreateQueryParams } from '@nestjsx/crud-request';
import { api, getOneWithFilter, getWithFilter } from 'services/apiService';
import { ContractTemplates } from 'types/contractTemplates';

export default class ContractTemplatesService {
  static get baseModuleUrl() {
    return 'contract-templates';
  }

  static async getTemplates(query?: CreateQueryParams) {
    try {
      return await getWithFilter(this.baseModuleUrl, query);
    } catch (error: any) {
      return error.response;
    }
  }

  static async getOneById(templateId: number | string) {
    try {
      return await getOneWithFilter<ContractTemplates>(
        `contract-templates`,
        templateId,
      );
    } catch (error: any) {
      return error.response;
    }
  }

  static async update(
    templateId: number | string,
    data: Pick<ContractTemplates, 'name' | 'externalId'>,
  ) {
    try {
      return await api.patch(`${this.baseModuleUrl}/${templateId}`, data);
    } catch (error: any) {
      return error.response;
    }
  }

  static async create(data: Pick<ContractTemplates, 'name' | 'externalId'>) {
    try {
      return await api.post(this.baseModuleUrl, data);
    } catch (error: any) {
      return error.response;
    }
  }

  static async generateTemplateExample(templateId: number | string) {
    try {
      return await api.post(`${this.baseModuleUrl}/${templateId}`);
    } catch (error: any) {
      return error.response;
    }
  }
}
