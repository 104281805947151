import { useLocalStorageWithExpirationDate } from 'hooks/LocalStorage/ExpirationDate';
import { HooksStoredDataKeys } from 'hooks/LocalStorage/types';
import { StatusCodes } from 'http-status-codes';
import { useCallback, useEffect } from 'react';
import RegistrationService from 'services/registrationService';
import { CurrentActiveRegistrations } from 'services/types/registrationService.types';

/**
 * This controller should be imported only ONCE PER PAGE
 * to prevent re-rendering and multiple fetch isuses
 */
// FIXME: remove from local storage
export const useRegistrationsController = () => {
  // ---------- States ----------
  const [registrations, setRegistrations, shouldRefetchRegistrations] =
    useLocalStorageWithExpirationDate<CurrentActiveRegistrations>(
      HooksStoredDataKeys.REGISTRATIONS,
      {
        expiresIn: {
          minutes: 2,
        },
      },
    );

  // ---------- Callbacks ----------
  const fetchDependentsRegistrations = useCallback(async () => {
    try {
      const response =
        await RegistrationService.getCurrentActiveRegistrations();

      if (response.status === StatusCodes.OK) {
        const registrations = response.data;

        setRegistrations(registrations as CurrentActiveRegistrations);
      }
    } catch (error) {
      console.error(error);
    }
  }, [setRegistrations]);

  // ---------- Effects ----------
  useEffect(() => {
    if (shouldRefetchRegistrations) {
      fetchDependentsRegistrations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetchRegistrations]);

  return {
    registrations,
    hasLoadedRegistrations: !shouldRefetchRegistrations,
  };
};
