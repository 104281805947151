import { REGISTRATION_STATUS } from 'types/enums';
import { SchoolClassDetails } from 'types/schoolClassDetails';

export const REGISTRATION_STATUS_NO_NEXT_STEPS = [
  REGISTRATION_STATUS.ACTIVE,
  REGISTRATION_STATUS.DEFAULTING,
  REGISTRATION_STATUS.PENDING_INTERVIEW,
  // Allow user to re-register
  // REGISTRATION_STATUS.CANCELLED,
];

export const getNextClassToReRegister = (
  schoolClassDetailsList: SchoolClassDetails[],
  currentSchoolClassId: number,
) => {
  const nextClassId = currentSchoolClassId + 1;
  return schoolClassDetailsList.find(
    schoolClassDetailsItem =>
      schoolClassDetailsItem.schoolClassId === nextClassId,
  );
};
