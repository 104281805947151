import * as Sentry from '@sentry/react';
import { StatusCodes } from 'http-status-codes';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { api } from 'services/apiService';
import AuthService, {
  getCurrentUser,
  getToken,
  logout,
  storeToken,
  storeUser,
} from 'services/authService';
import UserService from 'services/userService';
import { INCORRECT_LOGIN, NOT_VERIFIED } from 'texts';
import { User } from 'types/user';
import { isAdmin } from 'utils/isAdmin';
import { isDeveloper } from 'utils/isDeveloper';

import { AuthContextProps } from './types';

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);
  const [loginError, setLoginError] = useState('');
  const isAdminUser = useMemo(() => isAdmin(user?.roles || []), [user?.roles]);
  const isDeveloperUser = useMemo(
    () => isDeveloper(user?.roles || []),
    [user?.roles],
  );

  async function signIn(email: string, password: string) {
    setLoginError('');
    const response = await UserService.login(email, password);
    if (response.status !== StatusCodes.CREATED) {
      setLoginError(INCORRECT_LOGIN);
    } else {
      const { data } = response;
      storeToken(data.accessToken);
      setInterceptors();
      getUser();
    }
  }

  const getUser = useCallback(async () => {
    const response = await UserService.me();
    if (response.status === StatusCodes.OK) {
      const { id, email, isVerified } = response.data;
      if (!isVerified) {
        signOut();
        await AuthService.verifyEmail(email);
        setLoginError(NOT_VERIFIED(email));
      } else {
        storeUser(response.data);
        setUser(response.data);
        Sentry.setUser({ id, email });
      }
    } else {
      logout();
    }
  }, []);

  const setInterceptors = useCallback(() => {
    api.defaults.headers.Authorization = `Bearer ${getToken()}`;
    api.interceptors.request.use(async config => {
      setLoading(true);
      return config;
    });
    api.interceptors.response.use(
      response => {
        setLoading(false);
        return response;
      },
      error => {
        setLoading(false);
        const res = error.response;
        Sentry.captureMessage(error.response.data.message);
        if (res.status === 401 && res.config.url !== 'auth/login') {
          signOut();
        }
        return Promise.reject(error);
      },
    );
  }, []);

  const loadStorage = useCallback(async () => {
    const storageToken = getToken();
    const storageCurrentUser = getCurrentUser();

    if (storageToken && storageCurrentUser) {
      setInterceptors();
      await getUser();
    }
    setLoadingUser(false);
  }, [getUser, setInterceptors]);

  useEffect(() => {
    loadStorage();
  }, [loadStorage]);

  function signOut() {
    setUser(null);
    Sentry.configureScope(scope => scope.setUser(null));
    logout();
  }

  return (
    <AuthContext.Provider
      // FIXME: KKKK até o eslint avisa que isso tá errado
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        signed: !!user,
        user,
        getUser,
        signIn,
        signOut,
        loading,
        loadingUser,
        loginError,
        setUser,
        isAdmin: isAdminUser,
        isDeveloper: isDeveloperUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextProps {
  const context = useContext(AuthContext);
  return context;
}
