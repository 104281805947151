import { NO_NUMBERS, REQUIRED_FIELD } from 'texts';
import { hasNoNumbers } from 'utils';
import * as Yup from 'yup';

export const stringSchema = Yup.string().required(REQUIRED_FIELD);

export const noNumbersStringSchema = stringSchema.test(
  'no numbers',
  NO_NUMBERS,
  value => hasNoNumbers(value),
);
