import PrivateRoute from 'components/PrivateRouter';
import { SideMenu } from 'components/SideMenu';
import AdmRegistrationDetails from 'pages/Admin/AdmRegistrationDetails';
import AdmRegistrations from 'pages/Admin/AdmRegistrations';
import GeneralConfigurations from 'pages/Admin/Configurations';
import Contracts from 'pages/Admin/Contracts';
import ContractTemplates from 'pages/Admin/ContractTemplates';
import { ContractTemplateForm } from 'pages/Admin/ContractTemplates/ContractTemplateForm';
import { CouponDetail } from 'pages/Admin/CouponDetail';
import { Coupons } from 'pages/Admin/Coupons';
import CreateInvoice from 'pages/Admin/CreateInvoice';
import Dashboard from 'pages/Admin/Dashboard';
import FinacialForecast from 'pages/Admin/FinancialReport';
import { FormCoupons } from 'pages/Admin/FormCoupons';
import Invoices from 'pages/Admin/Invoices';
import { HomeMarketplace } from 'pages/Admin/Marketplace/Home';
import { Partners } from 'pages/Admin/Marketplace/Partners';
import { PartnersCreation } from 'pages/Admin/Marketplace/Partners/PartnerCreation';
import { PartnersCreationCode } from 'pages/Admin/Marketplace/Partners/PartnersCreationCode';
import { PartnersCreationHome } from 'pages/Admin/Marketplace/Partners/PartnersCreationHome';
import { PartnersUpdate } from 'pages/Admin/Marketplace/Partners/PartnerUpdate';
import { Reports } from 'pages/Admin/Marketplace/Reports';
import { ServiceClassCreation } from 'pages/Admin/Marketplace/ServiceClasses/ServiceClassCreation';
import { ServiceClassInfo } from 'pages/Admin/Marketplace/ServiceClasses/ServiceClassInfo';
import { ServiceClassUpdate } from 'pages/Admin/Marketplace/ServiceClasses/ServiceUpdate';
import ServiceCodes from 'pages/Admin/Marketplace/ServiceCodes';
import { Services } from 'pages/Admin/Marketplace/Services';
import { ServiceCreation } from 'pages/Admin/Marketplace/Services/ServiceCreation';
import { ServiceDetail } from 'pages/Admin/Marketplace/Services/ServiceDetail';
import { ServiceUpdate } from 'pages/Admin/Marketplace/Services/ServiceUpdate';
import { FinancialReport } from 'pages/Admin/NewFinancialReport';
import NewGuardian from 'pages/Admin/NewGuardian';
import PaymentDetails from 'pages/Admin/PaymentDetails';
import Tuition from 'pages/Admin/Tuition';
import { TuitionDetail } from 'pages/Admin/TuitionDetail';
import UserDetails from 'pages/Admin/UserDetails';
import Users from 'pages/Admin/Users';
import NewRegistration from 'pages/Client/NewRegistration';
import Profile from 'pages/Client/Profile';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { GroupRole, USER_ROLES } from 'types/enums';
import { ADMIN_ROUTE } from './consts';

// WARNING: make sure to change routes in src/components/SideMenu/utils aswell

const AdminRoutes: React.FC = () => (
  <SideMenu>
    <Switch>
      <PrivateRoute path={ADMIN_ROUTE.HOME} exact component={Dashboard} />
      <PrivateRoute path={ADMIN_ROUTE.HOME_PROFILE} component={Profile} />
      <PrivateRoute
        path={ADMIN_ROUTE.REGISTRATIONS}
        exact
        component={AdmRegistrations}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.REGISTRATION_DETAILS}
        exact
        component={AdmRegistrationDetails}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.REGISTRATION_GUARDIAN_CREATION}
        component={NewGuardian}
      />
      <PrivateRoute
        exact
        path={ADMIN_ROUTE.MARKETPLACE_HOME}
        component={HomeMarketplace}
        roles={GroupRole.basicAdmin}
      />
      <PrivateRoute
        exact
        path={ADMIN_ROUTE.MARKETPLACE_SERVICES}
        component={Services}
        roles={GroupRole.basicAdmin}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.MARKETPLACE_SERVICE_DETAILS}
        component={ServiceDetail}
        roles={GroupRole.basicAdmin}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.MARKETPLACE_SERVICE_CLASS_DETAILS}
        component={ServiceClassInfo}
        roles={GroupRole.basicAdmin}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.MARKETPLACE_SERVICE_CREATION}
        component={ServiceCreation}
        roles={GroupRole.basicAdmin}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.MARKETPLACE_SERVICE_UPDATE}
        component={ServiceUpdate}
        roles={GroupRole.basicAdmin}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.MARKETPLACE_SERVICE_CLASS_CREATION}
        component={ServiceClassCreation}
        roles={GroupRole.basicAdmin}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.MARKETPLACE_SERVICE_CLASS_UPDATE}
        component={ServiceClassUpdate}
        roles={GroupRole.basicAdmin}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.MARKETPLACE_SERVICE_CODES}
        component={ServiceCodes}
        roles={GroupRole.basicAdmin}
      />
      <PrivateRoute
        exact
        path={ADMIN_ROUTE.MARKETPLACE_PARTNERS}
        component={Partners}
        roles={GroupRole.basicAdmin}
      />
      <PrivateRoute
        exact
        path={ADMIN_ROUTE.MARKETPLACE_PARTNERS_MANAGEMENT}
        component={PartnersCreationHome}
        roles={GroupRole.basicAdmin}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.MARKETPLACE_PARTNER_CREATION}
        component={PartnersCreation}
        roles={GroupRole.basicAdmin}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.MARKETPLACE_PARTNERS_CODE_CREATION}
        component={PartnersCreationCode}
        roles={GroupRole.basicAdmin}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.MARKETPLACE_PARTNER_UPDATE}
        component={PartnersUpdate}
        roles={GroupRole.basicAdmin}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.MARKETPLACE_PARTNER_UPDATE}
        component={Services}
        roles={GroupRole.basicAdmin}
      />

      <PrivateRoute
        path={ADMIN_ROUTE.MARKETPLACE_REPORTS}
        component={Reports}
        roles={[USER_ROLES.DEVELOPER]}
      />
      <PrivateRoute
        exact
        path={ADMIN_ROUTE.TUITION}
        component={Tuition}
        roles={[USER_ROLES.TREASURY, USER_ROLES.ADMIN, USER_ROLES.DEVELOPER]}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.TUITION_DETAILS}
        component={TuitionDetail}
        roles={[USER_ROLES.TREASURY, USER_ROLES.ADMIN, USER_ROLES.DEVELOPER]}
      />
      <PrivateRoute
        exact
        path={ADMIN_ROUTE.COUPONS}
        component={Coupons}
        roles={[USER_ROLES.ADMIN, USER_ROLES.DEVELOPER]}
      />

      <PrivateRoute
        path={ADMIN_ROUTE.COUPON_CREATION}
        component={FormCoupons}
        roles={[USER_ROLES.ADMIN, USER_ROLES.DEVELOPER]}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.COUPON_UPDATE}
        component={FormCoupons}
        roles={[USER_ROLES.ADMIN, USER_ROLES.DEVELOPER]}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.COUPON_DETAILS}
        component={CouponDetail}
        roles={[USER_ROLES.ADMIN, USER_ROLES.DEVELOPER]}
      />
      <PrivateRoute exact path={ADMIN_ROUTE.CONTRACTS} component={Contracts} />
      <PrivateRoute
        exact
        path={ADMIN_ROUTE.USERS}
        component={Users}
        roles={[
          USER_ROLES.TREASURY,
          USER_ROLES.ADMIN,
          USER_ROLES.DEVELOPER,
          USER_ROLES.SECRETARY,
          USER_ROLES.COORDINATOR,
        ]}
      />
      <PrivateRoute
        exact
        path={ADMIN_ROUTE.USER_DETAILS}
        component={UserDetails}
        roles={[
          USER_ROLES.ADMIN,
          USER_ROLES.DEVELOPER,
          USER_ROLES.TREASURY,
          USER_ROLES.SECRETARY,
        ]}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.USER_CREATION}
        component={NewRegistration}
        roles={[
          USER_ROLES.TREASURY,
          USER_ROLES.ADMIN,
          USER_ROLES.DEVELOPER,
          USER_ROLES.SECRETARY,
          USER_ROLES.COORDINATOR,
        ]}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.PAYMENTS}
        exact
        component={FinancialReport}
        roles={[USER_ROLES.TREASURY, USER_ROLES.ADMIN, USER_ROLES.DEVELOPER]}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.PAYMENT_DETAILS}
        exact
        component={PaymentDetails}
        roles={[USER_ROLES.TREASURY, USER_ROLES.ADMIN, USER_ROLES.DEVELOPER]}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.PAYMENT_FORECAST}
        component={FinacialForecast}
        roles={[USER_ROLES.TREASURY, USER_ROLES.ADMIN, USER_ROLES.DEVELOPER]}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.CONTRACT_TEMPLATE_UPDATE}
        component={ContractTemplateForm}
        roles={[USER_ROLES.ADMIN, USER_ROLES.DEVELOPER]}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.CONTRACT_TEMPLATE_CREATION}
        component={ContractTemplateForm}
        roles={[USER_ROLES.ADMIN, USER_ROLES.DEVELOPER]}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.CONTRACT_TEMPLATES}
        component={ContractTemplates}
        roles={[USER_ROLES.ADMIN, USER_ROLES.DEVELOPER]}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.CONFIGURATIONS}
        component={GeneralConfigurations}
        roles={[USER_ROLES.TREASURY, USER_ROLES.ADMIN, USER_ROLES.DEVELOPER]}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.INVOICES}
        component={Invoices}
        roles={GroupRole.financial}
      />
      <PrivateRoute
        path={ADMIN_ROUTE.CREATE_MANUAL_INVOICE}
        exact
        component={CreateInvoice}
        roles={[USER_ROLES.TREASURY, USER_ROLES.ADMIN, USER_ROLES.DEVELOPER]}
      />
      <Route path="*">
        <Redirect to={ADMIN_ROUTE.HOME} />
      </Route>
    </Switch>
  </SideMenu>
);

export default AdminRoutes;
