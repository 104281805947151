import AccountBalanceWalletOutlined from '@material-ui/icons/AccountBalanceWalletOutlined';
import AccountCircleOutlined from '@material-ui/icons/AccountCircleOutlined';
import AssessmentOutlined from '@material-ui/icons/AssessmentOutlined';
import AssignmentOutlined from '@material-ui/icons/AssignmentOutlined';
import CreditCard from '@material-ui/icons/CreditCard';
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';
import HomeOutlined from '@material-ui/icons/HomeOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import LocalAtm from '@material-ui/icons/LocalAtm';
import MoneyOffOutlined from '@material-ui/icons/MoneyOffOutlined';
import PeopleOutline from '@material-ui/icons/PeopleOutline';
import SettingsOutlined from '@material-ui/icons/SettingsOutlined';

import StorefrontIcon from '@material-ui/icons/Storefront';
import { ADMIN_ROUTE } from 'routes/consts';
import { GroupRole, USER_ROLES } from 'types/enums';
import { isAdmin } from 'utils/isAdmin';

// WARNING: make sure to change routes in src/routes/(clientRoutes | adminRoutes) aswell

type IRoutes = {
  icon: any;
  route: string;
  title: string;
  roles: USER_ROLES[];
  onClick?: () => void;
};

// Rotas para usuários onde isAdmin = false
// Verificado em produção - 10/01/2023
// Roles que possuem isAdmin = false: "Responsável"
// A ordem dos elementos IMPORTA e está de acordo com o layout!
export const clientRoutes: IRoutes[] = [
  {
    icon: HomeOutlined,
    route: '/home',
    title: 'Início',
    roles: [USER_ROLES.GUARDIAN],
  },
  {
    icon: AccountCircleOutlined,
    route: '/dependents',
    title: 'Dependentes',
    roles: [USER_ROLES.GUARDIAN],
  },
  {
    icon: AssignmentOutlined,
    route: '/registrations',
    title: 'Matrículas',
    roles: [USER_ROLES.GUARDIAN],
  },
  {
    icon: DescriptionOutlined,
    route: '/student-report-cards',
    title: 'Boletins',
    roles: [USER_ROLES.GUARDIAN],
  },
  {
    icon: StorefrontIcon,
    route: '/client-marketplace',
    title: 'Marketplace',
    roles: [USER_ROLES.GUARDIAN],
  },
  {
    icon: LocalAtm,
    route: '/financial',
    title: 'Financeiro',
    roles: [USER_ROLES.GUARDIAN],
  },
  {
    icon: CreditCard,
    route: '/cards',
    title: 'Meus cartões',
    roles: [USER_ROLES.GUARDIAN],
  },
  {
    icon: AssignmentOutlined,
    route: '/income-tax-return',
    title: 'Declaração IR',
    roles: [USER_ROLES.GUARDIAN],
  },
  {
    icon: AssignmentOutlined,
    route: '/contracts',
    title: 'Contratos',
    roles: [USER_ROLES.GUARDIAN],
  },
];

// Rotas para usuários onde isAdmin = true
// Verificado em produção - 10/01/2023
// Roles que possuem isAdmin = true: "Admin", "Coordenação", "Secretaria" e "Tesouraria"
// A ordem dos elementos IMPORTA e está de acordo com o layout!
export const adminRoutes: IRoutes[] = [
  {
    icon: HomeOutlined,
    route: '/adm-home',
    title: 'Início',
    roles: [
      USER_ROLES.ADMIN,
      USER_ROLES.SECRETARY,
      USER_ROLES.COORDINATOR,
      USER_ROLES.TREASURY,
    ],
  },
  {
    icon: AssignmentOutlined,
    route: '/adm-registrations',
    title: 'Matrículas',
    roles: [USER_ROLES.ADMIN, USER_ROLES.SECRETARY, USER_ROLES.TREASURY],
  },
  {
    icon: StorefrontIcon,
    route: '/adm-marketplace',
    title: 'Marketplace',
    roles: [USER_ROLES.ADMIN, USER_ROLES.COORDINATOR, USER_ROLES.TREASURY],
  },
  {
    icon: AccountBalanceWalletOutlined,
    route: '/adm-tuition',
    title: 'Anuidade',
    roles: [USER_ROLES.ADMIN, USER_ROLES.TREASURY],
  },
  {
    icon: MoneyOffOutlined,
    route: '/adm-coupons',
    title: 'Cupons',
    roles: [USER_ROLES.ADMIN],
  },
  {
    icon: AssignmentOutlined,
    route: '/adm-contracts',
    title: 'Contratos',
    roles: [
      USER_ROLES.ADMIN,
      USER_ROLES.SECRETARY,
      USER_ROLES.COORDINATOR,
      USER_ROLES.TREASURY,
    ],
  },
  {
    icon: LocalAtm,
    route: ADMIN_ROUTE.PAYMENTS,
    title: 'Financeiro',
    roles: GroupRole.financial,
  },
  {
    icon: PeopleOutline,
    route: '/adm-users',
    title: 'Usuários',
    roles: [
      USER_ROLES.ADMIN,
      USER_ROLES.SECRETARY,
      USER_ROLES.COORDINATOR,
      USER_ROLES.TREASURY,
    ],
  },
  {
    icon: AssessmentOutlined,
    route: '/adm-forecast',
    title: 'Previsão',
    roles: [USER_ROLES.ADMIN, USER_ROLES.TREASURY],
  },
  {
    icon: InsertDriveFileOutlinedIcon,
    route: ADMIN_ROUTE.INVOICES,
    title: 'Notas fiscais',
    roles: GroupRole.financial,
  },
  {
    icon: SettingsOutlined,
    route: '/adm-configurations',
    title: 'Configurações',
    roles: [USER_ROLES.ADMIN, USER_ROLES.TREASURY],
  },
];

export const getRoutesByUserRoles = (roles: USER_ROLES[] = []) => {
  const isDeveloper = roles.includes(USER_ROLES.DEVELOPER);
  const mainRoutes = isAdmin(roles) ? adminRoutes : clientRoutes;

  // Caso "Desenvolvedor", retornar todas as rotas de acordo com seu isAdmin,
  // Independente de possuir as outras roles ou não.
  if (isDeveloper) return mainRoutes;

  // Se não for "Desenvolvedor", retornar somente as rotas
  // para as quais possui a role necessária
  return mainRoutes.filter(routeItem => {
    const hasRole = routeItem.roles.some(role => roles.includes(role));
    return hasRole;
  });
};
