import { Typography } from '@material-ui/core';
import React from 'react';
import useGlobalStyles from 'styles';
import { PAYMENT_METHODS, PAYMENT_TYPES } from 'types/enums';

const PaymentType = ({
  paymentMethod,
  rowsLength,
}: {
  paymentMethod: PAYMENT_METHODS;
  rowsLength: number;
}) => {
  const classes = useGlobalStyles();

  if (paymentMethod !== PAYMENT_METHODS.CARD) {
    return null;
  }

  return (
    <Typography className={classes.confirmScreenText1}>
      {rowsLength === 1 ? (
        <b>{PAYMENT_TYPES.COMPLETE}</b>
      ) : (
        <b>{PAYMENT_TYPES.SPLIT}</b>
      )}
    </Typography>
  );
};

export default PaymentType;
