import { REQUIRED_FIELD } from 'texts';
import * as Yup from 'yup';

const generalFieldsSchema = {
  serviceAmount: Yup.string().required(REQUIRED_FIELD),
};

export const schema = Yup.object({
  ...generalFieldsSchema,
});
