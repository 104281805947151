import { Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';
import { MessageProperties } from './types';

const OutlinedMessage: React.FC<MessageProperties> = ({ message, icon }) => {
  const styles = useStyles();

  return (
    <Paper elevation={0} className={styles.paper}>
      <Grid
        item
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item className={styles.icon}>
          {icon}
        </Grid>
        <Grid item className={styles.text}>
          <Typography className={styles.message}>{message}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OutlinedMessage;
