import { CreateQueryParams } from '@nestjsx/crud-request';
import { api, getWithFilter } from 'services/apiService';

export default class PartnersService {
  static async createPartner(formData: FormData) {
    try {
      return await api.post(`/partners/school-create-partner`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error: any) {
      return error.response;
    }
  }

  static async updatePartner(formData: FormData, id: string) {
    try {
      return await api.patch(`/partners/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error: any) {
      return error.response;
    }
  }

  static async getPartners(query?: CreateQueryParams) {
    try {
      return await getWithFilter(`partners`, query);
    } catch (error: any) {
      return error.response;
    }
  }

  static async getPartner(id: string, query?: CreateQueryParams) {
    try {
      return await getWithFilter(`partners/${id}`, query);
    } catch (error: any) {
      return error.response;
    }
  }
}
