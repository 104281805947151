import { Grid, TextField, makeStyles, styled } from '@material-ui/core';

export const StyledTextField = styled(TextField)(() => ({
  width: '100%',
  borderRadius: '8px',
  '& .MuiInputBase-root': {
    backgroundColor: 'white',
    fontSize: '14px',
    height: '55px',
  },
}));

export const CenteredGrid = styled(Grid)(() => ({
  alignSelf: 'center',
}));

export const useStyles = makeStyles(() => ({
  resetButton: {
    width: '100%',
    padding: '10px 0px',
    minHeight: '55px',
  },
}));
