import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import mockSchoolIcon from 'assets/icons/mock-school-icon.svg';
import { ChangeEvent, useEffect, useState } from 'react';
import SchoolClassesService from 'services/schoolClassesService';
import ServicesService from 'services/servicesService';
import useGlobalStyles from 'styles';
import { SelectState } from './types';

export const useReportController = () => {
  // Mocks
  const start = new Date('02/07/2023 15:00:00');
  const end = new Date('02/07/2023 16:00:00');

  const start2 = new Date('02/07/2023 10:00:00');
  const end2 = new Date('02/07/2023 12:00:00');

  const calendarViewTypesOptions = [
    { id: 'day', label: 'Por dia' },
    { id: 'week', label: 'Por semana' },
  ];

  const mockEvents = [
    {
      id: 'event1',
      name: 'LABINC',
      title: 'LABINC',
      body: '2º Série - E.M.',
      color: '#000',
      backgroundColor: '#fff',
      dragBackgroundColor: '#fff',
      borderColor: '#000',
      start: new Date(),
      end: new Date(),
      isReadOnly: true,
      raw: mockSchoolIcon,
      attendees: null,
      location: null,
      state: null,
    },
    {
      id: 'event2',
      name: 'Clube de Futebol',
      title: 'Clube de Futebol',
      body: '1º Série - E.M.',
      color: '#000',
      backgroundColor: '#fff',
      dragBackgroundColor: '#fff',
      borderColor: '#000',
      start,
      end,
      isReadOnly: true,
      raw: mockSchoolIcon,
      attendees: null,
      location: null,
      state: null,
    },
    {
      id: 'event23',
      name: 'Clube de Futebol 2',
      title: 'Clube de Futebol 2',
      body: '3º Série - E.M.',
      color: '#000',
      backgroundColor: '#fff',
      dragBackgroundColor: '#fff',
      borderColor: '#000',
      start,
      end,
      isReadOnly: true,
      raw: mockSchoolIcon,
      attendees: null,
      location: null,
      state: null,
    },
    {
      id: 'event3',
      name: 'Clube de Escrita Criativa',
      title: 'Clube de Escrita Criativa',
      body: '4º Série - E.M.',
      color: '#000',
      backgroundColor: '#fff',
      dragBackgroundColor: '#fff',
      borderColor: '#000',
      start: start2,
      end: end2,
      isReadOnly: true,
      raw: mockSchoolIcon,
      attendees: null,
      location: null,
      state: null,
    },
  ];

  // Styles
  const classes = useGlobalStyles();

  // States
  const theme = useTheme();
  const [calendarViewType, setCalendarViewType] = useState<string>('week');
  const [schoolClasses, setSchoolClasses] = useState<string[]>([]);
  const [service, setService] = useState<string[]>([]);
  const [schoolClassesOptions, setSchoolClassesOptions] = useState<
    Array<SelectState>
  >([]);
  const [servicesOptions, setServicesOptions] = useState<Array<SelectState>>(
    [],
  );
  const tabletWidthBreakPoint = useMediaQuery(theme.breakpoints.down('sm'));

  // Callbacks
  const handleChangeCalendarView = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCalendarViewType(target.value);
  };

  const handleChangeSchoolClasses = ({ target }: ChangeEvent<any>) => {
    setSchoolClasses(target.value);
  };

  const handleChangeService = ({ target }: ChangeEvent<any>) => {
    setService(target.value);
  };

  const getResources = async () => {
    const typesPromise = ServicesService.getTypes();
    const schoolClassesPromise = SchoolClassesService.getSchoolClasses({
      limit: 50,
    });

    const [types, schoolClasses] = await Promise.all([
      typesPromise,
      schoolClassesPromise,
    ]);

    if (types?.data?.data) {
      const arrServiceOptions = types.data.data.map((item: any) => ({
        id: item.id,
        label: item.name,
      }));
      setServicesOptions(arrServiceOptions);
    }

    if (schoolClasses?.data?.data) {
      const arrSchoolClassesOptions = schoolClasses.data.data.map(
        (item: any) => ({
          id: item.id,
          label: item.name,
        }),
      );
      setSchoolClassesOptions(arrSchoolClassesOptions);
    }
  };

  useEffect(() => {
    if (tabletWidthBreakPoint) setCalendarViewType('day');
  }, [tabletWidthBreakPoint]);

  useEffect(() => {
    getResources();
  }, []);

  return {
    classes,
    calendarViewType,
    schoolClasses,
    service,
    mockEvents,
    handleChangeCalendarView,
    handleChangeSchoolClasses,
    handleChangeService,
    tabletWidthBreakPoint,
    getResources,
    calendarViewTypesOptions,
    schoolClassesOptions,
    servicesOptions,
  };
};
