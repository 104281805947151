import { useMemo } from 'react';
import { CustomDatePickerProps } from './types';
import { MAX_DATE, MIN_DATE } from './consts';

export const useCustomDatePickerController = ({
  minDate,
  maxDate,
}: Pick<CustomDatePickerProps, 'minDate' | 'maxDate'>) => {
  const minimumDate = useMemo(() => minDate ?? MIN_DATE, [minDate]);
  const maximumDate = useMemo(() => maxDate ?? MAX_DATE, [maxDate]);

  return {
    minimumDate,
    maximumDate,
  };
};
