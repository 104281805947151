import { makeStyles } from '@material-ui/core';
import { primaryCardTitle, primaryDark, white } from 'styles/theme';

export const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: primaryDark,
    padding: '30px',
    borderRadius: '34px',
  },
  paperTitle: {
    color: primaryCardTitle,
    fontSize: '18px',
    letterSpacing: '0.13em',
    fontWeight: 'bold',
  },
  paperDescriptionText: {
    color: white,
    fontSize: '16px',
    maxWidth: '900px',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
}));
