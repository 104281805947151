import { CreateQueryParams } from '@nestjsx/crud-request';
import { api, getWithFilter } from 'services/apiService';
import { FinancialGuardian } from 'types/financialGuardian';
import { Guardian } from 'types/guardian';
import { LegalGuardian } from 'types/legalGuardian';

export default class GuardianService {
  static async guardians() {
    try {
      return await api.get('guardians');
    } catch (error: any) {
      return error.response;
    }
  }

  static async guardian(id: number) {
    try {
      return await api.get(`guardians/${id}`);
    } catch (error: any) {
      return error.response;
    }
  }

  static async financialGuardian(id: number, query?: CreateQueryParams) {
    try {
      return await getWithFilter(`financial-guardians/${id}`, query);
    } catch (error: any) {
      return error.response;
    }
  }

  static async createLegalGuardian(guardian: Partial<LegalGuardian>) {
    try {
      return await api.post('legal-guardians', guardian);
    } catch (error: any) {
      return error.response;
    }
  }

  static async updateLegalGuardian(
    id: number,
    guardian: Partial<LegalGuardian>,
  ) {
    try {
      return await api.patch(`legal-guardians/${id}`, guardian);
    } catch (error: any) {
      return error.response;
    }
  }

  static async completeUserFinancialGuardian(
    id: number,
    data: Partial<FinancialGuardian>,
  ) {
    try {
      return await api.put(`financial-guardians/${id}`, data);
    } catch (error: any) {
      return error.response;
    }
  }
}
