import { INVALID_FIELD, INVALID_INTERVAL, REQUIRED_FIELD } from 'texts';
import { isDateIntervalValid, isStringDateValid } from 'utils';
import * as Yup from 'yup';

export const SCHEDULE_DAYS_ERROR_MESSAGE = 'Adicione um horário para cada dia.';
export const SCHEDULE_INTERVAL_ERROR_MESSAGE = 'Intervalo de horário inválido.';
export const ERROR_ON_SUBMIT_CREATION = 'Não foi possível criar uma turma.';
export const ERROR_ON_SUBMIT_UPDATE = 'Não foi possível editar a turma.';
export const ERROR_NOT_FILLED_IMAGES = 'Todas as imagens são obrigatórias.';
export const ERROR_ACCESS_CODE_NOT_FILLED =
  'Código de convite obrigatório para atividade por convite.';
export const ERROR_NOT_ACCESS_CODE =
  'Código de convite obrigatório apenas para atividade por convite.';
export const ERROR_SERVICE_WITH_VALUE =
  'Valor total obrigatório para serviços com anuidade.';
export const ERROR_SERVICE_WITHOUT_VALUE =
  'Valor total apenas para serviços com anuidade.';
export const ERROR_SERVICE_RECURRENCE =
  'Recorrência obrigatória para serviços com custo.';
export const ERROR_SERVICE_RECURRENCE_AMOUNT =
  'Valor total obrigatório para serviços com recorrência.';
export const ERROR_SERVICE_INSTALLMENTS =
  'Serviço com custo deve possuir quantidade de parcelas.';
export const ERROR_SERVICE_INSTALLMENTS_WITHOUT_VALUE =
  'Adicione um valor total ao serviço.';

const generalFieldsSchema = {
  name: Yup.string().required(REQUIRED_FIELD),
  // serviceType: Yup.mixed()
  //   .when('isArray', {
  //     is: Array.isArray,
  //     then: Yup.array().of(Yup.string()),
  //   })
  //   .required(REQUIRED_FIELD),
  // categories: Yup.mixed()
  //   .when('isArray', {
  //     is: Array.isArray,
  //     then: Yup.array().of(Yup.string()),
  //   })
  //   .required(REQUIRED_FIELD),
  schoolClasses: Yup.mixed()
    .when('isArray', {
      is: Array.isArray,
      then: Yup.array().of(Yup.string()),
    })
    .required(REQUIRED_FIELD),
  // recurrence: Yup.string().nullable(),
  spotsNumber: Yup.number().required(REQUIRED_FIELD).typeError(REQUIRED_FIELD),
  pedagogicSubjectId: Yup.string().nullable(),
  pedagogicSchoolYearId: Yup.string().required(REQUIRED_FIELD),
  pedagogicUserId: Yup.string().when('pedagogicSubjectId', {
    is: (value: string) => !!value,
    then: Yup.string().required(REQUIRED_FIELD),
    otherwise: Yup.string().nullable(),
  }),
  responsibleCoordinator: Yup.string().when('pedagogicSubjectId', {
    is: (value: string) => !value,
    then: Yup.string().required(REQUIRED_FIELD),
    otherwise: Yup.string().nullable(),
  }),
  // yearlyTotalHours: Yup.number()
  //   .required(REQUIRED_FIELD)
  //   .typeError(REQUIRED_FIELD),
};

const calendarFieldsSchema = {
  startDate: Yup.string()
    .required(REQUIRED_FIELD)
    .test('is date valid', INVALID_FIELD, value => {
      return isStringDateValid(value).isDateValid;
    }),
  endDate: Yup.string()
    .required(REQUIRED_FIELD)
    .test(
      'is date valid',
      INVALID_FIELD,
      value => isStringDateValid(value).isDateValid,
    )
    .test('is interval valid', INVALID_INTERVAL, (value, context) => {
      if (context.parent.startDate === value) {
        return true;
      }
      return isDateIntervalValid(context.parent.startDate, value);
    }),
  days: Yup.mixed()
    .when('isArray', {
      is: Array.isArray,
      then: Yup.array().of(Yup.string()),
    })
    .required(REQUIRED_FIELD),
  scheduleCanVaryToEveryDay: Yup.boolean().required(REQUIRED_FIELD),
};

const financialFieldsSchema = {
  amount: Yup.string(),
  maxInstallmentsNumber: Yup.string(),
  registrationTaxValue: Yup.string(),
};

export const schema = Yup.object({
  ...generalFieldsSchema,
  ...calendarFieldsSchema,
  // ...financialFieldsSchema,
});
