import { ServiceClass } from 'pages/Client/MarketplaceServicesDetail/types';
import { Payment } from './payment';
import { Service } from './service';
import { UploadedDocument } from './uploadedDocument';
import { User } from './user';

export interface ContractInfo {
  id: number;
  ip: string;
  signDate: string;
  documentId: number;
  document: UploadedDocument;
  paymentId: number;
  payment: Payment;
  serviceId?: number;
  service?: Service;
  contractorId?: number;
  contractor: User;
  serviceClassId?: number;
  serviceClass: ServiceClass;
  canceledAt?: Date;
}

// WARNING: if adding more statuses, fix handleContractStatusFilter() function
export enum CONTRACT_STATUS {
  /** Status do contrato se contractInfo.signDate existir. */
  SIGNED = 'Assinado',
  /** Status se contractInfo existir, porém contractInfo.signDate não. */
  EMITTED = 'Emitido',
}
