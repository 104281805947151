import { endOfDay, startOfDay } from 'date-fns';

const handleTransformDate = (date: Date, period: 'start' | 'end') => {
  const modifiedDate = period === 'start' ? startOfDay(date) : endOfDay(date);
  return modifiedDate.toISOString();
};

export const handleDateFilter = (start?: Date | null, end?: Date | null) => {
  if (start && end) {
    return {
      createdAt: {
        $between: [
          handleTransformDate(start, 'start'),
          handleTransformDate(end, 'end'),
        ],
      },
    };
  }

  if (start) {
    return {
      createdAt: {
        $gte: handleTransformDate(start, 'start'),
      },
    };
  }

  if (end) {
    return {
      createdAt: {
        $lte: handleTransformDate(end, 'end'),
      },
    };
  }

  return {};
};
