import { StatusCodes } from 'http-status-codes';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import CouponService from 'services/couponService';
import useGlobalStyles from 'styles';

const headers = [
  'VALOR PIX',
  'VALOR CRÉDITO',
  'VALOR BOLETO',
  'QUANTIDADE',
  'INÍCIO E TÉRMINO',
  'EVENTO',
  'USADO PARA',
];

export const useCouponDetailsTableController = (couponId: number) => {
  // Styles
  const classes = useGlobalStyles();
  const history = useHistory();

  // States
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  // Callbacks
  const goToEditCoupon = () => {
    history.push(`/adm-coupons/edit/${couponId}`);
  };

  const deleteCoupon = async () => {
    const response = await CouponService.deleteCoupon(couponId);
    if (response.status !== StatusCodes.OK) {
      setAlertMessage(response.data.message);
    } else {
      setDeleteModalOpen(false);
      history.push('/adm-coupons');
    }
  };

  const handleOpenModalDelete = () => {
    setDeleteModalOpen(true);
  };

  const handleCloseModalDelete = () => {
    setDeleteModalOpen(false);
  };

  const handleCloseAlert = () => {
    setAlertMessage('');
  };

  return {
    classes,
    deleteModalOpen,
    alertMessage,
    headers,
    goToEditCoupon,
    deleteCoupon,
    handleOpenModalDelete,
    handleCloseModalDelete,
    handleCloseAlert,
  };
};
