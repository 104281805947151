import { api, getAxiosResponseMessageErrorOrDefault } from './apiService';
import { PedagogicUser } from './types/pedagogicUser.type';

export default class PedagogicUserService {
  static async getProfessors(fromSubjectIds: number[]) {
    try {
      return await api.get<PedagogicUser[]>('pedagogic-users/professors', {
        params: {
          subjectIds: fromSubjectIds,
        },
      });
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }
}
