import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import AlertCard from 'components/AlertCard';
import { GuardianTooltip } from 'components/GuardianTooltip';
import Modal from 'components/Modal';
import SpinnerButton from 'components/SpinnerButton';
import { useAuth } from 'contexts/auth';
import { format } from 'date-fns';
import { StatusCodes } from 'http-status-codes';
import { handleOpenTerms } from 'pages/Client/SignUp/utils';
import React, { useState } from 'react';
import UserService from 'services/userService';
import useGlobalStyles from 'styles';
import { CHECK_GET_EMAILS, CHECK_IS_GUARDIAN, GENERAL_ERROR } from 'texts';
import { ModalProperties } from './types';

const AcceptTermsModal: React.FC<ModalProperties> = ({ open, close }) => {
  const classes = useGlobalStyles();
  const { user, signOut } = useAuth();

  const [modal, setModal] = useState(false);
  const [alert, setAlert] = useState(false);
  const [checkTerms, setCheckTerms] = useState(false);
  const [checkIsGuardian, setCheckIsGuardian] = useState(false);

  async function acceptTerms() {
    if (user) {
      const response = await UserService.updateUser(user.id, {
        acceptedTerms: {
          accepted: true,
          acceptedAt: format(new Date(), 'yyyy-MM-dd'),
        },
      });
      if (response.status === StatusCodes.OK) {
        setModal(true);
        close();
      } else {
        setAlert(true);
      }
    }
  }

  return (
    <>
      <Modal
        icon={
          <CheckCircleOutline
            color="secondary"
            className={classes.dialogIcon}
          />
        }
        open={modal}
        text="Termos aceitos!"
        subtext="Você pode utilizar o portal agora."
        btnText="Fechar"
        btnAction={() => setModal(false)}
      />
      <Dialog open={open}>
        <AlertCard
          message={GENERAL_ERROR}
          open={alert}
          close={() => setAlert(false)}
          severity="error"
        />
        <Box
          style={{ width: 585, padding: 35 }}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box>
            <Typography className={classes.dialogText}>
              Bem vindo(a)!
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.dialogSubText}>
              Como <b>responsável</b>{' '}
              <GuardianTooltip marginBottom={-4} marginLeft={0} />, é necessário
              que você aceite os termos abaixo
            </Typography>
          </Box>
          <Box
            style={{ marginTop: 20 }}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <FormControlLabel
              control={
                <Checkbox
                  value={checkTerms}
                  onChange={() => setCheckTerms(!checkTerms)}
                  color="secondary"
                />
              }
              label={
                <Typography>
                  Li e aceito o{' '}
                  <Button
                    className={classes.linkButton}
                    color="primary"
                    onClick={handleOpenTerms}
                  >
                    termo e condições de uso.
                  </Button>
                </Typography>
              }
            />
            <FormControlLabel
              style={{ marginTop: 15, marginBottom: 15 }}
              control={
                <Checkbox
                  value={checkIsGuardian}
                  onChange={() => setCheckIsGuardian(!checkIsGuardian)}
                  color="secondary"
                />
              }
              label={CHECK_IS_GUARDIAN}
            />
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              label={CHECK_GET_EMAILS}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            style={{ width: '100%' }}
            justifyContent="flex-end"
          >
            <Box m={2}>
              <Button
                onClick={() => signOut()}
                color="primary"
                className={classes.textButton}
              >
                Recusar
              </Button>
            </Box>
            <Box m={2}>
              <SpinnerButton
                action={() => acceptTerms()}
                disabled={!checkTerms || !checkIsGuardian}
                text="Aceitar"
                className={classes.newDependentButton}
              />
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default AcceptTermsModal;
