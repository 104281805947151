import { INVALID_FIELD, INVALID_INTERVAL, REQUIRED_FIELD } from 'texts';
import { Configurations, IDiscount } from 'types/configurations';
import { PAYMENT_METHODS } from 'types/enums';
import {
  isDateIntervalValid,
  isStringDateValid,
  unformatValueMask,
} from 'utils';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  type: Yup.string(),
  discountUntilTheDay: Yup.mixed().when('type', {
    is: 'discountUntilTheDay',
    then: Yup.mixed()
      .required(REQUIRED_FIELD)
      .transform(value => unformatValueMask(value)),
  }),
  untilTheDay: Yup.mixed().when('type', {
    is: 'untilTheDay',
    then: Yup.mixed()
      .required(REQUIRED_FIELD)
      .transform(value => unformatValueMask(value)),
  }),
  periodStart: Yup.string().when('type', {
    is: 'period',
    then: Yup.string()
      .required(REQUIRED_FIELD)
      .test(
        'is date valid',
        INVALID_FIELD,
        value => isStringDateValid(value).isDateValid,
      ),
  }),
  periodEnd: Yup.string().when('type', {
    is: 'period',
    then: Yup.string()
      .required(REQUIRED_FIELD)
      .test(
        'is date valid',
        INVALID_FIELD,
        value => isStringDateValid(value).isDateValid,
      )
      .test('is interval valid', INVALID_INTERVAL, (value, context) =>
        isDateIntervalValid(context.parent.periodStart, value),
      ),
  }),
  enrollmentYear: Yup.string().when('type', {
    is: 'enrollmentYear',
    then: Yup.string()
      .required(REQUIRED_FIELD)
      .transform(value => (value || '').trim()),
  }),
  storeKey: Yup.string().when('type', {
    is: 'key',
    then: Yup.string().required(REQUIRED_FIELD),
  }),
});

export const tablesList = (
  configuration: Configurations,
  discountRows?: IDiscount[],
) => [
  {
    type: 'discount',
    title: 'Descontos',
    headers: [
      'MÉTODO DE PAGAMENTO',
      'DESCONTO',
      'DESCONTO VÁLIDO ATÉ',
      'PADRÃO DE DESCONTO',
    ],
    rows: discountRows,
    values: [`${configuration.discount}%`],
  },
  {
    type: 'period',
    title: 'Período de matrícula',
    headers: ['INÍCIO', 'TÉRMINO', 'ANO DA MATRÍCULA'],
    values: [
      configuration.periodStart,
      configuration.periodEnd,
      configuration.enrollmentYear,
    ],
  },
  {
    type: 'key',
    title: 'Chave anual de compra de materiais',
    headers: ['CÓDIGO'],
    values: [configuration.storeKey],
  },
];
