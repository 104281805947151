import { DeleteOutline } from '@material-ui/icons';
import { CustomButton } from 'components/CustomButton';
import CustomDialog from 'components/CustomDialog';
import { CustomDialogRef } from 'components/CustomDialog/types';
import { MouseEventHandler, useRef } from 'react';
import { ActionsColumnProps } from './types';

export const DeleteAction = ({
  onDelete,
}: Pick<ActionsColumnProps, 'onDelete'>) => {
  const deleteDialogRef = useRef<CustomDialogRef>(null);

  const handleOpenDialog = () => {
    deleteDialogRef.current?.openDialog();
  };

  const handleCloseDialog = () => {
    deleteDialogRef.current?.closeDialog();
  };

  const handleDelete: MouseEventHandler<HTMLButtonElement> = e => {
    onDelete?.onClick?.(e);
    deleteDialogRef.current?.closeDialog();
  };

  return (
    <>
      <CustomButton {...onDelete} isIconButton onClick={handleOpenDialog}>
        <DeleteOutline color="error" />
      </CustomButton>
      <CustomDialog
        ref={deleteDialogRef}
        rawTitle="Deseja realmente excluir?"
        rawSubtitle="Essa ação não poderá ser desfeita."
        primaryButton={{
          text: 'Não, fechar',
          onClick: handleCloseDialog,
        }}
        secondaryButton={{
          text: 'Sim, confirmar',
          onClick: handleDelete,
        }}
        icon={{
          name: 'warningCheckCircle',
        }}
      />
    </>
  );
};
