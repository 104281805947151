import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  loadingContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '64px',
  },
  scaffold: {
    padding: 25,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    // Aplica padding extra no final da página caso tenha um footer fixo
    '&:has(.fixed-footer)': {
      paddingBottom: `${25 + 90}px`,
    },
  },
}));
