import {
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';
import AlertCard from 'components/AlertCard';
import Modal from 'components/Modal';
import { TablePaper } from 'components/TablePaper';
import { formatValue } from 'pages/Admin/Coupons/utils';
import { COUPON_IS_UNLIMITED, COUPON_LASTS_FOREVER } from 'texts';
import { newDateInBrazilianTime } from 'utils/dateUtils';
import { formatDatePtBr } from 'utils/formatDatePtBr';
import { ICouponDetailTableProps } from '../types';
import { useCouponDetailsTableController } from './controller';

export const CouponDetailTable = ({ coupon }: ICouponDetailTableProps) => {
  const {
    classes,
    deleteModalOpen,
    alertMessage,
    headers,
    goToEditCoupon,
    deleteCoupon,
    handleOpenModalDelete,
    handleCloseModalDelete,
    handleCloseAlert,
  } = useCouponDetailsTableController(coupon.id);

  return (
    <>
      <TablePaper>
        <TableHead>
          <TableRow>
            {headers.map(header => (
              <TableCell key={header}>
                <Typography className={classes.listScreenHeaderText1}>
                  {header}
                </Typography>
              </TableCell>
            ))}
            <TableCell align="right">
              <Typography className={classes.listScreenHeaderText1}>
                AÇÕES
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={coupon.id}>
            <TableCell>
              {formatValue(coupon.pix_value, coupon.pix_percent)}
            </TableCell>
            <TableCell>
              {formatValue(
                coupon.credit_card_value,
                coupon.credit_card_percent,
              )}
            </TableCell>
            <TableCell>
              {formatValue(coupon.bank_slip_value, coupon.bank_slip_percent)}
            </TableCell>
            <TableCell>{coupon.limit || COUPON_IS_UNLIMITED}</TableCell>
            <TableCell>
              {coupon.start_date && coupon.end_date
                ? `${formatDatePtBr(
                    newDateInBrazilianTime(coupon.start_date),
                  )} até ${formatDatePtBr(
                    newDateInBrazilianTime(coupon.end_date),
                  )}`
                : COUPON_LASTS_FOREVER}
            </TableCell>
            <TableCell>{coupon.event || '---'}</TableCell>
            <TableCell>{coupon.source}</TableCell>
            <TableCell align="right">
              <IconButton onClick={goToEditCoupon} color="default">
                <EditOutlined color="primary" />
              </IconButton>
              <IconButton onClick={handleOpenModalDelete}>
                <DeleteOutline color="error" />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </TablePaper>
      <Modal
        icon={<DeleteOutline color="primary" className={classes.dialogIcon} />}
        open={deleteModalOpen}
        text="Deletar cupom?"
        btnText="Deletar"
        backBtnText="Voltar"
        btnAction={deleteCoupon}
        backBtnAction={handleCloseModalDelete}
      />
      <AlertCard
        message={alertMessage}
        open={!!alertMessage}
        close={handleCloseAlert}
        severity="error"
      />
    </>
  );
};
