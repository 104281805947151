import { makeStyles } from '@material-ui/core';
import { mediumGray } from 'styles/theme';

export const usePaymentMethodPixStyles = makeStyles({
  dropdownIconSelect: {
    paddingRight: 10,
    cursor: 'pointer',
  },
  paymentMethodGridTitle: {
    marginTop: 30,
    marginBottom: 30,
  },
  formMargin: {
    marginBottom: 20,
  },
  registrationTaxDisclaimer: {
    color: mediumGray,
    marginTop: 5,
  },
});

export const usePaymentMethodCardStyles = makeStyles(theme => ({
  titleMarginTop: {
    marginTop: 15,
  },
  titleMarginBottom: {
    marginBottom: 15,
  },
  addNewCardText: {
    textTransform: 'initial',
    fontWeight: 600,
  },
  installmentsDropdown: {
    paddingRight: 10,
    cursor: 'pointer',
  },
  registrationTaxDisclaimer: {
    color: mediumGray,
    marginTop: 5,
  },
  buttonAddNewCard: {
    [theme.breakpoints.down('sm')]: {
      padding: '0px 5px 0px 0px ',
      marginTop: '5px',
    },
  },
  helpText: {
    marginLeft: '2px',
  },
  formContainer: {
    marginBottom: '20px',
    gap: '20px',
  },
}));
