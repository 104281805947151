import BaseAutocomplete from 'components/BaseAutocomplete';
import { SearchAutocompleteProps } from './types';
import { useSearchAutocompleteController } from './useSearchAutocompleteController';

const SearchAutocomplete = ({
  filterEndpointPath,
  identifierProperty,
  minTextLength,
  onSearchText,
  createQueryParamsCallback,
  getAutocompleteOptionLabel,
  ...props
}: SearchAutocompleteProps) => {
  const {
    loading,
    autocompleteOptions,
    handleOnAutocompleteAction,
    handleOnEndTyping,
  } = useSearchAutocompleteController({
    filterEndpointPath,
    identifierProperty,
    minTextLength,
    onSearchText,
    createQueryParamsCallback,
    getAutocompleteOptionLabel,
  });

  return (
    <BaseAutocomplete
      {...props}
      loading={loading}
      onAction={handleOnAutocompleteAction}
      onEndTyping={handleOnEndTyping}
      options={autocompleteOptions}
      triggerActionOnEnter
      endIcon={onSearchText ? 'searchBlue' : null}
      freeSolo
    />
  );
};

export default SearchAutocomplete;
