import '@fontsource/open-sans';
import { makeStyles } from '@material-ui/core';
import {
  backgroundGray,
  borderGray,
  darkGray,
  divideBorderColor,
  infoMain,
  lightBoxShadow,
  mediumGray,
  primaryDark,
  primaryMain,
  primaryMedium,
  secondaryMain,
  warning,
} from './theme';

const useGlobalStyles = makeStyles(
  theme => ({
    hidden: {
      display: 'none',
    },
    textButton: {
      textTransform: 'none',
      fontWeight: 'bold',
      padding: 0,
      fontSize: 15,
      fontFamily: 'Open Sans',
    },
    linkButton: {
      textTransform: 'none',
      fontWeight: 600,
      padding: 0,
      fontSize: 15,
      fontFamily: 'Open Sans',
      marginBottom: 4,
      width: 'max-content',
    },
    verticalMargin: {
      margin: '25px 0px 25px 0px',
    },
    maxWidthInput: {
      background: 'white',
      width: '100%',
    },
    publicScreenInput: {
      width: '100%',
      maxWidth: 600,
      borderColor: borderGray,
    },
    publicScreenDivider: {
      width: '100%',
      maxWidth: 600,
    },
    publicScreenMargin1: {
      marginTop: 15,
    },
    publicScreenMargin2: {
      marginTop: 15,
      marginBottom: 20,
    },
    publicScreenMargin3: {
      marginTop: 45,
      marginBottom: 25,
    },
    formGrid: {
      display: 'flex',
      flexDirection: 'column',
    },
    dialog: {
      maxWidth: 670,
      padding: '25px 100px',
      [theme.breakpoints.down('sm')]: {
        padding: '25px 50px',
      },
    },
    dialogActions: {
      width: '100%',
      marginTop: 20,
    },
    dialogButton: {
      fontSize: 15,
      textTransform: 'none',
      padding: '15px 20px',
      marginTop: 50,
    },
    dialogIcon: {
      fontSize: 80,
      marginBottom: 20,
    },
    dialogText: {
      padding: '0px 10px',
      fontSize: 25,
      marginBottom: 15,
      textAlign: 'center',
    },
    dialogSubText: {
      fontSize: 16,
      textAlign: 'center',
      color: darkGray,
    },
    dialogSubText2: {
      marginTop: 15,
      fontSize: 13,
      textAlign: 'center',
    },
    loginButton: {
      marginTop: 30,
      textTransform: 'none',
      height: 60,
      width: 130,
      [theme.breakpoints.down(1024)]: {
        width: '100%',
        maxWidth: 600,
      },
    },
    signUpButton: {
      marginTop: 50,
      textTransform: 'none',
      height: 60,
      width: 130,
      [theme.breakpoints.down(1024)]: {
        width: '100%',
      },
    },
    exportButton: {
      width: 100,
      borderRadius: 8,
      boxShadow: 'none',
      marginBottom: 27,
      textTransform: 'none',
      padding: '15px 20px',
      marginTop: 50,
    },
    newDependentButton: {
      textTransform: 'none',
      height: 40,
      width: 100,
    },
    veteranDependentButton: {
      textTransform: 'none',
      height: 40,
    },
    newGuardianButton: {
      textTransform: 'none',
      height: 50,
      width: 160,
    },
    newContractButton: {
      textTransform: 'none',
      height: 50,
      minWidth: 100,
      borderRadius: 8,
    },
    error: {
      color: 'red',
      fontWeight: 'bold',
      fontSize: 15,
    },
    page: {
      height: '100vh',
      alignItems: 'center',
      width: '100vw',
    },
    content: {
      marginLeft: -100,
    },
    contentAdmin: {
      marginLeft: -450,
    },
    paddedContent: {
      paddingTop: '5%',
      paddingBottom: '5%',
      marginLeft: -100,
    },
    imageIcon: {
      display: 'flex',
      height: 'inherit',
      width: 'inherit',
    },
    iconRoot: {
      height: 85,
      width: 85,
      textAlign: 'center',
    },
    divider1: {
      width: '100%',
      marginTop: 15,
      marginBottom: 35,
      borderBottom: `0.8px solid ${divideBorderColor}`,
    },
    boxDivider: {
      borderBottom: `1px dashed ${divideBorderColor}`,
      width: '100%',
    },
    dropDownGrid: {
      position: 'absolute',
      top: 35,
      right: 35,
    },
    notificationDivider: {
      width: '100%',
      margin: '7px 0px',
      borderBottom: `0.8px solid ${divideBorderColor}`,
    },
    breadcrumbsText: {
      fontSize: 16,
      color: primaryMain,
    },
    registrationProgress: {
      width: 337,
      height: 12,
      borderRadius: 17,
      backgroundColor: '#E6E6E6',
    },
    screenHeaderText: {
      fontSize: 24,
      fontWeight: 400,
      [theme.breakpoints.down('xs')]: {
        fontSize: '18px',
      },
    },
    screenInputField: {
      background: 'white',
      width: '22vw',
    },
    screenSubHeaderText: {
      fontSize: 18,
      marginTop: 7,
      color: mediumGray,
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
      },
    },
    screenText3: {
      fontSize: 18,
      marginBottom: 22,
    },
    screenInputGrid: {
      marginBottom: 43,
    },
    screenButtonGrid: {
      marginTop: 70,
      width: '93%',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
    screenButtonGridRegisters: {
      width: '85%',
      marginLeft: '3%',
      [theme.breakpoints.down('md')]: {
        width: '75%',
      },
    },
    screenPaper: {
      borderRadius: 34,
      width: '100%',
      padding: '29px 32px 16px 31px',
      boxShadow: '0px 6px 32px rgba(9, 13, 27, 0.07)',
    },
    screenAccordion: {
      boxShadow: 'none',
      '&.MuiAccordion-root:before, &.MuiAccordion-root.Mui-disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
    },
    mainScreenHeader: {
      fontSize: 24,
      marginBottom: 28,
      fontWeight: 300,
    },
    mainScreenText3: {
      fontSize: 18,
      maxWidth: 380,
    },
    mainScreenPaper: {
      borderRadius: 34,
      height: 320,
      position: 'relative',
      padding: '25px 0px 35px 35px',
      boxShadow: '0px 6px 32px rgba(9, 13, 27, 0.07)',
      overflow: 'hidden',
      cursor: 'pointer',
    },
    listScreenSectionHeaderText: {
      fontSize: 18,
      marginTop: 51,
      marginBottom: 27,
    },
    listScreenPaper: {
      padding: '40px 70px',
      marginBottom: 20,
      borderRadius: 34,
      width: '100%',
      boxShadow: '0px 6px 32px rgba(9, 13, 27, 0.07)',
    },
    listScreenSubPaper: {
      padding: '22px 40px',
      marginBottom: 20,
      borderRadius: 22,
      width: '100%',
      boxShadow: '0px 6px 32px rgba(9, 13, 27, 0.07)',
    },
    listScreenPaperGrid: {
      cursor: 'pointer',
      width: '100%',
      height: '100%',
    },
    listScreenImg: {
      width: 120,
      height: 120,
      borderRadius: 28,
      marginRight: 40,
    },
    listScreenText: {
      fontSize: 16,
      width: 400,
    },
    // FIXME: replace screen to use scaffold
    listScreenGrid: {
      padding: 25,
      paddingTop: 0,
    },
    scaffold: {
      padding: 25,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    headerContainer: {
      marginBottom: '27px',
    },
    listScreenButton: {
      borderRadius: 8,
      boxShadow: 'none',
      marginBottom: 27,
      textTransform: 'none',
      padding: '15px 20px',
      marginTop: 50,
      fontWeight: 700,
    },
    listScreenGrayButton: {
      background: infoMain,
      color: primaryMain,
      borderRadius: 8,
      boxShadow: 'none',
      marginBottom: 27,
      textTransform: 'none',
      padding: '15px 20px',
      marginTop: 50,
    },
    listScreenNewGuardian: {
      position: 'absolute',
      bottom: 10,
      right: 10,
    },
    listScreenHeaderText1: {
      fontSize: 14,
      fontWeight: 'bold',
      letterSpacing: '0.13rem',
      textTransform: 'uppercase',
    },
    listScreenDivider: {
      margin: '15px 0px',
      width: '100%',
    },
    listScreenGuardian: {
      padding: '12px 0px',
    },
    contractScreenTabs: {
      minWidth: 350,
      borderRadius: 25,
      padding: 40,
      background: '#EBEBEB',
      color: primaryDark,
      '& button:not(.MuiTab-textColorInherit.Mui-selected)': {
        opacity: 0.3,
      },
    },
    contractScreenPanel: {
      padding: 25,
      width: 530,
    },
    contractScreenSmallText: {
      textAlign: 'justify',
      textJustify: 'inter-word',
      fontSize: 13,
      marginBottom: 15,
    },
    contractScreenSmallText2: {
      fontSize: 12,
      marginBottom: 14,
    },
    contractScreenTinyText: {
      fontSize: 8,
      marginBottom: 40,
    },
    paymentSscreenSelectBtn: {
      textTransform: 'none',
      padding: '20px 45px',
      width: 270,
      borderWidth: 2,
      borderRadius: 8,
    },
    paymentScreenTitle1: {
      color: '#1A90FF',
      fontSize: 18,
      letterSpacing: '0.13em',
      fontWeight: 'bold',
    },
    paymentScreenTitle2: {
      color: 'white',
      fontSize: 24,
    },
    paymentScreenDivider1: {
      width: '100%',
      margin: '35px 0px',
      borderBottom: `0.8px solid ${divideBorderColor}`,
    },
    paymentScreenDivider2: {
      width: '100%',
      margin: '50px 0px 50px 0px',
      borderBottom: `0.8px solid ${divideBorderColor}`,
    },
    paymentScreenGuardian: {
      margin: '10px 0px',
      fontSize: 18,
    },
    paymentScreenLightDivider: {
      border: '0.2px solid white',
    },
    paymentScreenHDivider: {
      border: '0.2px solid white',
      width: '100%',
      margin: '20px 0px',
    },
    paymentScreenSelect: {
      width: 450,
    },
    paymentScreenPaper: {
      borderRadius: 34,
      width: '100%',
      padding: 30,
      boxShadow: lightBoxShadow,
    },
    confirmScreenPaper: {
      background: primaryDark,
      marginTop: 90,
      padding: '56px 71px 55px 69px',
      marginBottom: 20,
      borderRadius: 34,
      width: '100%',
      boxShadow: '0px 6px 32px rgba(9, 13, 27, 0.07)',
    },
    confirmScreenImg: {
      width: 170,
      height: 170,
      borderRadius: 28,
      marginRight: 40,
      position: 'absolute',
      top: -150,
      left: -80,
    },
    confirmScreenText1: {
      fontSize: 20,
    },
    confirmScreenText2: {
      fontSize: 39,
    },
    confirmScreenText3: {
      fontSize: 15,
      fontWeight: 100,
      opacity: 0.6,
    },
    financialBillBig: {
      width: '18%',
    },
    financialBillMed: {
      width: '11%',
    },
    warningText: {
      fontSize: 16,
      color: primaryMain,
      backgroundColor: warning,
      padding: 8,
      borderRadius: 8,
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    filtersGrid: {
      marginBottom: 15,
    },
    filterField: {
      background: 'white',
      borderRadius: 8,
      width: 240,
      height: 55,
      marginBottom: 15,
    },
    bigFilterField: {
      background: 'white',
      borderRadius: 8,
      width: 284,
      height: 55,
    },
    smallFilterField: {
      background: 'white',
      borderRadius: 8,
      width: 150,
      height: 55,
    },
    regStatusUpdate: {
      height: 266,
      width: 420,
      background: backgroundGray,
      border: '1px solid #979797',
      borderRadius: 10,
      marginTop: 15,
      marginBottom: 35,
      padding: 10,
    },
    regCountIcon: {
      fontSize: 35,
      marginTop: 10,
      color: primaryMedium,
    },
    regUpdateField: {
      background: 'white',
      width: 350,
    },
    newUserInput: {
      width: '100%',
      marginBottom: 25,
    },
    overlayConfirmPaper: {
      background: primaryDark,
      marginTop: 90,
      padding: '48px 43px',
      marginBottom: 20,
      borderRadius: 34,
      width: '100%',
      boxShadow: '0px 6px 32px rgba(9, 13, 27, 0.07)',
    },
    dependentPaperBox: {
      position: 'relative',
      width: 170,
      marginRight: 54,
    },
    dependentPaperBoxAlt: {
      position: 'static',
    },
    dependentPaper: {
      background: primaryDark,
      marginTop: 90,
      padding: '56px 71px 33px 69px',
      marginBottom: 20,
      borderRadius: 34,
      boxShadow: '0px 6px 32px rgba(9, 13, 27, 0.07)',
    },
    dependentPaperInfo: {
      position: 'absolute',
      top: -120,
      color: 'white',
      cursor: 'inherit',
      padding: 0,
    },
    dependentPaperInfoAlt: {
      color: 'black',
      cursor: 'inherit',
    },
    dependentPaperName: {
      fontSize: 26,
      marginBottom: 10,
    },
    dependentPaperNameAlt: {
      fontSize: 24,
      marginTop: 90,
    },
    dependentPaperImg: {
      width: 107,
      height: 107,
      borderRadius: 28,
      marginRight: 40,
    },
    imgUploadIcon: {
      borderRadius: '50%',
      minWidth: 40,
      minHeight: 40,
      marginTop: -10,
    },
    imgUploadProgress: {
      position: 'absolute',
      bottom: -70,
      left: 65,
    },
    couponModalInput: {
      width: '35%',
      marginBottom: 15,
    },
    couponModalSelect: {
      width: '45%',
    },
    discountText: {
      fontSize: 16,
      color: primaryMain,
      backgroundColor: secondaryMain,
      padding: 8,
      borderRadius: 8,
      fontWeight: 'bold',
      width: 82,
      position: 'absolute',
      right: 50,
      bottom: 20,
    },
    configurationsPaper: {
      padding: 20,
      marginBottom: 20,
      borderRadius: 22,
      boxShadow: '0px 6px 32px rgba(9, 13, 27, 0.07)',
    },
    tooltip: {
      maxWidth: 500,
    },
    allAcceptedPaper: {
      padding: '40px 70px',
      marginBottom: 20,
      borderRadius: 34,
      width: '100%',
      boxShadow: '0px 6px 32px rgba(9, 13, 27, 0.07)',
      position: 'relative',
      height: 550,
      overflow: 'hidden',
    },
    allAcceptedImage: {
      position: 'absolute',
      bottom: 0,
    },
    adornedItem: {
      position: 'relative',
    },
    adornment: {
      position: 'absolute',
      bottom: 33,
      right: 30,
    },
    tablePagination: {
      marginTop: 22,
    },
  }),
  { index: 1 },
);

export default useGlobalStyles;
