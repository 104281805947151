/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';

import { CheckCircleOutlineSharp } from '@material-ui/icons';
import AlertCard from 'components/AlertCard';
import Modal from 'components/Modal';
import PageHeader from 'components/PageHeader';

import { DATE_MASK } from 'texts';
import { Controller } from 'react-hook-form';
import CurrencyField from 'components/CurrencyField';
import { COUPON_EVENT, COUPON_SOURCE } from 'types/enums';
import SpinnerButton from 'components/SpinnerButton';
import ReactInputMask from 'react-input-mask';
import { ECouponValueType } from './types';
import { useFormCouponsController } from './controller';

export const FormCoupons = () => {
  const {
    classes,
    styles,
    submitError,
    successModalOpen,
    handleSubmit,
    errors,
    register,
    control,
    onSubmit,
    event,
    couponHaveLimit,
    setHasEvent,
    toggleCheckbox,
    toggleCouponValuePix,
    toggleCouponValueCreditCard,
    toggleCouponValueBankSlip,
    toggleCouponLimit,
    handleCloseCreatedModal,
    onChangeCouponEvent,
    toggleHasDuration,
    hasEvent,
    hasDuration,
    couponValueTypePix,
    couponValueTypeCreditCard,
    couponValueTypeBankSlip,
    setSubmitError,
    goToCoupons,
    couponId,
  } = useFormCouponsController();

  return (
    <Grid
      className={classes.listScreenGrid}
      container
      direction="column"
      alignItems="flex-start"
    >
      <Modal
        icon={
          <CheckCircleOutlineSharp
            color="secondary"
            className={classes.dialogIcon}
          />
        }
        open={successModalOpen.open}
        text={successModalOpen.text}
        subtext="Seu cupom já está disponível para ser usado pelos responsáveis na plataforma"
        btnText="Voltar"
        btnAction={handleCloseCreatedModal}
      />

      <Grid container item direction="column">
        <PageHeader
          titleBoldPrefix={couponId ? 'Editar' : 'Novo'}
          title="cupom"
          subtitle={
            !couponId
              ? 'Adicione um novo cupom'
              : 'Edite as informações do cupom'
          }
        />
        <Paper className={styles.paper}>
          <Typography className={styles.paperTitle}>Dados</Typography>
          <Divider className={styles.divider} />
          <Grid
            container
            item
            lg={12}
            className={styles.fieldsContainer}
            spacing={3}
          >
            <Grid item md={4}>
              <Controller
                name="name"
                control={control}
                as={({ value, onChange }) => (
                  <TextField
                    variant="outlined"
                    id="name"
                    name="name"
                    placeholder="Nome"
                    className={styles.input}
                    value={value}
                    onChange={onChange}
                    error={Boolean(errors.name)}
                    helperText={errors.name ? errors.name.message : null}
                    inputRef={register}
                  />
                )}
              />
            </Grid>
            <Grid item lg={4} md={6}>
              <Controller
                name="source"
                control={control}
                as={({ value, onChange }) => (
                  <TextField
                    label="Usado para"
                    variant="outlined"
                    id="source"
                    name="source"
                    value={value}
                    select
                    className={styles.input}
                    SelectProps={{ MenuProps: { disableScrollLock: true } }}
                    onChange={onChange}
                    error={Boolean(errors.source)}
                    helperText={errors.source ? errors.source?.message : null}
                  >
                    {Object.values(COUPON_SOURCE).map(item => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid container item lg={12} spacing={3}>
              <Grid item lg={4} md={6}>
                <RadioGroup
                  value={couponValueTypePix}
                  onChange={toggleCouponValuePix}
                  className={styles.input}
                >
                  <Box display="flex">
                    <FormControlLabel
                      value={ECouponValueType.Currency}
                      control={<Radio color="secondary" />}
                      label="Valor fixo"
                      className={styles.actions}
                    />
                    <FormControlLabel
                      value={ECouponValueType.Percent}
                      className={styles.actions}
                      control={<Radio color="secondary" />}
                      label="Porcentagem"
                    />
                  </Box>
                  <Box>
                    {couponValueTypePix === 'currency' ? (
                      <Box>
                        <Controller
                          name="pix_value"
                          control={control}
                          as={({ value, onChange }) => (
                            <CurrencyField
                              variant="outlined"
                              name="pix_value"
                              label="Desconto no Pix"
                              value={value}
                              onChange={onChange}
                              error={Boolean(errors.pix_value)}
                              helperText={
                                errors.pix_value
                                  ? errors.pix_value?.message
                                  : null
                              }
                              inputRef={register}
                              className={styles.input}
                            />
                          )}
                        />
                      </Box>
                    ) : (
                      <Box>
                        <Controller
                          name="pix_percent"
                          control={control}
                          as={({ value, onChange }) => (
                            <CurrencyField
                              variant="outlined"
                              isPercent
                              label="Desconto no Pix"
                              name="pix_percent"
                              value={value}
                              onChange={onChange}
                              error={Boolean(errors.pix_percent)}
                              helperText={
                                errors.pix_percent
                                  ? errors.pix_percent?.message
                                  : null
                              }
                              inputRef={register}
                              className={styles.input}
                            />
                          )}
                        />
                      </Box>
                    )}
                  </Box>
                </RadioGroup>
              </Grid>
              <Grid item lg={4} md={6}>
                <RadioGroup
                  value={couponValueTypeCreditCard}
                  onChange={toggleCouponValueCreditCard}
                  className={styles.input}
                >
                  <Box display="flex">
                    <FormControlLabel
                      value={ECouponValueType.Currency}
                      control={<Radio color="secondary" />}
                      label="Valor fixo"
                      className={styles.actions}
                    />
                    <FormControlLabel
                      value={ECouponValueType.Percent}
                      className={styles.actions}
                      control={<Radio color="secondary" />}
                      label="Porcentagem"
                    />
                  </Box>
                  <Box>
                    {couponValueTypeCreditCard === 'currency' ? (
                      <Box>
                        <Controller
                          name="credit_card_value"
                          control={control}
                          as={({ value, onChange }) => (
                            <CurrencyField
                              variant="outlined"
                              name="credit_card_value"
                              label="Desconto no Crédito"
                              value={value}
                              onChange={onChange}
                              error={Boolean(errors.credit_card_value)}
                              helperText={
                                errors.credit_card_value
                                  ? errors.credit_card_value?.message
                                  : null
                              }
                              inputRef={register}
                              className={styles.input}
                            />
                          )}
                        />
                      </Box>
                    ) : (
                      <Box>
                        <Controller
                          name="credit_card_percent"
                          control={control}
                          as={({ value, onChange }) => (
                            <CurrencyField
                              variant="outlined"
                              isPercent
                              label="Desconto no Crédito"
                              name="credit_card_percent"
                              value={value}
                              onChange={onChange}
                              error={Boolean(errors.credit_card_percent)}
                              helperText={
                                errors.credit_card_percent
                                  ? errors.credit_card_percent?.message
                                  : null
                              }
                              inputRef={register}
                              className={styles.input}
                            />
                          )}
                        />
                      </Box>
                    )}
                  </Box>
                </RadioGroup>
              </Grid>
              <Grid item lg={4} md={6}>
                <RadioGroup
                  value={couponValueTypeBankSlip}
                  onChange={toggleCouponValueBankSlip}
                  className={styles.input}
                >
                  <Box display="flex">
                    <FormControlLabel
                      value={ECouponValueType.Currency}
                      control={<Radio color="secondary" />}
                      label="Valor fixo"
                      className={styles.actions}
                    />
                    <FormControlLabel
                      value={ECouponValueType.Percent}
                      className={styles.actions}
                      control={<Radio color="secondary" />}
                      label="Porcentagem"
                    />
                  </Box>
                  <Box>
                    {couponValueTypeBankSlip === 'currency' ? (
                      <Box>
                        <Controller
                          name="bank_slip_value"
                          control={control}
                          as={({ value, onChange }) => (
                            <CurrencyField
                              variant="outlined"
                              name="bank_slip_value"
                              label="Desconto no Boleto"
                              value={value}
                              onChange={onChange}
                              error={Boolean(errors.bank_slip_value)}
                              helperText={
                                errors.bank_slip_value
                                  ? errors.bank_slip_value?.message
                                  : null
                              }
                              inputRef={register}
                              className={styles.input}
                            />
                          )}
                        />
                      </Box>
                    ) : (
                      <Box>
                        <Controller
                          name="bank_slip_percent"
                          control={control}
                          as={({ value, onChange }) => (
                            <CurrencyField
                              variant="outlined"
                              isPercent
                              label="Desconto no Boleto"
                              name="bank_slip_percent"
                              value={value}
                              onChange={onChange}
                              error={Boolean(errors.bank_slip_percent)}
                              helperText={
                                errors.bank_slip_percent
                                  ? errors.bank_slip_percent?.message
                                  : null
                              }
                              inputRef={register}
                              className={styles.input}
                            />
                          )}
                        />
                      </Box>
                    )}
                  </Box>
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid container item lg={12} spacing={3}>
              <Grid item md={4}>
                <RadioGroup>
                  <Box display="flex">
                    <Controller
                      name="hasEvent"
                      control={control}
                      as={() => (
                        <FormControlLabel
                          className={styles.actions}
                          control={
                            <Checkbox
                              checked={hasEvent}
                              value={hasEvent}
                              onChange={toggleCheckbox(setHasEvent)}
                            />
                          }
                          label="Tem evento"
                        />
                      )}
                    />
                  </Box>
                  <Controller
                    name="event"
                    control={control}
                    as={() => (
                      <TextField
                        label="Evento"
                        variant="outlined"
                        id="event"
                        name="event"
                        value={event}
                        select
                        className={`${styles.input} ${styles.inputEvent}`}
                        SelectProps={{ MenuProps: { disableScrollLock: true } }}
                        onChange={onChangeCouponEvent}
                        disabled={!hasEvent}
                        error={Boolean(errors.event)}
                        helperText={errors.event ? errors.event?.message : null}
                      >
                        {Object.values(COUPON_EVENT).map(item => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </RadioGroup>
              </Grid>
              <Grid item lg={4} md={6}>
                <RadioGroup className={styles.input}>
                  <Box display="flex">
                    <Controller
                      name="hasDuration"
                      control={control}
                      as={() => (
                        <FormControlLabel
                          className={styles.actions}
                          control={
                            <Checkbox
                              checked={hasDuration}
                              value={hasDuration}
                              onChange={toggleHasDuration}
                            />
                          }
                          label="Com data de início e término"
                        />
                      )}
                    />
                  </Box>
                </RadioGroup>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <Controller
                      name="start_date"
                      control={control}
                      as={({ value, onChange }) => {
                        if (!hasDuration) {
                          return (
                            <TextField
                              disabled
                              variant="outlined"
                              label="Data de início"
                              name="start_date"
                              className={styles.input}
                            />
                          );
                        }
                        return (
                          <ReactInputMask
                            mask={DATE_MASK}
                            name="start_date"
                            placeholder="Data de início"
                            value={value}
                            onChange={onChange}
                            disabled={!hasDuration}
                            className={styles.input}
                            inputRef={register}
                          >
                            {(inputProps: any) => (
                              <TextField
                                variant="outlined"
                                error={Boolean(errors.start_date)}
                                helperText={
                                  errors.start_date
                                    ? errors.start_date.message
                                    : null
                                }
                                {...inputProps}
                              />
                            )}
                          </ReactInputMask>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Controller
                      name="end_date"
                      control={control}
                      as={({ value, onChange }) => {
                        if (!hasDuration) {
                          return (
                            <TextField
                              disabled
                              variant="outlined"
                              label="Data de término"
                              name="end_date"
                              className={styles.input}
                            />
                          );
                        }
                        return (
                          <ReactInputMask
                            mask={DATE_MASK}
                            name="data"
                            placeholder="Data de término"
                            value={value}
                            onChange={onChange}
                            className={styles.input}
                            inputRef={register}
                            disabled={!hasDuration}
                          >
                            {(inputProps: any) => (
                              <TextField
                                variant="outlined"
                                {...inputProps}
                                error={Boolean(errors.end_date)}
                                helperText={
                                  errors.end_date
                                    ? errors.end_date.message
                                    : null
                                }
                              />
                            )}
                          </ReactInputMask>
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={4} md={6}>
                <RadioGroup>
                  <Grid item md={6}>
                    <Controller
                      name="isLimited"
                      control={control}
                      as={() => (
                        <FormControlLabel
                          className={styles.actions}
                          control={
                            <Checkbox
                              name="isLimited"
                              checked={couponHaveLimit}
                              value={couponHaveLimit}
                              onChange={toggleCouponLimit}
                            />
                          }
                          label="Limite de uso"
                        />
                      )}
                    />
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <Controller
                        name="limit"
                        control={control}
                        as={({ value, onChange }) => (
                          <TextField
                            disabled={!couponHaveLimit}
                            variant="outlined"
                            label="Limite"
                            id="limit"
                            name="limit"
                            value={value}
                            onChange={onChange}
                            error={Boolean(errors.limit)}
                            helperText={
                              errors.limit ? errors?.limit?.message : null
                            }
                            inputRef={register}
                            className={styles.input}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Box className={styles.footerContainer}>
        <Button
          onClick={goToCoupons}
          variant="text"
          color="primary"
          className={styles.goBackButtom}
        >
          Voltar
        </Button>

        <SpinnerButton
          action={handleSubmit(onSubmit)}
          text={`${couponId ? 'Editar' : 'Criar'} cupom`}
          className={styles.submitButtom}
        />
      </Box>
      <AlertCard
        message={submitError}
        open={!!submitError}
        close={() => setSubmitError('')}
        severity="error"
      />
    </Grid>
  );
};
