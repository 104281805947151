import {
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { CustomButton } from 'components/CustomButton';
import { parseISO, startOfDay } from 'date-fns';
import { translatePaymentMethod } from 'pages/Client/PaymentMethod/steps/ConfirmPayment/utils';
import React, { useCallback, useMemo } from 'react';
import { BILL_STATUS, PAYMENT_STATUS } from 'types/enums';
import { buildRegistrationServiceName } from 'utils/buildRegistrationServiceName';
import { getPaymentStatusFromFirstBill } from '../../utils/getPaymentStatusFromFistBill';
import LoadingPaymentFeedback from '../LoadingPaymentFeedback';
import { usePaymentCardStyles } from '../paymentCardStyles';
import { PaymentCardComponentProps } from '../types';

const PaymentBankslip: React.FC<PaymentCardComponentProps> = ({
  payment,
  bill,
}) => {
  const styles = usePaymentCardStyles();
  const {
    title,
    description,
    icon: Icon,
  } = useMemo(() => {
    return getPaymentStatusFromFirstBill({
      bills: [bill],
      paymentMethodCode: payment.paymentMethodCode,
      status: payment.status,
      paymentFailReason: payment.paymentFailReason,
      paymentStartDate: payment.paymentStartDate,
    });
  }, [
    bill,
    payment.paymentFailReason,
    payment.paymentMethodCode,
    payment.paymentStartDate,
    payment.status,
  ]);

  const bankSlipInfo = useMemo(() => {
    const firstBill = bill;
    const printUrl = firstBill?.printUrl;
    const typableBarCode = firstBill?.typableBarCode;
    let isExpired = false;

    if (firstBill?.dueDate) {
      const dueDate = startOfDay(parseISO(firstBill.dueDate));
      const today = startOfDay(new Date());
      isExpired = dueDate < today;
    }

    return {
      url: printUrl,
      barCode: typableBarCode,
      isExpired,
      hasLoaded: !!firstBill || payment.status === PAYMENT_STATUS.ERROR,
      hasPaidFirstBill: firstBill?.status === BILL_STATUS.BILLED,
    };
  }, [bill]);

  const handleViewBankSlip = useCallback(() => {
    if (!bankSlipInfo.url) return;
    window.open(bankSlipInfo.url, '_blank');
  }, [bankSlipInfo.url]);

  const handleCopyBarCode = useCallback(() => {
    navigator.clipboard.writeText(bankSlipInfo?.barCode);
  }, [bankSlipInfo.barCode]);

  // Dps que a bill é paga esses 2 carinhas deixam de existir
  const shouldRenderBankSlipActions = useMemo(() => {
    return !!bankSlipInfo.barCode && !!bankSlipInfo.url;
  }, [bankSlipInfo.barCode, bankSlipInfo.url]);

  const dependentNameAndSchoolGrade = useMemo(
    () =>
      `${payment.dependent?.name || 'Sem nome'} - ${
        payment.referenceGrade || 'Sem ano/série'
      }`,
    [payment.dependent?.name, payment.referenceGrade],
  );

  // TODO: Uncomment when the API is ready
  // const bankSlipIsExpired = useMemo(() => {
  //   return payment.status === PAYMENT_STATUS.CANCELED && bankSlipInfo.isExpired;
  // }, [bankSlipInfo.isExpired, payment.status]);

  const renderBillContent = () => (
    <Grid container direction="column">
      <Grid container direction="column">
        {!bankSlipInfo.hasPaidFirstBill &&
        payment.status !== PAYMENT_STATUS.ERROR ? (
          <CircularProgress size={20} />
        ) : (
          <Icon className={styles.icon} />
        )}
        <Typography className={clsx(styles.title)}>{title}</Typography>
        <Typography className={styles.descriptionText}>
          {description}
        </Typography>
      </Grid>
      {shouldRenderBankSlipActions ? (
        <Grid container className={styles.gridButtons}>
          <CustomButton
            className={styles.button}
            textVariant="bold"
            variant="primary"
            onClick={handleViewBankSlip}
          >
            Visualizar boleto
          </CustomButton>
          <CustomButton
            className={styles.button}
            textVariant="bold"
            variant="secondary"
            onClick={handleCopyBarCode}
          >
            Copiar código de barras
          </CustomButton>
          {/* {bankSlipIsExpired ? (
          <CustomButton
            className={styles.button}
            textVariant="bold"
            variant="primary"
            onClick={handleViewBankSlip}
          >
            Gerar novo boleto
          </CustomButton>
        ) : null} */}
        </Grid>
      ) : null}
    </Grid>
  );

  return (
    <Grid item xs={12} md={6}>
      <Card className={styles.cardContainer}>
        <Typography className={styles.cardTitle}>
          {payment.service?.name ||
            buildRegistrationServiceName(payment.referenceYear)}
        </Typography>
        <Typography className={styles.cardSubtitle}>
          {dependentNameAndSchoolGrade}
        </Typography>
        <Typography className={styles.cardSubtitle}>
          {translatePaymentMethod(payment.paymentMethodCode)}
        </Typography>
        <Divider className={styles.divider} />
        <CardContent className={styles.cardContent}>
          {bankSlipInfo.hasLoaded ? (
            renderBillContent()
          ) : (
            <LoadingPaymentFeedback />
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PaymentBankslip;
