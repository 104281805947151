import { Box } from '@material-ui/core';
import AlertCard from 'components/AlertCard';
import { CustomIcon } from 'components/CustomIcon';
import PageHeader from 'components/PageHeader';
import { ServiceClassForm } from '../components/ServiceClassForm';
import { FormFooter } from '../components/ServiceClassForm/components/FormFooter';
import { Modal } from '../components/ServiceClassForm/components/Modal';
import {
  DISCART_OR_SAVE_WITHOUT_PUBLISHING,
  NOT_RECOVERABLE_DATA,
} from '../components/ServiceClassForm/components/Modal/messages';
import {
  ContainerButton,
  TextButton,
} from '../components/ServiceClassForm/styles';
import { useServiceClassCreationController } from './controller';

export const ServiceClassCreation = () => {
  const {
    handleSubmit,
    handleCancel,
    submit,
    control,
    errors,
    register,
    setValue,
    getValues,
    successModalOpen,
    cancelModalOpen,
    handleCloseSuccessModal,
    handleCloseCancelModal,
    handleCloseModalAndExit,
    generalErrorMessageAlert,
    handleCloseScheduleError,
    submitError,
    handleCloseSubmitError,
    loading,
  } = useServiceClassCreationController();

  return (
    <>
      <Box paddingX="45px" position="relative">
        <PageHeader
          title="Nova"
          titleBoldSufix="turma"
          subtitle="Cadastre uma turma para publicar no marketplace preenchendo "
          subtitleBold="todas as informações"
        />

        <ServiceClassForm
          type="create"
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          getValues={getValues}
        />
        <FormFooter
          type="create"
          onSubmit={handleSubmit(submit)}
          onCancel={handleCancel}
          loading={loading}
        />
      </Box>
      <Modal
        isOpen={successModalOpen}
        icon={<CustomIcon name="checkCircle" />}
        title={
          <>
            Turma <b>publicada</b> com sucesso!
          </>
        }
        subtitle="A turma foi publicada no marketplace e os responsáveis já podem fazer a contratação."
        confirmButton={
          <ContainerButton onClick={handleCloseSuccessModal}>
            Fechar
          </ContainerButton>
        }
      />
      <Modal
        isOpen={cancelModalOpen}
        icon={<CustomIcon name="warningCheckCircle" />}
        subtitle={NOT_RECOVERABLE_DATA}
        title={DISCART_OR_SAVE_WITHOUT_PUBLISHING}
        cancelButton={
          <TextButton onClick={handleCloseCancelModal}>Continuar</TextButton>
        }
        confirmButton={
          <ContainerButton onClick={handleCloseModalAndExit}>
            Sair
          </ContainerButton>
        }
      />
      <AlertCard
        message={generalErrorMessageAlert}
        open={!!generalErrorMessageAlert}
        close={handleCloseScheduleError}
        severity="error"
      />
      <AlertCard
        message={submitError}
        open={!!submitError}
        close={handleCloseSubmitError}
        severity="error"
      />
    </>
  );
};
