import { CustomDialogRef } from 'components/CustomDialog/types';
import { MenuItem } from 'components/HeaderBar/types';
import { useAuth } from 'contexts/auth';
import useNewWindow from 'hooks/newWindow';
import { BILL_CANCELABLE_STATUSES } from 'pages/Admin/PaymentDetails/consts';
import {
  MODAL_CANCEL_PIX_TITLE,
  MODAL_TITLE,
} from 'pages/Admin/PaymentDetails/utils';
import { useCallback, useMemo, useRef, useState } from 'react';
import BillService from 'services/billService';
import PaymentService from 'services/paymentService';
import { useAppStore } from 'store';
import useGlobalStyles from 'styles';
import { BILL_CANCELED, BILL_REFUNDED } from 'texts';
import { BILL_STATUS, PAYMENT_METHODS } from 'types/enums';
import { ButtonProps } from './types';

export const useButtonBillActionsController = ({
  billData,
  onReloadPayment,
}: ButtonProps) => {
  const classes = useGlobalStyles();
  const showAlert = useAppStore(state => state.alert.showAlert);
  const { isAdmin, isDeveloper } = useAuth();
  const { loading: loadingWindow, newWindow } = useNewWindow();
  const [loading, setLoading] = useState(false);
  const deleteBillDialogRef = useRef<CustomDialogRef>(null);
  const cancelBillDialogRef = useRef<CustomDialogRef>(null);
  const cancelAndRefundBillDialogRef = useRef<CustomDialogRef>(null);

  const handleOpenBankSlip = useCallback(async () => {
    setLoading(true);
    let url = billData.printUrl;
    try {
      if (billData.status === BILL_STATUS.DEFAULTING) {
        const response = await BillService.renewExpiredBankSlip(billData.id);
        url = response.data.lastPaymentCharge.print_url;
      }
    } catch (error: any) {
      showAlert({
        message: error?.message || 'Falha ao abrir a fatura.',
        severity: 'error',
      });
    }

    newWindow(url);
  }, [billData.printUrl, billData.status, billData.id, newWindow, showAlert]);

  const goToVindiBill = useCallback(() => {
    window.open(
      `${process.env.REACT_APP_VINDI_DASHBOARD_URL}/bills/${billData.externalId}`,
    );
  }, [billData.externalId]);

  const handleDeleteBill = async () => {
    setLoading(true);
    try {
      await PaymentService.deleteBill(billData.id);
      showAlert({
        message: 'Fatura removida com sucesso!',
        severity: 'success',
      });
      onReloadPayment();
    } catch (error: any) {
      showAlert({
        message: error?.message || 'Falha ao remover fatura.',
        severity: 'error',
      });
    }

    setLoading(false);
  };

  const handleCopyPix = useCallback(() => {
    if (!billData.pixPastAndCopy) {
      showAlert({
        message: 'Código PIX não encontrado!',
        severity: 'error',
      });
      return;
    }

    navigator.clipboard.writeText(billData?.pixPastAndCopy || '');
  }, [billData.pixPastAndCopy, showAlert]);

  const menuItems: MenuItem[] = useMemo(() => {
    const menuItems: MenuItem[] = [];

    const canDownloadBlankSlip =
      billData.paymentMethodCode === PAYMENT_METHODS.BANK_SLIP &&
      billData.status !== BILL_STATUS.BILLED;
    if (canDownloadBlankSlip) {
      menuItems.push({
        title: 'Acessar segunda via',
        action: handleOpenBankSlip,
      });
    }

    const canCopyPix =
      billData.paymentMethodCode === PAYMENT_METHODS.PIX &&
      billData.status !== BILL_STATUS.BILLED;
    if (canCopyPix) {
      menuItems.push({
        title: 'Copiar código PIX',
        action: handleCopyPix,
      });
    }

    const canRefund =
      billData.status === BILL_STATUS.BILLED &&
      billData.paymentMethodCode !== PAYMENT_METHODS.PIX;
    if (isAdmin && canRefund) {
      menuItems.push({
        title: 'Estornar fatura',
        action() {
          cancelAndRefundBillDialogRef.current?.openDialog();
        },
      });
    }

    const canCancel = BILL_CANCELABLE_STATUSES.includes(billData.status);
    if (isAdmin && canCancel) {
      menuItems.push({
        title: 'Cancelar fatura',
        action() {
          cancelBillDialogRef.current?.openDialog();
        },
      });
    }

    if (isAdmin && billData.externalId) {
      menuItems.push({
        title: 'Acessar fatura na Vindi',
        action: goToVindiBill,
      });
    }

    if (isDeveloper) {
      menuItems.push({
        title: 'Remover fatura do sistema',
        action() {
          deleteBillDialogRef.current?.openDialog();
        },
      });
    }

    return menuItems;
  }, [
    billData.paymentMethodCode,
    billData.status,
    billData.externalId,
    isAdmin,
    isDeveloper,
    handleOpenBankSlip,
    handleCopyPix,
    goToVindiBill,
  ]);

  const handleCancelAndRefundBill = async () => {
    await handleCancelBill(true);
  };

  const handleCancelBill = async (refund = false) => {
    try {
      await PaymentService.cancelBill(billData.id, refund);
      showAlert({
        message: refund ? BILL_REFUNDED : BILL_CANCELED,
        severity: 'success',
      });
    } catch (error: any) {
      showAlert({
        message: error?.message || 'Falha no cancelamento da fatura.',
        severity: 'success',
      });
    }
  };

  const getBillCancelationDialogTitle = () => {
    return billData.paymentMethodCode === 'pix'
      ? MODAL_CANCEL_PIX_TITLE
      : MODAL_TITLE(false, 'fatura');
  };

  return {
    handleOpenBankSlip,
    handleDeleteBill,
    classes,
    loading: loading || loadingWindow,
    menuItems,
    cancelBillDialogRef,
    deleteBillDialogRef,
    cancelAndRefundBillDialogRef,
    handleCancelAndRefundBill,
    handleCancelBill,
    getBillCancelationDialogTitle,
  };
};
