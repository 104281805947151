import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    width: 585,
    padding: 35,
  },
  title: {
    fontSize: 24,
    marginBottom: 50,
  },
  field: {
    width: '100%',
  },
  select: {
    width: '60%',
    marginBottom: 25,
  },
});
