import { Bill } from 'types/bill';
import { api, getAxiosResponseMessageErrorOrDefault } from './apiService';

export default class BillService {
  static async getBillAwaitingPaymentByRegistrationId(registrationId: number) {
    try {
      return await api.get(
        `/bills/bill-awaiting-payment/registration/${registrationId}`,
      );
    } catch (err: any) {
      return err.response;
    }
  }

  static async renewExpiredBankSlip(billId: Number) {
    try {
      return await api.put<Bill>(
        `bills/${billId}/update-bill-slip-expiration-date`,
      );
    } catch (err: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(err));
    }
  }
}
