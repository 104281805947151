import {
  INVALID_CPF,
  INVALID_EMAIL,
  INVALID_FIELD,
  INVALID_PHONE,
  NO_NUMBERS,
  REQUIRED_FIELD,
} from 'texts';
import * as Yup from 'yup';
import { compareAsc } from 'date-fns';
import Visa from 'assets/visaIcon.png';
import Mastercard from 'assets/mastercardIcon.png';
import Amex from 'assets/amexIcon.png';
import DinersClub from 'assets/dinersclubIcon.png';
import Hipercard from 'assets/hipercardIcon.png';
import Elo from 'assets/eloIcon.png';
import JCB from 'assets/jbcIcon.png';
import { hasNoNumbers, isBirthDateValid } from 'utils';
import { verifyPhone } from 'pages/Client/SignUp/utils';
import { addressSchema } from 'utils/schemas/addressSchema';

const specialCharacters = /\D+/g;

export const validationSchema = Yup.object({
  name: Yup.string()
    .test('no numbers', NO_NUMBERS, value => hasNoNumbers(value))
    .required(REQUIRED_FIELD),
  email: Yup.string().email(INVALID_EMAIL).required(REQUIRED_FIELD),
  phone: Yup.string()
    .test('valid phone', INVALID_PHONE, value => verifyPhone(value))
    .transform(value => value.replace(specialCharacters, ''))
    .required(REQUIRED_FIELD),
  cpf: Yup.string()
    .test('is valid', INVALID_CPF, value => isValidCPF(value || ''))
    .transform(value => value.replace(specialCharacters, ''))
    .required(REQUIRED_FIELD),
  relationship: Yup.string()
    .test('no numbers', NO_NUMBERS, hasNoNumbers)
    .required(REQUIRED_FIELD),
  occupation: Yup.string()
    .test('no numbers', NO_NUMBERS, value => hasNoNumbers(value))
    .required(REQUIRED_FIELD),
  birthplace: Yup.string()
    .test('no numbers', NO_NUMBERS, value => hasNoNumbers(value))
    .required(REQUIRED_FIELD),
  birthday: Yup.string()
    .test('is not empty', REQUIRED_FIELD, value => {
      // regex para remover "/" e "_"; isso é necessário pois estes sempre estarão presentes
      const rawDate = value?.replace(/\/|_/gm, '');
      return !!rawDate?.length;
    })
    .test('is valid', INVALID_FIELD, value => isBirthDateValid(value)),
  civilStatus: Yup.string().required(REQUIRED_FIELD),
  rg: Yup.object({
    rgNumber: Yup.string().required(REQUIRED_FIELD),
    emitter: Yup.string()
      .test('no numbers', NO_NUMBERS, value => hasNoNumbers(value))
      .required(REQUIRED_FIELD),
  }),
  address: addressSchema,
});

export function isValidCPF(cpf: string) {
  cpf = cpf.replace(/\D+/g, '');
  if (!cpf || cpf.length !== 11) return false;

  for (let i = 0; i <= 9; i++) {
    const digit = String(i);
    const str = digit.repeat(11);
    if (cpf === str) return false;
  }

  let sum;
  let rest;
  sum = 0;

  for (let i = 1; i <= 9; i++)
    sum += Number(cpf.substring(i - 1, i)) * (11 - i);
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== Number(cpf.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum += Number(cpf.substring(i - 1, i)) * (12 - i);
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== Number(cpf.substring(10, 11))) return false;

  return true;
}

export const patterns: Record<string, RegExp> = {
  visa: /^4[0-9]{12}(?:[0-9]{3})/,
  mastercard: /^5[1-5][0-9]{14}/,
  american_express: /^3[47][0-9]{13}/,
  dinersclub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
  discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
  elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/,
  hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
  jbc: /^(?:2131|1800|35\d{3})\d{11}$/,
  maestro: /^(?:5[0678]\d\d|6304|6390|67\d\d)\d{8,15}$/,
};

export const acceptedBrands = [
  {
    name: 'Mastercard',
    logo: Mastercard,
    code: 'mastercard',
    pattern: /^[25][1-5][0-9]{14}/,
  },
  {
    name: 'Visa',
    logo: Visa,
    code: 'visa',
    pattern: /^4[0-9]{12}(?:[0-9]{3})/,
  },
  {
    name: 'American Express',
    logo: Amex,
    code: 'american_express',
    pattern: /^3[47][0-9]{13}/,
  },
  {
    name: 'DinersClub',
    logo: DinersClub,
    code: 'diners_club',
    pattern: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
  },
  {
    name: 'Hipercard',
    logo: Hipercard,
    code: 'hipercard',
    pattern: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
  },
  {
    name: 'JCB',
    logo: JCB,
    code: 'jcb',
    pattern: /^(?:2131|1800|35\d{3})\d{11}$/,
  },
  {
    name: 'Elo',
    logo: Elo,
    code: 'elo',
    pattern:
      /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
  },
];

export const discoverCardBrand = (cardNumber: string) => {
  const brand = acceptedBrands.find(brand =>
    cardNumber.replace(/\D+/g, '').match(brand.pattern),
  );
  if (!brand) return null;
  const { name, code } = brand;
  return { name, code };
};

export const verifyExpiration = (date: string) => {
  const month = date.split('/')[0];
  const year = date.split('/')[1];
  const dateValidate = new Date(Number(`20${year}`), Number(month) - 1, 1);
  return compareAsc(dateValidate, new Date());
};
