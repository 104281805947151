import { Box, Divider, styled, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { CustomButton } from 'components/CustomButton';
import { ConditionalDividerProps } from './types';

export const useStyles = makeStyles(theme => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '28px',
    boxShadow: '0px 6px 32px rgba(9, 13, 27, 0.07)',
    padding: '25px',
    marginBottom: '40px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  centeredCardActions: {
    justifyContent: 'center',
    marginBottom: '10px',
  },
}));

export const ConditionalDivider = styled(Divider)<
  Theme,
  ConditionalDividerProps
>(({ theme, shouldHideWhenMobile }) => {
  return {
    [theme.breakpoints.down('sm')]: {
      display: shouldHideWhenMobile ? 'none' : undefined,
    },
    [theme.breakpoints.up('md')]: {
      display: shouldHideWhenMobile ? undefined : 'none',
    },
  };
});

export const ContractTitle = styled(Typography)(() => {
  return {
    fontSize: '18px',
    marginBottom: '5px',
  };
});

export const ContractSubtitle = styled(Typography)(() => {
  return {
    fontSize: '18px',
    marginBottom: '20px',
  };
});

export const DownloadButton = styled(CustomButton)<Theme>(({ theme }) => {
  return {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  };
});

export const StyledBox = styled(Box)<Theme>(({ theme }) => {
  return {
    height: '300px',
    [theme.breakpoints.down('sm')]: {
      height: '200px',
    },
  };
});
