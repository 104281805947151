import { TextField } from '@material-ui/core';
import MaskedTextField from 'components/MaskedTextField';
import { CPF_MASK, DATE_MASK, PHONE_MASK } from 'texts';
import { FinancialGuardian } from 'types/financialGuardian';
import { LegalGuardian } from 'types/legalGuardian';

export const guardianFields = (
  guardian?: FinancialGuardian | LegalGuardian,
) => [
  {
    mask: CPF_MASK,
    label: 'CPF',
    value: guardian?.cpf,
    name: 'cpf',
    component: MaskedTextField,
  },
  {
    mask: DATE_MASK,
    label: 'Data de nascimento',
    value: guardian?.birthday,
    name: 'birthday',
    component: MaskedTextField,
  },
  {
    label: 'Profissão',
    value: guardian?.occupation,
    name: 'occupation',
    component: TextField,
  },
  {
    label: 'Naturalidade',
    value: guardian?.birthplace,
    name: 'birthplace',
    component: TextField,
  },
];

export const legalGuardianFields = (guardian?: LegalGuardian) => [
  {
    label: 'Nome',
    value: guardian?.name,
    name: 'name',
    component: TextField,
  },
  {
    label: 'Email',
    value: guardian?.email,
    name: 'email',
    component: TextField,
  },
  {
    mask: PHONE_MASK,
    label: 'Telefone',
    value: guardian?.phone,
    name: 'phone',
    component: MaskedTextField,
  },
];
