import {
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import EmptyListPlaceholder from 'components/EmptyListPlaceholder';
import { TablePaper } from 'components/TablePaper';
import {
  billHeaders,
  billTableValues,
} from 'pages/Admin/FinancialReport/utils';
import useGlobalStyles from 'styles';
import { NO_PLANS } from 'texts';
import { Bill } from 'types/bill';
import { useStyles } from './styles';

const FinancialReportTable = ({ bills }: { bills: Bill[] }) => {
  const classes = useGlobalStyles();
  const styles = useStyles();

  return bills.length ? (
    <TablePaper>
      <TableHead>
        <TableRow>
          {billHeaders.map(header => (
            <TableCell key={header}>
              <Typography className={classes.listScreenHeaderText1}>
                {header}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {bills.map(bill => (
          <TableRow key={bill.id} hover className={styles.row}>
            {billTableValues(bill).map(item => (
              <TableCell key={item}>{item}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </TablePaper>
  ) : (
    <Grid className={styles.grid}>
      <EmptyListPlaceholder message={NO_PLANS} />
    </Grid>
  );
};

export default FinancialReportTable;
