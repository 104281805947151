import { CreateQueryParams } from '@nestjsx/crud-request';
import { api, getOneWithFilter, getWithFilter } from 'services/apiService';
import { SCHOOL_GRADES } from 'types/enums';
import { Service } from 'types/service';
import { ServiceType } from 'types/serviceType';
import { NestCrudPaginatedResponse } from './types';

export interface IGetDependentServicesParams {
  schoolClass: string;
  dependentId: number;
  serviceTypeId?: number;
  serviceName?: string;
}

export default class ServicesService {
  // FIXME: type with ServiceType
  static async getTypes<T = never>(): Promise<NestCrudPaginatedResponse<T>> {
    try {
      return await api.get('service-types');
    } catch (error: any) {
      return error.response;
    }
  }

  static async getTypesWithFilter(query?: CreateQueryParams) {
    try {
      return await getWithFilter<ServiceType>('service-types', query);
    } catch (error: any) {
      return error.response;
    }
  }

  static async getStatus() {
    try {
      return await api.get('service-publication-status');
    } catch (error: any) {
      return error.response;
    }
  }

  static async getCategories() {
    try {
      return await api.get(`service-categories`);
    } catch (error: any) {
      return error.response;
    }
  }

  static async getCurrencyRecurrence() {
    try {
      return await api.get(`/service-recurrences`);
    } catch (error: any) {
      return error.response;
    }
  }

  static async createService(formData: FormData) {
    try {
      return await api.post(`/services`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error: any) {
      return error.response;
    }
  }

  static async updateService(formData: FormData, id: string) {
    try {
      return await api.put(`/services/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error: any) {
      return error.response;
    }
  }

  static async getServices(query?: CreateQueryParams) {
    try {
      return await getWithFilter<Service>(`services`, query);
    } catch (error: any) {
      return error.response;
    }
  }

  static async getServicesBySchoollGrade(
    schoolGrade: SCHOOL_GRADES,
    query?: CreateQueryParams,
  ) {
    try {
      return await getWithFilter(`services/school-grade/${schoolGrade}`, query);
    } catch (error: any) {
      return error.response;
    }
  }

  static async getService(id: string, query?: CreateQueryParams) {
    try {
      return await getOneWithFilter('services', id, query);
    } catch (error: any) {
      return error.response;
    }
  }

  static async getDependentServices(params: IGetDependentServicesParams) {
    try {
      return await api.get(`services/dependent/dependent-services`, {
        params,
      });
    } catch (error: any) {
      return error.response;
    }
  }

  static async getInstallments(ids: number[]) {
    try {
      const params = { ids };
      return await api.get(`services/installments`, {
        params,
      });
    } catch (error: any) {
      return error.response;
    }
  }

  static async getMultipleServicesBasedOnRegistration<T = any>(
    registrationId: number,
  ): Promise<{
    status: number;
    data: T;
  }> {
    try {
      return await api.get(`services/registration/${registrationId}`);
    } catch (error: any) {
      return error.response;
    }
  }

  static async getSpecificServiceBasedOnRegistration<T = any>(
    serviceId: string,
    registrationId: string,
  ): Promise<{
    status: number;
    data: T;
  }> {
    try {
      return await api.get(
        `services/${serviceId}/registration/${registrationId}`,
      );
    } catch (error: any) {
      return error.response;
    }
  }

  static async validateServiceInviteCode(
    serviceId: number,
    inviteCode: string,
  ) {
    try {
      return await api.get(
        `services/${serviceId}/invite-code/${inviteCode}/validate`,
      );
    } catch (error: any) {
      return error.response;
    }
  }
}
