import { api, getAxiosResponseMessageErrorOrDefault } from './apiService';
import { PedagogicSchoolYear } from './types/pedagogicSchoolYear.type';

export default class PedagogicSchoolYearService {
  static async getOptions() {
    try {
      return await api.get<PedagogicSchoolYear[]>(
        'pedagogic-school-years/options',
      );
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }
}
