/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import {
  Button,
  CircularProgress,
  createTheme,
  Grid,
  TablePagination,
  ThemeProvider,
  MenuItem,
} from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';
import { DeleteOutline } from '@material-ui/icons';
import { ROWS_PER_PAGE } from 'utils';
import PageHeader from 'components/PageHeader';
import { NewServiceCodesModal } from 'components/NewServiceCodesModal';
import AlertCard from 'components/AlertCard';
import Modal from 'components/Modal';
import { useServiceCodesController } from './controller';
import ServiceCodesTable from './ServiceCodesTable';
import { ServiceHomeTextField } from '../Services/styles';
import { useStyles } from './styles';

const theme = createTheme({}, ptBR);

export const ServiceCodes: React.FC = () => {
  const styles = useStyles();
  const {
    classes,
    total,
    page,
    onChangePage,
    query,
    onChangeRowsPerPage,
    serviceCodes,
    goToCreateServiceCodes,
    openModal,
    handleCloseModal,
    closeAlert,
    isShowAlert,
    alertType,
    alertMessage,
    loadingButton,
    onSubmit,
    services,
    servicesClasses,
    loading,
    openDeleteServiceCodeModal,
    handleCloseDeleteServiceCodeModal,
    handleOpenDeleteServiceCodeModal,
    handleDeleteServiceCode,
    idServiceCode,
    handleChangeServiceId,
    selectedServiceId,
    selectedServiceClassId,
    handleChangeServiceClassId,
  } = useServiceCodesController();

  return (
    <>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: '80vh' }}
        >
          <CircularProgress size={50} color="inherit" />
        </Grid>
      ) : (
        <Grid
          className={classes.listScreenGrid}
          container
          direction="column"
          alignItems="flex-start"
        >
          <Grid container item direction="column" style={{ marginBottom: 50 }}>
            <PageHeader
              title="Gerenciamento dos "
              titleBoldSufix="códigos de acesso antecipado"
              subtitle="Adicione, edite ou delete os códigos de acesso antecipado"
              button={
                <Button
                  onClick={goToCreateServiceCodes}
                  variant="contained"
                  color="primary"
                  className={classes.listScreenButton}
                >
                  Criar códigos
                </Button>
              }
            />
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} md={4}>
                <ServiceHomeTextField
                  label="Serviço"
                  variant="outlined"
                  select
                  value={selectedServiceId}
                  onChange={handleChangeServiceId}
                  SelectProps={{
                    MenuProps: {
                      disableScrollLock: true,
                      className: styles.selectFilter,
                    },
                  }}
                >
                  <MenuItem value={undefined}>Todos</MenuItem>
                  {services.map(service => (
                    <MenuItem key={service.id} value={service.id}>
                      {service.name}
                    </MenuItem>
                  ))}
                </ServiceHomeTextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <ServiceHomeTextField
                  label="Turma"
                  variant="outlined"
                  select
                  value={selectedServiceClassId}
                  onChange={handleChangeServiceClassId}
                  SelectProps={{
                    MenuProps: {
                      disableScrollLock: true,
                      className: styles.selectFilter,
                    },
                  }}
                >
                  <MenuItem value={undefined}>Todos</MenuItem>
                  {servicesClasses.map(serviceClass => (
                    <MenuItem key={serviceClass.id} value={serviceClass.id}>
                      {serviceClass.name}
                    </MenuItem>
                  ))}
                </ServiceHomeTextField>
              </Grid>
            </Grid>

            <ServiceCodesTable
              serviceCodes={serviceCodes}
              handleOpenDeleteServiceCodeModal={
                handleOpenDeleteServiceCodeModal
              }
            />
            <ThemeProvider theme={theme}>
              {/** @ts-ignore */}
              <TablePagination
                component="div"
                count={total}
                page={page}
                className={classes.tablePagination}
                labelRowsPerPage="Itens por página"
                onChangePage={onChangePage}
                rowsPerPage={query.rows}
                onChangeRowsPerPage={onChangeRowsPerPage}
                rowsPerPageOptions={ROWS_PER_PAGE}
              />
            </ThemeProvider>
          </Grid>
        </Grid>
      )}

      <NewServiceCodesModal
        open={openModal}
        close={handleCloseModal}
        loading={loadingButton}
        onSubmit={onSubmit}
        services={services}
        servicesClasses={servicesClasses}
      />

      <Modal
        icon={<DeleteOutline color="primary" className={classes.dialogIcon} />}
        open={openDeleteServiceCodeModal}
        text={`Deseja deletar o código ${idServiceCode}?`}
        btnText="Deletar"
        backBtnText="Voltar"
        btnAction={() => handleDeleteServiceCode(idServiceCode)}
        backBtnAction={handleCloseDeleteServiceCodeModal}
      />

      <AlertCard
        message={alertMessage}
        open={isShowAlert}
        severity={alertType}
        close={closeAlert}
      />
    </>
  );
};

export default ServiceCodes;
