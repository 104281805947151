import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { CheckCircleOutlineOutlined } from '@material-ui/icons';
import { CondOperator } from '@nestjsx/crud-request';
import AddressForm from 'components/AddressForm';
import AlertCard from 'components/AlertCard';
import DependentDataBox from 'components/DependentDataBox';
import EditableFormContainer from 'components/EditableFormContainer';
import FileUploader from 'components/FileUploader';
import Modal from 'components/Modal';
import PageFooter from 'components/PageFooter';
import PageHeader from 'components/PageHeader';
import SavedContract from 'components/SavedContract';
import SpinnerButton from 'components/SpinnerButton';
import { useAuth } from 'contexts/auth';
import { useSearchCEP } from 'hooks/searchCEP';
import { StatusCodes } from 'http-status-codes';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRouteMatch } from 'react-router-dom';
import DependentService from 'services/dependentService';
import PaymentService from 'services/paymentService';
import RegistrationService from 'services/registrationService';
import useGlobalStyles from 'styles';
import { GENERAL_ERROR } from 'texts';
import { Address } from 'types/address';
import { ContractInfo } from 'types/contractInfo';
import { Dependent } from 'types/dependent';
import { Registration } from 'types/registration';
import { RouteParams } from 'types/routeParams';
import { allToUpperCase } from '../NewRegistration/utils';
import { nonEditableFields, uploadList, validationSchema } from './utils';

const ViewDependent: React.FC = () => {
  const classes = useGlobalStyles();
  const { user } = useAuth();
  const {
    params: { id: registrationId },
  } = useRouteMatch<RouteParams>();
  const [modal, setModal] = useState(false);
  const [dependent, setDependent] = useState<Dependent | null>(null);
  const [registration, setRegistration] = useState<Registration | null>(null);
  const [guardianAddress, setGuardianAddress] = useState<Address>(
    {} as Address,
  );
  const [hasGuardianAddress, setHasGuardianAddress] = useState(false);
  const [editing, setEditing] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [contractInfo, setContractInfo] = useState<ContractInfo | null>();

  const { handleSubmit, errors, register, control, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onBlur',
  });

  const {
    address,
    debouncedSearchCEP,
    cepError,
    cepErrorCount,
    clearCepError,
  } = useSearchCEP(dependent?.address);

  useEffect(() => {
    register('hasGuardianAddress');
    setValue('hasGuardianAddress', hasGuardianAddress);
  }, [hasGuardianAddress, register, setValue]);

  const loadRegistration = useCallback(async () => {
    const response = await RegistrationService.registration(registrationId, {
      join: [['financialGuardian'], ['documents'], ['dependent'], ['payments']],
    });
    if (response.status === StatusCodes.OK) {
      setDependent(response.data.dependent);
      setRegistration(response.data);
      setHasGuardianAddress(response.data.dependent.hasGuardianAddress);
    } else {
      setAlert(true);
    }
  }, [registrationId]);

  useEffect(() => {
    loadRegistration();
  }, [loadRegistration]);

  const getPlan = useCallback(async () => {
    if (registration && registration.payments.length) {
      const { data: response, status } = await PaymentService.queryPlans({
        sort: {
          field: 'createdAt',
          order: 'DESC',
        },
        filter: {
          field: 'registrationId',
          operator: CondOperator.EQUALS,
          value: registration.id,
        },
        join: [
          ['contractInfo'],
          ['contractInfo.document'],
          ['financialGuardian'],
        ],
        limit: 1,
      });
      if (status === StatusCodes.OK) {
        const { contractInfo } = response.data[0];
        setContractInfo(contractInfo?.signDate ? contractInfo : null);
      } else {
        setAlert(true);
      }
    }
  }, [registration]);

  useEffect(() => {
    getPlan();
  }, [getPlan]);

  async function onSubmit(data: Partial<Dependent>) {
    if (data.address) {
      data.address = allToUpperCase({ ...data.address, ...address }) as Address;
    }
    if (hasGuardianAddress) {
      data.address = guardianAddress;
    }
    data.hasGuardianDocuments = dependent?.hasGuardianDocuments;
    try {
      const response = await DependentService.updateDependent(
        { ...data, hasGuardianAddress },
        dependent?.id || 0,
      );

      setDependent(response.data);
      setEditing(!editing);
      setModal(true);
    } catch (error) {
      setAlert(true);
      setAlertMessage(GENERAL_ERROR);
    }
  }

  const handleCheckHasGuardianAddress = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setGuardianAddress(
      checked && user
        ? user.financialGuardian.address
        : {
            cep: '',
            number: '',
            complement: '',
            street: '',
            district: '',
            city: '',
            state: '',
          },
    );
    setHasGuardianAddress(checked);
  };

  const handleCloseAlert = () => {
    setAlert(false);
    clearCepError();
  };

  return (
    <Grid
      style={{ padding: 25 }}
      container
      direction="column"
      alignItems="flex-start"
    >
      <Modal
        icon={
          <CheckCircleOutlineOutlined
            color="secondary"
            className={classes.dialogIcon}
          />
        }
        open={modal}
        text="Alteração concluída."
        btnText="Voltar"
        btnAction={() => setModal(false)}
      />
      <AlertCard
        message={alertMessage || cepError}
        open={alert || !!cepError}
        close={handleCloseAlert}
        severity="error"
      />
      <Grid container item direction="column">
        <PageHeader
          title="Detalhes do dependente"
          subtitle="Veja o "
          subtitleBold="detalhamento"
          subtitleSufix=" completo do seu dependente"
        />

        {registration && dependent ? (
          <Paper className={classes.dependentPaper}>
            <DependentDataBox
              dependentName={dependent.name}
              registration={registration}
              light
              editable
              showText
              overlayed
            />
          </Paper>
        ) : null}

        <Paper elevation={0} className={classes.screenPaper}>
          <Grid item container justifyContent="space-between">
            <Typography className={classes.mainScreenText3}>
              <b>Dados gerais</b>
            </Typography>
          </Grid>
          <Divider className={classes.divider1} />
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            spacing={4}
            className={classes.screenInputGrid}
          >
            {dependent
              ? nonEditableFields(dependent).map((item, index) => (
                  <Grid key={index} item>
                    <TextField
                      label={item.label}
                      variant="outlined"
                      value={item.value}
                      disabled
                      className={classes.screenInputField}
                    />
                  </Grid>
                ))
              : null}
          </Grid>

          {dependent ? (
            <EditableFormContainer
              title="Endereço"
              editing={editing}
              edit={() => setEditing(true)}
              save={handleSubmit(onSubmit)}
            >
              <AddressForm
                address={dependent?.address}
                searchAddress={address}
                guardianAddress={guardianAddress}
                cepSearch={debouncedSearchCEP}
                cepErrorCount={cepErrorCount}
                hasGuardianAddress={hasGuardianAddress}
                setHasGuardianAddress={handleCheckHasGuardianAddress}
                register={register}
                control={control}
                errors={errors}
                editing={editing}
              />
            </EditableFormContainer>
          ) : null}
        </Paper>

        <Grid
          container
          item
          alignItems="center"
          justifyContent="flex-end"
          className={classes.verticalMargin}
        >
          <Grid item>
            <SpinnerButton
              className={classes.newContractButton}
              action={handleSubmit(onSubmit)}
              text="Salvar alterações"
              disabled={!editing}
            />
          </Grid>
        </Grid>

        <Paper elevation={0} className={classes.screenPaper}>
          <Typography className={classes.mainScreenText3}>
            <b>Upload de arquivos</b>
          </Typography>
          <Box>
            <Divider className={classes.divider1} />
            {registration
              ? uploadList(registration.documents).map((item, index) => (
                  <FileUploader
                    key={index}
                    registrationId={registration.id}
                    title={item.title}
                    type={item.type}
                    savedDocument={item.savedDocument}
                  />
                ))
              : null}
          </Box>
        </Paper>

        {contractInfo ? (
          <SavedContract
            contract={contractInfo}
            year={registration?.schoolYear || new Date().getFullYear()}
          />
        ) : null}

        <PageFooter returnPath="/dependents" />
      </Grid>
    </Grid>
  );
};

export default ViewDependent;
