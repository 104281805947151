import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import useGlobalStyles from 'styles';
import { error, primaryMedium, white } from 'styles/theme';
import { TABLE_HEADERS } from 'utils/tableHeaders';
import { EServiceStatus } from './types';

const headers = [
  TABLE_HEADERS.NAME_OF_SERVICE,
  TABLE_HEADERS.SERVICE_TYPE,
  TABLE_HEADERS.CATEGORY_TYPE,
  TABLE_HEADERS.PARTNER_NAME,
  TABLE_HEADERS.STATUS,
];

export const useServicesTableController = () => {
  // Styles
  const classes = useGlobalStyles();

  // Navigation
  const history = useHistory();

  // Memo
  const label = useMemo(
    () => (text: EServiceStatus) => {
      switch (text) {
        case EServiceStatus.PUBLISHED:
          return { backgroundColor: primaryMedium, color: white };
        case EServiceStatus.SOLD_OUT:
          return { backgroundColor: error, color: white };
        default:
          return { backgroundColor: primaryMedium, color: white };
      }
    },
    [],
  );

  // Callbacks
  const goToServiceDetail = (serviceId: number) => {
    history.push(`/adm-marketplace/services/${serviceId}/detail`);
  };

  const goToServiceUpdate = (serviceId: number) => {
    history.push(`/adm-marketplace/services/${serviceId}/update-service`);
  };

  return {
    headers,
    classes,
    label,
    goToServiceDetail,
    goToServiceUpdate,
  };
};
