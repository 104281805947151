import { NestCrudTable } from 'components/NestCrudTable';
import React from 'react';
import { InvoiceEntity } from 'utils/entities/InvoiceEntity';
import InvoiceFilters from './components/InvoiceFilters';
import { invoiceTableConfig } from './constants';
import { InvoiceTableProps } from './types';
import { useFilteredInvoicesTableController } from './useFilteredInvoicesTableController';

const FilteredInvoicesTable: React.FC<InvoiceTableProps> = ({
  paymentId,
  tableHeader,
  renderFilters = true,
}) => {
  const { fetchInvoices, handleFiltersUpdated, isDeveloper } =
    useFilteredInvoicesTableController({
      paymentId,
    });

  return (
    <NestCrudTable<InvoiceEntity>
      renderFilterComponent={() =>
        renderFilters ? (
          <InvoiceFilters onFilter={handleFiltersUpdated} />
        ) : null
      }
      onFetchData={fetchInvoices}
      tableConfig={invoiceTableConfig(isDeveloper)}
      emptyTableMessage="Não há dados para serem exibidos."
      tableHeader={tableHeader}
    />
  );
};

export default FilteredInvoicesTable;
