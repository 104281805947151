import React from 'react';
import { TextField } from '@material-ui/core';
import { InputProperties } from './types';
import CurrencyInput from './CurrencyInput';
import PercentInput from './PercentInput';

const CurrencyField: React.FC<InputProperties> = ({ isPercent, ...other }) => {
  if (isPercent) {
    return (
      <TextField
        {...other}
        InputProps={{ inputComponent: PercentInput as any }}
      />
    );
  }

  return (
    <TextField
      {...other}
      InputProps={{ inputComponent: CurrencyInput as any }}
    />
  );
};

export default CurrencyField;
