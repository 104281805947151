import React from 'react';

import { Dialog, Grid } from '@material-ui/core';
import {
  ButtonGridItem,
  ModalContainerGrid,
  ModalSubtitle,
  ModalTitle,
  PaperProps,
} from './styles';
import { IModalProps } from './types';

export const Modal = ({
  isOpen,
  icon,
  title,
  subtitle,
  cancelButton,
  confirmButton,
}: IModalProps) => {
  return (
    <Dialog open={isOpen} PaperProps={PaperProps}>
      <ModalContainerGrid container direction="column" alignItems="center">
        <Grid item>{icon}</Grid>
        <Grid item>
          <ModalTitle>{title}</ModalTitle>
        </Grid>
        <Grid item>
          <ModalSubtitle>{subtitle}</ModalSubtitle>
        </Grid>
        <Grid container item direction="row" justifyContent="center">
          {cancelButton && <ButtonGridItem item>{cancelButton}</ButtonGridItem>}
          {confirmButton && (
            <ButtonGridItem item>{confirmButton}</ButtonGridItem>
          )}
        </Grid>
      </ModalContainerGrid>
    </Dialog>
  );
};
