import { IconButton } from '@material-ui/core';
import { EditOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { buildMarketplaceContractTemplateUpdateRoute } from 'routes/utils';
import { ContractTemplates } from 'types/contractTemplates';

export const ContractTemplateRowActions = (_: any, data: ContractTemplates) => {
  return <ContractTemplateRowActionsComponent template={data} />;
};

const ContractTemplateRowActionsComponent = ({
  template,
}: {
  template: ContractTemplates;
}) => {
  const navigation = useHistory();
  const navigateToEditContractTemplate = () => {
    navigation.push(buildMarketplaceContractTemplateUpdateRoute(template.id));
  };

  // TODO: implement details page
  // const navigateToContractTemplateDetails = () => {
  //   navigation.push(buildMarketplaceContractTemplateUpdateRoute(template.id));
  // };

  return (
    <>
      {/* <CustomButton
        variant="secondary"
        onClick={navigateToContractTemplateDetails}
      >
        Ver detalhes
        <ChevronRight />
      </CustomButton> */}
      <IconButton onClick={navigateToEditContractTemplate} color="default">
        <EditOutlined color="primary" />
      </IconButton>
    </>
  );
};
