import { useMemo } from 'react';
import { formatBRL } from 'utils';
import { UsePaymentMethodControllerProps } from './types';

export const usePaymentMethodController = ({
  subscriptionInstallments,
  registrationTaxValue,
  calculatedServiceValue,
}: UsePaymentMethodControllerProps) => {
  // ---------- Memos ----------
  const formattedInstallments = useMemo(
    () =>
      subscriptionInstallments
        ? formatBRL(calculatedServiceValue / subscriptionInstallments)
        : '-',
    [calculatedServiceValue, subscriptionInstallments],
  );
  const formattedRegistrationTaxValue = useMemo(
    () => (registrationTaxValue ? formatBRL(registrationTaxValue) : '-'),
    [registrationTaxValue],
  );
  const totalPrice = useMemo(
    () => formatBRL(calculatedServiceValue + registrationTaxValue),
    [calculatedServiceValue, registrationTaxValue],
  );
  const installmentsAndPriceInfo = useMemo(
    () => `${subscriptionInstallments}x de ${formattedInstallments}`,
    [subscriptionInstallments, formattedInstallments],
  );
  const registrationTaxDisclaimer = useMemo(
    () =>
      `* Primeira parcela: ${formattedInstallments} +${formattedRegistrationTaxValue.replace(
        'R$',
        '',
      )} (taxa de matrícula)`,
    [formattedInstallments, formattedRegistrationTaxValue],
  );

  return {
    totalPrice,
    installmentsAndPriceInfo,
    registrationTaxDisclaimer,
  };
};
