import { CondOperator } from '@nestjsx/crud-request';
import { useAlert } from 'hooks/Alert';
import { MESSAGE_TYPE } from 'hooks/Alert/types';
import { useFilters } from 'hooks/filter';
import { StatusCodes } from 'http-status-codes';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import PaymentService from 'services/paymentService';
import useGlobalStyles from 'styles';
import { GENERAL_ERROR } from 'texts';
import { PAYMENT_STATUS } from 'types/enums';
import { Payment } from 'types/payment';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { ROWS_PER_PAGE } from 'utils';

export const useIncomeTaxReturnController = () => {
  const classes = useGlobalStyles();
  const [total, setTotal] = useState(0);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPage, setIsLoadingPage] = useState(true);

  // FIXME: Colocar filtro em um componente
  const [query, setQuery] = useQueryParams({
    referenceYear: withDefault(NumberParam, new Date().getFullYear() - 1),
    rows: withDefault(NumberParam, ROWS_PER_PAGE[0]),
  });

  const { referenceYear, rows } = query;

  const { openAlert, closeAlert, alertMessage, alertType, isShowAlert } =
    useAlert();

  const [defaultFilters] = useState([
    {
      field: 'referenceYear',
      value: referenceYear,
      operator: CondOperator.EQUALS,
    },
  ]);

  //  FIXME: Prevent access to current year and future years
  const yearSelectOptions = useMemo(() => {
    const startYear = 2022;
    const currentYear = new Date().getFullYear();
    return Array.from(Array(currentYear - startYear).keys()).map(
      (_, index) => startYear + index,
    );
  }, []);

  const { page, filterFields, handleUpdateFilters, handleSetValuePage } =
    useFilters(defaultFilters);

  const handleChangeReferenceYear = (e: ChangeEvent<HTMLInputElement>) => {
    handleUpdateFilters(
      'referenceYear',
      { value: e.target.value },
      CondOperator.EQUALS,
    );
    setQuery({ referenceYear: Number(e.target.value) });
  };

  const loadPayments = useCallback(async () => {
    const { data: response, status } = await PaymentService.filterPayments({
      page: page + 1,
      limit: rows,
      sort: [
        {
          field: 'referenceYear',
          order: 'DESC',
        },
      ],
      search: {
        $and: [
          ...filterFields,
          {
            status: {
              $notin: [PAYMENT_STATUS.ERROR, PAYMENT_STATUS.DRAFT],
            },
          },
        ],
      },
      join: [
        {
          field: 'payment',
          select: [
            'id',
            'registration',
            'source',
            'referenceYear',
            'referenceGrade',
            'value',
          ],
        },
        {
          field: 'service',
          select: ['id', 'name'],
        },
        {
          field: 'registration',
          select: ['id', 'dependent', 'number'],
        },
        {
          field: 'registration.dependent',
          select: ['id', 'name'],
        },
        ['financialGuardian'],
      ],
    });
    if (status === StatusCodes.OK) {
      setPayments(response.data);
      setTotal(response.total);
      setIsLoading(false);
    } else {
      openAlert({ message: GENERAL_ERROR, type: MESSAGE_TYPE.ERROR });
    }
    setIsLoadingPage(false);
  }, [page, rows, filterFields, openAlert]);

  useEffect(() => {
    loadPayments();
  }, [loadPayments]);

  const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    handleSetValuePage(0);
    setQuery({ rows: parseInt(event.target.value, 10) });
  };

  return {
    isLoadingPage,
    closeAlert,
    alertMessage,
    alertType,
    isShowAlert,
    handleSetValuePage,
    handleChangeReferenceYear,
    classes,
    total,
    payments,
    page,
    rows,
    referenceYear,
    setQuery,
    onChangeRowsPerPage,
    isLoading,
    yearSelectOptions,
  };
};
