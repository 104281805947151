import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, Grid } from '@material-ui/core';
import { CheckCircleOutlineOutlined, InfoOutlined } from '@material-ui/icons';
import AlertCard from 'components/AlertCard';
import CreditCardForm from 'components/CreditCardForm';
import Modal from 'components/Modal';
import OutlinedMessage from 'components/OutlinedMessage';
import PageHeader from 'components/PageHeader';
import SpinnerButton from 'components/SpinnerButton';
import Warning from 'assets/warning-circle.png';
import { StatusCodes } from 'http-status-codes';
import React, { useCallback, useState } from 'react';
import Cards, { Focused } from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { useForm } from 'react-hook-form';
import CreditCardService from 'services/creditCardService';
import VindiService from 'services/vindiService';
import useGlobalStyles from 'styles';
import { CARD_ADDED, CARD_ERROR, PARSE_VINDI_ERROR_MESSAGE } from 'texts';
import { NewCard } from 'types/card';
import { useStyles } from './styles';
import {
  BrandObject,
  CardComponentFields,
  ModalProperties,
  SubmitCard,
} from './types';
import { validationSchema } from './utils';

const CreditCardModal: React.FC<ModalProperties> = ({
  user,
  open,
  close,
  loadCards,
}) => {
  const classes = useGlobalStyles();
  const styles = useStyles();
  const { handleSubmit, errors, register } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onBlur',
  });

  const [modal, setModal] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [brand, setBrand] = useState<BrandObject>({} as BrandObject);
  const [focus, setFocus] = useState<Focused>('number');
  const [cardInfo, setCardInfo] = useState<CardComponentFields>({
    name: '',
    expiry: '',
    cvc: '',
    number: '',
  });

  const handleChangeCardInfo = useCallback((focus: Focused, value: string) => {
    setCardInfo(oldCardInfo => ({ ...oldCardInfo, [focus]: value }));
    setFocus(focus);
  }, []);

  async function onSubmit(data: SubmitCard) {
    const { vindi, card } = data;
    card.brand = brand.name;

    vindi.customer_id = user.financialGuardian.externalId;
    vindi.payment_method_code = 'credit_card';
    vindi.payment_company_code = brand.code;

    const response = (await VindiService.generateVindiCardToken(vindi)) as any;
    if (response.status === StatusCodes.CREATED) {
      saveCardWithToken(card, response.data.payment_profile.gateway_token);
    } else {
      setAlert(true);
      setAlertMessage(
        PARSE_VINDI_ERROR_MESSAGE(response?.response?.data?.errors),
      );
    }
  }

  async function saveCardWithToken(card: NewCard, token: string) {
    const response = await CreditCardService.createCardWithToken(
      card,
      user,
      token,
    );
    if (response.status === StatusCodes.CREATED) {
      loadCards();
      setModal(true);
      close();
    } else {
      setAlert(true);
      setAlertMessage(CARD_ERROR);
    }
  }

  return (
    <>
      <Modal
        icon={
          <CheckCircleOutlineOutlined
            color="secondary"
            className={classes.dialogIcon}
          />
        }
        open={modal}
        text={CARD_ADDED}
        btnText="Voltar"
        btnAction={() => setModal(false)}
      />
      <AlertCard
        message={alertMessage}
        open={alert}
        close={() => setAlert(false)}
        severity="error"
      />
      <Dialog open={open} fullWidth maxWidth="md">
        <Box className={styles.box} display="flex" flexDirection="column">
          <PageHeader
            title="Novo cartão"
            subtitle="Adicione um novo cartão de crédito"
          />
          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-around"
          >
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              justifyContent="flex-start"
              className={styles.cardGrid}
            >
              <Grid item className={styles.warning}>
                <OutlinedMessage
                  icon={<img src={Warning} alt="atenção" />}
                  message="Muitos bancos não permitem operações online com o cartão físico. Sempre dê preferência para o cartão virtual gerado pelo aplicativo do seu banco."
                />
              </Grid>
              <Grid item>
                <Cards
                  cvc={cardInfo.cvc}
                  expiry={cardInfo.expiry}
                  name={cardInfo.name}
                  number={cardInfo.number}
                  focused={focus}
                  locale={{ valid: 'válido até' }}
                  placeholders={{ name: 'SEU NOME AQUI' }}
                />
              </Grid>
            </Grid>
            <Grid item className={styles.formGrid}>
              <CreditCardForm
                setBrand={setBrand}
                setValue={handleChangeCardInfo}
                register={register}
                errors={errors}
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={3}
            className={classes.screenButtonGrid}
          >
            <Grid item>
              <Button
                onClick={() => close()}
                color="primary"
                className={classes.textButton}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <SpinnerButton
                action={handleSubmit(onSubmit)}
                text="Adicionar"
                className={classes.newDependentButton}
              />
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

export default CreditCardModal;
