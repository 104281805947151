import { Box, Grid, ListItemText } from '@material-ui/core';
import AlertCard from 'components/AlertCard';
import { CustomIcon } from 'components/CustomIcon';
import { Controller } from 'react-hook-form';
import {
  AvatarPlaceholderContainer,
  AvatarPlaceholderText,
  CameraButton,
  ContainerSiderGrid,
  HiddenFileInput,
  InputText,
  SelectMenuColorDotBorder,
  SelectMenuContainerColorDots,
  SelectMenuInputContainerColorDots,
  SelectMenuItemContainer,
  SelectMenuItemDivider,
} from '../../styles';

import { IGeneraFormProps } from '../../types';
import { useExtraDataFormController } from './controller';

export const ExtraDataForm = ({
  control,
  register,
  errors,
  setValue,
  setAvatar,
  partnerToUpdate,
}: IGeneraFormProps) => {
  const {
    avatarInputRef,
    onChangeAvatar,
    onClickAvatarUpload,
    imageSizeError,
    clearImageSizeError,
    MenuProps,
    selectedColor,
    handleSelectColor,
    colors,
    avatarFile,
  } = useExtraDataFormController({
    setValue,
    setAvatar,
    defaultColor: partnerToUpdate?.color ?? '',
  });

  return (
    <>
      <Grid container spacing={2}>
        <ContainerSiderGrid container item md={2} spacing={0}>
          <Grid item md={9}>
            <AvatarPlaceholderContainer
              imageUrl={avatarFile?.url ?? partnerToUpdate?.photo}
            >
              {!avatarFile?.url && !partnerToUpdate?.photo ? (
                <>
                  <AvatarPlaceholderText>
                    Logo do parceiro
                  </AvatarPlaceholderText>
                  <AvatarPlaceholderText>(500 x 500 px)</AvatarPlaceholderText>
                  <AvatarPlaceholderText>Máx. 1MB</AvatarPlaceholderText>
                </>
              ) : null}

              <Box display="flex" justifyContent="center">
                <HiddenFileInput
                  type="file"
                  accept="image/png, image/jpeg"
                  ref={avatarInputRef}
                  onChange={onChangeAvatar}
                />

                <CameraButton onClick={onClickAvatarUpload}>
                  <CustomIcon name="addPhoto" />
                </CameraButton>
              </Box>
            </AvatarPlaceholderContainer>
          </Grid>
          <SelectMenuInputContainerColorDots item md={9}>
            <Controller
              name="color"
              control={control}
              defaultValue={partnerToUpdate?.color}
              as={({ value }) => (
                <InputText
                  id="color"
                  select
                  innerSelectedColor={selectedColor}
                  SelectProps={{
                    MenuProps,
                  }}
                  name="color"
                  label="Cor"
                  variant="outlined"
                  value={value}
                  onBlur={undefined}
                  error={!!errors.color}
                  helperText={errors.color ? errors.color?.message : null}
                  inputRef={register}
                  fixedWidth
                >
                  <SelectMenuItemContainer
                    id="header"
                    key="header"
                    value={undefined}
                    onClick={undefined}
                  >
                    <ListItemText>Cor</ListItemText>
                  </SelectMenuItemContainer>
                  <SelectMenuItemDivider />
                  <SelectMenuContainerColorDots>
                    {colors.map(color => {
                      return (
                        <SelectMenuColorDotBorder
                          color={color}
                          selectedColor={selectedColor}
                          onClick={() => {
                            handleSelectColor(color);
                          }}
                        >
                          <div className="color-dot" />
                        </SelectMenuColorDotBorder>
                      );
                    })}
                  </SelectMenuContainerColorDots>
                </InputText>
              )}
            />
          </SelectMenuInputContainerColorDots>
        </ContainerSiderGrid>
        <Grid container item md={10}>
          <Grid item md={6}>
            <Controller
              name="description"
              control={control}
              defaultValue={partnerToUpdate?.description}
              as={({ value, onChange }) => (
                <InputText
                  id="description"
                  name="description"
                  label="Sobre o parceiro"
                  variant="outlined"
                  multiline
                  rows={10}
                  value={value}
                  onChange={onChange}
                  error={!!errors.description}
                  helperText={
                    errors.description ? errors.description?.message : null
                  }
                  inputRef={register}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <AlertCard
        message={imageSizeError}
        open={!!imageSizeError}
        close={clearImageSizeError}
        severity="error"
      />
    </>
  );
};
