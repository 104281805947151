import { Grid, MenuItem, TextField } from '@material-ui/core';
import MaskedTextField from 'components/MaskedTextField';
import { dropRight } from 'lodash';
import React from 'react';
import { Controller } from 'react-hook-form';
import useGlobalStyles from 'styles';
import { PHONE_MASK } from 'texts';
import { USER_ROLES } from 'types/enums';
import { FormProperties } from './types';

const UserForm: React.FC<FormProperties> = ({
  user,
  editing,
  register,
  errors,
  control,
  role,
  changeRole,
}) => {
  const classes = useGlobalStyles();

  return (
    <Grid item container spacing={4} className={classes.screenInputGrid}>
      <Grid item>
        <Controller
          name="name"
          control={control}
          as={({ value, onChange }) => (
            <TextField
              label="Nome completo"
              variant="outlined"
              value={value}
              onChange={onChange}
              name="name"
              id="name"
              error={!!errors.name}
              helperText={errors.name ? errors.name?.message : null}
              inputRef={register}
              disabled={!editing}
              className={classes.screenInputField}
              data-test="user-name"
            />
          )}
          defaultValue={user?.name}
        />
      </Grid>
      <Grid item>
        <Controller
          name="email"
          control={control}
          as={({ value, onChange }) => (
            <TextField
              label="Email"
              variant="outlined"
              value={value}
              onChange={onChange}
              name="email"
              id="email"
              error={!!errors.email}
              helperText={errors.email ? errors.email?.message : null}
              inputRef={register}
              disabled={!editing}
              className={classes.screenInputField}
            />
          )}
          defaultValue={user?.email}
        />
      </Grid>
      <Grid item>
        <Controller
          name="phone"
          control={control}
          as={({ value, onChange }) => (
            <MaskedTextField
              mask={PHONE_MASK}
              label="Telefone"
              name="phone"
              value={value}
              onChange={onChange}
              error={!!errors.phone}
              helperText={errors.phone ? errors.phone?.message : null}
              inputRef={register}
              disabled={!editing}
              className={classes.screenInputField}
            />
          )}
          defaultValue={user?.phone}
        />
      </Grid>
      {role && changeRole ? (
        <Grid item>
          <TextField
            label="Nivel de acesso"
            variant="outlined"
            select
            SelectProps={{ MenuProps: { disableScrollLock: true } }}
            value={role}
            onChange={changeRole}
            disabled={!editing}
            className={classes.screenInputField}
          >
            {dropRight(Object.values(USER_ROLES)).map(role => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default UserForm;
