import { Coupon } from 'types/coupon';
import { PAYMENT_METHODS } from 'types/enums';

export function calculateCouponDiscountForEachPaymentMethod(
  totalValue: number,
  coupon: Coupon,
  paymentMethod: PAYMENT_METHODS,
) {
  if (coupon) {
    const appliedCouponDiscount = calculateDiscountForEachMethodMarketplace(
      totalValue,
      coupon,
      paymentMethod,
    );

    if (appliedCouponDiscount) {
      return appliedCouponDiscount;
    }
  }
}

const formatResponseDiscountMarketplace = (
  totalValue: number,
  percent?: number | null,
  value?: number | null,
) => {
  if (percent) {
    const result = totalValue - totalValue * (percent / 100);
    const resultFixed = Number(result.toFixed(2));
    return { currentValue: resultFixed > 0 ? result : 0 };
  } else if (value) {
    const result = totalValue - value;
    return { currentValue: result > 0 ? result : 0 };
  }
  return false;
};

const calculateDiscountForEachMethodMarketplace = (
  totalValue: number,
  coupon: Coupon,
  paymentMethod: PAYMENT_METHODS,
) => {
  switch (paymentMethod) {
    case PAYMENT_METHODS.PIX:
      return formatResponseDiscountMarketplace(
        totalValue,
        coupon.pix_percent,
        coupon.pix_value,
      );
    case PAYMENT_METHODS.CARD:
      return formatResponseDiscountMarketplace(
        totalValue,
        coupon.credit_card_percent,
        coupon.credit_card_value,
      );
    case PAYMENT_METHODS.BANK_SLIP:
      return formatResponseDiscountMarketplace(
        totalValue,
        coupon.bank_slip_percent,
        coupon.bank_slip_value,
      );

    default:
      break;
  }
};
