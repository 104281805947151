import { MenuProps } from '@material-ui/core';
import { IPartnersResponse } from 'pages/Admin/Marketplace/Partners/components/PartnersForm/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import ContractTemplatesService from 'services/contractTemplates';
import PartnersService from 'services/partnersService';
import { ContractTemplates } from 'types/contractTemplates';

import { MenuProps as MenuPropsStructure } from '../../styles';

const provider = ['Interno', 'Happy Code', 'Discovery', 'Futebol'];

export const useFinancialDataFormController = () => {
  // Refs
  const inputRefMenuDimensions = useRef<HTMLInputElement>(null);

  // States
  const [menuProps, setMenuProps] = useState<Partial<MenuProps>>();
  const [partners, setPartners] = useState<Array<IPartnersResponse>>();
  const [contractTemplates, setContractTemplates] = useState<
    Array<ContractTemplates>
  >([]);

  // Callbacks
  const getSelectOptions = useCallback(async () => {
    const [{ data: responseContractTemplates }, { data: responsePartners }] =
      await Promise.all([
        ContractTemplatesService.getTemplates({ limit: 50 }),
        PartnersService.getPartners(),
      ]);

    if (responsePartners.data) {
      setPartners(responsePartners.data);
    }
    if (responseContractTemplates.data) {
      setContractTemplates(responseContractTemplates.data);
    }
  }, []);

  // Effects
  useEffect(() => {
    if (inputRefMenuDimensions?.current) {
      setMenuProps(
        MenuPropsStructure(inputRefMenuDimensions.current.clientWidth + 1),
      );
    }
  }, [inputRefMenuDimensions]);

  useEffect(() => {
    getSelectOptions();
  }, [getSelectOptions]);

  return {
    MenuProps: menuProps,
    provider,
    inputRefMenuDimensions,
    partners,
    contractTemplates,
  };
};
