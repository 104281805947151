import { Button, Dialog, Grid, Typography } from '@material-ui/core';
import React from 'react';
import useGlobalStyles from 'styles';
import SpinnerButton from 'components/SpinnerButton';
import { ModalProperties } from './types';

// NOTE: Use CustomDialog instead
const Modal: React.FC<ModalProperties> = ({
  icon,
  text,
  subtext,
  subtext2,
  children,
  btnText,
  btnAction,
  backBtnText,
  backBtnAction,
  open,
}) => {
  const classes = useGlobalStyles();

  return (
    <Dialog open={open}>
      <Grid
        className={classes.dialog}
        container
        direction="column"
        alignItems="center"
      >
        <Grid item>{icon}</Grid>
        <Grid item>
          <Typography className={classes.dialogText}>{text}</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.dialogSubText}>{subtext}</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.dialogSubText2}>{subtext2}</Typography>
        </Grid>
        <Grid item>{children}</Grid>
        <Grid
          item
          direction="row"
          container
          alignItems="center"
          justifyContent="center"
          spacing={3}
        >
          {backBtnText && backBtnAction ? (
            <Grid item>
              <Button
                color="primary"
                disableElevation
                className={classes.dialogButton}
                onClick={backBtnAction}
              >
                <b>{backBtnText}</b>
              </Button>
            </Grid>
          ) : null}
          <Grid item>
            <SpinnerButton
              action={btnAction}
              text={btnText}
              className={classes.dialogButton}
            />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Modal;
