import { CreateQueryParams } from '@nestjsx/crud-request';

export interface HandleDebouncedUpdateQueryParams
  extends UpdateQueryCallbackParams {
  isInstant?: boolean;
}

export type UpdateQueryCallback = (params: UpdateQueryCallbackParams) => void;

interface UpdateQueryCallbackParams {
  newStartDate?: Date | null;
  newEndDate?: Date | null;
  newPage?: number;
  newRows?: number;
}

export enum DateFilterKey {
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
}

export enum FilterKey {
  SEARCH_TEXT = 'SEARCH_TEXT',
  REFERENCE_YEARS = 'REFERENCE_YEARS',
  SCHOOL_GRADES = 'SCHOOL_GRADES',
  PAYMENT_STATUSES = 'PAYMENT_STATUSES',
  CONTRACT_STATUSES = 'CONTRACT_STATUSES',
}

export interface BuildQueryParams {
  includePagination?: boolean;
}

export type CreateQueryParamsCallback = (
  params?: BuildQueryParams,
) => CreateQueryParams;
