import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { COLOR_LIST } from 'utils';
import { FileState, IExtraDataFormControllerProps } from './types';
import { verifyImageRestrictions } from './utils';

import { MenuProps as MenuPropsStructure } from '../../styles';

export const useExtraDataFormController = ({
  setValue,
  setAvatar,
  defaultColor,
}: IExtraDataFormControllerProps) => {
  // Refs
  const avatarInputRef = useRef<HTMLInputElement>(null);

  // States
  const [avatarFile, setAvatarFile] = useState<FileState>();
  const [imageSizeError, setImageSizeError] = useState('');
  const [selectedColor, setSelectedColor] = useState(defaultColor);

  // Callbacks
  const onClickAvatarUpload = () => {
    avatarInputRef?.current?.click();
  };

  const onChangeAvatar = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files?.length) {
      const file = event.currentTarget.files![0];

      try {
        const url = await verifyImageRestrictions({ file });

        setAvatarFile({ file, url });
        if (setAvatar) {
          setAvatar({ file, url });
        }
      } catch (error) {
        setImageSizeError(error as string);
      }
    }
  };

  const clearImageSizeError = () => {
    setImageSizeError('');
  };

  const RedimensionedMenuProps = useCallback((width: number) => {
    return MenuPropsStructure(width + 1);
  }, []);

  const handleSelectColor = (color: string) => {
    if (setValue) {
      setValue('color', color);
    }
    setSelectedColor(color);
  };

  // Memo
  const MenuProps = RedimensionedMenuProps(350);

  return {
    avatarInputRef,
    onChangeAvatar,
    onClickAvatarUpload,
    imageSizeError,
    clearImageSizeError,
    MenuProps,
    selectedColor,
    handleSelectColor,
    colors: COLOR_LIST,
    avatarFile,
  };
};
