import { ReactComponent as CircleErrorIcon } from 'assets/icons/check-circle-error.svg';
import { ReactComponent as CheckCicleIcon } from 'assets/icons/check-circle.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { Bill } from 'types/bill';
import { BILL_STATUS, PAYMENT_METHODS, PAYMENT_STATUS } from 'types/enums';

// NOTE: src/pages/Client/MarketplaceOrderPayments/utils/getPaymentStatusDescription.ts has same logic and descriptions
export const getBillStatusDescription = (
  billData: Pick<
    Bill,
    'paymentMethodCode' | 'lastTransactionReason' | 'status'
  >,
  paymentStatus: PAYMENT_STATUS,
) => {
  const paymentErrorText =
    paymentStatus === PAYMENT_STATUS.ERROR
      ? '\nNão se preocupe, caso alguma cobrança seja realizada, a mesma será automaticamente estornada em alguns instantes. Por favor, realize a contratação novamente ou entre em contato com a administração.'
      : '';
  switch (billData.status) {
    case BILL_STATUS.BILLED:
      return {
        title: 'Recebemos seu pagamento!',
        description: 'O pagamento foi realizado com sucesso.',
        icon: CheckCicleIcon,
      };
    case BILL_STATUS.DEFAULTING:
      return {
        title: 'Seu pagamento está em atraso!',
        description: `Ainda não idenficamos o  seu pagamento. Caso tenha realizado o pagamento, aguarde até 48 horas para que o sistema atualize.`,
        icon: CircleErrorIcon,
      };
    case BILL_STATUS.REFUNDED:
    case BILL_STATUS.CANCELED:
      const cancelReason =
        billData?.lastTransactionReason ||
        'Não foi possivel identificar o motivo do cancelamento.';
      return {
        title: 'Seu pagamento foi cancelado!',
        description: `Motivo: ${cancelReason}.${paymentErrorText}`,
        icon: CircleErrorIcon,
      };
    case BILL_STATUS.REJECTED:
      const reason =
        billData?.lastTransactionReason ||
        'Não foi possivel identificar o motivo do erro, revise a forma de pagamento novamente';

      return {
        title: 'Ocorreu um erro com o pagamento!',
        description: `O pagamento não foi realizado devido a um erro interno.\nMotivo: ${reason}.${paymentErrorText}`,
        icon: CircleErrorIcon,
      };
    case BILL_STATUS.AWAITING_PAYMENT:
    case BILL_STATUS.OPEN:
      const description =
        billData.paymentMethodCode !== PAYMENT_METHODS.CARD
          ? 'Escaneie o código ou copie para fazer o pagamento.'
          : 'Aguarde até o lançamento da fatura em seu cartão.';

      return {
        title: 'Aguardando pagamento.',
        description,
        icon: CheckCicleIcon,
      };

    default:
      return {
        title: 'Seu pagamento ainda não foi processado!',
        description: 'Aguarde alguns minutos ou tente atualizar a página.',
        icon: SearchIcon,
      };
  }
};
