import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/core';
import { StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalAlertCard from 'components/GlobalAlertCard';
import GlobalSnackbarLoading from 'components/GlobalSnackbarLoading';
import { ptBR } from 'date-fns/locale';
import { useConfigurations } from 'hooks/useConfigurations';
import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import mainTheme from 'styles/theme';
import { QueryParamProvider } from 'use-query-params';
import { AuthProvider } from './contexts/auth';
import Routes from './routes';
import GlobalStyle from './styles/global';

const AppStoreProvider = () => {
  useConfigurations();
  return null;
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <StylesProvider injectFirst>
        <ThemeProvider theme={mainTheme}>
          <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
            <BrowserRouter>
              <QueryParamProvider ReactRouterRoute={Route}>
                <Routes />
                <GlobalAlertCard />
                <GlobalSnackbarLoading />
              </QueryParamProvider>
            </BrowserRouter>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </StylesProvider>
      <AppStoreProvider />
      <GlobalStyle />
    </AuthProvider>
  );
};

export default App;
