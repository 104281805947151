export enum EServicesSolicitationStatus {
  REFUSED = 'RECUSADO',
  PENDING = 'PENDENTE',
  PUBLISHED = 'APROVADO',
}

export type LabelStyledProps = {
  backgroundColor: string;
  color: string;
};

interface IServicePublicationStatus {
  name: string;
}

export type TServiceSolicitations = {
  id: number;
  name: string;
  partner: string;
  createdAt: string;
  servicePublicationStatus: IServicePublicationStatus;
};

export interface IServiceSolicitationTableProps {
  serviceSolicitations: Array<Partial<TServiceSolicitations>>;
}
