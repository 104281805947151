import { styled, Typography } from '@material-ui/core';
import { mediumGray } from 'styles/theme';

export const Text = styled(Typography)(() => {
  return {
    color: mediumGray,
    fontweight: 600,
    padding: 35,
    fontSize: 18,
  };
});
