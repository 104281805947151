import { EarlyAccessDataForm } from 'pages/Admin/Marketplace/Services/components/ServiceForm/components/EarlyAccessDataForm';
import { ExtraDataForm } from './components/ExtraDataForm';
import { FieldHeader } from './components/FieldHeader';
import { FinancialDataForm } from './components/FinancialDataForm';
import { GeneralDataForm } from './components/GeneralDataForm';
import { PaperContainer } from './styles';
import { IServiceFormProps } from './types';

export const ServiceForm = ({
  control,
  errors,
  register,
  setValue,
  getValues,
  setImagesValues,
  serviceToUpdate,
}: IServiceFormProps) => {
  return (
    <PaperContainer elevation={0}>
      <FieldHeader title="Dados gerais" />
      <GeneralDataForm
        control={control}
        register={register}
        errors={errors}
        setValue={setValue}
        serviceToUpdate={serviceToUpdate}
      />

      <FieldHeader title="Dados financeiros" />
      <FinancialDataForm
        control={control}
        register={register}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        serviceToUpdate={serviceToUpdate}
      />

      <FieldHeader title="Acesso ao serviço" />
      <EarlyAccessDataForm
        control={control}
        register={register}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        serviceToUpdate={serviceToUpdate}
      />

      <FieldHeader title="Informação extra" />
      <ExtraDataForm
        control={control}
        register={register}
        errors={errors}
        setValue={setValue}
        setImagesValues={setImagesValues}
        serviceToUpdate={serviceToUpdate}
      />
    </PaperContainer>
  );
};
