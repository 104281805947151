import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  gridItems: {
    width: '100%',
    marginBottom: 20,
    backgroundColor: 'white',
  },
  selectFilter: {
    maxHeight: 285,
  },
});
