import { QueryJoin, SFields } from '@nestjsx/crud-request';

export const filterServicesByServiceClassJoinArray: QueryJoin[] = [
  { field: 'serviceClasses', select: ['id', 'schoolClasses'] },
  { field: 'serviceClasses.schoolClasses', select: ['id'] },
];

export const buildFilterServicesByServiceClassSearchCondition: (
  schoolClassId: number,
) => SFields = (schoolClassId: number) => ({
  'serviceClassesSchoolClasses.id': {
    $eq: schoolClassId,
  },
});

export const buildFilterServicesByServiceTypeSearchCondition: (
  serviceTypeId: number,
) => SFields = (serviceTypeId: number) => ({
  serviceTypeId: {
    $eq: serviceTypeId,
  },
});

export const buildFilterServicesByServiceNameNameSearchCondition: (
  text: string,
) => SFields = (text: string) => ({
  $or: [{ name: { $cont: text } }, { name: { $contL: text } }],
});
