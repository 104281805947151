import React, { useEffect, useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { useAuth } from 'contexts/auth';

interface ButtonProperties {
  text: string;
  variant?: 'text' | 'outlined' | 'contained';
  className?: string;
  disabled?: boolean;
  action?: () => void;
  localLoading?: boolean;
  testId?: string;
}

// FIXME: don't use this component, use components/CustomButton instead
const SpinnerButton: React.FC<ButtonProperties> = ({
  action,
  text,
  variant,
  className,
  disabled,
  localLoading,
  testId,
}) => {
  const { loading: globalLoading } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(localLoading === undefined ? globalLoading : localLoading);
  }, [localLoading, globalLoading]);

  return (
    <Button
      type="submit"
      color="primary"
      disableElevation
      variant={variant || 'contained'}
      className={className}
      disabled={disabled || isLoading}
      onClick={action}
      data-test={testId}
    >
      {isLoading ? <CircularProgress size={30} color="inherit" /> : text}
    </Button>
  );
};

export default SpinnerButton;
