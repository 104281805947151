import Snackbar from '@material-ui/core/Snackbar';
import { Alert, Color } from '@material-ui/lab';
import React from 'react';
import { AlertProperties } from './types';

/**
 * NOTE: use GlobalAlertCard instead
 * @deprecated
 */
const AlertCard: React.FC<AlertProperties> = ({
  message,
  open,
  close,
  severity,
  permanent,
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={permanent ? null : 10000}
      onClose={close}
    >
      <Alert onClose={close} severity={severity as Color}>
        <b>{message}</b>
      </Alert>
    </Snackbar>
  );
};

export default AlertCard;
