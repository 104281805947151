import { isValidCPF } from 'pages/Admin/NewGuardian/utils';
import {
  INVALID_CPF,
  INVALID_EMAIL,
  INVALID_FIELD,
  INVALID_PHONE,
  NO_NUMBERS,
  REQUIRED_FIELD,
} from 'texts';
import { hasNoNumbers, isBirthDateValid, trimSpecialCharacters } from 'utils';
import { addressSchema } from 'utils/schemas/addressSchema';
import * as Yup from 'yup';
import { verifyPhone } from '../SignUp/utils';

export const validationSchema = Yup.object({
  isAdmin: Yup.boolean(),
  isCompleted: Yup.boolean(),
  name: Yup.string().when('isAdmin', {
    is: true,
    then: Yup.string()
      .test('no numbers', NO_NUMBERS, value => hasNoNumbers(value))
      .required(REQUIRED_FIELD),
  }),
  email: Yup.string().when('isAdmin', {
    is: true,
    then: Yup.string().email(INVALID_EMAIL).required(REQUIRED_FIELD),
  }),
  phone: Yup.string()
    .transform(value => trimSpecialCharacters(value))
    .when('isAdmin', {
      is: true,
      then: Yup.string()
        .required(REQUIRED_FIELD)
        .test('valid phone', INVALID_PHONE, value => verifyPhone(value))
        .transform(value => trimSpecialCharacters(value)),
    }),
  cpf: Yup.string()
    .transform(value => trimSpecialCharacters(value))
    .when(['isAdmin', 'isCompleted'], {
      is: false,
      then: Yup.string()
        .test('is valid', INVALID_CPF, value => isValidCPF(value || ''))
        .transform(value => trimSpecialCharacters(value))
        .required(REQUIRED_FIELD),
    }),
  relationship: Yup.string().when(['isAdmin', 'isCompleted'], {
    is: false,
    then: Yup.string()
      .test('no numbers', NO_NUMBERS, value => hasNoNumbers(value))
      .required(REQUIRED_FIELD),
  }),
  occupation: Yup.string().when(['isAdmin', 'isCompleted'], {
    is: false,
    then: Yup.string()
      .test('no numbers', NO_NUMBERS, value => hasNoNumbers(value))
      .required(REQUIRED_FIELD),
  }),
  birthplace: Yup.string().when(['isAdmin', 'isCompleted'], {
    is: false,
    then: Yup.string()
      .test('no numbers', NO_NUMBERS, value => hasNoNumbers(value))
      .required(REQUIRED_FIELD),
  }),
  birthday: Yup.string().when(['isAdmin', 'isCompleted'], {
    is: false,
    then: Yup.string()
      .test('is not empty', REQUIRED_FIELD, value => {
        // regex para remover "/" e "_"; isso é necessário pois estes sempre estarão presentes
        const rawDate = value?.replace(/\/|_/gm, '');
        return !!rawDate?.length;
      })
      .test('is valid', INVALID_FIELD, value => isBirthDateValid(value)),
  }),
  civilStatus: Yup.string().when(['isAdmin', 'isCompleted'], {
    is: false,
    then: Yup.string().required(REQUIRED_FIELD),
  }),
  rg: Yup.mixed().when(['isAdmin', 'isCompleted'], {
    is: false,
    then: Yup.object({
      rgNumber: Yup.string().required(REQUIRED_FIELD),
      emitter: Yup.string()
        .test('no numbers', NO_NUMBERS, value => hasNoNumbers(value))
        .required(REQUIRED_FIELD),
    }),
  }),
  address: Yup.mixed().when('isAdmin', {
    is: false,
    then: addressSchema,
  }),
}).omit(['isAdmin', 'isCompleted']);

export const userFields = ['name', 'email', 'phone'];
