/* eslint-disable func-names */

import { REQUIRED_FIELD, INVALID_CPF, CARD_EXPIRED, INVALID_CARD } from 'texts';
import * as Yup from 'yup';
import {
  discoverCardBrand,
  isValidCPF,
  verifyExpiration,
} from 'pages/Admin/NewGuardian/utils';

export const validationSchema = Yup.object({
  card: Yup.object({
    alias: Yup.string().required(REQUIRED_FIELD),
    cpf: Yup.string()
      .test('is valid', INVALID_CPF, value => isValidCPF(value || ''))
      .transform(function (value) {
        return value.replace(/\D+/g, '');
      })
      .required(REQUIRED_FIELD),
  }),
  vindi: Yup.object({
    card_number: Yup.string()
      .transform(function (value) {
        return value.replace(/\s+/g, '').trim();
      })
      .test('is valid card', INVALID_CARD, value => {
        return discoverCardBrand(value as string) !== null;
      })
      .required(REQUIRED_FIELD),
    holder_name: Yup.string().required(REQUIRED_FIELD),
    card_expiration: Yup.string()
      .required(REQUIRED_FIELD)
      .test('is not expired', CARD_EXPIRED, value => {
        return verifyExpiration(value as string) === 1;
      }),
    card_cvv: Yup.string().required(REQUIRED_FIELD),
  }),
});
