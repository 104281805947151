import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { FORM_OF_PAYMENT, PAYMENT_METHODS } from 'types/enums';
import { PaymentMethdoPixOrBankslipProps } from '../../types';
import ApplyCouponComponent from '../ApplyCoupon';
import { usePaymentMethodPixStyles } from './styles';
import { usePaymentMethodController } from './usePaymentMethodCardController';

const PaymentMethodPixOrBankslip: React.FC<PaymentMethdoPixOrBankslipProps> = ({
  title,
  serviceId,
  paymentMethod,
  calculatedServiceValue,
  subscription,
  handleChangeSubscription,
  maxInstallments,
  registrationTaxValue = 0,
  ...applyCouponProps
}) => {
  const styles = usePaymentMethodPixStyles();
  const { installmentsAndPriceInfo, registrationTaxDisclaimer, totalPrice } =
    usePaymentMethodController({
      subscriptionInstallments: subscription.installments,
      registrationTaxValue,
      calculatedServiceValue,
    });
  return (
    <>
      <Grid container item xs={12} className={styles.paymentMethodGridTitle}>
        <Typography variant="body2">{title}</Typography>
      </Grid>
      <Grid
        container
        item
        alignItems="flex-start"
        justifyContent="space-evenly"
        direction="row"
        spacing={3}
        className={styles.formMargin}
      >
        <Grid item xs={12} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id={`payment-installments-${serviceId}`}>
              Número de parcelas
            </InputLabel>
            <Select
              labelId={`payment-installments-${serviceId}`}
              id={`payment-installments-${serviceId}-id`}
              name={`installments-${serviceId}`}
              value={subscription.installments}
              label="Número de parcelas"
            >
              {paymentMethod === PAYMENT_METHODS.BANK_SLIP ? (
                [...Array(maxInstallments)].map((_, index) => (
                  <MenuItem
                    key={`${index}-${serviceId}`}
                    value={index + 1}
                    onClick={() =>
                      handleChangeSubscription(serviceId, {
                        installments: index + 1,
                      })
                    }
                  >
                    {index + 1 === 1
                      ? FORM_OF_PAYMENT.CASH_PAYMENT
                      : `${index + 1}x`}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={1}>{FORM_OF_PAYMENT.CASH_PAYMENT}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              label="Valor a pagar"
              value={totalPrice}
              name={`priceTotal-${serviceId}`}
              fullWidth
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="body2">
                      {installmentsAndPriceInfo}
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
            {registrationTaxValue ? (
              <Typography
                variant="body2"
                className={styles.registrationTaxDisclaimer}
              >
                {registrationTaxDisclaimer}
              </Typography>
            ) : null}
          </FormControl>
        </Grid>
        <ApplyCouponComponent {...applyCouponProps} />
      </Grid>
    </>
  );
};

export default PaymentMethodPixOrBankslip;
