import { getYear } from 'date-fns';

export const generateRegistrationYearOptions = (startYear = 2020) => {
  return Array.from(
    { length: getYear(new Date()) - startYear + 3 },
    (_, i) => i + startYear,
  ).map(year => ({
    id: year,
    label: `${year}`,
    value: year,
  }));
};
