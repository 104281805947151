import { useCallback, useMemo } from 'react';
import { Payment } from 'types/payment';

export const useContractBoxController = ({
  contractInfo,
}: Pick<Payment, 'contractInfo'>) => {
  const fileUrl = useMemo(() => {
    return contractInfo?.document.path || '';
  }, [contractInfo?.document.path]);

  const downloadPDF = useCallback(() => {
    if (!fileUrl) {
      console.error(`Could not download PDF. Received file url: ${fileUrl}`);
      return;
    }
    window.open(fileUrl, '_blank');
  }, [fileUrl]);

  return {
    fileUrl,
    downloadPDF,
  };
};
