import { Grid, makeStyles, styled } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  imageGrid: {
    [theme.breakpoints.down('lg')]: {
      maxHeight: 430,
    },
  },
  content: {
    height: '100%',
  },
  mobileImg: {
    width: '100vw',
    position: 'relative',
    left: 0,
    top: 0,
  },
  img: {
    height: '100vh',
    width: 'auto',
    position: 'fixed',
    left: 0,
    top: 0,
  },
}));

export const Container = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  [theme.breakpoints.down(1024)]: {
    alignItems: 'flex-start',
  },
}));
