import { makeStyles } from '@material-ui/core';
import { darkGray, infoMain, primaryMedium } from 'styles/theme';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
  },
  paper: {
    display: 'flex',
    boxShadow: '0px 2px 8px -2px rgba(171, 167, 167, 0.2)',
    borderRadius: '12px',
  },
  header: {
    backgroundColor: primaryMedium,
    borderRadius: '12px 12px 0 0',
    color: '#fff',
    padding: '15px 5px 7px 15px',
  },
  headerText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '15px',
    letterSpacing: '0.13em',
  },
  iconContainer: {
    margin: '15px 15px 8px 20px',
    width: '50px',
  },
  title: {
    marginLeft: '20px',
    fontSize: '16px',
  },
  divider: {
    marginTop: '9px',
    marginBottom: '18px',
    marginLeft: '20px',
    width: '80%',
    background: infoMain,
  },
  countContainer: {
    paddingBottom: '6px',
  },
  count: {
    fontSize: '40px',
    fontWeight: 'bold',
    color: darkGray,
    marginLeft: '20px',
  },
  contentContainer: {
    width: '100%',
  },
});

export default useStyles;
