import { Box } from '@material-ui/core';
import CustomSearchFilters from 'components/CustomSearchFilters';
import { NestCrudTable } from 'components/NestCrudTable';
import Scaffold from 'components/Scaffold';
import { ActionsColumn } from 'components/Table/components/ActionsColumn';
import { getYear } from 'date-fns';
import React from 'react';
import { ISchoolClass } from 'types/schoolClass';
import { NumberParam, StringParam, withDefault } from 'use-query-params';
import { formatBRL } from 'utils';
import { TABLE_HEADERS } from 'utils/tableHeaders';
import { RegistrationPeriodTableConfig } from '../Configurations/components/RegistrationPeriodTableConfig';
import { useTuitionController } from './controller';
import { generateRegistrationYearOptions } from './utils/generateRegistrationYearOptions';

const Tuition: React.FC = () => {
  const { handleFiltersUpdated, handleNavigateToDetails, loadTuitions } =
    useTuitionController();

  return (
    <Scaffold
      rawTitle={
        <>
          Gestão de <b>Anuidades</b> e <b>Periodo de matrículas</b>
        </>
      }
    >
      <RegistrationPeriodTableConfig />
      <Box mt={4} />
      <NestCrudTable<ISchoolClass>
        tableHeader={{
          rawTitle: (
            <>
              Gestão de <b>Anuidades</b>
            </>
          ),
          rawSubtitle: 'Altere ou cadastre novos anos letivos',
        }}
        renderFilterComponent={() => (
          <CustomSearchFilters
            // TODO: passar pro controller
            filtersConfig={[
              {
                type: 'search',
                name: 'name',
                label: 'Busque por série/ano',
                placeholder: 'Busque por série/ano...',
                queryParamConfig: withDefault(StringParam, ''),
                style: {
                  sm: 8,
                  xs: 6,
                },
              },
              {
                type: 'select',
                name: 'referenceYear',
                label: 'Ano letivo',
                defaultValue: getYear(new Date()),
                queryParamConfig: withDefault(NumberParam, getYear(new Date())),
                options: generateRegistrationYearOptions(),
                style: {
                  sm: 4,
                  xs: 6,
                },
              },
            ]}
            onFiltersUpdated={handleFiltersUpdated}
          />
        )}
        emptyTableMessage="Nenhuma série/ano encontrada."
        // TODO: passar pro controller
        tableConfig={[
          {
            headerName: TABLE_HEADERS.SCHOOL_GRADE,
            getter: 'name',
          },
          {
            headerName: TABLE_HEADERS.REGISTRATION_YEAR,
            getter: 'id',
            onFormat(_id, schoolClass: ISchoolClass) {
              return `${schoolClass.details?.[0].referenceYear}`;
            },
          },
          {
            headerName: TABLE_HEADERS.VALUE,
            getter: 'id',
            onFormat(_id, schoolClass: ISchoolClass) {
              return formatBRL(schoolClass.details?.[0].value);
            },
          },
          {
            headerName: TABLE_HEADERS.SPOTS,
            getter: 'id',
            onFormat(_id, schoolClass: ISchoolClass) {
              return `${schoolClass.details?.[0].remainingVacancies}/${schoolClass.details?.[0].vacancies} restantes`;
            },
          },
          {
            headerName: TABLE_HEADERS.INVOICE_SERVICE_CODE,
            getter: 'invoiceServiceCode',
          },
          {
            headerName: TABLE_HEADERS.ACTIONS,
            getter: 'id',
            align: 'right',
            customRender(schoolClassId: string) {
              return (
                <ActionsColumn
                  onViewDetails={{
                    onClick: () => handleNavigateToDetails(schoolClassId),
                  }}
                />
              );
            },
          },
        ]}
        onFetchData={loadTuitions}
      />
    </Scaffold>
  );
};

export default Tuition;
