import {
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { useStyles } from 'pages/Admin/ContractTemplates/ContractTemplateForm/styles';
import React from 'react';
import { DataPaperProps } from './types';

const DataPaper: React.FC<DataPaperProps> = ({ loading, title, children }) => {
  const styles = useStyles();

  return (
    <Paper className={styles.paper}>
      {loading ? (
        <CircularProgress size={20} color="primary" />
      ) : (
        <>
          <Typography className={styles.paperTitle}>{title}</Typography>
          <Divider className={styles.divider} />
          <Grid
            container
            lg={12}
            className={styles.fieldsContainer}
            spacing={3}
          >
            {children}
          </Grid>
        </>
      )}
    </Paper>
  );
};

export default DataPaper;
