import { ChevronRight, EditOutlined } from '@material-ui/icons';
import { CustomButton } from 'components/CustomButton';
import { DeleteAction } from './components';
import { ActionsColumnProps } from './types';

export const ActionsColumn = ({
  onEdit,
  onDelete,
  onViewDetails,
}: ActionsColumnProps) => {
  return (
    <>
      {onEdit ? (
        <CustomButton {...onEdit} isIconButton>
          <EditOutlined color="primary" />
        </CustomButton>
      ) : null}
      {onDelete ? <DeleteAction onDelete={onDelete} /> : null}
      {onViewDetails ? (
        <CustomButton {...onViewDetails} variant="secondary">
          {onViewDetails.text || ' Ver detalhes'}
          <ChevronRight />
        </CustomButton>
      ) : null}
    </>
  );
};
