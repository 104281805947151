import { Theme, styled } from '@material-ui/core';
import { ColoredEllipsisProps } from './types';

export const ColoredEllipsis = styled('div')<Theme, ColoredEllipsisProps>(
  ({ textColor, backgroundColor }) => ({
    color: textColor,
    backgroundColor,
    padding: '4.5px 9px',
    borderRadius: '10px',
    fontSize: '10px',
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '0.13rem',
    textAlign: 'center',
    display: 'inline-block',
  }),
);
