import { Paper, Table, TableBody, styled } from '@material-ui/core';
import { infoMain } from 'styles/theme';

export const StyledDiv = styled('div')(() => ({
  marginTop: 30,
}));

export const StyledPaper = styled(Paper)(() => ({
  marginTop: 30,
  padding: 20,
  borderRadius: 22,
  boxShadow: '0px 6px 32px rgba(9, 13, 27, 0.07)',
  overflowX: 'auto',
}));

export const StyledTable = styled(Table)(() => ({
  minWidth: 600,
}));

export const StyledTableBody = styled(TableBody)(() => ({
  borderTop: `1px solid ${infoMain}`,
}));
