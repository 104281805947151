import { REQUIRED_FIELD } from 'texts';
import * as Yup from 'yup';

export const ERROR_ON_SUBMIT_CREATION = 'Não foi possível criar um parceiro.';

const generalFieldsSchema = {
  name: Yup.string().required(REQUIRED_FIELD),
  cnpj: Yup.string().required(REQUIRED_FIELD),
  category: Yup.mixed()
    .when('isArray', {
      is: Array.isArray,
      then: Yup.array().of(Yup.string()),
    })
    .required(REQUIRED_FIELD),
  email: Yup.string().required(REQUIRED_FIELD),
  phone: Yup.string().required(REQUIRED_FIELD),
};

const extraFieldsSchema = {
  description: Yup.string().required(REQUIRED_FIELD),
  avatar: Yup.string(),
  color: Yup.string().required(REQUIRED_FIELD),
};

const marketplaceAccessSchema = {
  tax: Yup.string().required(REQUIRED_FIELD),
};

export const schema = Yup.object({
  ...generalFieldsSchema,
  ...extraFieldsSchema,
  ...marketplaceAccessSchema,
});
