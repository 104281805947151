import { TextFieldProps } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { CustomInputText } from './Forms/CustomInputText';

export interface InputProperties
  extends Pick<
    TextFieldProps,
    | 'rows'
    | 'multiline'
    | 'onChange'
    | 'error'
    | 'InputProps'
    | 'placeholder'
    | 'inputRef'
    | 'helperText'
    | 'disabled'
  > {
  mask?: string;
  label?: string;
  name: string;
  value?: any;
  onChange?: any;
  onBlur?: any;
  inputProps?: any;
  error?: boolean;
  helperText: any;
  className?: any;
  style?: any;
}

const MaskedTextField = ({
  disabled,
  mask,
  label,
  name,
  value,
  onChange,
  onBlur,
  inputProps,
  error,
  helperText,
  inputRef,
  className,
  style,
  ...rest
}: InputProperties) => {
  if (!mask) throw new Error('MaskedTextField: mask is required');

  return (
    <InputMask
      mask={mask}
      disabled={disabled}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
    >
      {(inputProperties: any) => (
        <CustomInputText
          label={label}
          id={name}
          name={name}
          disabled={disabled}
          inputProps={inputProps}
          error={error}
          helperText={helperText}
          inputRef={inputRef}
          className={className}
          style={style}
          {...inputProperties}
          {...rest}
        />
      )}
    </InputMask>
  );
};

export default MaskedTextField;
