import { yupResolver } from '@hookform/resolvers/yup';
import { useMediaQuery } from '@material-ui/core';
import { format } from 'date-fns';
import { StatusCodes } from 'http-status-codes';
import { omit } from 'lodash';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import AuthService from 'services/authService';
import UserService from 'services/userService';
import useGlobalStyles from 'styles';
import { EMAIL_FAILED, EXISTING_EMAIL } from 'texts';
import { USER_ROLES } from 'types/enums';
import { InputRef } from 'types/FormTypes';
import { NewUser } from 'types/user';
import { allToUpperCase } from '../NewRegistration/utils';
// import GuardianTooltip from './Tooltip';
import { validationSchema } from './utils';

export const useSignUpController = () => {
  // Styles
  const classes = useGlobalStyles();

  // States
  const [checkTerms, setCheckTerms] = useState(false);
  const [modalSuccessIsOpen, setModalSuccessIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  // Refs
  const nameRef = useRef<InputRef>();
  const emailRef = useRef<InputRef>();
  const repeatEmailRef = useRef<InputRef>();
  const passwordRef = useRef<InputRef>();
  const repeatPasswordRef = useRef<InputRef>();

  // Navigation
  const history = useHistory();

  // Form
  const { handleSubmit, errors, setError, register } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onBlur',
  });

  // Callbacks
  const onSubmit = (data: any) => {
    const newUser: any = allToUpperCase(
      omit(data, ['repeatEmail', 'repeatPassword']),
    );
    newUser.acceptedTerms = {
      accepted: true,
      acceptedAt: format(new Date(), 'yyyy-MM-dd'),
    };
    signUp(newUser as NewUser);
  };

  const modalSuccessClose = () => {
    setModalSuccessIsOpen(false);
    history.push('/login');
  };

  const sendVerificationEmail = async (email: string) => {
    const response = await AuthService.verifyEmail(email);
    if (response.data.status !== StatusCodes.OK) {
      setAlertMessage(EMAIL_FAILED);
    } else {
      setModalSuccessIsOpen(true);
    }
  };

  const signUp = async (newUser: NewUser) => {
    const response = await UserService.createUserFinancialGuardian({
      ...newUser,
      roles: [USER_ROLES.GUARDIAN],
    });
    if (response.status !== StatusCodes.CREATED) {
      setError('email', {
        message: EXISTING_EMAIL,
      });
    } else {
      sendVerificationEmail(newUser.email);
    }
  };

  const toggleCheckTerms = () => {
    setCheckTerms(previousCheckTerms => !previousCheckTerms);
  };

  const resetAlertMessage = () => {
    setAlertMessage('');
  };

  return {
    classes,
    modalSuccessIsOpen,
    checkTerms,
    toggleCheckTerms,
    modalSuccessClose,
    onSubmit,
    nameRef,
    emailRef,
    repeatEmailRef,
    passwordRef,
    repeatPasswordRef,
    handleSubmit,
    errors,
    register,
    alertMessage,
    resetAlertMessage,
  };
};
