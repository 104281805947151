import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import EmptyListPlaceholder from 'components/EmptyListPlaceholder';
import {
  monthlyReportHeaders,
  monthlyReportValues,
} from 'pages/Admin/FinancialReport/utils';
import React from 'react';
import useGlobalStyles from 'styles';
import { NO_PLANS } from 'texts';
import { MonthlyReport } from 'types/financialReport';
import { useStyles } from './styles';

const MonthlyReportTable = ({ report }: { report: MonthlyReport[] }) => {
  const classes = useGlobalStyles();
  const styles = useStyles();

  return report.length ? (
    <Grid item xs={12}>
      <Paper elevation={0} className={styles.paper}>
        <Table className={styles.table}>
          <TableHead>
            <TableRow>
              {monthlyReportHeaders.map(header => (
                <TableCell key={header}>
                  <Typography className={classes.listScreenHeaderText1}>
                    {header}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {report.map(item => (
              <TableRow key={item.mes} hover className={styles.row}>
                {monthlyReportValues(item).map(report => (
                  <TableCell key={report}>{report}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Grid>
  ) : (
    <Grid item xs={12} className={styles.grid}>
      <EmptyListPlaceholder message={NO_PLANS} />
    </Grid>
  );
};

export default MonthlyReportTable;
