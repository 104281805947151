import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    width: '100%',
    padding: 15,
  },
  item: {
    flexGrow: 1,
  },
  capitalized: {
    fontSize: 20,
    textTransform: 'capitalize',
  },
});
