import { useCallback, useMemo } from 'react';
import { useLocalStorage } from '..';
import { LocalStorageKeys } from '../types';
import { DEFAULT_OPTIONS } from './consts';
import {
  LocalStorageWithExpirationDateOptions,
  LocalStorageWithExpirationDateReturn,
  StoredTypeWithExpirationDate,
} from './types';
import { calculateExpirationDateFromNow, checkIfDateHasExpired } from './utils';

/**
 * Custom hook that returns data, a setter and a boolean that indicates
 * if the data has to be updated or not.
 * Known limitations: this hook is unable to store the value "undefined",
 * as it is used to check wether the data has been properly initialized or not.
 */
export const useLocalStorageWithExpirationDate = <T>(
  key: LocalStorageKeys,
  {
    initialValue,
    expiresIn,
  }: LocalStorageWithExpirationDateOptions<T> = DEFAULT_OPTIONS,
): LocalStorageWithExpirationDateReturn<T> => {
  const expiresAtISOString = useMemo(
    () => calculateExpirationDateFromNow(expiresIn).toISOString(),
    [expiresIn],
  );

  const [storedData, setStoredData] = useLocalStorage<
    StoredTypeWithExpirationDate<T> | undefined
  >(
    key,
    initialValue !== undefined
      ? { expiresAt: expiresAtISOString, storedValue: initialValue }
      : undefined,
  );

  /** FIXME:  */
  const setStoredValue = useCallback(
    (value: T) => {
      setStoredData({
        expiresAt: expiresAtISOString,
        storedValue: value,
      });
    },
    [expiresAtISOString, setStoredData],
  );

  const shouldUpdateValue = useMemo(() => {
    if (!storedData?.expiresAt) return true;

    return checkIfDateHasExpired(new Date(storedData.expiresAt));
  }, [storedData?.expiresAt]);

  return [storedData?.storedValue, setStoredValue, shouldUpdateValue];
};
