import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { useLoadingPaymentStyles } from './styles';

const LoadingPaymentFeedback = () => {
  const styles = useLoadingPaymentStyles();
  return (
    <Box className={styles.loadingPaymentContainer}>
      <CircularProgress size={20} />
      <Typography className={styles.loadingPaymentTitle}>
        Aguardando resposta da instuição financeira...
      </Typography>
    </Box>
  );
};

export default LoadingPaymentFeedback;
