import { TextField } from '@material-ui/core';
import styled from 'styled-components';

export const ServiceClassesTextField = styled(TextField)(() => {
  return {
    width: '100%',
    background: 'white',
    borderRadius: '8px',
    height: '55px',
    marginBottom: '15px',
  };
});
