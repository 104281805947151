import { GridSize } from '@material-ui/core';
import { IServiceDetailsResponse } from 'pages/Client/MarketplaceServicesDetail/types';
import { useMemo, useRef, useState } from 'react';
import Slider from 'react-slick';
import { getIdFromYoutubeVideo } from 'utils';

export const useServiceGalleryController = ({
  videoLink = '',
  galleryImages = [],
}: Pick<IServiceDetailsResponse, 'galleryImages' | 'videoLink'>) => {
  // Refs
  const sliderRef = useRef<Slider>(null);

  // States
  const [currentGalleryItemIndex, setCurrentGalleryItemIndex] =
    useState<number>(0);

  const youtubeEmbeddedUrl = useMemo(() => {
    const youtubeVideoId = getIdFromYoutubeVideo(videoLink);

    if (!youtubeVideoId) return '';

    return `https://www.youtube.com/embed/${youtubeVideoId}`;
  }, [videoLink]);

  const gallerySize = useMemo(() => {
    const hasVideo = !!youtubeEmbeddedUrl.length;

    let gallerySize = galleryImages.length;
    if (hasVideo) gallerySize += 1;

    return gallerySize;
  }, [youtubeEmbeddedUrl, galleryImages]);

  const imageBreakpoint = useMemo(() => {
    const gridSize: GridSize = youtubeEmbeddedUrl.length ? 2 : 12;
    return gridSize;
  }, [youtubeEmbeddedUrl]);

  // Callbacks
  const handlePreviousItemGallery = () => {
    setCurrentGalleryItemIndex(item => {
      if (item > 0) {
        sliderRef.current?.slickPrev();
        return item - 1;
      }
      return item;
    });
  };

  const handleNextItemGallery = () => {
    setCurrentGalleryItemIndex(item => {
      if (item < gallerySize - 1) {
        sliderRef.current?.slickNext();
        return item + 1;
      }
      return item;
    });
  };

  return {
    currentGalleryItemIndex,
    sliderRef,
    handlePreviousItemGallery,
    handleNextItemGallery,
    youtubeEmbeddedUrl,
    gallerySize,
    imageBreakpoint,
  };
};
