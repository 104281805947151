import Scaffold from 'components/Scaffold';
import React from 'react';
import FilteredInvoicesTable from './FilteredInvoicesTable';
import MonthlyReportsTable from './MonthlyReportsTable';

const Invoices: React.FC = () => {
  return (
    <Scaffold
      rawTitle={
        <>
          Relatório de <b>notas fiscais</b>
        </>
      }
      rawSubtitle="Resultado da emissão de notas fiscais por competência dos últimos meses"
    >
      <MonthlyReportsTable />
      <FilteredInvoicesTable
        tableHeader={{
          rawTitle: 'Notas fiscais',
          rawSubtitle: 'Relação de notas fiscais criadas no sistema',
        }}
      />
    </Scaffold>
  );
};

export default Invoices;
