import { ChevronRight } from '@material-ui/icons';
import { CustomButton } from 'components/CustomButton';
import { useHistory } from 'react-router-dom';
import { ADMIN_ROUTE } from 'routes/consts';
import { ServiceClassEnrollment } from 'types/serviceClassEnrollment';

export const ServiceClassEnrollmentRowActionsComponent = ({
  serviceClassEnrollmentInfo,
}: {
  serviceClassEnrollmentInfo: ServiceClassEnrollment;
}) => {
  const navigation = useHistory();
  const navigateToPaymentDetail = () => {
    if (serviceClassEnrollmentInfo.payments.length === 0) {
      alert(
        'Não foram encontrado pagamentos para esta matrícula. Contate o suporte!',
      );
      return;
    }

    // sort payments by createdAt desc
    const sortedPayments = serviceClassEnrollmentInfo.payments.sort((a, b) => {
      if (a.createdAt < b.createdAt) return 1;
      return -1;
    });

    const getLastPayment = sortedPayments.slice(-1)[0];

    navigation.push(
      ADMIN_ROUTE.PAYMENT_DETAILS.replace(':id', String(getLastPayment.id)),
    );
  };

  return (
    <CustomButton variant="secondary" onClick={navigateToPaymentDetail}>
      Ver pagamento
      <ChevronRight />
    </CustomButton>
  );
};
