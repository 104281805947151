import React from 'react';
import { Focused } from 'react-credit-cards';
import MaskedTextField from 'components/MaskedTextField';
import useGlobalStyles from 'styles';
import {
  acceptedBrands,
  discoverCardBrand,
} from 'pages/Admin/NewGuardian/utils';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { FormProperties } from './types';
import { creditCardFields } from './utils';

const CreditCardForm: React.FC<FormProperties> = ({
  setValue,
  setBrand,
  register,
  errors,
}) => {
  const classes = useGlobalStyles();

  const handleChange = (focus: Focused, value: string) => {
    if (focus) {
      if (focus === 'number') {
        const brand = discoverCardBrand(value);
        if (brand) setBrand(brand);
      }
      setValue(focus, value);
    }
  };

  return (
    <Grid item container spacing={2}>
      {creditCardFields().map((item, index) => (
        <Grid item key={index}>
          {item.mask ? (
            <MaskedTextField
              mask={item.mask}
              label={item.label}
              name={item.name}
              onChange={(
                e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>,
              ) => handleChange(item.focus as Focused, e.target.value)}
              error={!!item.getter(errors)}
              helperText={item.getter(errors)?.message}
              inputRef={register}
              style={{ width: item.width }}
              className={classes.screenInputField}
            />
          ) : (
            <TextField
              label={item.label}
              variant="outlined"
              name={item.name}
              id={item.name}
              onChange={(
                e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>,
              ) => handleChange(item.focus as Focused, e.target.value)}
              error={!!item.getter(errors)}
              helperText={item.getter(errors)?.message}
              inputRef={register}
              style={{ width: item.width }}
              className={classes.screenInputField}
            />
          )}
          {item.focus === 'number' ? (
            <Grid>
              {acceptedBrands.map(brand => (
                <img
                  key={brand.name}
                  src={brand.logo}
                  alt={brand.name}
                  width={60}
                />
              ))}
            </Grid>
          ) : null}
        </Grid>
      ))}
    </Grid>
  );
};

export default React.memo(CreditCardForm);
