import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { translatePaymentMethod } from 'pages/Client/PaymentMethod/steps/ConfirmPayment/utils';
import React, { useMemo } from 'react';
import { buildRegistrationServiceName } from 'utils/buildRegistrationServiceName';
import { getPaymentStatusFromFirstBill } from '../../utils/getPaymentStatusFromFistBill';
import { usePaymentCardStyles } from '../paymentCardStyles';
import { PaymentCardComponentProps } from '../types';

const PaymentCash: React.FC<PaymentCardComponentProps> = ({
  payment,
  bill,
}) => {
  const styles = usePaymentCardStyles();
  const {
    title,
    description,
    icon: Icon,
  } = useMemo(() => {
    return getPaymentStatusFromFirstBill({
      bills: [bill],
      paymentMethodCode: payment.paymentMethodCode,
      status: payment.status,
      paymentFailReason: payment.paymentFailReason,
      paymentStartDate: payment.paymentStartDate,
    });
  }, [
    bill,
    payment.paymentFailReason,
    payment.paymentMethodCode,
    payment.paymentStartDate,
    payment.status,
  ]);

  const dependentNameAndSchoolGrade = useMemo(
    () =>
      `${payment.dependent?.name || 'Sem nome'} - ${
        payment.referenceGrade || 'Sem ano/série'
      }`,
    [payment.dependent?.name, payment.referenceGrade],
  );

  const renderBillContent = () => (
    <Grid container direction="column">
      <Grid container direction="column">
        <Icon className={styles.icon} />

        <Typography className={clsx(styles.title)}>{title}</Typography>
        <Typography className={styles.descriptionText}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Grid item xs={12} md={6}>
      <Card className={styles.cardContainer}>
        <Typography className={styles.cardTitle}>
          {payment.service?.name ||
            buildRegistrationServiceName(payment.referenceYear)}
        </Typography>
        <Typography className={styles.cardSubtitle}>
          {dependentNameAndSchoolGrade}
        </Typography>
        <Typography className={styles.cardSubtitle}>
          {translatePaymentMethod(payment.paymentMethodCode)}
        </Typography>
        <Divider className={styles.divider} />
        <CardContent className={styles.cardContent}>
          {renderBillContent()}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PaymentCash;
