import {
  CreateQueryParams,
  SConditionAND,
  SFields,
} from '@nestjsx/crud-request';
import { StatusCodes } from 'http-status-codes';
import { EServiceStatus } from 'pages/Admin/Marketplace/Services/components/ServicesTable/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ServicesService from 'services/servicesService';
import { Service } from 'types/service';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import {
  buildFilterServicesByServiceClassSearchCondition,
  buildFilterServicesByServiceNameNameSearchCondition,
  buildFilterServicesByServiceTypeSearchCondition,
  filterServicesByServiceClassJoinArray,
} from '../components/MarketplaceFilteredSearch/utils';
import { useNewMarketplaceController } from '../useNewMarketplaceController';
import { PageHeaderProps } from './types';

export const useMarketplaceSearchResultsController = () => {
  // ---------- Navigation ----------
  const [{ registrationId, searchText, serviceTypeId }, setQueryParams] =
    useQueryParams({
      serviceTypeId: NumberParam,
      searchText: StringParam,
      registrationId: NumberParam,
    });

  // ---------- Common Marketplace Data ----------
  const {
    registrations,
    serviceTypes,
    hasLoadedRequiredData,
    doesGuardianHaveDependents,
    selectedRegistrationSchoolClassId,
  } = useNewMarketplaceController(registrationId);

  // ---------- States ----------
  const [loadingServices, setLoadingServices] = useState(false);
  const [resultingServices, setResultingServices] = useState<Service[]>([]);

  // ---------- Memos ----------
  const selectedServiceType = useMemo(
    () => serviceTypes?.find(({ id }) => id === serviceTypeId),
    [serviceTypeId, serviceTypes],
  );

  const areRouteParamsValid = useMemo(() => {
    const hasAtLeastOneSearchRouteParam = !!searchText || !!serviceTypeId;

    return hasAtLeastOneSearchRouteParam && !!registrationId;
  }, [registrationId, searchText, serviceTypeId]);

  const pageHeader: PageHeaderProps = useMemo(() => {
    if (!searchText && !serviceTypeId)
      return {
        title: '',
        subtitle: '',
      };

    const subtitlePrefix = resultingServices.length
      ? `encontramos ${resultingServices.length} itens`
      : `não encontramos nenhum item`;
    const subtitleSuffix = 'com esse nome para o dependente selecionado';

    const basePageHeader = {
      title: `Resultados para "${searchText}"`,
      subtitle: `${subtitlePrefix} ${subtitleSuffix}`,
    };

    if (!serviceTypeId || !selectedServiceType) {
      return basePageHeader;
    }

    return {
      title: selectedServiceType.name,
      subtitle: selectedServiceType.description,
    };
  }, [
    searchText,
    serviceTypeId,
    resultingServices.length,
    selectedServiceType,
  ]);

  // ---------- Callbacks ----------
  const filteredSearchQueryParams: CreateQueryParams = useMemo(() => {
    const searchAndArray: (SFields | SConditionAND)[] = [
      {
        'servicePublicationStatus.name': {
          $ne: EServiceStatus.NOT_PUBLISHED,
        },
      },
    ];

    if (selectedRegistrationSchoolClassId) {
      searchAndArray.push(
        buildFilterServicesByServiceClassSearchCondition(
          selectedRegistrationSchoolClassId,
        ),
      );
    }

    if (serviceTypeId) {
      searchAndArray.push(
        buildFilterServicesByServiceTypeSearchCondition(serviceTypeId),
      );
    }

    if (searchText) {
      searchAndArray.push(
        buildFilterServicesByServiceNameNameSearchCondition(searchText),
      );
    }

    return {
      fields: [
        'id',
        'name',
        'amount',
        'earlyAccess',
        'coverPhoto',
        'partnerLogo',
      ],
      limit: 30,
      search: {
        $and: searchAndArray,
      },
      join: [
        ...filterServicesByServiceClassJoinArray,
        {
          field: 'serviceRecurrence',
          select: ['id', 'name'],
        },
        {
          field: 'serviceType',
          select: ['id', 'hasAnnuity', 'onlyByInvite'],
        },
        {
          field: 'servicePublicationStatus',
          select: ['id', 'name'],
        },
      ],
    };
  }, [searchText, serviceTypeId, selectedRegistrationSchoolClassId]);

  const handleFilteredSearch = useCallback(async () => {
    if (!areRouteParamsValid) return;

    setLoadingServices(true);

    try {
      const response = await ServicesService.getServices(
        filteredSearchQueryParams,
      );

      if (response.status === StatusCodes.OK) {
        const services = response.data.data;

        setResultingServices(services);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingServices(false);
    }
  }, [areRouteParamsValid, filteredSearchQueryParams]);

  // https://github.com/pbeshai/use-query-params/tree/master/packages/use-query-params#readme
  // to unset or remove a parameter set it to undefined
  const handleOnSelectRegistrationId = useCallback(
    (selectedRegistrationId: number) => {
      setQueryParams(prev => ({
        searchText: prev.searchText || undefined,
        serviceTypeId: prev.serviceTypeId || undefined,
        registrationId: selectedRegistrationId || undefined,
      }));
    },
    [setQueryParams],
  );

  // ---------- Effects ----------
  useEffect(() => {
    if (selectedRegistrationSchoolClassId) {
      handleFilteredSearch();
    }
  }, [handleFilteredSearch, selectedRegistrationSchoolClassId]);

  return {
    // General
    hasLoadedRequiredData,
    loadingServices,
    pageHeader,
    selectedRegistrationId: registrationId,

    // Filtered Search
    registrations,
    selectedRegistrationSchoolClassId,
    doesGuardianHaveDependents,
    filterByServiceTypeId: serviceTypeId,
    handleOnSelectRegistrationId,

    // Services
    resultingServices,
  };
};
