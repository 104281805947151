import { SConditionAND, SFields } from '@nestjsx/crud-request';
import { useQueryParamsWithLocalStorage } from 'hooks/LocalStorage/QueryParams';
import { MarketplaceHomeStorageKeys } from 'hooks/LocalStorage/types';
import { StatusCodes } from 'http-status-codes';
import { EServiceStatus } from 'pages/Admin/Marketplace/Services/components/ServicesTable/types';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { buildMarketplaceServiceSearchResultsRoute } from 'routes/utils';
import ServicesService from 'services/servicesService';
import { SERVICE_MAJOR_TYPE } from 'types/enums';
import { ServiceType } from 'types/serviceType';
import { NumberParam } from 'use-query-params';
import { OnClickSeeAllCallbackProps } from '../components/CategorizedServices/types';

import {
  buildFilterServicesByServiceClassSearchCondition,
  buildFilterServicesByServiceTypeSearchCondition,
  filterServicesByServiceClassJoinArray,
} from '../components/MarketplaceFilteredSearch/utils';
import { useNewMarketplaceController } from '../useNewMarketplaceController';
import {
  MAX_SERVICES_PER_TYPE_ON_LANDING,
  MIN_SERVICES_FOR_RENDERING_SEE_ALL_BUTTON,
} from './consts';
import { FetchServicesByTypeResponse, ServiceTypeData } from './types';

export const useNewMarkatplaceHomeController = () => {
  // ---------- Navigation ----------
  const history = useHistory();

  const [{ registrationId }, setQueryParams] = useQueryParamsWithLocalStorage(
    MarketplaceHomeStorageKeys.QUERY_PARAMS,
    {
      registrationId: NumberParam,
    },
  );

  // ---------- Common Marketplace Data ----------
  const {
    registrations,
    serviceTypes,
    hasLoadedRequiredData,
    doesGuardianHaveDependents,
    selectedRegistrationSchoolClassId,
  } = useNewMarketplaceController(registrationId);

  // ---------- States ----------
  const [loadingServices, setLoadingServices] = useState(false);
  const [regularServiceTypes, setRegularServiceTypes] = useState<
    ServiceTypeData[]
  >([]);
  const [nonRecurrentServiceTypes, setNonRecurrentActivitiesServiceTypes] =
    useState<ServiceTypeData[]>([]);

  // ---------- Callbacks ----------
  const handleOnClickSeeAll: OnClickSeeAllCallbackProps = useCallback(
    serviceTypeId => {
      // FIXME: search for services with selected serviceTypeId after arriving at page
      if (registrationId) {
        history.push(
          buildMarketplaceServiceSearchResultsRoute({
            registrationId,
            serviceTypeId,
          }),
        );
      }
    },
    [history, registrationId],
  );

  const fetchServicesBasedOnType = useCallback(
    async ({
      id: serviceTypeId,
    }: ServiceType): Promise<FetchServicesByTypeResponse> => {
      try {
        const searchAndArray: (SFields | SConditionAND)[] = [
          {
            'servicePublicationStatus.name': {
              $ne: EServiceStatus.NOT_PUBLISHED,
            },
          },
          buildFilterServicesByServiceTypeSearchCondition(serviceTypeId),
        ];

        if (selectedRegistrationSchoolClassId) {
          searchAndArray.push(
            buildFilterServicesByServiceClassSearchCondition(
              selectedRegistrationSchoolClassId,
            ),
          );
        }

        const response = await ServicesService.getServices({
          fields: [
            'id',
            'name',
            'amount',
            'earlyAccess',
            'coverPhoto',
            'partnerLogo',
          ],
          limit: MAX_SERVICES_PER_TYPE_ON_LANDING,
          search: {
            $and: searchAndArray,
          },
          join: [
            ...filterServicesByServiceClassJoinArray,
            {
              field: 'serviceRecurrence',
              select: ['id', 'name'],
            },
            {
              field: 'servicePublicationStatus',
              select: ['id', 'name'],
            },
          ],
        });

        if (response.status === StatusCodes.OK) {
          return response.data;
        }
      } catch (error) {
        console.error(error);
      }

      return {
        data: [],
        total: 0,
      };
    },
    [selectedRegistrationSchoolClassId],
  );

  const handleFetchServicesForLandingPage = useCallback(
    async (serviceTypes: ServiceType[]) => {
      setLoadingServices(true);

      const promises = serviceTypes.map(fetchServicesBasedOnType);

      const servicesMatrix = await Promise.all(promises);

      const servicesByType: ServiceTypeData[] = servicesMatrix.map(
        ({ data }, index) => ({
          ...serviceTypes[index],
          shouldRenderSeeAllButton:
            data.length >= MIN_SERVICES_FOR_RENDERING_SEE_ALL_BUTTON,
          services: data,
        }),
      );

      const regularServiceTypes: ServiceTypeData[] = [];
      const nonRecurrentServiceTypes: ServiceTypeData[] = [];

      servicesByType.forEach(servicesByType => {
        switch (servicesByType.serviceMajorType) {
          case SERVICE_MAJOR_TYPE.GENERAL_ACTIVITIES:
            regularServiceTypes.push(servicesByType);
            break;
          case SERVICE_MAJOR_TYPE.NON_RECURRING_ACTIVITIES:
            nonRecurrentServiceTypes.push(servicesByType);
            break;
          default:
        }
      });

      setRegularServiceTypes(regularServiceTypes);
      setNonRecurrentActivitiesServiceTypes(nonRecurrentServiceTypes);

      setLoadingServices(false);
    },
    [fetchServicesBasedOnType],
  );

  // https://github.com/pbeshai/use-query-params/tree/master/packages/use-query-params#readme
  // to unset or remove a parameter set it to undefined
  const handleOnSelectRegistrationId = useCallback(
    (selectedRegistrationId: number) => {
      setQueryParams({
        registrationId: selectedRegistrationId || undefined,
      });
    },
    [setQueryParams],
  );

  // ---------- Effects ----------
  useEffect(() => {
    if (
      serviceTypes &&
      serviceTypes.length &&
      selectedRegistrationSchoolClassId
    ) {
      handleFetchServicesForLandingPage(serviceTypes);
    }
  }, [
    handleFetchServicesForLandingPage,
    selectedRegistrationSchoolClassId,
    serviceTypes,
  ]);

  return {
    // Search component
    registrations,
    selectedRegistrationSchoolClassId,

    // Select input (registrations/dependents)
    hasLoadedRequiredData,
    doesGuardianHaveDependents,
    selectedRegistrationId: registrationId,
    handleOnSelectRegistrationId,

    // Landing page services
    loadingServices,
    regularServiceTypes,
    nonRecurrentServiceTypes,
    handleOnClickSeeAll,
  };
};
