export const CLAUSE_LIST = [
  // ESTAS CLÁUSULAS SÃO DA TELA ANTES DO PAGAMENTO
  [
    `1. A MATRÍCULA, ato indispensável que estabelece o vínculo do aluno com o Colégio, dar-se-á com a entrevista presencial, com preenchimento dos formulários de cadastro no Portal dos Pais e com a entrega dos documentos solicitados, com a assinatura deste Contrato e com o pagamento integral da primeira parcela da anuidade.`,
    `a. A matrícula não será efetivada se os requisitos anteriores não forem cumpridos ou se houver débitos pendentes não negociados do ano anterior.`,
    `2. A escola CONTRATADA compromete-se a ministrar o serviço educacional contratado, por meio de aulas e demais atividades escolares, segundo as diretrizes pedagógicas adotadas pela instituição, consoante o Regimento Escolar e em conformidade com o disposto na legislação vigente.`,
    `a. O(a) aluno(a) e seus responsáveis declaram, desde logo, que conhecem as normas previstas no Regimento Escolar e no Termo de Entrevista, as quais integram o presente instrumento, e concordam com elas.`,
    `3. As aulas serão ministradas nas salas de aula da CONTRATADA ou em locais em que a escola indicar, inclusive em ambientes virtuais, como exemplo o “Google Workspace for Education”, tendo em vista a natureza do conteúdo e da técnica pedagógica que se fizerem necessários.`,
    `a. Em caso de ocorrência de eventos de força maior e/ou caso fortuito, declarados ou não pelo poder público, a CONTRATADA poderá prestar os serviços educacionais integralmente através de meios eletrônicos, como por exemplo videoaulas e outros, até que o evento extraordinário cesse. Tais hipóteses não geram ao CONTRATANTE o direito de ressarcimento de valores ou de recuperação presencial de aulas ou avaliações, uma vez que o serviço continuará a ser prestado de acordo com as possibilidades existentes durante a ocorrência da situação extraordinária.`,
    `b. O CONTRATANTE é inteiramente responsável pelos insumos e equipamentos necessários para acompanhamento das aulas em ambiente virtual, como por exemplo, computadores, “tablets”, conexão de internet, fones de ouvido e outros.`,
    `4. O Google Workspace for Education é fornecido e gerenciado pela escola por meio de uma conta do G Suite for Education para cada aluno, de uso pessoal e intransferível.`,
    `a. Quaisquer dúvidas em relação ao uso do Google Workspace pela CONTRATADA, o CONTRATANTE deve entrar em contato com o Colégio.`,
    `5. Ao contratar os serviços da CONTRATADA, o CONTRATANTE dá seu consentimento para que seja criada a conta do aluno.`,
  ],
  [
    `6. O CONTRATANTE autoriza o uso da imagem e da voz do aluno durante as atividades desenvolvidas através das aulas remotas, bem como de sua participação em outras atividades pedagógicas não presenciais, por meio tecnológico, junto à escola.`,
    `a. As imagens, sons e mensagens escritas captadas durante o desenvolvimento das atividades remotas, são de exclusiva responsabilidade do CONTRATANTE, inclusive, as imagens, sons e mensagens escritas que não tenham conteúdo pedagógico e que possam caracterizar crimes digitais.`,
    `7. O CONTRATANTE exime a CONTRATADA de quaisquer responsabilidades por atos praticados pelo educando, no âmbito escolar com a utilização de equipamentos eletrônicos e de multimídia de uso pessoal, como por exemplo, aparelhos celulares, smartphone, ipad, tablets etc, ficando os pais e/ou responsáveis notificados a orientarem seus filhos educandos a utilizarem tais equipamentos com ética e disciplina, voltados para fins educacionais, como por exemplo, pesquisas e aquisição de conhecimento.`,
    `a. A CONTRATADA não compactua, não autoriza e não se responsabiliza pelo uso indevido de dispositivos tecnológicos nas dependências do Colégio.`,
    `8. O CONTRATANTE desde logo fica ciente que a escola CONTRATADA utiliza câmeras de vídeo em suas dependências, o que poderá incluir as salas de aula, que as câmeras gravam imagens 24h por dia sete dias por semana, que captam somente imagem, não captam áudio e que as imagens não são acessadas por terceiros.`,
    `9. O CONTRATANTE desde logo autoriza e dá prévia licença para o uso e divulgação do nome, da imagem e da voz do (a) aluno (a) pela CONTRATADA, de forma gratuita e independente de qualquer outra licença ou autorização, para fins comerciais e/ou de publicidade.`,
    `10. O CONTRATANTE autoriza neste ato, a publicação, transmissão e distribuição de textos, obras, trabalhos literários e artísticos, sons, imagens, vídeos e outros meios produzidos pelo(a) aluno(a), ou que o(a) aluno(a) participe, em razão da execução do presente contrato, bem como, cede, gratuitamente, para todos os efeitos legais, os respectivos direitos autorais e de uso de imagem, podendo figurar, individualmente ou coletivamente, independente de nova autorização, nos materiais divulgados pela instituição CONTRATADA, através das diversas modalidades de mídia existentes, observando-se sempre a moral e os bons costumes.`,
  ],
  [
    `11. A primeira parcela da anuidade deverá ser paga no ato da matrícula e a última parcela até o mês de dezembro do ano letivo correspondente.`,
    `a. Convencionam as partes que, em caso de inadimplemento no pagamento de quaisquer das parcelas que compõem a anuidade, nas respectivas datas de vencimentos, as mesmas serão pagas com multa de 2% (dois por cento) e juros de mora de 1% (um por cento) ao mês, calculados sobre o valor atualizado da dívida desde o dia do vencimento, corrigida pelo IGP-M/FGV e calculados por dias corridos.`,
    `b. Em caso de inadimplência, a escola poderá optar pela resilição contratual, sem prejuízo da exigibilidade dos débitos até o mês da resilição.`,
    `c. Havendo atraso de pagamento superior a 30 (trinta) dias, a escola fica desde já autorizada a efetuar a cobrança por intermédio de empresa especializada, credenciada pela escola, arcando o contratante com as despesas advindas desse procedimento; recusar renovação de matrícula para o período letivo seguinte; informar a inadimplência ao Serviço de Proteção a Crédito (SPC).`,
    `12. A anuidade é calculada segundo a planilha de custos da escola de modo a atender ao normal cumprimento do planejamento pedagógico e demais encargos e o estipulado na legislação em vigor.`,
    `13. O valor da anuidade fixada destina-se à cobertura dos serviços educacionais relativos à carga horária normal, excetuadas eventuais atividades extraordinárias de caráter facultativo, tais como passeios, saídas de campo, cursos extras e opcionais, adaptações, segunda chamada de provas, segundas vias de documentos, grupos ou clubes de estudo, palestras e eventos culturais, cujos preços serão fixados na época própria.`,
    `14. Se o(a) aluno(a) der causa à realização de atividade obrigatória em circunstância e/ou horário extraordinário(s), o CONTRATANTE deverá arcar com o pagamento do consequente custo excepcional.`,
  ],
  [
    `15. Caso o CONTRATANTE desista expressamente do contrato até o dia 30 de novembro do ano anterior ao ano letivo contratado, os valores já pagos serão integralmente devolvidos.`,
    `a. Após o dia 30 de novembro, será devido à CONTRATADA 10% (dez por cento) do valor contratado em razão da dedução dos custos envolvidos e reserva de vaga, ficando caracterizada a desistência e a renúncia da vaga.`,
    `16. Em caso de desistência ou transferência do(a) aluno(a) no decorrer do período letivo, será devida a remuneração dos serviços equivalentes até a data do efetivo desligamento, acrescidos de multa de 10% (dez por cento), calculada sobre o valor deste CONTRATO e desconsiderando-se qualquer desconto previamente concedido pela CONTRATADA.`,
    `17. O não comparecimento às aulas, sob qualquer pretexto, não exime o cumprimento do presente contrato. A simples evasão não equivale a cancelamento de matrícula e não exime o CONTRATANTE do pagamento.`,
    `18. Eventual cancelamento de matrícula somente terá validade mediante requerimento protocolado na secretaria da escola, em formulário próprio e firmado pelo CONTRATANTE.`,
    `19. A CONTRATADA reserva- se o direito de aplicar sanções disciplinares e/ou requerer a transferência do(a) aluno(a) em caso de descumprimento de qualquer das normas previstas no Regimento Escolar e no Termo de Entrevista, as quais integram o presente instrumento, especialmente as disciplinares.`,
    `20. A CONTRATADA poderá condicionar a permanência do(a) aluno(a) e a continuidade do contrato, por parte do CONTRATANTE, à adoção de medidas terapêuticas extraescolares que se revelarem necessárias, inclusive com o adequado encaminhamento médico psicológico.`,
    `21. Caso o(a) aluno(a) cometa ou esteja na iminência de cometer grave infração disciplinar, notadamente em casos de violência ou de drogadição ilícita, a CONTRATADA poderá optar pelo imediato chamamento da autoridade pública competente, para o adequado encaminhamento da ocorrência.`,
    `22. A CONTRATADA será indenizada pelo CONTRATANTE e/ou responsável legal por qualquer dano ou prejuízo que este ou acompanhante venha a causar nos edifícios, instalações, mobiliários ou equipamentos da CONTRATADA.`,
  ],
  [
    `23. Pessoas Portadoras de Deficiência (PPD) - Caso o(a)aluno(a)venha a se enquadrar na condição de aluno(a) portador(a) de necessidades especiais, deverá o CONTRATANTE arcar com o pagamento, em separado, dos respectivos custos não pedagógicos, tais como terapias médicas, psicológicas ou psiquiátricas, necessidade de enfermeiros ou cuidadores permanentes e outras.`,
    `24. Não estão incluídos neste contrato os serviços de transporte escolar, aulas opcionais e de presença facultativa para o aluno, eventuais segundas chamadas de prova ou exames, a segunda via de documentos, a segunda via do cartão de acesso, o uniforme, a alimentação ou os materiais de uso individual do aluno.`,
    `25. A eventual contratação de transporte escolar será de iniciativa exclusiva do CONTRATANTE, junto aos transportadores devidamente credenciados pelo Sindicato da categoria, ficando a CONTRATADA eximida de responsabilidade em caso de eventual acidente ou quaisquer outros danos passíveis de responsabilização.`,
    `26. O CONTRATANTE se obriga a adquirir apostilas e material didático indicados pela CONTRATADA, necessários para o acompanhamento e realização das atividades educacionais.`,
    `27. A CONTRATADA não será responsável por eventuais danos sofridos pelo(a) aluno(a) no âmbito escolar ou em atividades promovidas pela CONTRATADA fora do recinto escolar, quando esses danos forem oriundos de caso fortuito e/ou força maior ou tenham ocorrido por iniciativa exclusiva do(a) aluno(a).`,
    `28. O Colégio conta com serviços de atendimento pré-hospitalar (socorristas). A equipe de socorristas é um serviço terceirizado. Ao contratar os serviços da CONTRATADA indica que o CONTRATANTE dá seu consentimento para que, em caso de acidente, urgência ou emergência, a equipe de socorristas preste os primeiros socorros.`,
    `a. Toda medicação de uso contínuo deverá ser entregue aos cuidados da equipe de socorristas para armazenamento, conservação e administração adequadas.`,
    `b. O CONTRATANTE deverá preencher a ficha médica do(a) aluno(a) com todas as informações pertinentes, especialmente o uso de medicação contínua, esclarecendo sua finalidade, bem como se o(a) aluno(a) é portador(a) de alguma enfermidade que necessite de cuidados especiais, sob pena de ser responsabilizado pela omissão da devida informação.`,
  ],
  [
    `29. Em caso de existência de contrato anterior, a assinatura deste não significa quitação de qualquer débito existente.`,
    `30. Durante a vigência deste contrato e para fins de cumprimento das atividades educacionais, atendimento de políticas públicas, proteção da vida e da saúde, bem como para aperfeiçoar seus serviços e promover um melhor desempenho na entrega dos serviços contratados, poderão ser coletados dados pessoais e dados pessoais sensíveis, que, neste ato, autoriza expressamente, o responsável legal, diretamente e/ou por intermédio do CONTRATANTE, para utilização e tratamento pela CONTRATADA no melhor interesse do ALUNO, em atenção ao artigo 14 da Lei 13.709/2018.`,
    `a. O CONTRATANTE autoriza neste ato o tratamento de dados sensíveis, relacionados à saúde, coletados através de formulário a ser preenchido pelo responsável, bem como, aqueles coletados em ambulatório, fornecidos pelo próprio aluno(a), para finalidade de atendimento emergencial.`,
    `b. Para o estabelecido no caput desta cláusula deverá ser considerado que`,
    `i. “Dados Pessoais” são informações relativas a uma pessoa natural identificada ou identificável, incluindo, mas não se limitado a Dados Pessoais necessários à vida estudantil do aluno(a), incluindo a dados sensíveis como dados clínicos e médicos necessários para atendimentos e necessidades pontuais relacionadas à saúde do aluno;`,
    `ii. “Dados Pessoais Sensíveis” aqueles de origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural;`,
    `iii. A CONTRATADA é a “Controladora”, nos termos do art. 5, inciso IV, da Lei 13.709/2018, responsável pela coleta, armazenamento e processamento dos dados pessoais e sensíveis;`,
    `c. A CONTRATADA, enquanto “Controladora”, coleta e promove tratamento de dados pessoais para atendimento das finalidades informadas em sua política de privacidade considerando sempre o princípio da minimização de modo a utilizar apenas as informações necessárias para execução deste contrato que tem por objeto a prestação de serviços educacionais.`,
    `31. A CONTRATADA poderá subcontratar o serviço de processamento e armazenamento de dados, de forma que o(a) CONTRATANTE TEM CIÊNCIA E AUTORIZA NESTE ATO o acesso e tratamento de dados pessoais por terceiros, prestadores de serviços, cuja contratação tenha por objeto, garantir a eficiência dos serviços a serem prestados.`,
  ],
];
