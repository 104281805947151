import { INVALID_FIELD, REQUIRED_FIELD } from 'texts';
import { unformatValueMask } from 'utils';
import * as Yup from 'yup';

export const editSchoolClassDetailsValidationSchema = Yup.object({
  value: Yup.mixed()
    .transform(value => unformatValueMask(value))
    .required(REQUIRED_FIELD),
  vacancies: Yup.number().required(REQUIRED_FIELD).min(1, INVALID_FIELD),
});
