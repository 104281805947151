import { IPaymentData } from 'pages/Client/PaymentMethod/types';
import { api } from 'services/apiService';

export default class DiscountsService {
  static async applyDiscount(paymentData: IPaymentData) {
    try {
      return await api.post(`discounts/apply`, paymentData);
    } catch (error: any) {
      return error.response;
    }
  }
}
