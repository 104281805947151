import { Grid, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { DatePickerProps } from '@material-ui/pickers';
import { CustomDatePicker } from 'components/CustomDatePicker';
import { CustomInputText } from 'components/Forms/CustomInputText';
import { SelectInput } from 'components/Forms/SelectInput/SelectInput';

import clsx from 'clsx';
import { useStyles } from './styles';
import {
  CustomSearchFiltersProps,
  SearchFilterProps,
  SelectFilterProps,
  TextFilterProps,
} from './types';
import { useCustomSearchFiltersController } from './useCustomSearchFiltersController';

const CustomSearchFilters = ({
  filtersConfig,
  onFiltersUpdated,
  className,
}: CustomSearchFiltersProps) => {
  const styles = useStyles();
  const { debouncedUpdateFilters, filters, setFilters } =
    useCustomSearchFiltersController({
      filtersConfig,
      onFiltersUpdated,
    });

  const getInputElement = (filter: SearchFilterProps) => {
    const { style: _s, type, queryParamConfig: _q, ...componentProps } = filter;

    switch (type) {
      case 'select':
        return (
          <SelectInput
            {...(componentProps as SelectFilterProps)}
            key={filter.name}
            name={filter.name}
            value={filters[filter.name]}
            onChange={event => {
              const { value } = event.target;
              setFilters(oldState => ({
                ...oldState,
                [filter.name]: value,
              }));
            }}
            label={filter.label}
            options={filter.options}
          />
        );
      case 'date':
        return (
          <CustomDatePicker
            {...(componentProps as DatePickerProps)}
            key={filter.name}
            label={filter.label}
            value={filters[filter.name]}
            onChange={date => {
              setFilters(oldState => ({
                ...oldState,
                [filter.name]: date,
              }));
            }}
          />
        );
      case 'search':
      default:
        return (
          <CustomInputText
            {...(componentProps as TextFilterProps)}
            key={filter.name}
            label={filter.label}
            name={filter.name}
            onChange={event => {
              const { value } = event.target;
              debouncedUpdateFilters(filter.name, value);
            }}
            placeholder={filter.placeholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        );
    }
  };

  const renderInputElement = (filter: SearchFilterProps) => {
    return (
      <Grid item key={filter.name} sm={filter.style.sm} xs={filter.style.xs}>
        {getInputElement(filter)}
      </Grid>
    );
  };

  return (
    <Grid
      container
      direction="row"
      spacing={2}
      className={clsx(styles.filtersContainer, className)}
    >
      {filtersConfig.map(renderInputElement)}
    </Grid>
  );
};

export default CustomSearchFilters;
