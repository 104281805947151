import React from 'react';

import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { TablePaper } from 'components/TablePaper';
import ChevronRight from '@material-ui/icons/ChevronRight';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { usePartnersTableController } from './controller';
import { IPartnerTableProps, TPartner } from './types';
import { DetailsButton, EditButton, TableBodyContainer } from '../../styles';
import { PartnersCreation } from '../../PartnerCreation';

export const PartnersTable = ({ partners }: IPartnerTableProps) => {
  const { headers, classes, goToPartnerServices, goToPartnerUpdate } =
    usePartnersTableController();

  const getFeeToBeChargedFormatted = (partner: TPartner) => {
    if (partner.feeToBeCharged) return `${partner.feeToBeCharged} %`;
    return '-';
  };

  return (
    <TablePaper isEmpty={!partners.length}>
      <TableHead>
        <TableRow>
          {headers.map(header => (
            <TableCell key={header}>
              <Typography className={classes.listScreenHeaderText1}>
                {header}
              </Typography>
            </TableCell>
          ))}
          <TableCell align="right">
            <Typography className={classes.listScreenHeaderText1}>
              AÇÕES
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBodyContainer>
        <Box height={20} />
        {partners.map(partner => (
          <TableRow key={String(partner.id)}>
            <TableCell>{partner.name}</TableCell>
            <TableCell>{partner.serviceCategory?.name || '-'}</TableCell>
            {/* <TableCell>----</TableCell>
            <TableCell>----</TableCell> */}
            {/* <TableCell>{`${getFeeToBeChargedFormatted(partner)}`}</TableCell> */}

            <TableCell align="right">
              <EditButton
                disableTouchRipple
                onClick={() => goToPartnerUpdate(partner.id)}
              >
                <CreateOutlinedIcon />
              </EditButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBodyContainer>
    </TablePaper>
  );
};
