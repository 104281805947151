import React from 'react';
import { CustomIconWrapper } from './styles';
import { ICustomIconProps } from './types';

import { icons } from './icons';

export const CustomIcon = ({
  name,
  color,
  size,
  onClick,
}: ICustomIconProps) => {
  return (
    <CustomIconWrapper color={color} fontSize={size} onClick={onClick}>
      <img src={icons[name]} alt={`icon-${name}`} />
    </CustomIconWrapper>
  );
};
