import { DEFAULT_SERVICE_NAME } from 'components/FinancialTable/consts';
import { buildSelectOptionsFromArray } from 'components/Forms/SelectInput/utils';
import { FormatOption } from 'services/csvService/enums';
import { CsvConfig } from 'services/csvService/types';
import { CONTRACT_STATUS } from 'types/contractInfo';
import { PAYMENT_STATUS, SCHOOL_GRADES } from 'types/enums';
import { TABLE_HEADERS } from 'utils/tableHeaders';
import { YEARS_ARRAY } from '../AdmRegistrations/utils';

export const FINANTIAL_REPORT_CSV_CONFIG: CsvConfig[] = [
  {
    path: 'referenceYear',
    title: TABLE_HEADERS.YEAR,
  },
  {
    path: 'registration.dependent.name',
    title: TABLE_HEADERS.DEPENDENT_NAME,
  },
  {
    path: 'service.name',
    title: TABLE_HEADERS.SERVICE,
    defaultValue: DEFAULT_SERVICE_NAME,
  },
  {
    path: 'paymentMethodCode',
    title: TABLE_HEADERS.PAYMENT_METHOD,
    formatTo: FormatOption.PAYMENT_METHOD,
  },
  {
    path: 'status',
    title: TABLE_HEADERS.PAYMENT_STATUS,
  },
  {
    path: 'value',
    title: TABLE_HEADERS.TOTAL_VALUE,
    formatTo: FormatOption.CURRENCY_BRL,
  },
  {
    path: 'registration.number',
    title: TABLE_HEADERS.REGISTRATION,
  },
  {
    path: 'referenceGrade',
    title: TABLE_HEADERS.YEAR_OR_GRADE,
    formatTo: FormatOption.SCHOOL_GRADE,
  },
  { path: 'serviceClass.name', title: TABLE_HEADERS.CLASS },
  {
    path: 'contractInfo.signDate',
    title: TABLE_HEADERS.CONTRACT_STATUS,
    formatTo: FormatOption.CONTRACT_STATUS,
  },
  {
    path: 'contractInfo.signDate',
    title: TABLE_HEADERS.CONTRACT_SIGNATURE_DATE,
    formatTo: FormatOption.DATE_DD_MM_YYYY,
    defaultValue: '',
  },
  {
    path: 'subscriptions[*].installments',
    title: TABLE_HEADERS.INSTALLMENTS,
    formatTo: FormatOption.REDUCE_SUM_VALUES,
  },
  {
    path: 'financialGuardian.user.name',
    title: TABLE_HEADERS.FINANCIAL_GUARDIAN,
  },
];

export const PAYMENT_STATUSES_TO_BE_IGNORED = (roles: {
  isAdmin?: boolean;
  isDeveloper?: boolean;
}) => {
  if (roles.isDeveloper) return [PAYMENT_STATUS.DRAFT];
  if (roles.isAdmin) return [PAYMENT_STATUS.DRAFT, PAYMENT_STATUS.ERROR];
  return [PAYMENT_STATUS.DRAFT];
};

export const FILTER_OPTIONS = {
  YEARS: buildSelectOptionsFromArray(YEARS_ARRAY),
  SCHOOL_GRADES: buildSelectOptionsFromArray(Object.values(SCHOOL_GRADES)),
  PAYMENT_STATUSES: (roles: { isAdmin?: boolean; isDeveloper?: boolean }) =>
    buildSelectOptionsFromArray(
      Object.values(PAYMENT_STATUS).filter(
        status => !PAYMENT_STATUSES_TO_BE_IGNORED(roles).includes(status),
      ),
    ),
  CONTRACT_STATUSES: buildSelectOptionsFromArray(
    Object.values(CONTRACT_STATUS),
  ),
};

export const GENERIC_LOAD_ERROR_MESSAGE =
  'Não foi possível carregar os pagamentos solicitados. Por favor, tente mais tarde.';
export const ERROR_DOWNLOAD_CSV_MESSAGE =
  'Não foi possível gerar o relatório solicitado. Por favor, tente mais tarde.';
