import React from 'react';

import { Box } from '@material-ui/core';
import PageHeader from 'components/PageHeader';
import AlertCard from 'components/AlertCard';
import { CustomIcon } from 'components/CustomIcon';
import { PartnersForm } from '../components/PartnersForm';
import { usePartnerCreationController } from './controller';
import { FormFooter } from '../components/PartnersForm/components/FormFooter';
import { ContainerButton, TextButton } from '../components/PartnersForm/styles';
import { Modal } from '../../Services/components/ServiceForm/components/Modal';
import {
  DISCART_OR_SAVE_WITHOUT_PUBLISHING,
  NOT_RECOVERABLE_DATA,
} from '../../Services/components/ServiceForm/components/Modal/messages';

export const PartnersCreation = () => {
  const {
    submit,
    handleSubmit,
    errors,
    register,
    control,
    setValue,
    getValues,
    handleCancel,
    successModalOpen,
    cancelModalOpen,
    handleCloseSuccessModal,
    handleCloseCancelModal,
    handleCloseAndNotGoBack,
    setAvatarFile,
    submitError,
    handleCloseSubmitError,
    loading,
  } = usePartnerCreationController();
  return (
    <>
      <Box paddingX="45px" position="relative">
        <PageHeader
          title="Novo"
          titleBoldSufix="parceiro"
          subtitle="Adicione "
          subtitleBold="todas"
          subtitleSufix=" as informações do parceiro a ser cadastrado"
        />
        <PartnersForm
          type="create"
          control={control}
          register={register}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          setAvatar={setAvatarFile}
        />
        <FormFooter
          type="create"
          onSubmit={handleSubmit(submit)}
          onCancel={handleCancel}
          loading={loading}
        />
      </Box>
      <Modal
        isOpen={successModalOpen}
        icon={<CustomIcon name="checkCircle" />}
        title={
          <>
            Parceiro <b>cadastrado</b> com sucesso!
          </>
        }
        subtitle="O parceiro foi cadastrado e a partir de agora poderá solicitar a publicação de serviços no marketplace."
        confirmButton={
          <ContainerButton onClick={handleCloseSuccessModal}>
            Fechar
          </ContainerButton>
        }
      />
      <Modal
        isOpen={cancelModalOpen}
        icon={<CustomIcon name="warningCheckCircle" />}
        subtitle={NOT_RECOVERABLE_DATA}
        title={DISCART_OR_SAVE_WITHOUT_PUBLISHING}
        cancelButton={
          <TextButton onClick={handleCloseCancelModal}>Sair</TextButton>
        }
        confirmButton={
          <ContainerButton onClick={handleCloseAndNotGoBack}>
            Continuar
          </ContainerButton>
        }
      />

      <AlertCard
        message={submitError}
        open={!!submitError}
        close={handleCloseSubmitError}
        severity="error"
      />
    </>
  );
};
