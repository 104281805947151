import { CreateQueryParams, RequestQueryBuilder } from '@nestjsx/crud-request';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { NestCrudPaginatedResponse, NestCrudSingleResponse } from './types';
import { PostGenerateCsvProps } from './types/getWithFilter.types';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

export async function postGenerateCsv({
  url,
  queryParams,
  body,
  config,
}: PostGenerateCsvProps): Promise<AxiosResponse> {
  const queryString = RequestQueryBuilder.create({ ...queryParams }).query();

  if (queryString) {
    url = `${url}?${queryString}`;
  }

  return api.post(url, body, config);
}

// TODO: change params to implement GetWithFilterProps from './types/getWithFilter.types.ts'
export async function getWithFilter<T = any>(
  url: string,
  queryParams?: CreateQueryParams,
  config?: AxiosRequestConfig,
): Promise<NestCrudPaginatedResponse<T>> {
  const queryString = RequestQueryBuilder.create({ ...queryParams }).query();

  if (queryString) {
    url = `${url}?${queryString}`;
  }

  try {
    return await api.get(url, config);
  } catch (err: any) {
    return err;
  }
}

// TODO: change params to implement GetOneWithFilterProps from './types/getWithFilter.types.ts'
export async function getOneWithFilter<T = any>(
  url: string,
  id: number | string,
  queryParams?: CreateQueryParams,
  config?: AxiosRequestConfig,
): Promise<NestCrudSingleResponse<T>> {
  const queryString = RequestQueryBuilder.create({ ...queryParams }).query();

  let formattedUrl = `${url}/${id}`;

  if (queryString) {
    formattedUrl += `?${queryString}`;
  }

  try {
    return await api.get(formattedUrl, config);
  } catch (err: any) {
    return err;
  }
}

export async function patchWithFilter(
  url: string,
  body: any,
  queryParams?: CreateQueryParams,
) {
  const queryString = RequestQueryBuilder.create({ ...queryParams }).query();

  if (queryString) {
    url = `${url}?${queryString}`;
  }

  try {
    return await api.patch(url, body);
  } catch (err: any) {
    return err;
  }
}

export const getAxiosResponseMessageErrorOrDefault = (
  error: any,
  defaultMessage = 'Ocorreu um erro ao realizar a operação.',
) => {
  if (axios.isAxiosError(error)) {
    const messageIsArray = Array.isArray(error.response?.data?.message);
    if (messageIsArray) {
      return error.response?.data?.message.join(', ').trim() || defaultMessage;
    }

    return error.response?.data?.message || defaultMessage;
  }
  return defaultMessage;
};
