export enum TABLE_HEADERS {
  NAME = 'NOME',
  TYPE = 'TIPO',
  LAST_UPDATE = 'ÚLTIMA ATUALIZAÇÃO',
  RELATION_OR_KINSHIP = 'RELAÇÃO / PARENTESCO',
  STUDENT_NAME = 'NOME DO ALUNO',
  GUARDIAN = 'RESPONSÁVEL',
  SCHOOL_GRADE = 'TURMA',
  STATUS = 'STATUS',
  DEPENDENT = 'DEPENDENTE',
  REGISTRATION = 'MATRÍCULA',
  USE_DATE = 'DATA DE USO',
  YEAR = 'ANO',
  YEAR_OR_GRADE = 'ANO/SÉRIE',
  MONTH_SLASH_YEAR = 'MÊS / ANO',
  INTERVIEW = 'ENTREVISTA',
  NAME_OF_SERVICE = 'NOME DO SERVIÇO',
  SERVICE_TYPE = 'TIPO DE SERVIÇO',
  PAYMENT_SOURCE = 'ORIGEM DO PAGAMENTO',
  CATEGORY_TYPE = 'ÁREA DE ATUAÇÃO',
  CLASSES = 'TURMAS',
  SPOTS = 'VAGAS',
  STATE = 'ESTADO',
  STUDENT = 'ESTUDANTE',
  TOTAL_VALUE = 'VALOR TOTAL',
  VALUE_PIX = 'VALOR PIX',
  VALUE_CREDIT = 'VALOR CRÉDITO',
  VALUE_BANK_SLIP = 'VALOR BOLETO',
  QUANTITY = 'QUANTIDADE',
  INIT_AND_END = 'INÍCIO E TÉRMINO',
  EVENT = 'EVENTO',
  PARTNER_NAME = 'NOME DO PARCEIRO',
  STUDENTS = 'ALUNOS',
  SERVICES_OFFER = 'SERVIÇOS OFERECIDOS',
  TAX_TO_CHARGE = 'TAXA A COBRAR',
  SERVICE_NAME = 'NOME DO SERVIÇO',
  VALIDITY = 'VALIDADE',
  INITIAL_DATE = 'DATA DE INÍCIO',
  END_DATE = 'DATA DE TÉRMINO',
  VALUE = 'VALOR',
  COORDINATOR = 'COORDENADOR',
  CURRENT_YEAR_VALUE = 'VALOR ANO ATUAL',
  CURRENT_YEAR_SPOTS = 'VAGAS ANO ATUAL',
  REGISTRATION_YEAR = 'ANO LETIVO',
  MONTH = 'MÊS',
  RECEIPT = 'PREV. RECEBIMENTO',
  SERVICE = 'SERVIÇO',
  PARTNER = 'PARCEIRO',
  SOLICITATION_DATE = 'DATA DE SOLICITAÇÃO',
  INSTALLMENT = 'PARCELA',
  EMAIL = 'EMAIL',
  PHONE = 'TELEFONE',
  ACCESS_LEVEL = 'NÍVEL DE ACESSO',
  ID = 'ID',
  CODE = 'CÓDIGO',
  SERVICE_CLASS = 'TURMA',
  EXPIRES_IN = 'DATA DE EXPIRAÇÃO',
  CODE_WAS_USED = 'CÓDIGO USADO',
  SIGNATURE_DATE = 'DATA ASSINATURA',
  CONTRACT_STATUS = 'STATUS CONTRATO',
  CONTRACT_NAME = 'CONTRATO',
  CONTRACT_INTEGRATION = 'CONTRATO INTEGRAÇÃO',
  PAYMENT_STATUS = 'STATUS PAGAMENTO',
  WHO_USED = 'USADO POR',
  RECURRENCE = 'RECORRÊNCIA',
  CLASS = 'TURMA',
  ACTIONS = 'AÇÕES',
  FILE = 'ARQUIVO',
  DEPENDENT_NAME = 'ALUNO',
  PAYMENT_METHOD = 'FORMA DE PAGAMENTO',
  FINANCIAL_GUARDIAN = 'RESPONSÁVEL FINANCEIRO',
  PAYMENT_DETAILS = 'DETALHES DO PAGAMENTO',
  INSTALLMENTS = 'PARCELAS',
  CONTRACT_SIGNATURE_DATE = 'DATA DE ASSINATURA DO CONTRATO',
  BILLING_MONTH = 'MÊS DE FATURA',
  PAYMENT_DATE = 'DATA DE PAGAMENTO',
  INVOICE_SERVICE_CODE = 'CÓDIGO DE SERVIÇO',
  STUDENT_REPORT_CARD = 'BOLETIM',
}
