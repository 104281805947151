import { CondOperator } from '@nestjsx/crud-request';
import { nextYear } from 'pages/Client/NewRegistration/utils';
import {
  FUNDAMENTAL,
  FUNDAMENTAL_INITIALS,
  MEDIUM,
  MEDIUM_INITIALS,
} from 'texts';
import { CONTRACT_STATUS } from 'types/contractInfo';
import { PAYMENT_STATUS, SCHOOL_GRADES } from 'types/enums';

export const filterOptions = {
  status: { filterTitle: 'Status', value: undefined },
  schoolYear: { filterTitle: 'Ano', value: undefined },
  'dependent.name': { filterTitle: 'Aluno', value: undefined },
  'schoolClass.name': {
    filterTitle: 'Turma/Série',
    value: undefined,
  },
};

export const searchObject = [
  {
    field: 'dependent.name',
    operator: CondOperator.CONTAINS_LOW,
  },
];

export const formatGrade = (grade: string) => {
  if (grade.includes(MEDIUM)) return grade.replace(MEDIUM, MEDIUM_INITIALS);
  if (grade.includes(FUNDAMENTAL))
    return grade.replace(FUNDAMENTAL, FUNDAMENTAL_INITIALS);
  return grade;
};

// Gera um array a partir do ano 2021 até o ano após o atual.
export const INITIAL_YEAR = 2021;
const length = nextYear - INITIAL_YEAR;
export const YEARS_ARRAY = Array.from(Array(length + 1).keys()).map(
  (_, index) => INITIAL_YEAR + index,
);

export const SCHOOL_GRADES_ARRAY: {
  key: string;
  value: string;
}[] = Object.entries(SCHOOL_GRADES).map(([key, value]) => ({ key, value }));
