import { Table, TableCell, styled } from '@material-ui/core';
import { lightGray } from 'styles/theme';

export const StyledTableCell = styled(TableCell)(() => ({
  background: lightGray,
  padding: 0,
}));

export const StyledSubTable = styled(Table)(() => ({
  tableLayout: 'auto',
}));

export const Hairline = styled('div')(() => ({
  borderBottom: `1px solid lightgray`,
  margin: '0px 17px',
}));
