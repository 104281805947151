import { Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import useGlobalStyles from 'styles';
import { PaymentSummaryProperties } from '../../types';
import { usePaymentSummaryController } from './usePaymentSummaryController';

const PaymentSummary: React.FC<PaymentSummaryProperties> = ({
  items,
  isAdmin,
  canceledBy,
  payment,
}) => {
  const classes = useGlobalStyles();
  const { enrollmentReference, paymentOriginLink } =
    usePaymentSummaryController({
      payment,
    });

  return (
    <Paper elevation={0} className={classes.listScreenPaper}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={3}
      >
        {items.map(item => (
          <Grid xs={12} md={4} item key={item.title}>
            <Typography className={classes.confirmScreenText1}>
              <b>{item.title}</b>
            </Typography>
            <Typography className={classes.confirmScreenText1}>
              {item.value}
            </Typography>
          </Grid>
        ))}
        {isAdmin ? (
          <Grid xs={12} md={4} item key="payment-origin">
            <Typography className={classes.confirmScreenText1}>
              <b>Origem do pagamento</b>
            </Typography>
            <Typography className={classes.confirmScreenText1}>
              <Link to={paymentOriginLink}>{enrollmentReference}</Link>
            </Typography>
          </Grid>
        ) : null}
        {isAdmin && canceledBy ? (
          <Grid xs={12} md={4} item>
            <Typography className={classes.confirmScreenText1}>
              <b>Cancelado por</b>
            </Typography>
            <Typography className={classes.confirmScreenText1}>
              {canceledBy}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
};

export default PaymentSummary;
