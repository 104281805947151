import { BILL_STATUS, PAYMENT_STATUS } from 'types/enums';

export const CANCELABLE_STATUSES = [
  PAYMENT_STATUS.OPEN,
  PAYMENT_STATUS.PROCESSING,
  PAYMENT_STATUS.DEFAULTING,
  PAYMENT_STATUS.CONCLUDED,
  PAYMENT_STATUS.FUTURE,
];

export const BILL_CANCELABLE_STATUSES = [
  BILL_STATUS.BILLED,
  BILL_STATUS.AWAITING_PAYMENT,
  BILL_STATUS.DEFAULTING,
  BILL_STATUS.REJECTED,
];
