import { useMemo } from 'react';
import { TABLE_HEADERS } from 'utils/tableHeaders';

export const useFinancialReportTableController = () => {
  const tableHeaders = useMemo(
    () => [
      TABLE_HEADERS.YEAR,
      TABLE_HEADERS.DEPENDENT,
      TABLE_HEADERS.SERVICE,
      TABLE_HEADERS.PAYMENT_METHOD,
      TABLE_HEADERS.PAYMENT_STATUS,
      TABLE_HEADERS.TOTAL_VALUE,
    ],
    [],
  );

  return {
    tableHeaders,
  };
};
