import { SelectProps } from '@material-ui/core';
import { SConditionAND, SFields } from '@nestjsx/crud-request';
import { IServiceClassResponse } from 'pages/Admin/Marketplace/ServiceClasses/components/ServiceClassForm/types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ServiceClassService from 'services/serviceClassesService';
import useGlobalStyles from 'styles';
import { black, error } from 'styles/theme';
import { SCHOOL_GRADES } from 'types/enums';
import { useDebouncedCallback } from 'use-debounce';
import { TABLE_HEADERS } from 'utils/tableHeaders';

const headers = [
  TABLE_HEADERS.YEAR_OR_GRADE,
  TABLE_HEADERS.CLASS,
  TABLE_HEADERS.SPOTS,
];

const rowsPerPageOptions = [5, 10, 25];

export const useServiceClassesDataTableController = (serviceId: number) => {
  // Styles
  const classes = useGlobalStyles();
  const fontColorBasedOnAvailableSpots = useMemo(
    () =>
      (remainingSpots = 0) => {
        if (remainingSpots === 0) return error;

        return black;
      },
    [],
  );

  // Routes
  const history = useHistory();
  const goToServiceClassDetail = (serviceClassId: number) => {
    history.push(
      `/adm-marketplace/services/${serviceId}/service-class/${serviceClassId}/info`,
    );
  };
  const goToServiceClassUpdate = (serviceClassId: number) => {
    history.push(
      `/adm-marketplace/services/${serviceId}/service-class/${serviceClassId}`,
    );
  };

  // Table data
  const [serviceClasses, setServiceClasses] = useState<IServiceClassResponse[]>(
    [],
  );
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filters
  const schoolGradeOptions = useMemo(() => Object.values(SCHOOL_GRADES), []);
  const [selectedSchoolGradeOption, setSelectedSchoolGradeOption] = useState<
    string | undefined
  >();

  const selectProps: Partial<SelectProps> = {
    MenuProps: {
      disableScrollLock: true,
      style: { maxHeight: 285 },
    },
  };

  const fetchData = useCallback(async () => {
    if (serviceId) {
      const searchConditions: (SFields | SConditionAND)[] = [
        {
          serviceId: {
            $eq: serviceId,
          },
        },
      ];

      if (selectedSchoolGradeOption) {
        searchConditions.push({
          'schoolClasses.name': {
            $eq: selectedSchoolGradeOption,
          },
        });
      }

      const { data: response } = await ServiceClassService.getServiceClasses({
        page: page + 1,
        limit: rowsPerPage,
        join: [['schoolClasses']],
        search: {
          $and: searchConditions,
        },
      });

      setServiceClasses(response?.data || []);
      setTotal(response?.total || 0);
    }
  }, [serviceId, selectedSchoolGradeOption, page, rowsPerPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChangeYearOrGrade = useDebouncedCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setSelectedSchoolGradeOption(e.target.value),
    300,
  );

  return {
    classes,
    headers,
    fontColorBasedOnAvailableSpots,
    goToServiceClassDetail,
    goToServiceClassUpdate,
    total,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    serviceClasses,
    selectProps,
    handleChangePage,
    handleChangeRowsPerPage,
    schoolGradeOptions,
    handleChangeYearOrGrade,
  };
};
