import { TableColumnConfig } from 'components/Table/types';
import { formatBRL } from 'utils';
import { InvoiceEntity } from 'utils/entities/InvoiceEntity';
import {
  formatDateTimePtBr,
  formatMonthYearDatePtBr,
} from 'utils/formatDatePtBr';
import { renderInvoiceRowActions } from './components/tableComponents';

export const INVOICE_TABLE_HEADERS = {
  FINANCIAL_GUARDIAN: 'Responsável Financeiro',
  DEPENDENT: 'Dependente',
  SOURCE: 'Serviço',
  VALUE: 'Valor Líquido',
  NF_NUMBER: 'Nº NF',
  COMPETENCE: 'Competência',
  STATUS: 'Status',
  ISSUED_AT: 'Emissão',
  ACTIONS: 'Ações',
};

// FIXME: revert to being a constant
export const invoiceTableConfig = (
  isDeveloper?: boolean,
): TableColumnConfig<InvoiceEntity>[] => {
  const config: TableColumnConfig<InvoiceEntity>[] = [
    {
      headerName: INVOICE_TABLE_HEADERS.FINANCIAL_GUARDIAN,
      getter: 'financialGuardian.user.name',
      defaultValue: '-',
    },
    {
      headerName: INVOICE_TABLE_HEADERS.DEPENDENT,
      getter: 'dependent.name',
      defaultValue: '-',
    },
    {
      headerName: INVOICE_TABLE_HEADERS.SOURCE,
      getter: 'source',
      onFormat(_, invoiceData) {
        return invoiceData.service?.name || 'Ano letivo';
      },
      defaultValue: '-',
    },
    {
      headerName: INVOICE_TABLE_HEADERS.COMPETENCE,
      getter: 'competenceDate',
      onFormat: formatMonthYearDatePtBr,
      defaultValue: '-',
    },
    {
      headerName: INVOICE_TABLE_HEADERS.STATUS,
      getter: 'internalStatus',
      defaultValue: '-',
    },
    {
      headerName: INVOICE_TABLE_HEADERS.NF_NUMBER,
      getter: 'number',
      defaultValue: '-',
    },
    {
      headerName: INVOICE_TABLE_HEADERS.VALUE,
      getter: 'serviceAmountWithDiscount',
      onFormat: formatBRL,
      defaultValue: '-',
    },
    {
      headerName: INVOICE_TABLE_HEADERS.ISSUED_AT,
      getter: 'issuedDate',
      onFormat: formatDateTimePtBr,
      defaultValue: '-',
    },
  ];

  if (isDeveloper) {
    config.push({
      headerName: INVOICE_TABLE_HEADERS.ACTIONS,
      getter: 'issuedDate',
      customRender: renderInvoiceRowActions,
      defaultValue: '-',
    });
  }

  return config;
};
