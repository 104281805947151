import { Grid, Paper, Typography } from '@material-ui/core';
import useGlobalStyles from 'styles';
import { Registration } from 'types/registration';
import { formatBRL } from 'utils';
import { useStyles } from '../styles';

type PaymentHeaderProps = {
  totalValue: number;
  registration: Registration;
};

const PaymentHeader = ({ totalValue, registration }: PaymentHeaderProps) => {
  const classes = useGlobalStyles();
  const styles = useStyles();

  return (
    <Paper elevation={0} className={styles.paper}>
      <Grid container alignItems="center">
        <Grid item className={styles.headerField} style={{ marginRight: 100 }}>
          <Typography className={classes.paymentScreenTitle1}>
            ANO LETIVO {registration?.schoolYear}
          </Typography>
          <Typography className={classes.paymentScreenTitle2}>
            {registration?.schoolGrade}
          </Typography>
        </Grid>
        {/* <Divider
            className={classes.paymentScreenLightDivider}
            orientation="vertical"
            flexItem
            light
          /> */}
        <Grid item className={styles.headerField}>
          <Typography className={classes.paymentScreenTitle1}>
            VALOR TOTAL
          </Typography>
          <Typography className={classes.paymentScreenTitle2}>
            {formatBRL(totalValue)}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PaymentHeader;
