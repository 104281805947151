import {
  Box,
  ButtonBase,
  Checkbox,
  Divider,
  FormControlLabel,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import TabLabel from 'components/TabLabel/TabLabel';
import { useState } from 'react';
import useGlobalStyles from 'styles';
import { darkGray, infoMain } from 'styles/theme';
import { CLAUSE_LIST } from 'texts';
import SuccessPaper from './SuccessPaper';
import TabPage from './TabPage';
import { ContractClausesProps } from './types';

function tabId(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

// FIXME: when width is close to mobile, the text column gets too slim
export const DesktopContractClauses = ({
  setIsContractClausesAccepted,
  isContractClausesAccepted,
}: ContractClausesProps) => {
  const classes = useGlobalStyles();
  const [value, setValue] = useState(0);
  const [checks, setChecks] = useState(Array(CLAUSE_LIST.length).fill(false));

  const handleNext = () => {
    if (value === checks.length - 1) {
      setIsContractClausesAccepted(true);
    } else {
      setValue(value + 1);
    }
  };
  const handlePrevious = () => {
    setValue(value - 1);
  };

  const handleCheck = (index: number, value: boolean) => {
    const aux = checks;
    aux[index] = value;
    setChecks([...aux]);
  };

  if (isContractClausesAccepted) {
    return <SuccessPaper />;
  }

  return (
    <Paper elevation={0} className={classes.listScreenPaper}>
      <Box display="flex" flexDirection="row">
        <Box className={classes.contractScreenTabs}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
          >
            {checks.map((item, index) => (
              <Tab
                disableRipple
                style={{ cursor: 'default' }}
                key={index}
                label={
                  <TabLabel label={`Área 0${index + 1}`} index={index + 1} />
                }
                {...tabId(index)}
              />
            ))}
          </Tabs>
        </Box>
        <Box flexGrow={1}>
          {checks.map((_item, index) => (
            <TabPage key={index} value={value} index={index} />
          ))}
          <Divider
            style={{
              width: '89%',
              marginLeft: 'auto',
              marginRight: 'auto',
              backgroundColor: '#DCDCDC',
              marginTop: -20,
              marginBottom: 20,
            }}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexGrow={1}
          >
            <Box>
              <FormControlLabel
                control={<Checkbox checked={checks[value]} color="secondary" />}
                value={checks[value]}
                onChange={() => handleCheck(value, !checks[value])}
                label="Li e aceito os termos"
                style={{ marginLeft: 15 }}
              />
            </Box>
            <Box display="flex">
              <Typography style={{ marginRight: 20 }}>
                {value + 1} de {checks.length}
              </Typography>
              <ButtonBase
                disabled={value === 0}
                onClick={() => handlePrevious()}
                style={{
                  color: value === 0 ? infoMain : darkGray,
                  marginRight: 20,
                }}
              >
                <ChevronLeft />
              </ButtonBase>
              <ButtonBase
                disabled={!checks[value]}
                style={{ color: !checks[value] ? infoMain : darkGray }}
                onClick={() => handleNext()}
                color="primary"
                className={classes.textButton}
              >
                <ChevronRight />
              </ButtonBase>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};
