import { Grid, makeStyles, styled, Typography } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { darkGray, mediumGray, primaryDark, purple } from 'styles/theme';

export const StyledBeforeIcon = styled(NavigateBeforeIcon)(() => ({
  color: primaryDark,
  cursor: 'pointer',
  '&:hover': {
    color: purple,
  },
}));

export const StyledNextIcon = styled(NavigateNextIcon)(() => ({
  color: primaryDark,
  cursor: 'pointer',
  '&:hover': {
    color: purple,
  },
}));

export const StyledCalendarDate = styled(Typography)(() => ({
  color: darkGray,
  fontSize: '25px',
  lineHeight: '25px',
  fontWeight: 700,
  marginLeft: '8px',
}));

export const StyledGrid = styled(Grid)(() => ({
  marginBottom: '8px',
}));

export const useStyles = makeStyles(() => ({
  containerTime: {
    padding: '8px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  containerTimeTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  containerTitle: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  containerDetailBody: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '8px',
  },
  title: {
    fontSize: '14px',
    lineHeight: '19px',
    color: mediumGray,
    fontWeight: 600,
  },
  raw: {
    color: primaryDark,
    marginLeft: '16px',
    height: '16px',
    width: '16px',
  },
  body: {
    fontSize: '14px',
    lineHeight: '19px',
    color: darkGray,
    fontWeight: 700,
    marginTop: '16px',
  },
  time: {
    fontSize: '12px',
    lineHeight: '16px',
    color: mediumGray,
    fontWeight: 400,
  },
  divider: {
    width: '99%',
    margin: '5px 0px',
  },
  lineHeight: {
    lineHeight: '8px',
  },
  editIcon: {
    color: primaryDark,
    marginRight: '16px',
    height: '16px',
    width: '16px',
    cursor: 'pointer',
  },
  containerLink: {
    display: 'flex',
    alignItems: 'center',
  },
  seeDetailText: {
    color: primaryDark,
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,
  },
  nextIcon: {
    color: primaryDark,
    marginLeft: '8px',
    height: '16px',
    width: '16px',
  },
}));
