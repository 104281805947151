import React from 'react';
import { USER_ROLES } from 'types/enums';
import { User } from 'types/user';

type Props = {
  children: React.ReactNode;
  roles: Array<USER_ROLES>;
  user: User | null;
};

const hasPermission = (roles: USER_ROLES[], user: User | null) => {
  if (user) {
    return roles.some(role => user.roles.includes(role));
  }
};

const Permission: React.FC<Props> = ({ children, roles, user }) => {
  if (hasPermission(roles, user)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  return null;
};

export default Permission;
