// LocalStorageKeys should be a composition of all other keys
// type LocalStorageKeys = MarketplaceHomeStorageKeys | AnotherPageStorageKeys | ...
export type LocalStorageKeys =
  | HooksStoredDataKeys
  | MarketplaceHomeStorageKeys
  | MarketplaceSearchResultsStorageKeys;

// Make sure each key inside this file is unique

export enum HooksStoredDataKeys {
  REGISTRATIONS = 'marketplace-home-registrations',
  SERVICE_TYPES = 'marketplace-home-serviceTypes',
}

export enum MarketplaceHomeStorageKeys {
  QUERY_PARAMS = 'marketplace-home-queryParams',
  // SELECTED_REGISTRATION = 'marketplace-home-selectedRegistration',
}

export enum MarketplaceSearchResultsStorageKeys {
  QUERY_PARAMS = 'marketplace-search-results-queryParams',
}
