import { MyTable } from 'components/Table';
import { ActionsColumn } from 'components/Table/components/ActionsColumn';
import { GenericDialogRef } from 'components/dialogs/GenericDigalog/types';
import { useConfigurations } from 'hooks/useConfigurations';
import { useRef } from 'react';
import { useAppStore } from 'store';
import { Configurations } from 'types/configurations';
import { RegistrationPeriodModal } from './RegistrationPeriodModal';

export const RegistrationPeriodTableConfig = () => {
  // Move to src/App.tsx
  useConfigurations();
  const configurations = useAppStore(state => state.configurations.data);
  const registrationPeriodFormRef = useRef<GenericDialogRef>(null);

  const handleOpenDialog = () => {
    registrationPeriodFormRef.current?.openDialog();
  };

  if (!configurations) return <b>Configuração do sistema não encontrada</b>;

  return (
    <>
      <MyTable<Configurations>
        tableHeader={{
          rawTitle: (
            <>
              Período de <b>matrícula</b>
            </>
          ),
          rawSubtitle: 'Defina o período de matrícula',
        }}
        emptyTableMessage="Não há configurações cadastradas"
        data={[configurations]}
        tableConfig={[
          {
            headerName: 'INÍCIO',
            getter: 'periodStart',
            defaultValue: 'Não definido',
          },
          {
            headerName: 'FIM',
            getter: 'periodEnd',
            defaultValue: 'Não definido',
          },
          {
            headerName: 'ANO DE MATRÍCULA',
            getter: 'enrollmentYear',
            defaultValue: 'Não definido',
          },
          {
            headerName: 'EDITAR',
            getter: 'id',
            align: 'right',
            customRender() {
              return (
                <ActionsColumn
                  onEdit={{
                    onClick: handleOpenDialog,
                  }}
                />
              );
            },
          },
        ]}
      />
      <RegistrationPeriodModal
        registrationPeriodFormRef={registrationPeriodFormRef}
      />
    </>
  );
};
