import AlertCard from 'components/AlertCard';
import { createContext } from 'react';

import { ICartContext, ICartContextProviderProps } from './types';
import { useCartController } from './useCartController';

export const CartContext = createContext<ICartContext>({} as ICartContext);

export const CartContextProvider = ({
  children,
}: ICartContextProviderProps) => {
  const {
    cartItems,
    totalItemsCart,
    alertMessage,
    alertType,
    isShowAlert,
    totalCartValue,
    handleRemoveServiceFromCart,
    closeAlert,
    actualDependent,
    cartItemAlreadyAdded,
    actualCartItem,
    subscriptions,
    setSubscriptions,
    handleResetCartContextData,
    handleResetCartItems,
    handleAddServiceToCart,
    getServiceFromCart,
    isSubscriptionInputCorrect,
    handleChangeSubscriptionData,
  } = useCartController();

  return (
    <CartContext.Provider
      // FIXME: KKKK foda
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        handleAddServiceToCart,
        getServiceFromCart,
        cartItems,
        totalCartValue,
        totalItemsCart,
        actualDependent,
        handleRemoveServiceFromCart,
        cartItemAlreadyAdded,
        actualCartItem,
        subscriptions,
        setSubscriptions,
        handleResetCartContextData,
        handleResetCartItems,
        isSubscriptionInputCorrect,
        handleChangeSubscriptionData,
      }}
    >
      <AlertCard
        message={alertMessage}
        open={isShowAlert}
        severity={alertType}
        close={closeAlert}
      />
      {children}
    </CartContext.Provider>
  );
};
