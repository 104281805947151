import { InputAdornment } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import { CustomButton } from 'components/CustomButton';
import { CustomDatePicker } from 'components/CustomDatePicker';
import { SelectInput } from 'components/Forms/SelectInput/SelectInput';
import Scaffold from 'components/Scaffold';
import { ROWS_PER_PAGE } from 'utils';
import { FinancialReportTable } from './FinancialReportTable';
import { ExportFinancialReportButton } from './components/ExportFinancialReportButton';
import { FILTER_OPTIONS } from './consts';
import { CenteredGrid, StyledTextField, useStyles } from './styles';
import { DateFilterKey, FilterKey } from './types';
import { useFinancialReportController } from './useFinancialReportController';

export const FinancialReport = () => {
  const {
    payments,
    isLoading,
    total,
    page,
    rows,
    handleOnPageChange,
    handleOnRowsPerPageChange,
    searchText,
    handleUpdateFilter,
    selectedYears,
    selectedSchoolGrades,
    selectedPaymentStatuses,
    selectedContractStatuses,
    startDate,
    endDate,
    handleUpdateDate,
    handleResetDateInterval,
    isLoadingCsv,
    getFinantialReportCsv,
    isAdmin,
    isDeveloper,
  } = useFinancialReportController();
  const styles = useStyles();

  return (
    <Scaffold
      rawTitle={<b>Financeiro</b>}
      rawSubtitle="Veja o detalhamento dos pagamentos"
      headerButton={
        <ExportFinancialReportButton
          isAdmin={isAdmin}
          loading={isLoadingCsv}
          onClick={getFinantialReportCsv}
        />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={6} xl={4}>
          <StyledTextField
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchText}
            onChange={e => handleUpdateFilter(FilterKey.SEARCH_TEXT, e)}
            placeholder="Buscar por aluno, matrícula, serviço..."
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <SelectInput
            name="referenceYear"
            label="Ano da matrícula"
            value={selectedYears}
            options={FILTER_OPTIONS.YEARS}
            onChange={e => handleUpdateFilter(FilterKey.REFERENCE_YEARS, e)}
            isMultiple
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <SelectInput
            name="schoolGrade"
            label="Ano/Série"
            value={selectedSchoolGrades}
            options={FILTER_OPTIONS.SCHOOL_GRADES}
            onChange={e => handleUpdateFilter(FilterKey.SCHOOL_GRADES, e)}
            isMultiple
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <SelectInput
            name="paymentStatus"
            label="Status de pagamento"
            value={selectedPaymentStatuses}
            options={FILTER_OPTIONS.PAYMENT_STATUSES({ isAdmin, isDeveloper })}
            onChange={e => handleUpdateFilter(FilterKey.PAYMENT_STATUSES, e)}
            isMultiple
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <SelectInput
            name="contractStatus"
            label="Status do contrato"
            value={selectedContractStatuses}
            options={FILTER_OPTIONS.CONTRACT_STATUSES}
            onChange={e => handleUpdateFilter(FilterKey.CONTRACT_STATUSES, e)}
            isMultiple
          />
        </Grid>

        <Grid item xs={6} sm={4} md={3} lg={2}>
          <CustomDatePicker
            label="Período - Início"
            value={startDate}
            maxDate={endDate}
            onChange={date => handleUpdateDate(DateFilterKey.START_DATE, date)}
          />
        </Grid>

        <Grid item xs={6} sm={4} md={3} lg={2}>
          <CustomDatePicker
            label="Período - Fim"
            value={endDate}
            minDate={startDate}
            onChange={date => handleUpdateDate(DateFilterKey.END_DATE, date)}
          />
        </Grid>

        <CenteredGrid item xs={4} md={3} lg={2}>
          <CustomButton
            onClick={handleResetDateInterval}
            variant="secondary"
            textVariant="bold"
            className={styles.resetButton}
          >
            Resetar período
          </CustomButton>
        </CenteredGrid>
      </Grid>

      <FinancialReportTable payments={payments} isLoading={isLoading} />

      <TablePagination
        component="div"
        count={total}
        page={page}
        labelRowsPerPage="Itens por página"
        onPageChange={handleOnPageChange}
        rowsPerPage={rows}
        onRowsPerPageChange={handleOnRowsPerPageChange}
        rowsPerPageOptions={ROWS_PER_PAGE}
      />
    </Scaffold>
  );
};
