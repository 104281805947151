import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import useGlobalStyles from 'styles';
import { PAYMENT_METHODS } from 'types/enums';
import { formatDatePtBr } from 'utils/formatDatePtBr';
import { getDecemberBillingDate, translatePaymentMethod } from '../utils';
import { useStyles } from './styles';
import { SummaryProperties } from './types';

const PaymentSummary: React.FC<SummaryProperties> = ({
  registration,
  installments,
  paymentMethod,
  couponName,
}) => {
  const classes = useGlobalStyles();
  const styles = useStyles();

  const getFirstBillInfo = () => {
    if (installments === 1) {
      return 'Pagamento à vista';
    }
    const currentYear = new Date().getFullYear();
    if (
      registration.schoolYear > currentYear &&
      paymentMethod === PAYMENT_METHODS.CARD
    ) {
      return getDecemberBillingDate(registration.schoolYear - 1);
    }

    return formatDatePtBr(new Date());
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      spacing={3}
      className={styles.container}
    >
      <Grid item className={styles.item}>
        <Typography className={classes.confirmScreenText1}>
          <b>Aluno</b>
        </Typography>
        <Typography className={classes.confirmScreenText1}>
          {registration.dependent.name}
        </Typography>
      </Grid>
      <Grid item className={styles.item}>
        <Typography className={classes.confirmScreenText1}>
          <b>Ano letivo {registration.schoolYear}</b>
        </Typography>
        <Typography className={classes.confirmScreenText1}>
          {registration.schoolClass.name}
        </Typography>
      </Grid>
      <Grid item className={styles.item}>
        <Typography className={classes.confirmScreenText1}>
          <b>Forma de pagamento</b>
        </Typography>
        <Typography className={classes.confirmScreenText1}>
          {translatePaymentMethod(paymentMethod)}
        </Typography>
      </Grid>
      {couponName ? (
        <Grid item className={styles.item}>
          <Typography className={classes.confirmScreenText1}>
            <b>Cupom aplicado</b>
          </Typography>
          <Typography className={classes.confirmScreenText1}>
            {couponName}
          </Typography>
        </Grid>
      ) : null}
      <Grid item className={styles.item}>
        <Typography className={classes.confirmScreenText1}>
          <b>Primeira parcela</b>
        </Typography>
        <Typography className={classes.confirmScreenText1}>
          {getFirstBillInfo()}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PaymentSummary;
