export const INCORRECT_LOGIN = 'Email ou senha incorretos.';
export const INCORRECT_PASSWORD = 'Senha incorreta.';
export const EMAIL_REQUIRED = 'Informe seu email.';
export const EXISTING_EMAIL = 'Email já cadastrado.';
export const PASSWORD_REQUIRED = 'Informe sua senha.';
export const DIFFERENT_EMAIL = 'Os emails devem ser iguais.';
export const DIFFERENT_PASSWORD = 'As senhas devem ser iguais.';
export const WEAK_PASSWORD =
  'A senha deve ter no mínimo: 8 caracteres, 1 letra maíscula, 1 número e 1 caracter especial.';
export const NOT_VERIFIED = (email: string) =>
  `Este endereço de email ainda não foi confirmado. Um email de confirmação foi enviado para ${email}.`;
export const EMAIL_FAILED =
  'Houve uma falha no envio do e-mail de confirmação. Tente fazer login e iremos enviá-lo novamente.';
export const COMPLETION_SUCCESSFUL = 'Seus dados estão completos!';
export const EDITING_SUCCESSFUL = 'Seus dados foram editados com sucesso';
