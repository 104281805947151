import { useHistory } from 'react-router-dom';
import { ADMIN_ROUTE } from 'routes/consts';

export const useMarketplaceHomeController = () => {
  // Navigation
  const history = useHistory();

  // Callbacks
  const goToServicesManagement = () => {
    history.push(ADMIN_ROUTE.MARKETPLACE_SERVICES);
  };
  const goToPartnersManagement = () => {
    history.push(ADMIN_ROUTE.MARKETPLACE_PARTNERS);
  };
  const goToAccessReports = () => {
    history.push(ADMIN_ROUTE.MARKETPLACE_REPORTS);
  };
  const goToContractsManagement = () => {
    history.push(ADMIN_ROUTE.CONTRACT_TEMPLATES);
  };

  return {
    goToServicesManagement,
    goToPartnersManagement,
    goToAccessReports,
    goToContractsManagement,
  };
};
