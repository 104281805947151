import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { CheckCircleOutlineOutlined } from '@material-ui/icons';
import AddressForm from 'components/AddressForm';
import AlertCard from 'components/AlertCard';
import ChangePasswordModal from 'components/ChangePasswordModal';
import EditableFormContainer from 'components/EditableFormContainer';
import GuardianForm from 'components/GuardianForm';
import Modal from 'components/Modal';
import PageFooter from 'components/PageFooter';
import PageHeader from 'components/PageHeader';
import SpinnerButton from 'components/SpinnerButton';
import UserForm from 'components/UserForm';
import { useAuth } from 'contexts/auth';
import { useSearchCEP } from 'hooks/searchCEP';
import { StatusCodes } from 'http-status-codes';
import { omit, pick } from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import UserService from 'services/userService';
import useGlobalStyles from 'styles';
import { CHECK_IS_LEGAL_GUARDIAN, EDITING_SUCCESSFUL } from 'texts';
import { CIVIL_STATUS, DEPENDENT_RELATIONSHIP } from 'types/enums';
import { FinancialGuardian } from 'types/financialGuardian';
import { User } from 'types/user';
import { allToUpperCase } from '../NewRegistration/utils';
import { useStyles } from './styles';
import { ISubmitUser } from './types';
import { userFields, validationSchema } from './utils';

const Profile: React.FC = () => {
  const classes = useGlobalStyles();
  const styles = useStyles();
  const { user, getUser } = useAuth();
  const isAdmin = user?.isAdmin;
  const isCompleted = isAdmin ? true : !!user?.financialGuardian.isCompleted;
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [modal, setModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [editing, setEditing] = useState(!isCompleted);
  const [civilStatus, setCivilStatus] = useState<CIVIL_STATUS | undefined>(
    user?.financialGuardian?.civilStatus,
  );
  const [relationship, setRelationship] = useState<
    DEPENDENT_RELATIONSHIP | undefined
  >(user?.financialGuardian?.relationship);
  const [isChecked, setIsChecked] = useState(false);

  const { handleSubmit, errors, register, control, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onBlur',
  });

  const {
    address,
    debouncedSearchCEP,
    cepError,
    cepErrorCount,
    clearCepError,
  } = useSearchCEP(user?.financialGuardian?.address);

  useEffect(() => {
    if (user) {
      register('isAdmin');
      register('isCompleted');
      setValue('isAdmin', isAdmin);
      setValue('isCompleted', isCompleted);
      if (!isAdmin) register('civilStatus');
      if (!isAdmin) register('relationship');
    }
  }, [user, isAdmin, register, setValue, isCompleted]);

  async function onSubmit(data: ISubmitUser) {
    if (user) {
      data = allToUpperCase(data) as ISubmitUser;
      data.relationship = relationship;
      data.civilStatus = civilStatus;
      data.address = { ...data.address, ...address };

      const userData = pick(data, userFields) as Partial<User>;
      const guardianData = omit(data, [
        ...userFields,
        'isAdmin',
        'isCompleted',
      ]) as Partial<FinancialGuardian>;

      updateUser(userData, !user.isAdmin ? guardianData : undefined);
    }
  }

  const updateUser = async (
    userData: Partial<ISubmitUser>,
    guardianData?: Partial<FinancialGuardian>,
  ) => {
    if (guardianData) guardianData.id = user?.financialGuardian?.id;
    if (user) {
      const response = await UserService.updateUserAndGuardian(
        user.id,
        userData,
        guardianData,
      );
      if (response.status === StatusCodes.OK) {
        getUser();
        setEditing(!editing);
        setModal(true);
      } else {
        setAlert(true);
        setAlertMessage(response.data.message);
      }
    }
  };

  const handleChangeCivilStatus = (status: string) => {
    setCivilStatus(status as CIVIL_STATUS);
    setValue('civilStatus', status);
  };

  const handleChangeRelationship = (status: string) => {
    setRelationship(status as DEPENDENT_RELATIONSHIP);
    setValue('relationship', status);
  };

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(!isChecked);
  };

  const handleCloseAlert = () => {
    setAlert(false);
    clearCepError();
  };

  return (
    <Grid
      className={styles.page}
      container
      direction="column"
      alignItems="flex-start"
    >
      {user ? (
        <ChangePasswordModal
          open={passwordModal}
          email={user.email}
          close={() => setPasswordModal(false)}
        />
      ) : null}
      <Modal
        icon={
          <CheckCircleOutlineOutlined
            color="secondary"
            className={classes.dialogIcon}
          />
        }
        open={modal}
        text={EDITING_SUCCESSFUL}
        btnText="Voltar"
        btnAction={() => setModal(false)}
      />
      <AlertCard
        message={alertMessage || cepError}
        open={alert || !!cepError}
        close={handleCloseAlert}
        severity="error"
      />
      <Grid container item direction="column">
        <PageHeader
          title="Editar perfil"
          subtitle="Edite seus dados"
          testId="edit-profile-header"
        />
        <Paper elevation={0} className={classes.screenPaper}>
          {user ? (
            <EditableFormContainer
              hidden={!isCompleted}
              title="Dados completos"
              editing={editing}
              edit={() => setEditing(true)}
              save={handleSubmit(onSubmit)}
            >
              {!isAdmin && isCompleted ? (
                <Typography
                  variant="body1"
                  className={styles.isCompletedMessage}
                >
                  <b>
                    Para editar seu cadastro, entre em contato com a secretaria
                    através do e-mail secretaria@jpsul.com.br
                  </b>
                </Typography>
              ) : null}
              {isCompleted ? null : (
                <>
                  <Typography className={classes.mainScreenText3}>
                    <b>Completar dados</b>
                  </Typography>
                  <Divider className={classes.divider1} />
                </>
              )}
              <UserForm
                user={user}
                register={register}
                control={control}
                errors={errors}
                editing={!isAdmin ? false : editing}
              />
              {isAdmin ? null : (
                <>
                  <GuardianForm
                    guardian={isCompleted ? user.financialGuardian : undefined}
                    civilStatus={civilStatus}
                    relationship={relationship}
                    changeCivilStatus={handleChangeCivilStatus}
                    changeRelationship={handleChangeRelationship}
                    register={register}
                    control={control}
                    errors={errors}
                    editing={!isAdmin && isCompleted ? false : editing}
                  />
                  <AddressForm
                    address={
                      isCompleted ? user.financialGuardian.address : undefined
                    }
                    searchAddress={address}
                    cepSearch={debouncedSearchCEP}
                    cepErrorCount={cepErrorCount}
                    register={register}
                    control={control}
                    errors={errors}
                    editing={editing}
                  />
                </>
              )}
            </EditableFormContainer>
          ) : null}
          {isCompleted ? null : (
            <Grid item className={styles.changePasswordContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    value={isChecked}
                    onChange={handleCheckbox}
                    color="secondary"
                  />
                }
                label={CHECK_IS_LEGAL_GUARDIAN}
              />
            </Grid>
          )}
        </Paper>

        {isCompleted ? (
          <>
            <Grid
              container
              item
              alignItems="center"
              justifyContent="flex-end"
              className={classes.verticalMargin}
            >
              <Grid item>
                <SpinnerButton
                  className={classes.newContractButton}
                  action={handleSubmit(onSubmit)}
                  text="Salvar alterações"
                  disabled={!editing}
                />
              </Grid>
            </Grid>
            <Paper elevation={0} className={classes.screenPaper}>
              <Typography className={classes.mainScreenText3}>
                <b>Segurança e acesso</b>
              </Typography>
              <Divider className={classes.divider1} />
              <Grid
                container
                direction="column"
                spacing={1}
                className={styles.changePasswordContainer}
              >
                <Grid item>
                  <Typography className={classes.mainScreenText3}>
                    <b>Alterar senha</b>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    Para alterar sua senha clique no link abaixo. Escolha uma
                    senha forte e de fácil memorização.
                  </Typography>
                </Grid>
                <Grid item className={styles.changePasswordBtn}>
                  <Button
                    onClick={() => setPasswordModal(true)}
                    className={classes.textButton}
                    color="primary"
                  >
                    Alterar senha
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </>
        ) : null}

        <PageFooter
          returnPath="/home"
          nextButton={
            isCompleted ? null : (
              <SpinnerButton
                text="Completar dados"
                className={classes.newContractButton}
                disabled={!isChecked}
                action={handleSubmit(onSubmit)}
              />
            )
          }
        />
      </Grid>
    </Grid>
  );
};

export default Profile;
