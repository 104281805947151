import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './utils';

export const useNewServiceCodesModalController = () => {
  // Refs
  const inputRefMenuDimensions = useRef<HTMLInputElement>(null);

  const { handleSubmit, errors, setError, register, control } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onBlur',
  });

  return {
    handleSubmit,
    errors,
    setError,
    register,
    control,
    inputRefMenuDimensions,
  };
};
