import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { QueryParamConfigMap, useQueryParams } from 'use-query-params';
import { CustomSearchFiltersProps, FiltersUpdatedCallback } from './types';

export const useCustomSearchFiltersController = ({
  onFiltersUpdated,
  filtersConfig,
}: CustomSearchFiltersProps) => {
  const [queryFilters, setQueryFilters] = useQueryParams(
    filtersConfig.reduce((acc, filter) => {
      // @ts-ignore
      acc[filter.name] = filter.queryParamConfig;
      return acc;
    }, {} as QueryParamConfigMap),
  );

  const debouncedUpdateFilters = useDebouncedCallback(
    (filterName, filterValue) => {
      setQueryFilters(oldState => ({
        ...oldState,
        [filterName]: filterValue,
      }));
    },
    1000,
  );

  const handleFiltersUpdated = useDebouncedCallback<FiltersUpdatedCallback>(
    filters => {
      onFiltersUpdated(filters);
    },
    1000,
  );

  useEffect(() => {
    handleFiltersUpdated(queryFilters);
  }, [queryFilters, handleFiltersUpdated]);

  return {
    filters: queryFilters,
    debouncedUpdateFilters,
    setFilters: setQueryFilters,
  };
};
