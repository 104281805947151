import { INVALID_DATE_MESSAGE } from './consts';
import { StyledDatePicker } from './styles';
import { CustomDatePickerProps } from './types';
import { useCustomDatePickerController } from './useCustomDatePickerController';
import { getMaxDateMessage, getMinDateMessage } from './utils';

export const CustomDatePicker = ({
  label,
  value,
  onChange,
  minDate,
  maxDate,
  name,
  defaultValue,
  format = 'dd/MM/yyyy',
}: CustomDatePickerProps) => {
  const { minimumDate, maximumDate } = useCustomDatePickerController({
    minDate,
    maxDate,
  });

  return (
    <StyledDatePicker
      disableToolbar
      variant="inline"
      format={format}
      inputVariant="outlined"
      label={label}
      name={name}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      minDate={minimumDate}
      maxDate={maximumDate}
      invalidDateMessage={INVALID_DATE_MESSAGE}
      minDateMessage={getMinDateMessage(minimumDate)}
      maxDateMessage={getMaxDateMessage(maximumDate)}
    />
  );
};
