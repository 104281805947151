import React from 'react';

import { Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import CurrencyField from 'components/CurrencyField';
import { mask } from 'remask';
import { CustomCurrencyInput, InputText } from '../../styles';
import { IGeneraFormProps } from '../../types';

export const MarketplaceAccessDataForm = ({
  control,
  register,
  errors,
  partnerToUpdate,
}: IGeneraFormProps) => {
  return (
    <Grid container spacing={2} md={11}>
      {/* //FIXME
        Comentando pra ficar coerente com figma. Faz parte da funcionalidade
        de Fluxo de solicatacao de turmas que vai entrar mais pra frente */}
      {/* <Grid item md={4}>
        <Controller
          name="accessCode"
          control={control}
          defaultValue={partnerToUpdate?.accessCode?.accessCode}
          as={({ value, onChange }) => (
            <InputText
              id="accessCode"
              name="accessCode"
              label="Código de convite"
              variant="outlined"
              value={value}
              defaultValue={value}
              onChange={onChange}
              error={!!errors.accessCode}
              helperText={errors.accessCode ? errors.accessCode?.message : null}
              inputRef={register}
            />
          )}
        />
      </Grid> */}
      <Grid item md={4}>
        <Controller
          name="tax"
          control={control}
          defaultValue={
            partnerToUpdate?.feeToBeCharged
              ? `${partnerToUpdate?.feeToBeCharged}%`
              : ''
          }
          as={({ value, onChange }) => (
            <InputText
              id="tax"
              name="tax"
              label="Taxa a cobrar"
              variant="outlined"
              value={value}
              onChange={({ target }) => {
                onChange(mask(target.value, ['9%', '99%']));
              }}
              error={!!errors.tax}
              helperText={errors.tax ? errors.tax?.message : null}
              inputRef={register}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
