import { Dialog, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { CustomButton } from 'components/CustomButton';
import React, { forwardRef, useImperativeHandle } from 'react';
import { useStyles } from './styles';
import { GenericDialogProps, GenericDialogRef } from './types';

export const GenericDialog = forwardRef<GenericDialogRef, GenericDialogProps>(
  (props, ref) => {
    const {
      primaryButton,
      isVisibleByDefault,
      secondaryButton,
      children,
      alignActions = 'center',
    } = props;
    const classes = useStyles();
    const [isDialogVisible, setIsDialogVisible] = React.useState(
      !!isVisibleByDefault,
    );
    const [areActionsDisabled, setAreActionsDisabled] = React.useState(false);

    useImperativeHandle(ref, () => ({
      openDialog() {
        setIsDialogVisible(true);
      },
      closeDialog() {
        setIsDialogVisible(false);
      },
      disableDialogActions(disable: boolean) {
        setAreActionsDisabled(disable);
      },
    }));

    return (
      <Dialog
        open={isDialogVisible}
        PaperProps={{
          className: classes.dialog,
        }}
      >
        {children}

        {primaryButton || secondaryButton ? (
          <Grid
            item
            className={clsx(
              classes.dialogActions,
              alignActions === 'center'
                ? classes.justifyAtCenter
                : classes.justifyAtEnd,
            )}
          >
            {secondaryButton ? (
              <CustomButton
                {...secondaryButton}
                variant="secondary"
                disabled={areActionsDisabled || secondaryButton.disabled}
              />
            ) : null}
            {primaryButton ? (
              <CustomButton
                {...primaryButton}
                variant="primary"
                disabled={areActionsDisabled || primaryButton.disabled}
              />
            ) : null}
            {/* TODO: variant: danger (CustomButton) */}
          </Grid>
        ) : null}
      </Dialog>
    );
  },
);
