import { CondOperator } from '@nestjsx/crud-request';
import { useAlert } from 'hooks/Alert';
import { MESSAGE_TYPE } from 'hooks/Alert/types';
import { StatusCodes } from 'http-status-codes';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ServicesCodes from 'services/serviceCodes';
import ServicesService from 'services/servicesService';
import { LOAD_DATA_ERROR } from 'texts';
import { LOAD_SERVICES_CODES_ERROR } from 'texts/messages/services';
import { RouteParams } from 'types/routeParams';
import { IServiceResponse } from '../components/ServiceForm/types';

export const useServiceDetailController = () => {
  const { id } = useParams<RouteParams>();
  const history = useHistory();

  // States
  const [service, setService] = useState<IServiceResponse>(
    {} as IServiceResponse,
  );
  const [totalServiceCodesUsed, setTotalServiceCodesUsed] = useState(0);
  const [totalServiceCodes, setTotalServiceCodes] = useState(0);
  const [loading, setLoading] = useState(false);

  // Callbacks
  const getServiceDetails = useCallback(async () => {
    const { data: response } = await ServicesService.getService(id, {
      join: [
        ['schoolClasses'],
        // serviceClasses: Isso tem que trazer em outra request, porque no front é paginado
        ['serviceClasses'],
        ['partner'],
        ['galleryImages'],
        ['serviceType'],
        ['servicePublicationStatus'],
        ['serviceCategories'],
        ['serviceRecurrence'],
      ],
    });

    setService(response);
  }, [id]);

  // Custom hooks
  const { openAlert, closeAlert, isShowAlert, alertType, alertMessage } =
    useAlert();

  const handleDispatchAlert = useCallback(() => {
    openAlert({
      message: LOAD_DATA_ERROR,
      type: MESSAGE_TYPE.ERROR,
    });
    setTimeout(() => {
      closeAlert();
    }, 2000);
  }, [closeAlert, openAlert]);

  const handleGoToServiceCodes = () => {
    history.push(`/adm-marketplace/service-codes`, { serviceId: service.id });
  };

  const loadServiceCodes = useCallback(async () => {
    try {
      setLoading(true);
      const { data: response, status } = await ServicesCodes.getAllCodeServices(
        {
          filter: [
            {
              field: 'serviceId',
              value: id,
              operator: CondOperator.EQUALS,
            },
          ],
        },
      );

      const { data } = response;

      if (status === StatusCodes.OK) {
        const serviceCodes = data.filter(
          (serviceCode: any) => serviceCode.wasUsed,
        );
        setTotalServiceCodesUsed(serviceCodes.length);
        setTotalServiceCodes(response.total);
      } else {
        throw new Error(LOAD_SERVICES_CODES_ERROR);
      }
    } catch (error) {
      handleDispatchAlert();
    } finally {
      setLoading(false);
    }
  }, [id, handleDispatchAlert]);

  // Effects
  useEffect(() => {
    getServiceDetails();
  }, [getServiceDetails]);

  useEffect(() => {
    loadServiceCodes();
  }, [loadServiceCodes]);

  return {
    service,
    totalServiceCodesUsed,
    totalServiceCodes,
    loading,
    isShowAlert,
    alertType,
    alertMessage,
    handleGoToServiceCodes,
  };
};
