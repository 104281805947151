import { styled } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import CurrencyField from 'components/CurrencyField';
import { borderGray } from 'styles/theme';

export const InputText = styled(TextField)(() => {
  return {
    fontSize: '14px',
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1.45px solid ${borderGray}`,
      fontSize: 16,
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock':
      {
        fontSize: '14px',
        height: '9px',
        marginTop: '-5px',
      },
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      fontSize: '14px',
      minHeight: '55px',
      lineHeight: '20px',
    },
    // FIXME: Pra que serve isso?
    // '& .MuiSelect-root ': innerSelectedColor
    //   ? {
    //       margin: 8,
    //       height: '0px',
    //       backgroundColor: innerSelectedColor,
    //     }
    //   : undefined,
    '& .material-icons': {
      marginRight: 15,
    },
    '& .MuiPopover-paper': {
      width: '20px !important',
    },
    '& .MuiSelect-select': {
      fontSize: '14px',
    },
    // FIXME: Pra que serve isso?
    // '& .MuiSelect-select:focus': {
    //   backgroundColor: innerSelectedColor || 'white',
    // },
    '& .MuiRadio-root': {
      display: 'none',
    },
  };
});

export const CustomCurrencyInput = styled(CurrencyField)(() => {
  return {
    fontSize: '14px',
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1.45px solid ${borderGray}`,
      fontSize: 16,
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock':
      {
        fontSize: '14px',
        height: '9px',
        marginTop: '-5px',
      },
  };
});
