import { CreateQueryParams } from '@nestjsx/crud-request';
import { getWithFilter } from 'services/apiService';
import { ServiceClassEnrollment } from 'types/serviceClassEnrollment';

// TODO: types
export default class ServiceClassEnrollmentsService {
  static async getServiceClassEnrollments(query?: CreateQueryParams) {
    return getWithFilter<ServiceClassEnrollment>(
      `service-class-enrollments`,
      query,
    );
  }
}
