import React from 'react';

import Slider from 'react-slick';
import { Box, ButtonBase, Grid } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { CustomIcon } from 'components/CustomIcon';
import { IServiceDetailsResponse } from 'pages/Client/MarketplaceServicesDetail/types';
import { Image, Iframe, ContainerActions } from './styles';
import { useServiceGalleryController } from './controller';

export const ServiceGallery = ({
  service,
}: {
  service: IServiceDetailsResponse;
}) => {
  const {
    sliderRef,
    handlePreviousItemGallery,
    handleNextItemGallery,
    currentGalleryItemIndex,
    youtubeEmbeddedUrl,
    gallerySize,
    imageBreakpoint,
  } = useServiceGalleryController(service);

  return (
    <>
      <Hidden only={['xs', 'sm', 'md']}>
        <Grid container spacing={10} direction="row">
          {youtubeEmbeddedUrl.length ? (
            <Grid item lg={8} md={8} sm={6}>
              <Box>
                <Iframe
                  src={youtubeEmbeddedUrl}
                  frameBorder="0"
                  allowFullScreen
                  title="Embedded Youtube"
                  allow="clipboard-write; encrypted-media; picture-in-picture"
                />
              </Box>
            </Grid>
          ) : null}
          <Grid
            direction={youtubeEmbeddedUrl.length ? 'column' : 'row'}
            container
            item
            lg={imageBreakpoint}
            md={imageBreakpoint}
            sm={imageBreakpoint}
            spacing={1}
          >
            {(service?.galleryImages || []).map(({ source, id }) => {
              return (
                <Grid item key={id}>
                  <Image src={source} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Hidden>
      <Hidden only={['lg', 'xl']}>
        <Slider
          infinite={false}
          speed={300}
          slidesToShow={1}
          slidesToScroll={1}
          ref={sliderRef}
          swipe={false}
        >
          {youtubeEmbeddedUrl.length ? (
            <Iframe
              src={youtubeEmbeddedUrl}
              frameBorder="0"
              allowFullScreen
              title="Embedded Youtube"
              allow="clipboard-write; encrypted-media; picture-in-picture"
            />
          ) : null}

          {(service?.galleryImages || []).map(({ source, id }) => {
            return (
              <Grid item key={id}>
                <Image src={source} />
              </Grid>
            );
          })}
        </Slider>
        {gallerySize > 1 ? (
          <ContainerActions>
            <ButtonBase onClick={handlePreviousItemGallery}>
              <CustomIcon name="galleryPaginationChevLeft" />
            </ButtonBase>
            <p>
              {currentGalleryItemIndex + 1} / {gallerySize}
            </p>
            <ButtonBase onClick={handleNextItemGallery}>
              <CustomIcon name="galleryPaginationChevRight" />
            </ButtonBase>
          </ContainerActions>
        ) : null}
      </Hidden>
    </>
  );
};
