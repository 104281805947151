import { useState } from 'react';

export const useCollapsed = (shouldBeginExpanded = false) => {
  const [isExpanded, setIsExpanded] = useState(shouldBeginExpanded);

  const handleToggleCollapse = () => {
    setIsExpanded(prev => !prev);
  };

  return {
    isExpanded,
    handleToggleCollapse,
  };
};
