export enum MESSAGE_TYPE {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

export interface IShowAlert {
  message: string;
  type?: MESSAGE_TYPE;
}
