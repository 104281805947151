import React from 'react';
import { Paper, Grid, Typography } from '@material-ui/core';
import { PlaceholderProperties } from './types';
import { useStyles } from './styles';

const EmptyListPlaceholder: React.FC<PlaceholderProperties> = ({ message }) => {
  const styles = useStyles();

  return (
    <Paper className={styles.paper}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={styles.container}
      >
        <Grid item>
          <Typography color="primary" variant="body1">
            <b>{message}</b>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EmptyListPlaceholder;
