import { REQUIRED_FIELD } from 'texts';
import { unformatValueMask } from 'utils';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  year: Yup.number().required(REQUIRED_FIELD),
  value: Yup.mixed()
    .transform(value => unformatValueMask(value))
    .required(REQUIRED_FIELD),
  vacancies: Yup.number().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD),
});

/**
 *
 * @param quantityOfYears Quantity of years to generate.
 * @returns A list of years based on the quantity of years by the current year.
 */
export const generateListOfYears = (quantityOfYears: number) => {
  const dates = [];
  const start = new Date().getFullYear();
  const end = start + quantityOfYears;

  for (let i = start; i <= end; i += 1) {
    dates.push(new Date(i, 0, 1).getFullYear());
  }

  return dates;
};
