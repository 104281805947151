import {
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { CustomButton } from 'components/CustomButton';
import { translatePaymentMethod } from 'pages/Client/PaymentMethod/steps/ConfirmPayment/utils';
import React, { useCallback, useMemo } from 'react';
import { PAYMENT_STATUS } from 'types/enums';
import { buildRegistrationServiceName } from 'utils/buildRegistrationServiceName';
import { getPaidStatusDescription } from '../../utils/getPaymentStatusDescription';
import { usePaymentCardStyles } from '../paymentCardStyles';
import { PaymentCardComponentProps } from '../types';
import { useStyles } from './styles';

const PaymentPix: React.FC<PaymentCardComponentProps> = ({ payment, bill }) => {
  const styles = useStyles();
  const paymentCardStyles = usePaymentCardStyles();
  const {
    title,
    description,
    icon: Icon,
  } = useMemo(() => {
    return getPaidStatusDescription({
      paymentMethodCode: payment.paymentMethodCode,
      status: payment.status,
      paymentFailReason: payment.paymentFailReason,
      paymentStartDate: payment.paymentStartDate,
    });
  }, [
    payment.paymentFailReason,
    payment.paymentMethodCode,
    payment.paymentStartDate,
    payment.status,
  ]);

  const pixInfo = useMemo(() => {
    const qrCode = bill.pixQrCode;
    const pastAndCopy = bill.pixPastAndCopy;

    return {
      qrCode,
      pastAndCopy,
    };
  }, [bill]);

  const handleCopyPix = useCallback(() => {
    navigator.clipboard.writeText(pixInfo?.pastAndCopy || '');
  }, [pixInfo?.pastAndCopy]);

  const handleRenewPix = useCallback(() => {
    // TODO: integrate with API to renew PIX
  }, []);

  const shouldRenderPixButton = useMemo(() => {
    const hasQrCode = !!pixInfo?.qrCode;

    return payment.status === PAYMENT_STATUS.PROCESSING && hasQrCode;
  }, [payment.status, pixInfo?.qrCode]);

  const shouldRenderRenewPixButton = useMemo(() => {
    return payment.status === PAYMENT_STATUS.CANCELED;
  }, [payment.status]);

  const dependentNameAndSchoolGrade = useMemo(
    () =>
      `${payment.dependent?.name || 'Sem nome'} - ${
        payment.referenceGrade || 'Sem ano/série'
      }`,
    [payment.dependent?.name, payment.referenceGrade],
  );

  return (
    <Grid item xs={12} md={6}>
      <Card className={paymentCardStyles.cardContainer}>
        <Typography className={paymentCardStyles.cardTitle}>
          {payment.service?.name ||
            buildRegistrationServiceName(payment.referenceYear)}
        </Typography>
        <Typography className={paymentCardStyles.cardSubtitle}>
          {dependentNameAndSchoolGrade}
        </Typography>
        <Typography className={paymentCardStyles.cardSubtitle}>
          {translatePaymentMethod(payment.paymentMethodCode)}
        </Typography>
        <Divider className={paymentCardStyles.divider} />
        <CardContent className={paymentCardStyles.cardContent}>
          <Grid container item>
            {payment.status === PAYMENT_STATUS.PROCESSING && pixInfo?.qrCode ? (
              <Grid item>
                <img
                  className={styles.pixImage}
                  src={`data:image/jpeg;base64,${pixInfo.qrCode}`}
                  alt="QR Code de pagamento do serviço"
                />
              </Grid>
            ) : null}
            <Grid item className={paymentCardStyles.gridInformations}>
              {payment.status !== PAYMENT_STATUS.PROCESSING ? (
                <Icon className={paymentCardStyles.icon} />
              ) : (
                <CircularProgress size={15} />
              )}
              <Typography className={paymentCardStyles.title}>
                {title}
              </Typography>
              <Typography className={paymentCardStyles.descriptionText}>
                {description}
              </Typography>
              {shouldRenderPixButton ? (
                <CustomButton
                  className={styles.copyPixButton}
                  textVariant="bold"
                  variant="primary"
                  onClick={handleCopyPix}
                >
                  Copiar código PIX
                </CustomButton>
              ) : null}
              {shouldRenderRenewPixButton ? (
                <CustomButton
                  className={styles.copyPixButton}
                  textVariant="bold"
                  variant="primary"
                  onClick={handleRenewPix}
                >
                  Gerar novo código PIX
                </CustomButton>
              ) : null}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PaymentPix;
