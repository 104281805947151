import { makeStyles, styled } from '@material-ui/core';
import { primaryDark } from 'styles/theme';

export const useStyles = makeStyles(() => ({
  button: {
    padding: '10px',
    right: '-10px',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '-0.3px',
    color: primaryDark,
    minWidth: '95px',
  },
}));

export const Container = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    padding: '10px 0px',
  };
});

export const RowContainer = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    gap: '30px',
  };
});
