import { ButtonViewContract } from './components/ButtonViewContract';
import { ContractTableData } from './ContractsTables/types';

export const ContractRowActions = (_: any, data: ContractTableData) => {
  return <ContractRowActionsComponent contract={data} />;
};

const ContractRowActionsComponent = ({
  contract,
}: {
  contract: ContractTableData;
}) => {
  const {
    document: { path },
    signDate,
  } = contract;
  return <ButtonViewContract documentPath={path} signDate={signDate} />;
};
