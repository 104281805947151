import { CARD_EXPIRATION_MASK, CPF_MASK } from 'texts';

export const creditCardFields = () => [
  {
    mask: null,
    label: 'Apelido deste cartão',
    name: 'card.alias',
    focus: null,
    width: 230,
    getter: (error: any) => {
      return error?.card?.alias;
    },
  },
  {
    mask: null,
    label: 'Número do cartão',
    name: 'vindi.card_number',
    focus: 'number',
    width: 475,
    getter: (error: any) => {
      return error?.vindi?.card_number;
    },
  },
  {
    mask: null,
    label: 'Titular do cartão',
    name: 'vindi.holder_name',
    focus: 'name',
    width: 475,
    getter: (error: any) => {
      return error?.vindi?.holder_name;
    },
  },
  {
    mask: CARD_EXPIRATION_MASK,
    label: 'Vencimento',
    name: 'vindi.card_expiration',
    focus: 'expiry',
    width: 230,
    getter: (error: any) => {
      return error?.vindi?.card_expiration;
    },
  },
  {
    mask: null,
    label: 'CVV',
    name: 'vindi.card_cvv',
    focus: 'cvc',
    width: 230,
    getter: (error: any) => {
      return error?.vindi?.card_cvv;
    },
  },
  {
    mask: CPF_MASK,
    label: 'CPF do titular',
    name: 'card.cpf',
    focus: null,
    width: 230,
    getter: (error: any) => {
      return error?.card?.cpf;
    },
  },
];
