import useGlobalStyles from 'styles';
import { TABLE_HEADERS } from 'utils/tableHeaders';
import { useStyles } from './styles';

const headers = [
  TABLE_HEADERS.DEPENDENT,
  TABLE_HEADERS.REGISTRATION,
  TABLE_HEADERS.GUARDIAN,
  TABLE_HEADERS.USE_DATE,
];

export const useCouponRegistrationsController = () => {
  // Styles
  const classes = useGlobalStyles();
  const styles = useStyles();

  return {
    classes,
    styles,
    headers,
  };
};
