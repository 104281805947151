import React from 'react';

import { Grid } from '@material-ui/core';

import PageHeader from 'components/PageHeader';
import { CouponRegistrations } from './CouponRegistrations';
import { ICouponDetail } from './types';
import { CouponDetailTable } from './CouponDetailsTable';
import { useCouponDetailController } from './controller';

export const CouponDetail = () => {
  const { classes, couponDetail } = useCouponDetailController();

  return (
    <Grid
      className={classes.listScreenGrid}
      container
      direction="column"
      alignItems="flex-start"
    >
      <Grid container item direction="column">
        <PageHeader
          title="Cupom"
          titleBoldSufix={couponDetail?.name || ''}
          subtitle="Adicione, delete ou edite os cupons disponíveis"
        />

        {couponDetail ? (
          <>
            <CouponDetailTable coupon={couponDetail as ICouponDetail} />
            <CouponRegistrations registrations={couponDetail?.registrations} />
          </>
        ) : null}
      </Grid>
    </Grid>
  );
};
