import { WEAK_PASSWORD } from 'texts';
import { testPasswordStrength } from 'utils';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  password: Yup.string()
    .required('Defina a nova senha')
    .test('password-strength', WEAK_PASSWORD, value =>
      testPasswordStrength(value),
    ),
  repeatPassword: Yup.string()
    .required('Repita a nova senha.')
    .test('password-match', 'As senhas devem ser iguais.', function (value) {
      return this.parent.password === value;
    }),
});
