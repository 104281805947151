import { Box, Typography } from '@material-ui/core';
import LightTooltip from 'components/LightTooltip';
import useGlobalStyles from 'styles';
import {
  TOOLTIP_TEXT_FINANCIAL_GUARDIAN,
  TOOLTIP_TEXT_LEGAL_GUARDIAN,
  TOOLTIP_TEXT_REGISTER,
} from 'texts';
import { HelpIcon } from './styles';
import { IGuardianTooltipProps } from './types';

export const GuardianTooltip = ({
  marginLeft,
  marginBottom,
  marginRight,
  marginTop,
}: IGuardianTooltipProps) => {
  const classes = useGlobalStyles();
  return (
    <LightTooltip
      leaveTouchDelay={10000}
      title={
        <Box>
          <Box m={1}>
            <Typography>
              <b>Novo responsável</b>
            </Typography>
          </Box>
          <Box m={1}>
            <Typography>{TOOLTIP_TEXT_REGISTER}</Typography>
          </Box>
          <Box m={1}>
            <Typography>
              <b>O que é isso?</b>
            </Typography>
          </Box>
          <Box m={1}>
            <Typography>{TOOLTIP_TEXT_LEGAL_GUARDIAN}</Typography>
          </Box>
          <Box m={1}>
            <Typography>{TOOLTIP_TEXT_FINANCIAL_GUARDIAN}</Typography>
          </Box>
        </Box>
      }
      classes={{ tooltip: classes.tooltip }}
      placement="right"
    >
      <HelpIcon
        marginLeft={marginLeft}
        marginBottom={marginBottom}
        marginRight={marginRight}
        marginTop={marginTop}
      />
    </LightTooltip>
  );
};
