import { IServiceDetailsResponse } from 'pages/Client/MarketplaceServicesDetail/types';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { RegisterType } from 'types/register';

type ServiceType = string;

type ServicePublicationStatus = string;

type CurrencyRecurrenceType = string;

export type SelectState = { id: number; name: string };

type ServiceCategoryType = Array<string>;
export type ServiceCategoryResponseType = Array<SelectState>;

export type FileState = { file?: File; url?: string };

export enum ImagesNames {
  Avatar = 'avatar',
  Cover = 'cover',
  Images = 'images',
}

export enum DaysInTheWeek {
  Monday = 'Segunda',
  Tuesday = 'Tuesday',
  Wednesday = 'Quarta',
  Thursday = 'Quinta',
  Friday = 'Sexta',
  Saturday = 'Sábado',
  Sunday = 'Domingo',
}

export type ScheduleType = { day: string; schedule: string };

type ServiceProviderType = string;

interface IGeneralFields {
  name: string;
  type: ServiceType;
  publicationStatus: ServicePublicationStatus;
  category: ServiceCategoryType;
  schoolClasses: Array<string>;
  currencyRecurrence: CurrencyRecurrenceType;
  totalOfSpots: number;
  annualDuratonInHours: number;
  earlyAccess: string;
  pedagogicSchoolYearId: string;
  pedagogicSubjectId?: string;
}

export interface ICalendarAssociatedFields {
  'day-schedule': string;
  'day-schedule-0': string;
  'day-schedule-1': string;
  'day-schedule-2': string;
  'day-schedule-3': string;
  'day-schedule-4': string;
  'day-schedule-5': string;
  'day-schedule-6': string;
}

interface ICalendarFields extends ICalendarAssociatedFields {
  startDate: string;
  endDate: string;
  days: Array<DaysInTheWeek>;
  scheduleCanVaryToEveryDay: boolean;
  schedule?: Array<ScheduleType>;
}

interface IFinancialFields {
  amount: string;
  installments: number;
  registrationTaxValue: string;
  partnerId: ServiceProviderType;
  contractTemplateId: number;
  invoiceServiceCode: string;
}

interface ServiceAccessFields {
  inviteCode?: string;
}

interface IExtraFields {
  avatar: string;
  cover: string;
  images: Array<string>;
  description: string;
  color: string;
  youtubeVideo: string;
}

interface IServiceFormFields {
  general: IGeneralFields;
  calendar: ICalendarFields;
  financial: IFinancialFields;
  extra: IExtraFields;
}

export type IServiceFieldValues = IGeneralFields &
  ServiceAccessFields &
  IFinancialFields &
  IExtraFields;

export interface IServiceFormProps {
  type: 'create' | 'edit';
  previousService?: IServiceFormFields;
  errors: DeepMap<Record<string, any>, FieldError>;
  register: RegisterType;
  control: Control<Record<string, any>>;
  setValue: (name: string, value: unknown) => void;
  getValues?: (name: string) => unknown;
  setImagesValues?: (
    name: ImagesNames,
    value?: FileState | FileState[],
  ) => void;
  serviceToUpdate?: Partial<IServiceResponse>;
}

export interface IGeneraFormProps {
  errors: DeepMap<Record<string, any>, FieldError>;
  register: RegisterType;
  control: Control<Record<string, any>>;
  setValue?: (name: string, value: unknown) => void;
  getValues?: (name: string) => unknown;
  setImagesValues?: (
    name: ImagesNames,
    value?: FileState | FileState[],
  ) => void;
  serviceToUpdate?: Partial<IServiceResponse>;
}

export type InputTextStyleProps = {
  fixedWidth?: boolean;
  innerSelectedColor?: string;
};
export type InputImageStyleProps = { imageUrl?: string; error?: boolean };
export type GridServiceImageStyleProps = { isMiddleGridItem: boolean };
export type InputImageTextStyleProps = { error?: boolean };

export type IServiceResponse = IServiceDetailsResponse;

export enum EServicesTypes {
  EVENT = 'Evento',
  BY_INVITE = 'Atividade por convite',
  WITHOUT_ANNUITY = 'Currículo complementar sem anuidade',
  WITH_ANNUITY = 'Currículo complementar com anuidade',
}

export interface IGenerateServiceFormDataProps {
  values: IServiceFieldValues;
  serviceTypeId: string;
  servicePublicationStatusId: string;
  // haveAmount: boolean;
  coverPhoto?: FileState;
  galleryImages: Array<Partial<FileState>>;
  partnerLogo?: FileState;
  // schedule: Array<ScheduleType>;
  appendCategoriesOnEdit?: boolean;
  isUpdate?: boolean;
}
