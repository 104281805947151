import { ButtonBaseProps, styled, Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import { infoMain, primaryDark } from 'styles/theme';

export const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#F2F6FA',
  position: 'fixed',
  width: '100%',
  zIndex: 1,
  bottom: 0,
  left: 0,
  right: 0,
  boxShadow: `0px 6px 32px ${infoMain}`,
  height: '77px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  paddingRight: '85px',
  [theme.breakpoints.down('md')]: {
    paddingRight: '10px',
    paddingLeft: '10px',
  },
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  },
}));

export const LoadingProgress = styled(CircularProgress)(() => ({
  marginLeft: '18px',
}));

export const ContainerButton = styled(ButtonBase)<Theme, ButtonBaseProps>(
  ({ theme, disabled }) => ({
    padding: '10px 20px',
    width: 'fit-content',
    height: '48px',
    background: disabled ? infoMain : primaryDark,
    borderRadius: '8px',
    fontWeight: 700,
    fontSize: '16px',
    color: 'white',
    marginLeft: '10px',
    [theme.breakpoints.down('md')]: {
      padding: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  }),
);

export const TextButton = styled(ButtonBase)<Theme>(({ theme }) => ({
  padding: '10px 20px',
  minWidth: '167px',
  height: '48px',
  borderRadius: '8px',
  fontWeight: 700,
  fontSize: '16px',
  color: primaryDark,
  '&.mobile': {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '100px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
}));
