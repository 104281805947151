import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  box: {
    width: 960,
    padding: 35,
  },
  cardGrid: {
    maxWidth: '40%',
    marginTop: -65,
  },
  warning: {
    marginBottom: 30,
  },
  formGrid: {
    maxWidth: '60%',
  },
});
