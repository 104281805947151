import { Paper, Grid } from '@material-ui/core';
import { useStyles } from 'components/EmptyListPlaceholder/styles';
import { LoadingProgress } from 'components/FooterActions/styles';

const LoadingTable = () => {
  const styles = useStyles();

  return (
    <Paper className={styles.paper}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={styles.container}
      >
        <Grid item>
          <LoadingProgress size={24} color="secondary" />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default LoadingTable;
