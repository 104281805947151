import { Box } from '@material-ui/core';
import AlertCard from 'components/AlertCard';
import { CustomIcon } from 'components/CustomIcon';
import PageHeader from 'components/PageHeader';
import { ServiceForm } from '../components/ServiceForm';
import { FormFooter } from '../components/ServiceForm/components/FormFooter';
import { Modal } from '../components/ServiceForm/components/Modal';
import {
  DISCART_OR_SAVE_WITHOUT_PUBLISHING,
  NOT_RECOVERABLE_DATA,
} from '../components/ServiceForm/components/Modal/messages';
import { ContainerButton, TextButton } from '../components/ServiceForm/styles';
import { useServiceUpdateController } from './controller';

export const ServiceUpdate = () => {
  const {
    handleSubmit,
    handleCancel,
    submit,
    control,
    errors,
    register,
    setValue,
    getValues,
    successModalOpen,
    cancelModalOpen,
    handleCloseModal,
    generalErrorMessageAlert,
    handleCloseScheduleError,
    setImagesValues,
    serviceToUpdate,
    submitError,
    handleCloseSubmitError,
    loading,
    handleCloseModalAndExit,
  } = useServiceUpdateController();

  return (
    <>
      <Box paddingX="45px" position="relative">
        <PageHeader
          title="Editar"
          titleBoldSufix="serviço"
          subtitle="Edite os dados de um serviço para já cadastrado no marketplace, preenchendo "
          subtitleBold="todas as informações"
        />

        {serviceToUpdate && (
          <>
            <ServiceForm
              type="edit"
              control={control}
              errors={errors}
              register={register}
              setValue={setValue}
              getValues={getValues}
              setImagesValues={setImagesValues}
              serviceToUpdate={serviceToUpdate}
            />
            <FormFooter
              type="edit"
              onSubmit={handleSubmit(submit)}
              onCancel={handleCancel}
              loading={loading}
            />
          </>
        )}
      </Box>
      <Modal
        isOpen={successModalOpen}
        icon={<CustomIcon name="checkCircle" />}
        title={
          <>
            Alterações <b>publicadas</b> com sucesso!
          </>
        }
        subtitle="O serviço foi publicado no market place e os responsáveis podem fazer a contratação."
        confirmButton={
          <ContainerButton onClick={handleCloseModalAndExit}>
            Fechar
          </ContainerButton>
        }
      />
      <Modal
        isOpen={cancelModalOpen}
        icon={<CustomIcon name="warningCheckCircle" />}
        subtitle={NOT_RECOVERABLE_DATA}
        title={DISCART_OR_SAVE_WITHOUT_PUBLISHING}
        cancelButton={
          <TextButton onClick={handleCloseModal}>Continuar </TextButton>
        }
        confirmButton={
          <ContainerButton onClick={handleCloseModalAndExit}>
            Sair
          </ContainerButton>
        }
      />
      <AlertCard
        message={generalErrorMessageAlert}
        open={!!generalErrorMessageAlert}
        close={handleCloseScheduleError}
        severity="error"
      />
      <AlertCard
        message={submitError}
        open={!!submitError}
        close={handleCloseSubmitError}
        severity="error"
      />
    </>
  );
};
