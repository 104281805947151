import React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { PdfViewerProps } from './types';

const WORKER_URL = 'https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.js';

const PdfViewer: React.FC<PdfViewerProps> = ({
  fileUrl,
  viewHeight = '750px',
}) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: () => [],
  });

  return (
    <Worker workerUrl={WORKER_URL}>
      <div style={{ height: viewHeight }}>
        <Viewer fileUrl={fileUrl} plugins={[defaultLayoutPluginInstance]} />
      </div>
    </Worker>
  );
};

export default PdfViewer;
