import { CustomButton } from 'components/CustomButton';
import { ExportFinancialReportButtonProps } from './types';

export const ExportFinancialReportButton = ({
  isAdmin,
  ...customButtonProps
}: ExportFinancialReportButtonProps) => {
  if (!isAdmin) return null;

  return (
    <CustomButton {...customButtonProps} textVariant="bold">
      Exportar
    </CustomButton>
  );
};
