export const COUPON_NOT_FOUND = 'Cupom não foi encontrado';
export const EXPIRED_COUPON = 'Cupom vencido';
export const LIMIT_EXCEEDED = 'Cupom atingiu o limite de uso';
export const VALUE_NAN = (decimals?: string, hasDecimals?: boolean) =>
  `O valor precisa ser um número${
    hasDecimals ? `, separe ${decimals} com um ponto. (ex: 1350.25)` : '.'
  }`;
export const EXISTING_COUPON = 'Cupom já existente.';
export const COUPON_IS_USED = 'Este cupom está aplicado em uma matrícula.';
export const VALUE_ZERO = 'Insira um número maior que zero.';
export const COUPON_APPLIED = 'Cupom aplicado com sucesso!';
export const COUPON_REMOVED = 'Cupom removido.';
export const INVALID_INTERVAL = 'A data final deve ser superior à inicial';
export const INVALID_PERCENT =
  'A porcentagem deve ser  superior a 0 e menor que 100.';
export const INVALID_COUPON =
  'Cupom incorreto: tente novamente ou entre em contato com a escola para receber o cupom correto.';
