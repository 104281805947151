import { findDocument } from 'pages/Client/ViewDependent/utils';
import { useMemo } from 'react';
import useGlobalStyles from 'styles';
import { DOCUMENT_TYPE } from 'types/enums';
import { useStyles } from './styles';
import { IBoxProperties } from './types';

export const useDependentCompactBoxController = ({
  registration,
}: Pick<IBoxProperties, 'registration'>) => {
  // Styles
  const classes = useGlobalStyles();
  const styles = useStyles();

  // Memo
  const savedPicture = useMemo(
    () =>
      registration?.documents
        ? findDocument(registration?.documents, DOCUMENT_TYPE.IMAGEM_PERFIL)
        : null,
    [registration?.documents],
  );

  return {
    classes,
    styles,
    schoolGrade: registration?.schoolGrade,
    savedPicture,
  };
};
