import { makeStyles, styled, Theme } from '@material-ui/core';
import { infoMain } from 'styles/theme';
import { LabelStyledProps } from './types';

export const useStyles = makeStyles({
  row: {
    cursor: 'pointer',
  },
  grid: {
    marginTop: 30,
  },
  paper: {
    marginTop: 30,
    padding: 20,
    borderRadius: 22,
    boxShadow: '0px 6px 32px rgba(9, 13, 27, 0.07)',
    overflowX: 'auto',
  },
  table: {
    minWidth: 600,
  },
  tableCell: {
    minWidth: 10,
  },
  tableCellLabel: {
    minWidth: 245,
  },
  spacingBox: {
    height: 20,
  },
  tableBody: {
    borderTop: `1px solid ${infoMain}`,
  },
});

export const Label = styled('span')<Theme, LabelStyledProps>(
  ({ backgroundColor, color }) => ({
    backgroundColor,
    padding: '4.5px 9px',
    borderRadius: 100,
    fontSize: 10,
    fontWeight: 700,
    color,
    textTransform: 'uppercase',
    letterSpacing: '0.13rem',
  }),
);
