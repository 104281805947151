import {
  NestCrudTableFetchData,
  NestCrudTableFetchRequest,
} from 'components/NestCrudTable/types';
import { useCallback } from 'react';
import InvoicesService from 'services/invoicesService';
import { InvoiceGetMonthlyReport } from 'types/invoice';

export const useMonthlyReportsTableController = () => {
  const fetchReport: NestCrudTableFetchData<InvoiceGetMonthlyReport> =
    useCallback(async ({ page, perPage }: NestCrudTableFetchRequest) => {
      const response = await InvoicesService.getMonthlyReport({
        limit: perPage,
        page,
      });

      return {
        data: response.data.data,
        total: response.data.total,
      };
    }, []);

  return {
    fetchReport,
  };
};
