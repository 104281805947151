import CustomDialog from 'components/CustomDialog';
import Dropdown from 'components/HeaderBar/Dropdown';
import { FC } from 'react';
import { MODAL_BTN, MODAL_TITLE } from '../../../../utils';
import { ButtonProps } from './types';
import { useButtonBillActionsController } from './useButtonBillActionsController';

export const ButtonBillActions: FC<ButtonProps> = ({
  billData,
  onReloadPayment,
}) => {
  const {
    menuItems,
    handleDeleteBill,
    cancelBillDialogRef,
    deleteBillDialogRef,
    cancelAndRefundBillDialogRef,
    handleCancelBill,
    loading,
    handleCancelAndRefundBill,
    getBillCancelationDialogTitle,
  } = useButtonBillActionsController({ billData, onReloadPayment });

  return (
    <>
      <CustomDialog
        ref={deleteBillDialogRef}
        rawTitle="Remover fatura"
        rawSubtitle="Esta fatura será removida do sistema. Ela não será contabilizada no relatório financeiro e o status do pagamento será atualizado em breve."
        primaryButton={{
          text: 'Sim, remover fatura',
          onClick: handleDeleteBill,
          loading,
        }}
        secondaryButton={{
          text: 'Voltar',
          onClick: cancelBillDialogRef.current?.closeDialog,
          disabled: loading,
        }}
        icon={{
          name: 'warningCheckCircle',
        }}
      />
      <CustomDialog
        ref={cancelBillDialogRef}
        rawTitle={getBillCancelationDialogTitle()}
        rawSubtitle="Esta fatura será marcada como cancelada no sistema! O status do pagamento será atualizado em breve."
        primaryButton={{
          text: MODAL_BTN(false),
          onClick: () => handleCancelBill(),
          loading,
        }}
        secondaryButton={{
          text: 'Voltar',
          onClick: cancelBillDialogRef.current?.closeDialog,
          disabled: loading,
        }}
        icon={{
          name: 'warningCheckCircle',
        }}
      />
      <CustomDialog
        ref={cancelAndRefundBillDialogRef}
        rawTitle={MODAL_TITLE(true, 'fatura')}
        rawSubtitle="Esta fatura será estornada e marcada como cancelada no sistema! O status do pagamento será atualizado em breve."
        primaryButton={{
          text: MODAL_BTN(true),
          onClick: handleCancelAndRefundBill,
          loading,
        }}
        secondaryButton={{
          text: 'Voltar',
          onClick: cancelAndRefundBillDialogRef.current?.closeDialog,
          disabled: loading,
        }}
        icon={{
          name: 'warningCheckCircle',
        }}
      />
      <Dropdown
        text="Ações"
        menuItems={menuItems}
        disabled={!menuItems.length}
        // FIXME: Actions item can broken line in small screens
        maxItems={8}
      />
    </>
  );
};
