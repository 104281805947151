import { makeStyles } from '@material-ui/core';
import { primaryMain, primaryMedium, whiteFont } from 'styles/theme';
import { StyleProperties } from 'types/style';

export const useStyles = (properties: Partial<StyleProperties>) => {
  const { isAdmin, active } = properties;

  return makeStyles(() => ({
    activeRoute: {
      backgroundColor: isAdmin ? primaryMedium : whiteFont,
      fontWeight: 'bold',
      color: isAdmin ? 'white' : primaryMain,
      paddingLeft: 38,
      borderRadius: '0px 12px 12px 0px',
      marginBottom: 15,
      width: 'fit-content',
    },
    route: {
      color: isAdmin ? 'white' : '#757575',
      paddingLeft: 38,
      borderRadius: '0px 12px 12px 0px',
      marginBottom: 15,
      width: 'fit-content',
    },
    menuItemTitle: {
      marginLeft: -23,
      fontWeight: active ? 700 : 300,
      fontSize: 14,
      paddingRight: 10,
    },
  }));
};
