import { makeStyles } from '@material-ui/core';
import { StyleProperties } from 'types/style';

export const useStyles = ({ isAdmin }: StyleProperties) =>
  makeStyles(theme => ({
    menuHeader: {
      margin: '5px',
    },
    text: {
      marginLeft: '-15px',
    },
    button: {
      height: '100%',
      backgroundColor: 'white',
      borderRadius: '14px',
      boxShadow: '0px 6px 32px rgba(9, 13, 27, 0.07)',
      [theme.breakpoints.down('md')]: {
        color: isAdmin ? 'white' : 'black',
        backgroundColor: isAdmin ? theme.palette.primary.main : 'white',
        boxShadow: 'none',
      },
    },
    icon: {
      color: 'black',
      [theme.breakpoints.down('md')]: {
        color: isAdmin ? 'white' : 'black',
      },
    },
  }));
