import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import MaskedTextField from 'components/MaskedTextField';
import SpinnerButton from 'components/SpinnerButton';
import { useAuth } from 'contexts/auth';
import { format } from 'date-fns';
import { StatusCodes } from 'http-status-codes';
import { dropRight, omit } from 'lodash';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import AuthService from 'services/authService';
import UserService from 'services/userService';
import useGlobalStyles from 'styles';
import { EXISTING_EMAIL, WEAK_PASSWORD } from 'texts/messages/auth';
import { USER_ROLES } from 'types/enums';
import { NewUser } from 'types/user';
import { allToUpperCase } from '../../pages/Client/NewRegistration/utils';
import { useStyles } from './styles';
import { ModalProperties } from './types';
import { validationSchema } from './utils';

const NewUserModal: React.FC<ModalProperties> = ({
  admin,
  open,
  close,
  loadUsers,
}) => {
  const classes = useGlobalStyles();
  const styles = useStyles();
  const { user } = useAuth();
  const [selectedRole, setSelectedRole] = useState(USER_ROLES.ADMIN);
  const { handleSubmit, errors, setError, register } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onBlur',
  });

  function onSubmit(data: any) {
    const newUser = allToUpperCase(omit(data, ['repeatPassword']));
    if (user) {
      newUser.createdByAdmin = {
        admin: user.name,
        adminId: user.id,
        createdAt: format(new Date(), 'yyyy-MM-dd'),
      };
      if (admin) {
        newUser.isVerified = true;
        newAdmin(newUser as NewUser);
      } else {
        newFinancialGuardian(newUser as NewUser);
      }
    }
  }

  async function newAdmin(formData: NewUser) {
    const response = await UserService.createUser({
      ...formData,
      isAdmin: true,
      roles: [selectedRole],
    });
    if (response.status !== StatusCodes.CREATED) {
      setError('email', {
        message: EXISTING_EMAIL,
      });
    } else {
      loadUsers();
      close();
    }
  }

  async function newFinancialGuardian(newUser: NewUser) {
    const response = await UserService.createUserFinancialGuardian({
      ...newUser,
      roles: [USER_ROLES.GUARDIAN],
    });
    if (response.status !== StatusCodes.CREATED) {
      setError('email', {
        message: EXISTING_EMAIL,
      });
    } else {
      sendConfirmationEmail(newUser.email);
    }
  }

  async function sendConfirmationEmail(email: string) {
    const response = await AuthService.verifyEmail(email);
    if (response.data.status !== StatusCodes.OK) {
      setError('email', {
        message: EXISTING_EMAIL,
      });
    } else {
      loadUsers();
      close();
    }
  }

  return (
    <Dialog open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className={styles.container}>
          <Box m={3}>
            <Typography className={styles.title}>
              <b>Adicionar novo perfil {admin ? 'administrativo' : 'geral'}</b>
            </Typography>
          </Box>
          <Box m={3}>
            <TextField
              variant="outlined"
              label="Nome"
              id="name"
              name="name"
              error={Boolean(errors.name)}
              helperText={errors.name ? errors.name.message : null}
              inputRef={register}
              className={styles.field}
            />
          </Box>
          <Box m={3}>
            <MaskedTextField
              mask="(99) 99999-9999"
              label="Telefone"
              name="phone"
              error={!!errors.phone}
              helperText={errors.phone ? errors.phone?.message : null}
              inputRef={register}
              className={styles.field}
            />
          </Box>
          <Box m={3}>
            <TextField
              variant="outlined"
              label="Email"
              id="email"
              name="email"
              error={Boolean(errors.email)}
              helperText={errors.email ? errors.email.message : null}
              inputRef={register}
              className={styles.field}
            />
          </Box>
          <Box m={3}>
            <TextField
              variant="outlined"
              label="Senha"
              id="password"
              name="password"
              type="password"
              error={Boolean(errors.password)}
              helperText={
                errors.password ? errors.password.message : WEAK_PASSWORD
              }
              inputRef={register}
              className={styles.field}
            />
          </Box>
          <Box m={3}>
            <TextField
              variant="outlined"
              label="Confirme a senha"
              id="repeatPassword"
              name="repeatPassword"
              type="password"
              error={Boolean(errors.repeatPassword)}
              helperText={
                errors.repeatPassword ? errors.repeatPassword.message : null
              }
              inputRef={register}
              className={styles.field}
            />
          </Box>
          {admin ? (
            <Box m={3}>
              <TextField
                variant="outlined"
                select
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
                value={selectedRole}
                onChange={e => setSelectedRole(e.target.value as USER_ROLES)}
                label="Nivel de acesso"
                className={styles.select}
              >
                {dropRight(Object.values(USER_ROLES)).map(role => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          ) : null}

          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={3}
            className={classes.screenButtonGrid}
          >
            <Grid item>
              <Button
                onClick={() => close()}
                color="primary"
                className={classes.textButton}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <SpinnerButton
                text="Adicionar"
                className={classes.newDependentButton}
              />
            </Grid>
          </Grid>
        </Box>
      </form>
    </Dialog>
  );
};

export default NewUserModal;
