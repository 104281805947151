import { useFooterActionsController } from './controller';
import {
  FooterContainer,
  LoadingProgress,
  TextButton,
  ContainerButton,
} from './styles';
import { IFormFooterProps } from './types';

const FooterActions = ({
  onConfirm,
  onCancel,
  loading,
  cancelTextButton,
  confirmTextButton,
  disabled,
}: IFormFooterProps) => {
  const { handleGoBack } = useFooterActionsController();

  return (
    <FooterContainer position="fixed">
      <TextButton onClick={handleGoBack} className="mobile">
        Voltar
      </TextButton>
      {onCancel && cancelTextButton ? (
        <TextButton onClick={onCancel}>{cancelTextButton}</TextButton>
      ) : null}
      {onConfirm && confirmTextButton ? (
        <ContainerButton disabled={disabled} type="submit" onClick={onConfirm}>
          {loading ? (
            <>
              {confirmTextButton}
              <LoadingProgress color="secondary" size={15} />
            </>
          ) : (
            confirmTextButton
          )}
        </ContainerButton>
      ) : null}
    </FooterContainer>
  );
};

export { FooterActions };
