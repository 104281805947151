import { useLocalStorageWithExpirationDate } from 'hooks/LocalStorage/ExpirationDate';
import { HooksStoredDataKeys } from 'hooks/LocalStorage/types';
import { StatusCodes } from 'http-status-codes';
import { useCallback, useEffect } from 'react';
import ServicesService from 'services/servicesService';
import { ServiceType } from 'types/serviceType';

/**
 * This controller should be imported only ONCE PER PAGE
 * to prevent re-rendering and multiple fetch isuses
 */
export const useServiceTypesController = () => {
  // ---------- States ----------
  const [serviceTypes, setServiceTypes, shouldRefetchServiceTypes] =
    useLocalStorageWithExpirationDate<ServiceType[]>(
      HooksStoredDataKeys.SERVICE_TYPES,
      {
        expiresIn: {
          hours: 1,
        },
      },
    );

  // ---------- Callbacks ----------
  const fetchServiceTypes = useCallback(async () => {
    try {
      const response = await ServicesService.getTypesWithFilter({
        sort: {
          field: 'displayOrder',
          order: 'ASC',
        },
      });

      if (response.status === StatusCodes.OK) {
        const serviceTypes = response.data.data;

        setServiceTypes(serviceTypes);
      }
    } catch (error) {
      console.error(error);
    }
  }, [setServiceTypes]);

  // ---------- Effects ----------
  useEffect(() => {
    if (shouldRefetchServiceTypes) {
      fetchServiceTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetchServiceTypes]);

  return {
    serviceTypes,
    hasLoadedServiceTypes: !shouldRefetchServiceTypes,
  };
};
