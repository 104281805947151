import React from 'react';

import { IconButton, Typography, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { CustomButton } from 'components/CustomButton';
import useGlobalStyles from 'styles';
import {
  ContainerModalInsertInviteCode,
  ModalInsertInviteInputText,
} from '../styles';
import { IModalInsertInviteCodeProps } from '../types';
import { useStyles } from './styles';

export const ModalInsertInviteCode = ({
  open,
  onChange,
  value,
  onClose,
  onConfirm,
  isLoading,
}: IModalInsertInviteCodeProps) => {
  const classes = useGlobalStyles();
  const styles = useStyles();

  return (
    <ContainerModalInsertInviteCode open={open}>
      <div id="top-modal-actions">
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <Typography className={classes.dialogText}>
        Insira o código de convite
      </Typography>
      <Typography className={classes.dialogSubText}>
        Essa atividade precisa do código de convite, para obtê-lo <br /> entre
        em contato com a escola
      </Typography>

      <ModalInsertInviteInputText
        id="accessCode"
        className="desktop-input"
        name="accessCode"
        label="Código"
        variant="outlined"
        value={value}
        onChange={onChange}
      />

      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        className={styles.container}
        justifyContent="center"
      >
        <Box m={2}>
          <CustomButton onClick={onClose} variant="secondary">
            Fechar
          </CustomButton>
        </Box>
        <Box m={2}>
          <CustomButton onClick={onConfirm} loading={isLoading}>
            Aplicar código
          </CustomButton>
        </Box>
      </Box>
    </ContainerModalInsertInviteCode>
  );
};
