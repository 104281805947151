import { makeStyles, Theme } from '@material-ui/core';
import {
  backgroundGray,
  darkGray,
  divideBorderColor,
  lightBoxShadow,
} from 'styles/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderRadius: 10,
    width: '100%',
    boxShadow: lightBoxShadow,
    padding: '21px 31px 36px',
    marginBottom: 77,
  },
  paperTitle: {
    fontSize: 18,
    fontWeight: 600,
  },
  divider: {
    height: 0.8,
    backgroundColor: divideBorderColor,
    marginTop: 16,
  },
  fieldsContainer: {
    paddingTop: 41,
  },
  input: {
    width: '100%',
    '& input::placeholder': {
      fontSize: '14px',
    },
    '& label': {
      fontSize: '14px',
    },
    '& .Mui-disabled': {
      backgroundColor: backgroundGray,
    },
  },
  inputEvent: {
    paddingRight: 16,
  },
  actions: {
    color: darkGray,
    fontSize: 14,
    marginBottom: 15,
    '& span': {
      fontSize: '14px',
    },
  },
  submitButtom: {
    margin: 16,
    marginRight: 30,
    fontWeight: 700,
    textTransform: 'none',
  },
  goBackButtom: {
    fontWeight: 700,
    textTransform: 'none',
  },
  footerContainer: {
    position: 'fixed',
    display: 'flex',
    bottom: 0,
    backgroundColor: backgroundGray,
    width: '100%',
    height: 77,
    justifyContent: 'flex-end',
    paddingRight: 260,
    [theme.breakpoints.down(1325)]: {
      paddingRight: 260,
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: 20,
    },
  },
}));
