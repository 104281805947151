import { CircularProgress } from '@material-ui/core';
import CustomDialog from 'components/CustomDialog';
import { useMemo } from 'react';
import { LoadingContainer } from './styles';
import { CustomLoadingDialogProps } from './types';
import { useCustomLoadindDialogController } from './useCustomLoadindDialogController';

const CustomLoadingDialog = ({
  loading,
  rawTitle,
  rawSubtitle,
}: CustomLoadingDialogProps) => {
  const { modalLoadingRef, shouldRenderDefaultText } =
    useCustomLoadindDialogController({
      loading,
      rawTitle,
      rawSubtitle,
    });

  const renderTitle = useMemo(() => {
    if (!shouldRenderDefaultText) return rawTitle;

    return (
      <>
        Aguarde!
        <br />
        Estamos carregando algumas informações.
      </>
    );
  }, [rawTitle, shouldRenderDefaultText]);

  return (
    <CustomDialog
      rawHeader={
        <LoadingContainer>
          <CircularProgress size={100} />
        </LoadingContainer>
      }
      rawTitle={renderTitle}
      rawSubtitle={rawSubtitle}
      ref={modalLoadingRef}
    />
  );
};

export default CustomLoadingDialog;
