import React from 'react';
import { Button, Divider, Grid, Paper, Typography } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import clsx from 'clsx';

import { FooterActions } from 'components/FooterActions';
import AlertCard from 'components/AlertCard';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { CustomIcon } from 'components/CustomIcon';
import useGlobalStyles from 'styles';

import { useAuth } from 'contexts/auth';
import { formatBRL } from 'utils';
import Scaffold from 'components/Scaffold';
import CustomDialog from 'components/CustomDialog';
import { Hairline } from 'components/Hairline';
import CustomLoadingDialog from 'components/CustomLoadindDialog';
import { useMarketplacePaymentCheckoutController } from './controller';
import { useStyles } from './styles';
import ServiceComponent from './components';
import { ItemCart } from '../MarketplaceCart/ItemCart';

export const MarketplacePaymentCheckout: React.FC = () => {
  const { user } = useAuth();
  const {
    loading,
    loadingDialog,
    // handleChangeSubscription,
    creditCards,
    isOpenDetailPaper,
    loadCards,
    handleCloseCardModal,
    handleOpenCardModal,
    handleChangeIsOpenDetailPaper,
    isOpenCardModal,
    // getServiceInstallments,
    handleGoToContract,
    isShowAlert,
    alertType,
    alertMessage,
    closeAlert,
    isOpenErrorPaymentModal,
    handleClosePaymentModal,
    // shortenDayOfTheWeek,
    cartItems,
    totalCartValue,
    handleChangeSubscriptionData,
    isSubscriptionInputCorrect,
    goToContracts,
    successDialogRef,
  } = useMarketplacePaymentCheckoutController();

  const styles = useStyles();
  const classes = useGlobalStyles();

  return (
    <>
      <Scaffold
        rawTitle="Contratação de serviços"
        rawSubtitle={
          <>
            Dados para <b>pagamento</b>
          </>
        }
      >
        <Paper
          elevation={0}
          className={clsx(styles.paper, styles.activeDetailPaper)}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item>
              <Typography className={classes.paymentScreenTitle1}>
                VALOR TOTAL
              </Typography>
              <Typography className={styles.blueCardTotalValue}>
                {formatBRL(totalCartValue)}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={handleChangeIsOpenDetailPaper}
                className={styles.buttonText}
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={styles.buttonWhite}
                >
                  Ver detalhe
                </Typography>
                {isOpenDetailPaper ? (
                  <KeyboardArrowUp
                    color="secondary"
                    className={styles.arrowDetailButton}
                  />
                ) : (
                  <KeyboardArrowDown
                    color="secondary"
                    className={styles.arrowDetailButton}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </Paper>
        {isOpenDetailPaper ? (
          <Paper elevation={0}>
            <Grid container className={styles.gridPaperContainer} spacing={2}>
              {cartItems?.map(item => (
                <>
                  <ItemCart
                    key={`${item.service.id}:${item.registrationId}`}
                    item={item}
                    disableRemove
                  />
                  <Hairline />
                </>
              ))}
            </Grid>

            <Grid
              container
              className={styles.gridPaperDetail}
              direction="row-reverse"
            >
              <Grid item>
                <Typography className={styles.mediumFont} variant="body1">
                  Valor Total
                </Typography>
                <Typography
                  className={styles.cartTotalDetailText}
                  variant="body1"
                >
                  {formatBRL(totalCartValue)}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        ) : null}
        <Grid container className={styles.formPaymentGrid}>
          <Grid item>
            Forma de <strong>pagamento</strong>
            <Typography variant="body2" className={styles.formPaymentTextInfo}>
              Escolha uma forma de pagamento para cada uma das atividades,
              eventos ou passeios.
            </Typography>
          </Grid>
        </Grid>
        {cartItems.map(
          ({
            service,
            payment,
            registrationId,
            maxInstallments,
            dependent,
            registration,
          }) => (
            <ServiceComponent
              key={service.id}
              creditCards={creditCards}
              loadCards={loadCards}
              handleCloseCardModal={handleCloseCardModal}
              handleOpenCardModal={handleOpenCardModal}
              isOpenCardModal={isOpenCardModal}
              subscription={{ ...payment, serviceId: service.id }}
              user={user}
              handleChangeSubscription={(serviceId, data) =>
                handleChangeSubscriptionData(serviceId, registrationId, data)
              }
              serviceRecurrenceName={service.serviceRecurrence?.name}
              serviceTitle={service.name}
              serviceValue={service?.amount || 0}
              serviceRegistrationTax={service.registrationTaxValue}
              serviceId={service.id}
              maxInstallments={maxInstallments}
              registrationTaxValue={service.registrationTaxValue}
              dependentName={dependent.name}
              schoolClassName={registration.schoolClass.name}
            />
          ),
        )}
      </Scaffold>

      <CustomLoadingDialog loading={loadingDialog} />
      <CustomDialog
        rawTitle={
          <>
            Sua ordem de compra foi <b>criada com sucesso</b>!
          </>
        }
        rawSubtitle="Siga para o contrato para garantir a(s) vaga(s) na(s) atividade(s) contratada(s)."
        primaryButton={{
          text: 'Ir para o contrato',
          onClick: goToContracts,
        }}
        icon={{
          name: 'checkCircle',
        }}
        ref={successDialogRef}
      />
      <ConfirmationModal
        open={isOpenErrorPaymentModal}
        title="Tivemos um problema com a contratação."
        description="Entre em contato com a administração da escola para resolver a situação."
        confirmText="Voltar ao painel principal"
        onConfirm={handleClosePaymentModal}
        icon={<CustomIcon name="checkCircleError" />}
      />

      <AlertCard
        message={alertMessage}
        open={isShowAlert}
        severity={alertType}
        close={closeAlert}
      />

      <FooterActions
        confirmTextButton="Ir para contrato"
        onConfirm={handleGoToContract}
        loading={loading}
        disabled={!isSubscriptionInputCorrect || loading}
      />
    </>
  );
};
