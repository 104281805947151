import { Checkbox, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';
import { CheckboxProperties } from './types';

// FIXME: FAVOR NÃO USAR ESTE COMPONENTE, ELE NÃO ESTÁ ADEQUADO PARA O PROJETO
const CheckboxWithLabel: React.FC<CheckboxProperties> = ({
  value,
  onChange,
  label,
  disabled,
}) => {
  const styles = useStyles();

  return (
    <Grid
      item
      container
      direction="row"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <Grid item className={styles.checkbox}>
        <Checkbox
          disabled={disabled}
          value={value}
          onChange={onChange}
          color="secondary"
        />
      </Grid>
      <Grid item className={styles.label}>
        <Typography>{label}</Typography>
      </Grid>
    </Grid>
  );
};

export default CheckboxWithLabel;
