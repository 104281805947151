import { NestCrudTable } from 'components/NestCrudTable';
import { InvoiceGetMonthlyReport } from 'types/invoice';
import { invoiceMonthlyReportsTableConfig } from './contants';
import { useMonthlyReportsTableController } from './useMonthlyReportsTableController';

const MonthlyReportsTable = () => {
  const { fetchReport } = useMonthlyReportsTableController();

  return (
    <NestCrudTable<InvoiceGetMonthlyReport>
      onFetchData={fetchReport}
      tableConfig={invoiceMonthlyReportsTableConfig}
      emptyTableMessage="Não há dados para serem exibidos."
    />
  );
};

export default MonthlyReportsTable;
