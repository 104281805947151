import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { currentYear } from 'pages/Client/NewRegistration/utils';

export const formatMonth = (month: number) => {
  return format(new Date(currentYear, month - 1), 'MMMM', {
    locale: ptBR,
  }).toLocaleUpperCase();
};

export const formatForecastMonth = (month: number, year: number) => {
  return `${formatMonth(month)}/${month <= 12 ? year : year + 1}`;
};
