import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import ServiceClassesService from 'services/serviceClassesService';
import { verifyValidHourInterval } from 'utils';
import { parseBrDateToISO } from 'utils/dateUtils';
import { ScheduleTypeLabel } from '../components/ServiceClassForm/components/SchedulerForm/types';
import {
  ERROR_ON_SUBMIT_UPDATE,
  SCHEDULE_DAYS_ERROR_MESSAGE,
  SCHEDULE_INTERVAL_ERROR_MESSAGE,
  schema,
} from '../components/ServiceClassForm/schema';
import {
  ICalendarAssociatedFields,
  IServiceClassCreateRequest,
  IServiceClassResponse,
  IServiceFieldValues,
  ScheduleType,
  ServiceClassRouteParams,
} from '../components/ServiceClassForm/types';

export const useServiceClassUpdateController = () => {
  // Navigation
  const { serviceClassId, serviceId } = useParams<ServiceClassRouteParams>();
  const history = useHistory();

  // Form
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    setValue,
    getValues,
  } = useForm<IServiceFieldValues>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  // States
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [generalErrorMessageAlert, setGeneralErrorMessageAlert] = useState('');
  const [serviceClassToUpdate, setServiceClassToUpdate] =
    useState<IServiceClassResponse>();
  const [submitError, setSubmitError] = useState('');
  const [loading, setLoading] = useState(false);

  // Callbacks
  const renderValue = useCallback((values: unknown) => {
    return (values as Array<string>).join(', ');
  }, []);

  const getServiceToUpdate = useCallback(async () => {
    const { data: response } = await ServiceClassesService.getServiceClass(
      serviceClassId,
      {
        join: [
          ['service'],
          ['serviceType'],
          ['publicationStatus'],
          ['categories'],
          ['calendars'],
          ['schoolClasses'],
          ['recurrence'],
        ],
      },
    );
    setServiceClassToUpdate(response);
  }, [serviceClassId]);

  const submit = useCallback(
    async (values: IServiceFieldValues) => {
      setLoading(true);
      const parsedValues = { ...values };
      let generalErrorMessageAlert = '';
      let intervalIsValid = true;
      // const [serviceTypeId, serviceTypeName] = values.serviceType.split(':');

      // const haveAmount = !!values.amount && values.amount !== 'R$ 0';
      // generalErrorMessageAlert = validateGeneralErrorsOnSubmit(values);

      if (generalErrorMessageAlert) {
        setGeneralErrorMessageAlert(generalErrorMessageAlert);
      }

      if (values.scheduleCanVaryToEveryDay) {
        parsedValues.schedule = values?.days?.map((day, index) => {
          const dynamicKey = `day-schedule${index === 0 ? '' : `-${index}`}`;
          const daysScheduleHourInterval =
            values[dynamicKey as keyof ICalendarAssociatedFields];

          if (!daysScheduleHourInterval) {
            generalErrorMessageAlert = SCHEDULE_DAYS_ERROR_MESSAGE;
            setGeneralErrorMessageAlert(generalErrorMessageAlert);
          } else {
            intervalIsValid = verifyValidHourInterval(
              daysScheduleHourInterval as string,
            );

            if (!intervalIsValid) {
              generalErrorMessageAlert = SCHEDULE_INTERVAL_ERROR_MESSAGE;
              setGeneralErrorMessageAlert(generalErrorMessageAlert);
            }

            return {
              day,
              schedule: daysScheduleHourInterval,
            };
          }
        }) as ScheduleType[];
      } else {
        parsedValues.schedule = values?.days?.map(day => {
          const daysScheduleHourInterval =
            values[`day-schedule` as keyof ICalendarAssociatedFields];
          if (!daysScheduleHourInterval) {
            generalErrorMessageAlert = SCHEDULE_DAYS_ERROR_MESSAGE;
            setGeneralErrorMessageAlert(generalErrorMessageAlert);
          } else {
            intervalIsValid = verifyValidHourInterval(daysScheduleHourInterval);

            if (!intervalIsValid) {
              generalErrorMessageAlert = SCHEDULE_INTERVAL_ERROR_MESSAGE;
              setGeneralErrorMessageAlert(generalErrorMessageAlert);
            }

            return {
              day,
              schedule: daysScheduleHourInterval,
            };
          }
        }) as ScheduleType[];
      }

      if (!generalErrorMessageAlert) {
        const data: IServiceClassCreateRequest = {
          name: values.name,
          pedagogicSchoolYearId: values.pedagogicSchoolYearId,
          pedagogicUserId: values.pedagogicUserId,
          responsibleCoordinator: values.responsibleCoordinator,
          schoolClasses: values.schoolClasses.map(id => Number(id)),
          // recurrenceId: Number(values.recurrence),
          spotsNumber: values.spotsNumber,
          yearlyTotalHours: values.yearlyTotalHours,
          startDate: parseBrDateToISO(values.startDate),
          endDate: parseBrDateToISO(values.endDate),
          calendars: parsedValues.schedule,
          timeType: values.scheduleCanVaryToEveryDay
            ? ScheduleTypeLabel.EachDayHaveDifferentSchedule
            : ScheduleTypeLabel.EachDayHaveEqualSchedule,
          serviceId: Number(serviceId),
        };

        const { status } = await ServiceClassesService.updateServiceClass(
          serviceClassId,
          data,
        );
        if (status === 200) {
          setSuccessModalOpen(true);
        } else {
          setSubmitError(ERROR_ON_SUBMIT_UPDATE);
        }
      }
      setLoading(false);
    },
    [serviceClassId, serviceId],
  );

  const handleCloseScheduleError = () => {
    setGeneralErrorMessageAlert('');
  };

  const handleCloseSubmitError = () => {
    setSubmitError('');
  };

  const handleCancel = () => {
    setCancelModalOpen(true);
  };

  const handleCloseModal = () => {
    setSuccessModalOpen(false);
    setCancelModalOpen(false);
  };
  const handleCloseModalAndExit = () => {
    setCancelModalOpen(false);
    history.goBack();
  };

  // Effects
  useEffect(() => {
    getServiceToUpdate();
  }, [getServiceToUpdate]);

  return {
    submit,
    handleSubmit,
    errors,
    register,
    control,
    setValue,
    getValues,
    renderValue,
    handleCancel,
    successModalOpen,
    cancelModalOpen,
    handleCloseModal,
    generalErrorMessageAlert,
    handleCloseScheduleError,
    serviceClassToUpdate,
    submitError,
    handleCloseSubmitError,
    loading,
    handleCloseModalAndExit,
  };
};
