import { useAuth } from 'contexts/auth';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';

export const useHomeBannerController = () => {
  // Styles
  const styles = useStyles();

  // Navigation
  const history = useHistory();

  // Custom Hooks
  const { user } = useAuth();

  // States
  const [modal, setModal] = useState(false);

  // Callbacks
  const goToRegistration = () => {
    if (user && user.financialGuardian.isCompleted) {
      const { cpf, rg, address } = user.financialGuardian;
      history.push({
        pathname: `/registrations`,
        state: {
          userId: user.id,
          guardianId: user.financialGuardian.id,
          isAdmin: false,
          userDocuments: {
            cpf,
            rg,
          },
          userAddress: address,
        },
      });
    } else {
      setModal(true);
    }
  };

  return {
    styles,
    modal,
    goToRegistration,
  };
};
