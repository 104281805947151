import { useAuth } from 'contexts/auth';
import { StatusCodes } from 'http-status-codes';
import { useEffect } from 'react';
import ConfigurationsService from 'services/configurationsService';
import { useAppStore } from 'store';

export const useConfigurations = () => {
  const { signed } = useAuth();
  const setConfigurations = useAppStore(
    state => state.configurations.setConfigurations,
  );
  const showAlert = useAppStore(state => state.alert.showAlert);
  useEffect(() => {
    async function getConfigurations() {
      if (!signed) return;

      const { data: response, status } =
        await ConfigurationsService.configurations();

      if (status === StatusCodes.OK && response.data.length) {
        setConfigurations(response.data.shift());
      } else {
        showAlert({
          message: 'Não foi possível carregar as configurações',
          severity: 'error',
        });
      }
    }

    getConfigurations();
  }, [setConfigurations, showAlert, signed]);
};
