import { styled, Theme } from '@material-ui/core';
import { StyledCardProps } from 'pages/Client/MarketplaceHome/types';
import {
  white,
  mediumGray,
  darkGray,
  purple,
  gray,
  black,
  yellow,
} from 'styles/theme';
import { LabelAccessCodeContainerProps } from './types';

export const CardContainer = styled('div')<Theme>(({ theme }) => {
  return {
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'flex-start',
    transition: 'all .2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.02)',
    },
    background: white,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
    borderRadius: '34px',
    height: '401px',
    width: '344px',
    minWidth: '344px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '277px',
      maxWidth: '277px',
      height: '294px',
    },
    '& .card-body': {
      padding: '27px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: '20px 30px 0',
      },
    },
    '& .card-body span': {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '22px',
      letterSpacing: '0.13em',
      color: mediumGray,
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
    '& .card-body p': {
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '27px',
      height: '54px',
      maxWidth: '230px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      letterSpacing: '0.3px',
      color: darkGray,
      marginTop: '23px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
    },
    '& .card-caption': {
      display: 'flex',
      marginTop: '18px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '10px',
      },
    },
    '& .card-caption span': {
      color: darkGray,
      fontWeight: 400,
      fontSize: '16px',
      textAlign: 'right',
      letterSpacing: 'inherit',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
  };
});

export const CardCover = styled('div')<Theme, StyledCardProps>(
  ({ cover, theme }) => {
    return {
      display: 'flex',
      alignItems: 'flex-end',
      width: '100%',
      height: '200px',
      minHeight: '200px',
      borderRadius: '34px 34px 0 0',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundImage: `url("${cover}")`,
      backgroundColor: !cover ? gray : undefined,
      [theme.breakpoints.down('sm')]: {
        height: '97px',
        minHeight: '108px',
        marginBottom: '10px',
      },
    };
  },
);

export const LabelAccessCodeContainer = styled('div')<
  Theme,
  LabelAccessCodeContainerProps
>(({ variant }) => {
  const color = variant === 'secondary' ? black : '#FFFFFF';

  return {
    width: '100%',
    height: '40px',
    backgroundColor: variant === 'secondary' ? yellow : purple,
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '22px',
    color,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    '& .info-icon-tooltip': {
      color,
      fontSize: '20px',
    },
  };
});

export const CardHeader = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '3px',
  };
});

export const Avatar = styled('img')(() => {
  return {
    height: '42px',
    width: '42px',
    borderRadius: '100%',
  };
});
