import { Box, Checkbox, Typography } from '@material-ui/core';
import useGlobalStyles from 'styles';
import { CLAUSE_LIST } from 'texts';
import { MobilePaper, MobileTextList } from './styles';
import { ContractClausesProps } from './types';

const clauses = CLAUSE_LIST.reduce((acc, curr) => {
  return acc.concat(curr);
}, []);

export const MobileContractClauses = ({
  isContractClausesAccepted,
  setIsContractClausesAccepted,
}: ContractClausesProps) => {
  const classes = useGlobalStyles();

  return (
    <MobilePaper elevation={0}>
      <Typography className={classes.mainScreenHeader}>
        <b>Termos</b> de serviço
      </Typography>

      <MobileTextList>
        {clauses.map((clause, index) => (
          <Typography key={index} className={classes.contractScreenSmallText2}>
            {clause}
          </Typography>
        ))}
      </MobileTextList>

      <Box marginTop={3} display="flex" flexDirection="row" alignItems="center">
        <Checkbox
          checked={isContractClausesAccepted}
          color="secondary"
          onClick={() => setIsContractClausesAccepted(prev => !prev)}
        />
        <Typography>Li e aceito os termos</Typography>
      </Box>
    </MobilePaper>
  );
};
