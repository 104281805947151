import React from 'react';
import { Box, Button, Divider, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { CustomIcon } from 'components/CustomIcon';

import { PAYMENT_METHODS } from 'types/enums';
import { formatBRL, formatPaymentMethodCode } from 'utils';
import CustomDialog from 'components/CustomDialog';
import AlertCard from 'components/AlertCard';
import { ServiceComponentProps } from '../types';

import { useStyles } from '../styles';
import PaymentMethodPixOrBankslip from './PaymentMethodComponents/PaymentMethodPixOrBankslip';
import PaymentMethodCard from './PaymentMethodComponents/PaymentMethodCard';
import { useServiceComponentController } from './useServiceComponentController';

const ServiceComponent: React.FC<ServiceComponentProps> = ({
  creditCards,
  loadCards,
  handleCloseCardModal,
  handleOpenCardModal,
  isOpenCardModal,
  user,
  subscription,
  serviceTitle,
  serviceValue = 0,
  serviceId,
  handleChangeSubscription,
  serviceRecurrenceName,
  serviceRegistrationTax = 0,
  maxInstallments = 1,
  registrationTaxValue = 0,
  dependentName,
  schoolClassName,
}) => {
  const styles = useStyles();

  const {
    // Refs
    warningChangePaymentMethodModal,

    // States
    alertMessage,
    alertType,
    isShowAlert,
    calculatedValue,

    // Callbacks
    checkIsButtonSelected,
    handleChangePaymentMethod,
    handleCloseModal,
    handleChangeSubscriptionData,
    closeAlert,

    // ApplyCouponComponent props
    applyCouponProps,
  } = useServiceComponentController({
    serviceId,
    subscription,
    handleChangeSubscription,
    serviceValue,
  });

  const renderPaymentMethod = (serviceId: number) => {
    const { paymentMethodCode: selectedPaymentMethodCode } = subscription;

    switch (selectedPaymentMethodCode) {
      case PAYMENT_METHODS.PIX:
      case PAYMENT_METHODS.BANK_SLIP:
        return (
          <PaymentMethodPixOrBankslip
            title={formatPaymentMethodCode(selectedPaymentMethodCode)}
            paymentMethod={selectedPaymentMethodCode}
            serviceId={serviceId}
            subscription={subscription}
            calculatedServiceValue={calculatedValue}
            handleChangeSubscription={handleChangeSubscription}
            maxInstallments={maxInstallments}
            registrationTaxValue={registrationTaxValue}
            {...applyCouponProps}
          />
        );
      default:
        return user ? (
          <PaymentMethodCard
            creditCards={creditCards}
            handleClose={handleCloseCardModal}
            user={user}
            loadCards={loadCards}
            open={isOpenCardModal}
            handleOpen={handleOpenCardModal}
            subscription={subscription}
            serviceId={serviceId}
            calculatedServiceValue={calculatedValue}
            handleChangeSubscription={handleChangeSubscription}
            maxInstallments={maxInstallments}
            registrationTaxValue={registrationTaxValue}
            {...applyCouponProps}
          />
        ) : null;
    }
  };

  return (
    <>
      <Paper elevation={0} className={styles.paperCard}>
        <Box>
          <Box className={styles.serviceComponentGrid}>
            <Box className={styles.column}>
              <strong>{serviceTitle}</strong>
              <Typography variant="subtitle2">
                {dependentName} - {schoolClassName}
              </Typography>
            </Box>
            <Box className={styles.columnEnd}>
              {`${formatBRL(
                serviceValue,
              )} /${serviceRecurrenceName.toLowerCase()}`}
              <Typography variant="subtitle2">
                Taxa de matrícula: {formatBRL(serviceRegistrationTax)}*
              </Typography>
            </Box>
          </Box>
          <Box className={styles.dividerMargin}>
            <Divider />
          </Box>
        </Box>
        <Box className={styles.gridButtons}>
          <Button
            className={clsx(
              styles.selectionButton,
              (checkIsButtonSelected(PAYMENT_METHODS.CARD) ||
                !subscription.paymentMethodCode) &&
                styles.selectedButton,
            )}
            variant="outlined"
            onClick={() => handleChangePaymentMethod(PAYMENT_METHODS.CARD)}
          >
            <span className={styles.creditCardIcon}>
              <CustomIcon name="creditCardBlack" />
            </span>
            <Typography variant="body2">Cartão de crédito</Typography>
          </Button>
          <Button
            className={clsx(
              styles.selectionButton,
              checkIsButtonSelected(PAYMENT_METHODS.BANK_SLIP) &&
                styles.selectedButton,
            )}
            variant="outlined"
            onClick={() => handleChangePaymentMethod(PAYMENT_METHODS.BANK_SLIP)}
          >
            <span>
              <CustomIcon name="bankSlipAttachMoney" />
            </span>
            <Typography variant="body2">Boleto</Typography>
          </Button>
          <Button
            className={clsx(
              styles.selectionButton,
              checkIsButtonSelected(PAYMENT_METHODS.PIX) &&
                styles.selectedButton,
            )}
            variant="outlined"
            onClick={() => handleChangePaymentMethod(PAYMENT_METHODS.PIX)}
          >
            <span>
              <CustomIcon name="pixBlack" />
            </span>
            <Typography variant="body2">Pix</Typography>
          </Button>
        </Box>
        {renderPaymentMethod(serviceId)}
      </Paper>

      <AlertCard
        message={alertMessage}
        open={isShowAlert}
        close={closeAlert}
        severity={alertType}
      />

      <CustomDialog
        ref={warningChangePaymentMethodModal}
        rawTitle={
          <>
            Ao alterar a forma de pagamento <b>removeremos o cupom</b> aplicado.
            <br />
            Deseja continuar?
          </>
        }
        rawSubtitle={'Não se preocupe, você poderá inserir\no cupom novamente'}
        primaryButton={{
          text: 'Sim, continuar',
          onClick: handleChangeSubscriptionData,
          textVariant: 'bold',
        }}
        secondaryButton={{
          text: 'Não, voltar',
          onClick: handleCloseModal,
          textVariant: 'bold',
        }}
      />
    </>
  );
};

export default ServiceComponent;
