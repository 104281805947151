export const mainAddressFields = [
  {
    label: 'Rua',
    name: 'address.street',
    key: 'street',
  },
  {
    label: 'Bairro',
    name: 'address.district',
    key: 'district',
  },
  {
    label: 'Cidade',
    name: 'address.city',
    key: 'city',
  },
  {
    label: 'Estado',
    name: 'address.state',
    key: 'state',
    inputProps: {
      maxLength: 2,
    },
  },
];

export const TRIES_TO_SEARCH_CEP = 1;
export const READ_ONLY_FIELD = 'Preenchido automaticamente a partir do CEP';
export const EDITABLE_FIELD =
  'CEP não encontrado, preencha o campo manualmente';
