import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  page: {
    padding: 25,
  },
  changePasswordContainer: {
    marginBottom: 35,
  },
  changePasswordBtn: {
    marginTop: 15,
  },
  isCompletedMessage: {
    marginBottom: 40,
  },
});
