import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import EmptyListPlaceholder from 'components/EmptyListPlaceholder';
import ExportExcelButton from 'components/ExportExcelButton';
import PageHeader from 'components/PageHeader';
import { StatusCodes } from 'http-status-codes';
import {
  yearlyReportHeaders,
  yearlyReportValues,
} from 'pages/Admin/FinancialReport/utils';
import React, { useEffect, useState, memo, useMemo } from 'react';
import PaymentService from 'services/paymentService';
import useGlobalStyles from 'styles';
import { NO_PLANS } from 'texts';
import { YearlyReport } from 'types/financialReport';
import { useStyles } from './styles';

const YearlyReportTable = () => {
  const classes = useGlobalStyles();
  const styles = useStyles();
  const [yearlyReport, setYearlyReport] = useState<YearlyReport[]>([]);

  const csvData = useMemo(() => {
    const csv: any[][] = [yearlyReportHeaders];
    yearlyReport.forEach((item: YearlyReport) => {
      csv.push(yearlyReportValues(item));
    });
    return csv;
  }, [yearlyReport]);

  useEffect(() => {
    const loadYearlyReport = async () => {
      const response = await PaymentService.getYearSum();
      if (response.status === StatusCodes.OK) {
        setYearlyReport(response.data);
      }
    };
    loadYearlyReport();
  }, []);

  return yearlyReport.length ? (
    <>
      <PageHeader
        title="Soma anual"
        subtitle="Rendimentos por ano"
        button={<ExportExcelButton data={csvData} fileName="soma_anual.csv" />}
      />
      <Grid item xs={12}>
        <Paper elevation={0} className={styles.paper}>
          <Table className={styles.table}>
            <TableHead>
              <TableRow>
                {yearlyReportHeaders.map(header => (
                  <TableCell key={header}>
                    <Typography className={classes.listScreenHeaderText1}>
                      {header}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {yearlyReport.map(item => (
                <TableRow key={item.ano} hover className={styles.row}>
                  {yearlyReportValues(item).map(report => (
                    <TableCell key={report}>{report}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </>
  ) : (
    <Grid item xs={12} className={styles.grid}>
      <EmptyListPlaceholder message={NO_PLANS} />
    </Grid>
  );
};

export default memo(YearlyReportTable);
