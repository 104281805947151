import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import AlertCard from 'components/AlertCard';
import { DependentCompactBox } from 'components/DependentCompactBox';
import EmptyListPlaceholder from 'components/EmptyListPlaceholder';
import { HomeBanner } from 'components/HomeBanner';
import { GENERAL_ERROR, NO_DEPENDENTS } from 'texts';
import { latestRegistration } from '../Registrations/utils';
import { useHomeController } from './controller';

export const Home = () => {
  const {
    classes,
    styles,
    alert,
    dependents,
    goToDetails,
    closeAlert,
    period,
  } = useHomeController();

  return (
    <Grid
      className={styles.grid}
      container
      direction="column"
      alignItems="flex-start"
    >
      <AlertCard
        message={GENERAL_ERROR}
        open={alert}
        close={closeAlert}
        severity="error"
      />

      <Typography className={classes.mainScreenHeader}>
        <b>Ano letivo</b>
      </Typography>
      <Grid container className={styles.margin} spacing={5}>
        <Grid item xs={12} className={styles.grow}>
          <HomeBanner date={period.end} />
        </Grid>
      </Grid>
      <Typography className={classes.mainScreenHeader}>
        <b>Dependentes</b>
      </Typography>

      <Grid container className={styles.margin} spacing={5}>
        {dependents?.length ? (
          dependents.map(dependent => {
            return (
              <Grid
                key={dependent.id}
                className={styles.grow}
                item
                lg={3}
                md={6}
                xs={12}
                onClick={goToDetails(dependent)}
              >
                <DependentCompactBox
                  registration={latestRegistration(dependent)}
                  dependentName={dependent.name}
                />
              </Grid>
            );
          })
        ) : (
          <EmptyListPlaceholder message={NO_DEPENDENTS} />
        )}
      </Grid>
    </Grid>
  );
};
