import { ChangeEvent, useEffect, useState } from 'react';

import { Grid, MenuItem, TextField } from '@material-ui/core';
import { AttachMoney, RecentActors } from '@material-ui/icons';
import SelectButton from 'components/SelectButton';
import { selectMenuProps } from 'constants/selectMenu';
import useGlobalStyles from 'styles';
import { PAYMENT_METHODS, PAYMENT_TYPES } from 'types/enums';
import { formatBRL } from 'utils';
import { SelectionFieldsProperties } from '../types';
import { installmentsArray } from '../utils';
import { useStyles } from './styles';

const SelectionFields = ({
  hasFreeCoupon,
  paymentType,
  paymentMethod,
  handleChangePaymentType,
  remainingValue,
  installments,
  saveInstallment,
  registration,
}: SelectionFieldsProperties) => {
  const classes = useGlobalStyles();
  const styles = useStyles();
  const [coupon, setCoupon] = useState('');

  const handleChangeCoupon = (e: ChangeEvent<HTMLInputElement>) => {
    setCoupon(e.target.value);
  };

  const handleChangeInstallments = (e: ChangeEvent<HTMLInputElement>) => {
    saveInstallment(e.target.value);
  };

  useEffect(() => {
    setCoupon(registration?.coupon?.name || '');
  }, [registration?.coupon?.name]);

  return (
    <>
      {paymentMethod === PAYMENT_METHODS.CARD ? (
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          spacing={3}
          className={classes.screenInputGrid}
        >
          <Grid item>
            <SelectButton
              text="Cartão único"
              icon={<AttachMoney />}
              action={handleChangePaymentType(false)}
              active={paymentType === PAYMENT_TYPES.COMPLETE}
              disabled={hasFreeCoupon}
            />
          </Grid>
          <Grid item>
            <SelectButton
              text="Pagamento com mais cartões"
              icon={<RecentActors />}
              action={handleChangePaymentType(true)}
              active={paymentType === PAYMENT_TYPES.SPLIT}
              disabled={hasFreeCoupon}
            />
          </Grid>
        </Grid>
      ) : null}
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        spacing={3}
        className={styles.marginB}
      >
        <Grid item className={styles.pRelative}>
          <TextField
            label="Número de parcelas"
            variant="outlined"
            select
            SelectProps={selectMenuProps}
            value={hasFreeCoupon ? 1 : installments}
            disabled={hasFreeCoupon}
            onChange={handleChangeInstallments}
            className={styles.smallField}
          >
            {installmentsArray(registration?.schoolYear, paymentMethod).map(
              item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ),
            )}
          </TextField>
        </Grid>
        {paymentMethod === PAYMENT_METHODS.CARD &&
        paymentType === PAYMENT_TYPES.SPLIT ? (
          <Grid item>
            <TextField
              label="Valor restante"
              variant="outlined"
              value={formatBRL(remainingValue)}
              className={styles.smallField}
            />
          </Grid>
        ) : null}
        <Grid item>
          <TextField
            label={coupon ? 'Cupom aplicado' : 'Sem cupom aplicado'}
            variant="outlined"
            disabled
            value={coupon}
            className={styles.smallField}
            onChange={handleChangeCoupon}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SelectionFields;
