import { CreateQueryParams } from '@nestjsx/crud-request';
import { api, getWithFilter } from 'services/apiService';
import { NewCard } from 'types/card';
import { User } from 'types/user';

export default class CreditCardService {
  static async creditCards(query?: CreateQueryParams) {
    try {
      return await getWithFilter('credit-cards', query);
    } catch (error: any) {
      return error.response;
    }
  }

  static async createCardWithToken(card: NewCard, user: User, token: string) {
    try {
      return await api.post('credit-cards', { card, user, token });
    } catch (error: any) {
      return error.response;
    }
  }

  static async updateCard(id: number, isBlocked: boolean) {
    try {
      return await api.patch(`credit-cards/${id}`, { isBlocked });
    } catch (error: any) {
      return error.response;
    }
  }

  static async deleteCard(id: number) {
    try {
      return await api.delete(`credit-cards/${id}`);
    } catch (error: any) {
      return error.response;
    }
  }
}
