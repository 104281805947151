import { CreateQueryParams } from '@nestjsx/crud-request';
import {
  api,
  getAxiosResponseMessageErrorOrDefault,
  getWithFilter,
} from 'services/apiService';
import {
  InvoiceGetMonthlyReport,
  InvoiceEntity as InvoiceInterface,
} from 'types/invoice';
import { InvoiceEntity } from 'utils/entities/InvoiceEntity';
import { CreateInvoiceManual } from './types/invoice.service.types';

export default class InvoicesService {
  static async getInvoices(params: CreateQueryParams) {
    try {
      const results = await getWithFilter<InvoiceInterface>(
        '/invoices',
        params,
      );

      const mapToEntity = results.data.data.map(
        invoice => new InvoiceEntity(invoice),
      );
      return {
        ...results.data,
        data: mapToEntity,
      };
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }

  static async getMonthlyReport(params: CreateQueryParams) {
    try {
      return await getWithFilter<InvoiceGetMonthlyReport>(
        '/invoices/monthly-report',
        params,
      );
    } catch (error: any) {
      return error.response;
    }
  }

  static async getInvoicesFromPaymentId<T>(
    paymentId: number,
    params: CreateQueryParams,
  ) {
    try {
      return await getWithFilter<T>('/invoices', {
        ...params,
        search: {
          paymentId: {
            $eq: paymentId,
          },
        },
      });
    } catch (error: any) {
      return error.response;
    }
  }

  static async syncWithNFeio(invoiceId: number) {
    try {
      return await api.post(`/invoices/sync-invoice-with-nfe`, {
        invoiceIds: [invoiceId],
      });
    } catch (error: any) {
      return error.response;
    }
  }

  static async cancel(invoiceId: number) {
    try {
      return await api.delete(`/invoices/${invoiceId}`);
    } catch (error: any) {
      return error.response;
    }
  }

  static async downloadPDF(invoiceId: number) {
    try {
      return await api.get(`/invoices/${invoiceId}/pdf`);
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }

  static async createManual(invoice: CreateInvoiceManual) {
    try {
      return await api.post(`/invoices/create-manual`, invoice);
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }

  static async forceIssue(invoiceId: number | string) {
    try {
      return await api.put(`/invoices/${invoiceId}/force-issue`);
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }
}
