import { Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';
import { BlueCardInformationsProps } from './types';

const BlueCardInformations: React.FC<BlueCardInformationsProps> = ({
  title,
  descriptionText,
}) => {
  const styles = useStyles();
  return (
    <Paper elevation={0} className={styles.paper}>
      <Typography variant="h6" className={styles.paperTitle}>
        {title}
      </Typography>
      <div>
        <Typography className={styles.paperDescriptionText}>
          {descriptionText}
        </Typography>
      </div>
    </Paper>
  );
};

export default BlueCardInformations;
