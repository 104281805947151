import { List, Paper, styled } from '@material-ui/core';

export const MobilePaper = styled(Paper)(() => {
  return {
    padding: 12,
    borderRadius: 16,
  };
});

export const MobileTextList = styled(List)(() => {
  return {
    paddingRight: 10,
    overflow: 'auto',
    maxHeight: 300,
  };
});
