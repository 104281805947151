import { sortBy } from 'lodash';
import { Payment } from 'types/payment';
import { getBillStatusDescription } from './getBillStatusDescription';
import { getPaidStatusDescription } from './getPaymentStatusDescription';

export const getPaymentStatusFromFirstBill = ({
  bills = [],
  paymentMethodCode,
  status,
  paymentFailReason,
  paymentStartDate,
}: Pick<
  Payment,
  | 'bills'
  | 'paymentMethodCode'
  | 'status'
  | 'paymentFailReason'
  | 'paymentStartDate'
>) => {
  const orderedBills = sortBy(bills, 'id');
  if (orderedBills?.[0]) {
    return getBillStatusDescription(orderedBills?.[0], status);
  }

  return getPaidStatusDescription({
    paymentMethodCode,
    status,
    paymentFailReason,
    paymentStartDate,
  });
};
