import { Payment } from './payment';

export type Order = {
  payments: Payment[];
  status: ORDER_STATUS;
};

export enum ORDER_STATUS {
  DRAFT_ERROR = 'Ocorreu um erro ao criar o pedido',
  DRAFT = 'Rascunho',
  PENDING_CONTRACT_SIGNATURE = 'Pendente de assinatura de contrato',
  QUEUE = 'Em fila',
  PROCESSING = 'Em processamento',
  CONCLUDED = 'Concluido',
  CONCLUDED_WITH_ERROR = 'Concluido com erro',
}
