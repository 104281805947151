import React from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import { ForumOutlined, AssignmentOutlined } from '@material-ui/icons';
import AlertCard from 'components/AlertCard';
import { DashboardRegistrationCountCards } from 'components/RegCountCard';
import RegistrationsTable from 'components/RegistrationsTable';
import { GENERAL_ERROR } from 'texts';
import PageHeader from 'components/PageHeader';
import { CustomIcon } from 'components/CustomIcon';
import { InputText } from './styles';
import { YEARS_ARRAY } from '../AdmRegistrations/utils';
import { useDashboardController } from './controller';

const AdmRegistrations = () => {
  const {
    alert,
    classes,
    countRegistrations,
    handleChangeSearch,
    handleChangeYear,
    registrations,
    styles,
    setAlert,
    year,
    searchField,
  } = useDashboardController();
  return (
    <Grid
      className={classes.listScreenGrid}
      container
      direction="column"
      alignItems="flex-start"
    >
      <AlertCard
        message={GENERAL_ERROR}
        open={alert}
        close={() => setAlert(false)}
        severity="error"
      />
      <Grid container item direction="column">
        <PageHeader
          title="Painel do"
          titleBoldSufix="administrador"
          subtitle="Tenha acesso as informações dos usuários cadastrados na plataforma"
        />
        <Grid item container justifyContent="space-around" spacing={5}>
          <DashboardRegistrationCountCards
            header={`ANO ${year}`}
            countInterviewRequest={
              countRegistrations.pendinginterviewcurrentyear
            }
            iconInterviewRequest={
              <ForumOutlined className={classes.regCountIcon} />
            }
            titleInterviewRequest={
              <>
                Solicitações de <b>entrevista</b>
              </>
            }
            iconActiveEnrollment={
              <AssignmentOutlined className={classes.regCountIcon} />
            }
            titleActiveEnrollment={
              <>
                Matrículas <b>ativas</b>
              </>
            }
            countActiveEnrollment={
              countRegistrations.activeregistrationscurrentyear
            }
          />

          <DashboardRegistrationCountCards
            header={`ANO ${year + 1}`}
            countInterviewRequest={countRegistrations.pendinginterviewnextyear}
            iconInterviewRequest={
              <ForumOutlined className={classes.regCountIcon} />
            }
            titleInterviewRequest={
              <>
                Solicitações de <b>entrevista</b>
              </>
            }
            iconActiveEnrollment={
              <AssignmentOutlined className={classes.regCountIcon} />
            }
            titleActiveEnrollment={
              <>
                Matrículas <b>ativas</b>
              </>
            }
            countActiveEnrollment={
              countRegistrations.activeregistrationsnextyear
            }
          />
        </Grid>
        <Divider className={styles.divider} />
        <PageHeader title="Matrículas" />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <InputText
              id="search"
              name="search"
              label="Buscar"
              variant="outlined"
              onChange={handleChangeSearch}
              value={searchField}
              InputProps={{
                startAdornment: <CustomIcon name="search" />,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <InputText
              label="Ano"
              variant="outlined"
              select
              onChange={handleChangeYear}
            >
              <MenuItem>Todos</MenuItem>
              {YEARS_ARRAY.map(year => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </InputText>
          </Grid>
        </Grid>
        <RegistrationsTable isShortened registrations={registrations} />
      </Grid>
    </Grid>
  );
};

export default AdmRegistrations;
