import {
  NestCrudTableFetchData,
  NestCrudTableFetchRequest,
} from 'components/NestCrudTable/types';

import InvoicesService from 'services/invoicesService';

import { SCondition } from '@nestjsx/crud-request';
import { FiltersUpdatedCallback } from 'components/CustomSearchFilters/types';
import { getMonthPeriod } from 'utils/dateUtils';
import { InvoiceEntity } from 'utils/entities/InvoiceEntity';

import { useAuth } from 'contexts/auth';
import { useCallback, useState } from 'react';
import { InvoiceTableProps } from './types';

export const useFilteredInvoicesTableController = ({
  paymentId,
}: Pick<InvoiceTableProps, 'paymentId'>) => {
  const [invoiceFilters, setInvoiceFilters] = useState<SCondition>(
    paymentId
      ? {
          paymentId: {
            $eq: paymentId,
          },
        }
      : {},
  );

  const { isAdmin, isDeveloper } = useAuth();

  const fetchInvoices: NestCrudTableFetchData<InvoiceEntity> = useCallback(
    async ({ page, perPage }: NestCrudTableFetchRequest) => {
      const response = await InvoicesService.getInvoices({
        search: invoiceFilters as SCondition,
        limit: perPage,
        page,
        fields: [
          'id',
          'competenceDate',
          'number',
          'externalId',
          'value',
          'internalStatus',
          'serviceAmount',
          'serviceDiscountUnconditionedAmount',
          'source',
          'issuedDate',
        ],
        sort: {
          field: 'competenceDate',
          order: 'ASC',
        },
        join: [
          { field: 'financialGuardian', select: ['id', 'userId'] },
          { field: 'financialGuardian.user', select: ['id', 'name'] },
          { field: 'dependent', select: ['id', 'name'] },
          { field: 'service', select: ['id', 'name'] },
        ],
      });

      return {
        data: response.data,
        total: response.total,
      };
    },
    [invoiceFilters],
  );

  const handleFiltersUpdated: FiltersUpdatedCallback = filters => {
    const { startMonthDate, endMonthDate } = getMonthPeriod(
      filters.competenceDate as string,
    );
    const startMonthDateStr = startMonthDate.toISOString();
    const endMonthDateStr = endMonthDate.toISOString();

    setInvoiceFilters(_prevState => {
      const andFilter: any[] = [];

      if ((filters.internalStatus as string[])?.length) {
        andFilter.push({
          internalStatus: {
            $in: filters.internalStatus,
          },
        });
      }

      if (paymentId) {
        andFilter.push({
          paymentId: {
            $eq: paymentId,
          },
        });
      }

      if (filters.competenceDate) {
        andFilter.push({
          $or: [
            {
              issuedDate: {
                $between: [startMonthDateStr, endMonthDateStr],
              },
            },
            {
              competenceDate: {
                $between: [startMonthDateStr, endMonthDateStr],
              },
              issuedDate: {
                $eq: null,
              },
            },
          ],
        });
      }

      if (filters.search) {
        const search = filters.search as string;
        andFilter.push({
          $or: [
            {
              'dependent.name': {
                $contL: search,
              },
            },
            {
              'financialGuardian.user.name': {
                $contL: search,
              },
            },
          ],
        });
      }

      return {
        $and: andFilter,
      } as SCondition;
    });
  };

  return {
    isDeveloper,
    fetchInvoices,
    handleFiltersUpdated,
    isAdmin,
  };
};
