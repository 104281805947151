import { useHistory } from 'react-router-dom';
import useGlobalStyles from 'styles';
import { TABLE_HEADERS } from 'utils/tableHeaders';
import { useStyles } from './styles';

const headers = [
  TABLE_HEADERS.NAME,
  TABLE_HEADERS.VALUE_PIX,
  TABLE_HEADERS.VALUE_CREDIT,
  TABLE_HEADERS.VALUE_BANK_SLIP,
  TABLE_HEADERS.QUANTITY,
  TABLE_HEADERS.INIT_AND_END,
];

export const useCouponsTableController = () => {
  // Styles
  const classes = useGlobalStyles();
  const styles = useStyles();

  // Navigation
  const history = useHistory();

  // Callbacks
  const navigateToDetail = (id: number) => {
    history.push(`/adm-coupons/${id}`);
  };

  return {
    classes,
    styles,
    headers,
    navigateToDetail,
  };
};
