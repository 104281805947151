import { useAuth } from 'contexts/auth';
import { format } from 'date-fns';
import { formatGrade } from 'pages/Admin/AdmRegistrations/utils';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ADMIN_ROUTE, CLIENT_ROUTE } from 'routes/consts';
import { CONTRACT_STATUS } from 'types/contractInfo';
import { PAYMENT_SOURCE } from 'types/enums';
import { TABLE_HEADERS } from 'utils/tableHeaders';
import { FinancialReportCollapsableTableRowProps } from './types';

export const useFinancialReportCollapsableTableRowController = ({
  payment,
}: Pick<FinancialReportCollapsableTableRowProps, 'payment'>) => {
  // ---------- Custom hooks ----------
  const history = useHistory();
  const { isAdmin } = useAuth();

  // ---------- Memos ----------
  const subTableHeaders1 = useMemo(() => {
    return [
      TABLE_HEADERS.REGISTRATION,
      payment.source === PAYMENT_SOURCE.REGISTRATION
        ? TABLE_HEADERS.YEAR_OR_GRADE
        : TABLE_HEADERS.SERVICE_CLASS,
      TABLE_HEADERS.CONTRACT_STATUS,
      TABLE_HEADERS.CONTRACT_SIGNATURE_DATE,
    ];
  }, [payment.source]);

  const subTableHeaders2 = useMemo(
    () => [
      TABLE_HEADERS.INSTALLMENTS,
      TABLE_HEADERS.FINANCIAL_GUARDIAN,
      TABLE_HEADERS.PAYMENT_DETAILS,
    ],
    [],
  );

  // ---------- Callbacks ----------
  const navigateToFinancialDetails = useCallback(() => {
    if (isAdmin) {
      history.push(ADMIN_ROUTE.PAYMENT_DETAILS.replace(':id', `${payment.id}`));
    } else {
      history.push(
        CLIENT_ROUTE.FINANCIAL_DETAILS.replace(':id', `${payment.id}`),
      );
    }
  }, [history, isAdmin, payment.id]);

  const renderContractSignDate = useCallback((contractSignDate?: string) => {
    if (!contractSignDate) return '-';

    return format(new Date(contractSignDate), 'dd/MM/yyyy');
  }, []);

  const renderSchoolClassOrServiceClassName = useCallback(() => {
    if (payment.source === PAYMENT_SOURCE.REGISTRATION) {
      return formatGrade(payment.referenceGrade);
    }

    return payment.serviceClass?.name || '-';
  }, [payment.referenceGrade, payment.serviceClass?.name, payment.source]);

  const renderContractStatus = useCallback(() => {
    if (payment.contractInfo?.signDate) return CONTRACT_STATUS.SIGNED;

    return CONTRACT_STATUS.EMITTED;
  }, [payment.contractInfo]);

  const renderTotalNumberOfInstallments = useCallback(
    () =>
      payment.subscriptions.reduce((acc, curr) => acc + curr.installments, 0),
    [payment.subscriptions],
  );

  return {
    subTableHeaders1,
    subTableHeaders2,
    navigateToFinancialDetails,
    renderContractSignDate,
    renderSchoolClassOrServiceClassName,
    renderContractStatus,
    renderTotalNumberOfInstallments,
  };
};
