import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  pixImage: {
    width: '170px',
    height: '170px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '290px',
    },
  },
  copyPixButton: {
    marginTop: '20px',
    textTransform: 'initial',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
