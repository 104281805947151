import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: '0px 6px 32px rgba(9, 13, 27, 0.07)',
    fontSize: 14,
    borderRadius: 16,
    padding: 20,
  },
}))(Tooltip);

export default LightTooltip;
