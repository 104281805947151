import { USER_ROLES } from 'types/enums';

// WARNING: when changing this array, make sure to also change it in backend
// filterUser.ts => isAdmin()
export const REQUIRED_ROLES_ADMIN = [
  USER_ROLES.ADMIN,
  USER_ROLES.TREASURY,
  USER_ROLES.SECRETARY,
  USER_ROLES.COORDINATOR,
];

export const isAdmin = (roles: Array<string> = []) => {
  return REQUIRED_ROLES_ADMIN.some(role => roles?.includes(role));
};
