import { without } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import { PAYMENT_METHODS, PAYMENT_TYPES } from 'types/enums';

import { Coupon } from 'types/coupon';
import { RouteParams } from 'types/routeParams';

import { CustomDialogRef } from 'components/CustomDialog/types';
import { StatusCodes } from 'http-status-codes';
import { CLIENT_ROUTE } from 'routes/consts';
import PaymentService from 'services/paymentService';
import RegistrationService from 'services/registrationService';
import { useAppStore } from 'store';
import { GENERAL_ERROR } from 'texts';
import { Registration } from 'types/registration';
import { formatBRL, unformatValueMask } from 'utils';
import { shallow } from 'zustand/shallow';
import { PaymentCardOption, PaymentSelectionData } from './types';
import { PAYMENT_STEPS, calcRemainingValue, validationSchema } from './utils';

export const usePaymentMethodController = () => {
  const { showAlert, configurations } = useAppStore(
    state => ({
      showAlert: state.alert.showAlert,
      configurations: state.configurations.data,
    }),
    shallow,
  );
  const successDialogRef = useRef<CustomDialogRef>(null);
  const { id: registrationId } = useParams<RouteParams>();
  const [currentRegistration, setCurrentRegistration] =
    useState<Registration>();
  const history = useHistory();
  const [originalValue, setOriginalValue] = useState(0);
  const [originalValueWithDiscount, setOriginalValueWithDiscount] = useState(0);

  const [isLoadingRegistration, setIsLoadingRegistration] = useState(true);
  const [paymentType, setPaymentType] = useState<PAYMENT_TYPES>(
    PAYMENT_TYPES.COMPLETE,
  );
  const [appliedCoupon, setAppliedCoupon] = useState<Coupon | null>(
    currentRegistration?.coupon || null,
  );
  const [installments, setInstallments] = useState(1);
  const [subscriptions, setSubscriptions] = useState<PaymentCardOption[]>([
    {
      value: '',
      installments,
      card: undefined,
    },
  ]);
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHODS.CARD);
  const [paymentStep, setPaymentStep] = useState<PAYMENT_STEPS>(
    PAYMENT_STEPS.CONTRACT_CLAUSES,
  );
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [draftPaymentId, setDraftPaymentId] = useState<number | null>(null);
  const [isContractClausesAccepted, setIsContractClausesAccepted] =
    useState(false);

  const goToPage = (page: string) => {
    history.push(page);
  };

  const {
    register,
    handleSubmit: handleSubmitForm,
    errors,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onBlur',
  });

  const isSplitPayment = useMemo(
    () =>
      paymentType === PAYMENT_TYPES.SPLIT &&
      paymentMethod === PAYMENT_METHODS.CARD,
    [paymentType, paymentMethod],
  );

  // FIXME: A IDEIA É BOA, MAS ISSO TA RUIM
  const remainingValue = useMemo(() => {
    return (
      originalValueWithDiscount -
      subscriptions.reduce((acc, item) => {
        return acc + unformatValueMask(item.value);
      }, 0)
    );
  }, [originalValueWithDiscount, subscriptions]);

  const hasFreeCoupon = useMemo(
    () =>
      Number(appliedCoupon?.percent) >= 100 ||
      Number(appliedCoupon?.value) >= originalValueWithDiscount,
    [appliedCoupon, originalValueWithDiscount],
  );

  const canProceed = useMemo(() => {
    if (!isContractClausesAccepted) return false;
    if (
      paymentStep === PAYMENT_STEPS.CONTRACT_CLAUSES &&
      isContractClausesAccepted
    )
      return true;
    if (hasFreeCoupon) return true;
    if (installments <= 0) return false;
    if (paymentMethod === PAYMENT_METHODS.BANK_SLIP) return true;
    if (paymentMethod === PAYMENT_METHODS.PIX) return true;
    if (paymentMethod === PAYMENT_METHODS.CARD) return true;
    if (paymentType === PAYMENT_TYPES.SPLIT) return remainingValue === 0;
    if (paymentType === PAYMENT_TYPES.COMPLETE) return !!subscriptions[0].card;
    return false;
  }, [
    isContractClausesAccepted,
    paymentStep,
    hasFreeCoupon,
    installments,
    paymentMethod,
    paymentType,
    remainingValue,
    subscriptions,
  ]);

  const getRegistration = useCallback(async () => {
    const response = await RegistrationService.registration(registrationId, {
      join: [
        ['schoolClass'],
        ['coupon'],
        ['financialGuardian'],
        ['financialGuardian.user'],
        ['dependent'],
        ['schoolClassDetails'],
        // Required to check re registration flow
        { field: 'nextRegistration', select: ['id'] },
      ],
    });
    if (response.status === StatusCodes.OK) {
      const registrationData = response.data as Registration;
      setCurrentRegistration(response.data);
      setAppliedCoupon(registrationData?.coupon);
      setOriginalValue(registrationData.schoolClassDetails.value);
      setAppliedCoupon(registrationData.coupon);
    } else {
      showAlert({
        message: response.data?.message || GENERAL_ERROR,
        severity: 'error',
      });
    }

    setIsLoadingRegistration(false);
  }, [registrationId, showAlert]);

  useEffect(() => {
    getRegistration();
  }, [getRegistration]);

  // Aqui reune os dados do pagamento de acordo com a seleção do usuario na interface
  // no fim esse objeto é usado pra enviar pro backend
  const paymentSelectionData: PaymentSelectionData = useMemo(() => {
    return {
      cardOptions: subscriptions,
      originalValueWithDiscount,
      coupon: appliedCoupon,
      originalValue,
      hasFreeCoupon,
      method: paymentMethod,
    };
  }, [
    subscriptions,
    originalValue,
    originalValueWithDiscount,
    appliedCoupon,
    hasFreeCoupon,
    paymentMethod,
  ]);

  // FIXME: esse data é um objeto do tipo { subscriptions?: { value: number }[] }
  const onSubmit = async (data: any) => {
    setSubscriptions(prevState =>
      prevState.map((subscription, index) => ({
        ...subscription,
        value: isSplitPayment
          ? data.subscriptions[index].value
          : data.value || subscription.value,
      })),
    );

    setPaymentStep(PAYMENT_STEPS.CONFIRMATION);
  };

  // Não sei porque precisa disso, mas vou continuar pra nao quebrar
  // calculates the remaining value to complete the payment
  const handleCalcRemainingValue = useCallback(async () => {
    const parsedSubscriptions = isSplitPayment
      ? subscriptions
      : [
          {
            value: 'R$ 0',
            installments,
            card: undefined,
          },
        ];

    const { currentValue } = await calcRemainingValue(
      parsedSubscriptions,
      originalValue,
      appliedCoupon,
      paymentMethod,
    );

    setOriginalValueWithDiscount(currentValue);
    setSubscriptions(prevState =>
      prevState.map(subscriptionItem => ({
        ...subscriptionItem,
        value: isSplitPayment
          ? subscriptionItem.value
          : formatBRL(currentValue),
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentType, paymentMethod, installments, originalValue, appliedCoupon]);

  useEffect(() => {
    handleCalcRemainingValue();
  }, [handleCalcRemainingValue]);

  const saveSubscriptionInstallments = (installmentsOption: string) => {
    // FIXME: não faz sentido ter 2 estados diferentes pra mesma coisa
    setInstallments(Number(installmentsOption));
    setSubscriptions(prevState =>
      prevState.map(subscriptionItem => ({
        ...subscriptionItem,
        installments: Number(installmentsOption),
      })),
    );
  };

  const handleChangePaymentType = (split: boolean) => () => {
    if (split) {
      setPaymentType(PAYMENT_TYPES.SPLIT);
    } else {
      setPaymentType(PAYMENT_TYPES.COMPLETE);
      /**
       * the value has to be 0 here
       * the calculation will then only take into account the isntallments and coupon
       */

      setSubscriptions(prevSubscription => [
        {
          value: 'R$ 0',
          installments,
          card: prevSubscription?.[0]?.card,
        },
      ]);
    }
  };

  const handleChangePaymentMethod = (method: PAYMENT_METHODS) => () => {
    setPaymentMethod(method);
  };

  const addCard = () => {
    setSubscriptions([
      ...subscriptions,
      {
        value: '',
        installments,
        card: undefined,
      },
    ]);
  };

  const removeCard = (index: number) => () => {
    setSubscriptions(without(subscriptions, subscriptions[index]));
  };

  const handleCreatePayment = async () => {
    if (!currentRegistration) return;
    setIsLoadingPayment(true);

    try {
      const { data: draftPayment } =
        await PaymentService.createDraftRegistrationPayment({
          registrationId: currentRegistration.id,
          paymentMethodCode: paymentSelectionData.method,
          installments,
          subscriptions: paymentSelectionData.cardOptions.map(cardOption => ({
            value: unformatValueMask(cardOption.value),
            cardId: cardOption.card?.id as number,
          })),
        });

      setDraftPaymentId(draftPayment.id);

      successDialogRef.current?.openDialog();
    } catch (error: any) {
      showAlert({
        message:
          error?.message ||
          'Ocorreu um erro ao criar a ordem de compra. Verifique as informações de pagamento e tente novamente.',
        severity: 'error',
      });
    } finally {
      setIsLoadingPayment(false);
    }
  };

  const goToContract = () => {
    history.replace(
      CLIENT_ROUTE.REGISTRATION_SIGN_CONTRACT.replace(
        ':id',
        String(draftPaymentId),
      ),
    );
  };

  const handleSubmit = () => {
    switch (paymentStep) {
      case PAYMENT_STEPS.SELECTION:
        return handleSubmitForm(onSubmit)();
      case PAYMENT_STEPS.CONTRACT_CLAUSES:
        setPaymentStep(PAYMENT_STEPS.SELECTION);
        return;
      case PAYMENT_STEPS.CONFIRMATION:
        return handleCreatePayment();
      default:
        console.error(`Falha no fluxo. Etapa inválida: ${paymentStep}`);
        break;
    }
  };

  const renderSubmitTextButton = () => {
    switch (paymentStep) {
      case PAYMENT_STEPS.CONFIRMATION:
        return 'Ir para contrato';
      default:
        return 'Continuar';
    }
  };

  const handleBackToSelection = () => {
    setPaymentStep(PAYMENT_STEPS.SELECTION);
  };

  return {
    handleChangePaymentMethod,
    handleChangePaymentType,
    saveSubscriptionInstallments,
    canProceed,
    handleSubmit,
    onSubmit,
    paymentMethod,
    hasFreeCoupon,
    paymentType,
    remainingValue,
    installments,
    register,
    errors,
    goToPage,
    currentRegistration,
    isLoadingRegistration,
    subscriptions,
    configurations,
    setSubscriptions,
    appliedCoupon,
    setAppliedCoupon,
    addCard,
    removeCard,
    handleCreatePayment,
    paymentStep,
    paymentSelectionData,
    renderSubmitTextButton,
    handleBackToSelection,
    isLoadingPayment,
    originalValue,
    originalValueWithDiscount,
    goToContract,
    successDialogRef,
    setIsContractClausesAccepted,
    isContractClausesAccepted,
  };
};
