import { CircularProgress, Grid, MenuItem, TextField } from '@material-ui/core';
import { CustomButton } from 'components/CustomButton';
import Scaffold from 'components/Scaffold';
import { MyTable } from 'components/Table';
import { TableColumnConfig } from 'components/Table/types';
import { selectMenuProps } from 'constants/selectMenu';
import React, { useMemo } from 'react';
import { TABLE_HEADERS } from 'utils/tableHeaders';
import { styles } from './styles';
import { TableViewStudentReportCard } from './types';
import { useViewStudentReportCardsController } from './viewStudentReportCardsController';

const ViewStudentReportCards: React.FC = () => {
  const {
    isLoading,
    studentReportCardData,
    classes,
    openPdfUrl,
    referenceYear,
    handleChangeReferenceYear,
    pedagogicSchoolYears,
  } = useViewStudentReportCardsController();

  const tableConfig: TableColumnConfig<TableViewStudentReportCard>[] = useMemo(
    () => [
      {
        headerName: TABLE_HEADERS.DEPENDENT,
        getter: 'portalDependentName',
        onFormat: undefined,
        defaultValue: '-',
      },
      {
        headerName: TABLE_HEADERS.STUDENT_REPORT_CARD,
        getter: 'pdfUrl',
        onFormat: undefined,
        defaultValue: '-',
        customRender: pdfUrl => {
          return (
            <CustomButton
              onClick={() => openPdfUrl(pdfUrl)}
              variant="secondary"
              textVariant="bold"
            >
              Baixar
            </CustomButton>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (isLoading) {
    return (
      <Grid style={styles.gridLoading}>
        <CircularProgress size={50} />
      </Grid>
    );
  }

  return (
    <Scaffold
      rawTitle="Boletim do(s) dependente(s)"
      rawSubtitle="Faça o download do boletim do dependente."
    >
      <Grid item>
        <TextField
          label="Ano"
          variant="outlined"
          select
          SelectProps={selectMenuProps}
          value={referenceYear}
          onChange={handleChangeReferenceYear}
          className={classes.filterField}
        >
          {pedagogicSchoolYears.map(({ year }) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <MyTable<any>
        data={studentReportCardData}
        tableConfig={tableConfig}
        isLoading={isLoading}
        emptyTableMessage="Não há dados para serem exibidos."
      />
    </Scaffold>
  );
};

export default ViewStudentReportCards;
