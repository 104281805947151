export const getObjectProperty = <T, IValue = any>(
  value: T,
  path: string,
  defaultValue?: string,
): IValue => {
  const result = String(path)
    ?.split('.')
    ?.reduce((acc: any, path) => {
      try {
        acc =
          acc[path] !== undefined && acc[path] !== null
            ? acc[path]
            : defaultValue;
      } catch (e) {
        return defaultValue;
      }
      return acc;
    }, value || []);
  return result as IValue;
};
