import { MenuItem } from 'components/HeaderBar/types';
import { useAuth } from 'contexts/auth';
import useNewWindow from 'hooks/newWindow';
import { useMemo, useState } from 'react';
import InvoicesService from 'services/invoicesService';
import { useAppStore } from 'store';
import { SnackbarLoadingConfig } from 'store/loadingSnackbar/types';
import { isAdmin } from 'utils/isAdmin';
import { isDeveloper } from 'utils/isDeveloper';
import { nfeioUtils } from 'utils/nfeio';
import { FeedbackMessages } from './consts';
import { ButtonInvoiceActionProps } from './types';

export const useButtonInvoiceActionsController = ({
  invoiceData,
}: ButtonInvoiceActionProps) => {
  const { user: loggedUser } = useAuth();
  const [isActionsDisabled, setIsActionsDisabled] = useState(false);
  const { loading: loadingWindow, newWindow } = useNewWindow();
  const { closeLoading, showLoading, isLoading } = useAppStore(
    state => state.snackbarLoading,
  );
  const showAlert = useAppStore(state => state.alert.showAlert);

  const goToNfeioDetails = () => {
    window.open(nfeioUtils.getInvoiceUrl(invoiceData.externalId), '_blank');
  };

  const handleShowLoading = (loadingConfig?: SnackbarLoadingConfig) => {
    setIsActionsDisabled(true);
    showLoading(loadingConfig);
  };

  const handleCloseLoading = () => {
    setIsActionsDisabled(false);
    closeLoading();
  };

  const downloadPdf = async () => {
    try {
      handleShowLoading({
        message: FeedbackMessages.DOWNLOADING_PDF,
      });
      const response = await InvoicesService.downloadPDF(invoiceData.id);
      if (response.status === 200) {
        newWindow(response.data);
      } else {
        throw new Error(response?.data?.error);
      }
    } catch (error: any) {
      showAlert({
        message: error?.message || FeedbackMessages.ERROR_DOWNLOADING_PDF,
        severity: 'error',
      });
    } finally {
      handleCloseLoading();
    }
  };

  const cancelInvoice = async () => {
    try {
      handleShowLoading({
        message: FeedbackMessages.CANCELING_INVOICE,
      });
      const response = await InvoicesService.cancel(invoiceData.id);
      if (response.status === 200) {
        showAlert({
          title: 'Tudo certo!',
          message: FeedbackMessages.SUCCESS_CANCELING_INVOICE,
          severity: 'success',
        });
      } else {
        throw new Error(response?.data?.error);
      }
    } catch (error: any) {
      showAlert({
        title: 'Opss! Ocorreu um erro.',
        message: error?.message || FeedbackMessages.ERROR_CANCELING_INVOICE,
        severity: 'error',
      });
    } finally {
      handleCloseLoading();
    }
  };

  const syncWithNFeio = async () => {
    try {
      handleShowLoading();
      const response = await InvoicesService.syncWithNFeio(invoiceData.id);
      if (response.status === 201) {
        showAlert({
          message: FeedbackMessages.SUCCESS_SYNCING_WITH_NFEIO,
          severity: 'success',
        });
      } else {
        throw new Error(response?.data?.error);
      }
    } catch (error: any) {
      showAlert({
        message: error?.message || FeedbackMessages.ERROR_SYNCING_WITH_NFEIO,
        severity: 'error',
      });
    } finally {
      handleCloseLoading();
    }
  };

  const handleIssueNow = async () => {
    try {
      handleShowLoading();
      await InvoicesService.forceIssue(invoiceData.id);
      showAlert({
        message: FeedbackMessages.SUCCESS_ISSUING_INVOICE,
        severity: 'success',
      });
    } catch (error: any) {
      showAlert({
        message: error?.message || FeedbackMessages.ERROR_ISSUING_INVOICE,
        severity: 'error',
      });
    } finally {
      handleCloseLoading();
    }
  };

  const menuItems: MenuItem[] = useMemo(() => {
    const menuItems: MenuItem[] = [];

    const isAdminUser = isAdmin(loggedUser?.roles);
    const isDeveloperUser = isDeveloper(loggedUser?.roles);

    if (invoiceData.isIssued) {
      menuItems.push({
        title: 'Baixar PDF',
        action: downloadPdf,
      });
    }

    if (isAdminUser && invoiceData.externalId) {
      menuItems.push({
        title: 'Acessar na NFe.io',
        action: goToNfeioDetails,
      });
      menuItems.push({
        title: 'Sincronizar com NFe.io',
        action: syncWithNFeio,
      });

      if (!invoiceData.isCanceled) {
        menuItems.push({
          title: 'Cancelar NF',
          action: cancelInvoice,
        });
      }
    }

    if (isDeveloperUser && !invoiceData.externalId) {
      menuItems.push({
        title: 'Emitir agora',
        action: handleIssueNow,
      });
    }

    return menuItems;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceData.externalId, loggedUser?.roles]);

  return {
    loading: isLoading || loadingWindow,
    isActionsDisabled,
    alert,
    menuItems,
  };
};
