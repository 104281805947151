import { makeStyles, styled } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { darkGray, lightBoxShadow, primaryDark } from 'styles/theme';

export const useStyles = makeStyles({
  pRelative: {
    position: 'relative',
  },
  cardRowField: {
    height: 120,
  },
  headerField: {
    width: 350,
  },
  smallField: {
    width: 270,
  },
  removeBtn: {
    height: 90,
  },
  marginB: {
    marginBottom: 50,
  },
  redTextButton: {
    textTransform: 'none',
    fontWeight: 'bold',
    padding: 0,
    fontSize: 17,
    color: 'red',
    fontFamily: 'Open Sans',
    textDecoration: 'underline',
    marginTop: 20,
  },
  paper: {
    background: primaryDark,
    padding: '20px 50px',
    marginBottom: 20,
    borderRadius: 34,
    width: '100%',
    boxShadow: lightBoxShadow,
  },
  container: {
    padding: 25,
  },
});

export const Container = styled(Grid)(() => {
  return {
    padding: 25,
    '& .MuiPaper-root .Mui-disabled': {
      backgroundColor: 'transparent',
      color: darkGray,
    },
  };
});
