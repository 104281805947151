import {
  CreateQueryParams,
  SConditionAND,
  SFields,
} from '@nestjsx/crud-request';
import { useAuth } from 'contexts/auth';
import { StatusCodes } from 'http-status-codes';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import CsvService from 'services/csvService';
import PaymentService from 'services/paymentService';
import { useAppStore } from 'store';
import { useDebouncedCallback } from 'use-debounce';
import {
  DateParam,
  DelimitedArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { ROWS_PER_PAGE } from 'utils';
import { FinancialReportPayment } from './FinancialReportTable/types';
import {
  ERROR_DOWNLOAD_CSV_MESSAGE,
  FINANTIAL_REPORT_CSV_CONFIG,
  GENERIC_LOAD_ERROR_MESSAGE,
  PAYMENT_STATUSES_TO_BE_IGNORED,
} from './consts';
import {
  BuildQueryParams,
  CreateQueryParamsCallback,
  DateFilterKey,
  FilterKey,
  HandleDebouncedUpdateQueryParams,
  UpdateQueryCallback,
} from './types';
import {
  filterStringValuesOnly,
  handleBuildSearchDateInterval,
  handleBuildSearchText,
  handleContractStatusFilter,
  handleDatesToQueryParams,
} from './utils';

export const useFinancialReportController = () => {
  // -------------------- Custom hooks --------------------
  const { isAdmin, isDeveloper } = useAuth();

  /**
   * The following parameters trigger a request 2000 ms after the last change:
   * search, schoolGrades, paymentStatuses, contractStatuses and referenceYears
   * The following parameters trigger a request instantly:
   * page, rows, start and end
   */
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 0),
    rows: withDefault(NumberParam, ROWS_PER_PAGE[0]),
    referenceYears: DelimitedArrayParam,
    search: StringParam,
    schoolGrades: DelimitedArrayParam,
    paymentStatuses: DelimitedArrayParam,
    contractStatuses: DelimitedArrayParam,
    start: DateParam,
    end: DateParam,
  });
  const showAlert = useAppStore(state => state.alert.showAlert);

  // -------------------- States --------------------
  const [payments, setPayments] = useState<FinancialReportPayment[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);
  const [page, setPage] = useState(query.page);
  const [rows, setRows] = useState(query.rows);
  const [total, setTotal] = useState(0);
  const [filterValues, setFilterValues] = useState({
    [FilterKey.SEARCH_TEXT]: query.search,
    [FilterKey.REFERENCE_YEARS]: filterStringValuesOnly(query.referenceYears),
    [FilterKey.SCHOOL_GRADES]: filterStringValuesOnly(query.schoolGrades),
    [FilterKey.PAYMENT_STATUSES]: filterStringValuesOnly(query.paymentStatuses),
    [FilterKey.CONTRACT_STATUSES]: filterStringValuesOnly(
      query.contractStatuses,
    ),
    [DateFilterKey.START_DATE]: query.start ?? null,
    [DateFilterKey.END_DATE]: query.end ?? null,
  });

  // -------------------- Memos --------------------
  const filters = useMemo(() => {
    const paymentStatuses = filterStringValuesOnly(query.paymentStatuses);
    const schoolGrades = filterStringValuesOnly(query.schoolGrades);
    const referenceYears = filterStringValuesOnly(query.referenceYears);
    const contractStatuses = filterStringValuesOnly(query.contractStatuses);

    const filters: (SFields | SConditionAND)[] = [];

    if (paymentStatuses?.length) {
      filters.push({
        status: {
          $in: paymentStatuses,
        },
      });
    } else {
      filters.push({
        status: {
          $notin: PAYMENT_STATUSES_TO_BE_IGNORED({ isAdmin, isDeveloper }),
        },
      });
    }

    if (schoolGrades?.length) {
      filters.push({
        'registration.schoolClass.name': {
          $in: schoolGrades,
        },
      });
    }

    if (referenceYears?.length) {
      filters.push({
        referenceYear: {
          $in: referenceYears,
        },
      });
    }

    const dateFilter = handleBuildSearchDateInterval(query.start, query.end);
    if (dateFilter) {
      filters.push(dateFilter);
    }

    const searchFilters = handleBuildSearchText(query.search);
    if (searchFilters) {
      filters.push(searchFilters);
    }

    const contractFilter = handleContractStatusFilter(contractStatuses);
    if (contractFilter) {
      filters.push(contractFilter);
    }

    return filters;
  }, [
    query.contractStatuses,
    query.end,
    query.paymentStatuses,
    query.referenceYears,
    query.schoolGrades,
    query.search,
    query.start,
  ]);

  const queryPagination = useMemo(() => {
    return { page: query.page + 1, limit: query.rows };
  }, [query.page, query.rows]);

  // -------------------- Callbacks --------------------

  // ---------- Query callbacks ----------
  const createNestCrudQuery: CreateQueryParamsCallback = useCallback(
    ({ includePagination }: BuildQueryParams = {}) => {
      const queryParams: CreateQueryParams = {
        fields: [
          'id',
          'referenceYear',
          'paymentMethodCode',
          'status',
          'value',
          'referenceGrade',
          'source',
          'createdAt',
        ],
        join: [
          { field: 'financialGuardian', select: ['id'] },
          { field: 'financialGuardian.user', select: ['id', 'name'] },
          { field: 'registration', select: ['id', 'number'] },
          { field: 'registration.dependent', select: ['id', 'name'] },
          { field: 'registration.schoolClass', select: ['id', 'name'] },
          { field: 'subscriptions', select: ['id', 'installments'] },
          {
            field: 'bills',
            select: ['id', 'billingDate', 'status'],
          },
          { field: 'service', select: ['id', 'name'] },
          { field: 'contractInfo', select: ['id', 'signDate'] },
          { field: 'serviceClass', select: ['id', 'name'] },
        ],
        sort: [
          {
            field: 'createdAt',
            order: 'DESC',
          },
        ],
        search: {
          $and: filters,
        },
      };

      if (includePagination) {
        queryParams.page = queryPagination.page;
        queryParams.limit = queryPagination.limit;
      }

      return queryParams;
    },
    [filters, queryPagination],
  );

  const updateQuery: UpdateQueryCallback = useCallback(
    ({
      newPage = page,
      newRows = rows,
      newStartDate = filterValues.START_DATE,
      newEndDate = filterValues.END_DATE,
    }) => {
      setQuery({
        search: filterValues.SEARCH_TEXT || undefined,
        referenceYears: filterValues.REFERENCE_YEARS.length
          ? filterValues.REFERENCE_YEARS
          : undefined,
        schoolGrades: filterValues.SCHOOL_GRADES.length
          ? filterValues.SCHOOL_GRADES
          : undefined,
        paymentStatuses: filterValues.PAYMENT_STATUSES.length
          ? filterValues.PAYMENT_STATUSES
          : undefined,
        contractStatuses: filterValues.CONTRACT_STATUSES.length
          ? filterValues.CONTRACT_STATUSES
          : undefined,
        ...handleDatesToQueryParams(newStartDate, newEndDate),
        page: newPage,
        rows: newRows,
      });
    },
    [
      page,
      rows,
      filterValues.START_DATE,
      filterValues.END_DATE,
      filterValues.SEARCH_TEXT,
      filterValues.REFERENCE_YEARS,
      filterValues.SCHOOL_GRADES,
      filterValues.PAYMENT_STATUSES,
      filterValues.CONTRACT_STATUSES,
      setQuery,
    ],
  );

  const debouncedUpdateQuery = useDebouncedCallback(updateQuery, 2000);

  const handleUpdateQuery = useCallback(
    ({ isInstant, ...rest }: HandleDebouncedUpdateQueryParams = {}) => {
      if (!isInstant) {
        debouncedUpdateQuery({});
        return;
      }

      if (debouncedUpdateQuery.isPending()) {
        debouncedUpdateQuery.cancel();
      }

      updateQuery(rest);
    },
    [debouncedUpdateQuery, updateQuery],
  );

  // ---------- Load data callbacks ----------

  const fetchPayments = useCallback(async () => {
    const { data: response, status } =
      await PaymentService.filterPayments<FinancialReportPayment>(
        createNestCrudQuery({ includePagination: true }),
      );

    if (status === StatusCodes.OK) {
      return response;
    }

    throw new Error(GENERIC_LOAD_ERROR_MESSAGE);
  }, [createNestCrudQuery]);

  const loadTablePayments = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await fetchPayments();

      setPayments(response.data);
      setTotal(response.total);
    } catch (error) {
      console.error(error);
      showAlert({
        message: GENERIC_LOAD_ERROR_MESSAGE,
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }, [fetchPayments, showAlert]);

  const getFinantialReportCsv = useCallback(async () => {
    setIsLoadingCsv(true);

    try {
      await CsvService.downloadFinancialReport({
        query: createNestCrudQuery(),
        config: FINANTIAL_REPORT_CSV_CONFIG,
      });
    } catch (error) {
      console.error(error);
      showAlert({
        message: ERROR_DOWNLOAD_CSV_MESSAGE,
        severity: 'error',
      });
    } finally {
      setIsLoadingCsv(false);
    }
  }, [createNestCrudQuery, showAlert]);

  // ---------- Filters callbacks ----------

  // ----- Table pagination -----
  const handleOnPageChange = useCallback(
    (_event: unknown, newPage: number) => {
      setPage(newPage);
      handleUpdateQuery({ isInstant: true, newPage });
    },
    [handleUpdateQuery],
  );

  const handleOnRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newPage = 0;
      const newRows = parseInt(event.target.value, 10);
      setPage(newPage);
      setRows(newRows);
      handleUpdateQuery({ isInstant: true, newPage, newRows });
    },
    [handleUpdateQuery],
  );

  // ----- Date filters -----
  const handleUpdateDate = useCallback(
    (key: DateFilterKey, date: Date | null) => {
      setFilterValues(prev => ({
        ...prev,
        [key]: date,
      }));

      const newStartDate = key === DateFilterKey.START_DATE ? date : undefined;
      const newEndDate = key === DateFilterKey.END_DATE ? date : undefined;
      handleUpdateQuery({ isInstant: true, newStartDate, newEndDate });
    },
    [handleUpdateQuery],
  );

  const handleResetDateInterval = useCallback(() => {
    const newStartDate = null;
    const newEndDate = null;
    setFilterValues(prev => ({
      ...prev,
      [DateFilterKey.START_DATE]: newStartDate,
      [DateFilterKey.END_DATE]: newEndDate,
    }));
    handleUpdateQuery({ isInstant: true, newStartDate, newEndDate });
  }, [handleUpdateQuery]);

  // ----- General filters (multi-select and search text) -----
  const handleUpdateFilter = useCallback(
    (
      key: FilterKey,
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setFilterValues(prev => ({
        ...prev,
        [key]: e.target.value,
      }));
      handleUpdateQuery();
    },
    [handleUpdateQuery],
  );

  // ---------- Effects ----------
  useEffect(() => {
    loadTablePayments();
  }, [loadTablePayments]);

  return {
    payments,
    isLoading,
    total,
    page: query.page,
    rows: query.rows,
    handleOnPageChange,
    handleOnRowsPerPageChange,
    searchText: filterValues.SEARCH_TEXT,
    handleUpdateFilter,
    selectedYears: filterValues.REFERENCE_YEARS,
    selectedSchoolGrades: filterValues.SCHOOL_GRADES,
    selectedPaymentStatuses: filterValues.PAYMENT_STATUSES,
    selectedContractStatuses: filterValues.CONTRACT_STATUSES,
    startDate: filterValues.START_DATE,
    endDate: filterValues.END_DATE,
    handleUpdateDate,
    handleResetDateInterval,
    isLoadingCsv,
    getFinantialReportCsv,
    isAdmin,
    isDeveloper,
  };
};
