import { MenuItems } from 'components/HeaderBar/types';
import { useAuth } from 'contexts/auth';
import { StatusCodes } from 'http-status-codes';
import { sortBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ADMIN_ROUTE } from 'routes/consts';
import PaymentService from 'services/paymentService';
import {
  GENERAL_ERROR,
  PAYMENT_CANCELED,
  PAYMENT_REFUNDED,
  VINDI_SYNCED,
} from 'texts';
import { Bill } from 'types/bill';
import { ALERT_COLORS, PAYMENT_STATUS } from 'types/enums';
import { Payment } from 'types/payment';
import { RouteParams } from 'types/routeParams';
import { Subscription } from 'types/subscription';
import { formatDateTimePtBr } from 'utils/formatDatePtBr';
import { CANCELABLE_STATUSES } from './consts';

export const useAdmPaymentDetailsController = () => {
  // ---------- Custom hooks ----------
  const { id: paymentId } = useParams<RouteParams>();
  const { isAdmin, user } = useAuth();

  // ---------- States ----------
  const [loading, setLoading] = useState(false);
  const [payment, setPayment] = useState<Payment | null>();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertColor, setAlertColor] = useState(ALERT_COLORS.RED);
  const [modal, setModal] = useState(false);
  const [refund, setRefund] = useState(false);
  const [syncModal, setSyncModal] = useState(false);

  const history = useHistory();

  // ---------- Callbacks ----------
  const checkIfCancelPaymentShouldBeDisabled = useCallback(
    (paymentStatus: PAYMENT_STATUS) => {
      if (payment?.canceledAt) return true;

      return !CANCELABLE_STATUSES.includes(paymentStatus);
    },
    [payment?.canceledAt],
  );

  const filterBillsWithBillingDate = useCallback(
    (bills: Bill[]) => bills.filter(bill => !!bill.billingDate),
    [],
  );

  const handleSetSubscriptions = useCallback(
    (subscriptions: Subscription[]) => {
      const filteredSubscriptions = (subscriptions || []).map(
        ({ bills, ...rest }) => {
          return {
            ...rest,
            bills: sortBy(filterBillsWithBillingDate(bills), ['id']),
          };
        },
      );

      setSubscriptions(filteredSubscriptions);
    },
    [filterBillsWithBillingDate],
  );

  const loadPayment = useCallback(async () => {
    setLoading(true);
    try {
      const response = await PaymentService.payment(paymentId, {
        join: [
          ['financialGuardian'],
          ['registration'],
          ['registration.dependent'],
          ['subscriptions'],
          ['subscriptions.bills'],
          ['subscriptions.card'],
          ['service'],
        ],
      });
      if (response.status === StatusCodes.OK) {
        setPayment(response.data);
        handleSetSubscriptions(response.data.subscriptions);
      }
    } catch (error: any) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [handleSetSubscriptions, paymentId]);

  const handleOpenModal = useCallback(
    (refund: boolean) => () => {
      setRefund(refund);
      setModal(true);
    },
    [],
  );

  const cancelPayment = useCallback(async () => {
    const response = await PaymentService.cancelPayment(
      Number(paymentId),
      refund,
    );
    if (response.status === StatusCodes.CREATED) {
      loadPayment();
      setAlertMessage(refund ? PAYMENT_REFUNDED : PAYMENT_CANCELED);
      setAlertColor(ALERT_COLORS.GREEN);
    } else {
      setAlertMessage(response.data.message);
      setAlertColor(ALERT_COLORS.RED);
    }
    setModal(false);
    setAlert(true);
  }, [loadPayment, paymentId, refund]);

  const syncWithVindi = useCallback(async () => {
    const response = await PaymentService.syncPaymentWithVindi(
      Number(paymentId),
    );
    if (response.status === StatusCodes.OK) {
      loadPayment();
      setAlertMessage(VINDI_SYNCED);
      setAlertColor(ALERT_COLORS.GREEN);
    } else {
      setAlertMessage(response.data.message);
      setAlertColor(ALERT_COLORS.RED);
    }
    setSyncModal(false);
    setAlert(true);
  }, [loadPayment, paymentId]);

  const closeAlert = useCallback(() => {
    setAlert(false);
  }, []);

  const closeModal = useCallback(() => {
    setModal(false);
  }, []);

  const openSyncModal = useCallback(() => {
    setSyncModal(true);
  }, []);

  const closeSyncModal = useCallback(() => {
    setSyncModal(false);
  }, []);

  const exportStudentFinancialReport = useCallback(async () => {
    if (payment?.id) {
      const response = await PaymentService.exportStudentFinancialReport(
        payment.id,
      );

      if (response.status === StatusCodes.OK) {
        window.open(response.data.fileUrl);
      } else {
        setAlert(true);
        setAlertMessage(GENERAL_ERROR);
      }
    }
  }, [payment?.id]);

  const exportIncomeTaxReturn = useCallback(async () => {
    if (payment?.id) {
      let response;
      if (payment.status === PAYMENT_STATUS.CANCELED) {
        response =
          await PaymentService.exportIncomeTaxReturnByCanceledPaymentId(
            payment.id,
          );
      } else {
        response = await PaymentService.exportIncomeTaxReturnByPaymentId(
          payment.id,
        );
      }

      if (response.status === StatusCodes.CREATED) {
        window.open(response.data.fileUrl);
      } else {
        setAlert(true);
        setAlertMessage(response.data.message);
      }
    }
  }, [payment?.id]);

  const menuItems: MenuItems = useMemo(() => {
    if (!payment?.status) return [];

    return [
      {
        action: handleOpenModal(false),
        title: 'Cancelar pagamento',
        disabled: checkIfCancelPaymentShouldBeDisabled(payment.status),
      },
      {
        action: openSyncModal,
        title: 'Sincronizar status com Vindi/Itau',
      },
      {
        action: exportStudentFinancialReport,
        title: 'Exportar Relatório',
        // FIXME: temporary. As soon as this is fixed, remove this line below
        disabled: true,
      },
      {
        action: exportIncomeTaxReturn,
        title: 'Exportar Declaração IR',
      },
    ];
  }, [
    handleOpenModal,
    checkIfCancelPaymentShouldBeDisabled,
    payment?.status,
    openSyncModal,
    exportStudentFinancialReport,
    exportIncomeTaxReturn,
  ]);

  // ---------- Effects ----------
  useEffect(() => {
    loadPayment();
  }, [loadPayment]);

  const getCancelationText = (payment: Payment) => {
    let text = '';

    if (payment.canceledAt) {
      const cancelationPrefix = `Pagamento cancelado em ${formatDateTimePtBr(
        payment.canceledAt,
      )}`;
      const cancelationSuffix = payment.canceledBy?.admin
        ? ` por ${payment.canceledBy?.admin}.`
        : '.';
      text += `${cancelationPrefix}${cancelationSuffix} `;
    }

    if (payment.status === PAYMENT_STATUS.ERROR) {
      const reason = `Motivo: ${payment?.paymentFailReason}`;
      text += payment?.paymentFailReason
        ? reason
        : 'Não foi possivel identificar o motivo do cancelamento.';
    }

    return text;
  };

  const handleCreateManualInvoiceOnclick = () => {
    history.push({
      pathname: ADMIN_ROUTE.CREATE_MANUAL_INVOICE.replace(
        ':paymentId',
        `${paymentId}`,
      ),
    });
  };

  return {
    // Alert
    alertMessage,
    alert,
    closeAlert,
    alertColor,

    // Cancel payment modal
    modal,
    refund,
    cancelPayment,
    closeModal,

    // Sync with vindi modal
    syncModal,
    syncWithVindi,
    closeSyncModal,

    // Dropdown
    isAdmin,
    menuItems,

    // Page data
    loading,
    payment,
    subscriptions,
    loadPayment,
    getCancelationText,
    handleCreateManualInvoiceOnclick,
    user,
  };
};
