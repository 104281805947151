import { useState } from 'react';
import { useStyles } from './styles';

export const useDarkToolTipController = () => {
  const styles = useStyles();
  const [showTooltip, setShowTooltip] = useState(false);

  const openToolTip = () => {
    setShowTooltip(true);
  };

  const closeToolTip = () => {
    setShowTooltip(false);
  };

  const onClickToolTip = () => {
    setShowTooltip(currentShowTooltip => !currentShowTooltip);
  };

  return {
    styles,
    showTooltip,
    openToolTip,
    closeToolTip,
    onClickToolTip,
  };
};
