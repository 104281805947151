/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  TablePagination,
  TextField,
} from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { DatePicker } from '@material-ui/pickers';
import { CondOperator, SCondition } from '@nestjsx/crud-request';
import AlertCard from 'components/AlertCard';
import PageHeader from 'components/PageHeader';
import RegistrationsTable from 'components/RegistrationsTable';
import SpinnerButton from 'components/SpinnerButton';
import { selectMenuProps } from 'constants/selectMenu';
import { endOfDay, startOfDay } from 'date-fns';
import { useAlert } from 'hooks/Alert';
import { MESSAGE_TYPE } from 'hooks/Alert/types';
import { useFilters } from 'hooks/filter';
import { StatusCodes } from 'http-status-codes';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import RegistrationService from 'services/registrationService';
import useGlobalStyles from 'styles';
import { GENERAL_ERROR } from 'texts';
import { REGISTRATION_STATUS, SCHOOL_GRADES } from 'types/enums';
import { Registration } from 'types/registration';
import { useDebouncedCallback } from 'use-debounce';
import {
  DateParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { ROWS_PER_PAGE } from 'utils';
import { YEARS_ARRAY } from './utils';

const theme = createMuiTheme({}, ptBR);
const minDate = new Date(2021, 9); //  first registration date
const maxDate = new Date();

const AdmRegistrations: React.FC = () => {
  const classes = useGlobalStyles();
  const [registrations, setRegistrations] = useState<Registration[]>([]);
  const [total, setTotal] = useState(0);
  const [searchField, setSearchField] = useState('');

  const [query, setQuery] = useQueryParams({
    year: NumberParam,
    rows: withDefault(NumberParam, ROWS_PER_PAGE[0]),
    search: StringParam,
    grade: StringParam,
    status: StringParam,
    start: withDefault(DateParam, null),
    end: withDefault(DateParam, null),
  });
  const { year, rows, search, grade, status, start, end } = query;

  const { openAlert, closeAlert, alertMessage, alertType, isShowAlert } =
    useAlert();

  const startDate = useMemo(() => {
    return startOfDay(start || minDate);
  }, [start]);

  const endDate = useMemo(() => {
    return endOfDay(end || maxDate);
  }, [end]);

  const [defaultFilters] = useState([
    {
      field: 'status',
      value: status,
      operator: CondOperator.EQUALS,
    },
    {
      field: 'schoolClass.name',
      value: grade,
      operator: CondOperator.EQUALS,
    },
    {
      field: 'schoolYear',
      value: year,
      operator: CondOperator.EQUALS,
    },
  ]);

  const { page, filterFields, handleUpdateFilters, handleSetValuePage } =
    useFilters(defaultFilters);

  const loadRegistrations = useCallback(async () => {
    setSearchField(search || '');
    const { data: response, status } =
      await RegistrationService.filterRegistrations({
        page: page + 1,
        limit: rows,
        search: {
          $and: [
            ...filterFields,
            {
              createdAt: {
                $between: [startDate, endDate],
              },
            },
            {
              $or: [
                { 'dependent.name': { $contL: search } },
                { number: { $contL: search } },
              ],
            },
          ],
        } as SCondition,
        join: [
          ['dependent'],
          ['schoolClass'],
          ['interview'],
          { field: 'payments', select: ['id', 'contractInfo'] },
          { field: 'payments.contractInfo', select: ['id', 'createdAt'] },
        ],
      });
    if (status === StatusCodes.OK) {
      setRegistrations(response.data);
      setTotal(response.total);
    } else {
      openAlert({ message: GENERAL_ERROR, type: MESSAGE_TYPE.ERROR });
    }
  }, [page, rows, search, filterFields, startDate, endDate, openAlert]);

  useEffect(() => {
    loadRegistrations();
  }, [loadRegistrations]);

  const searchFieldDebounce = useDebouncedCallback((value: string) => {
    setQuery({ search: value });
  }, 2000);

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchField(e.target.value);
    searchFieldDebounce(e.target.value);
  };

  const handleChangeStartDate = (date: Date | null) => {
    if (date) setQuery({ start: date });
  };

  const handleChangeEndDate = (date: Date | null) => {
    if (date) setQuery({ end: date });
  };

  const handleChangeStatus = (e: ChangeEvent<HTMLInputElement>) => {
    handleUpdateFilters(
      'status',
      { value: e.target.value },
      CondOperator.EQUALS,
    );
    setQuery({ status: e.target.value });
  };

  const handleChangeGrade = (e: ChangeEvent<HTMLInputElement>) => {
    handleUpdateFilters(
      'schoolClass.name',
      { value: e.target.value },
      CondOperator.EQUALS,
    );
    setQuery({ grade: e.target.value });
  };

  const handleChangeYear = (e: ChangeEvent<HTMLInputElement>) => {
    handleUpdateFilters(
      'schoolYear',
      { value: e.target.value },
      CondOperator.EQUALS,
    );
    setQuery({ year: Number(e.target.value) });
  };

  const resetPeriod = () => {
    setQuery({ start: null, end: null });
  };

  const exportCsv = async () => {
    const response = await RegistrationService.getFilteredCsv({
      join: [['dependent'], ['schoolClass'], ['interview']],
      search: {
        $and: [
          ...filterFields,
          {
            createdAt: {
              $between: [startDate, endDate],
            },
          },
          {
            $or: [
              { 'dependent.name': { $contL: search } },
              { number: { $contL: search } },
            ],
          },
        ],
      } as SCondition,
    });
    if (response.status === StatusCodes.OK) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `matriculas.csv`);
      document.body.appendChild(link);
      link.click();
    } else {
      openAlert({ message: GENERAL_ERROR, type: MESSAGE_TYPE.ERROR });
    }
  };

  return (
    <Grid
      className={classes.listScreenGrid}
      container
      direction="column"
      alignItems="flex-start"
    >
      <AlertCard
        message={alertMessage}
        open={isShowAlert}
        close={closeAlert}
        severity={alertType}
      />
      <Grid container item direction="column">
        <PageHeader
          titleBold="Matrículas"
          subtitle="Acesse aqui as solicitações de matrículas"
          button={
            <SpinnerButton
              action={exportCsv}
              text="Exportar"
              className={classes.exportButton}
            />
          }
        />
        <Grid
          item
          container
          spacing={2}
          alignItems="center"
          className={classes.filtersGrid}
        >
          <Grid item>
            <TextField
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="Nome ou matrícula"
              value={searchField}
              onChange={handleChangeSearch}
              className={classes.bigFilterField}
            />
          </Grid>
          <Grid item>
            <DatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              label="Periodo - Início"
              minDate={minDate}
              maxDate={end || maxDate}
              value={start}
              onChange={handleChangeStartDate}
              className={classes.smallFilterField}
            />
          </Grid>
          <Grid item>
            <DatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              label="Periodo - Fim"
              minDate={start || minDate}
              maxDate={maxDate}
              value={end}
              onChange={handleChangeEndDate}
              className={classes.smallFilterField}
            />
          </Grid>
          {start || end ? (
            <Grid item>
              <Button
                color="primary"
                className={classes.textButton}
                onClick={resetPeriod}
              >
                Resetar período
              </Button>
            </Grid>
          ) : null}
        </Grid>
        <Grid container item spacing={2}>
          <Grid item>
            <TextField
              label="Turma/Série"
              variant="outlined"
              select
              SelectProps={selectMenuProps}
              value={grade}
              onChange={handleChangeGrade}
              className={classes.filterField}
            >
              <MenuItem>Todos</MenuItem>
              {Object.values(SCHOOL_GRADES).map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <TextField
              label="Status"
              variant="outlined"
              select
              SelectProps={selectMenuProps}
              value={status}
              onChange={handleChangeStatus}
              className={classes.filterField}
            >
              <MenuItem>Todos</MenuItem>
              {Object.values(REGISTRATION_STATUS).map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <TextField
              label="Ano"
              variant="outlined"
              select
              SelectProps={selectMenuProps}
              value={year}
              onChange={handleChangeYear}
              className={classes.filterField}
            >
              <MenuItem>Todos</MenuItem>
              {YEARS_ARRAY.map(year => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <RegistrationsTable registrations={registrations} />
        <ThemeProvider theme={theme}>
          {/** @ts-ignore */}
          <TablePagination
            component="div"
            count={total}
            page={page}
            labelRowsPerPage="Itens por página"
            onChangePage={(_event: unknown, newPage: number) =>
              handleSetValuePage(newPage)
            }
            rowsPerPage={rows}
            onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
              handleSetValuePage(0);
              setQuery({ rows: parseInt(event.target.value, 10) });
            }}
            rowsPerPageOptions={ROWS_PER_PAGE}
          />
        </ThemeProvider>
      </Grid>
    </Grid>
  );
};

export default AdmRegistrations;
