import { Box, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import SpinnerButton from 'components/SpinnerButton';
import React, { useState } from 'react';
import useGlobalStyles from 'styles';
import { SelectBoxProperties } from './types';

const SelectBox: React.FC<SelectBoxProperties> = ({
  title,
  value,
  setAction,
  options,
  saveAction,
  disabled,
  helperText,
}) => {
  const classes = useGlobalStyles();
  const [localLoading, setLocalLoading] = useState(false);

  const handleAction = async () => {
    setLocalLoading(true);
    await saveAction();
    setLocalLoading(false);
  };

  return (
    <Grid
      className={classes.regStatusUpdate}
      item
      container
      justifyContent="center"
      alignItems="center"
    >
      <Box style={{ width: '90%' }}>
        <Typography className={classes.screenText3}>
          <b>{title}</b>
        </Typography>
        <TextField
          disabled={disabled}
          variant="outlined"
          select
          helperText={helperText}
          SelectProps={{ MenuProps: { disableScrollLock: true } }}
          value={value}
          onChange={e => setAction(e.target.value)}
          className={classes.regUpdateField}
        >
          {Object.values(options).map((item, index) => {
            return (
              <MenuItem
                key={index}
                value={item.label || item}
                disabled={item.isDisabled || false}
              >
                {item.label || item}
              </MenuItem>
            );
          })}
        </TextField>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="flex-end"
          spacing={3}
          className={classes.screenButtonGrid}
        >
          <Grid item>
            <SpinnerButton
              disabled={disabled}
              text="Salvar"
              action={handleAction}
              localLoading={localLoading}
              className={classes.newDependentButton}
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default SelectBox;
