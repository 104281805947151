import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, TextField, Typography } from '@material-ui/core';
import SideImageAdmin from 'components/SideImageAdmin';
import SpinnerButton from 'components/SpinnerButton';
import { useAuth } from 'contexts/auth';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import useGlobalStyles from 'styles';
import { INCORRECT_LOGIN } from 'texts';
import { InputRef } from 'types/FormTypes';
import { validationSchema } from './utils';

const LoginAdmin: React.FC = () => {
  const classes = useGlobalStyles();
  const { signIn, loginError } = useAuth();
  const email = useRef<InputRef>();
  const password = useRef<InputRef>();

  const { handleSubmit, errors, setError, register } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (loginError) {
      setError('email', {
        message: INCORRECT_LOGIN,
      });
      setError('password', {
        message: INCORRECT_LOGIN,
      });
    }
  }, [loginError, setError]);

  function onSubmit(data: any) {
    logIn(data);
  }

  async function logIn(data: any) {
    await signIn(data.email, data.password);
  }

  return (
    <Grid container className={classes.page}>
      <Grid item xs={4} md={6}>
        <SideImageAdmin />
      </Grid>
      <Grid
        className={classes.contentAdmin}
        item
        xs={8}
        md={6}
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Typography paragraph variant="h2">
          Painel do <b>administrador</b>
        </Typography>
        <Typography variant="h3">Acesse sua conta</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column">
            <Grid item className={classes.publicScreenMargin2}>
              <TextField
                variant="outlined"
                label="Email"
                id="email"
                name="email"
                inputProps={{ ref: email }}
                error={Boolean(errors.email)}
                helperText={errors.email ? errors.email.message : null}
                inputRef={register}
                className={classes.publicScreenInput}
              />
            </Grid>
            <Grid item className={classes.publicScreenMargin2}>
              <TextField
                variant="outlined"
                label="Senha"
                id="password"
                name="password"
                type="password"
                inputProps={{ ref: password }}
                error={Boolean(errors.password)}
                helperText={errors.password ? errors.password.message : null}
                inputRef={register}
                className={classes.publicScreenInput}
              />
            </Grid>
            <SpinnerButton text="Entrar" className={classes.loginButton} />
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default LoginAdmin;
