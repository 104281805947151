import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Paper, Typography } from '@material-ui/core';
import { CheckCircleOutlineOutlined } from '@material-ui/icons';
import AddressForm from 'components/AddressForm';
import AlertCard from 'components/AlertCard';
import GuardianForm from 'components/GuardianForm';
import Modal from 'components/Modal';
import PageFooter from 'components/PageFooter';
import PageHeader from 'components/PageHeader';
import SpinnerButton from 'components/SpinnerButton';
import { useSearchCEP } from 'hooks/searchCEP';
import { StatusCodes } from 'http-status-codes';
import { allToUpperCase } from 'pages/Client/NewRegistration/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useRouteMatch } from 'react-router-dom';
import GuardianService from 'services/guardianService';
import RegistrationService from 'services/registrationService';
import useGlobalStyles from 'styles';
import { GUARDIAN_ERROR } from 'texts';
import { Dependent } from 'types/dependent';
import { CIVIL_STATUS, DEPENDENT_RELATIONSHIP } from 'types/enums';
import { LegalGuardian } from 'types/legalGuardian';
import { RouteParams } from 'types/routeParams';
import { validationSchema } from './utils';

const NewGuardian: React.FC = () => {
  const classes = useGlobalStyles();
  const {
    params: { id: registrationId },
  } = useRouteMatch<RouteParams>();
  const [dependent, setDependent] = useState({} as Dependent);
  const [civilStatus, setCivilStatus] = useState<CIVIL_STATUS | undefined>();
  const [relationship, setRelationship] = useState<
    DEPENDENT_RELATIONSHIP | undefined
  >();
  const [modal, setModal] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const history = useHistory();

  const { handleSubmit, errors, register, control, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onBlur',
  });

  const {
    address,
    debouncedSearchCEP,
    cepError,
    cepErrorCount,
    clearCepError,
  } = useSearchCEP();

  useEffect(() => {
    register('civilStatus');
    register('relationship');
  }, [register]);

  const loadRegistration = useCallback(async () => {
    const response = await RegistrationService.registration(registrationId, {
      join: ['dependent'],
    });
    if (response.status === StatusCodes.OK) {
      setDependent(response.data.dependent);
    } else {
      setAlert(true);
    }
  }, [registrationId]);

  useEffect(() => {
    loadRegistration();
  }, [loadRegistration]);

  function modalClose() {
    setModal(false);
    history.goBack();
  }

  async function onSubmit(data: Partial<LegalGuardian>) {
    const guardian = allToUpperCase(data);
    guardian.dependents = [dependent.id];
    const response = await GuardianService.createLegalGuardian(guardian);
    if (response.status !== StatusCodes.CREATED) {
      setAlert(true);
      setAlertMessage(GUARDIAN_ERROR);
    } else {
      setModal(true);
    }
  }

  const handleChangeCivilStatus = (status: string) => {
    setCivilStatus(status as CIVIL_STATUS);
    setValue('civilStatus', status);
  };

  const handleChangeRelationship = (status: string) => {
    setRelationship(status as DEPENDENT_RELATIONSHIP);
    setValue('relationship', status);
  };

  const handleCloseAlert = () => {
    setAlert(false);
    clearCepError();
  };

  return (
    <Grid
      style={{ padding: 25 }}
      container
      direction="column"
      alignItems="flex-start"
    >
      <Modal
        icon={
          <CheckCircleOutlineOutlined
            color="secondary"
            className={classes.dialogIcon}
          />
        }
        open={modal}
        text="Responsável legal cadastrado com sucesso."
        btnText="Voltar"
        btnAction={() => modalClose()}
      />
      <AlertCard
        message={alertMessage || cepError}
        open={alert || !!cepError}
        close={handleCloseAlert}
        severity="error"
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container item direction="column">
          <PageHeader
            title="Cadastrar responsável legal"
            subtitle="Informe os dados do responsável legal"
          />
          <Paper elevation={0} className={classes.screenPaper}>
            <Grid item container direction="column">
              <Typography className={classes.screenText3}>
                <b>Dados gerais</b>
              </Typography>
              <GuardianForm
                isLegal
                civilStatus={civilStatus}
                relationship={relationship}
                changeCivilStatus={handleChangeCivilStatus}
                changeRelationship={handleChangeRelationship}
                register={register}
                control={control}
                errors={errors}
                editing
              />
              <Typography className={classes.screenText3}>
                <b>Endereço</b>
              </Typography>
              <AddressForm
                searchAddress={address}
                cepSearch={debouncedSearchCEP}
                cepErrorCount={cepErrorCount}
                register={register}
                control={control}
                errors={errors}
                editing
              />
            </Grid>
          </Paper>
          <PageFooter
            returnPath="/home"
            nextButton={
              <SpinnerButton
                text="Salvar responsável"
                className={classes.newGuardianButton}
              />
            }
          />
        </Grid>
      </form>
    </Grid>
  );
};

export default NewGuardian;
