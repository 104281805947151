import { CustomButton } from 'components/CustomButton';
import { ServiceCardsContainer } from '../ServiceCardsContainer';
import { ServiceHeader } from '../ServiceHeader';
import { Container, RowContainer, useStyles } from './styles';
import { CategorizedServicesProps } from './types';
import { useCategorizedServicesController } from './useCategorizedServicesController';

export const CategorizedServices = ({
  servicesByType,
  onClickSeeAll,
  shouldRenderSeeAllButton,
  ...serviceCardsContainerProps
}: CategorizedServicesProps) => {
  const { id, name, description, emptyDescription, onlyByInvite, services } =
    servicesByType;

  const { handleOnClick } = useCategorizedServicesController({
    onClickSeeAll,
    serviceTypeId: id,
  });

  const classes = useStyles();

  return (
    <Container>
      <RowContainer>
        <ServiceHeader
          variant={onlyByInvite ? 'by-invite' : 'minor'}
          title={name}
          subtitle={description}
        />

        {shouldRenderSeeAllButton ? (
          <CustomButton
            variant="secondary"
            className={classes.button}
            onClick={handleOnClick}
          >
            Ver todos
          </CustomButton>
        ) : null}
      </RowContainer>

      <ServiceCardsContainer
        emptyDescription={emptyDescription}
        services={services.map(service => ({
          ...service,
          serviceType: servicesByType,
        }))}
        {...serviceCardsContainerProps}
      />
    </Container>
  );
};
