import {
  Box,
  Button,
  Dialog,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { CheckCircleOutlineOutlined } from '@material-ui/icons';
import AlertCard from 'components/AlertCard';
import CurrencyField from 'components/CurrencyField';
import Modal from 'components/Modal';
import SpinnerButton from 'components/SpinnerButton';
import { Controller } from 'react-hook-form';
import { useSchoolClassCreateDetailsController } from './controller';
import { ModalProperties } from './types';

export const SchoolClassCreateDetailsModal = ({
  open,
  schoolClassId,
  schoolClassName,
  close,
  afterCreate,
}: ModalProperties) => {
  const {
    alertMessage,
    control,
    styles,
    handleSubmit,
    onSubmit,
    errors,
    classes,
    register,
    handleCloseAlertMessage,
    YEARS,
    handleCloseSuccessModal,
    successModalOpen,
  } = useSchoolClassCreateDetailsController(schoolClassId, close, afterCreate);

  return (
    <>
      <Modal
        icon={
          <CheckCircleOutlineOutlined
            color="secondary"
            className={classes.dialogIcon}
          />
        }
        open={successModalOpen}
        text="Nova anuidade cadastrada com sucesso."
        btnText="Voltar"
        btnAction={handleCloseSuccessModal}
      />
      <AlertCard
        message={alertMessage}
        open={!!alertMessage}
        close={handleCloseAlertMessage}
        severity="error"
      />
      <Dialog open={open} className={styles.dialog}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box style={{ width: 585, padding: 35 }}>
            <Typography style={{ fontSize: 30, marginBottom: 10 }}>
              <b>{schoolClassName}</b>
            </Typography>
            <Typography style={{ fontSize: 18, marginBottom: 50 }}>
              Cadastre um novo ano
            </Typography>
            <Grid container sm={12} spacing={1}>
              <Grid item sm={6}>
                <Controller
                  name="year"
                  control={control}
                  defaultValue={YEARS[0]}
                  as={({ value, onChange }) => (
                    <TextField
                      variant="outlined"
                      id="year"
                      name="year"
                      value={value}
                      label="Ano"
                      select
                      SelectProps={{ MenuProps: { disableScrollLock: true } }}
                      onChange={onChange}
                      style={{ width: '100%' }}
                      inputRef={register}
                      error={Boolean(errors.year)}
                      helperText={errors.year ? errors.year?.message : null}
                    >
                      {YEARS.map(item => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid container item sm={12} spacing={1}>
                <Grid item sm={6}>
                  <Controller
                    name="value"
                    control={control}
                    as={({ value, onChange }) => (
                      <CurrencyField
                        variant="outlined"
                        label="Valor"
                        name="value"
                        value={value}
                        onChange={onChange}
                        error={Boolean(errors.value)}
                        helperText={errors.value ? errors.value.message : null}
                        inputRef={register}
                        className={classes.newUserInput}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Controller
                    name="vacancies"
                    control={control}
                    as={({ value, onChange }) => (
                      <TextField
                        variant="outlined"
                        label="Vagas"
                        type="number"
                        name="vacancies"
                        value={value}
                        onChange={onChange}
                        error={Boolean(errors.vacancies)}
                        helperText={
                          errors.vacancies ? errors.vacancies.message : null
                        }
                        inputRef={register}
                        className={classes.newUserInput}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
              className={classes.dialogActions}
            >
              <Grid item>
                <Button
                  onClick={() => close()}
                  color="primary"
                  className={classes.textButton}
                >
                  Voltar
                </Button>
              </Grid>
              <Grid item>
                <SpinnerButton
                  text="Salvar"
                  className={classes.newContractButton}
                />
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
    </>
  );
};
