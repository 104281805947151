import React, { useMemo } from 'react';
import { Route } from 'react-router-dom';
import { useAuth } from 'contexts/auth';
import { RouteProperties } from './types';

const PrivateRoute: React.FC<RouteProperties> = ({
  exact,
  component,
  path,
  roles = [],
}) => {
  const { user } = useAuth();

  const userHasRequiredRole = useMemo(
    () => user && roles.some(role => user.roles.includes(role)),
    [roles, user],
  );

  return userHasRequiredRole || !roles.length ? (
    <Route path={path} exact={exact} component={component} />
  ) : null;
};

export default PrivateRoute;
