import React from 'react';

import { Box, Grid } from '@material-ui/core';
import PageHeader from 'components/PageHeader';
import { usePartnersCreationHomeController } from './controller';
import { IndicatorButton } from '../../Home/IndicatorButton';

export const PartnersCreationHome = () => {
  const { goToCreatePartner } = usePartnersCreationHomeController();

  return (
    <Box paddingX="45px">
      <PageHeader
        title="Novo "
        titleBoldSufix="parceiro"
        subtitle="Selecione uma das opções abaixo"
      />
      <Grid container direction="row" spacing={5}>
        <IndicatorButton
          title="Cadastrar "
          titleBoldSufix="parceiro"
          description="Cadastre manualmente um parceiro"
          onClick={goToCreatePartner}
        />
        {/* TODO: REMOVE THIS COMMENT WHEN WE HAVE THIS FEATURE */}
        {/* <IndicatorButton
          title="Gerar código "
          titleBoldSufix="de cadastro"
          description="Crie códigos de cadastro para parceiros "
          onClick={goToGenerateCode}
        /> */}
      </Grid>
    </Box>
  );
};
