import { Grid, Typography } from '@material-ui/core';
import { CustomIcon } from 'components/CustomIcon';
import { DarkTooltip } from 'components/DarkToolTip';
import { IBannerProps } from '../types';
import { StyledCoverGrid, useStyles } from './styles';

export const BannerInfo = ({
  variant,
  text,
  toolTipText,
  iconName,
  mini,
}: IBannerProps) => {
  const styles = useStyles();

  return (
    <StyledCoverGrid
      container
      alignItems="center"
      variant={variant}
      mini={mini}
    >
      {iconName ? (
        <Grid item className={styles.icon}>
          <CustomIcon name={iconName} />
        </Grid>
      ) : null}
      <Grid item className={styles.bannerContainer}>
        <Typography className="text-banner">{text}</Typography>

        {toolTipText?.length ? <DarkTooltip text={toolTipText} /> : null}
      </Grid>
    </StyledCoverGrid>
  );
};
