import { SConditionAND, SFields } from '@nestjsx/crud-request';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PartnersService from 'services/partnersService';
import ServicesService from 'services/servicesService';
import useGlobalStyles from 'styles';
import { useDebouncedCallback } from 'use-debounce';
import { ROWS_PER_PAGE } from 'utils';
import { EServiceStatus } from '../ServiceClasses/components/ServicesTable/types';
import { EServicesSolicitationStatus } from './components/ServicesSolicitationsTable/types';

const statusOptions = [
  ...Object.values(EServicesSolicitationStatus),
  ...Object.values(EServiceStatus),
];

export const useServicesController = () => {
  // Styles
  const classes = useGlobalStyles();

  // States
  const [services, setServices] = useState([]);
  const [serviceName, setServiceName] = useState('');
  const [selectedGrade, setSelectedGrade] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [selectedPartnerId, setSelectedPartnerId] = useState('');

  // Navigation
  const history = useHistory();

  // Callbacks
  const navigateToServiceCreation = () => {
    history.push('/adm-marketplace/services/new-service');
  };

  const handleChangeSearchServiceName = useDebouncedCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setServiceName(e.target.value),
    300,
  );

  const handleChangeGrade = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedGrade(e.target.value);
  };

  const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedStatus(e.target.value);
  };

  const handleChangePartner = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPartnerId(e.target.value);
  };

  // Table Pagination
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[1]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getServices = useCallback(async () => {
    const searchMultipleConditions: (SFields | SConditionAND)[] = [
      {
        name: { $contL: serviceName },
      },
    ];

    if (selectedGrade) {
      searchMultipleConditions.push({
        'schoolClasses.name': {
          $eq: selectedGrade,
        },
      });
    }

    if (selectedStatus) {
      searchMultipleConditions.push({
        'servicePublicationStatus.name': {
          $eq: selectedStatus,
        },
      });
    }

    if (selectedPartnerId) {
      searchMultipleConditions.push({
        partnerId: {
          $eq: selectedPartnerId,
        },
      });
    }

    const { data: response } = await ServicesService.getServices({
      fields: [
        'id',
        'name',
        'serviceType.id',
        'serviceType.name',
        'serviceCategories.id',
        'serviceCategories.name',
        'schoolClasses.id',
        'schoolClasses.name',
        'servicePublicationStatus.id',
        'servicePublicationStatus.name',
        'partner.id',
        'partner.name',
      ],
      page: page + 1,
      limit: rowsPerPage,
      search: {
        $and: searchMultipleConditions,
      },
      join: [
        ['servicePublicationStatus'],
        ['schoolClasses'],
        ['serviceType'],
        ['serviceCategories'],
        ['partner'],
      ],
      sort: {
        field: 'id',
        order: 'DESC',
      },
    });

    setServices(response?.data || []);
    setTotal(response?.total || 0);
  }, [
    page,
    rowsPerPage,
    selectedGrade,
    selectedPartnerId,
    selectedStatus,
    serviceName,
  ]);

  const getPartnerOptions = useCallback(async () => {
    const { data: response } = await PartnersService.getPartners({
      fields: ['id', 'name'],
    });

    if (response.data) {
      setPartnerOptions(response.data);
    }
  }, []);

  useEffect(() => {
    getServices();
  }, [getServices]);

  useEffect(() => {
    getPartnerOptions();
  }, [getPartnerOptions]);

  return {
    classes,
    services,
    navigateToServiceCreation,
    handleChangeSearchServiceName,
    handleChangeGrade,
    statusOptions,
    handleChangeStatus,
    partnerOptions,
    handleChangePartner,
    page,
    rowsPerPage,
    total,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};
