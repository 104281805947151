import {
  Avatar,
  Box,
  Button,
  styled,
  Theme,
  Typography,
  Grid,
  ButtonProps,
} from '@material-ui/core';
import { darkGray, error, mediumGray, primaryDark, white } from 'styles/theme';
import { TDescriptionSpotsValueStyled } from './types';

export const Container = styled(Grid)(({ theme }) => {
  return {
    display: 'flex',
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  };
});

export const DescriptionResumeName = styled(Typography)(({ theme }) => {
  return {
    fontSize: 24,
    fontWeight: 'bold',
    color: darkGray,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
  };
});

export const DescriptionResumeAvatar = styled(Avatar)(() => {
  return {
    height: 36,
    width: 36,
    marginRight: 13,
  };
});

export const DescriptionResumeText = styled(Typography)(({ theme }) => {
  return {
    fontSize: 16,
    color: darkGray,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  };
});

const DescriptionPaperContainer = styled(Box)(({ theme }) => {
  return {
    backgroundColor: '#fff',
    padding: '30px 12px 30px 30px',
    borderRadius: 10,
    height: '209px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  };
});

export const DescriptionPaperContainerClasses = styled(
  DescriptionPaperContainer,
)(() => {
  return {
    minWidth: '246px',
    minHeight: '230px',
  };
});

export const DescriptionClassesTitle = styled(Typography)(() => {
  return {
    color: mediumGray,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '19px',
  };
});

export const DescriptionClassesDescription = styled(DescriptionClassesTitle)(
  () => {
    return {
      color: darkGray,
      fontWeight: 'bold',
      marginTop: 5,
    };
  },
);

export const DescriptionPaperContainerSpots = styled(DescriptionPaperContainer)(
  () => {
    return {
      minWidth: '346px',
      minHeight: '230px',
    };
  },
);

export const DescriptionSpotsTitle = styled(Typography)(() => {
  return {
    fontSize: '16px',
    lineHeight: '22px',
    color: mediumGray,
    marginBottom: 10,
  };
});

export const DescriptionSpotsValue = styled(Typography)<
  Theme,
  TDescriptionSpotsValueStyled
>(({ noMoreSpots }) => {
  return {
    fontWeight: 'bold',
    fontSize: 18,
    color: noMoreSpots ? error : darkGray,
    marginBottom: 4,
  };
});

export const DescriptionSpotsCaption = styled(Typography)<
  Theme,
  TDescriptionSpotsValueStyled
>(({ noMoreSpots }) => {
  return {
    color: noMoreSpots ? error : 'transparent',
    fontSize: 14,
    width: '50%',
  };
});

export const DescriptionSpotsButton = styled(Button)<Theme, ButtonProps>(
  ({ variant }) => {
    return {
      fontWeight: 'bold',
      fontSize: 14,
      borderRadius: 8,
      textTransform: 'none',
      backgroundColor: variant === 'text' ? 'transparent' : primaryDark,
      color: variant === 'text' ? primaryDark : white,
      padding: variant === 'text' ? 0 : '14px 20px',
      '&:hover': {
        opacity: 0.8,
        transition: 'opacity 0.2s linear',
        backgroundColor: variant === 'text' ? 'transparent' : primaryDark,
      },
    };
  },
);
