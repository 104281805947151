import { Dependent } from 'types/dependent';
import { FinancialGuardian } from 'types/financialGuardian';
import {
  INVOICE_STATUS,
  InvoiceEntity as InvoiceInterface,
} from 'types/invoice';
import { Service } from 'types/service';
import { BaseEntity } from './BaseEntity';

// https://refactoring.guru/design-patterns/adapter
export class InvoiceEntity extends BaseEntity {
  id!: number;

  createdAt!: string;

  updatedAt!: string;

  deletedAt?: string;

  number?: string;

  internalStatus!: INVOICE_STATUS;

  externalStatus?: any;

  externalId?: any;

  flowStatus?: any;

  printUrlPdf?: any;

  printUrlXml?: any;

  canceledAt?: any;

  invoiceFailReason?: any;

  serviceAmount!: number;

  serviceDiscountUnconditionedAmount!: number;

  fractionsNumber!: number;

  paymentId!: number;

  financialGuardianId!: number;

  registrationNumber!: string;

  source!: string;

  paymentDiscountedValue!: number;

  paymentOriginalValue!: number;

  paymentDiscountAmount!: number;

  registrationId!: number;

  dependentId!: number;

  competenceDate!: string;

  issuedDate?: string;

  startDateServiceProvision!: string;

  endDateServiceProvision!: string;

  serviceClassId!: number;

  invoiceServiceCode!: string;

  serviceId!: number;

  errors?: any;

  // relations

  service?: Service;

  financialGuardian?: FinancialGuardian;

  dependent?: Dependent;

  constructor(invoice: InvoiceInterface) {
    super();
    this.mapJsonToEntity(invoice);
  }

  get isCanceled(): boolean {
    return this.internalStatus === INVOICE_STATUS.CANCELED || !!this.canceledAt;
  }

  get isIssued(): boolean {
    return this.externalId && this.internalStatus === INVOICE_STATUS.ISSUED;
  }

  get serviceAmountWithDiscount(): number {
    return this.serviceAmount - (this.serviceDiscountUnconditionedAmount || 0);
  }
}
