import React from 'react';
import { Controller } from 'react-hook-form';
import { IconButton, TextField, Typography, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import useGlobalStyles from 'styles';
import { CustomButton } from 'components/CustomButton';
import { ContainerModalInsertInviteCode } from '../styles';
import { IModalInsertServiceCodeProps } from '../types';
import { useServiceCodeModalController } from './controller';
import { useStyles } from './styles';

export const ModalInsertServiceCode = ({
  open,
  onClose,
  onConfirm,
  isLoading,
}: IModalInsertServiceCodeProps) => {
  const { control, errors, handleSubmit, register } =
    useServiceCodeModalController();
  const styles = useStyles();
  const classes = useGlobalStyles();
  return (
    <ContainerModalInsertInviteCode open={open}>
      <div id="top-modal-actions">
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <Typography className={styles.dialogText}>
        Insira o código de acesso antecipado
      </Typography>
      <Typography className={classes.dialogSubText}>
        Para obter o código acesso de antecipado,
        <br />
        entre em contato com a escola.
      </Typography>

      <Controller
        name="serviceCode"
        control={control}
        as={({ value, onChange }) => (
          <TextField
            id="serviceCode"
            className={styles.textIpunt}
            name="serviceCode"
            label="Código"
            variant="outlined"
            value={value}
            onChange={onChange}
            error={!!errors.serviceCode}
            helperText={errors.serviceCode ? errors.serviceCode?.message : null}
            inputRef={register}
          />
        )}
      />

      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        className={styles.container}
        justifyContent="center"
      >
        <Box m={2}>
          <CustomButton onClick={onClose} variant="secondary">
            Fechar
          </CustomButton>
        </Box>
        <Box m={2}>
          <CustomButton onClick={handleSubmit(onConfirm)} loading={isLoading}>
            Aplicar código
          </CustomButton>
        </Box>
      </Box>
    </ContainerModalInsertInviteCode>
  );
};
