import { Grid } from '@material-ui/core';
import DependentDocumentsForm from 'components/DependentDocumentsForm';
import { ResponsiveInputGridItem } from 'components/ResponsiveInputGridItem';
import React from 'react';
import { Controller } from 'react-hook-form';
import useGlobalStyles from 'styles';
import { FormProperties } from './types';
import { dependentFields } from './utils';

const DependentForm: React.FC<FormProperties> = ({
  dependent,
  documents,
  hasGuardianDocuments,
  setHasGuardianDocuments,
  editing,
  register,
  errors,
  control,
}) => {
  const classes = useGlobalStyles();

  return (
    <Grid item container spacing={4} className={classes.screenInputGrid}>
      {dependentFields(dependent).map(field => (
        <ResponsiveInputGridItem key={field.label}>
          <Controller
            name={field.name}
            control={control}
            as={({ value, onChange }) => (
              <field.component
                variant="outlined"
                mask={field.mask || ''}
                label={field.label}
                name={field.name}
                value={value}
                onChange={onChange}
                error={!!errors[field.name]}
                helperText={
                  errors[field.name] ? errors[field.name]?.message : null
                }
                inputRef={register}
                disabled={!editing}
                className={classes.maxWidthInput}
              />
            )}
            defaultValue={field.value}
          />
        </ResponsiveInputGridItem>
      ))}
      <DependentDocumentsForm
        dependent={dependent}
        documents={documents}
        hasGuardianDocuments={hasGuardianDocuments}
        setHasGuardianDocuments={setHasGuardianDocuments}
        editing={editing}
        register={register}
        errors={errors}
        control={control}
      />
    </Grid>
  );
};

export default DependentForm;
