import useNewWindow from 'hooks/newWindow';
import useGlobalStyles from 'styles';
import { ButtonProps } from './types';

export const useButtonViewContractController = ({
  documentPath,
}: Pick<ButtonProps, 'documentPath'>) => {
  const classes = useGlobalStyles();

  const { loading, newWindow } = useNewWindow();

  const viewContract = async () => {
    newWindow(documentPath);
  };

  return {
    viewContract,
    loading,
    classes,
  };
};
