import { Divider as MUDivider, styled, Typography } from '@material-ui/core';
import { darkGray, divideBorderColor } from 'styles/theme';

export const Title = styled(Typography)(() => {
  return {
    fontWeight: 600,
    fontSize: '18px',
    color: darkGray,
  };
});

export const Divider = styled(MUDivider)(() => {
  return {
    width: '100%',
    height: '0.2px',
    border: `0 solid ${divideBorderColor}`,
    marginTop: 16,
    marginBottom: 37,
  };
});
