export class FeedbackMessages {
  static DOWNLOADING_PDF = 'Estamos baixando o seu PDF...';

  static CANCELING_INVOICE = 'Estamos cancelando a sua nota, aguarde...';

  static SYNCING_WITH_NFEIO = 'Estamos sincronizando a sua nota, aguarde...';

  static ERROR_DOWNLOADING_PDF = 'Erro ao baixar PDF da NFe.io';

  static ERROR_CANCELING_INVOICE = 'Erro ao cancelar a nota.';

  static ERROR_SYNCING_WITH_NFEIO =
    'Erro ao sincronizar a nota com o serviço da NFe.io.';

  static SUCCESS_CANCELING_INVOICE =
    'A nota será cancelada e atualizará em breve!';

  static SUCCESS_SYNCING_WITH_NFEIO =
    'A nota será sincronizada e atualizará em breve!';

  static SUCCESS_ISSUING_INVOICE = 'A nota será emitida e atualizará em breve!';

  static ERROR_ISSUING_INVOICE = 'Erro ao emitir a nota.';
}
