import { translatePaymentMethod } from 'pages/Client/PaymentMethod/steps/ConfirmPayment/utils';
import { Payment } from 'types/payment';
import { formatBRL } from 'utils';

export const MODAL_TITLE = (refund: boolean, entity: 'pagamento' | 'fatura') =>
  `${refund ? 'Estornar' : 'Cancelar'} ${entity}?`;

export const MODAL_SUBTITLE = (refund: boolean) =>
  `Esta ação irá cancelar todas as cobranças futuras${
    refund ? ' e estornar as que já foram pagas' : ''
  }.`;

export const MODAL_CANCEL_PIX_TITLE = 'Cancelar PIX?';

export const MODAL_BTN = (refund: boolean) =>
  `Sim, ${refund ? 'estornar' : 'cancelar'}`;

export const MODAL_CANCEL_PIX_BTN = 'Sim, cancelar';

export const SYNC_MODAL_TITLE =
  'Sincronizar status do pagamento com a Vindi/Itau?';

export const SYNC_MODAL_BTN = 'Sim, sincronizar';

export const paymentSummary = (payment: Payment) => [
  {
    title: 'Forma de pagamento',
    value: translatePaymentMethod(payment.paymentMethodCode),
    visibleToUser: false,
  },
  {
    title: 'Valor',
    value: formatBRL(payment.value),
  },
  {
    title: 'Status',
    value: payment.status,
  },
  {
    title: 'Aluno',
    value: payment.registration.dependent.name,
  },
  {
    title: 'Matrícula',
    value: payment.registration.number || payment.registration.enrollmentNumber,
  },
  {
    title: 'Turma',
    value: payment.registration.schoolGrade,
  },
];
