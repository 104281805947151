import {
  ChangeEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { isString } from 'utils/typeCheckers';
import {
  BaseAutocompleteProps,
  GetOptionLabelCallback,
  OnAutocompleteChangeCallback,
} from './types';
import { isAutocompleteOption } from './utils';

export const useBaseAutocompleteController = ({
  onChange,
  onSelect,
  onAction,
  onEndTyping,
  triggerActionOnEnter,
  freeSolo,
  delayAfterLastKeyStroke = 500,
  initialValue,
}: Omit<BaseAutocompleteProps, 'endIcon' | 'loading' | 'options'>) => {
  // ---------- States ----------
  const [text, setText] = useState(initialValue || '');

  // ---------- Refs ----------
  const timerRef = useRef<NodeJS.Timeout>();

  // ---------- Callbacks ----------
  const handleClearTimeout = useCallback(() => {
    if (timerRef?.current) return clearTimeout(timerRef.current);
  }, []);

  const handleOnAutocompleteChange: OnAutocompleteChangeCallback = useCallback(
    (_event, newValue) => {
      if (isString(newValue)) {
        setText(newValue);
      }

      if (isAutocompleteOption(newValue)) {
        setText(newValue.label);

        onSelect?.(newValue);
      }
    },
    [onSelect],
  );

  const handleOnTextInputChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = useCallback(
    event => {
      const newValue = event.target.value;
      setText(newValue);

      onChange?.(newValue);
    },
    [onChange],
  );

  const handleOnKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    event => {
      const keyPressed = event.key;
      if (
        onAction &&
        freeSolo &&
        triggerActionOnEnter &&
        keyPressed === 'Enter'
      ) {
        handleClearTimeout();
        onAction(text);
      }
    },
    [onAction, freeSolo, triggerActionOnEnter, handleClearTimeout, text],
  );

  const handleOnClick: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      onAction?.(text);
    }, [onAction, text]);

  const handleGetOptionLabel: GetOptionLabelCallback = useCallback(option => {
    if (isAutocompleteOption(option)) {
      return option.label;
    }

    if (isString(option)) {
      return option;
    }

    console.error('Failed to get option label for option:', option);
    return '';
  }, []);

  // ---------- Effects ----------
  useEffect(() => {
    if (freeSolo && onEndTyping) {
      timerRef.current = setTimeout(() => {
        onEndTyping(text);
      }, delayAfterLastKeyStroke);
    }

    return handleClearTimeout;
  }, [
    delayAfterLastKeyStroke,
    freeSolo,
    handleClearTimeout,
    onEndTyping,
    text,
  ]);

  return {
    text,
    handleOnAutocompleteChange,
    handleOnTextInputChange,
    handleOnKeyDown,
    handleOnClick,
    handleGetOptionLabel,
  };
};
