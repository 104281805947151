import { Grid, Paper, Table } from '@material-ui/core';
import { EmptyTable } from 'components/EmptyTable';
import React from 'react';
import { useStyles } from './styles';
import { TableProperties } from './types';

export const TablePaper: React.FC<TableProperties> = ({
  children,
  minWidth,
  isEmpty,
}) => {
  const styles = useStyles({ minWidth })();

  return (
    <Grid item xs={12}>
      <Paper elevation={0} className={styles.paper}>
        <Table className={styles.table}>{children}</Table>
        {isEmpty && <EmptyTable />}
      </Paper>
    </Grid>
  );
};
