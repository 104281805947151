import { yupResolver } from '@hookform/resolvers/yup';
import { useMediaQuery } from '@material-ui/core';
import { useAuth } from 'contexts/auth';
import { StatusCodes } from 'http-status-codes';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import AuthService from 'services/authService';
import useGlobalStyles from 'styles';
import { GENERAL_ERROR } from 'texts';
import { useStyles } from './styles';
import { loginSchema } from './schema';

export const useLoginController = () => {
  // Styles
  const classes = useGlobalStyles();
  const styles = useStyles();

  // Custom Hooks
  const { signIn, loginError } = useAuth();

  // States
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');

  // Form
  const { handleSubmit, errors, register } = useForm({
    resolver: yupResolver(loginSchema),
    reValidateMode: 'onBlur',
  });

  // Callbacks
  const logIn = async (data: any) => {
    setEmail(data.email);
    await signIn(data.email, data.password);
  };

  const onSubmit = async (data: any) => {
    logIn(data);
  };

  const reSendEmail = async () => {
    const response = await AuthService.verifyEmail(email);
    if (response.data.status === StatusCodes.OK) {
      setError('');
    } else {
      setError(GENERAL_ERROR);
    }
  };

  // Effects
  useEffect(() => {
    setError(loginError);
  }, [loginError]);

  return {
    classes,
    styles,
    error,
    handleSubmit,
    errors,
    register,
    onSubmit,
    reSendEmail,
    email,
  };
};
