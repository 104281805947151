/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Grid, MenuItem, TablePagination, TextField } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ExportExcelButton from 'components/ExportExcelButton';
import FinancialReportTable from 'components/FinancialReportTable';
import MonthlyReportTable from 'components/FinancialReportTable/MonthlyReportTable';
import YearlyReportTable from 'components/FinancialReportTable/YearlyReportTable';
import PageHeader from 'components/PageHeader';
import { StatusCodes } from 'http-status-codes';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import PaymentService from 'services/paymentService';
import useGlobalStyles from 'styles';
import { Bill } from 'types/bill';
import { BILL_STATUS, PAYMENT_STATUS } from 'types/enums';
import { MonthlyReport } from 'types/financialReport';
import { ROWS_PER_PAGE, monthsArray } from 'utils';
import { YEARS_ARRAY } from '../AdmRegistrations/utils';
import {
  billHeaders,
  billTableValues,
  monthlyReportHeaders,
  monthlyReportValues,
} from './utils';

const theme = createMuiTheme({}, ptBR);

const FinancialReport: React.FC = () => {
  const classes = useGlobalStyles();
  const [bills, setBills] = useState<Bill[]>([]);
  const [monthlyReport, setMonthlyReport] = useState<MonthlyReport[]>([]);
  const [selectYear, setSelectYear] = useState(new Date().getFullYear());
  const [selectMonth, setSelectMonth] = useState(new Date().getMonth());
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);
  const [billsCsv, setBillsCsv] = useState<string[][]>([]);
  const [monthsCsv, setMonthsCsv] = useState<any[][]>([]);

  const loadPayments = useCallback(async () => {
    const MM = String(selectMonth).padStart(2, '0');
    const { data: response, status } = await PaymentService.getBills({
      page: page + 1,
      limit: rowsPerPage,
      filter: [
        {
          field: 'paymentDateForecast',
          operator: '$dtMY',
          value: `${MM}-${selectYear}`,
        },
        {
          field: 'status',
          operator: '$ne',
          value: BILL_STATUS.CANCELED,
        },
        {
          field: 'payment.status',
          operator: '$ne',
          value: PAYMENT_STATUS.CANCELED,
        },
      ],
      join: [['payment'], ['payment.registration']],
    });
    if (status === StatusCodes.OK) {
      setBills(response.data);
      setTotal(response.total);

      const csv: string[][] = [billHeaders];
      response.data.forEach((bill: Bill) => {
        csv.push(billTableValues(bill));
      });
      setBillsCsv(csv);
    }
  }, [selectYear, selectMonth, page, rowsPerPage]);

  useEffect(() => {
    loadPayments();
  }, [loadPayments]);

  const loadMonthlyReport = useCallback(async () => {
    const response = await PaymentService.getMonthAndYearSum(selectYear);
    if (response.status === StatusCodes.OK) {
      setMonthlyReport(response.data);

      const csv: any[][] = [monthlyReportHeaders];
      response.data.forEach((item: MonthlyReport) => {
        csv.push(monthlyReportValues(item));
      });
      setMonthsCsv(csv);
    }
  }, [selectYear]);

  useEffect(() => {
    loadMonthlyReport();
  }, [loadMonthlyReport]);

  const handleChangeYear = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectYear(Number(e.target.value));
  };

  const handleChangeMonth = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectMonth(Number(e.target.value));
  };

  return (
    <Grid
      className={classes.listScreenGrid}
      container
      direction="column"
      alignItems="flex-start"
    >
      <Grid container item direction="column">
        <PageHeader
          title="Previsão financeira"
          subtitle="Veja a previsão de rendimentos"
          button={
            <ExportExcelButton data={billsCsv} fileName="pagamentos.csv" />
          }
        />
        <Grid container spacing={2}>
          <Grid item>
            <TextField
              label="Ano"
              variant="outlined"
              select
              SelectProps={{ MenuProps: { disableScrollLock: true } }}
              value={selectYear}
              onChange={handleChangeYear}
              className={classes.filterField}
            >
              {YEARS_ARRAY.map(year => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <TextField
              label="Mês"
              variant="outlined"
              select
              SelectProps={{ MenuProps: { disableScrollLock: true } }}
              value={selectMonth}
              onChange={handleChangeMonth}
              className={classes.filterField}
            >
              {monthsArray.map((month, i) => (
                <MenuItem key={month} value={i + 1}>
                  {month}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <FinancialReportTable bills={bills} />
        <ThemeProvider theme={theme}>
          {/** @ts-ignore */}
          <TablePagination
            component="div"
            count={total}
            page={page}
            labelRowsPerPage="Itens por página"
            onChangePage={(_event: unknown, newPage: number) =>
              setPage(newPage)
            }
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
              setPage(0);
              setRowsPerPage(parseInt(event.target.value, 10));
            }}
            rowsPerPageOptions={ROWS_PER_PAGE}
          />
        </ThemeProvider>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <PageHeader
              title="Soma mensal"
              subtitle="Rendimentos por mês"
              button={
                <ExportExcelButton
                  data={monthsCsv}
                  fileName="soma_mensal.csv"
                />
              }
            />
            <MonthlyReportTable report={monthlyReport} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <YearlyReportTable />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FinancialReport;
