import { makeStyles } from '@material-ui/core';
import { primaryMedium } from 'styles/theme';

export const useStyles = makeStyles({
  grid: {
    marginTop: 30,
  },
  paper: {
    marginTop: 30,
    padding: 20,
    borderRadius: 22,
    boxShadow: '0px 6px 32px rgba(9, 13, 27, 0.07)',
  },
  navigateButton: {
    color: primaryMedium,
  },
});
