import { Box, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CustomButton } from 'components/CustomButton';
import CustomDialog from 'components/CustomDialog';
import { FixedFooter } from 'components/FixedFooter';
import { RegistrationListItem } from 'components/RegistrationListItem';
import Scaffold from 'components/Scaffold';
import { GenericDialogRef } from 'components/dialogs/GenericDigalog/types';
import { StatusCodes } from 'http-status-codes';
import GuardiansTable from 'pages/Admin/AdmRegistrationDetails/GuardiansTable';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { CLIENT_ROUTE } from 'routes/consts';
import DependentService from 'services/dependentService';
import RegistrationService from 'services/registrationService';
import { useAppStore } from 'store';
import useGlobalStyles from 'styles';
import { GENERAL_ERROR } from 'texts';
import { REGISTRATION_STATUS } from 'types/enums';
import { Registration } from 'types/registration';
import { RouteParams } from 'types/routeParams';
import { shallow } from 'zustand/shallow';
import { ReRegistrationFormDialog } from './ReRegistrationFormDialog';
import { subtitleOlderBrotherCouponModal } from './conts';
import { REGISTRATION_STATUS_NO_NEXT_STEPS } from './utils';

const ViewRegistration: React.FC = () => {
  const { showAlert, configurations } = useAppStore(
    state => ({
      showAlert: state.alert.showAlert,
      configurations: state.configurations.data,
    }),
    shallow,
  );
  const selectedClassDetailDialogRef = useRef<GenericDialogRef>(null);
  const classes = useGlobalStyles();
  const history = useHistory();
  const { params } = useRouteMatch<RouteParams>();
  const { id: registrationId } = params;
  const [registration, setRegistration] = useState<Registration | null>(null);
  const [enrollmentStep, setEnrollmentStep] = useState<'enroll' | 'reEnroll'>(
    'enroll',
  );

  const hasOlderBrotherEnrolledDialogRef = useRef<GenericDialogRef>(null);

  const getRegistration = useCallback(async () => {
    const response = await RegistrationService.registration(
      Number(registrationId),
      {
        join: [
          ['schoolClass'],
          ['coupon'],
          ['financialGuardian'],
          ['financialGuardian.user'],
          ['dependent'],
          ['documents'],
          // Required to check re registration flow
          { field: 'nextRegistration', select: ['id'] },
        ],
      },
    );
    if (response.status === StatusCodes.OK) {
      setRegistration(response.data);
    } else {
      showAlert({
        message: response.data?.message || GENERAL_ERROR,
        severity: 'error',
      });
    }
  }, [registrationId, showAlert]);

  useEffect(() => {
    getRegistration();
  }, [getRegistration]);

  const handleStartRegistrationEnrollment = async (
    targetRegistrationId = registrationId,
  ) => {
    try {
      await RegistrationService.startRegistrationEnrollmentFlow(
        targetRegistrationId,
      );

      history.push(
        CLIENT_ROUTE.REGISTRATION_PAYMENT_METHOD.replace(
          ':id',
          targetRegistrationId,
        ),
      );
    } catch (error: any) {
      showAlert({
        message:
          error?.message || 'Erro ao prosseguir com o fluxo de matrícula',
        severity: 'error',
      });
    }
  };

  const handleGoNext = async () => {
    if (!registration) return;

    setEnrollmentStep('enroll');
    const hasOlderBrotherEnrolled = await handleCheckOlderBrotherEnrolled();
    if (hasOlderBrotherEnrolled) {
      await handleStartRegistrationEnrollment();
    }
  };

  const handleOpenCheckOlderBrotherEnrolledDialog = () => {
    hasOlderBrotherEnrolledDialogRef.current?.openDialog();
  };
  const handleCloseCheckOlderBrotherEnrolledDialog = () => {
    hasOlderBrotherEnrolledDialogRef.current?.closeDialog();
  };

  const handleConfirmOlderBrotherModal = async () => {
    if (!registration) return;

    handleCloseCheckOlderBrotherEnrolledDialog();
    if (enrollmentStep === 'enroll') {
      await handleStartRegistrationEnrollment();
    } else if (enrollmentStep === 'reEnroll') {
      selectedClassDetailDialogRef.current?.openDialog();
    }
  };

  const handleCheckOlderBrotherEnrolled = async (isReRegistration = false) => {
    if (!registration) return;

    /**
     * https://app.clickup.com/t/8678gj4aa - Se a escola ja tiver aplicado um cupom pelo admin,
     * entao o fluxo não vai aplicar o cupom de irmão mais velho automaticamente,
     * logo não precisa verificar se tem irmão mais velho.
     */
    if (registration.couponId && !isReRegistration) return true;

    try {
      const { data: olderBrothersInfo } =
        await DependentService.checkOlderBrothersWithActiveEnrollment(
          registration.dependentId,
          isReRegistration
            ? configurations!.enrollmentYear
            : registration.schoolYear,
        );

      if (
        olderBrothersInfo.olderBrothersCount > 0 &&
        olderBrothersInfo.registrationCount === 0
      ) {
        handleOpenCheckOlderBrotherEnrolledDialog();
      }

      // Tem irmão mais velho e ta matriculado no ano letivo atual
      const hasOlderBrotherEnrolled =
        olderBrothersInfo.olderBrothersCount > 0 &&
        olderBrothersInfo.registrationCount > 0;
      // Não tem irmão mais velho
      const hasNoOlderBrotherEnrolled =
        olderBrothersInfo.olderBrothersCount === 0;

      return hasOlderBrotherEnrolled || hasNoOlderBrotherEnrolled;
    } catch (error: any) {
      showAlert({
        message: error?.message || 'Erro ao verificar se tem irmão mais velho',
        severity: 'error',
      });
    }
  };

  const pageTitle = useMemo(() => {
    if (registration?.status === REGISTRATION_STATUS.ACTIVE) {
      return `Matrícula do ano letivo ${registration.schoolYear}`;
    }

    return 'Solicitação de matrícula';
  }, [registration?.schoolYear, registration?.status]);

  const handleOpenReRegistrationDialog = async () => {
    if (registration?.isBlocked) {
      showAlert({
        message:
          'Matrícula bloqueada! Não é possivel realizar a matrícula de veterano.',
        severity: 'warning',
      });
      return;
    }

    setEnrollmentStep('reEnroll');
    const hasOlderBrotherEnrolled = await handleCheckOlderBrotherEnrolled(true);
    if (hasOlderBrotherEnrolled) {
      selectedClassDetailDialogRef.current?.openDialog();
    }
  };

  const renderReRegistrationButton = () => {
    if (!registration?.canReRegister) return null;

    return (
      <Grid item>
        <CustomButton onClick={handleOpenReRegistrationDialog}>
          Ir para matrícula de veterano {configurations?.enrollmentYear}
        </CustomButton>
      </Grid>
    );
  };

  const renderNextButton = () => {
    if (
      !registration?.status ||
      REGISTRATION_STATUS_NO_NEXT_STEPS.includes(registration?.status)
    )
      return null;

    if (registration?.isBlocked) {
      showAlert({
        message: `Matrícula bloqueada! Não é possivel realizar a matrícula de ${registration.schoolYear}.`,
        severity: 'warning',
      });
      return;
    }

    return (
      <Grid item>
        <CustomButton onClick={handleGoNext}>
          Seguir com a matrícula de {registration.schoolYear}
        </CustomButton>
      </Grid>
    );
  };

  if (!registration) return null;

  return (
    <Scaffold
      rawTitle={pageTitle}
      rawSubtitle={
        <>
          <b>Dados básicos</b> do aluno(a)
        </>
      }
    >
      {registration?.isBlocked ? (
        <Box justifyContent="center" alignItems="center" display="flex" mb={4}>
          <Alert variant="filled" severity="warning">
            Sua matrícula foi sinalizada como bloqueada. Se você passou por um
            erro de contratação recentemente, prossiga com a matrícula. Caso
            contrario, entre em contato com a administração para obter mais
            informações.
          </Alert>
        </Box>
      ) : null}

      <RegistrationListItem
        registration={{
          ...registration,
          status: registration.status,
        }}
        dependentName={registration.dependent.name}
      />

      <Typography className={classes.listScreenSectionHeaderText}>
        <b>Responsáveis</b>
      </Typography>

      <GuardiansTable
        financialGuardian={registration.financialGuardian}
        // FIXME: ISSO AQUI NAO É USADO NO SISTEMA, 0 REGISTROS DE RESPONSAVEIS LEGAIS EM PRODUCÃO EM (04/JUL/2023)
        legalGuardians={[]}
      />

      <FixedFooter spacing={2}>
        <>
          {renderReRegistrationButton()}
          {renderNextButton()}
        </>
      </FixedFooter>

      <ReRegistrationFormDialog
        currentSchoolClassId={registration.schoolClassId}
        dependent={registration?.dependent}
        dialogRef={selectedClassDetailDialogRef}
        onStartRegistrationEnrollment={handleStartRegistrationEnrollment}
      />

      <CustomDialog
        rawTitle="Cupom de irmão mais velho"
        rawSubtitle={subtitleOlderBrotherCouponModal}
        ref={hasOlderBrotherEnrolledDialogRef}
        primaryButton={{
          text: 'Não, fechar',
          onClick: handleCloseCheckOlderBrotherEnrolledDialog,
        }}
        secondaryButton={{
          text: 'Sim, continuar',
          onClick: handleConfirmOlderBrotherModal,
        }}
        icon={{
          name: 'warningCheckCircle',
        }}
      />
    </Scaffold>
  );
};

export default ViewRegistration;
