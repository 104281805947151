import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useAlert } from 'hooks/Alert';
import { MESSAGE_TYPE } from 'hooks/Alert/types';
import { useCart } from 'hooks/useCart';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';

export const useMarketplaceCartController = () => {
  // Custom Hooks
  const history = useHistory();
  const { cartItems, totalCartValue } = useCart();
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const { openAlert, isShowAlert, alertMessage, alertType, closeAlert } =
    useAlert();

  // Styles
  const styles = useStyles();

  const handleGoToPaymentCheckout = () => {
    if (cartItems.length === 0) {
      openAlert({
        message: 'O carrinho está vazio.',
        type: MESSAGE_TYPE.ERROR,
      });

      return;
    }

    history.push('/client-marketplace/payment-checkout');
  };

  return {
    styles,
    cartItems,
    totalCartValue,
    handleGoToPaymentCheckout,
    isMobile,
    isShowAlert,
    closeAlert,
    alertMessage,
    alertType,
  };
};
